import React from 'react'
import { useIntl } from 'react-intl'
import { WarningOutlined } from '@ant-design/icons'
import { pi } from '../../../../lang/definitions'

const PaymentState = ({ state }: { state: string }): React.JSX.Element => {
  const intl = useIntl()

  let statusColor
  let textColor
  let translatedState
  switch (state) {
    case 'ADDED':
      statusColor = '#5f9cce'
      textColor = 'white'
      translatedState = intl.formatMessage(pi['pi.status.added'])
      break
    case 'READY':
      statusColor = '#E2EEF9'
      translatedState = intl.formatMessage(pi['pi.status.ready'])
      break
    case 'REQUIRE_SIGNATURE':
      statusColor = 'rgba(255, 193, 7, 0.3)' //IE11 supported
      translatedState = intl.formatMessage(pi['pi.status.requireSignature'])
      break
    case 'ACTIVE':
      statusColor = 'rgba(255, 193, 7, 0.3)' //IE11 supported
      translatedState = 'Active'
      break
    case 'PROCESSING':
      statusColor = '#46004a'
      textColor = 'white'
      translatedState = intl.formatMessage(pi['pi.status.processing'])
      break
    case 'PENDING':
      statusColor = '#FCEAC7' //IE11 supported
      translatedState = intl.formatMessage(pi['pi.status.pending'])
      break
    case 'PENDING_AUTHORIZATION':
      statusColor = 'rgba(143, 34, 58, 0.2)' //IE11 supported
      translatedState = intl.formatMessage(pi['pi.status.pending.authorization'])
      break
    case 'BANK':
      statusColor = 'rgba(248, 179, 25, 0.2)' //IE11 supported
      translatedState = intl.formatMessage(pi['pi.status.bank'])
      break
    case 'PAID':
      statusColor = '#9FCBB2'
      translatedState = intl.formatMessage(pi['pi.status.paid'])
      break
    case 'ERROR':
      statusColor = '#E49999' // red
      translatedState = intl.formatMessage(pi['pi.status.inReview'])
      break
    case 'DELETED':
      statusColor = '#f2f2f2' //IE11 supported
      translatedState = intl.formatMessage(pi['pi.status.deleted'])
      break
    case 'CANCELED':
      statusColor = '#999999'
      translatedState = intl.formatMessage(pi['pi.status.canceled'])
      break
    default:
      statusColor = '#f2f2f2'
      translatedState = ''
      break
  }

  return (
    <React.Fragment>
      <div
        className="desktop-display"
        data-testid="payment-state-desktop"
        style={{
          background: statusColor,
          color: textColor ? textColor : 'inherit',
          fontWeight: 'bold',
          fontSize: '85%',
          padding: '2px 4px',
          textAlign: 'center',
          textTransform: 'capitalize',
          borderRadius: 5,
        }}
      >
        {translatedState.toLowerCase()}{' '}
        {state === 'ERROR' && <WarningOutlined style={{ color: '#8F223A', paddingLeft: 5 }} />}
      </div>
      <div
        className="mobile-display"
        data-testid="payment-state-mobile"
        style={{
          backgroundColor: statusColor,
          height: '25px',
          borderRadius: '4px',
          fontWeight: 'bold',
          fontSize: '85%',
        }}
      ></div>
    </React.Fragment>
  )
}

export default PaymentState
