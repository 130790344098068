import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import * as Sentry from '@sentry/react'
import { Tabs, Alert } from 'antd'
import { useIntl } from 'react-intl'
import LoginSSO from './LoginSSO'
import Login from './Login'
import { page } from 'lang/definitions/index'
import { Rules } from 'types/rules'
import { getCountryRules } from 'api/rules'
import { savePreferredLanguage, getPreferredLoginMethod } from 'utils/storage'
import { getCountryCodeFromLanguageCode, getLanguageCodeFromCountryCode } from 'utils/helpers'
import { useLanguageState } from 'stores/language/LanguageStore'
import { SET_LANGUAGE } from 'stores/language/LanguageActionTypes'
import { useSsoState } from 'stores/Sso/SsoStore'
import { Profile } from 'types/profile'
import ExternalContainer from 'components/ExternalContainer/ExternalContainer'
import CountrySelect from 'components/CountrySelect/CountrySelect'
import { SplitContainer } from 'components/SplitContainer/SplitContainer'
import { useSession } from 'stores/session'
import { cx } from 'antd-style'
import useLoginPanelStyles from './LoginPanel.style'

export interface EntityUser {
  sessionId: string
  user: {
    mocked: boolean
    dateMocked: string
    id: string
    name: {
      first: string
      last: string
    }
    email: string
    dateCreated: string
    countryCode: string
    dateLastLogin: string
    dateUpdated: string
  }
  profiles: Array<Profile>
}

const LoginPanel = (): React.JSX.Element => {
  const intl = useIntl()
  const history = useHistory()
  const { styles } = useLoginPanelStyles()
  const {
    state: { rules },
    actions: { setRules },
  } = useSession()
  const [language, dispatchLanguage] = useLanguageState()
  const setLanguage = (locale: string) => dispatchLanguage({ type: SET_LANGUAGE, language: locale })
  const [SsoState] = useSsoState()
  const ssoStatus = SsoState.ssoStatus
  const availableCountries = (rules && rules.logic?.registrationCountries?.rule) || ([] as string[])
  // Loading variable is used to show a loader while fetching country rules
  const [loading, setLoading] = useState<boolean>(false)
  const [countryCode, setCountryCode] = useState<string>('gb')

  useEffect(() => {
    let isMounted = true
    const fetchCountryRules = async (countryCode: string) => {
      try {
        if (isMounted) setLoading(true)
        const response: Rules = await getCountryRules(countryCode)
        if (isMounted) {
          setRules(response)
          setLoading(false)
        }
      } catch (err) {
        Sentry.captureException(err)
      }
    }

    try {
      const countryCodeFromLanguage = getCountryCodeFromLanguageCode(language.language)
      setCountryCode(countryCodeFromLanguage)
      void fetchCountryRules(countryCodeFromLanguage)
    } catch (err) {
      Sentry.captureException(err)
      void fetchCountryRules('gb')
    }
    return () => {
      isMounted = false
    }
  }, [language])

  // loginMethods from country rules.
  const loginMethods = (rules?.logic && rules.logic.authorizationMethods.rule) || ''

  // check if there is a single login method
  const singleLoginMethod = loginMethods && Object.keys(loginMethods).length

  // url will contain sessionExpired=true if automatic logging out happened
  const showSessionExpiredMessage = history.location.search.includes('sessionExpired=true')

  const displaySingleLoginMethod = (method: string) => {
    if (method === 'credentials') return <Login countryCode={countryCode} />
    if (method === 'sso') return <LoginSSO />
    // add more methods here..
  }

  type methodsType = {
    [key: string]: string
    credentials: string
  }

  //List of logging methods
  const methods: methodsType = {
    credentials: intl.formatMessage(page['page.login.tab.credentials']),
    sso: intl.formatMessage(page['page.login.tab.sso']),
  }

  // function that renders the loginMethod and the alternative buttons.
  const renderLoginView = () => {
    const loginMethodsKeys: string[] = Object.keys(loginMethods).filter(
      (key) => loginMethods[key as keyof typeof loginMethods]
    )
    // get the preferred loginMethod from localStorage, check if current language rules allows it, or use credentials as default.
    const preferredLoginMethod: string = loginMethodsKeys.includes(getPreferredLoginMethod() as string)
      ? (getPreferredLoginMethod() as string)
      : 'credentials'
    // return the items that is not equal to the preferred login method
    const alternativeLoginMethods = loginMethodsKeys.filter((item) => item !== preferredLoginMethod)
    const loginMethodsSorted: string[] = [preferredLoginMethod, ...alternativeLoginMethods]

    return singleLoginMethod && singleLoginMethod > 1 ? (
      <Tabs
        type="card"
        className={styles.loginContainer}
        tabPosition={'bottom'}
        items={loginMethodsSorted.map((item) => ({
          key: item,
          label: (
            <div className={cx(styles.loginMethod, `bh-track-${item}-button`)}>
              {item !== 'credentials' ? <div className={cx(styles.loginIcons, styles.sso)} /> : ''}
              {methods[item]}
            </div>
          ),
          children: displaySingleLoginMethod(item),
          destroyInactiveTabPane: false,
          className: `bh-track-${item}-button`,
        }))}
      />
    ) : (
      displaySingleLoginMethod(ssoStatus === 'SSO_ERROR_USER_PROVISION_NOT_ALLOWED' ? 'sso' : preferredLoginMethod)
    )
  }

  const changeLangInUrl = (countryCode: string) => {
    const language = getLanguageCodeFromCountryCode(countryCode)
    savePreferredLanguage(language)
    setLanguage(language)
  }

  return (
    <SplitContainer>
      <ExternalContainer
        bhTrackClassName="bh-track-login-page-container"
        loading={loading}
        contentBelow={[
          <CountrySelect
            defaultCountry={countryCode}
            countries={availableCountries}
            handleLangChange={changeLangInUrl}
            key={1}
          />,
        ]}
        showAlertMessages
      >
        {showSessionExpiredMessage && (
          <Alert message={intl.formatMessage(page['page.login.sessionExpired'])} type="error" />
        )}
        {renderLoginView()}
      </ExternalContainer>
    </SplitContainer>
  )
}

export default LoginPanel
