import { api } from './utils'
import { Profile } from '../types/profile'
import { User, ChangePasswordRequest, UserData } from '../types/user'
import { FilterBody } from '../components/Filter/Filter'
import { QueryParams } from '../types/general'
import { Entity } from '../types/entity'

const BASE_PATH = 'barracks'

export const getUser = async (id: string): Promise<{ user: User }> => {
  const url = `/${BASE_PATH}/user/${id}`

  const response = await api.get(url)

  const user = (await response.json()) as User

  return {
    user,
  }
}

export const getProfileDetails = async (
  id: string
): Promise<{
  profile: Profile
}> => {
  const url = `/${BASE_PATH}/profile/${id}`

  const response = await api.get(url)

  const profile = (await response.json()) as Profile

  return {
    profile,
  }
}

export const changePassword = async (userId: string, data: ChangePasswordRequest): Promise<void> => {
  const url = `/zuul/password/${userId}`
  const body = {
    ...data,
  }

  await api.put(url, body)
}

export const updateUser = async (id: string, data: UserData): Promise<void> => {
  const url = `/${BASE_PATH}/user/${id}`
  const body = {
    ...data,
  }

  await api.put(url, body)
}

export const deleteAccount = async (entityId: string): Promise<void> => {
  const url = `/${BASE_PATH}/entity/${entityId}`

  await api.delete(url)
}

export const getAllUsersPerEntity = async (
  entityId: string,
  params?: QueryParams
): Promise<{
  users: UserData[]
  total?: number
}> => {
  const url = `/${BASE_PATH}/entity/${entityId}/user`

  const response = await api.get(url, params)

  const users = (await response.json()) as UserData[]
  const total = response.headers.get('x-total-count')

  return {
    users,
    total: Number.parseInt(total || '', 10),
  }
}

export const getAllProfilesPerEntity = async (
  entityId: string,
  params?: QueryParams
): Promise<{
  profiles: Profile[]
  total?: number
}> => {
  const url = `/${BASE_PATH}/entity/${entityId}/profile`

  const response = await api.get(url, params)

  const profiles = (await response.json()) as Profile[]
  const total = response.headers.get('x-total-count')

  return {
    profiles,
    total: Number.parseInt(total || '', 10),
  }
}

export const searchUsersByMultipleEntities = async (
  filter: FilterBody,
  params?: QueryParams
): Promise<{
  users: UserData[]
  total: number
}> => {
  const url = `/${BASE_PATH}/search`
  const body: FilterBody = {
    kind: 'user',
    ...filter,
  }

  const response = await api.post(url, body, params)

  const users = (await response.json()) as UserData[]
  const total = response.headers.get('x-total-count')

  return {
    users,
    total: Number.parseInt(total || '', 10),
  }
}

export const deleteUser = async (id: string): Promise<void> => {
  const url = `/${BASE_PATH}/user/${id}`

  await api.delete(url)
}

export const getUniqueUsers = async (
  params?: QueryParams
): Promise<{
  users: UserData[]
  total?: number
}> => {
  const url = `/${BASE_PATH}/user`

  const response = await api.get(url, params)

  const users = (await response.json()) as UserData[]
  const total = response.headers.get('x-total-count')

  return {
    users,
    total: Number.parseInt(total || '', 10),
  }
}

export const searchProfilesByMultipleUsers = async (
  filter: FilterBody,
  params?: QueryParams
): Promise<{
  profiles: Array<Profile>
  total?: number
}> => {
  const url = `/${BASE_PATH}/search`
  const body: FilterBody = {
    operator: 'and',
    ...filter,
  }

  const response = await api.post(url, body, params)

  const profiles = (await response.json()) as Array<Profile>
  const total = response.headers.get('x-total-count')

  return {
    profiles,
    total: Number.parseInt(total || '', 10),
  }
}

export const searchProfilesByEntityIDs = async (
  filter: FilterBody,
  params?: QueryParams
): Promise<{
  profiles: Array<Profile>
  total?: number
}> => {
  const url = `/${BASE_PATH}/search`
  const body: FilterBody = {
    ...filter,
  }

  const response = await api.post(url, body, params)

  const profiles = (await response.json()) as Array<Profile>
  const total = response.headers.get('x-total-count')

  return {
    profiles,
    total: Number.parseInt(total || '', 10),
  }
}

export const getAllEntities = async (
  params?: QueryParams
): Promise<{
  entities: Entity[]
  total?: number
}> => {
  const url = `/${BASE_PATH}/entity`

  const response = await api.get(url, params)
  const entities = (await response.json()) as Entity[]
  const total = response.headers.get('x-total-count')

  return {
    entities,
    total: Number.parseInt(total || '', 10),
  }
}

export const getEntityHierarchy = async (
  userId: string,
  params?: QueryParams
): Promise<{
  entities: Entity[]
  total?: number
}> => {
  const url = `/${BASE_PATH}/entity-hierarchy/user/${userId}`

  const response = await api.get(url, params)

  const entities = (await response.json()) as Entity[]
  const total = response.headers.get('x-total-count')

  return {
    entities,
    total: Number.parseInt(total || '', 10),
  }
}

export const getEntityHierarchyForEntity = async (
  entityId: string,
  params?: QueryParams
): Promise<{
  entity: Entity
  total?: number
}> => {
  const url = `/${BASE_PATH}/entity-hierarchy/entity/${entityId}`

  const response = await api.get(url, params)

  const entity = (await response.json()) as Entity
  const total = response.headers.get('x-total-count')

  return {
    entity,
    total: Number.parseInt(total || '', 10),
  }
}

export const createUser = async (data: UserData): Promise<void> => {
  const url = `/${BASE_PATH}/user`

  await api.post(url, data)
}

export const editUserRoles = async (userId: string, entityId: string, roles: string[]): Promise<void> => {
  const url = `/${BASE_PATH}/entity/${entityId}/user/${userId}/entity-roles`

  await api.patch(url, roles)
}

export const getEntityDetails = async (
  entityId: string
): Promise<{
  entity: Entity
  total?: number
}> => {
  const url = `/${BASE_PATH}/entity/${entityId}`

  const response = await api.get(url)

  const entity = (await response.json()) as Entity

  return {
    entity,
  }
}

export const createEntity = async (data: Partial<Entity>): Promise<void> => {
  const url = `/${BASE_PATH}/entity`

  await api.post(url, data)
}

export const updateEntity = async (id: string, data: Entity): Promise<void> => {
  const url = `/${BASE_PATH}/entity/${id}`
  const body = {
    ...data,
  }

  await api.put(url, body)
}

export const deleteEntity = async (id: string): Promise<void> => {
  const url = `/${BASE_PATH}/entity/${id}`

  await api.delete(url)
}
