import React, { useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import * as Sentry from '@sentry/react'
import { Carousel, Modal, Badge } from 'antd'
import { sortBy, differenceBy, uniqBy } from 'lodash'
import SelectPayments from './SelectPayments/SelectPayments'
import ReviewPayments from './ReviewPayments/ReviewPayments'
import SubmitPayments from '../components/SubmitPayments/SubmitPayments'
import SubmitProgress from '../components/SubmitProgress/SubmitProgress'
import PaymentInstructionDetails from '../PaymentInstructionDetails'
import PaymentInstructionTemplateDetails from '../PaymentInstructionTemplateDetails'
import ActionPage from 'components/ActionPage/ActionPage'
import * as piApi from 'api/paymentInstruction'
import { ArrowLeftOutlined } from '@ant-design/icons'
import {
  mapPaymentInstructionToPayment,
  mapTemplateToPayment,
  applyPaymentsFilter,
  getTotalNumberOfPages,
  sortPaymentsByKindAndSetActionType,
  sortPaymentsByKind,
} from '../Add/utils'
import {
  PaymentInstruction,
  PaymentInstructionTemplate,
  Set,
  Payment,
  PAYMENT_KIND,
  PAYMENT_STATE,
  SetActionsTypes,
  SetStates,
} from 'types/paymentInstruction'
import { Source } from 'types/source'
import { page, pi } from 'lang/definitions'
import { QueryParams } from 'types/general'
import { Approver } from 'types/user'
import Page, { MobileMenuOption } from 'components/Page/Page'
import { useHistory } from 'react-router-dom'
import { setDrawerHash } from 'components/Drawers/utils'
import { SCREEN_LG, Size } from 'utils/getDeviceType'
import { useWindowSize } from 'hooks/useWindowSize'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { FilterBody } from 'components/Filter/Filter'
import receiptIcon from 'assets/receipt.svg'
import { useActiveUser, useSetPolling } from 'hooks'
import { useCurrentPayment } from 'stores/Payment'
import { useCurrentPaymentEffects, useProgressSlide } from 'stores/Payment/hooks'
import { CarouselRef } from 'antd/lib/carousel'
import { useSession } from 'stores/session'
import useAddStyle from '../Add/Add.style'
import { cx } from 'antd-style'

const Review = (): React.JSX.Element => {
  const {
    state: { user },
  } = useSession()
  const { profileId, entityId } = useActiveUser()

  const carouselRef = useRef<CarouselRef>(null)
  const size: Size = useWindowSize()
  const history = useHistory()
  const intl = useIntl()
  const { styles } = useAddStyle()

  const { goToProgressSlide, goBackTwoSlides, goToSlide } = useProgressSlide(carouselRef)

  const {
    state: {
      currentPaymentsPage,
      selectedPayments,
      filteredPayments,
      paymentsFilter,
      payments,
      numberOfSignaturesRequired,
      paymentInstruction,
      paymentInstructions,
      paymentInstructionTemplate,
      paymentInstructionTemplates,
      disableReviewPage,
      paymentsInSet,
      currentPaymentsInSetPage,
      paymentsInSetOnCurrentPage,
      approveSet,
      approveAndPaySet,
      approversToShow,
      approvalRounds,
      slideNumber,
    },
    actions: {
      setCards,
      setCurrentPaymentsPage,
      setSelectedPayments,
      setFilteredPayments,
      setPayments,
      setNumberOfSignaturesRequired,
      setPaymentInstruction,
      setPaymentInstructions,
      setPaymentInstructionTemplate,
      setPaymentInstructionTemplates,
      setPaymentsInSet,
      setCurrentPaymentsInSetPage,
      resetState: resetGlobalState,
      setApproveSet,
      setApproveAndPaySet,
      setApproversToShow,
    },
  } = useCurrentPayment()

  useCurrentPaymentEffects()

  const { chargedSet, chargingSet, startPolling, requireActionSet, resetState: resetPoolingState } = useSetPolling()

  useEffect(() => {
    void initData(entityId)
  }, [user])

  useEffect(() => {
    let paymentIdsInSets: string[] = []

    if (approveSet) {
      paymentIdsInSets = [...approveSet.paymentInstructionIds!, ...approveSet.paymentInstructionTemplateIds!]
    }

    if (approveAndPaySet) {
      paymentIdsInSets = [
        ...paymentIdsInSets,
        ...approveAndPaySet.paymentInstructionIds!,
        ...approveAndPaySet.paymentInstructionTemplateIds!,
      ]
    }

    const paymentsInSet: Payment[] = []
    let paymentsOutOfSet: Payment[] = []

    if (!approveAndPaySet && !approveSet) {
      paymentsOutOfSet = [...payments]
    } else {
      payments.forEach((payment: Payment): void => {
        if (paymentIdsInSets.indexOf(payment.id) !== -1) {
          paymentsInSet.push(payment)
        } else {
          paymentsOutOfSet.push(payment)
        }
      })
    }

    setPaymentsInSet(paymentsInSet)
    const filteredPayments = applyPaymentsFilter(paymentsOutOfSet, paymentsFilter)
    setFilteredPayments(filteredPayments)
  }, [payments, paymentsFilter, approveSet, approveAndPaySet])

  const initData = async (entityId: string): Promise<void> => {
    if (!user) {
      return
    }

    try {
      // Look for SET in PROCESSING
      const processingSets = await piApi.searchSetByState({
        state: [SetStates.PROCESSING],
        profileId: [user.activeProfileId!],
      })

      const setsWithChargeGroup = processingSets.filter(
        (set) => set.chargeGroups?.length || set.piChargeGroupIds?.length
      )
      if (setsWithChargeGroup.length) {
        startPolling(setsWithChargeGroup[0])
        goToProgressSlide()

        return
      }

      const params: QueryParams = {
        limit: 0,
      }
      let paymentInstructions: PaymentInstruction[] = []
      let templates: PaymentInstructionTemplate[] = []

      const response = await Promise.all([
        piApi.getPaymentInstructionsRequiresSignature(entityId, params),
        piApi.getPaymentInstructionTemplatesRequiresSignature(entityId, params),
      ])

      paymentInstructions = response[0].filter((paymentInstruction: PaymentInstruction) => {
        const userIds = paymentInstruction?.signatures?.signedBy?.map((userRound: Approver[]) => {
          const currentRoundUserIds = userRound.map((user) => user.userId)
          return currentRoundUserIds
        })
        const flatUserIds = userIds?.flatMap((user) => user)
        return !flatUserIds?.some((userId: string) => userId === user.user.id)
      })

      templates = response[1].filter((template: PaymentInstructionTemplate) => {
        const userIds = template?.signatures?.signedBy?.map((userRound: Approver[]) => {
          const currentRoundUserIds = userRound.map((user) => user.userId)
          return currentRoundUserIds
        })
        const flatUserIds = userIds?.flatMap((user) => user)
        return !flatUserIds?.some((userId: string) => userId === user.user.id)
      })

      setPaymentInstructions(paymentInstructions)
      setPaymentInstructionTemplates(templates)

      const paymentsFromPaymentInstructions = paymentInstructions.map(mapPaymentInstructionToPayment)
      const paymentsFromTemplates = templates.map(mapTemplateToPayment)

      const sortedPayments = sortBy(
        [...paymentsFromPaymentInstructions, ...paymentsFromTemplates],
        (payment: Payment) => payment.dateDue
      )

      setPayments(sortedPayments)
      const sources: Source[] = sortedPayments
        .filter((payment: Payment) => !!payment.source)
        .map((payment: Payment) => payment.source!)

      if (sources.length) {
        setCards(uniqBy(sources, (source) => source.id))
      }
    } catch (error) {
      Sentry.captureException(error)
    }

    await getApproveSet()
    await getApproveAndPaySet()
  }

  const getApproversForRound = async (): Promise<void> => {
    const paymentInstructionIds = approveSet?.paymentInstructionIds || []
    const searchBody: FilterBody = {
      kind: 'paymentInstruction',
      operator: 'and',
      paymentInstructionId: paymentInstructionIds,
    }
    const { paymentInstructions } = await piApi.searchPaymentInstructions([searchBody])
    let currentRound = 0

    if (paymentInstructions) {
      const { 0: paymentInstruction } = paymentInstructions
      const signedByLength = paymentInstruction?.signatures?.signedBy?.length
      currentRound = (signedByLength || 0) + 2
    }

    const nextApprovalRound = approvalRounds?.find((round) => round.round === currentRound)

    if (nextApprovalRound?.users) {
      setApproversToShow(nextApprovalRound.users)
    }
  }

  useEffect(() => {
    void getApproversForRound()
  }, [approvalRounds, approveSet])

  const getApproveSet = async (): Promise<void> => {
    const requireSignatureState = 'require_signature'
    const additionalIdentifiers = {
      key: 'action',
      value: SetActionsTypes.APPROVE,
    }
    try {
      const set = await piApi.getSetByAdditionalIdentifiers(profileId, requireSignatureState, additionalIdentifiers)
      setApproveSet(set)
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const getApproveAndPaySet = async (): Promise<void> => {
    const requireSignatureState = 'require_signature'
    const additionalIdentifiers = {
      key: 'action',
      value: SetActionsTypes.PAY,
    }
    try {
      const set = await piApi.getSetByAdditionalIdentifiers(profileId, requireSignatureState, additionalIdentifiers)
      setApproveAndPaySet(set)
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const selectPayments = (payments: Payment[]): void => {
    const paymentsNotSignedByCurrentUser = payments.filter((payment: Payment) => {
      const index = payment?.signatures!.signedBy.findIndex((userRound: Approver[]) =>
        userRound.some((user) => user.id)
      )
      const isApprovedByCurrentUser = index !== -1
      return !isApprovedByCurrentUser
    })

    setSelectedPayments([...selectedPayments, ...paymentsNotSignedByCurrentUser])
  }

  const deselectPayments = (deselectedPayments: Payment[]): void => {
    const payments = differenceBy(selectedPayments, deselectedPayments, (payment: Payment) => payment.id)

    setSelectedPayments(payments)
  }
  const selectAllPayments = (): void => {
    const notSelectedPayments = differenceBy(filteredPayments, selectedPayments, (payment: Payment) => payment.id)
    selectPayments(notSelectedPayments)
  }

  const deselectAllPayments = (): void => {
    deselectPayments(filteredPayments)
  }

  const showPaymentDetails = (kind: PAYMENT_KIND, paymentId: string): void => {
    if (kind === PAYMENT_KIND.PAYMENT_INSTRUCTION) {
      const paymentInstruction = paymentInstructions.find((pi: PaymentInstruction) => pi.id! === paymentId)
      setPaymentInstruction(paymentInstruction)
      setPaymentInstructionTemplate(undefined)
      setDrawerHash(history, '#drawer-review-details-pi')
    } else {
      const template = paymentInstructionTemplates.find(
        (template: PaymentInstructionTemplate) => template.id! === paymentId
      )
      setPaymentInstructionTemplate(template)
      setPaymentInstruction(undefined)
      setDrawerHash(history, '#drawer-review-details-pi-template')
    }
  }

  const isOnlyPaymentOnPage = (page: Payment[], id: string): boolean => {
    return page.length === 1 && page[0].id === id
  }

  const removePaymentFromSet = async (kind: PAYMENT_KIND, paymentId: string): Promise<void> => {
    try {
      if (kind === PAYMENT_KIND.PAYMENT_INSTRUCTION) {
        // remove payment from approve set or approve and pay set
        if (approveSet && approveSet.paymentInstructionIds?.indexOf(paymentId) !== -1) {
          const updatedSet = await piApi.removePaymentInstructionFromSet(approveSet.id!, [paymentId])
          setApproveSet(updatedSet)
        } else {
          const updatedSet = await piApi.removePaymentInstructionFromSet(approveAndPaySet!.id!, [paymentId])
          setApproveAndPaySet(updatedSet)
        }
      } else {
        if (approveSet && approveSet.paymentInstructionTemplateIds?.indexOf(paymentId) !== -1) {
          const updatedSet = await piApi.removeTemplateFromSet(approveSet.id!, [paymentId])
          setApproveSet(updatedSet)
        } else {
          const updatedSet = await piApi.removeTemplateFromSet(approveAndPaySet!.id!, [paymentId])
          setApproveAndPaySet(updatedSet)
        }
      }
      if (isOnlyPaymentOnPage(paymentsInSetOnCurrentPage, paymentId) && currentPaymentsInSetPage > 1) {
        setCurrentPaymentsInSetPage(currentPaymentsInSetPage - 1)
      }
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const createSet = async (
    setActionType: SetActionsTypes,
    piIds: string[] = [],
    templateIds: string[] = []
  ): Promise<void> => {
    const newSet: Set = {
      profileId,
      paymentInstructionState: PAYMENT_STATE.REQUIRE_SIGNATURE,
      additionalIdentifiers: [
        {
          key: 'action',
          value: setActionType,
        },
      ],
    }

    if (piIds.length) {
      newSet.paymentInstructionIds = [...piIds]
    }
    if (templateIds.length) {
      newSet.paymentInstructionTemplateIds = [...templateIds]
    }

    try {
      const response = await piApi.createSet(newSet)

      if (setActionType === SetActionsTypes.APPROVE) {
        setApproveSet(response)
      } else {
        setApproveAndPaySet(response)
      }
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const addSelectedPaymentsToSet = async (): Promise<void> => {
    const { payPiIds, payTemplateIds, approvePiIds, approveTemplateIds } =
      sortPaymentsByKindAndSetActionType(selectedPayments)

    if (payPiIds.length || payTemplateIds.length) {
      if (approveAndPaySet) {
        try {
          if (payPiIds.length) {
            await piApi.addPaymentInstructionToSet(approveAndPaySet.id!, payPiIds)
          }

          if (payTemplateIds.length) {
            await piApi.addTemplateToSet(approveAndPaySet.id!, payTemplateIds)
          }

          void getApproveAndPaySet()
        } catch (error) {
          Sentry.captureException(error)
        }
      } else {
        await createSet(SetActionsTypes.PAY, payPiIds, payTemplateIds)
      }
    }
    if (approvePiIds.length || approveTemplateIds.length) {
      if (approveSet) {
        try {
          if (approvePiIds.length) {
            await piApi.addPaymentInstructionToSet(approveSet.id!, approvePiIds)
          }

          if (approveTemplateIds.length) {
            await piApi.addTemplateToSet(approveSet.id!, approveTemplateIds)
          }

          void getApproveSet()
        } catch (error) {
          Sentry.captureException(error)
        }
      } else {
        await createSet(SetActionsTypes.APPROVE, approvePiIds, approveTemplateIds)
      }
    }

    const numberOfRemainingPayments = filteredPayments.length - selectedPayments.length
    // how many pages are required for payments that are not selected
    const remainingNumberOfPages = getTotalNumberOfPages(numberOfRemainingPayments)
    // go to the last page if the current page no longer exists
    if (remainingNumberOfPages < currentPaymentsPage) {
      setCurrentPaymentsPage(remainingNumberOfPages)
    }

    setSelectedPayments([])
    if ((size.width || 0) < SCREEN_LG) {
      history.push('#review-payments')
    }
  }

  const getNumberOfSignaturesRequired = (paymentIds: string[]): void => {
    const paymentsFromSet = paymentIds.map(
      (id: string): Payment => payments.find((payment: Payment) => payment.id === id)!
    )

    let numberOfSignaturesRequired = 0

    paymentsFromSet
      .filter((payment: Payment) => !!payment)
      .forEach((payment: Payment) => {
        const { signatures: { requiredNumber = 0 } = {} } = payment
        if (requiredNumber > numberOfSignaturesRequired) {
          numberOfSignaturesRequired = requiredNumber
        }
      })

    setNumberOfSignaturesRequired(numberOfSignaturesRequired)
  }

  const handleGoBackAfterSubmit = (): void => {
    goBackTwoSlides()

    setTimeout(() => {
      resetState()
      void initData(entityId)
    }, 500)
  }

  const resetState = (): void => {
    resetGlobalState()
    resetPoolingState()
  }

  const mobileMenuOptions: Array<MobileMenuOption> = []

  const handleDeletePayment = (payment: Payment): void => {
    showDeleteConfirmationModal([payment])
  }

  // used to remove array of payments
  const deletePayments = async (payments: Payment[]): Promise<void> => {
    try {
      const { paymentInstructionIds, templateIds } = sortPaymentsByKind(payments)

      await Promise.all([
        ...paymentInstructionIds.map((paymentInstructionId: string) =>
          piApi.deletePaymentInstruction(paymentInstructionId)
        ),
        ...templateIds.map((templateId: string) => piApi.deletePaymentInstructionTemplate(templateId)),
      ])
      void initData(entityId)
    } catch (error) {
      Sentry.captureException(error)
    }
  }
  const showDeleteConfirmationModal = (payments: Payment[]) => {
    Modal.confirm({
      title: intl.formatMessage(pi['pi.modal.delete.header']),
      icon: <ExclamationCircleOutlined />,
      content: intl.formatMessage(pi['pi.modal.delete.text'], {
        numberOfPayments: payments.length,
      }),
      okText: intl.formatMessage(pi['pi.modal.button.confirmDelete']),
      cancelText: intl.formatMessage(pi['pi.modal.button.cancel']),
      onOk: () => deletePayments(payments),
      okButtonProps: {
        style: {
          backgroundColor: '#C15A5A',
          border: 'none',
          outline: 'none',
          fontWeight: 'bold',
        },
      },
    })
  }

  const handleSlide = () => {
    if (slideNumber === 0) {
      history.push('#make-payments')
    } else {
      history.goBack()
    }
  }

  const submitButton = (
    <SubmitPayments
      goToProgressSlide={goToProgressSlide}
      requireActionSet={requireActionSet}
      startPolling={startPolling}
      skipApproverSelection
    />
  )

  return (
    <Page
      title={intl.formatMessage(page['page.review.payments.page.title'])}
      mobileMenuOptions={mobileMenuOptions}
      goToSlide={goToSlide}
      numberOfPayments={payments ? payments.length : 0}
    >
      <div className={styles.payContainer}>
        {approveAndPaySet?.paymentInstructionIds && approveAndPaySet?.paymentInstructionIds?.length ? (
          <span onClick={() => handleSlide()}>
            {slideNumber ? (
              <ArrowLeftOutlined className={styles.backBtn} />
            ) : (
              <Badge count={approveAndPaySet?.paymentInstructionIds?.length} className={styles.mobilePaymentCounts}>
                <img className={styles.receiptIcon} src={receiptIcon} />
              </Badge>
            )}
          </span>
        ) : null}
        <Carousel
          ref={carouselRef}
          className={cx(styles.payCarousel, styles.review)}
          slidesToShow={2}
          dots={false}
          infinite={false}
          swipeToSlide={false}
          swipe={false}
          responsive={[
            {
              breakpoint: SCREEN_LG,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
              },
            },
          ]}
        >
          <div className={cx(styles.payContainerStep, styles.scrollable, styles.disableScrollbars)}>
            <SelectPayments
              totalNumberOfPayments={filteredPayments.length}
              addSelectedPaymentsToSet={() => void addSelectedPaymentsToSet()}
              selectPayments={selectPayments}
              selectAllPayments={selectAllPayments}
              deselectPayments={deselectPayments}
              deselectAllPayments={deselectAllPayments}
              showPaymentDetails={showPaymentDetails}
              deletePayment={handleDeletePayment}
            />
          </div>

          <div
            className={cx(styles.payContainerStep, styles.scrollable, styles.disableScrollbars, {
              [styles.disabled]: disableReviewPage,
            })}
          >
            <ReviewPayments
              totalNumberOfPayments={paymentsInSet.length}
              numberOfSignaturesRequired={numberOfSignaturesRequired}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              removePaymentFromSet={removePaymentFromSet}
              showPaymentDetails={showPaymentDetails}
              getNumberOfSignaturesRequired={getNumberOfSignaturesRequired}
              goToProgressSlide={goToProgressSlide}
              approvers={approversToShow}
              submitButton={submitButton}
              requireActionSet={requireActionSet}
              startPolling={startPolling}
            />
          </div>
          <div className={cx(styles.payContainerStep, styles.scrollable, styles.disableScrollbars)}>
            <SubmitProgress
              chargingSet={chargingSet}
              chargedSet={chargedSet}
              goBackAfterSubmit={handleGoBackAfterSubmit}
            />
          </div>
        </Carousel>
        <ActionPage
          title={intl.formatMessage(page['page.pi.slider.label.detail'])}
          hash="#drawer-review-details-pi"
          pathname={history.location.pathname}
        >
          {paymentInstruction && <PaymentInstructionDetails paymentInstructionId={paymentInstruction.id!} />}
        </ActionPage>
        <ActionPage
          title={intl.formatMessage(page['page.pi.slider.label.detail'])}
          hash="#drawer-review-details-pi-template"
          pathname={history.location.pathname}
        >
          {paymentInstructionTemplate && (
            <PaymentInstructionTemplateDetails templateId={paymentInstructionTemplate.id!} />
          )}
        </ActionPage>
      </div>
    </Page>
  )
}

export default Review
