import React, { useEffect, useState } from 'react'
import { ProfileGroup } from '../../../../types/profileGroups'
import * as usersApi from '../../../../api/user'
import { Checkbox, Col, Collapse, notification, Row, Spin } from 'antd'
import { useIntl } from 'react-intl'
import { getEntityRules } from '../../../../api/rules'
import { Profile } from '../../../../types/profile'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import * as Sentry from '@sentry/react'
import { entity } from '../../../../lang/definitions'
import { renderRoleLabel } from './utils'
import { useOrganisationHierarchy, useOrganisationHierarchyUtils } from 'stores/OrganisationHierarchy/hooks'

const { Panel } = Collapse

interface GroupRole {
  profileGroupId: string
  roles: Array<string>
}

const ProfileGroupRoles = (): React.JSX.Element => {
  const intl = useIntl()

  const [activeRoles, setActiveRoles] = useState<GroupRole[]>([])
  const [availableRoles, setAvailableRoles] = useState<GroupRole[]>([])

  const {
    state: { selectedUser, profileGroups, profiles, isFetchingProfileGroups: isLoading },
  } = useOrganisationHierarchy()

  const { fetchProfileGroups } = useOrganisationHierarchyUtils()

  useEffect(() => {
    if (profileGroups.length) {
      void fetchAvailableRoles(profileGroups)
    }
  }, [profileGroups])

  useEffect(() => {
    if (profiles.length) {
      void handleActiveGroupRoles(profiles)
    }
  }, [profiles])

  const fetchAvailableRoles = async (profileGroups: ProfileGroup[]): Promise<void> => {
    const availableRoles: GroupRole[] = await Promise.all(
      profileGroups.map(async (group: ProfileGroup) => {
        const rules = await getEntityRules(group.entityId!)
        const availableRoles = rules.logic.availableRoles.rule

        const roles = Object.keys(availableRoles).filter((key) => availableRoles[key])
        return {
          profileGroupId: group.id,
          roles,
        }
      })
    )

    setAvailableRoles(availableRoles)
  }

  const handleActiveGroupRoles = (profiles: Profile[]): void => {
    const profilesWithGroupId = profiles.filter((profile) => profile.profileGroupIds)

    const activeRoles = profilesWithGroupId.flatMap((profile) => {
      const rolesWithGroups: GroupRole[] = profile.profileGroupIds!.map((groupId) => ({
        profileGroupId: groupId,
        roles: profile.entityRoles,
      }))
      return rolesWithGroups
    })

    setActiveRoles(activeRoles)
  }

  const updateGroupRole = async (group: ProfileGroup, role: string, checked: boolean): Promise<void> => {
    if (!selectedUser) return
    try {
      const currentGroupRole = activeRoles.find((groupRole) => groupRole.profileGroupId === group.id)
      const existingRoles = currentGroupRole?.roles || []
      if (checked) {
        const rolesToAdd = [...existingRoles, role]
        await usersApi.editUserRoles(selectedUser.id!, group.entityId!, rolesToAdd)
      } else {
        const filteredRoles = existingRoles.filter((currentRole) => currentRole !== role)
        await usersApi.editUserRoles(selectedUser.id!, group.entityId!, filteredRoles)
      }
      await fetchProfileGroups(selectedUser)
    } catch (error) {
      notification.error({
        message: intl.formatMessage(entity['profile.groups.role.update.error.label']),
        placement: 'topRight',
      })
      Sentry.captureException(error)
    }
  }

  const isRoleChecked = (role: string, profileGroupId: string): boolean => {
    const isActive = activeRoles.some(
      (groupRole) => groupRole.profileGroupId === profileGroupId && groupRole.roles.includes(role)
    )

    return isActive
  }

  const renderRoles = (group: ProfileGroup): React.ReactNode => {
    const groupRole = availableRoles.find((groupRoles) => groupRoles.profileGroupId === group.id)
    return groupRole ? (
      <React.Fragment>
        {groupRole.roles.map((role) => (
          <Row className="entity-role-row" key={`row-${groupRole.profileGroupId}-${role}`}>
            <Col className="entity-roles-labels" span={8}>
              {renderRoleLabel(role, intl)}
            </Col>
            <Col className="role-checkbox-wrapper" span={12}>
              <Checkbox
                onChange={(event: CheckboxChangeEvent) => void updateGroupRole(group, role, event.target.checked)}
                checked={isRoleChecked(role, group.id)}
              ></Checkbox>
            </Col>
          </Row>
        ))}
      </React.Fragment>
    ) : (
      <React.Fragment />
    )
  }
  return (
    <Spin spinning={isLoading}>
      {profileGroups.length > 0 && (
        <div className="profile-group-roles">
          <span className="profile-group-label">{intl.formatMessage(entity['profile.groups.title'])}</span>
          <Collapse expandIconPosition="end">
            {profileGroups.map((group: ProfileGroup) => (
              <Panel key={group.id} header={group.title}>
                <div className="entity-roles-wrapper">{renderRoles(group)}</div>
              </Panel>
            ))}
          </Collapse>
        </div>
      )}
    </Spin>
  )
}

export default ProfileGroupRoles
