import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css, token }) => {
  return {
    dateDueSelectorContainer: css`
      .ant-picker-calendar-header .ant-select.ant-select-single.ant-select-show-arrow {
        width: 100px;
      }
      tbody {
        td {
          padding: 0;

          &::before {
            display: none;
          }
        }
      }
    `,
    bankDaysContainer: css`
      background-color: transparent;
    `,
    updateButtonContainer: css`
      width: 100%;
    `,
    updateButton: css`
      font-weight: 500;
    `,
    selected: css`
      background-color: #d9d9d9;
      color: inherit;
    `,
    selectedMonth: css`
      background-color: ${token.colorPrimary};
      color: white;

      &:hover {
        background-color: ${token.colorPrimary};
      }
    `,
    disabled: css`
      cursor: not-allowed;
      color: rgba(0, 0, 0, 0.2);
    `,
    bankDaysDate: css`
      display: flex;
      align-items: center;
      margin: 10px;
    `,
    bankDate: css`
      background-color: #1890ff;
      color: white;
    `,
    red: css`
      background-color: #ff85c0;
    `,
    date: css`
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 200;
      width: 24px;
      height: 24px;
      user-select: none;
      border-radius: 10px;
    `,
    monthDate: css`
      padding: 2px 5px;
      border-radius: 10px;

      &:hover {
        background-color: #f5f5f5;
      }
    `,
    calendarCell: css`
      display: flex;
      justify-content: center;
      align-items: center;
      width: 45px;
      height: 41px;
      z-index: 200;
    `,
    calendarCellMonth: css`
      display: flex;
      justify-content: center;
      align-items: center;
    `,
    cardNetwork: css`
      margin-left: 10px;
    `,
    bankDateDay: css`
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      width: 24px;
      color: #fff;
      font-weight: 500;
      background-color: #1890ff;
      border-radius: 10px;
    `,
  }
})

export default useStyles
