import { validateSession } from '../api/auth'
import { removeAuthenticationStatus, removeIsProfileChosen, removeSessionId, saveAuthenticationStatus } from './storage'

export const validateSessionId = async (): Promise<boolean> => {
  try {
    const validSession = await validateSession()
    if (!validSession) {
      removeSessionId()
      removeAuthenticationStatus()
      removeIsProfileChosen()
      return false
    } else saveAuthenticationStatus()

    return true
  } catch (error) {
    removeSessionId()
    removeAuthenticationStatus()
    return false
  }
}
