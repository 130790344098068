import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'

dayjs.extend(customParseFormat)
dayjs.extend(utc)

interface DateFormats {
  [key: string]: string
}

export const getLocaleDateStringFormat = (locale: string): string => {
  const formats: DateFormats = {
    'en-GB': 'DD/MM/YYYY',
    'en-IE': 'DD/MM/YYYY',
    'fi-FI': 'D.M.YYYY',
    'it-IT': 'DD/MM/YYYY',
    'nl-NL': 'D-M-YYYY',
    'sv-SE': 'YYYY-MM-DD',
  }

  return formats[locale] || 'DD/MM/YYYY'
}

export const formatLocaleDateString = (value: string | null, locale: string): string => {
  if (!value) return ''
  return dayjs(value).format(getLocaleDateStringFormat(locale))
}
