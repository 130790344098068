import { Button } from 'antd'
import React from 'react'
import { WarningFilled } from '@ant-design/icons'

interface WarningBoxProps {
  title: string
  children: React.JSX.Element
  action?: () => void
  actionTitle?: string
}

const WarningBox = ({ title, action, children, actionTitle }: WarningBoxProps): React.JSX.Element => {
  const warningIcon = <WarningFilled />
  return (
    <div className="warning-box-wrapper">
      <div className="header-wrapper">
        <div className="title-wrapper">
          <div className="warning-icon">{warningIcon}</div>
          <div className="warning-title">
            <span>{title}</span>
          </div>
        </div>
        {actionTitle && (
          <div className="action">
            <Button type="text" onClick={action}>
              {actionTitle}
            </Button>
          </div>
        )}
      </div>
      <div className="content-wrapper">{children}</div>
    </div>
  )
}

export default WarningBox
