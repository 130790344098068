import React from 'react'
import { formatLocale, FormatLocaleProps } from 'utils/formatLocale'

const Date = (props: FormatLocaleProps): React.JSX.Element => {
  const renderDate = () => {
    const finalDate = formatLocale('date', props)
    return <span>{finalDate}</span>
  }

  return <span>{renderDate()}</span>
}

export default Date
