import { defineMessages } from 'react-intl'

export const password = defineMessages({
  'password.policy.minLength': {
    id: 'password.policy.minLength',
    defaultMessage: 'Min length {value} character ',
    description: 'number of minimum char for password',
    error: false,
  },
  'password.policy.maxLength': {
    id: 'password.policy.maxLength',
    defaultMessage: 'Max {value} characters',
  },
  'password.policy.minAlpha': {
    id: 'password.policy.minAplha',
    defaultMessage: 'Min {value} letter',
  },
  'password.policy.minNumeric': {
    id: 'password.policy.minNumeric',
    defaultMessage: 'Min {value} digit',
  },
  'password.policy.minSpecial': {
    id: 'password.policy.minSpecial',
    defaultMessage: 'Min {value} special character (eg. %#&/)',
  },
  'password.requestReset.title': {
    id: 'password.requestReset.title',
    defaultMessage: 'Reset Password',
    description: 'Request reset password mail title',
  },
  'password.requestReset.paragraph': {
    id: 'password.requestReset.paragraph',
    defaultMessage:
      'Enter email address that you’ve registered at Billhop and we’ll send you instructions on how to reset your password',
    description: 'Request reset password mail paragraph text',
  },
  'password.requestReset.form.email.label': {
    id: 'password.requestReset.form.email.label',
    defaultMessage: 'Email address',
    description: 'request new password form email label',
  },
  'password.requestReset.form.error.enter.email': {
    id: 'password.requestReset.form.error.enter.email',
    defaultMessage: 'Please enter a email',
    description: 'Message to ask user to enter email',
  },
  'password.requestReset.form.error.finding.email': {
    id: 'password.requestReset.form.error.finding.email',
    defaultMessage: 'Email not found!',
    description: 'Message when email not found in db',
  },

  'password.requestReset.form.submit.button': {
    id: 'password.requestReset.form.submit.button',
    defaultMessage: 'Send me the info',
    description: 'submit button text',
  },
  'password.requestReset.footer.login.link': {
    id: 'password.requestReset.footer.login.link',
    defaultMessage: 'I got it! Log in instead',
    description: 'back to login description in footer',
  },
  'password.requestReset.sucess.title': {
    id: 'password.requestReset.sucess.title',
    defaultMessage: 'If user exists, an email for your password reset will be sent!',
    description: 'title when email successfully sent',
  },
  'password.requestReset.sucess.subTitle': {
    id: 'password.requestReset.sucess.subTitle',
    defaultMessage: 'Check your email',
    description: 'sub-title when email successfully sent',
  },
  'password.requestReset.form.backButton': {
    id: 'password.requestReset.form.backButton',
    defaultMessage: 'Back to login',
    description: 'Back to login button label when email successfully sent on requestReset password page',
  },
  'password.reset.title': {
    id: 'password.reset.title',
    defaultMessage: 'Choose your password',
    description: 'resetPassword title',
  },
  'password.reset.form.error.finding.password': {
    id: 'password.reset.form.error.finding.password',
    defaultMessage: 'Please input a password!',
    description: 'Message when new password is missing',
  },
  'password.reset.form.error.finding.confirmPassword': {
    id: 'password.reset.form.error.finding.confirmPassword',
    defaultMessage: 'Please confirm password!',
    description: 'Message when confirm password is missing',
  },
  'password.reset.form.error.dontMatch.passwords': {
    id: 'password.reset.form.error.dontMatch.passwords',
    defaultMessage: 'Passwords dont match',
    description: 'Message when new password and confirm password not match/ are equal',
  },
  'password.reset.form.newPassword.label': {
    id: 'password.reset.form.newPassword.label',
    defaultMessage: 'New password',
    description: 'reset form new password label',
  },
  'password.reset.form.confirmPassword.label': {
    id: 'password.reset.form.confirmPassword.label',
    defaultMessage: 'Confirm password',
    description: 'reset form confirm password label',
  },
  'password.reset.form.submit.button': {
    id: 'password.reset.form.submit.button',
    defaultMessage: 'Send',
    description: 'reset form submit button text',
  },
  'password.reset.form.backButton': {
    id: 'password.reset.form.backButton',
    defaultMessage: 'Back to login',
    description: 'Back to login button label when password successfully changed',
  },
  'password.reset.success.title': {
    id: 'password.reset.success.title',
    defaultMessage: 'Password changed successfully!',
    description: 'title when password successfully changed',
  },
  'password.update.required.text': {
    id: 'password.update.required.text',
    defaultMessage:
      "Your organization's password policy required you to update your password for Billhop account before you can continue using this service.",
    description: 'Description text for force password recreation',
  },
})
