import React from 'react'
import { Form, Input } from 'antd'
import { useTranslation } from '../../../../utils/helpers'
import useSuffix from './useSuffix'
import { GenericMaxLength, useLocalAddressValidator } from 'utils/validators'

export default function AddressApartment(): React.JSX.Element {
  const t = useTranslation()
  const AddressApartmentSuffix = useSuffix({ fieldName: ['address', 'apartment'], maxLength: GenericMaxLength })
  const addressValidator = useLocalAddressValidator('apartment')

  return (
    <Form.Item
      label={t('beneficiary.form.ach.apartment.label.placeholder')}
      name={['address', 'apartment']}
      validateTrigger="onChange"
      hasFeedback
      rules={[
        {
          validator: addressValidator,
        },
      ]}
    >
      <Input
        type="text"
        size="large"
        data-testid="apartment-input-field"
        suffix={AddressApartmentSuffix}
        placeholder={t('beneficiary.form.ach.apartment.label.placeholder')}
        maxLength={GenericMaxLength}
      />
    </Form.Item>
  )
}
