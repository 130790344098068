import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css, token, responsive }) => ({
  cardWrapper: css`
    background: white;
    border-radius: 10px;
    padding: 16px;
    margin-bottom: 16px;
  `,
  header: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  title: css`
    font-size: ${token.fontSize}px;
  `,
  editLabel: css`
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  `,
  content: css`
    margin-top: 16px;
  `,
  actionLinks: css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 20px 0px 40px;

    > a {
      margin-right: 16px;

      &:hover {
        text-decoration: underline;
      }

      ${responsive.md} {
        margin-right: 8px;
      }
    }
  `,
  default: css`
    color: rgba(0, 0, 0, 0.85);
  `,
  danger: css`
    color: ${token.colorError};
  `,
}))

export default useStyles
