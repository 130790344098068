import { Message } from 'utils/init'
import { api } from './utils'

const BASE_PATH = 'external/content'

export const getStrings = async (locale: string): Promise<Message> => {
  const url = `/${BASE_PATH}/${locale}/client/strings`
  const response = await api.get(url)
  return await response.json()
}
