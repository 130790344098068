import React, { useEffect } from 'react'
import { Form, FormInstance } from 'antd'
import { RecipientTitle, RoutingNumber, AccountNumber, TypeSelector } from './FormItems'
import { SpaceForm } from '../components'
import { useTranslation } from '../../../utils/helpers'
import {
  useSwiftAccountNumberValidator,
  AccountNumberMaxLength,
  useLocalUsRoutingNumberValidator,
  UsRoutingNumberMaxLength,
} from 'utils/validators'

interface ABAProps {
  getFormInstance?: (form: FormInstance) => void
  onFinish?: (values: ABAForm) => void
}

export type ABAForm = {
  title: string
  accountNumber: string
  type: string
  routingNumber: string
}

const ABA = ({ getFormInstance, onFinish }: ABAProps): React.JSX.Element => {
  const t = useTranslation()
  const [abaForm] = Form.useForm<ABAForm>()

  const swiftAccountNumberValidator = useSwiftAccountNumberValidator()
  const abaRoutingNumberValidator = useLocalUsRoutingNumberValidator()

  useEffect(() => {
    getFormInstance?.(abaForm)
  }, [abaForm])

  return (
    <Form form={abaForm} requiredMark={false} onFinish={onFinish} layout="vertical" name="swift">
      <SpaceForm>
        <RecipientTitle />

        <RoutingNumber
          label={t('beneficiary.aba.routingNumber')}
          validator={abaRoutingNumberValidator}
          maxLength={UsRoutingNumberMaxLength}
        />

        <AccountNumber
          label={t('beneficiary.accountNumber')}
          validator={swiftAccountNumberValidator}
          maxLength={AccountNumberMaxLength}
        />

        <TypeSelector type="beneficiary" />
      </SpaceForm>

      <button type="submit" style={{ display: 'none' }} />
    </Form>
  )
}

export default ABA
