import { Form, Select } from 'antd'
import { useTranslation } from 'utils/helpers'
import { PaymentTypeRules } from 'types/beneficiary'
import countryToCurrency from 'country-to-currency'
import { useSession } from 'stores/session'

interface BeneficiaryDetailsProps {
  availablePaymentTypes: PaymentTypeRules
}

const CurrencySelect = ({
  availablePaymentTypes: availablePaymentMethods,
}: BeneficiaryDetailsProps): React.JSX.Element => {
  const {
    state: { rules },
  } = useSession()
  const form = Form.useFormInstance()
  const t = useTranslation()

  const onCurrencyChange = (value: string) => {
    form.setFieldValue('paymentType', availablePaymentMethods[value]?.defaultPaymentType)
  }

  const convertCurrencyToCountry = (currency: string) => {
    if (currency.toUpperCase() === 'EUR') {
      // We want the European flag for currency EUR
      return 'eu'
    }

    if (currency.toUpperCase() === 'USD') {
      // We want the US flag for currency USD
      return 'us'
    }

    const item = Object.entries(countryToCurrency).find(([, countryCurrency]) => {
      return typeof countryCurrency === 'string' && countryCurrency.toLowerCase() === currency.toLowerCase()
    })

    if (item) {
      return item[0].toLowerCase()
    }
  }

  const setCountryFlag = (currency: string) => {
    const countryCode = convertCurrencyToCountry(currency)

    if (!countryCode) return null

    return (
      <img
        style={{ height: 12, marginRight: 5, marginBottom: 1 }}
        src={`https://flagcdn.com/84x63/${countryCode}.png`}
        srcSet={`https://flagcdn.com/168x126/${countryCode}.png 2x, https://flagcdn.com/252x189/${countryCode}.png 3x`}
        alt={countryCode}
        data-testid="currency-flag"
      />
    )
  }

  const getProfileCurrencies = (): string[] => {
    const currencies = new Set<string>()

    rules?.logic?.clearingNetworks?.rule?.map((rule) => {
      rule.currencies.map((currency) => {
        currencies.add(currency)
      })
    })

    const sortedArrayOfCurrencies = Array.from(currencies).sort((a, b) =>
      t(`beneficiary.currency.${a}`).localeCompare(t(`beneficiary.currency.${b}`))
    )

    return sortedArrayOfCurrencies
  }

  return (
    <Form.Item label={t('beneficiary.currency.label')} name="currency">
      <Select
        className="bh-select"
        showSearch
        optionFilterProp="children"
        onChange={onCurrencyChange}
        size="large"
        data-testid="currency-select"
        filterOption={(input: string, option: unknown): boolean => {
          const { key, value } = option as {
            key: string
            value: string
          }
          return (
            key.toLowerCase().startsWith(input.toLowerCase()) || value.toLowerCase().startsWith(input.toLowerCase())
          )
        }}
      >
        {getProfileCurrencies().map((currency: string) => {
          return (
            <Select.Option
              value={currency}
              key={t(`beneficiary.currency.${currency}`)}
              data-testid="currency-select-option"
            >
              {setCountryFlag(currency)}
              {t(`beneficiary.currency.${currency}`)}
            </Select.Option>
          )
        })}
      </Select>
    </Form.Item>
  )
}

export default CurrencySelect
