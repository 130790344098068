import { FAQ } from 'types/faq'
import { api } from './utils'

const BASE_PATH = 'c3po'

export const getFaq = async (): Promise<FAQ> => {
  const url = `/${BASE_PATH}/content/faq`
  const response = await api.get(url)
  return await response.json()
}
