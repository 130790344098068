import { defineMessages } from 'react-intl'

export const action = defineMessages({
  'action.pi.newpayment': {
    id: 'action.pi.newpayment',
    defaultMessage: '+ New payment',
    description: 'Label for the New PI button in right action list',
  },
  'action.pi.new.payment': {
    id: 'action.pi.new.payment',
    defaultMessage: 'New payment',
    description: 'Label for the New PI button in right action list',
  },
  'action.pi.makepayments': {
    id: 'action.pi.makepayments',
    defaultMessage: 'Make payments',
    description: 'Label for make payments button in right action list',
  },
  'action.pi.reviewpayments': {
    id: 'action.pi.reviewpayments',
    defaultMessage: 'Review payments',
    description: 'Label for review payments button in right action list',
  },
  'action.pi.reports': {
    id: 'action.pi.reports',
    defaultMessage: 'Reports',
    description: 'Label for the Reports action in right action list',
  },
  'action.pi.download': {
    id: 'action.pi.download',
    defaultMessage: 'Download',
    description: 'Label for the Download action in right action list',
  },
  'action.pi.delete': {
    id: 'action.pi.delete',
    defaultMessage: 'Delete',
    description: 'Label for the Delete action in right action list',
  },
  'action.pi.stop': {
    id: 'action.pi.stop',
    defaultMessage: 'Stop',
    description: 'Label for the Stop (previously Delete) action in right action list',
  },
  'action.pi.change': {
    id: 'action.pi.change',
    defaultMessage: 'Change',
    description: 'Label for Change action in right action list',
  },
  'action.pi.cancel': {
    id: 'action.pi.cancel',
    defaultMessage: 'Cancel',
    description: 'Label for Cancel action in right action list',
  },
  'action.pi.refund': {
    id: 'action.pi.refund',
    defaultMessage: 'Refund',
    description: 'Label for the Refund action in right action list',
  },
  'action.label': {
    id: 'action.label',
    defaultMessage: 'Actions',
    description: 'Label for Actions button (mobile)',
  },
  'action.pi.page.title': {
    id: 'action.pi.page.title',
    defaultMessage: 'Manage your payments',
    description: 'Title for Actions PI page (mobile)',
  },
  'action.beneficiary.add': {
    id: 'action.beneficiary.add',
    defaultMessage: 'Add beneficiary',
    description: 'Label for the Add beneficiary action in right action list',
  },
  'action.beneficiary.page.title': {
    id: 'action.beneficiary.page.title',
    defaultMessage: 'Manage your beneficiaries',
    description: 'Title for Actions beneficiary page (mobile)',
  },
  'action.table.edit': {
    id: 'action.table.edit',
    defaultMessage: 'Edit',
    description: 'Label for edit action in a table',
  },
  'action.table.details': {
    id: 'action.table.details',
    defaultMessage: 'Details',
    description: 'Label for details action in a table',
  },
  'action.table.delete': {
    id: 'action.table.delete',
    defaultMessage: 'Delete',
    description: 'Label for delete action in a table',
  },
  'action.table.share': {
    id: 'action.table.share',
    defaultMessage: 'Share',
    description: 'Label for share action in a table',
  },
  'action.table.refund': {
    id: 'action.table.refund',
    defaultMessage: 'Refund',
    description: 'Label for refund action in a table',
  },
  'action.settings.privacyPolicy': {
    id: 'action.settings.privacyPolicy',
    defaultMessage: 'Privacy policy',
    description: 'Label for the privacy policy link in right action list',
  },
  'action.settings.terms': {
    id: 'action.settings.terms',
    defaultMessage: 'Terms of service',
    description: 'Label for the terms of service link in right action list',
  },
  'action.settings.cookiePolicy': {
    id: 'action.settings.cookiePolicy',
    defaultMessage: 'Cookie policy',
    description: 'Label for the cookie policy link in right action list',
  },
  'action.settings.downloadMyData': {
    id: 'action.settings.downloadMyData',
    defaultMessage: 'Download my data',
    description: 'Label for the download my data link in right action list',
  },
  'action.settings.deleteMyAccount': {
    id: 'action.settings.deleteMyAccount',
    defaultMessage: 'Delete my account',
    description: 'Label for the delete my account link in right action list',
  },
  'action.settings.manageAccount': {
    id: 'action.settings.manageAccount',
    defaultMessage: 'Manage your account',
    description: 'Label for the Manage account page (mobile)',
  },
  'action.settings.uploadDocument': {
    id: 'action.settings.uploadDocument',
    defaultMessage: 'Upload',
    description: 'Label for the document upload button in settings',
  },
  'action.settings.uploadDocuments.text': {
    id: 'action.settings.uploadDocuments.text',
    defaultMessage: 'Upload Document',
    description: 'Label for the document upload drawer in settings',
  },
  'action.admin.user.add': {
    id: 'action.admin.user.add',
    defaultMessage: 'Add User',
    description: 'Label for the Add user action in right action list',
  },
  'action.admin.user.edit': {
    id: 'action.admin.user.edit',
    defaultMessage: 'Edit User',
    description: 'Label for the Edit user drawer title',
  },
  'action.admin.user.details': {
    id: 'action.admin.user.details',
    defaultMessage: 'User Details',
    description: 'Label for the user details drawer title',
  },
  'action.admin.user.change': {
    id: 'action.admin.user.change',
    defaultMessage: 'Change',
    description: 'Label for the change entity roles action in entity hierarchy table',
  },
  'action.admin.user.save': {
    id: 'action.admin.user.save',
    defaultMessage: 'Save',
    description: 'Label for the save entity roles action in entity hierarchy table',
  },
  'action.admin.user.cancel': {
    id: 'action.admin.user.cancel',
    defaultMessage: 'Cancel',
    description: 'Label for the cancel entity roles action in entity hierarchy table',
  },
  'action.card.add': {
    id: 'action.card.add',
    defaultMessage: 'Add Card',
    description: 'Label for the Add card action',
  },
  'action.card.mobileMenu.label': {
    id: 'action.card.mobileMenu.label',
    defaultMessage: 'Manage your cards',
    description: 'Label for mobile action menu label',
  },
  'action.pi.page.second.action.title': {
    id: 'action.pi.page.second.action.title',
    defaultMessage: 'Manage your payments',
    description: 'Title for Actions PI page (mobile)',
  },
  'action.pi.page.download.progress': {
    id: 'action.pi.page.download.progress',
    defaultMessage: 'Downloading...',
    description: 'Description for pi download progress',
  },
  'action.pi.page.download.finished': {
    id: 'action.pi.page.download.finished',
    defaultMessage: 'Downloaded!',
    description: 'Description for pi download finished',
  },
  'action.reports.page.template.useTemplate': {
    id: 'action.reports.page.template.useTemplate',
    defaultMessage: 'Use Template',
    description: 'Description for use Template button in templates table',
  },
  'action.reports.page.template.editTemplate': {
    id: 'action.reports.page.template.editTemplate',
    defaultMessage: 'Edit',
    description: 'Description for edit Template button in templates table',
  },
  'action.reports.page.template.deleteTemplate': {
    id: 'action.reports.page.template.deleteTemplate',
    defaultMessage: 'Delete',
    description: 'Description for delete Template button in templates table',
  },
  'action.reports.page.template.form.updateTemplate': {
    id: 'action.reports.page.template.form.updateTemplate',
    defaultMessage: 'save',
    description: 'Description for save Template button in  edit report template form',
  },
  'action.reports.page.template.form.createTemplate': {
    id: 'action.reports.page.template.form.createTemplate',
    defaultMessage: 'Create Template',
    description: 'Description for create Template button in report template form',
  },
})
