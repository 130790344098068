import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css, token, responsive, cx }) => {
  const actionMenuContainer = css`
    position: relative;
    height: 100%;
    width: 180px;
    padding-bottom: ${token.paddingLG}px;
    background-color: transparent;
    display: none;

    ${responsive.sm} {
      display: none;
    }
  `
  const open = css`
    display: block;
    z-index: 1;
    position: absolute;
    height: 100%;
    width: 100%;
    padding: ${token.paddingMD}px;
    top: 0;
    left: 0;
    background-color: white;
  `
  const closeButton = css`
    margin-top: ${token.marginXS}px;

    button {
      background: transparent;
      border: none;
      outline: none;
      width: 20px;
      height: 20px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  `
  const label = css`
    h2 {
      font-weight: 500;
    }
  `
  const actionMenuHeader = css`
    display: none;

    ${responsive.sm} {
      display: flex;
      justify-content: space-between;
    }
  `
  const action = css`
    padding: 0;
    margin-right: ${token.marginLG}px;

    .ant-badge {
      width: 100%;
    }

    .ant-badge-count {
      top: 4px;
      right: 5px;
      padding-top: 0px;
    }

    button {
      display: flex;
      align-items: center;
    }

    button span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `
  const actions = css`
    margin: 0;
    list-style: none;
    padding: 0;
    display: flex;
  `

  const pageContainer = css`
    height: 100%;
    overflow: auto;
  `

  const mobileMenu = css`
    display: none;

    ${responsive.sm} {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
    }
  `

  const rightMenu = css`
    display: flex;
    flex-direction: row-reverse;
  `

  const column = css`
    flex-direction: column;

    > .${cx(rightMenu)} {
      margin-top: 15px;
    }
  `

  const makePaymentsBtn = css`
    margin-left: 15px;

    :not(:first-child) {
      margin-left: ${token.marginSM}px;
    }
  `

  const reviewPaymentsBtn = css`
    margin-bottom: ${token.marginMD}px;

    :not(:first-child) {
      margin-left: ${token.marginSM}px;
    }
  `

  const dNone = css`
    display: none;
  `

  const secondaryMobileMenu = css`
    display: none;

    ${responsive.sm} {
      max-width: 100vw;
    }
  `

  const filterButtonWrapper = css`
    display: flex;
    justify-content: center;
    align-items: center;
  `

  const mobileFilterLabel = css`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: ${token.marginXXS}px;
    font-weight: 500;
    font-size: ${token.fontSize}px;
    color: #3e1d67;
  `

  const mobileFilterBadge = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: ${token.paddingXXS}px ${token.paddingXS}px;
    background: #f4effb;
    border-radius: 50%;
    min-width: 30px;
  `

  const filterField = css`
    display: flex;
    align-items: center;
    width: auto;
    max-width: 100%;

    .ant-input-affix-wrapper.ant-input-search {
      width: 100% !important;
    }

    .ant-input-affix-wrapper {
      height: 32px;
    }
    .ant-input-group-addon button {
      height: 32px !important;
    }
  `

  const beneficiaryFilterField = css`
    display: flex;
    align-items: center;
    width: auto;
    max-width: 100%;

    .ant-input-affix-wrapper.ant-input-search {
      width: 100% !important;
    }

    .ant-input-affix-wrapper {
      height: 40px;
    }
    .ant-input-group-addon button {
      height: 40px !important;
    }
  `

  const filtersWrapper = css`
    display: flex;
    align-items: center;
  `

  const topMenuContainer = css`
    display: block;

    .${cx(actionMenuContainer)} {
      display: flex;
    }

    ${responsive.sm} {
      display: none;
    }
  `

  const contentContainer = css`
    height: calc(100vh - 65px);
    overflow: unset;
    width: 100%;
    padding: ${token.paddingMD}px;
    position: relative;
  `

  const title = css`
    margin-top: 10px;
    margin-bottom: 30px;
  `

  return {
    actionMenuContainer,
    open,
    closeButton,
    label,
    actionMenuHeader,
    action,
    actions,
    pageContainer,
    mobileMenu,
    rightMenu,
    column,
    makePaymentsBtn,
    dNone,
    secondaryMobileMenu,
    filterButtonWrapper,
    mobileFilterLabel,
    mobileFilterBadge,
    filterField,
    beneficiaryFilterField,
    filtersWrapper,
    topMenuContainer,
    contentContainer,
    title,
    reviewPaymentsBtn,
  }
})

export default useStyles
