import { RuleObject } from 'antd/lib/form'
import { useDebouncePromise } from 'hooks'
import { useTranslation } from 'utils/helpers'
import { invalidCardNumberError } from './utils'
import { CardType } from 'types/source'

interface Props {
  cardNetworks: string[]
  cardType: CardType | undefined
}

export const useCardNumberValidator = ({ cardNetworks, cardType }: Props) => {
  const t = useTranslation()

  const ERROR_MESSAGES = {
    emptyCardNumber: t('card.form.cardNumber.error.required'),
    invalidCardNumber: t('card.form.cardNumber.error'),
  }

  return useDebouncePromise((_ruleObject: RuleObject, value: string) => {
    if (!value) {
      return Promise.reject(ERROR_MESSAGES.emptyCardNumber)
    }

    if (invalidCardNumberError(value)) {
      return Promise.reject(ERROR_MESSAGES.invalidCardNumber)
    }

    if (cardType) {
      const networkRule = cardNetworks.find((card) => card === cardType.network)

      if (!networkRule) {
        return Promise.reject(t('card.form.cardNumber.error.notSupported', { network: cardType.network }))
      }
    }

    return Promise.resolve()
  }, 300)
}

export const useCVCValidator = () => {
  const t = useTranslation()

  const ERROR_MESSAGES = {
    emptyCVC: t('card.form.cvc.error.required'),
    invalidCVC: t('card.form.cvc.error'),
  }

  return useDebouncePromise((_ruleObject: RuleObject, value: string) => {
    if (!value) {
      return Promise.reject(ERROR_MESSAGES.emptyCVC)
    }

    if (value.length < 3) {
      return Promise.reject(ERROR_MESSAGES.invalidCVC)
    }

    return Promise.resolve()
  }, 300)
}

export const useExpiryDateValidator = () => {
  const t = useTranslation()

  const ERROR_MESSAGES = {
    emptyExpiryDate: t('card.form.expiryDate.error.required'),
    monthOutOfRange: t('card.form.expiryMonth.error'),
    yearOutOfRange: t('card.form.expiryYear.error'),
    dateOutOfRange: t('card.form.expiryDate.error'),
    invalidExpiryDate: t('card.form.expiryDate.error'),
  }

  return useDebouncePromise((_ruleObject: RuleObject, value: string) => {
    if (!value) {
      return Promise.reject(ERROR_MESSAGES.emptyExpiryDate)
    }

    const [month, year] = value.split('/').map((v) => parseInt(v, 10))

    if (month < 1 || month > 12) {
      return Promise.reject(ERROR_MESSAGES.monthOutOfRange)
    }

    const currentYear = new Date().getFullYear() % 100

    if (year < currentYear % 100 || year > currentYear + 10) {
      return Promise.reject(ERROR_MESSAGES.yearOutOfRange)
    }

    if (year === currentYear && month < new Date().getMonth() + 1) {
      return Promise.reject(ERROR_MESSAGES.dateOutOfRange)
    }

    return Promise.resolve()
  }, 300)
}
