import { Source } from './source'

export enum ChargeStatus {
  REQUIRES_ACTION = 'REQUIRES_ACTION',
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
  PROCESSING = 'PROCESSING',
  AUTHORIZED = 'AUTHORIZED',
}

export type Gateway = {
  id: string
  gatewayAccount: string
  mid: string | null
}

export interface Charge {
  id: string
  setId?: string
  currency: string
  sourceId: string
  dateCreated: string
  providerId: string
  type: string
  amount: number
  userId: string
  entityId: string
  profileId: string
  dateUpdated: string
  statementDescriptor: string
  gateway: Gateway
  threeDSecure: boolean
  status: ChargeStatus
  nextAction: {
    type: NextActionType
    redirect: RedirectData
  } | null
  source?: Source
  outcome?: Partial<Outcome>
  redirect?: {
    url: string
    method: string
    params: []
  }
  paymentInstructionIds?: string[]
  chargeGroupId?: string
}

export interface RedirectData {
  url: string
  method: string
  params: RedirectDataParam[]
}

export interface ProgressData {
  current: number
  total: number
}

export interface RedirectDataParam {
  key: string
  value: string
}

export interface Outcome {
  success: boolean
  errorCode?: string
  errorMessage?: string
  providerErrorCode?: string
  providerErrorMessage?: string
  successfulChargeIds?: string[]
  failedChargeIds?: string[]
  successfulFeeChargeIds?: string[]
  failedFeeChargeIds?: string[]
  status: 'FAIL' | 'SUCCESS'
}

export enum NextActionType {
  REDIRECT = 'redirect',
}
