import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css, token }) => ({
  importPaymentContainer: css`
    padding: 15px;
    background-color: white;
    border-radius: 10px;
    overflow: auto;
    height: calc(100vh - 220px);
  `,
  uploader: css`
    max-width: 100%;
    margin: auto;
  `,
  selectCardContainer: css`
    width: 100%;
  `,
  selectCardLabel: css`
    padding-bottom: 2px;
  `,
  selectCardAddCard: css`
    display: flex;

    .ant-select {
      width: 255px;
    }

    .ant-select-selector {
      .ant-select-selection-item {
        .card-container {
          .card-details-wraper {
            margin-top: ${token.marginXXS}px;
          }
        }
      }
    }

    .ant-select-selection-placeholder {
      display: flex !important;
      align-items: center !important;
    }

    button {
      width: 130px;
      margin-left: 20px;
      border-radius: 10px;
    }
  `,
  cardNumber: css`
    font-weight: 600;
  `,
  oddOption: css`
    background-color: #f5f5f5;
  `,
  paymentFormSelectOption: css``,
  sourceError: css`
    color: #ff4d4f;
  `,
  uploaderContainer: css`
    width: 100%;
    margin-top: 25px;
  `,
  uploaderLabel: css`
    padding-bottom: 2px;
  `,
  importFilesInstructions: css`
    color: #00000073;
  `,
  supportedFormatsContainer: css`
    .ant-dropdown-link {
      color: #00000073;
      font-size: ${token.fontSizeSM}px;
      padding: 0;

      &:hover {
        color: #00000073 !important;
      }
    }
  `,
  exampleContainer: css`
    border-radius: 10px;
    background-color: #f0f2f5;
    padding: 14px 10px;
    font-size: ${token.fontSizeSM}px;
  `,
  downloadLinkContainer: css`
    margin-top: 20px;

    a {
      color: #68cdba !important;
    }
  `,
  importTabs: css`
    padding: 0;
    margin-top: 20px;
  `,
  noImportsLabel: css`
    padding: 5px;
    color: #00000073;
  `,
  numberOfNewCompleted: css`
    top: -7px;
    left: 5px;
  `,
  importListContainer: css`
    margin-top: 15px;
    border: 1px solid #fafafa;
  `,
  importsPagination: css`
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  `,
}))

export default useStyles
