import { useState, ReactElement } from 'react'
import { Link, useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { Form, Input, Button, notification, Alert, Divider, Spin } from 'antd'
import { InfoCircleFilled, LoadingOutlined } from '@ant-design/icons'
import Loader from '../../components/Loader/Loader'
import { login } from '../../api/auth'
import {
  getDeviceToken,
  saveSessionId,
  saveAuthenticationStatus,
  getIsProfileChosen,
  removeSessionId,
  removeAuthenticationStatus,
  savePasswordToken,
  setTokenDateExpire,
  setPreferredLoginMethod,
  getFileDownloadTriggerInfo,
  removeSsoPendingProvider,
} from '../../utils/storage'
import OTPInput from '../../components/OTPInput/OTPInput'
import { useIntl } from 'react-intl'
import { page, messages, fileDownload } from '../../lang/definitions/index'
import OTPSetup from '../../components/OTPSetup/OTPSetup'
import { validateSessionId } from '../../utils/validate'
import { useTranslation } from 'utils/helpers'
import { notifyError } from 'utils/error'
import * as Sentry from '@sentry/react'
import { useSession } from 'stores/session'
import useLoginStyles from './Login.style'

interface Props {
  countryCode: string
}
interface Credentials {
  username: string
  password: string
}

interface LoginErrorProps {
  message: string
  details: {
    userId?: string
    passwordToken?: string
    dateCreated?: string
    tokenDateExpire?: string
  }
}

const Login = ({ countryCode }: Props): ReactElement => {
  const { styles } = useLoginStyles()
  const intl = useIntl()
  const history = useHistory()
  const isProfileChosen = getIsProfileChosen()
  const deviceToken = getDeviceToken()
  const {
    actions: { setSessionId },
  } = useSession()

  const [showLoader, setShowLoader] = useState(false)
  const [loginStatus, setLoginStatus] = useState<string>('')
  const [currentSessionId, setCurrentSessionId] = useState('') // used to send sessionId as prop to OTPInput without setting sessionId to LocalStorage
  const [userId, setUserId] = useState('')
  const [tfaToken, setTfaToken] = useState<string>('')
  const [tfaMethod, setTfaMethod] = useState<string>('')
  const [tfaEmail, setTfaEmail] = useState<string>('')
  const [tfaPhone, setTfaPhone] = useState<string>('')
  const t = useTranslation()

  const [form] = Form.useForm()

  const fileDownloadTriggerInfo = getFileDownloadTriggerInfo()

  const handleCredentialsFormSubmit = async (credentials: Credentials) => {
    setShowLoader(true)
    removeSsoPendingProvider()

    try {
      const response = await login(credentials, deviceToken)
      setLoginStatus(response.status)

      // if User has not setup a phone-number
      if (response.status === 'OTP_SETUP_REQUIRED') {
        setUserId(response.userId as string)
        setCurrentSessionId(response.sessionId)
        hideTabList(true)
      }

      if (response.status === 'OTP_PENDING') {
        const { tfaMethod } = response
        const { token, phoneMask, emailMask } = response.tfaProperties
        // getting the tfaMethod to use in OTPInput component
        setTfaPhone(phoneMask as string)
        setTfaEmail(emailMask as string)
        setTfaMethod(tfaMethod as string)
        setTfaToken(token as string)
        setCurrentSessionId(response.sessionId)
        hideTabList(true)
      }

      if (response.status !== 'OTP_PENDING' && response.status !== 'OTP_SETUP_REQUIRED') {
        saveSessionId(response.sessionId)
        setSessionId(response.sessionId)
        const params = queryString.parse(history.location.search)
        const url = (params.previousLocation || '/') as string
        const sessionId = response.sessionId
        if (sessionId) {
          const checkIsValid = await validateSessionId()
          // Here check if session is valid, if yes save to localStorage,else show notification that unsuccessful login happened
          if (checkIsValid) {
            saveAuthenticationStatus()
            setPreferredLoginMethod('credentials')
          } else
            notification.error({
              message: 'Error with login',
              placement: 'topRight',
            })
        } else {
          removeSessionId()
          removeAuthenticationStatus()
          if (fileDownloadTriggerInfo) {
            history.push('/action/download')
          } else {
            history.push('/choose-profile')
          }
        }
        if (!isProfileChosen) {
          if (fileDownloadTriggerInfo) {
            history.push('/action/download')
          } else {
            history.push('/choose-profile')
          }
        } else history.push(url)
      }
    } catch (error) {
      const err = error as LoginErrorProps
      if (err.message === 'CREDENTIALS_MISSMATCH') {
        form.setFields([
          {
            name: 'password',
            errors: [intl.formatMessage(messages['messages.error.login.incorrect.credentials'])],
          },
        ])
        return
      }
      if (err.message === 'PASSWORD_UPDATE_REQUIRED') {
        const { userId, passwordToken, tokenDateExpire } = err.details
        savePasswordToken(passwordToken!)
        setTokenDateExpire(tokenDateExpire!)
        history.push(`/update-password/user/${userId as string}`)
        return
      }

      if (err.message === 'Network error') {
        return
      }

      notifyError(t('error.login.generic'))
      Sentry.captureException(err)
    } finally {
      setShowLoader(false)
    }
  }

  const hideTabList = (value: boolean) => {
    const tablist = document.querySelector('.ant-tabs-nav') as HTMLElement
    value ? (tablist.style.display = 'none') : (tablist.style.display = 'initial')
  }

  const loadingIcon = <LoadingOutlined style={{ color: '#fff' }} spin />

  return (
    <div data-testid="login-panel">
      {/* phone not exists but 2fa is active */}
      {loginStatus === 'OTP_SETUP_REQUIRED' && (
        <OTPSetup sessionId={currentSessionId} userId={userId} setLoginStatus={setLoginStatus} />
      )}

      {/* 2fa active */}
      {loginStatus === 'OTP_PENDING' && (
        <>
          <OTPInput
            token={tfaToken}
            sessionId={currentSessionId}
            method={tfaMethod}
            email={tfaEmail}
            phone={tfaPhone}
          />
        </>
      )}

      {loginStatus !== 'OTP_PENDING' && loginStatus !== 'OTP_SETUP_REQUIRED' && (
        <>
          {fileDownloadTriggerInfo && (
            <Alert
              type="info"
              className="file-download-banner"
              icon={<InfoCircleFilled style={{ color: '#6CA8E3' }} />}
              message={intl.formatMessage(fileDownload['file.download.banner.title'])}
              description={intl.formatMessage(fileDownload['file.download.banner.description'])}
              showIcon
            />
          )}
          <Loader showLoader={showLoader}>
            <Form
              form={form}
              id="credentials-login-form"
              layout="vertical"
              name="basic"
              requiredMark={false}
              onFinish={(credentials: Credentials) => void handleCredentialsFormSubmit(credentials)}
            >
              <Form.Item
                label={intl.formatMessage(page['page.login.form.email'])}
                name="username"
                rules={[
                  {
                    type: 'email',
                    message: intl.formatMessage(messages['messages.error.login.incorrect.emailFormat']),
                  },
                  {
                    required: true,
                    message: intl.formatMessage(messages['messages.error.login.enter.email']),
                  },
                ]}
              >
                <Input className="bh-input bh-track-email-field" size="large" autoFocus />
              </Form.Item>
              <Form.Item
                label={intl.formatMessage(page['page.login.form.password'])}
                name="password"
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage(messages['messages.error.login.enter.password']),
                  },
                ]}
              >
                <Input.Password className="password-input bh-track-password-field" size="large" />
              </Form.Item>
              <Form.Item>
                <Button
                  htmlType="submit"
                  block
                  type="primary"
                  size="large"
                  className="bh-track-login-button"
                  data-testid="login-btn"
                  disabled={showLoader}
                >
                  {showLoader ? (
                    <Spin indicator={loadingIcon} />
                  ) : (
                    intl.formatMessage(page['page.login.form.loginButton'])
                  )}
                </Button>
              </Form.Item>
            </Form>
          </Loader>
          <div className={styles.formFooter}>
            <Link className="bh-track-reset-button-password" to={`/errare-humanum-est/${countryCode || 'gb'}`}>
              <span className={styles.link}>{intl.formatMessage(page['page.login.link.forgot'])}</span>
            </Link>

            <Divider className={styles.divider}>{intl.formatMessage(page['page.login.divider.continue.with'])}</Divider>
          </div>
        </>
      )}
    </div>
  )
}

export default Login
