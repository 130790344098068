import { createStyles } from 'antd-style'

const useStyles = createStyles(({ token, css, responsive }) => {
  return {
    externalContainer: css`
      min-height: 100vh;
      display: flex;
      align-items: center;
      flex-direction: column;
    `,
    externalContainerWrapper: css`
      justify-self: end;
      padding: ${token.paddingLG}px;
      background: ${token.colorWhite};
      justify-content: center;
      display: flex;
      height: 80vh;
      min-width: 480px;

      ${responsive.xs} {
        min-width: 100vw;
      }

      .ant-checkbox-wrapper {
        margin-top: 10px;
        margin-bottom: 15px;
        font-size: 12px;
        color: #949393;
      }
    `,
    externalContainerInner: css`
      display: flex;
      flex-direction: column;
      width: max-content;
      justify-content: center;

      ${responsive.xs} {
        width: 100%;
        padding: ${token.paddingLG}px;
      }
    `,
    logoContainer: css`
      display: flex;
      flex-direction: column;
      align-self: center;
      align-items: center;
    `,
    logoImg: css`
      height: 62px;
      width: 163px;
    `,
    externalContainerHeading: css`
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: justify;
      justify-content: center;
    `,
    externalAlertMessageContainer: css`
      min-height: 10vh;
      width: 100%;
    `,
    titleContainer: css`
      text-align: center;
      margin: 10px 140px 0px;
      max-width: 280px;
      font-size: ${token.fontSize}px;
      margin-bottom: ${token.marginLG}px;
    `,
    updatePasswordWrapper: css`
      max-width: 300px;
      margin: 0 auto;
    `,
  }
})

export default useStyles
