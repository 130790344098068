import { api } from './utils'
import { CreateBankDateBody, Holiday, BankDate } from '../types/greg'

const BASE_PATH = 'greg'

export const createBankDate = async (data: CreateBankDateBody): Promise<BankDate> => {
  const url = `/${BASE_PATH}/bank-date`

  const response = await api.post(url, data)

  return response.json()
}

export const getBankHolidays = async (country: string): Promise<Array<Holiday>> => {
  const url = `/${BASE_PATH}/bank-holidays/${country}`

  const response = await api.get(url)

  return response.json()
}
