/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { AlertMessage } from '../types/messages'

interface AlertMessagesContext {
  messages: AlertMessage[]
  setMessages: (messages: AlertMessage[]) => void
}

export const AlertMessagesContext = React.createContext({
  messages: [],
  setMessages: (messages: AlertMessage[]) => undefined,
} as AlertMessagesContext)

export const AlertMessagesProvider = AlertMessagesContext.Provider
export const AlertMessagesConsumer = AlertMessagesContext.Consumer
