import React, { ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { password } from '../../lang/definitions/password'
import { PasswordPolicyRule } from '../../types/rules'
//import { PasswordPolicyRule } from "../../pages/ResetPassword/ResetPassword"

interface Props {
  passwordPolicy: PasswordPolicyRule
  error?: string[] | null
}

const PasswordPolicy = (props: Props): ReactElement => {
  const { passwordPolicy, error } = props

  const intl = useIntl()

  /* Important ⚠️
    Add radditional rule values to translate here if needed
  */
  const translateKey = (rule: string, value: number) => {
    if (value !== 0 || undefined) {
      switch (rule) {
        case 'minLength':
          return intl.formatMessage(password['password.policy.minLength'], {
            value: `${value}`,
          })

        case 'minAlpha':
          return intl.formatMessage(password['password.policy.minAlpha'], {
            value: `${value}`,
          })

        case 'minNumeric':
          return intl.formatMessage(password['password.policy.minNumeric'], {
            value: `${value}`,
          })

        case 'minSpecial':
          return intl.formatMessage(password['password.policy.minSpecial'], {
            value: `${value}`,
          })
        default:
          return null
      }
    }
  }

  return (
    <ul data-testid="reset-password-policy">
      {passwordPolicy &&
        // map all key/values of passwordPolicy
        Object.entries(passwordPolicy).map(([key, value]) => {
          const translatedRule = translateKey(key, value as number) // translate the rule (key) and send value to format string as prefered.
          return error?.some((e) => e === key) && translatedRule ? (
            // if the rule has the same value as the error, display with red text
            <li key={`policy-${key}-error`} data-testid={`policy-${key}-error`} style={{ color: 'red' }}>
              {translatedRule}
            </li>
          ) : (
            translatedRule && (
              <li key={`policy-${key}`} data-testid={`policy-${key}`}>
                {translatedRule}
              </li>
            )
          )
        })}
    </ul>
  )
}

export default PasswordPolicy
