import { Dispatch } from 'react'
import { SessionAction } from './types'
import { Iam, Rules } from 'types/rules'
import { User } from 'types/user'

const getActions = (dispatch: Dispatch<SessionAction>) => {
  return {
    setSessionId: (sessionId: string) => {
      dispatch({ type: 'SET_SESSION_ID', payload: sessionId })
    },
    setUser: (user: User) => {
      dispatch({ type: 'SET_USER', payload: user })
    },

    setRules: (rules: Rules) => {
      dispatch({ type: 'SET_RULES', payload: rules })
    },

    setIam: (iam: Iam[]) => {
      dispatch({ type: 'SET_IAM', payload: iam })
    },

    setIsB2BUser: (isB2BUser: boolean) => {
      dispatch({ type: 'SET_IS_B2B_USER', payload: isB2BUser })
    },

    resetContext: () => {
      dispatch({ type: 'RESET_CONTEXT' })
    },
  }
}

export default getActions
