import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css, token }) => ({
  container: css`
    box-sizing: border-box;
    padding: 8px;
    display: flex;
    border-bottom: 1px solid #fafafa;
  `,
  icon: css`
    display: flex;
    align-items: center;
    padding-right: 10px;
  `,
  errors: css`
    color: #ff4d4f;
  `,
  fileName: css``,
  importErrors: css``,
  errorHeader: css`
    display: flex;

    span.label {
      color: #ff4d4f;
    }

    button {
      margin-left: auto;
      padding: 0 10px;
      height: 22px;
    }
  `,
  errorsTable: css`
    margin-top: 10px;
  `,
  row: css`
    display: flex;
    box-shadow: inset 0px -1px 0px #f0f0f0;
  `,
  column: css`
    padding: 5px;
    width: 25%;
  `,
  empty: css`
    display: none;
  `,
  header: css`
    background-color: #fafafa;

    span {
      font-size: ${token.fontSize}px;
      font-weight: 500;
      line-height: 22px;
    }
  `,
  errorsPagination: css`
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  `,
  import: css`
    margin-left: 8px;
    width: 100%;
  `,
}))

export default useStyles
