import { IntlShape } from 'react-intl'
import { menu } from '../lang/definitions/index'
import {
  MailOutlined,
  SettingOutlined,
  UserOutlined,
  CreditCardOutlined,
  EllipsisOutlined,
  ContainerOutlined,
  DashboardOutlined,
  BarChartOutlined,
  LineChartOutlined,
  TableOutlined,
  ApartmentOutlined,
} from '@ant-design/icons'
import React, { ComponentType } from 'react'
import { RequiredACL, RulesLogic, SignChainRule } from '../types/rules'
import { organization } from '../lang/definitions/organization'
import { CustomIconComponentProps } from 'types/icon'

export interface MenuItem {
  label: string
  path?: string
  icon: ComponentType<CustomIconComponentProps>
  requiredRoles?: string[]
  requiredACL?: RequiredACL[]
  badge?: React.ReactNode
  children?: Array<{
    label: string
    icon: ComponentType<CustomIconComponentProps>
    path: string
    requiredACL?: RequiredACL[]
    requiredRoles?: string[]
    default?: boolean
    applicableEntityClasses?: string[]
    requiredRuleSettings?: {
      kind: string
      action: (a: Partial<RulesLogic>) => boolean
    }
  }>
}

export const menuItems = (intl: IntlShape): Array<MenuItem> => [
  {
    label: intl.formatMessage(menu['menu.left.payments']),
    icon: ContainerOutlined as ComponentType<CustomIconComponentProps>,
    children: [
      {
        label: intl.formatMessage(menu['menu.left.payments']),
        path: '/',
        icon: ContainerOutlined as ComponentType<CustomIconComponentProps>,
        default: true,
        applicableEntityClasses: ['person', 'sme', 'corp'],
      },
      {
        label: intl.formatMessage(menu['menu.left.addpayments']),
        path: '/app/payments/add/input',
        icon: ContainerOutlined as ComponentType<CustomIconComponentProps>,
        requiredACL: [{ kind: 'paymentInstruction', action: 'create' }],
        applicableEntityClasses: ['person', 'sme', 'corp'],
      },
      {
        label: intl.formatMessage(menu['menu.left.reviewpayments']),
        path: '/app/payments/review',
        icon: ContainerOutlined as ComponentType<CustomIconComponentProps>,
        requiredACL: [{ kind: 'signature', action: 'create' }],
        applicableEntityClasses: ['corp'],
      },
      {
        label: intl.formatMessage(menu['menu.left.makepayments']),
        path: '/app/payments/pay',
        icon: ContainerOutlined as ComponentType<CustomIconComponentProps>,
        applicableEntityClasses: ['corp'],
        requiredRuleSettings: {
          kind: 'requiredSignChain',
          action: requiredSignaturesForAllCurrencies,
        },
      },
    ],
  },
  {
    label: intl.formatMessage(menu['menu.left.beneficiaries']),
    path: '/app/beneficiaries',
    icon: UserOutlined as ComponentType<CustomIconComponentProps>,
  },
  {
    label: intl.formatMessage(menu['menu.left.sources']),
    path: '/app/cards',
    icon: CreditCardOutlined as ComponentType<CustomIconComponentProps>,
  },
  {
    label: intl.formatMessage(menu['menu.left.reports']),
    path: '/app/reports',
    icon: MailOutlined as ComponentType<CustomIconComponentProps>,
  },
  {
    label: intl.formatMessage(menu['menu.left.settings']),
    path: '/app/settings',
    icon: SettingOutlined as ComponentType<CustomIconComponentProps>,
  },
  {
    label: intl.formatMessage(menu['menu.left.adminConsole']),
    path: '/app/admin/organization-hierarchy',
    icon: DashboardOutlined as ComponentType<CustomIconComponentProps>,
    requiredRoles: ['admin', 'owner'], // any of those required,
  },
  {
    label: intl.formatMessage(menu['menu.left.support']),
    path: '/app/support-faq',
    icon: EllipsisOutlined as ComponentType<CustomIconComponentProps>,
  },
]

export const profileMenuItems = (intl: IntlShape): Array<MenuItem> => [
  {
    label: intl.formatMessage(menu['menu.right.profile']),
    path: '/app/profile',
    icon: UserOutlined as ComponentType<CustomIconComponentProps>,
  },
  {
    label: intl.formatMessage(menu['menu.right.settings']),
    path: '/app/settings',
    icon: SettingOutlined as ComponentType<CustomIconComponentProps>,
  },
]

export const adminMenuItems = (intl: IntlShape): Array<MenuItem> => [
  ...(window.VITE_ENVIRONMENT !== 'production'
    ? [
        {
          label: intl.formatMessage(menu['menu.admin.paymentOverview']),
          path: '/app/admin/payment-overview',
          icon: TableOutlined as ComponentType<CustomIconComponentProps>,
        },
      ]
    : []),
  {
    label: intl.formatMessage(organization['organization.page.title']),
    path: '/app/admin/organization-hierarchy',
    icon: ApartmentOutlined as ComponentType<CustomIconComponentProps>,
  },
  {
    label: intl.formatMessage(menu['menu.admin.activity']),
    path: '/app/admin/activity',
    icon: BarChartOutlined as ComponentType<CustomIconComponentProps>,
  },
  {
    label: intl.formatMessage(menu['menu.left.reports']),
    path: '/app/admin/reports',
    icon: LineChartOutlined as ComponentType<CustomIconComponentProps>,
  },
]

// Action for menuItem

// Used in menu to decide if "make payments" menu item should be shown or not.
export const requiredSignaturesForAllCurrencies = (requiredSignaturesRules: Partial<RulesLogic>): boolean => {
  if (!requiredSignaturesRules) return false

  const { rule: rules } = requiredSignaturesRules as { rule: SignChainRule[] }

  // requiredSignaturesRules is undefined
  if (!rules) return false

  return rules.filter((r) => !!r).every((r: SignChainRule) => r.requiredSignatures !== 0)
}
