/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/**
 * Define global variables
 */

/// <reference types="vite/client" />
declare global {
  interface Window {
    VITE_SENTRY_DSN: string
    VITE_ENVIRONMENT: string
    VITE_VERSION: string
    VITE_MATOMO_CONTAINER: string
    VITE_GA4_ID: string
    VITE_ANALYTICS_ID: string
    VITE_URL: string
    VITE_FRONTEND_PROTOCOL: string
    VITE_FRONTEND_DOMAIN: string
    VITE_HUBSPOT_ID: string
    import: {
      meta: {
        env: ImportMetaEnv
      }
    }
  }
}

type ENV_VARIABLES = {
  VITE_SENTRY_DSN: string
  VITE_ENVIRONMENT: string
  VITE_VERSION: string
  VITE_MATOMO_CONTAINER: string
  VITE_GA4_ID: string
  VITE_ANALYTICS_ID: string
  VITE_URL: string
  VITE_BASE_URL: string
  VITE_FRONTEND_PROTOCOL: string
  VITE_FRONTEND_DOMAIN: string
  VITE_HUBSPOT_ID: string
}

const ENV_VARIABLES: ENV_VARIABLES = {
  VITE_SENTRY_DSN: window.VITE_SENTRY_DSN,
  VITE_ENVIRONMENT: window.VITE_ENVIRONMENT,
  VITE_VERSION: window.VITE_VERSION || import.meta.env.VITE_VERSION,
  VITE_MATOMO_CONTAINER: window.VITE_MATOMO_CONTAINER,
  VITE_GA4_ID: window.VITE_GA4_ID,
  VITE_ANALYTICS_ID: window.VITE_ANALYTICS_ID,
  VITE_URL: import.meta.env.VITE_URL,
  VITE_BASE_URL: import.meta.env.BASE_URL,
  VITE_FRONTEND_PROTOCOL: import.meta.env.VITE_FRONTEND_PROTOCOL,
  VITE_FRONTEND_DOMAIN: import.meta.env.VITE_FRONTEND_DOMAIN,
  VITE_HUBSPOT_ID: import.meta.env.VITE_HUBSPOT_ID,
}

export default function env(key: keyof ENV_VARIABLES) {
  return ENV_VARIABLES[key]
}
