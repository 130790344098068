import React, { useEffect } from 'react'
import { Alert } from 'antd'
import classNames from 'classnames'
import { AlertMessage } from '../../types/messages'
import { User } from '../../types/user'

interface AlertMessagesProps {
  isBanner: boolean
  messages: AlertMessage[]
  user: User | undefined
  markAsSeen: (messagesNonSeenByUser: AlertMessage[], userId: string) => void
  handleCloseAlert: (messageId: string, userId: string) => void
}
const AlertMessages = React.memo(
  (props: AlertMessagesProps): React.JSX.Element => {
    const { isBanner, messages, user, markAsSeen, handleCloseAlert } = props
    useEffect(() => {
      if (user && props.messages.length) {
        const userId = user.user.id
        const messagesNonSeenByUser = props.messages.filter(
          (message: AlertMessage) => !message.seenBy?.includes(userId)
        )
        if (messagesNonSeenByUser.length) {
          void markAsSeen(messagesNonSeenByUser, userId)
        }
      }
    }, [messages])

    return (
      <React.Fragment>
        {!!props.messages.length &&
          props.messages.map((message) => {
            return (
              <Alert
                banner={isBanner}
                className={classNames('alert-message', {
                  banner: isBanner,
                })}
                key={message.id}
                message={message?.content[0]?.heading}
                description={<div style={{ whiteSpace: 'pre-line' }}>{message?.content[0]?.content}</div>}
                type={message.type as 'success' | 'info' | 'warning' | 'error' | undefined}
                closable={!message.persistent}
                // need to handle close event only if user is logged in
                onClose={() => user && handleCloseAlert(message.id, user.user.id)}
                showIcon
              />
            )
          })}
      </React.Fragment>
    )
  },
  (prevProps, nextProps) => {
    const prevIds = prevProps.messages.map((message) => message.id)
    const nextIds = nextProps.messages.map((message) => message.id)

    return prevIds.join('-') === nextIds.join('-')
  }
)

export default AlertMessages
