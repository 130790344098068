import React from 'react'
import { Descriptions } from 'antd'
import { useIntl } from 'react-intl'
import { ImportDetails } from '../../types/activity'
import { activity } from '../../lang/definitions/activity'

interface ImportEventDetailsProps {
  importDetails: ImportDetails
}

const ImportEventDetails = ({ importDetails }: ImportEventDetailsProps): React.JSX.Element => {
  const intl = useIntl()

  const paymentsCount: number =
    importDetails.count.paymentInstructions + importDetails.count.paymentInstructionTemplates

  return (
    <Descriptions bordered layout="vertical" size="small" className="bh-description-vertical">
      {importDetails.count.existing && (
        <Descriptions.Item label={intl.formatMessage(activity['activity.import.details.total'])} span={3}>
          {importDetails.count.existing}
        </Descriptions.Item>
      )}
      {importDetails.count.parsedValid && (
        <Descriptions.Item label={intl.formatMessage(activity['activity.import.details.successfull'])} span={3}>
          {importDetails.count.parsedValid}
        </Descriptions.Item>
      )}
      {importDetails.count.parsedInvalid && (
        <Descriptions.Item label={intl.formatMessage(activity['activity.import.details.unsuccessfull'])} span={3}>
          {importDetails.count.parsedInvalid}
        </Descriptions.Item>
      )}
      {paymentsCount && (
        <Descriptions.Item label={intl.formatMessage(activity['activity.import.details.payments'])} span={3}>
          {paymentsCount}
        </Descriptions.Item>
      )}
    </Descriptions>
  )
}

export default ImportEventDetails
