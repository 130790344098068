import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css }) => ({
  payments: css`
    margin-top: 10px;
  `,
  paymentsPagination: css`
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
  `,
}))

export default useStyles
