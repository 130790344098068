import { Reducer } from 'react'
import { State, Action } from './types'
import { withLogging } from './utils'

const reducer: Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case 'SET_CURRENT_CARD':
      return {
        ...state,
        selectedCard: action.payload,
      }
    case 'SET_CURRENT_BENEFICIARY':
      return {
        ...state,
        selectedBeneficiary: action.payload,
      }
    case 'SET_CURRENT_AMOUNT':
      return {
        ...state,
        selectedAmount: action.payload,
      }
    case 'SET_CURRENT_CURRENCY':
      return {
        ...state,
        selectedCurrency: action.payload,
      }
    case 'RESET_CURRENT_PAYMENT_STATE':
      return {
        ...state,
        selectedBeneficiary: undefined,
        selectedReference: '',
        selectedAmount: 0,
        selectedCurrency: undefined,
      }
    case 'SET_IS_ALL_SELECTED_BUTTON_ACTIVE':
      return {
        ...state,
        isAllPaymentsSelectedButtonActive: action.payload,
      }
    case 'SET_CURRENT_PAYMENTS_TAB':
      return {
        ...state,
        currentTab: action.payload,
      }
    case 'SET_SELECTED_PAYMENTS':
      return {
        ...state,
        selectedPayments: action.payload,
      }
    case 'SET_FILTERED_PAYMENTS':
      return {
        ...state,
        filteredPayments: action.payload,
      }
    case 'SET_PAYMENTS_ON_CURRENT_PAGE':
      return {
        ...state,
        paymentsOnCurrentPage: action.payload,
      }
    case 'SET_PAYMENTS':
      return {
        ...state,
        payments: action.payload,
      }
    case 'SET_PAYMENTS_FILTER':
      return {
        ...state,
        paymentsFilter: action.payload,
      }
    case 'SET_PAYMENT_INSTRUCTIONS':
      return {
        ...state,
        paymentInstructions: action.payload,
      }
    case 'SET_PAYMENT_INSTRUCTION_TEMPLATES':
      return {
        ...state,
        paymentInstructionTemplates: action.payload,
      }
    case 'SET_PAYMENT_INSTRUCTION':
      return {
        ...state,
        paymentInstruction: action.payload,
      }
    case 'SET_PAYMENT_INSTRUCTION_TEMPLATE':
      return {
        ...state,
        paymentInstructionTemplate: action.payload,
      }
    case 'SET_SELECTED_APPROVERS':
      return {
        ...state,
        selectedApprovers: action.payload,
      }
    case 'SET_CURRENT_PAYMENTS_PAGE':
      return {
        ...state,
        currentPaymentsPage: action.payload,
      }
    case 'SET_CARDS':
      return {
        ...state,
        cards: action.payload,
      }
    case 'SET_PAYMENT_CARDS':
      return {
        ...state,
        paymentCards: action.payload,
      }
    case 'SET_BENEFICIARIES':
      return {
        ...state,
        beneficiaries: action.payload,
      }
    case 'SET_CURRENCIES':
      return {
        ...state,
        currencies: action.payload,
      }
    case 'SET_NUMBER_OF_PAYMENTS_SENT_TO_APPROVERS':
      return {
        ...state,
        numberOfPaymentsSentToApprovers: action.payload,
      }
    case 'SET_NUMBER_OF_SIGNATURES_REQUIRED':
      return {
        ...state,
        numberOfSignaturesRequired: action.payload,
      }
    case 'SET_DISABLE_REVIEW_PAGE':
      return {
        ...state,
        disableReviewPage: action.payload,
      }
    case 'SET_SHOW_INIT_LOADER':
      return {
        ...state,
        showInitLoader: action.payload,
      }
    case 'SET_SHOW_REVIEW_PAYMENT_LOADER':
      return {
        ...state,
        showReviewPaymentLoader: action.payload,
      }
    case 'SET_SIGNATURE_ORDER':
      return {
        ...state,
        signatureOrder: action.payload,
      }
    case 'SET_ENTITY_RULES':
      return {
        ...state,
        entityRules: action.payload,
      }
    case 'SET_CHECKED_CONFIRMATION':
      return {
        ...state,
        checkedConfirmation: action.payload,
      }
    case 'SET_NEED_CONFIRMATION':
      return {
        ...state,
        needConfirmation: action.payload,
      }
    case 'SET_SUBMIT_ERROR':
      return {
        ...state,
        submitError: action.payload,
      }
    case 'SET_NUMBER_OF_APPROVED_PAYMENTS':
      return {
        ...state,
        numberOfApprovedPayments: action.payload,
      }
    case 'SET_PAYMENTS_IN_SET':
      return {
        ...state,
        paymentsInSet: action.payload,
      }
    case 'SET_CURRENT_PAYMENTS_IN_SET_PAGE':
      return {
        ...state,
        currentPaymentsInSetPage: action.payload,
      }
    case 'SET_PAYMENTS_IN_SET_ON_CURRENT_PAGE':
      return {
        ...state,
        paymentsInSetOnCurrentPage: action.payload,
      }
    case 'RESET_STATE':
      return {
        ...state,
        numberOfSignaturesRequired: 0,
        numberOfApprovedPayments: 0,
        paymentInstructions: [],
        paymentInstructionTemplates: [],
        paymentInstruction: undefined,
        paymentInstructionTemplate: undefined,
        currentPaymentsInSetPage: 1,
        submitError: false,
        numberOfPaymentsSentToApprovers: 0,
        selectedPayments: [],
        selectedApprovers: [],
        readySet: undefined,
        requireSignatureSet: undefined,
        approvers: [],
        approveAndPaySet: undefined,
        approveSet: undefined,
      }
    case 'SET_APPROVE_SET':
      return {
        ...state,
        approveSet: action.payload,
      }
    case 'SET_APPROVE_AND_PAY_SET':
      return {
        ...state,
        approveAndPaySet: action.payload,
      }
    case 'SET_APPROVERS_TO_SHOW':
      return {
        ...state,
        approversToShow: action.payload,
      }
    case 'SET_READY_SET':
      return {
        ...state,
        readySet: action.payload,
      }
    case 'SET_REQUIRED_SIGNATURE_SET':
      return {
        ...state,
        requireSignatureSet: action.payload,
      }
    case 'SET_APPROVERS':
      return {
        ...state,
        approvers: action.payload,
      }
    case 'SET_APPROVAL_ROUNDS':
      return {
        ...state,
        approvalRounds: action.payload,
      }
    case 'SET_SLIDE_NUMBER':
      return {
        ...state,
        slideNumber: action.payload,
      }
    case 'SET_IS_SELECTING_PAYMENTS':
      return {
        ...state,
        isSelectingPayments: action.payload,
      }
    default:
      throw new Error(`Unknown action type`, action)
  }
}

export default withLogging(reducer)
