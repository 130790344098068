import { AirplusIntegration } from 'types/rules'
import { Source } from 'types/source'
import { User } from 'types/user'

export const mapAirplusVcnPoolsToSources = (vcnPools: AirplusIntegration[], user?: User): Source[] =>
  vcnPools.map((vcnPool) => ({
    id: vcnPool.id,
    preferredCurrency: vcnPool.integrationProperties.currency,
    title: vcnPool.integrationProperties.accountName,
    type: 'airplus',
    entityId: user?.profiles.find((profile) => profile.id === user?.activeProfileId)?.entityId || '',
    dateCreated: new Date().toISOString(),
    nextAction: null,
    status: vcnPool.status,
    onFile: true,
    profileId: user?.activeProfileId || '',
    typeProperties: {
      bin: '',
      lastFour: '',
      cardNetwork: 'airplus',
      expiryMonth: 0,
      expiryYear: 0,
      cardholderName: vcnPool.integrationProperties.accountName,
      gateway: {
        id: '',
        gatewayAccount: '',
      },
      last4: '',
      validationCharge: null,
    },
  }))
