import { defineMessages } from 'react-intl'

export const entity = defineMessages({
  'entity.title': {
    id: 'entity.title',
    defaultMessage: 'Title',
    description: 'This string sets entity title label',
  },
  'entity.orgNumber': {
    id: 'entity.orgNumber',
    defaultMessage: 'Organization number',
    description: 'This string sets entity org number label',
  },
  'entity.dateOfRegistration': {
    id: 'entity.dateOfRegistration',
    defaultMessage: 'Date of registration',
    description: 'This string sets entity date of registration label',
  },
  'entity.form.add.title': {
    id: 'entity.form.add.title',
    defaultMessage: 'Add entity',
    description: 'This string sets add entity form title',
  },
  'entity.form.edit.title': {
    id: 'entity.form.edit.title',
    defaultMessage: 'Edit entity',
    description: 'This string sets edit entity form title',
  },
  'entity.form.details.title': {
    id: 'entity.form.details.title',
    defaultMessage: 'Entity details',
    description: 'This string sets details entity form title',
  },
  'entity.form.parentEntity': {
    id: 'entity.form.parentEntity',
    defaultMessage: 'Parent entity',
    description: 'This string sets parent entity label',
  },
  'entity.form.parentEntity.placeholder': {
    id: 'entity.form.parentEntity.placeholder',
    defaultMessage: 'Choose Entity',
    description: 'This string sets parent entity placeholder',
  },
  'entity.form.country': {
    id: 'entity.form.country',
    defaultMessage: 'Choose country',
    description: 'This string sets country label',
  },
  'entity.form.name': {
    id: 'entity.form.name',
    defaultMessage: 'Name',
    description: 'This string sets name label',
  },
  'entity.form.name.placeholder': {
    id: 'entity.form.name.placeholder',
    defaultMessage: 'Enter name',
    description: 'This string sets parent name placeholder',
  },
  'entity.form.companyRegistrationNumber': {
    id: 'entity.form.companyRegistrationNumber',
    defaultMessage: 'Company registration number',
    description: 'This string sets company registration number label',
  },
  'entity.form.companyRegistrationNumber.placeholder': {
    id: 'entity.form.companyRegistrationNumber.placeholder',
    defaultMessage: 'Enter registration number',
    description: 'This string sets company registration number placeholder',
  },
  'entity.form.streetAddress': {
    id: 'entity.form.streetAddress',
    defaultMessage: 'Street address',
    description: 'This string sets street address label',
  },
  'entity.form.streetAddress.placeholder': {
    id: 'entity.form.streetAddress.placeholder',
    defaultMessage: 'Enter street address',
    description: 'This string sets street address placeholder',
  },
  'entity.form.zipCode': {
    id: 'entity.form.zipCode',
    defaultMessage: 'Zip code',
    description: 'This string sets zip code label',
  },
  'entity.form.zipCode.placeholder': {
    id: 'entity.form.zipCode.placeholder',
    defaultMessage: 'Enter zip code',
    description: 'This string sets zip code placeholder',
  },
  'entity.form.city': {
    id: 'entity.form.city',
    defaultMessage: 'City',
    description: 'This string sets city label',
  },
  'entity.form.city.placeholder': {
    id: 'entity.form.city.placeholder',
    defaultMessage: 'Enter city',
    description: 'This string sets city placeholder',
  },
  'entity.form.add': {
    id: 'entity.form.add',
    defaultMessage: 'Add',
    description: 'This string sets add button label',
  },
  'entity.form.edit': {
    id: 'entity.form.edit',
    defaultMessage: 'Edit',
    description: 'This string sets edit button label',
  },
  'entity.form.action.create': {
    id: 'entity.form.action.create',
    defaultMessage: 'Entity created successfully!',
    description: 'This string sets create notification label',
  },
  'entity.form.button.save': {
    id: 'entity.form.button.save',
    defaultMessage: 'Save changes',
    description: 'This string sets save button label',
  },
  'entity.form.action.edit': {
    id: 'entity.form.action.edit',
    defaultMessage: 'Entity updated successfully!',
    description: 'This string sets edit notification label',
  },
  'entity.form.action.delete': {
    id: 'entity.form.action.delete',
    defaultMessage: 'Entity deleted successfully!',
    description: 'This string sets delete notification label',
  },
  'organizationSettings.passwordPolicy.minLength': {
    id: 'organizationSettings.passwordPolicy.minLength',
    defaultMessage: 'Minimum length',
    description: 'This string sets minimum length label on password policy form',
  },
  'organizationSettings.passwordPolicy.lengthPlaceholder': {
    id: 'organizationSettings.passwordPolicy.lengthPlaceholder',
    defaultMessage: 'Enter length',
    description: 'This string sets length placeholder on password policy form',
  },
  'organizationSettings.passwordPolicy.minAlpha': {
    id: 'organizationSettings.passwordPolicy.minAlpha',
    defaultMessage: 'Minimum alpha characters',
    description: 'This string sets minimum alpha characters label on password policy form',
  },
  'organizationSettings.passwordPolicy.minNum': {
    id: 'organizationSettings.passwordPolicy.minNum',
    defaultMessage: 'Minimum numeric characters',
    description: 'This string sets minimum numeric characters label on password policy form',
  },
  'organizationSettings.passwordPolicy.minSpecial': {
    id: 'organizationSettings.passwordPolicy.minSpecial',
    defaultMessage: 'Minimum special characters',
    description: 'This string sets minimum special characters label on password policy form',
  },
  'organizationSettings.passwordPolicy.defaultPasswordLength': {
    id: 'organizationSettings.passwordPolicy.defaultPasswordLength',
    defaultMessage: 'Password length(number of characters)',
    description: 'This string sets default password label on password policy form',
  },
  'organizationSettings.dualSigneeNumber.amountAbove': {
    id: 'organizationSettings.dualSigneeNumber.amountAbove',
    defaultMessage: 'Amount above',
    description: 'This string sets default amount above label on dual signee number form',
  },
  'organizationSettings.dualSigneeNumber.numberOfSignee': {
    id: 'organizationSettings.dualSigneeNumber.numberOfSignee',
    defaultMessage: 'Number of signee',
    description: 'This string sets number of signee label on dual signee number form',
  },
  'organizationSettings.dualSigneeNumber.numberOfSigneePlaceholder': {
    id: 'organizationSettings.dualSigneeNumber.numberOfSigneePlaceholder',
    defaultMessage: 'Select number of signee',
    description: 'This string sets number of signee placeholder on dual signee number form',
  },
  'organizationSettings.twoFactorAuthentication.label': {
    id: 'organizationSettings.twoFactorAuthentication.label',
    defaultMessage: 'Two factor authentication for all members',
    description: 'This string sets twoFA label',
  },
  'organizationSettings.twoFactorAuthentication.intro': {
    id: 'organizationSettings.twoFactorAuthentication.intro',
    defaultMessage: 'Two factor authentication intro',
    description: 'This string sets twoFA intro',
  },
  'organizationSettings.passwordPolicy.label': {
    id: 'organizationSettings.passwordPolicy.label',
    defaultMessage: 'Password policy',
    description: 'This string sets passwordPolicy label',
  },
  'organizationSettings.passwordPolicy.intro': {
    id: 'organizationSettings.passwordPolicy.intro',
    defaultMessage: 'Password policy intro',
    description: 'This string sets passwordPolicy intro',
  },
  'organizationSettings.passwordPolicy.strictLabel': {
    id: 'organizationSettings.passwordPolicy.strictLabel',
    defaultMessage: 'Strict',
    description: 'This string sets strict label',
  },
  'organizationSettings.passwordPolicy.defaultLabel': {
    id: 'organizationSettings.passwordPolicy.defaultLabel',
    defaultMessage: 'Default',
    description: 'This string sets default label',
  },
  'organizationSettings.passwordPolicy.customLabel': {
    id: 'organizationSettings.passwordPolicy.customLabel',
    defaultMessage: 'Custom',
    description: 'This string sets custom label',
  },
  'organizationSettings.dualSignee.label': {
    id: 'organizationSettings.dualSignee.label',
    defaultMessage: 'Dual signee required',
    description: 'This string sets dualSignee label',
  },
  'organizationSettings.button.update': {
    id: 'organizationSettings.button.update',
    defaultMessage: 'Update',
    description: 'This string sets update button label',
  },
  'organizationSettings.tabs.user': {
    id: 'organizationSettings.tabs.user',
    defaultMessage: 'User',
    description: 'This string sets user tab label',
  },
  'organizationSettings.tabs.cards': {
    id: 'organizationSettings.tabs.cards',
    defaultMessage: 'Cards',
    description: 'This string sets cards tab label',
  },
  'organizationSettings.tabs.settings': {
    id: 'organizationSettings.tabs.settings',
    defaultMessage: 'Settings',
    description: 'This string sets settings tab label',
  },
  'organizationSettings.tabs.info': {
    id: 'organizationSettings.tabs.info',
    defaultMessage: 'Info',
    description: 'This string sets info tab label',
  },
  'organizationSettings.entity.add': {
    id: 'organizationSettings.entity.add',
    defaultMessage: 'Settings',
    description: 'We were unable to create the entity, please try again!',
  },
  'organizationSettings.entity.edit': {
    id: 'organizationSettings.entity.edit',
    defaultMessage: 'Settings',
    description: 'We were unable to update the entity, please try again!',
  },
  'organizationSettings.entity.delete.description': {
    id: 'organizationSettings.entity.delete.description',
    defaultMessage: 'Are you sure you want to delete {title}?',
    description: 'This string sets delete entity description',
  },
  'organizationSettings.entity.tab.title': {
    id: 'organizationSettings.entity.tab.title',
    defaultMessage: 'Entity',
    description: 'This string sets Entity tab title',
  },
  'entity.role.owner': {
    id: 'entity.role.owner',
    defaultMessage: 'Owner',
    description: 'This string sets Entity role title',
  },
  'entity.role.admin': {
    id: 'entity.role.admin',
    defaultMessage: 'Admin',
    description: 'This string sets Entity role title',
  },
  'entity.role.payer': {
    id: 'entity.role.payer',
    defaultMessage: 'Make payments',
    description: 'This string sets Entity role title',
  },
  'entity.role.viewer': {
    id: 'entity.role.viewer',
    defaultMessage: 'View payments',
    description: 'This string sets Entity role title',
  },
  'entity.role.approver': {
    id: 'entity.role.approver',
    defaultMessage: 'Approve payments',
    description: 'This string sets Entity role title',
  },
  'entity.role.adder': {
    id: 'entity.role.adder',
    defaultMessage: 'Add payments',
    description: 'This string sets Entity role title',
  },
  'entity.role.member': {
    id: 'entity.role.member',
    defaultMessage: 'Member',
    description: 'This string sets Entity role title',
  },
  'entity.not.added': {
    id: 'entity.not.added',
    defaultMessage: 'No entities added',
    description: 'No entities added message',
  },
  'user.not.added': {
    id: 'user.not.added',
    defaultMessage: 'No users added',
    description: 'No users added message',
  },
  'user.not.found': {
    id: 'user.not.found',
    defaultMessage: 'No users found',
    description: 'No users found message',
  },
  'organizationSettings.signature.error': {
    id: 'organizationSettings.signature.error',
    defaultMessage: 'Required number of profile groups needs to be equal to required signatures number.',
    description: 'This string sets error label',
  },
  'organizationSettings.signature.deleting.error': {
    id: 'organizationSettings.signature.deleting.error',
    defaultMessage: 'An error ocurred while deleting profile group.',
    description: 'This string sets error label',
  },
  'profile.groups.title': {
    id: 'profile.groups.title',
    defaultMessage: 'Profile groups',
    description: 'Profile groups title',
  },
  'profile.groups.description': {
    id: 'profile.groups.description',
    defaultMessage: 'Profile intro',
    description: 'Profile groups description',
  },
  'profile.groups.add.group': {
    id: 'profile.groups.add.group',
    defaultMessage: 'Add group',
    description: 'Profile groups add group title',
  },
  'profile.groups.add.user': {
    id: 'profile.groups.add.user',
    defaultMessage: 'Add user',
    description: 'Profile groups add user title',
  },
  'profile.groups.save': {
    id: 'profile.groups.save',
    defaultMessage: 'Save',
    description: 'Profile groups save button label',
  },
  'profile.groups.cancel': {
    id: 'profile.groups.cancel',
    defaultMessage: 'Cancel',
    description: 'Profile groups cancel button label',
  },
  'profile.groups.fetch.error.label': {
    id: 'profile.groups.fetch.error.label',
    defaultMessage: 'An error occurred while fetching profile groups.',
    description: 'Profile groups error label',
  },
  'profile.groups.role.update.error.label': {
    id: 'profile.groups.role.update.error.label',
    defaultMessage: 'An error occurred while updating role.',
    description: 'Profile groups error label',
  },
  'organizationSettings.dualSignee.intro': {
    id: 'organizationSettings.dualSignee.intro',
    defaultMessage: 'How many signatures are required when making a payment on this account',
    description: 'This string sets dualSignee intro',
  },
  'organizationSettings.dualSignee.intro2': {
    id: 'organizationSettings.dualSignee.intro2',
    defaultMessage: 'Dual signee intro',
    description: 'This string sets dualSignee intro',
  },
  'organizationSettings.dualSignee.selectUser': {
    id: 'organizationSettings.dualSignee.selectUser',
    defaultMessage: 'Please select user',
    description: 'This string sets select user placeholder',
  },
  'entity.country.errorMessage.required': {
    id: 'entity.country.errorMessage.required',
    defaultMessage: 'Please choose country',
    description: 'This string sets error message for required field',
  },
  'entity.cin.errorMessage.required': {
    id: 'entity.cin.errorMessage.required',
    defaultMessage: 'Please enter company number',
    description: 'This string sets error message for required field',
  },
  'entity.address.errorMessage.required': {
    id: 'entity.address.errorMessage.required',
    defaultMessage: 'Please enter address',
    description: 'This string sets error message for required field',
  },
  'entity.name.errorMessage.required': {
    id: 'entity.name.errorMessage.required',
    defaultMessage: 'Please enter comapny name',
    description: 'This string sets error message for required field',
  },
  'entity.city.errorMessage.required': {
    id: 'entity.city.errorMessage.required',
    defaultMessage: 'Please enter city',
    description: 'This string sets error message for required field',
  },
  'entity.zip.errorMessage.required': {
    id: 'entity.zip.errorMessage.required',
    defaultMessage: 'Please enter zip code',
    description: 'This string sets error message for required field',
  },
  'entity.parentEntity.errorMessage.required': {
    id: 'entity.parentEntity.errorMessage.required',
    defaultMessage: 'Please choose parent entity',
    description: 'This string sets error message for required field',
  },
})
