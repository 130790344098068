import { DataNode } from 'rc-tree/lib/interface'
import React from 'react'
import { useUtils } from '../../hooks/useUtils'
import {
  CheckedAllProps,
  CheckedProps,
  DateRangeObject,
  DATE_OPTIONS,
  OptionData,
  REPORT_ITEMS,
  ENTITY_OPTIONS,
} from './utils'
import { useIntl } from 'react-intl'
import { reports } from '../../lang/definitions/reports'
import { formatLocale } from '../../utils/formatLocale'
import { useLanguageState } from '../../stores/language/LanguageStore'
import useReportSummaryStyles from './ReportSummary.style'

interface SummaryProps {
  title: string
  icon: string
  type: ENTITY_OPTIONS | REPORT_ITEMS
  options: OptionData[] | DataNode[]
  selectedItems: CheckedProps
  dateOption?: string
  dateRange?: DateRangeObject
  checkedAll: CheckedAllProps
  setCheckedAll: (selected: boolean, type: string) => void
  handleResetTree?: (type: ENTITY_OPTIONS | REPORT_ITEMS) => void
  handleCheckboxChange: (values: string[], type: string) => void
}

const SummaryCard = ({
  title,
  icon,
  type,
  options,
  selectedItems,
  dateOption,
  dateRange,
  checkedAll,
  setCheckedAll,
  handleResetTree,
}: SummaryProps): React.JSX.Element => {
  const { styles } = useReportSummaryStyles()
  const { dateOptions } = useUtils()
  const intl = useIntl()
  const [languageState] = useLanguageState()
  const language = languageState.language

  const buildData = (checkedItems: string[], options: OptionData[]): OptionData[] => {
    const filteredOptions = options.filter((option: OptionData) =>
      checkedItems.some((item: string) => item === option.value)
    )
    return filteredOptions
  }

  const buildDateRangeData = (dateOption: string, dateRange: DateRangeObject): OptionData | null => {
    if (dateOption === (DATE_OPTIONS.SPECIFIC_DATE as string)) {
      if (dateRange.from && dateRange.to) {
        const to = formatLocale('date', {
          value: dateRange.to,
          locale: language,
        })
        const from = formatLocale('date', {
          value: dateRange.from,
          locale: language,
        })
        return {
          label: `${from} - ${to}`,
          value: DATE_OPTIONS.SPECIFIC_DATE,
        }
      } else {
        return null
      }
    } else {
      const index = dateOptions.findIndex((option) => option.value === dateOption)
      if (index !== -1) {
        return {
          label: dateOptions[index].label,
          value: dateOptions[index].value,
        }
      } else {
        return null
      }
    }
  }
  const renderDateValues = (dateOption: string, dateRange: DateRangeObject): React.JSX.Element => {
    const dateItem = buildDateRangeData(dateOption, dateRange)
    return dateItem ? (
      <div className={styles.box}>
        <span>{dateItem.label}</span>
      </div>
    ) : (
      <React.Fragment />
    )
  }

  const renderDateRange = (): React.ReactNode => {
    const date: DateRangeObject = { ...dateRange! }
    return date.from && date.to ? (
      <div className={styles.content}>
        <div className={styles.item}>
          <img className={styles.icon} src={icon} alt="icon" />
          <span className={styles.title}>{title}</span>
        </div>
        <div className={styles.itemSelected}>{renderDateValues(dateOption!, date)}</div>
      </div>
    ) : (
      <React.Fragment />
    )
  }

  const renderCheckboxItems = (): React.ReactNode => {
    const condition: boolean =
      !!buildData(selectedItems[type] ? selectedItems[type] : [], options as OptionData[]).length &&
      checkedAll[type] === false

    return condition ? (
      <div className={styles.content}>
        <div className={styles.item}>
          <img className={styles.icon} src={icon} alt="icon" />
          <span className={styles.title}>{title}</span>
        </div>
        <div className={styles.itemSelected}>
          {checkedAll[type] ? (
            <div className={styles.box}>
              <span>{intl.formatMessage(reports['reports.all'])}</span>
            </div>
          ) : (
            buildData(selectedItems[type] ? selectedItems[type] : [], options as OptionData[]).map(
              (item: OptionData, index: number) => (
                <div className={styles.box} key={index}>
                  <span>{item.label}</span>
                </div>
              )
            )
          )}
        </div>
      </div>
    ) : (
      <React.Fragment />
    )
  }

  const findByIds = (ids: string[], list: DataNode[]): DataNode[] => {
    const findedList: DataNode[] = []
    list.forEach((item) => {
      if (ids.some((id) => id === item.key)) {
        findedList.push(item)
      } else if (item?.children?.length) {
        findByIds(ids, item.children)
        const findedChildNodes = findByIds(ids, item.children)
        findedList.push(...findedChildNodes)
      } else {
        return
      }
    })
    return findedList
  }

  const renderTreeFields = (): React.ReactNode => {
    const optionsCopy = [...options] as DataNode[]

    let selected: DataNode[] = []
    if (selectedItems[type]) {
      selected = findByIds(selectedItems[type], optionsCopy)
    }

    const handleDeselectAll = (type: ENTITY_OPTIONS | REPORT_ITEMS): void => {
      setCheckedAll(false, type)
      handleResetTree && handleResetTree(type)
    }

    return selected.length ? (
      <div className={styles.content}>
        <div className={styles.item}>
          <img className={styles.icon} src={icon} alt="icon" />
          <span className={styles.title}>{title}</span>
        </div>
        {checkedAll[type] ? (
          <div className={styles.itemSelected}>
            <div className={styles.box}>
              <span>{intl.formatMessage(reports['reports.all'])}</span>
              <span className={styles.remove} onClick={() => handleDeselectAll(type)}>
                X
              </span>
            </div>
          </div>
        ) : (
          <div className={styles.itemSelected}>
            {selected.map((item: DataNode, index: number) => (
              <div className={styles.box} key={index}>
                <span>{item.title as React.ReactNode}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    ) : (
      <React.Fragment />
    )
  }

  const renderRadioFields = (): React.ReactNode => {
    return buildData(selectedItems[type] ? selectedItems[type] : [], options as OptionData[]).length ? (
      <div className={styles.content}>
        <div className={styles.item}>
          <img className={styles.icon} src={icon} alt="icon" />
          <span className={styles.title}>{title}</span>
        </div>
        <div className={styles.itemSelected}>
          {buildData(selectedItems[type] ? selectedItems[type] : [], options as OptionData[]).map(
            (item: OptionData, index: number) => (
              <div className={styles.box} key={index}>
                <span>{item.label}</span>
              </div>
            )
          )}
        </div>
      </div>
    ) : (
      <React.Fragment />
    )
  }

  const renderCard = (type: ENTITY_OPTIONS | REPORT_ITEMS): React.ReactNode => {
    if (type === REPORT_ITEMS.DATE_RANGE) {
      return renderDateRange()
    } else if (type === REPORT_ITEMS.ENTITY || type === REPORT_ITEMS.BENEFICIARY) {
      return renderTreeFields()
    } else if (type === REPORT_ITEMS.DATE_USER_PAID || type === REPORT_ITEMS.REPORT_FORMAT) {
      return renderRadioFields()
    } else {
      return renderCheckboxItems()
    }
  }
  return <React.Fragment>{renderCard(type)}</React.Fragment>
}

export default SummaryCard
