import { useState } from 'react'
import { Input, Form, Button, Modal } from 'antd'
import { useIntl } from 'react-intl'
import { card } from 'lang/definitions'
import { useCVCValidator } from '../validators'
import { getCardTypeByValue } from '../utils'
import CreditCardCVV from 'assets/Credit-Card-CVV.png'
import { SpaceEven } from 'pages/Beneficiaries/components'

const CVVFormItem = () => {
  const [showCVVImage, setShowCVVImage] = useState(false)
  const intl = useIntl()

  const validator = useCVCValidator()
  const form = Form.useFormInstance()
  const pan = Form.useWatch('pan', form) as string

  const maxLength = () => {
    const cardType = getCardTypeByValue(pan)

    if (cardType) {
      return cardType.code.length
    }
    return 4
  }

  return (
    <>
      <Modal open={showCVVImage} footer={false} onCancel={() => setShowCVVImage(false)}>
        <img style={{ width: '100%' }} src={CreditCardCVV} alt="Credit Card CVV" />
      </Modal>
      <SpaceEven gap={15}>
        <Form.Item
          name="cvv"
          key="cvv-input"
          label={intl.formatMessage(card['card.form.cvc.label'])}
          hasFeedback
          rules={[
            {
              validator,
            },
          ]}
        >
          <Input size="large" maxLength={maxLength()} placeholder="CVV" />
        </Form.Item>

        <div key="cvv-image-btn" style={{ marginTop: '18px' }}>
          <Button size="large" type="link" onClick={() => setShowCVVImage(true)}>
            {intl.formatMessage(card['card.form.cvc.button.label'])}
          </Button>
        </div>
      </SpaceEven>
    </>
  )
}

export default CVVFormItem
