import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Button, Divider, Pagination, Result } from 'antd'
import * as Sentry from '@sentry/react'
import Loader from 'components/Loader/Loader'
import Currency from 'components/Format/Currency/Currency'
import Date from 'components/Format/Date/Date'
import * as api from 'api/paymentInstruction'
import * as cardApi from 'api/card'
import { useLanguageState } from 'stores/language/LanguageStore'
import { Set as SetType, ChargGroup, PaymentInstruction } from 'types/paymentInstruction'
import { Charge, ChargeStatus } from 'types/charge'
import { getCurrentPage, DEFAULT_PAYMENTS_PAGE_SIZE } from '../../Add/utils'
import { pi } from 'lang/definitions'
import { useFeatureFlags, useSetProgress, useUtils } from 'hooks'
import { searchSources } from 'api/card'
import { Source } from 'types/source'
import { useCurrentPayment } from 'stores/Payment'
import useSubmitProgressStyles from './SubmitProgress.style'
import { cx } from 'antd-style'

interface FailedPayment {
  paymentInstructionId: string
  errorCode: string
  paymentInstruction?: PaymentInstruction
}

interface SubmitProgressProps {
  chargedSet?: SetType
  chargingSet?: SetType
  goBackAfterSubmit: () => void
}
const SubmitProgress = (props: SubmitProgressProps): React.JSX.Element => {
  const { chargedSet, goBackAfterSubmit, chargingSet } = props

  const { styles } = useSubmitProgressStyles()
  const intl = useIntl()
  const { getTranslatedErrorMessage } = useUtils()

  const {
    state: { selectedPayments, numberOfPaymentsSentToApprovers, submitError, numberOfApprovedPayments },
  } = useCurrentPayment()

  const [languageState] = useLanguageState()
  const { amountOfPaymentsForAsyncResponse, useDynamicPaymentThreshold } = useFeatureFlags()
  const language = languageState.language

  const [numberOfScheduledPayments, setNumberOfScheduledPayments] = useState(0)
  const [numberOfSuccessfulPayments, setNumberOfSuccessfulPayments] = useState(0)
  const [numberOfSuccessfulDeferredPayments, setNumberOfSuccessfulDeferredPayments] = useState(0)
  const [failedPayments, setFailedPayments] = useState<FailedPayment[]>([])
  const [failedPaymentsPage, setFailedPaymentsPage] = useState<FailedPayment[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const totalNumberOfPayments = selectedPayments.length

  const progress = useSetProgress(chargingSet, chargedSet)

  useEffect(() => {
    if (!chargedSet) {
      return
    }

    setNumberOfScheduledPayments(chargedSet.paymentInstructionTemplateIds!.length)
  }, [chargedSet])

  useEffect(() => {
    const page = getCurrentPage(failedPayments, currentPage) as FailedPayment[]
    setFailedPaymentsPage(page)
  }, [failedPayments, currentPage])

  const enrichChargeGroupsChargesWithSourceObject = async (chargeGroups: ChargGroup[]): Promise<ChargGroup[]> => {
    const chargeGroupsWithoutSource = chargeGroups.filter((cg: ChargGroup): boolean => !cg.charge?.source)
    const chargeGroupsWithSource = chargeGroups.filter((cg: ChargGroup): boolean => !!cg.charge?.source)

    const sourceIdsForChargeGroups = [
      ...new Set(chargeGroupsWithoutSource.map((cg: ChargGroup): string => cg.sourceId)),
    ]
    let sources: Source[] = []
    if (sourceIdsForChargeGroups.length) {
      try {
        ;({ sources } = await searchSources(
          {
            kind: 'source',
            sourceId: sourceIdsForChargeGroups,
          },
          {
            limit: sourceIdsForChargeGroups.length,
          }
        ))
      } catch (error) {
        Sentry.captureException(error)
      }
    }

    const chargeGroupsWithAddedSources = chargeGroupsWithoutSource.map((cg: ChargGroup): ChargGroup => {
      const source = sources.find((s: Source) => s.id === cg.sourceId) as Source
      return {
        ...cg,
        charge: {
          ...cg.charge,
          source,
        },
      }
    })

    return [...chargeGroupsWithSource, ...chargeGroupsWithAddedSources]
  }

  const getPaymentInstructionSuccessCount = (chargeGroups: ChargGroup[], chargeIds: string[]): number => {
    const successfulPaymentInstructionIds = chargeGroups
      .filter((cg: ChargGroup): boolean => chargeIds.includes(cg?.charge?.id))
      .flatMap((cg: ChargGroup): string[] => cg.paymentInstructionIds || [])

    return successfulPaymentInstructionIds.length
  }

  const calculateChargesOutcome = async (chargedSet: SetType): Promise<void> => {
    const { chargeGroups = [], outcome } = chargedSet

    // calculate outcome based only on payment instructions charges and don't include fee charges
    // fee charges will not probably be there since they are charged after set is closed but just to be sure
    const piChargeGroups = chargeGroups.filter((cg: ChargGroup): boolean =>
      ['paymentInstruction', 'set'].includes(cg.type)
    )

    const chargeGroupsEnriched = await enrichChargeGroupsChargesWithSourceObject(piChargeGroups)

    const onlyDeferredChargeGroups = chargeGroupsEnriched.filter(
      (cg: ChargGroup): boolean => cg?.charge?.source?.type === 'deferred'
    )
    const onlyCardChargeGroups = chargeGroupsEnriched.filter(
      (cg: ChargGroup): boolean => cg?.charge?.source?.type !== 'deferred'
    )
    const onlyFailedChargeGroups = chargeGroupsEnriched.filter(
      (cg: ChargGroup): boolean => cg?.charge?.status === ChargeStatus.FAIL
    )

    const outcomeSuccessChargeIds = (outcome?.successfulChargeIds || []).filter(Boolean)

    const numberOfScheduledPayments = chargedSet?.paymentInstructionTemplateIds?.length || 0

    // charges with source type "card"
    const numberOfSuccessfulPayments = getPaymentInstructionSuccessCount(onlyCardChargeGroups, outcomeSuccessChargeIds)

    // charges with source type "deferred"
    const numberOfSuccessfulDeferredPayments = getPaymentInstructionSuccessCount(
      onlyDeferredChargeGroups,
      outcomeSuccessChargeIds
    )

    const failedPayments = onlyFailedChargeGroups
      .map((cg: ChargGroup): FailedPayment[] => {
        const failedPaymentInstructionIds = cg.paymentInstructionIds || []
        const failedPaymentsPerCharge = failedPaymentInstructionIds.map(
          (paymentInstructionId: string): FailedPayment => ({
            paymentInstructionId,
            errorCode: cg.charge?.outcome?.errorCode || '',
          })
        )

        return failedPaymentsPerCharge
      })
      .flat()

    setNumberOfSuccessfulPayments(numberOfSuccessfulPayments)
    setNumberOfSuccessfulDeferredPayments(numberOfSuccessfulDeferredPayments)
    setNumberOfScheduledPayments(numberOfScheduledPayments)

    if (failedPayments.length) {
      void processFailedPayments(failedPayments)
    }
  }

  const calculateChargesOutcomeForDPT = async (chargedSet: SetType): Promise<void> => {
    const { id, paymentInstructionTemplateIds } = chargedSet

    // get all charges from set
    const { charges: setCharges } = await cardApi.searchCharges({
      kind: 'charge',
      setId: [id!],
    })

    // group charges by paymentInstructionId

    const groupedChargesByPaymentInstructionId = setCharges.reduce(
      (acc: { [key: string]: Charge[] }, charge: Charge) => {
        const { paymentInstructionIds } = charge

        if (paymentInstructionIds?.length) {
          paymentInstructionIds.forEach((paymentInstructionId) => {
            if (!acc[paymentInstructionId]) {
              acc[paymentInstructionId] = []
            }
            acc[paymentInstructionId].push(charge)
          })
        }

        return acc
      },
      {}
    )

    // group successful and failed paymentInstructionIds
    const successfulPaymentInstructionIds = []
    const numberOfScheduledPayments = paymentInstructionTemplateIds?.length || 0
    const failedPaymentInstructionIds = []

    for (const [paymentInstructionId, charges] of Object.entries(groupedChargesByPaymentInstructionId)) {
      const isPaymentInstructionSuccessful = charges.every((charge: Charge) => charge.status === ChargeStatus.SUCCESS)

      if (isPaymentInstructionSuccessful) {
        successfulPaymentInstructionIds.push(paymentInstructionId)
      } else {
        failedPaymentInstructionIds.push(paymentInstructionId)
      }
    }

    setNumberOfSuccessfulPayments(successfulPaymentInstructionIds.length)
    setNumberOfScheduledPayments(numberOfScheduledPayments)

    if (failedPaymentInstructionIds.length) {
      const failedPayments = failedPaymentInstructionIds.map((paymentInstructionId: string): FailedPayment => {
        const { outcome: { errorCode = '' } = {} } =
          groupedChargesByPaymentInstructionId[paymentInstructionId].find(
            (charge) => charge.status === ChargeStatus.FAIL
          ) || {}

        return {
          paymentInstructionId,
          errorCode,
        }
      })
      void processFailedPayments(failedPayments)
    }
  }

  useEffect(() => {
    // if all charges are processed
    if (amountOfPaymentsForAsyncResponse && totalNumberOfPayments >= amountOfPaymentsForAsyncResponse) {
      return
    }

    if (chargedSet && chargedSet.outcome) {
      if (useDynamicPaymentThreshold) {
        void calculateChargesOutcomeForDPT(chargedSet)
      } else {
        void calculateChargesOutcome(chargedSet)
      }
    }
  }, [chargedSet])

  const processFailedPayments = async (failedPayments: FailedPayment[]): Promise<void> => {
    const paymentInstructions = await Promise.all(
      failedPayments.map((failedPayment: FailedPayment) =>
        api.getPaymentInstruction(failedPayment.paymentInstructionId)
      )
    )
    const failedPaymentsWithPIs = failedPayments.map((failedPayment: FailedPayment): FailedPayment => {
      const paymentInstruction = paymentInstructions.find(
        (pi: PaymentInstruction) => pi.id === failedPayment.paymentInstructionId
      )

      return {
        ...failedPayment,
        paymentInstruction,
      }
    })
    setFailedPayments(failedPaymentsWithPIs)
  }

  const resetState = (): void => {
    // Wait for tab animation to finish
    setTimeout(() => {
      setNumberOfSuccessfulPayments(0)
      setFailedPayments([])
      setFailedPaymentsPage([])
      setCurrentPage(1)
    }, 500)
  }

  const handleGoBackAfterSubmit = (): void => {
    resetState()
    goBackAfterSubmit()
  }

  const renderFailedPayment = (failedPayment: FailedPayment): React.ReactNode => {
    const errorLabel = getTranslatedErrorMessage(failedPayment.errorCode)

    return (
      <div key={`failed-payment-${failedPayment.paymentInstructionId}`} className={styles.row}>
        <div className={styles.column}>
          <div>
            <span>{failedPayment.paymentInstruction?.beneficiary!.title}</span>
          </div>
          <div className={styles.errorCode}>
            <span>{errorLabel}</span>
          </div>
        </div>
        <div className={styles.column}>
          <Date value={failedPayment.paymentInstruction!.dateDue} locale={language} />
        </div>
        <div className={styles.column}>
          <Currency
            value={failedPayment.paymentInstruction!.amount.toString()}
            locale={language}
            currency={failedPayment.paymentInstruction!.currency}
          />
        </div>
      </div>
    )
  }

  return (
    <div className={styles.container} data-testid="submit-progress-container">
      {amountOfPaymentsForAsyncResponse && totalNumberOfPayments >= amountOfPaymentsForAsyncResponse ? (
        <>
          <div>
            {intl.formatMessage(pi['pi.submitProgress.no.await.case.message'], {
              totalNumberOfPayments,
            })}
          </div>
          <div className={styles.makeAnotherPaymentButton} data-testid="make-another-payment-button">
            <Button type="primary" onClick={handleGoBackAfterSubmit}>
              {intl.formatMessage(pi['pi.add.reviewPayments.submitProgress.button.successfulPayments'])}
            </Button>
          </div>
        </>
      ) : chargedSet || (!chargingSet && (numberOfPaymentsSentToApprovers || numberOfApprovedPayments)) ? (
        <React.Fragment>
          {!!numberOfSuccessfulPayments && (
            <React.Fragment>
              <div className={styles.submitHeader}>
                <span className={styles.success} data-testid="successful-payments-header">
                  {intl.formatMessage(pi['pi.add.reviewPayments.submitProgress.successfulPayments.header'], {
                    numberOfSuccessfulPayments,
                  })}
                </span>
              </div>
            </React.Fragment>
          )}
          {!!numberOfSuccessfulDeferredPayments && (
            <div className={styles.submitHeader}>
              <span className={styles.success} data-testid="successful-deferred-payments-header">
                {intl.formatMessage(pi['pi.add.reviewPayments.submitProgress.successfulDeferredPayments.header'], {
                  numberOfSuccessfulDeferredPayments,
                })}
              </span>
            </div>
          )}
          {!!numberOfScheduledPayments && (
            <div className={styles.submitHeader}>
              <span className="scheduled" data-testid="scheduled-payments-header">
                {intl.formatMessage(pi['pi.add.reviewPayments.submitProgress.scheduledPayments.header'], {
                  numberOfScheduledPayments,
                })}
              </span>
            </div>
          )}
          {!!numberOfPaymentsSentToApprovers && (
            <div className={styles.submitHeader}>
              <span>
                {intl.formatMessage(pi['pi.add.reviewPayments.submitProgress.numberOfPaymentsSentToApprovers.header'], {
                  numberOfPaymentsSentToApprovers,
                })}
              </span>
            </div>
          )}
          {!!numberOfApprovedPayments && (
            <div className={styles.submitHeader}>
              <span>
                {intl.formatMessage(pi['pi.add.reviewPayments.submitProgress.numberOfApprovedPayments.header'], {
                  numberOfApprovedPayments,
                })}
              </span>
            </div>
          )}
          {!!failedPayments.length && (
            <React.Fragment>
              {numberOfSuccessfulPayments ? (
                <React.Fragment>
                  <div style={{ width: '70%' }}>
                    <Divider>
                      {intl.formatMessage(pi['pi.add.reviewPayments.submitProgress.dividerText'], {
                        failedPayments: failedPayments.length,
                      })}
                    </Divider>
                  </div>
                  <div className={styles.submitHeader}>
                    <span className={styles.fail}>
                      {intl.formatMessage(pi['pi.add.reviewPayments.submitProgress.failedPayments.header'], {
                        failedPayments: failedPayments.length,
                      })}
                    </span>
                  </div>
                </React.Fragment>
              ) : (
                <Result
                  status="error"
                  title={intl.formatMessage(pi['pi.add.reviewPayments.submitProgress.failedPayments.header'], {
                    failedPayments: failedPayments.length,
                  })}
                ></Result>
              )}
              <div className={styles.failedPaymentsTable}>
                <div className={styles.headerRow}>
                  <div className={styles.headerColumn}>
                    <span>
                      {intl.formatMessage(pi['pi.add.reviewPayments.submitProgress.failedPayments.table.beneficiary'])}
                    </span>
                  </div>
                  <div className={styles.headerColumn}>
                    <span>
                      {intl.formatMessage(pi['pi.add.reviewPayments.submitProgress.failedPayments.table.dateDue'])}
                    </span>
                  </div>
                  <div className={styles.headerColumn}>
                    <span>
                      {intl.formatMessage(pi['pi.add.reviewPayments.submitProgress.failedPayments.table.amount'])}
                    </span>
                  </div>
                </div>
                {failedPaymentsPage.map(renderFailedPayment)}
                {failedPayments.length > DEFAULT_PAYMENTS_PAGE_SIZE && (
                  <div className={styles.paymentsPagination}>
                    <Pagination
                      current={currentPage}
                      defaultPageSize={DEFAULT_PAYMENTS_PAGE_SIZE}
                      showSizeChanger={false}
                      total={failedPayments.length}
                      size="small"
                      onChange={setCurrentPage}
                    />
                  </div>
                )}
              </div>
            </React.Fragment>
          )}
          {!!submitError && (
            <div>
              <Result
                status="error"
                title={intl.formatMessage(pi['pi.add.reviewPayments.submitProgress.error.header'], {
                  failedPayments: failedPayments.length,
                })}
              ></Result>
            </div>
          )}
          <div className={styles.makeAnotherPaymentButton} data-testid="make-another-payment-button">
            <Button type="primary" onClick={handleGoBackAfterSubmit}>
              {failedPayments.length || submitError
                ? intl.formatMessage(pi['pi.add.reviewPayments.submitProgress.button.failedPayments'])
                : intl.formatMessage(pi['pi.add.reviewPayments.submitProgress.button.successfulPayments'])}
            </Button>
          </div>
        </React.Fragment>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 10 }}>
          <div className={styles.submitHeader}>
            <span>{intl.formatMessage(pi['pi.add.reviewPayments.submitProgress.inProgress.header'])}</span>
          </div>
          {!useDynamicPaymentThreshold && <div>{progress.toFixed(0)}%</div>}
          <div className={cx(styles.loaderContainer, 'loader-container')}>
            <Loader showLoader={true} />
          </div>
        </div>
      )}
    </div>
  )
}

export default SubmitProgress
