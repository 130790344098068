export function initGoogleTagManagerV4() {
  const ga4Id = window.VITE_GA4_ID
  const d = document
  const tagId = 'google-tag-manager'
  const existingTag = d.getElementById(tagId)
  const isLocalhost = window.location.hostname === 'localhost'

  if (isLocalhost || existingTag || !ga4Id) {
    return
  }

  const headScript = d.createElement('script')
  headScript.id = tagId

  const innerFunction = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${ga4Id}');`

  headScript.innerHTML = innerFunction

  const s = d.getElementsByTagName('script')[0]
  s?.parentNode?.insertBefore(headScript, s)

  const googleIframe = d.createElement('iframe')
  googleIframe.src = `https://www.googletagmanager.com/ns.html?id=${ga4Id}`
  googleIframe.height = '0'
  googleIframe.width = '0'
  googleIframe.style.cssText = 'display:none;visibility:hidden'

  const bodyScript = d.createElement('noscript')
  bodyScript.id = 'google-tag-manager-iframe'
  bodyScript.appendChild(googleIframe)
  d.body.appendChild(bodyScript)
}

export const getDomain = () => {
  const domain = window.location.hostname.match(/\w*\.\w*$/gi)
  if (domain && Array.isArray(domain)) {
    return domain[0]
  } else {
    return window.location.hostname
  }
}
