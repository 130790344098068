import React, { useEffect, useState } from 'react'
import { Button, Descriptions, Form, Input, InputNumber, notification, Radio, Row, Switch } from 'antd'
import { PasswordPolicyRule, Rules, RulesLogic, SignChainRule, TwoFactorAuthenticationRule } from 'types/rules'
import { useIntl } from 'react-intl'
import { page, entity, messages } from 'lang/definitions/index'
import { RadioChangeEvent } from 'antd/lib/radio'
import { isEqual } from 'lodash'
import * as apiRules from 'api/rules'
import * as apiBarracks from 'api/barracks'
import Loader from 'components/Loader/Loader'

import lockIcon from 'assets/lock.svg'
import passwordIcon from 'assets/password.svg'
import dualSigneeIcon from 'assets/dual-signee-icon.svg'
import ProfileGroups from './Settings/ProfileGroups'
import { ProfileGroup } from 'types/profileGroups'
import * as Sentry from '@sentry/react'
import useSettingsTabStyle from './SettingsTab.style'

interface OrganizationSettingsProps {
  entityId: string
}

interface OrganizationSettingsFields {
  when: string
  what: string
  requiredSignatures: number
  amountFrom: number
  is2FAEnabled: boolean
  passwordPolicyType: string
  requiredSignaturesEnabled: boolean
  minLength?: number
  minAlpha?: number
  minNumeric?: number
  minSpecial?: number
  maxLength?: number
}

const SettingsTab = ({ entityId }: OrganizationSettingsProps): React.JSX.Element => {
  const intl = useIntl()
  const { styles } = useSettingsTabStyle()

  const [form] = Form.useForm()

  const [entityRules, setEntityRules] = useState<Rules>()

  const [isFormUpdated, setIsFormUpdated] = useState(true)

  const [is2FAEnabled, setIs2FAEnabled] = useState(false)
  const [isDualSigneeEnabled, setIsDualSigneeEnabled] = useState(false)

  const [what, setWhat] = useState('')
  const [when, setWhen] = useState('')

  const [passwordPolicyType, setPasswordPolicyType] = useState('')
  const [, setPasswordPolicyTypeInitial] = useState('')

  const [minLength, setMinLength] = useState(0)
  const [, setMaxLength] = useState(0)
  const [minAlpha, setMinAlpha] = useState(0)
  const [minNumeric, setMinNumeric] = useState(0)
  const [minSpecial, setMinSpecial] = useState(0)

  const [amountFrom, setAmountFrom] = useState<number>(0)
  const [requiredSignatures, setRequiredSignatures] = useState<number | null>(0)
  const [initialFieldValues, setInitialFieldValues] = useState<OrganizationSettingsFields>()
  const [isEntityCorp, setIsEntityCorp] = useState(false)
  const [showLoader, setShowLoader] = useState(true)
  const [profileGroups, setProfileGroups] = useState<ProfileGroup[]>([])
  const [isCreatingGroupDisabled, setIsCreatingGroupDisabled] = useState<boolean>(false)
  const [isSequentialSignatureOrder, setIsSequentialSignatureOrder] = useState<boolean>(false)
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false)

  useEffect(() => {
    void fetchProfileGroups(entityId)

    apiBarracks
      .getEntity(entityId)
      .then((entity) => {
        setIsEntityCorp(!!entity?.class?.corp)
      })
      .catch((err) => {
        Sentry.captureException(err)
      })
  }, [entityId])

  useEffect(() => {
    const isNewCreated = profileGroups.some((group) => group.id.startsWith('new_'))
    const isExistingUpdated = profileGroups.some((group) => group.updated)

    if (isNewCreated || isExistingUpdated) {
      setIsFormUpdated(true)
    }

    const shouldDisableCreatingGroup = (requiredSignatures || 0) > profileGroups.length
    setIsCreatingGroupDisabled(shouldDisableCreatingGroup)
  }, [profileGroups, requiredSignatures])

  const getEntityRules = async (entityId: string): Promise<void> => {
    try {
      const entityRules = await apiRules.getEntityRules(entityId)
      setEntityRules(entityRules)
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const fetchProfileGroups = async (entityId: string): Promise<void> => {
    try {
      const profileGroups = await apiBarracks.getProfileGroups(entityId)
      setProfileGroups(profileGroups)
    } catch (error) {
      notification.error({
        message: 'An error ocurred while fetching profile groups',
        placement: 'topRight',
      })
      Sentry.captureException(error)
    }
  }

  const handleUpdatePasswordPolicyRule = async (data: PasswordPolicyRule, id: string): Promise<void> => {
    try {
      setShowLoader(true)
      await apiRules.updateEntityPasswordPolicyRule(data, id)
      void getEntityRules(id)
      notification.success({
        message: intl.formatMessage(entity['entity.form.action.edit']),
        placement: 'topRight',
      })
    } catch (error) {
      notification.warning({
        message: 'Action failed!',
        description: intl.formatMessage(messages['messages.error.user.add']),
        placement: 'topRight',
      })
      Sentry.captureException(error)
    } finally {
      setShowLoader(false)
    }
  }

  const handleUpdateEntity2FARule = async (data: TwoFactorAuthenticationRule, id: string): Promise<void> => {
    try {
      setShowLoader(true)
      await apiRules.updateEntity2FARule(data, id)
      void getEntityRules(id)
      notification.success({
        message: intl.formatMessage(entity['entity.form.action.edit']),
        placement: 'topRight',
      })
    } catch (error) {
      notification.warning({
        message: 'Action failed!',
        description: intl.formatMessage(messages['messages.error.user.add']),
        placement: 'topRight',
      })
      Sentry.captureException(error)
    } finally {
      setShowLoader(false)
    }
  }

  const handleUpdateEntitySignChainRule = async (data: SignChainRule[], id: string): Promise<void> => {
    try {
      setShowLoader(true)
      await apiRules.updateEntitySignChainRule(data, id)
      void getEntityRules(id)
      notification.success({
        message: intl.formatMessage(entity['entity.form.action.edit']),
        placement: 'topRight',
      })
    } catch (error) {
      notification.warning({
        message: 'Action failed!',
        description: intl.formatMessage(messages['messages.error.user.add']),
        placement: 'topRight',
      })
      Sentry.captureException(error)
    } finally {
      setShowLoader(false)
    }
  }

  useEffect(() => {
    if (entityId) {
      void getEntityRules(entityId)
    }
  }, [entityId])

  const handleValuesChange = (
    changedValues: Partial<OrganizationSettingsFields>,
    values: OrganizationSettingsFields
  ): void => {
    const { what, when, requiredSignatures, amountFrom } = changedValues

    if (what) {
      setWhat(what)
      values.what = what
    }

    if (when) {
      setWhen(when)
      values.when = when
    }

    if (requiredSignatures) {
      setRequiredSignatures(requiredSignatures)
      values.requiredSignatures = requiredSignatures
    }

    if (amountFrom) {
      setAmountFrom(+amountFrom)
      values.amountFrom = +amountFrom
    }

    delete values['minAlpha']
    delete values['minLength']
    delete values['minNumeric']
    delete values['minSpecial']

    values.passwordPolicyType = passwordPolicyType
    const formNotChanged = isEqual(initialFieldValues, values)
    setIsFormUpdated(formNotChanged)
  }

  const handleSubmit = async (): Promise<void> => {
    setIsButtonLoading(true)
    prepareEntityRulesRequest(entityRules!.logic)

    if (
      initialFieldValues!.is2FAEnabled != is2FAEnabled ||
      initialFieldValues!.when != when ||
      initialFieldValues!.what != what
    ) {
      await handleUpdateEntity2FARule(entityRules!.logic.twoFactorAuthentication.rule, entityId)
    }

    if (initialFieldValues!.passwordPolicyType != passwordPolicyType) {
      if (entityRules!.logic.passwordPolicy?.rule) {
        await handleUpdatePasswordPolicyRule(entityRules!.logic.passwordPolicy.rule, entityId)
        setPasswordPolicyTypeInitial(passwordPolicyType)
      }
    }

    if (
      initialFieldValues!.requiredSignaturesEnabled != isDualSigneeEnabled ||
      initialFieldValues!.amountFrom != amountFrom ||
      initialFieldValues!.requiredSignatures != requiredSignatures
    ) {
      await handleUpdateEntitySignChainRule(entityRules!.logic.requiredSignChain.rule, entityId)
    }

    await handleSaveProfileGroups()
    setIsButtonLoading(false)
  }

  const createMultipleGroups = async (groupsToCreate: Array<Partial<ProfileGroup>>): Promise<void> => {
    try {
      await apiBarracks.createProfileGroup(entityId, groupsToCreate)
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const updateProfileGroup = async (profileGroup: ProfileGroup): Promise<void> => {
    const profileGroupClean = profileGroup
    delete profileGroupClean.updated
    try {
      await apiBarracks.updateProfileGroup(entityId, profileGroup.id, profileGroupClean)
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const handleSaveProfileGroups = async (): Promise<void> => {
    const newGroupsToCreate: Array<Partial<ProfileGroup>> = profileGroups
      .filter((group: ProfileGroup) => group.id.startsWith('new_'))
      .map((group: ProfileGroup) => ({
        title: group.title,
        profileIds: group.profileIds,
      }))

    if (newGroupsToCreate.length) {
      await createMultipleGroups(newGroupsToCreate)
    }

    await Promise.all(
      profileGroups.map(async (group) => {
        // update existing groups with new data
        if (group.updated && !group.id.startsWith('new_')) {
          await updateProfileGroup(group)
        }
      })
    )

    // reset the state and fetch saved data
    await fetchProfileGroups(entityId)
  }

  const prepareEntityRulesRequest = (entityRules: RulesLogic): void => {
    if (is2FAEnabled) {
      entityRules.twoFactorAuthentication.rule.when = when as TwoFactorAuthenticationRule['when']
      entityRules.twoFactorAuthentication.rule.what = what as TwoFactorAuthenticationRule['what']
    } else {
      entityRules.twoFactorAuthentication.rule.when = 'never'
    }

    if (passwordPolicyType == 'strict') {
      entityRules.passwordPolicy!.rule.minAlpha = 1
      entityRules.passwordPolicy!.rule.minSpecial = 1
      entityRules.passwordPolicy!.rule.minNumeric = 1
      entityRules.passwordPolicy!.rule.minLength = 8
      entityRules.passwordPolicy!.rule.maxLength = 100
    } else if (passwordPolicyType == 'default') {
      entityRules.passwordPolicy!.rule.minAlpha = 0
      entityRules.passwordPolicy!.rule.minSpecial = 0
      entityRules.passwordPolicy!.rule.minNumeric = 0
      entityRules.passwordPolicy!.rule.minLength = 8
      entityRules.passwordPolicy!.rule.maxLength = 100
    }

    if (entityRules.requiredSignChain?.rule) {
      entityRules.requiredSignChain?.rule?.forEach((rule) => {
        if (!isDualSigneeEnabled) {
          rule.amountFrom = 0
          rule.requiredSignatures = 0
        } else {
          rule.amountFrom = amountFrom
          rule.requiredSignatures = requiredSignatures || 0
        }
      })
    }
  }

  useEffect(() => {
    if (entityRules) {
      if (entityRules?.logic?.twoFactorAuthentication) {
        setWhat(entityRules.logic.twoFactorAuthentication.rule.what)
        setWhen(entityRules.logic.twoFactorAuthentication.rule.when)

        if (entityRules.logic.twoFactorAuthentication.rule.when == 'never') {
          setIs2FAEnabled(false)
        } else {
          setIs2FAEnabled(true)
        }
      }

      if (entityRules.logic.passwordPolicy) {
        setMinLength(entityRules.logic.passwordPolicy.rule.minLength)
        setMaxLength(entityRules.logic.passwordPolicy?.rule.maxLength)
        setMinNumeric(entityRules.logic.passwordPolicy?.rule.minNumeric)
        setMinAlpha(entityRules.logic.passwordPolicy?.rule.minAlpha)
        setMinSpecial(entityRules.logic.passwordPolicy?.rule.minSpecial)
      }

      let passPolicyType
      if (
        entityRules.logic.passwordPolicy?.rule.minAlpha == 1 &&
        entityRules.logic.passwordPolicy?.rule.minSpecial == 1 &&
        entityRules.logic.passwordPolicy?.rule.minNumeric == 1 &&
        entityRules.logic.passwordPolicy.rule.minLength == 8 &&
        entityRules.logic.passwordPolicy?.rule.maxLength == 100
      ) {
        setPasswordPolicyType('strict')
        setPasswordPolicyTypeInitial('strict')
        passPolicyType = 'strict'
      } else if (
        entityRules.logic.passwordPolicy?.rule.minAlpha == 0 &&
        entityRules.logic.passwordPolicy?.rule.minSpecial == 0 &&
        entityRules.logic.passwordPolicy?.rule.minNumeric == 0 &&
        entityRules.logic.passwordPolicy.rule.minLength == 8 &&
        entityRules.logic.passwordPolicy?.rule.maxLength == 100
      ) {
        setPasswordPolicyType('default')
        setPasswordPolicyTypeInitial('default')
        passPolicyType = 'default'
      } else {
        setPasswordPolicyType('custom')
        passPolicyType = 'custom'
      }

      setAmountFrom(entityRules.logic.requiredSignChain?.rule[0].amountFrom)
      setRequiredSignatures(entityRules.logic.requiredSignChain?.rule[0].requiredSignatures)

      const shouldDualSigneeEnable = entityRules.logic.requiredSignChain?.rule.some(
        (rule) => rule.requiredSignatures > 0
      )
      setIsDualSigneeEnabled(shouldDualSigneeEnable)

      const isSequentialSignatureOrder = entityRules.logic.requiredSignChain?.rule.some(
        (rule) => rule.signatureOrder === 'sequential'
      )
      setIsSequentialSignatureOrder(isSequentialSignatureOrder)

      const initialValues = {
        amountFrom: entityRules.logic.requiredSignChain?.rule[0].amountFrom,
        requiredSignatures: entityRules.logic.requiredSignChain?.rule[0].requiredSignatures,
        what: entityRules.logic.twoFactorAuthentication?.rule.what,
        when: entityRules.logic.twoFactorAuthentication?.rule.when,
        requiredSignaturesEnabled:
          entityRules.logic.requiredSignChain?.rule[0].amountFrom != 0 &&
          entityRules.logic.requiredSignChain?.rule[0].requiredSignatures != 0,
        is2FAEnabled: entityRules.logic.twoFactorAuthentication?.rule.when != 'never',
        passwordPolicyType: passPolicyType,
      }

      setInitialFieldValues(initialValues)

      setIsFormUpdated(true)
      setShowLoader(false)
    }
  }, [entityRules])

  useEffect(() => {
    form.resetFields()
  }, [initialFieldValues])

  useEffect(() => {
    if (passwordPolicyType == 'strict') {
      setMinAlpha(1)
      setMinNumeric(1)
      setMinSpecial(1)
    } else if (passwordPolicyType == 'default') {
      setMinAlpha(0)
      setMinNumeric(0)
      setMinSpecial(0)
    }
  }, [passwordPolicyType])

  const render2FA = (): React.JSX.Element => {
    if (is2FAEnabled) {
      return (
        <React.Fragment>
          <Form.Item
            className={styles.formItem}
            label={intl.formatMessage(page['page.settings.slider.2FA.frequency.label'])}
            name="when"
          >
            <Radio.Group>
              <Radio key="device" value="device">
                {intl.formatMessage(page['page.settings.slider.2FA.frequency.once.label'])}
              </Radio>
              <Radio key="always" value="always">
                {intl.formatMessage(page['page.settings.slider.2FA.frequency.always.label'])}
              </Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            className={styles.formItem}
            label={intl.formatMessage(page['page.settings.slider.2FA.mode.label'])}
            name="what"
          >
            <Radio.Group>
              <Radio key="sms" value="sms">
                {intl.formatMessage(page['page.settings.slider.2FA.mode.sms.label'])}
              </Radio>
              <Radio key="mail" value="mail">
                {intl.formatMessage(page['page.settings.slider.2FA.mode.email.label'])}
              </Radio>
            </Radio.Group>
          </Form.Item>
        </React.Fragment>
      )
    } else {
      return <React.Fragment></React.Fragment>
    }
  }

  const renderPasswordPolicy = (): React.JSX.Element => {
    if (passwordPolicyType == 'strict' || passwordPolicyType == 'custom') {
      return (
        <Descriptions column={1} className={styles.passwordItems}>
          <Descriptions.Item label={intl.formatMessage(entity['organizationSettings.passwordPolicy.minLength'])}>
            {minLength}
          </Descriptions.Item>

          <Descriptions.Item label={intl.formatMessage(entity['organizationSettings.passwordPolicy.minAlpha'])}>
            {minAlpha}
          </Descriptions.Item>

          <Descriptions.Item label={intl.formatMessage(entity['organizationSettings.passwordPolicy.minNum'])}>
            {minNumeric}
          </Descriptions.Item>

          <Descriptions.Item label={intl.formatMessage(entity['organizationSettings.passwordPolicy.minSpecial'])}>
            {minSpecial}
          </Descriptions.Item>
        </Descriptions>
      )
    } else {
      return (
        <Descriptions column={1} className={styles.passwordItems}>
          <Descriptions.Item label={intl.formatMessage(entity['organizationSettings.passwordPolicy.minLength'])}>
            {minLength}
          </Descriptions.Item>
        </Descriptions>
      )
    }
  }

  const renderDualSignee = (): React.JSX.Element => {
    if (isDualSigneeEnabled) {
      return (
        <div className={styles.dualSigneeWrapper}>
          <Form.Item
            className={styles.signeeFormItem}
            label={intl.formatMessage(entity['organizationSettings.dualSigneeNumber.amountAbove'])}
            name="amountFrom"
          >
            <Input
              className={styles.signeeInput}
              type="number"
              placeholder={intl.formatMessage(entity['organizationSettings.passwordPolicy.lengthPlaceholder'])}
              variant="borderless"
              name="amountFrom"
            ></Input>
          </Form.Item>

          <Form.Item
            className={styles.signeeFormItem}
            label={intl.formatMessage(entity['organizationSettings.dualSigneeNumber.numberOfSignee'])}
            name="requiredSignatures"
          >
            <InputNumber
              className={styles.signeeSelect}
              value={requiredSignatures || 0}
              placeholder={intl.formatMessage(
                entity['organizationSettings.dualSigneeNumber.numberOfSigneePlaceholder']
              )}
              onChange={(value) => setRequiredSignatures(value)}
            />
          </Form.Item>

          {!!requiredSignatures && isSequentialSignatureOrder && (
            <div className="profile-groups-section">
              <ProfileGroups groups={profileGroups} setGroups={setProfileGroups} />
              {isCreatingGroupDisabled && (
                <span className={styles.requiredSignaturesError}>
                  {intl.formatMessage(entity['organizationSettings.signature.error'])}
                </span>
              )}
            </div>
          )}
        </div>
      )
    } else {
      return <div></div>
    }
  }

  return (
    <div>
      {entityRules || showLoader ? (
        <Loader showLoader={showLoader}>
          <Form
            className="settings-tab-wrapper"
            form={form}
            id="settings-form"
            layout="vertical"
            name="basic"
            requiredMark={false}
            size="small"
            initialValues={{
              is2FAEnabled: is2FAEnabled,
              what: what,
              when: when,
              minLength: minLength,
              minSpecial: minSpecial,
              minAlpha: minAlpha,
              minNumeric: minNumeric,
              amountFrom: amountFrom,
              requiredSignatures: requiredSignatures,
              requiredSignaturesEnabled: isDualSigneeEnabled,
            }}
            onValuesChange={handleValuesChange}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onFinish={handleSubmit}
          >
            <div className={styles.cardWrapper}>
              <div className={styles.header}>
                <span className={styles.title}>
                  <span className={styles.icon}>
                    <img src={lockIcon} alt="logo" />
                  </span>
                  <span>{intl.formatMessage(entity['organizationSettings.twoFactorAuthentication.label'])}</span>
                </span>
                <div className={styles.subtitleWrapper}>
                  <span className={styles.subtitle}>
                    {intl.formatMessage(entity['organizationSettings.twoFactorAuthentication.intro'])}
                  </span>
                  <Form.Item className={styles.subtitleSwitch} name="is2FAEnabled">
                    <Switch
                      size="default"
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={is2FAEnabled}
                      onChange={() => {
                        setIs2FAEnabled(!is2FAEnabled)
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className={styles.content}>{render2FA()}</div>
            </div>

            <div className={styles.cardWrapper}>
              <div className={styles.header}>
                <span className={styles.title}>
                  <span className={styles.icon}>
                    <img src={passwordIcon} alt="logo" />
                  </span>
                  <span>{intl.formatMessage(entity['organizationSettings.passwordPolicy.label'])}</span>
                </span>
                <div className={styles.subtitleWrapper}>
                  <span className={styles.subtitle}>
                    {intl.formatMessage(entity['organizationSettings.passwordPolicy.intro'])}
                  </span>
                  <Form.Item className={styles.formItem}>
                    <Radio.Group
                      value={passwordPolicyType}
                      defaultValue={passwordPolicyType}
                      onChange={(e: RadioChangeEvent) => {
                        setPasswordPolicyType(e.target.value as string)
                        const policy = e.target.value as string
                        const updatedFormValues = {
                          amountFrom: amountFrom,
                          requiredSignatures: requiredSignatures,
                          what: what,
                          when: when,
                          requiredSignaturesEnabled: isDualSigneeEnabled,
                          is2FAEnabled: is2FAEnabled,
                          passwordPolicyType: policy,
                        }
                        delete initialFieldValues!['minAlpha']
                        delete initialFieldValues!['minLength']
                        delete initialFieldValues!['minNumeric']
                        delete initialFieldValues!['minSpecial']
                        const formChanged = isEqual(initialFieldValues, updatedFormValues)

                        setIsFormUpdated(formChanged)
                      }}
                      name="passwordPolicyType"
                    >
                      {passwordPolicyType == 'custom' && (
                        <Radio key="password-strict" value="custom">
                          {intl.formatMessage(entity['organizationSettings.passwordPolicy.customLabel'])}
                        </Radio>
                      )}

                      {passwordPolicyType != 'custom' && (
                        <div>
                          <Radio key="strict" value="strict">
                            {intl.formatMessage(entity['organizationSettings.passwordPolicy.strictLabel'])}
                          </Radio>
                          <Radio key="default" value="default">
                            {intl.formatMessage(entity['organizationSettings.passwordPolicy.defaultLabel'])}
                          </Radio>
                        </div>
                      )}
                    </Radio.Group>
                  </Form.Item>
                </div>
              </div>
              <div className={styles.content}>{renderPasswordPolicy()}</div>
            </div>

            {isEntityCorp && (
              <div className={styles.cardWrapper}>
                <div className={styles.header}>
                  <span className={styles.title}>
                    <span className={styles.icon}>
                      <img src={dualSigneeIcon} alt="logo" />
                    </span>
                    <span>{intl.formatMessage(entity['organizationSettings.dualSignee.label'])}</span>
                  </span>
                  <div className={styles.subtitleWrapper}>
                    <span className={styles.subtitle}>
                      {intl.formatMessage(entity['organizationSettings.dualSignee.intro2'])}
                    </span>
                    <Form.Item className={styles.subtitleSwitch} name="isDualSigneeRequired">
                      <Switch
                        size="default"
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        checked={isDualSigneeEnabled}
                        onChange={() => {
                          setIsDualSigneeEnabled(!isDualSigneeEnabled)
                        }}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className={styles.content}>{renderDualSignee()}</div>
              </div>
            )}
            <Row>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                disabled={!isFormUpdated || isCreatingGroupDisabled || isButtonLoading}
                block
                loading={isButtonLoading}
              >
                {intl.formatMessage(entity['entity.form.button.save'])}
              </Button>
            </Row>
          </Form>
        </Loader>
      ) : (
        <div></div>
      )}
    </div>
  )
}

export default SettingsTab
