import { api } from './utils'
import { Report, ReportKeys } from '../pages/Reports/utils'

const BASE_PATH = 'reporter/report'

export const getReport = async (userId: string): Promise<string> => {
  const url = `/${BASE_PATH}/${userId}`
  const response = await api.get(url)

  const report = (await response.json()) as string
  return report
}

export const createReport = async (data: Report): Promise<Report> => {
  const url = `/${BASE_PATH}`

  const response = await api.post(url, data)
  const value = (await response.json()) as Report
  return value
}

export const downloadReport = async (reportId: string): Promise<ArrayBuffer> => {
  const url = `/${BASE_PATH}/${reportId}/file`

  const response = await api.get(url)
  return await response.arrayBuffer()
}

export const getKeys = async (): Promise<ReportKeys[]> => {
  const url = `/${BASE_PATH}/keys`

  const response = await api.get(url)
  return await response.json()
}
