import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css, token }) => ({
  container: css`
    height: 100%;
  `,
  tabs: css`
    .ant-tabs.ant-tabs-top {
      .ant-tabs-tab {
        padding: 12px 24px;
      }
      .ant-tabs-nav {
        margin: 0 0 12px 0;
      }
    }
    .ant-badge {
      .ant-badge-count {
        background-color: ${token.colorError};
        top: -2px;
        right: -15px;
      }
    }
  `,
  tab: css`
    height: 100%;
  `,
}))

export default useStyles
