import { useEffect, useState } from 'react'
import { HubspotConversation, HubspotChatSettings, HubspotChat } from 'types/hubspot'

const hubspotId = window.VITE_HUBSPOT_ID
const scriptId = 'hs-script-loader'

declare global {
  interface Window {
    hsConversationsOnReady: ((callback: () => void) => void)[] | undefined
    hsConversationsSettings: HubspotChatSettings
    HubSpotConversations: HubspotConversation
  }
}

const loadHubspotScript = () => {
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.async = true
  script.defer = true
  script.id = scriptId
  script.src = `//js-eu1.hs-scripts.com/${hubspotId}.js`
  script.async = true
  document.body.appendChild(script)
}

export const useHubspotChat = (): HubspotChat => {
  const [chatInitialized, setChatInitialized] = useState<boolean>(false)

  useEffect(() => {
    return () => {
      removeHubspotChat()
    }
  }, [])

  const initalizeHubspotChat = (email: string, token: string) => {
    window.hsConversationsSettings = {
      loadImmediately: false,
      identificationEmail: email,
      identificationToken: token,
    }

    if (window.HubSpotConversations) {
      setChatInitialized(true)
    } else {
      window.hsConversationsOnReady = [
        () => {
          setChatInitialized(true)
        },
      ]
      loadHubspotScript()
    }
  }

  const loadHubspotChat = () => {
    if (!chatInitialized) {
      return
    }
    window.HubSpotConversations.widget.load()
  }

  const removeHubspotChat = () => {
    if (!chatInitialized) {
      return
    }
    window.HubSpotConversations.clear({ resetWidget: true })
    setChatInitialized(false)
  }

  const openHubspotChat = () => {
    if (!chatInitialized) {
      return
    }
    window.HubSpotConversations.widget.open()
  }

  return {
    chatInitialized,
    setChatInitialized,
    loadHubspotChat,
    removeHubspotChat,
    openHubspotChat,
    initalizeHubspotChat,
  }
}
