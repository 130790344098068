import { Radio, RadioChangeEvent } from 'antd'
import React from 'react'
import { OptionData } from './utils'

interface GroupProps {
  options: OptionData[]
  selectedValue: string
  type: string
  onRadioChange: (value: RadioChangeEvent, type: string) => void
}

const RadioGroupFields = ({ options, selectedValue, type, onRadioChange }: GroupProps): React.JSX.Element => {
  return (
    <Radio.Group
      onChange={(event: RadioChangeEvent) => onRadioChange(event, type)}
      options={options}
      value={selectedValue}
      data-testid={`radio-group-wrapper-${type}`}
    />
  )
}

export default RadioGroupFields
