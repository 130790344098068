import { api } from './utils'
import { AlertMessage, AlertMessageFilter } from '../types/messages'

const BASE_PATH = 'times-square'

interface MessageResponse {
  messages: AlertMessage[]
  total: number
}

export const updateMessageSeenBy = async (messageId: string, userId: string): Promise<AlertMessage> => {
  const url = `/${BASE_PATH}/message/${messageId}/seen/${userId}`
  const response = await api.patch(url, {})
  return response.json()
}
export const updateMessageClosedBy = async (messageId: string, userId: string): Promise<AlertMessage> => {
  const url = `/${BASE_PATH}/message/${messageId}/close/${userId}`
  const response = await api.patch(url, {})
  return response.json()
}

export const searchMessages = async (filter: AlertMessageFilter): Promise<MessageResponse> => {
  const url = `/${BASE_PATH}/search`

  const body = {
    kind: 'message',
    ...filter,
  }

  const response = await api.post(url, body)

  const messages = (await response.json()) as AlertMessage[]
  const total = response.headers.get('x-total-count')

  return {
    messages,
    total: Number.parseInt(total || '', 10),
  }
}

export const searchExternalMessages = async (filter: AlertMessageFilter): Promise<MessageResponse> => {
  const url = `/external/${BASE_PATH}/search`

  const body = {
    kind: 'message',
    ...filter,
  }

  const response = await api.post(url, body)

  const messages = (await response.json()) as AlertMessage[]
  const total = response.headers.get('x-total-count')

  return {
    messages,
    total: Number.parseInt(total || '', 10),
  }
}
