import React, { useEffect } from 'react'
import { Divider, Form, FormInstance } from 'antd'
import {
  RecipientTitle,
  RoutingNumber,
  AccountNumber,
  TypeSelector,
  AddressStreet,
  AddressCity,
  AddressZip,
  AddressState,
  AddressApartment,
  CareOf,
} from './FormItems'
import { AccountTypes, Address } from '../../../types/beneficiary'
import { SpaceEven, SpaceForm } from '../components'
import { useTranslation } from '../../../utils/helpers'
import {
  useLocalUSAccountNumberValidator,
  useLocalUsRoutingNumberValidator,
  UsRoutingNumberMaxLength,
  UsAccountNumberMaxLength,
} from 'utils/validators'

interface Props {
  getFormInstance?: (form: FormInstance) => void
  onFinish?: (values: LocalUSForm) => void
}

export type LocalUSForm = {
  title?: string
  accountNumber?: string
  type?: string
  accountType?: AccountTypes
  address: Address
}

const countryCode = 'us'

const initialValues: LocalUSForm = {
  address: {
    countryCode,
  },
}

const LocalUS = ({ getFormInstance, onFinish }: Props): React.JSX.Element => {
  const [form] = Form.useForm<LocalUSForm>()
  const t = useTranslation()

  const accountNumberValidator = useLocalUSAccountNumberValidator()

  const routingNumberValidator = useLocalUsRoutingNumberValidator()

  useEffect(() => {
    getFormInstance?.(form)
  }, [form])

  return (
    <Form
      form={form}
      onFinish={onFinish}
      initialValues={initialValues}
      layout="vertical"
      name="swift"
      data-testid="local-us-form"
    >
      <SpaceForm>
        <RecipientTitle />

        <CareOf />

        <RoutingNumber
          label={t('beneficiary.routingNumber')}
          validator={routingNumberValidator}
          maxLength={UsRoutingNumberMaxLength}
        />

        <AccountNumber
          label={t('beneficiary.accountNumber')}
          validator={accountNumberValidator}
          maxLength={UsAccountNumberMaxLength}
        />

        <TypeSelector type="account" />
      </SpaceForm>

      <Divider className="form-divider" />

      <SpaceForm>
        <AddressStreet />

        <AddressApartment />

        <AddressCity />

        <SpaceEven gap={16}>
          <AddressZip key="ach-address-zip" />
          <AddressState key="ach-address-state" />
        </SpaceEven>

        <TypeSelector type="beneficiary" />
      </SpaceForm>

      <button type="submit" style={{ display: 'none' }} />
    </Form>
  )
}

export default LocalUS
