import React from 'react'
import { Alert, Form } from 'antd'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { SWIFTForm } from '../RecipientsDrawer/SWIFT'
import iban from 'iban'
import { bicCodePattern } from 'utils/validators'
import { useTranslation } from 'utils/helpers'
import FormItem from 'antd/es/form/FormItem'

const alertStyle = {
  backgroundColor: '#F8E6E6',
  borderColor: '#EBB3B3',
}

export default function CountryWarning() {
  const t = useTranslation()
  const form = Form.useFormInstance<SWIFTForm>()
  const accountNumber = Form.useWatch('accountNumber', form)
  const routingNumber = Form.useWatch('routingNumber', form)

  const CountryMatchFormItem = (
    <FormItem
      name="countryMatch"
      style={{ display: 'none' }}
      rules={[{ validator: (_, countryMatch) => (countryMatch ? Promise.resolve() : Promise.reject()) }]}
    >
      <input />
    </FormItem>
  )

  if (!form.isFieldTouched('accountNumber') || !form.isFieldTouched('routingNumber')) {
    form.setFieldValue('countryMatch', true)
    return CountryMatchFormItem
  }

  const isAnyInvalid = form.getFieldsError(['accountNumber', 'routingNumber']).some((error) => error.errors.length > 0)

  if (isAnyInvalid) {
    form.setFieldValue('countryMatch', true)
    return CountryMatchFormItem
  }

  if (!iban.isValid(accountNumber) || !bicCodePattern.test(routingNumber)) {
    form.setFieldValue('countryMatch', true)
    return CountryMatchFormItem
  }

  if (accountNumber.substring(0, 2).toLowerCase() === routingNumber.slice(4, 6).toLowerCase()) {
    form.setFieldValue('countryMatch', true)
    return CountryMatchFormItem
  }

  form.setFieldValue('countryMatch', false)

  return (
    <>
      {CountryMatchFormItem}
      <Alert
        message={t('beneficiary.swift.country.mismatch.title')}
        description={t('beneficiary.swift.country.mismatch.description')}
        data-testid="account-mismatch-alert"
        type="error"
        icon={<ExclamationCircleFilled />}
        showIcon
        style={alertStyle}
      />
    </>
  )
}
