/* eslint-disable react/prop-types */
import React from 'react'
import { Descriptions } from 'antd'
import { useIntl } from 'react-intl'
import { ReportDetails } from '../../types/activity'
import { activity } from '../../lang/definitions/activity'

interface ReportEventDetailsProps {
  report: ReportDetails
}

const ReportEventDetails = ({ report }: ReportEventDetailsProps): React.JSX.Element => {
  const intl = useIntl()
  const renderDelivery = (report: ReportDetails): React.JSX.Element => {
    let content: React.JSX.Element = <div></div>

    if (report.delivery.email.addresses) {
      const mails = report.delivery.email.addresses.map((mail, index) => <p key={index}>{mail}</p>)
      content = (
        <Descriptions.Item label={intl.formatMessage(activity['activity.report.create.delivery.email'])}>
          {mails}
        </Descriptions.Item>
      )
    } else {
      content = (
        <Descriptions.Item label={intl.formatMessage(activity['activity.report.create.delivery.download'])}>
          {''}
        </Descriptions.Item>
      )
    }

    return content
  }

  return (
    <Descriptions bordered layout="vertical" size="small" className="bh-description-vertical">
      {report.output.file.fileName && (
        <Descriptions.Item label={intl.formatMessage(activity['activity.report.create.fileName'])} span={3}>
          {report.output.file.fileName}
        </Descriptions.Item>
      )}
      {report.profileInfo.name && (
        <Descriptions.Item label={intl.formatMessage(activity['activity.details.createdBy'])} span={3}>
          {report.profileInfo.name.first} {report.profileInfo.name.last}
        </Descriptions.Item>
      )}
      {report.profileInfo.title && (
        <Descriptions.Item label={intl.formatMessage(activity['activity.details.entity'])} span={3}>
          {report.profileInfo.title}
        </Descriptions.Item>
      )}
      {renderDelivery(report)}
    </Descriptions>
  )
}

export default ReportEventDetails
