import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css, token }) => ({
  container: css`
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: space-between;
  `,
  cardDetailsWrapper: css`
    margin-top: ${token.marginXXS}px;
  `,
  cardTitle: css`
    span {
      font-size: ${token.fontSize}px;
      font-weight: 500;
      line-height: 14px;
    }
  `,
  cardDetails: css`
    display: flex;
    align-items: center;
  `,
  cardNumber: css`
    margin-left: ${token.marginXXS}px;
  `,
  cardCurrency: css`
    margin-left: 10px;
  `,
}))

export default useStyles
