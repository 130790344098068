import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { Tabs } from 'antd'
import * as Sentry from '@sentry/react'
import { page, reports } from 'lang/definitions'
import * as templateApi from 'api/reportTemplate'
import Templates from './Templates/Templates'
import Page from 'components/Page/Page'
import { QueryParams } from 'types/general'
import { ReportTemplate } from 'types/report'
import Reports from './Reports'

interface Props {
  location: Location
}

export interface ReportFields {
  value: string
  label: string
  disabled: boolean
}

const ReportsTabs = ({ location }: Props): React.JSX.Element => {
  const history = useHistory()
  const intl = useIntl()
  const [activeTab, setActiveTab] = useState<string>('1')

  // USED FROM TEMPLATES
  const [reportTemplates, setReportTemplates] = useState<ReportTemplate[]>([])
  const [total, setTotal] = useState<number>()
  const [showLoader, setShowLoader] = useState(false)
  const [template, setTemplate] = useState<ReportTemplate>()

  const handleSetTemplate = (data: ReportTemplate): void => {
    setTemplate(data)
  }

  const getReportTemplates = async (profileId: string, params?: QueryParams): Promise<void> => {
    try {
      setShowLoader(true)
      const { templates, total } = await templateApi.getReportTemplate(profileId, params)
      setReportTemplates(templates)
      setTotal(total)
    } catch (error) {
      Sentry.captureException(error)
    } finally {
      setShowLoader(false)
    }
  }

  // handle TABS
  const showTabFromHash = (): void => {
    const windowHash = window.location.hash
    if (windowHash) {
      const hashArray = windowHash.split(/[#,/]/)
      const hashId = hashArray[1]
      let activeTabKey = ''
      if (hashArray.length === 2) {
        switch (hashId) {
          case 'reports': {
            activeTabKey = '1'
            break
          }
          case 'templates': {
            activeTabKey = '2'
            break
          }
          default:
            activeTabKey = '1'
            break
        }
        setActiveTab(activeTabKey)
      }
    }
  }
  useEffect(() => {
    void showTabFromHash()
  }, [window.location.hash])

  return (
    <Page title={intl.formatMessage(reports['reports.header'])} mobileMenuOptions={[]}>
      <Tabs
        className="settings-tabs"
        activeKey={activeTab}
        onChange={(key: string) => {
          let activeTab = ''
          switch (key) {
            case '1': {
              activeTab = 'reports'
              break
            }
            case '2': {
              activeTab = 'templates'
              break
            }
            default: {
              activeTab = 'reports'
              break
            }
          }
          history.push(`#${activeTab}`)
          setActiveTab(key)
        }}
        items={[
          {
            key: '1',
            label: intl.formatMessage(page['page.reports.tab.reports.label']),
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            children: <Reports location={location} template={template} getReportTemplates={getReportTemplates} />,
          },
          {
            key: '2',
            label: intl.formatMessage(page['page.reports.tab.templates.label']),
            children: (
              <Templates
                isLoadingTemplates={showLoader}
                reportTemplates={reportTemplates}
                total={total}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                getReportTemplates={getReportTemplates}
                handleSetTemplate={handleSetTemplate}
                setActiveTab={setActiveTab}
              />
            ),
          },
        ]}
      />
    </Page>
  )
}
export default ReportsTabs
