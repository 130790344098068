import { defineMessages } from 'react-intl'

export const vcn = defineMessages({
  'vcn.airplus.date.validation.label': {
    id: 'vcn.airplus.date.validation.label',
    defaultMessage: 'Please enter the date in the format: DDMMMYY (eg. 01JUN20)',
    description: 'VCN card date validation label',
  },
  'vcn.airplus.optional.label': {
    id: 'vcn.airplus.optional.label',
    defaultMessage: 'optional',
    description: 'VCN card optional label',
  },
  'vcn.airplus.dbi.error.format.message': {
    id: 'vcn.airplus.dbi.error.format.message',
    defaultMessage: 'Incorrect format',
    description: 'VCN card DBI error format message',
  },
  'vcn.airplus.dbi.error.required.message': {
    id: 'vcn.airplus.dbi.error.required.message',
    defaultMessage: 'This field cannot be blank',
    description: 'VCN card DBI error required message',
  },
  'vcn.airplus.dbi.placeholder': {
    id: 'vcn.airplus.dbi.placeholder',
    defaultMessage: 'Add {label}',
    description: 'VCN card DBI placeholder',
  },
  'vcn.airplus.dbi.ae.label': {
    id: 'vcn.airplus.dbi.ae.label',
    defaultMessage: 'Accounting unit',
    description: 'VCN card AE label',
  },
  'vcn.airplus.dbi.ak.label': {
    id: 'vcn.airplus.dbi.ak.label',
    defaultMessage: 'Accounting code',
    description: 'VCN card AK label',
  },
  'vcn.airplus.dbi.au.label': {
    id: 'vcn.airplus.dbi.au.label',
    defaultMessage: 'Order number',
    description: 'VCN card AU label',
  },
  'vcn.airplus.dbi.bd.label': {
    id: 'vcn.airplus.dbi.bd.label',
    defaultMessage: 'Departure date',
    description: 'VCN card BD label',
  },
  'vcn.airplus.dbi.ds.label': {
    id: 'vcn.airplus.dbi.ds.label',
    defaultMessage: 'Department code',
    description: 'VCN card DS label',
  },
  'vcn.airplus.dbi.ik.label': {
    id: 'vcn.airplus.dbi.ik.label',
    defaultMessage: 'Internal account',
    description: 'VCN card IK label',
  },
  'vcn.airplus.dbi.ks.label': {
    id: 'vcn.airplus.dbi.ks.label',
    defaultMessage: 'Cost centre',
    description: 'VCN card KS label',
  },
  'vcn.airplus.dbi.pk.label': {
    id: 'vcn.airplus.dbi.pk.label',
    defaultMessage: 'Employee number',
    description: 'VCN card PK label',
  },
  'vcn.airplus.dbi.pr.label': {
    id: 'vcn.airplus.dbi.pr.label',
    defaultMessage: 'Project number',
    description: 'VCN card PR label',
  },
  'vcn.airplus.dbi.rz.label': {
    id: 'vcn.airplus.dbi.rz.label',
    defaultMessage: 'Destination',
    description: 'VCN card RZ label',
  },
})
