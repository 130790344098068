import { PAYMENT_KIND, PAYMENT_STATE, Payment } from 'types/paymentInstruction'

export default function sortPaymentsByKindAndState(payments: Payment[]): {
  readyPiIds: string[]
  readyTemplateIds: string[]
  requireSignaturePiIds: string[]
  requireSignatureTemplateIds: string[]
} {
  const mapToId = (payment: Payment): string => payment.id

  return {
    readyPiIds: payments
      .filter(({ kind, state }) => kind === PAYMENT_KIND.PAYMENT_INSTRUCTION && state === PAYMENT_STATE.READY)
      .map(mapToId),
    requireSignaturePiIds: payments
      .filter(({ kind, state }) => kind === PAYMENT_KIND.PAYMENT_INSTRUCTION && state !== PAYMENT_STATE.READY)
      .map(mapToId),
    readyTemplateIds: payments
      .filter(({ kind, state }) => kind !== PAYMENT_KIND.PAYMENT_INSTRUCTION && state === PAYMENT_STATE.READY)
      .map(mapToId),
    requireSignatureTemplateIds: payments
      .filter(({ kind, state }) => kind !== PAYMENT_KIND.PAYMENT_INSTRUCTION && state !== PAYMENT_STATE.READY)
      .map(mapToId),
  }
}
