import { Descriptions } from 'antd'
import React, { useState } from 'react'
import { Details, Event, ReportDetails } from '../../types/activity'
import Date from '../Format/Date/Date'
import PaymentInstructionDetails from '../../pages/PaymentInstructions/PaymentInstructionDetails'
import BeneficiaryDetails from '../../pages/Beneficiaries/BeneficiaryDetails'
import PaymentInstructionTemplateDetails from '../../pages/PaymentInstructions/PaymentInstructionTemplateDetails'
import { Beneficiary } from '../../types/beneficiary'
import { getBeneficiary } from '../../api/beneficiary'
import { activity } from '../../lang/definitions/activity'
import { useIntl } from 'react-intl'
import SignatureDetails from '../SignatureDetails/SignatureDetails'
import { SignatureEventDetails } from '../../types/signature'
import ReportEventDetails from '../EventDetailsForm/ReportEventDetails'
import ImportCreateEventDetails from '../EventDetailsForm/ImportCreateEventDetails'
import { useLanguageState } from '../../stores/language/LanguageStore'

interface CreateEventDetailsProps {
  event: Event
}
const CreateEventDetails = ({ event }: CreateEventDetailsProps): React.JSX.Element => {
  const [languageState] = useLanguageState()
  const language = languageState.language
  const intl = useIntl()

  const [beneficiary, setBeneficiary] = useState<Beneficiary>()

  const getBeneficiaryData = async (beneficiaryId: string) => {
    try {
      const beneficiary = await getBeneficiary(beneficiaryId)
      setBeneficiary(beneficiary)
    } catch (error) {
      console.error(error)
    }
  }

  const prepareContent = (event: Event): React.JSX.Element => {
    const relatedTo = event.label.split('.', 2)[0]

    let content: React.JSX.Element = <div></div>
    const label: React.JSX.Element = (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>{event.labelText}</span>
        <Date value={event.dateCreated} locale={language} time />
      </div>
    )
    switch (relatedTo) {
      case 'user': {
        const text = intl.formatMessage(activity['activity.event.create.user.text'], {
          userValue: `${event.createdBy.name.first} ${event.createdBy.name.last}`,
        })
        content = (
          <Descriptions layout="vertical" bordered>
            <Descriptions.Item label={label}>
              <p>{text}</p>
            </Descriptions.Item>
          </Descriptions>
        )
        break
      }
      case 'entity': {
        const text = intl.formatMessage(activity['activity.event.create.entity.text'], {
          userValue: `${event.createdBy.name.first} ${event.createdBy.name.last}`,
        })
        content = (
          <Descriptions layout="vertical" bordered>
            <Descriptions.Item label={label}>
              <p>{text}</p>
            </Descriptions.Item>
          </Descriptions>
        )
        break
      }
      case 'paymentInstruction': {
        content = (
          <Descriptions layout="vertical" bordered>
            <Descriptions.Item label={label}>
              <PaymentInstructionDetails paymentInstructionId={event.relatedTo.paymentInstructionId} />
            </Descriptions.Item>
          </Descriptions>
        )
        break
      }
      case 'paymentInstructionTemplate': {
        content = (
          <Descriptions layout="vertical" bordered>
            <Descriptions.Item label={label}>
              <PaymentInstructionTemplateDetails templateId={event.relatedTo.paymentInstructionTemplateId} />
            </Descriptions.Item>
          </Descriptions>
        )
        break
      }
      case 'beneficiary': {
        if (!beneficiary) {
          void getBeneficiaryData(event.relatedTo.beneficiaryId)
        }

        content = (
          <Descriptions layout="vertical" bordered>
            <Descriptions.Item label={label}>
              {beneficiary && <BeneficiaryDetails beneficiary={beneficiary} />}
            </Descriptions.Item>
          </Descriptions>
        )
        break
      }
      case 'signatureRequest' || 'signature': {
        const signatureDetails = event.details as SignatureEventDetails
        content = (
          <Descriptions layout="vertical" bordered>
            <Descriptions.Item label={label}>
              <SignatureDetails signature={signatureDetails}></SignatureDetails>
            </Descriptions.Item>
          </Descriptions>
        )
        break
      }
      case 'report': {
        const reportDetails = event.details as ReportDetails
        content = (
          <Descriptions layout="vertical" bordered>
            <Descriptions.Item label={label}>
              <ReportEventDetails report={reportDetails}></ReportEventDetails>
            </Descriptions.Item>
          </Descriptions>
        )
        break
      }
      case 'import': {
        const importDetails = event.details as Details
        content = (
          <Descriptions layout="vertical" bordered>
            <Descriptions.Item label={label}>
              <ImportCreateEventDetails importDetails={importDetails}></ImportCreateEventDetails>
            </Descriptions.Item>
          </Descriptions>
        )
        break
      }
      default: {
        break
      }
    }

    return content
  }

  return event && <div>{prepareContent(event)}</div>
}

export default CreateEventDetails
