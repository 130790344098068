import React, { useEffect } from 'react'
import { Form, Button, Radio } from 'antd'
import { TwoFactorAuthenticationRule } from '../../../types/rules'
import { useIntl } from 'react-intl'
import { page } from '../../../lang/definitions/index'

interface Update2FAFormProps {
  rule: TwoFactorAuthenticationRule
  onSubmit: (rule: TwoFactorAuthenticationRule) => void
}

interface FormValues {
  enabled: boolean
  what: TwoFactorAuthenticationRule['what']
  when: TwoFactorAuthenticationRule['when']
}

const UpdateTwoFactorAuthenticationForm = (props: Update2FAFormProps): React.JSX.Element => {
  const { onSubmit, rule } = props
  const intl = useIntl()

  const [isSubmitDisabled, setIsSubmitDisabled] = React.useState<boolean>(true)

  const [form] = Form.useForm<FormValues>()
  const is2FAEnabled = Form.useWatch('enabled', form)
  const what = Form.useWatch('what', form)
  const when = Form.useWatch('when', form)

  React.useEffect(() => {
    setIsSubmitDisabled(is2FAEnabled && (what === 'call' || when === 'never'))
  }, [is2FAEnabled, what, when])

  useEffect(() => {
    // Set the form's initial values after the component mounts
    form.setFieldsValue({
      enabled: rule.when !== 'never',
      when: rule.when,
      what: rule.what,
    })
  }, [form, rule])

  const handleSubmit = (values: FormValues): void => {
    const updatedRule = { ...rule }
    if (values.enabled) {
      updatedRule.what = values.what
      updatedRule.when = values.when
    } else {
      updatedRule.when = 'never'
      updatedRule.what = 'call'
    }
    onSubmit(updatedRule)
  }

  return (
    <React.Fragment>
      <Form
        className="2fa-form"
        form={form}
        id="2fa-form"
        layout="vertical"
        name="basic"
        requiredMark={false}
        onFinish={handleSubmit}
        initialValues={{
          enabled: rule.when !== 'never',
          when: rule.when,
          what: rule.what,
        }}
      >
        <Form.Item
          className="bh-form-item"
          label={intl.formatMessage(page['page.settings.slider.2FA.enable.label'])}
          name="enabled"
        >
          <Radio.Group className="bh-radio-group" name="enabled">
            <Radio.Button key="enable-2fa-yes" value={true}>
              {intl.formatMessage(page['page.settings.slider.2FA.enable.on.label'])}
            </Radio.Button>
            <Radio.Button key="enable-2fa-no" value={false}>
              {intl.formatMessage(page['page.settings.slider.2FA.enable.off.label'])}
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
        {is2FAEnabled && (
          <div>
            <Form.Item
              className="bh-form-item"
              name="when"
              label={intl.formatMessage(page['page.settings.slider.2FA.frequency.label'])}
            >
              <Radio.Group className="bh-radio-group" name="when">
                <Radio.Button key="frequency-2fa-once" value="device">
                  {intl.formatMessage(page['page.settings.slider.2FA.frequency.once.label'])}
                </Radio.Button>
                <Radio.Button key="frequency-2fa-always" value="always">
                  {intl.formatMessage(page['page.settings.slider.2FA.frequency.always.label'])}
                </Radio.Button>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              className="bh-form-item"
              name="what"
              label={intl.formatMessage(page['page.settings.slider.2FA.mode.label'])}
            >
              <Radio.Group className="bh-radio-group" name="what">
                <Radio.Button key="mode-2fa-sms" value="sms">
                  {intl.formatMessage(page['page.settings.slider.2FA.mode.sms.label'])}
                </Radio.Button>
                <Radio.Button key="mode-2fa-email" value="mail">
                  {intl.formatMessage(page['page.settings.slider.2FA.mode.email.label'])}
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          </div>
        )}

        <Form.Item className="bh-form-item">
          <Button htmlType="submit" type="primary" disabled={isSubmitDisabled} block>
            {intl.formatMessage(page['page.settings.slider.2FA.update.button.label'])}
          </Button>
        </Form.Item>
      </Form>
    </React.Fragment>
  )
}

export default UpdateTwoFactorAuthenticationForm
