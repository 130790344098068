import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css, token }) => ({
  container: css`
    background: white;
    margin-bottom: ${token.marginLG}px;
    padding: ${token.paddingLG}px;
    border-radius: ${token.borderRadiusLG}px;
  `,
  header: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  titleWrapper: css`
    display: flex;
    align-items: center;
  `,
  number: css`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    background: black;
    border: 2px solid black;
    color: white;
    text-align: center;
  `,
  title: css`
    font-size: ${token.fontSize}px;
    font-weight: 500;
    margin: 0 0 0 10px;
  `,
  cancelAction: css`
    color: ${token.colorError};
    cursor: pointer;
    font-size: ${token.fontSize}px;
  `,
  content: css`
    margin-top: 15px;
  `,
}))

export default useStyles
