import React from 'react'

interface ProviderProps {
  children: React.ReactNode
}

export const combineProviders = (...providers: React.ComponentType<ProviderProps>[]): React.FC<ProviderProps> => {
  const Provider: React.FC<ProviderProps> = ({ children }) => {
    return providers.reduceRight((child, Provider) => <Provider>{child}</Provider>, children) as React.ReactElement
  }

  return Provider
}
