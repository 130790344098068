import { useState } from 'react'
import { Rules } from 'types/rules'
import usePaymentInstructionReferencePrefixAndSuffix from './usePaymentInstructionReferencePrefixAndSuffix'
import { referenceFieldMaxLength } from 'pages/PaymentInstructions/Add/utils'

type ClearingNetwork = 'ach' | 'bacs' | 'bg' | 'pg' | 'sepa' | 'sic' | 'swift'
type MaxLengths = { [key in ClearingNetwork]?: number }

export default function useClearingNetworkMaxLength(
  rules: Rules,
  defaultNetwork: ClearingNetwork = 'sepa'
): [number, (network: string | undefined) => void] {
  const maxLengths = rules?.logic?.clearingNetworksReferenceLength?.rule as MaxLengths
  const { prefixLength, suffixLength } = usePaymentInstructionReferencePrefixAndSuffix()

  const [maxLength, setMaxLength] = useState<number>(maxLengths?.[defaultNetwork] || 0)

  const updateMaxLength = (network: string | undefined) => {
    const networkKey = network as ClearingNetwork
    if (networkKey && maxLengths?.[networkKey]) {
      const referenceMaxLength = referenceFieldMaxLength(maxLengths?.[networkKey], prefixLength, suffixLength)
      setMaxLength(referenceMaxLength)
    }
  }

  return [maxLength, updateMaxLength]
}
