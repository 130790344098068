import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css, token, responsive }) => ({
  wrapper: css`
    background: white;
    border-radius: 10px;
    padding: 16px;
    margin-bottom: 16px;
  `,
  header: css`
    display: flex;
    align-items: center;

    ${responsive.md} {
      flex-direction: column;
      align-items: flex-start;
    }
  `,
  title: css`
    font-size: ${token.fontSize}px;
    margin: 0px;
    display: flex;
    align-items: center;
  `,
  subtitle: css`
    font-weight: 400;
    font-size: ${token.fontSize}px;
    color: #828282;
    margin-left: 10px;

    ${responsive.md} {
      font-size: 12px;
      margin-left: 30px;
      line-height: 100%;
    }
  `,
  icon: css`
    > img {
      width: 16px;
      height: 16px;
      margin-right: 10px;

      ${responsive.md} {
        height: 18px;
        width: 18px;
      }
    }
  `,
  content: css`
    padding: 10px 0px;
    margin-left: 30px;
  `,
  row: css`
    display: flex;
    align-items: center;
    padding: 5px 0px;
  `,
  left: css`
    min-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;

    ${responsive.md} {
      min-width: unset;
    }
  `,
  right: css`
    min-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;

    ${responsive.md} {
      min-width: unset;
    }
  `,
  description: css`
    color: #828282;
  `,
  spaceBetween: css`
    justify-content: space-between;
  `,
  flexColumn: css`
    display: flex;
    flex-direction: column;
  `,
}))

export default useStyles
