import { Reducer } from 'react'
import { Action, State } from '../types'

const withLogging = (reducer: Reducer<State, Action>) => (state: State, action: Action) => {
  const newState = reducer(state, action)

  if (location.hostname === 'localhost') {
    console.log(`%c${action.type}`, 'color: #00aaff; font-weight: bold;', {
      action,
      previousState: state,
      state: newState,
    })
  }

  return newState
}

export default withLogging
