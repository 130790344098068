import React from 'react'
import { NumberFormatValues, NumericFormat } from 'react-number-format'

interface Props {
  onChange?: (value: string | undefined) => void
  value?: string | undefined
  className?: string
  placeholder?: string
  thousandSeparator?: string | boolean
  decimalScale?: number
  allowLeadingZeros?: boolean
  fixedDecimalScale?: boolean
  decimalSeparator?: string
  allowedDecimalSeparators?: Array<string>
  allowNegative?: boolean
  isAllowed?: (values: NumberFormatValues) => boolean
}

const CurrencyInput = React.forwardRef(
  (
    {
      onChange,
      value,
      className,
      placeholder,
      decimalScale,
      allowLeadingZeros,
      fixedDecimalScale,
      decimalSeparator,
      allowedDecimalSeparators,
      allowNegative,
      thousandSeparator,
      isAllowed,
      ...rest
    }: Props,
    ref: React.Ref<React.FC<Props>>
  ) => {
    return (
      <NumericFormat
        data-testid="currency-number-input"
        getInputRef={ref}
        decimalScale={decimalScale === undefined ? 2 : decimalScale}
        allowNegative={allowNegative === undefined ? false : allowNegative}
        fixedDecimalScale={fixedDecimalScale === undefined ? true : fixedDecimalScale}
        allowLeadingZeros={allowLeadingZeros === undefined ? false : allowLeadingZeros}
        decimalSeparator={decimalSeparator || '.'}
        thousandSeparator={thousandSeparator === undefined ? ',' : thousandSeparator}
        allowedDecimalSeparators={allowedDecimalSeparators || ['.']}
        isAllowed={isAllowed || (() => true)}
        onValueChange={(value: NumberFormatValues) => {
          onChange && onChange(value.value)
        }}
        value={value}
        className={className}
        placeholder={placeholder}
        {...rest}
      />
    )
  }
)

export default CurrencyInput
