import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css, token, responsive }) => ({
  container: css`
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px 10px;
    width: 100%;
    height: 100%;

    .ant-result.ant-result-error {
      padding: 0px ${token.paddingMD}px;
      width: 100%;
    }

    .ant-result-content {
      background-color: transparent;
      padding: 0px;

      ${responsive.md} {
        max-height: 40vh;
        overflow: scroll;
      }
    }
  `,
  progress: css`
    margin: 20px 0;
  `,
  submitHeader: css`
    span {
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
    }
  `,
  paymentsPagination: css`
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  `,
  makeAnotherPaymentButton: css`
    margin: 30px 0;
  `,
  loaderContainer: css`
    margin: 20px;
  `,
  success: css`
    color: ${token.colorSuccess};
  `,
  fail: css`
    color: ${token.colorError};
  `,
  column: css`
    position: relative;
    width: 33%;
    min-height: 55px;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-left: 1px solid white;
    border-right: 1px solid white;
    font-size: ${token.fontSizeSM}px;
  `,
  row: css`
    display: flex;
    box-shadow: inset 0px -1px 0px #f0f0f0;
  `,
  errorCode: css`
    span {
      font-size: 10px;
      font-weight: 400;
      line-height: 20px;
    }
  `,
  headerColumn: css`
    position: relative;
    width: 33%;
    min-height: 55px;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    span {
      font-size: ${token.fontSize}px;
      font-weight: 500;
      line-height: 22px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `,
  headerRow: css`
    background-color: #fafafa;
    display: flex;
    box-shadow: inset 0 -1px #f0f0f0;
  `,
  failedPaymentsTable: css`
    margin-top: 40px;
    width: 100%;
    padding: 0px ${token.paddingMD}px;
  `,
}))

export default useStyles
