import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Form, Select, Radio, Button, InputNumber } from 'antd'
import { isEqual } from 'lodash'
import { Recurrence } from '../../../../../types/paymentInstruction'
import { pi } from '../../../../../lang/definitions'

interface RecurrenceFormData {
  limit: boolean
  occurrencesRemaining?: string
  interval?: string
}

interface RecurrenceFormProps {
  data?: Recurrence
  onSubmit: (values: Recurrence) => void
}

const RecurrenceForm = ({ data, onSubmit }: RecurrenceFormProps): React.JSX.Element => {
  const intl = useIntl()

  const [showNumberOfOcurrences, setShowNumberOfOcurrences] = useState(false)
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)

  useEffect(() => {
    if (data) {
      setShowNumberOfOcurrences(data.limit)
    }
  }, [data])

  const handleValueChange = (value: { limit: boolean }, values: RecurrenceFormData): void => {
    const isFormUpdated = !isEqual(recurrenceDataToForm(data), values)
    setIsSubmitDisabled(!isFormUpdated)

    const { limit } = value

    if (limit !== undefined) {
      setShowNumberOfOcurrences(limit)
    }
  }

  const handleSubmit = (values: RecurrenceFormData): void => {
    const recurrenceData: Recurrence = {
      ...values,
      occurrencesRemaining:
        (values.occurrencesRemaining && Number.parseInt(values.occurrencesRemaining, 10)) || undefined,
    }

    onSubmit(recurrenceData)
  }

  const recurrenceDataToForm = (data: Recurrence | undefined): RecurrenceFormData => {
    if (data) {
      return {
        ...data,
        occurrencesRemaining: `${data.occurrencesRemaining || ''}`,
      }
    }
    return {
      limit: false,
    }
  }

  return (
    <Form
      initialValues={recurrenceDataToForm(data)}
      id="recurrence-form"
      className="recurrence-form"
      layout="vertical"
      requiredMark={false}
      onValuesChange={handleValueChange}
      onFinish={handleSubmit}
    >
      <Form.Item
        className="bh-form-item"
        label={intl.formatMessage(pi['pi.add.addPayments.recurrenceForm.limit.label'])}
        name="limit"
      >
        <Radio.Group className="bh-radio-group">
          <Radio value={false}>
            {intl.formatMessage(pi['pi.add.addPayments.recurrenceForm.limit.option.noLimit'])}
          </Radio>
          <Radio value={true}>{intl.formatMessage(pi['pi.add.addPayments.recurrenceForm.limit.option.limit'])}</Radio>
        </Radio.Group>
      </Form.Item>
      {showNumberOfOcurrences && (
        <Form.Item
          className="bh-form-item"
          label={intl.formatMessage(pi['pi.add.addPayments.recurrenceForm.occurrencesRemaining.label'])}
          name="occurrencesRemaining"
          rules={[
            {
              required: true,
              message: intl.formatMessage(
                pi['pi.add.addPayments.recurrenceForm.occurrencesRemaining.errorMessage.required']
              ),
            },
          ]}
        >
          <InputNumber
            className="bh-input"
            placeholder={intl.formatMessage(pi['pi.add.addPayments.recurrenceForm.occurrencesRemaining.placeholder'])}
            min={0}
            style={{ width: '100%' }}
            type="number"
          />
        </Form.Item>
      )}
      <Form.Item
        className="bh-form-item"
        label={intl.formatMessage(pi['pi.add.addPayments.recurrenceForm.interval.label'])}
        name="interval"
        rules={[
          {
            required: true,
            message: intl.formatMessage(pi['pi.add.addPayments.recurrenceForm.interval.errorMessage.required']),
          },
        ]}
      >
        <Select
          className="bh-select"
          placeholder={intl.formatMessage(pi['pi.add.addPayments.recurrenceForm.interval.placeholder'])}
        >
          <Select.Option value={'everyWeek'}>
            {intl.formatMessage(pi['pi.paymentsFrequency.option.everyWeek'])}
          </Select.Option>
          <Select.Option value={'every2Weeks'}>
            {intl.formatMessage(pi['pi.paymentsFrequency.option.every2Weeks'])}
          </Select.Option>
          <Select.Option value={'everyMonth'}>
            {intl.formatMessage(pi['pi.paymentsFrequency.option.everyMonth'])}
          </Select.Option>
          <Select.Option value={'every2Months'}>
            {intl.formatMessage(pi['pi.paymentsFrequency.option.every2Months'])}
          </Select.Option>
          <Select.Option value={'everyQuarter'}>
            {intl.formatMessage(pi['pi.paymentsFrequency.option.everyQuarter'])}
          </Select.Option>
        </Select>
      </Form.Item>
      <Form.Item className="bh-form-item">
        <Button type="primary" size="large" htmlType="submit" disabled={isSubmitDisabled} block>
          {intl.formatMessage(pi['pi.add.addPayments.recurrenceForm.update'])}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default RecurrenceForm
