import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css }) => ({
  actionsContainer: css`
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    button {
      margin: 10px 0;
    }
  `,
}))

export default useStyles
