export interface Identifier {
  type: string
  value?: string
}

export interface Address {
  street?: string | Array<string>
  careOf?: string
  zip?: string
  city?: string
  region?: string
  countryCode: string
  apartment?: string
}

interface Profile {
  profileId?: string
  title: string
  type: string
  email?: string
  phone?: string
  identifier: Identifier
}

interface Bank {
  title: string
  bic: string
  country: string
  address: Address
}

export interface BlacklistEntry {
  id?: string
  title?: string
  accountNumber: string
  routingNumber?: string
  clearingNetwork: string
  issuedBy: string
  dateCreated?: string
  dateUpdated?: string
  blockType?: string
  cardNetworks: Array<string>
}

interface WhitePermission {
  id?: string
  accountNumber: string
  routingNumber?: string
  clearingNetwork: string
  blockType: string
  issuedBy: string
  comment: string
  dateCreated?: string
  dateUpdated?: string
}

export interface Beneficiary {
  id?: string
  title: string
  type: string
  email?: string
  phone?: string
  identifier: Identifier
  address: Address
  accountNumber: string
  accountType?: string
  routingNumber?: string
  clearingNetwork: string
  currency?: string
  profiles?: Array<Profile>
  bank?: Bank
  blacklist?: Array<BlacklistEntry>
  whitelist?: Array<WhitePermission>
  dateCreated?: string
  dateUpdated?: string
  isVerified?: boolean
  additionalIdentifiers?: AdditionalIdentifiers
  newBeneficiary?: boolean
}

export interface Organization {
  address: Address
  cin: string
  companyType: string
  countryCode: string
  status: string | null
  title: string
}

export interface BeneficiaryFormData {
  title: string
  type: string
  identifier: Identifier
  address: Address
  accountNumber: string
  routingNumber?: string
  clearingNetwork: string
  /*** Supplier funded ***/
  supplierFunded?: boolean
  feePercent?: string
  feeFixed?: string
  feeFixedCurrency?: string
  defaultSourceId?: string
}

export interface AdditionalIdentifiers {
  externalId: string
}

export type PaymentTypeRule = {
  defaultPaymentType?: PaymentType
  paymentTypes: PaymentType[]
}

export type PaymentTypeRules = {
  [key: string]: PaymentTypeRule
}

export type ClearingNetworkRule = {
  clearingNetwork: ClearingNetworks
  currencies: string[]
}

export enum ClearingNetworks {
  BG = 'bg',
  PG = 'pg',
  SEPA = 'sepa',
  BACS = 'bacs',
  SWIFT = 'swift',
  ACH = 'ach',
  SIC = 'sic',
}

export enum PaymentType {
  BankGiro = 'bg',
  PlusGiro = 'pg',
  LocalGB = 'local_gb',
  LocalUS = 'local_us',
  IBAN = 'iban',
  Swift = 'swift',
  Aba = 'aba',
}

export enum SwedishClearingNetwork {
  BG = ClearingNetworks.BG,
  PG = ClearingNetworks.PG,
}

export enum AccountTypes {
  checking = 'checking',
  savings = 'savings',
}

export enum BeneficiaryTypes {
  business = 'business',
  personal = 'personal',
}

export const defaultClearingNetworkByCurrency: Record<string, PaymentType> = {
  gbp: PaymentType.LocalGB,
  sek: PaymentType.BankGiro,
  usd: PaymentType.LocalUS,
  cad: PaymentType.Swift,
  nok: PaymentType.IBAN,
  chf: PaymentType.IBAN,
  dkk: PaymentType.IBAN,
  eur: PaymentType.IBAN,
  ils: PaymentType.IBAN,
  pln: PaymentType.IBAN,
  huf: PaymentType.IBAN,
  try: PaymentType.IBAN,
  czk: PaymentType.IBAN,
}

export const getPaymentTypesByClearingNetwork = (currency: string): Record<ClearingNetworks, PaymentType[]> => {
  const swiftPaymentTypes = [PaymentType.Swift, PaymentType.IBAN]

  if (currency === 'usd') {
    swiftPaymentTypes.push(PaymentType.Aba)
  }

  return {
    bg: [PaymentType.BankGiro],
    pg: [PaymentType.PlusGiro],
    bacs: [PaymentType.LocalGB],
    ach: [PaymentType.LocalUS],
    sic: [PaymentType.IBAN],
    sepa: [PaymentType.IBAN],
    swift: swiftPaymentTypes,
  }
}

export const clearingNetworkByPaymentType: Record<PaymentType, ClearingNetworks> = {
  [PaymentType.BankGiro]: ClearingNetworks.BG,
  [PaymentType.PlusGiro]: ClearingNetworks.PG,
  [PaymentType.LocalGB]: ClearingNetworks.BACS,
  [PaymentType.LocalUS]: ClearingNetworks.ACH,
  [PaymentType.Swift]: ClearingNetworks.SWIFT,
  [PaymentType.IBAN]: ClearingNetworks.SWIFT,
  [PaymentType.Aba]: ClearingNetworks.SWIFT,
}

export interface DeleteBeneficiariesResponse {
  success: boolean
  message: string
}
