import React from 'react'
import { Form, Input } from 'antd'
import { useTranslation } from '../../../../utils/helpers'
import useSuffix from './useSuffix'
import { GenericMaxLength, useLocalAddressValidator } from 'utils/validators'

export default function StreetAddress(): React.JSX.Element {
  const t = useTranslation()
  const AddressStreetSuffix = useSuffix({ fieldName: ['address', 'street'], maxLength: GenericMaxLength })
  const streetValidator = useLocalAddressValidator('street')

  return (
    <Form.Item
      label={t('beneficiary.form.address.street.label')}
      name={['address', 'street']}
      validateTrigger="onChange"
      hasFeedback
      rules={[
        {
          validator: streetValidator,
        },
      ]}
    >
      <Input
        type="text"
        size="large"
        data-testid="street-input-field"
        suffix={AddressStreetSuffix}
        placeholder={t('beneficiary.form.address.street.placeholder')}
        maxLength={GenericMaxLength}
      />
    </Form.Item>
  )
}
