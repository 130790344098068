import { PaymentFormData, PaymentInstruction } from 'types/paymentInstruction'
import { convertFormAmountToCents } from '.'
import dayjs from 'dayjs'

export default function mapFormToPaymentInstruction(
  formData: PaymentFormData,
  profileId: string,
  dateDue: dayjs.Dayjs
): PaymentInstruction {
  return {
    profileId: profileId,
    beneficiaryId: formData.beneficiaryId!,
    amount: convertFormAmountToCents(formData.amount!),
    currency: formData.currency!,
    reference: [formData.reference!],
    creationChannel: {
      channel: 'web',
    },
    sourceId: formData.sourceId,
    dateDue: dateDue.format('YYYY-MM-DD'),
    remittanceAdvice: formData.remittanceAdvice
      ? {
          emails: formData.remittanceAdvice.split(','),
        }
      : undefined,
  }
}
