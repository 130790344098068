/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useHistory } from 'react-router-dom'
import { useIntl, createIntl } from 'react-intl'
import dayjs from 'dayjs'

import {
  getPreferredLanguage,
  removeSessionId,
  removeAuthenticationStatus,
  removeIsProfileChosen,
} from './../utils/storage'
import { logout } from '../api/auth'
import { util } from '../lang/definitions/util'
import { document, messages } from '../lang/definitions'
import { DEFAULT_LOCALE } from '../utils/init'
import { card } from '../lang/definitions'
import * as Sentry from '@sentry/react'
import { useSession } from 'stores/session'

export interface OptionData {
  label: string
  value: string
  disabled?: boolean
  checked?: boolean
}

export const useUtils = () => {
  const intl = useIntl()
  const {
    actions: { resetContext },
  } = useSession()
  const history = useHistory()
  const language = getPreferredLanguage() || 'en-GB'
  const intlDateFormat = createIntl({
    locale: language,
    defaultLocale: DEFAULT_LOCALE,
  })

  const paymentStatusV2 = [
    {
      value: 'READY',
      label: intl.formatMessage(util['util.payment.status.ready']),
    },
    {
      value: 'REQUIRE_SIGNATURE',
      label: intl.formatMessage(util['util.payment.status.signature.required']),
    },
    {
      value: 'PENDING',
      label: intl.formatMessage(util['util.payment.status.pending']),
    },
    {
      value: 'BANK',
      label: intl.formatMessage(util['util.payment.status.bank']),
    },
    {
      value: 'PAID',
      label: intl.formatMessage(util['util.payment.status.paid']),
    },
    {
      value: 'ERROR',
      label: intl.formatMessage(util['util.payment.status.error']),
    },
    {
      value: 'CANCELED',
      label: intl.formatMessage(util['util.payment.status.canceled']),
    },
  ]

  const getReportFieldLabel = (key: string) => {
    let label: string
    switch (key) {
      case 'internal_company_number':
        label = intl.formatMessage(util['util.report.fields.internal.company.number'])
        break

      case 'entity':
        label = intl.formatMessage(util['util.report.fields.entity'])
        break

      case 'beneficiaryTitle':
        label = intl.formatMessage(util['util.report.fields.beneficiary.label'])
        break
      case 'beneficiaryCountryCode':
        label = intl.formatMessage(util['util.report.fields.beneficiary.country.code'])
        break
      case 'accountType':
        label = intl.formatMessage(util['util.report.fields.account.type'])
        break
      case 'clearingNetwork':
        label = intl.formatMessage(util['util.report.fields.clearing.network'])
        break

      case 'routingNumber':
        label = intl.formatMessage(util['util.report.fields.routing.number'])
        break

      case 'accountNumber':
        label = intl.formatMessage(util['util.report.fields.account.number'])
        break
      case 'dateDue':
        label = intl.formatMessage(util['util.report.fields.date.due'])
        break

      case 'reference':
        label = intl.formatMessage(util['util.report.fields.reference'])
        break

      case 'amount':
        label = intl.formatMessage(util['util.report.fields.amount'])
        break

      case 'fee':
        label = intl.formatMessage(util['util.report.fields.fee'])
        break

      case 'amountOnCard':
        label = intl.formatMessage(util['util.report.fields.amount.on.card'])
        break
      case 'amountPayout':
        label = intl.formatMessage(util['util.report.fields.amount.paid.out'])
        break

      case 'cardNetwork':
        label = intl.formatMessage(util['util.report.fields.card.network'])
        break

      case 'card':
        label = intl.formatMessage(util['util.report.fields.card.card'])
        break

      case 'supplierFunded':
        label = intl.formatMessage(util['util.report.fields.supplier.funded'])
        break

      case 'creationChannel':
        label = intl.formatMessage(util['util.report.fields.creation.channel'])
        break

      case 'currency':
        label = intl.formatMessage(util['util.report.fields.currency'])
        break

      case 'dateUserPaid':
        label = intl.formatMessage(util['util.report.fields.date.card.charge'])
        break

      case 'user':
        label = intl.formatMessage(util['util.report.fields.user'])
        break

      case 'refunded':
        label = intl.formatMessage(util['util.report.fields.refunded'])
        break

      case 'date_created':
        label = intl.formatMessage(util['util.report.fields.date.created'])
        break

      case 'piDateCreated':
        label = intl.formatMessage(util['util.report.fields.date.created'])
        break

      case 'piState':
        label = intl.formatMessage(util['util.report.fields.pi.state'])
        break

      case 'amountUsd':
        label = intl.formatMessage(util['util.report.fields.amount.usd'])
        break

      case 'amountChf':
        label = intl.formatMessage(util['util.report.fields.amount.chf'])
        break

      case 'attachment':
        label = intl.formatMessage(util['util.report.fields.attachment'])
        break

      case 'addedByName':
        label = intl.formatMessage(util['util.report.fields.adder'])
        break

      case 'paidByName':
        label = intl.formatMessage(util['util.report.fields.payer'])
        break

      default:
        return key
    }

    return label
  }

  const dateOptions: OptionData[] = [
    {
      value: '1',
      label: intl.formatMessage(util['util.date.options.last.thirty.days']),
    },
    {
      value: '2',
      label: intlDateFormat.formatDate(dayjs().subtract(1, 'months').format(), {
        year: 'numeric',
        month: 'short',
      }),
    },
    {
      value: '3',
      label: intlDateFormat.formatDate(dayjs().subtract(2, 'months').format(), {
        year: 'numeric',
        month: 'short',
      }),
    },
    {
      value: '4',
      label: intl.formatMessage(util['util.date.options.this.year']),
    },
    {
      value: '5',
      label: intl.formatMessage(util['util.date.options.specific.date.range']),
    },
  ]

  const beneficiaryType = [
    {
      value: 'personal',
      label: intl.formatMessage(util['util.beneficiary.type.personal']),
    },
    {
      value: 'business',
      label: intl.formatMessage(util['util.beneficiary.type.business']),
    },
  ]

  const listOfTypeOfDocuments = [
    {
      key: 'proof-of-identity',
      value: intl.formatMessage(document['document.typeOfDocument.proofOfIdentity']),
    },
    {
      key: 'proof-of-address',
      value: intl.formatMessage(document['document.typeOfDocument.proofOfAddress']),
    },
    {
      key: 'organization-certificate',
      value: intl.formatMessage(document['document.typeOfDocument.organizationCertificate']),
    },
    {
      key: 'invoice-copy',
      value: intl.formatMessage(document['document.typeOfDocument.invoiceCopy']),
    },
  ]

  const getTranslatedErrorMessage = (errorCode: string): string => {
    let errorMessage
    switch (errorCode) {
      case 'GENERAL_ERROR': {
        errorMessage = intl.formatMessage(card['card.form.submitError.GENERAL_ERROR'])
        break
      }
      case 'INSUFFICIENT_FUNDS': {
        errorMessage = intl.formatMessage(card['card.form.submitError.INSUFFICIENT_FUNDS'])
        break
      }
      case 'EXCEEDS_WITHDRAWAL_LIMIT': {
        errorMessage = intl.formatMessage(card['card.form.submitError.EXCEEDS_WITHDRAWAL_LIMIT'])
        break
      }
      case 'FORMAT_ERROR': {
        errorMessage = intl.formatMessage(card['card.form.submitError.FORMAT_ERROR'])
        break
      }
      case 'PAN_FORMAT_ERROR': {
        errorMessage = intl.formatMessage(card['card.form.submitError.PAN_FORMAT_ERROR'])
        break
      }
      case 'DATE_FORMAT_ERROR': {
        errorMessage = intl.formatMessage(card['card.form.submitError.DATE_FORMAT_ERROR'])
        break
      }
      case 'EXPIRED': {
        errorMessage = intl.formatMessage(card['card.form.submitError.EXPIRED'])
        break
      }
      case 'CVV_ERROR': {
        errorMessage = intl.formatMessage(card['card.form.submitError.CVV_ERROR'])
        break
      }
      case 'CVC_FORMAT_ERROR': {
        errorMessage = intl.formatMessage(card['card.form.submitError.CVC_FORMAT_ERROR'])
        break
      }
      case 'BANK_REFUSAL': {
        errorMessage = intl.formatMessage(card['card.form.submitError.BANK_REFUSAL'])
        break
      }
      case 'SCA_FAIL': {
        errorMessage = intl.formatMessage(card['card.form.submitError.SCA_FAIL'])
        break
      }
      case 'SCA_EXPIRED': {
        errorMessage = intl.formatMessage(card['card.form.submitError.SCA_EXPIRED'])
        break
      }
      case 'BIN_BLOCKED': {
        errorMessage = intl.formatMessage(card['card.form.submitError.BIN_BLOCKED'])
        break
      }
      default: {
        errorMessage = intl.formatMessage(card['card.form.submitError.GENERAL_ERROR'])
      }
    }
    return errorMessage
  }

  const getTranslatedUserErrorMessage = (error: string): string => {
    let errorMessage
    switch (error) {
      case 'phone already exists': {
        errorMessage = intl.formatMessage(messages['messages.error.user.phone.already.exists'])
        break
      }
      case 'email already exists': {
        errorMessage = intl.formatMessage(messages['messages.error.user.email.already.exists'])
        break
      }
      default: {
        errorMessage = intl.formatMessage(messages['messages.error.user.add'])
      }
    }
    return errorMessage
  }

  const handleSessionExpire = () => {
    removeSessionId()
    resetContext()

    history.replace(`/login?sessionExpired=true`)
  }

  const logoutUser = async () => {
    try {
      await logout()
      removeAuthenticationStatus()
      removeSessionId()
      removeIsProfileChosen()

      // clean context
      resetContext()

      history.replace(`/login`)
    } catch (error) {
      Sentry.captureException(error)
    }
  }
  return {
    paymentStatusV2,
    getReportFieldLabel,
    dateOptions,
    beneficiaryType,
    listOfTypeOfDocuments,
    getTranslatedErrorMessage,
    getTranslatedUserErrorMessage,
    logoutUser,
    handleSessionExpire,
  }
}

export default useUtils
