import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css, token, responsive }) => ({
  reviewContainer: css`
    width: 100%;
    overflow: auto;
    height: calc(103vh - 176px - 55px);
  `,
  transparent: css`
    background-color: transparent;
  `,
  disabled: css`
    opacity: 0.2;
    cursor: not-allowed;
  `,
  withBackground: css`
    background-color: white;
  `,
  scrollable: css`
    overflow-y: auto;
  `,
  disableScrollbars: css`
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */

    &::-webkit-scrollbar {
      width: 0px;
      background: transparent; /* Chrome/Safari/Webkit */
    }
  `,
  showScroll: css`
    padding-right: ${token.paddingMD}px;

    ${responsive.sm} {
      padding-right: ${token.paddingSM}px;
    }
    .tab {
      height: 100%;
    }
  `,
  descriptionContainer: css`
    margin-top: 0.5rem;
  `,
  descriptionItem: css`
    margin-top: 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  noDataMessage: css`
    text-align: center;
    margin-top: 20px;
    color: rgba(0, 0, 0, 0.65);
    font-size: ${token.fontSizeSM}px;
  `,
}))

export default useStyles
