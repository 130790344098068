import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css, token }) => ({
  actionsSettings: css`
    display: flex;
    align-items: center;
    margin: 20px 0 40px;
    column-gap: 20px;
  `,
  danger: css`
    color: ${token.colorError};
  `,
}))

export default useStyles
