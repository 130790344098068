import React from 'react'
import { IntlShape } from 'react-intl'
import { document } from '../../../lang/definitions'
import Date from '../../../components/Format/Date/Date'

interface ListOfDocType {
  key: string
  value: string
}

export const documentColumns = (intl: IntlShape, language: string, listOfTypeOfDocuments: ListOfDocType[]) => [
  {
    title: intl.formatMessage(document['document.table.column.type']),
    dataIndex: 'type',
    render: (data: string) => {
      const docTypeItem = listOfTypeOfDocuments.find((item) => item.key === data)
      return docTypeItem?.value
    },
  },
  {
    title: intl.formatMessage(document['document.table.column.dateUploaded']),
    dataIndex: 'dateCreated',
    render: (dateCreated: string) => {
      return <Date value={dateCreated} locale={language} time />
    },
  },
  {
    title: intl.formatMessage(document['document.table.column.comment']),
    dataIndex: 'comment',
    className: 'desktop-display',
  },
]
