import { Descriptions } from 'antd'
import React from 'react'
import { Event, ImportDetails } from '../../types/activity'
import Date from '../Format/Date/Date'
import { useLanguageState } from '../../stores/language/LanguageStore'
import ImportEventDetails from '../EventDetailsForm/ImportEventDetails'

interface SuccessEventDetailsProps {
  event: Event
}
const SuccessEventDetails = ({ event }: SuccessEventDetailsProps): React.JSX.Element => {
  const [languageState] = useLanguageState()
  const language = languageState.language

  const prepareContent = (event: Event): React.JSX.Element => {
    const relatedTo = event.label.split('.', 2)[0]

    let content: React.JSX.Element = <div></div>
    const label: React.JSX.Element = (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>{event.labelText}</span>
        <Date value={event.dateCreated} locale={language} time />
      </div>
    )
    switch (relatedTo) {
      case 'import': {
        const importDetails = event.details as ImportDetails
        content = (
          <Descriptions layout="vertical" bordered>
            <Descriptions.Item label={label}>
              <ImportEventDetails importDetails={importDetails}></ImportEventDetails>
            </Descriptions.Item>
          </Descriptions>
        )
        break
      }
      default: {
        break
      }
    }

    return content
  }

  return event && <div>{prepareContent(event)}</div>
}

export default SuccessEventDetails
