import { formatLocaleDateString } from './dates'

export interface FormatLocaleProps {
  value: string
  locale: string
  time?: boolean
  currency?: string
}

export const formatLocale = (option: string, props: FormatLocaleProps): string => {
  let format = ''
  switch (option) {
    case 'number':
      format = Number(props.value).toLocaleString(props.locale, {
        // handling so that 10.00 becomes 10 and 10.2 becomes 10.20
        minimumFractionDigits: ((Number(props.value) / 100) & 1) === 0 ? 0 : 2,
        maximumFractionDigits: 2,
      })
      break
    case 'currency':
      format = (Number(props.value) / 100).toLocaleString(props.locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
      break
    case 'date':
      format = formatLocaleDateString(props.value, props.locale)
      if (props.time) {
        format += ' ' + new Date(props.value).toLocaleTimeString(props.locale)
      }
      break
    default:
      break
  }
  return format
}
