import React from 'react'
import { Input, Form } from 'antd'
import { formatCardNumber, getCardImage, getCardTypeByValue } from '../utils'
import { CardForm } from 'types/source'
import { useIntl } from 'react-intl'
import { card } from 'lang/definitions'
import { useCardNumberValidator } from '../validators'

interface Props {
  cardNetworks: string[]
}

const CardNumberFormItem = ({ cardNetworks }: Props) => {
  const form = Form.useFormInstance<CardForm>()
  const pan = Form.useWatch('pan', form)
  const intl = useIntl()

  const cardImage = getCardImage(pan)
  const cardType = getCardTypeByValue(pan)

  const validator = useCardNumberValidator({ cardNetworks, cardType })

  const cardMaxLength = () => {
    const maxNumber = Math.max(...(cardType?.lengths || []))
    return maxNumber && cardType ? maxNumber + cardType?.gaps?.length : 23
  }

  return (
    <Form.Item
      className="bh-form-item"
      name="pan"
      normalize={(value: string) => formatCardNumber(value)}
      label={intl.formatMessage(card['card.form.cardNumber.label'])}
      hasFeedback
      rules={[
        {
          validator,
        },
      ]}
    >
      <Input size="large" prefix={<img src={cardImage} />} maxLength={cardMaxLength()} placeholder="Card Number" />
    </Form.Item>
  )
}

export default CardNumberFormItem
