import { api } from './utils'
import { PaymentInstruction } from '../types/paymentInstruction'
import { PaymentInstructionTemplate } from '../pages/PaymentInstructions/PaymentInstructionTemplate'
import { FilterBody } from '../components/Filter/Filter'
import { QueryParams } from '../types/general'
import { User } from '../types/user'

const BASE_PATH = 'pi'

export const getProfileId = async (): Promise<User> => {
  const url = '/me'
  const response = await api.get(url)

  return (await response.json()) as User
}

export const getPaymentInstructions = async (
  profileId: string,
  params?: QueryParams
): Promise<{
  paymentInstructions: Array<PaymentInstruction>
  total?: number
}> => {
  const url = `/${BASE_PATH}/profile/${profileId}/payment-instruction`

  const response = await api.get(url, params)

  const paymentInstructions = (await response.json()) as Array<PaymentInstruction>
  const total = response.headers.get('x-total-count')

  return {
    paymentInstructions,
    total: Number.parseInt(total || '', 10),
  }
}

export const getPaymentInstructionTemplates = async (
  profileId: string,
  params?: QueryParams
): Promise<{
  paymentInstructionTemplates: Array<PaymentInstructionTemplate>
  total?: number
}> => {
  const url = `/${BASE_PATH}/profile/${profileId}/payment-instruction-template`

  const response = await api.get(url, params)

  const paymentInstructionTemplates = (await response.json()) as Array<PaymentInstructionTemplate>
  const total = response.headers.get('x-total-count')

  return {
    paymentInstructionTemplates,
    total: Number.parseInt(total || '', 10),
  }
}

export const getCurrencies = async (): Promise<{
  currencies: Array<string>
}> => {
  const url = '/rules/constant/currency'

  const response = await api.get(url)

  const currencies = (await response.json()) as Array<string>

  return {
    currencies,
  }
}

export const searchPaymentInstructions = async (
  filter: FilterBody,
  params?: QueryParams
): Promise<{
  paymentInstructions: Array<PaymentInstruction>
  total?: number
}> => {
  const url = `/${BASE_PATH}/search`
  const body: FilterBody = {
    // kind: "paymentInstruction",
    operator: 'and',
    ...filter,
  }

  const response = await api.post(url, body, params)

  const paymentInstructions = (await response.json()) as Array<PaymentInstruction>
  const total = response.headers.get('x-total-count')

  return {
    paymentInstructions,
    total: Number.parseInt(total || '', 10),
  }
}
