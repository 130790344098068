import { useIntl } from 'react-intl'
import { notification } from '../../../lang/definitions/notification'

export const IntlTranslated = (item: string, category?: string, childOf = ''): string => {
  const intl = useIntl()

  if (item === 'switch.no') {
    return intl.formatMessage(notification['notification.switch.no'])
  }
  if (item === 'switch.yes') {
    return intl.formatMessage(notification['notification.switch.yes'])
  }
  // General
  if (item === 'general' && category === 'heading') return intl.formatMessage(notification['notification.general'])
  if (item === 'general' && category === 'description')
    return intl.formatMessage(notification['notification.general.description'])

  // News
  if (item === 'news' && category === 'heading') return intl.formatMessage(notification['notification.news'])
  if (item === 'news' && category === 'description')
    return intl.formatMessage(notification['notification.news.description'])

  // date break
  if (item === 'dateBreak' && category === 'heading') return intl.formatMessage(notification['notification.dateBreak'])
  if (item === 'dateBreak' && category === 'description')
    return intl.formatMessage(notification['notification.dateBreak.description'])

  // Payment states
  if (item === 'paymentStates' && category === 'heading')
    return intl.formatMessage(notification['notification.payments'])
  if (item === 'paymentStates' && category === 'description')
    return intl.formatMessage(notification['notification.payments.description'])

  // Added
  if (item === 'added' && category === 'heading' && childOf === 'paymentStates')
    return intl.formatMessage(notification['notification.added'])
  if (item === 'added' && category === 'description' && childOf === 'paymentStates')
    return intl.formatMessage(notification['notification.added.description'])

  // Pending
  if (item === 'pending' && category === 'heading') return intl.formatMessage(notification['notification.pending'])
  if (item === 'pending' && category === 'description')
    return intl.formatMessage(notification['notification.pending.description'])

  // Paid
  if (item === 'paid' && category === 'heading') return intl.formatMessage(notification['notification.paid'])
  if (item === 'paid' && category === 'description')
    return intl.formatMessage(notification['notification.paid.description'])

  // Payment signatures
  if (item === 'paymentSignatures' && category === 'heading')
    return intl.formatMessage(notification['notification.paymentSignatures'])
  if (item === 'paymentSignatures' && category === 'description')
    return intl.formatMessage(notification['notification.paymentSignatures.description'])

  // signature request
  if (item === 'signatureRequest' && category === 'heading')
    return intl.formatMessage(notification['notification.signatureRequest'])
  if (item === 'signatureRequest' && category === 'description')
    return intl.formatMessage(notification['notification.signatureRequest.description'])

  // signature
  if (item === 'signature' && category === 'heading') return intl.formatMessage(notification['notification.signature'])
  if (item === 'signature' && category === 'description')
    return intl.formatMessage(notification['notification.signature.description'])

  // entityProfiles
  if (item === 'entityProfiles' && category === 'heading')
    return intl.formatMessage(notification['notification.organization'])
  if (item === 'entityProfiles' && category === 'description')
    return intl.formatMessage(notification['notification.organization.description'])

  // User added
  if (item === 'added' && category === 'heading' && childOf === 'entityProfiles')
    return intl.formatMessage(notification['notification.user.added'])

  if (item === 'added' && category === 'description' && childOf === 'entityProfiles')
    return intl.formatMessage(notification['notification.user.added.description'])

  // User updated
  if (item === 'roleUpdated' && category == 'heading')
    return intl.formatMessage(notification['notification.roleUpdated'])
  if (item === 'roleUpdated' && category === 'description')
    return intl.formatMessage(notification['notification.roleUpdated.description'])
  // User deleted
  if (item === 'deleted' && category === 'heading') return intl.formatMessage(notification['notification.deleted'])
  if (item === 'deleted' && category === 'description')
    return intl.formatMessage(notification['notification.deleted.description'])
  else return item
}
