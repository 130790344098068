import { defineMessages } from 'react-intl'

export const user = defineMessages({
  'user.name': {
    id: 'user.name',
    defaultMessage: 'Name',
    description: 'This string sets user name label',
  },
  'user.dateOfBirth': {
    id: 'user.dateOfBirth',
    defaultMessage: 'Date of birth',
    description: 'This string sets user dob label',
  },
  'user.dateOfRegistration': {
    id: 'user.dateOfRegistration',
    defaultMessage: 'Date of registration',
    description: 'This string sets user date of registration label',
  },
  'user.email': {
    id: 'user.email',
    defaultMessage: 'E-mail',
    description: 'This string sets user email label',
  },
  'user.mobile': {
    id: 'user.mobile',
    defaultMessage: 'Mobile number',
    description: 'This string sets user mobile number label',
  },
  'user.password': {
    id: 'user.password',
    defaultMessage: 'Password',
    description: 'This string sets user password label',
  },
  'user.language': {
    id: 'user.language',
    defaultMessage: 'Language',
    description: 'This string sets user language label',
  },
  'user.2FA': {
    id: 'user.2FA',
    defaultMessage: 'Two factor authentication',
    description: 'This string sets user 2FA label',
  },
  'user.choseCountry': {
    id: 'user.choseCountry',
    defaultMessage: 'Choose country',
    description: 'This string sets user country label',
  },
  'user.country': {
    id: 'user.country',
    defaultMessage: 'Country',
    description: 'This string sets user country label',
  },
  'user.emailAddress': {
    id: 'user.emailAddress',
    defaultMessage: 'Email address',
    description: 'This string sets user email address label',
  },
  'user.emailAddress.placeholder': {
    id: 'user.emailAddress.placeholder',
    defaultMessage: 'Enter email address',
    description: 'This string sets user email address placeholder',
  },
  'user.firstName': {
    id: 'user.firstName',
    defaultMessage: 'First name',
    description: 'This string sets user first name label',
  },
  'user.firstName.placeholder': {
    id: 'user.firstName.placeholder',
    defaultMessage: 'Enter first name',
    description: 'This string sets user first name placeholder',
  },
  'user.lastName': {
    id: 'user.lastName',
    defaultMessage: 'Last name',
    description: 'This string sets user last name label',
  },
  'user.lastName.placeholder': {
    id: 'user.lastName.placeholder',
    defaultMessage: 'Enter last name',
    description: 'This string sets user last name placeholder',
  },
  'user.country.errorMessage.required': {
    id: 'user.country.errorMessage.required',
    defaultMessage: 'Please choose country',
    description: 'This string sets error message for required field',
  },
  'user.country.email.errorMessage.required': {
    id: 'user.country.email.errorMessage.required',
    defaultMessage: 'Please enter email address',
    description: 'This string sets error message for required field',
  },
  'user.country.firstName.errorMessage.required': {
    id: 'user.country.firstName.errorMessage.required',
    defaultMessage: 'Please enter first name',
    description: 'This string sets error message for required field',
  },
  'user.country.lastName.errorMessage.required': {
    id: 'user.country.lastName.errorMessage.required',
    defaultMessage: 'Please enter last name',
    description: 'This string sets error message for required field',
  },
})
