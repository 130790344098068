import * as Sentry from '@sentry/react'
import { getAuthenticationStatus, getPreferredLanguage, removeIsProfileChosen } from './../utils/storage'
import { Modal, message } from 'antd'
import queryString from 'query-string'
import { getSessionId, removeSessionId, removeAuthenticationStatus } from '../utils/storage'
import { QueryParams } from '../types/general'
import { createIntl, createIntlCache } from 'react-intl'
import { errors } from '../lang/definitions/errors'
import { DEFAULT_LOCALE } from '../utils/init'
import { constructNetworkErrorMessage } from 'utils/errorMessages'

declare global {
  interface Window {
    VITE_BACKEND_PROTOCOL: string
    VITE_BACKEND_DOMAIN: string
    VITE_BACKEND_PORT: string
    VITE_BACKEND_VERSION: string
    VITE_FRONTEND_PROTOCOL: string
    VITE_FRONTEND_DOMAIN: string
    VITE_FRONTEND_PORT: string
  }
}

interface HTTPMethods {
  GET: keyof HTTPMethods
  POST: keyof HTTPMethods
  PUT: keyof HTTPMethods
  DELETE: keyof HTTPMethods
  PATCH: keyof HTTPMethods
}

const httpMethods: HTTPMethods = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
  PATCH: 'PATCH',
}

let modalDisplaying: boolean

const language = getPreferredLanguage() || 'en-GB'
const cache = createIntlCache()
const intl = createIntl(
  {
    locale: language,
    defaultLocale: DEFAULT_LOCALE,
    messages: errors['error.default'],
  },
  cache
)

class DetailedError extends Error {
  details: {
    errors: unknown
    rule: unknown
    errorMessage: string
    errMsg: string
    err: {
      status: number
    }
  }
  constructor(
    message: string,
    details: {
      errors: unknown
      rule: unknown
      errorMessage: string
      errMsg: string
      err: {
        status: number
      }
    }
  ) {
    super(message)
    this.name = 'DetailedError'
    this.details = details
  }
}

interface AuthHeader {
  Authorization: string
}

export const getAuthHeader = (): AuthHeader => {
  const sessionId = getSessionId() || ''

  return { Authorization: sessionId }
}

const handleError = async (response: Response): Promise<Response> => {
  let skipDisplayingModal = false

  const requestId = response.headers.get('x-request-id')

  if (!response.ok) {
    const error = (await response.json()) as DetailedError
    const isSessionError = response.status === 404 && response.url.includes('session')

    // if we are Logged in
    if (!isSessionError && getAuthenticationStatus()) {
      const url = `/auth/session/`
      const sessionResponse = await fetch(`${backendUrl()}${url}`, {
        method: httpMethods.GET,
        headers: { ...getAuthHeader() },
      })

      const sessionIsValid = sessionResponse.status === 200 ? true : false

      let notificationDescription: string

      switch (response.status) {
        case 401:
          notificationDescription = intl.formatMessage(errors['error.401.default'])
          break
        case 404:
          notificationDescription = intl.formatMessage(errors['error.404.default'])
          break
        case 400:
          // We should consider adding id property on backend by which we would check errors instead of checking by message string
          if (error.details.errorMessage === 'Month limit exceeded') {
            notificationDescription = intl.formatMessage(errors['error.month.limit.exceeded'])
          } else if (error.details.errorMessage === 'FOREIGN_CARD_CURRENCY') {
            skipDisplayingModal = false
          } else {
            notificationDescription = intl.formatMessage(errors['error.default'])
          }
          break
        default:
          notificationDescription = intl.formatMessage(errors['error.default'])
          break
      }

      //On invalid session id return to the login screen
      if (!sessionIsValid) {
        removeAuthenticationStatus()
        removeSessionId()
        removeIsProfileChosen()
        window.location.href = '/login'
      }

      const errorModal = () => {
        let errorTitle = ''
        let errorMessage = ''
        let redirectToLogout = false
        let buttonText = ''

        if (sessionIsValid) {
          errorTitle = response.statusText
          errorMessage = notificationDescription
          redirectToLogout = false
          buttonText = 'Ok'
        }

        if (!sessionIsValid) {
          redirectToLogout = true
          removeAuthenticationStatus()
          removeSessionId()
          removeIsProfileChosen()
          window.location.href = '/login'
        }
        if (!modalDisplaying && sessionIsValid && skipDisplayingModal) {
          modalDisplaying = true
          Modal.error({
            title: errorTitle,
            centered: true,
            styles: {
              mask: { backgroundColor: 'rgba(0, 0, 0, 0.85)' },
            },
            content: errorMessage,
            onOk: () => {
              Modal.destroyAll()
              modalDisplaying = false
              if (redirectToLogout) {
                removeAuthenticationStatus()
                removeSessionId()
                removeIsProfileChosen()
                window.location.href = '/login'
              }
            },
            okText: buttonText,
            width: 600,
          })
        }
      }

      if (!modalDisplaying && ![404, 401].includes(response.status) && skipDisplayingModal) {
        errorModal()
      }
    }

    if (error.details) {
      const detaildError = new DetailedError(error.message, error.details)
      Sentry.withScope(function (scope) {
        scope.setContext('Request', {
          requestId,
          sessionId: getSessionId(),
          url: response.url,
          state: response.status,
        })
        // group errors together based on their request and response
        Sentry.captureException(error.message)
      })
      throw detaildError
    } else {
      const err = new Error(error.message)
      Sentry.withScope(function (scope) {
        scope.setContext('Request', {
          requestId,
          sessionId: getSessionId(),
          url: response.url,
          state: response.status,
        })
        // group errors together based on their request and response
        Sentry.captureException(err)
      })
      throw err
    }
  }
  return response
}

const createQueryFromParams = (params: unknown) => {
  let query = ''
  if (params && Object.keys(params).length) {
    query = `/?${queryString.stringify(params)}`
  }

  return query
}

export const getBackendRootUrl = () => {
  const PROTOCOL = window.VITE_BACKEND_PROTOCOL
  const DOMAIN = window.VITE_BACKEND_DOMAIN
  const PORT = window.VITE_BACKEND_PORT

  return `${PROTOCOL}://${DOMAIN}${PORT ? `:${PORT}` : ''}`
}

const backendUrl = () => {
  const rootUrl = getBackendRootUrl()
  const API_VERSION = window.VITE_BACKEND_VERSION

  return `${rootUrl}/${API_VERSION}`
}

const originalFetch = window.fetch.bind(window)

let shouldRenderNetworkErrorToast = true
window.fetch = async (...args) => {
  try {
    const response = await originalFetch(...args)
    return response
  } catch (err) {
    if (shouldRenderNetworkErrorToast) {
      shouldRenderNetworkErrorToast = false
      await message.error(constructNetworkErrorMessage(intl.formatMessage(errors['networkError.message'])))

      shouldRenderNetworkErrorToast = true
    }

    return Promise.reject({ message: 'Network error' })
  }
}

export const api = {
  get: async (url: string, params?: QueryParams): Promise<Response> => {
    const query = createQueryFromParams(params)
    const fullUrl = `${backendUrl()}${url}${query}`
    const response = await fetch(fullUrl, {
      method: httpMethods.GET,
      headers: { ...getAuthHeader() },
    })
    return handleError(response)
  },
  getWithToken: async (url: string, token: string, params?: QueryParams): Promise<Response> => {
    const query = createQueryFromParams(params)
    const response = await fetch(`${backendUrl()}${url}${query}`, {
      method: httpMethods.GET,
      headers: {
        'Content-Type': 'application/json',
        token,
      },
    })

    return handleError(response)
  },
  getWithoutHeaders: (url: string): Promise<Response> => {
    return fetch(`${backendUrl()}${url}`, {
      method: httpMethods.GET,
    })
  },
  post: async (url: string, body: unknown, params?: QueryParams): Promise<Response> => {
    const query = createQueryFromParams(params)

    const response = await fetch(`${backendUrl()}${url}${query}`, {
      method: httpMethods.POST,
      headers: {
        'Content-Type': 'application/json',
        ...getAuthHeader(),
      },
      body: JSON.stringify(body),
    })

    return handleError(response)
  },
  put: async (url: string, body: unknown): Promise<Response> => {
    const response = await fetch(`${backendUrl()}${url}`, {
      method: httpMethods.PUT,
      headers: {
        'Content-Type': 'application/json',
        ...getAuthHeader(),
      },
      body: JSON.stringify(body),
    })
    return handleError(response)
  },
  putWithToken: async (url: string, token: string, body: unknown): Promise<Response> => {
    return fetch(`${backendUrl()}${url}`, {
      method: httpMethods.PUT,
      headers: {
        'Content-Type': 'application/json',
        Token: token,
      },
      body: JSON.stringify(body),
    })
  },

  patch: async (url: string, body: unknown): Promise<Response> => {
    const response = await fetch(`${backendUrl()}${url}`, {
      method: httpMethods.PATCH,
      headers: {
        'Content-Type': 'application/json',
        ...getAuthHeader(),
      },
      body: JSON.stringify(body),
    })
    return handleError(response)
  },
  patchWithToken: async (url: string, token: string, body: unknown): Promise<Response> => {
    const response = await fetch(`${backendUrl()}${url}`, {
      method: httpMethods.PATCH,
      headers: {
        'Content-Type': 'application/json',
        token,
      },
      body: JSON.stringify(body),
    })
    return handleError(response)
  },
  delete: async (url: string, body?: unknown): Promise<Response> => {
    const requestInit: RequestInit = {
      method: httpMethods.DELETE,
      headers: {
        ...getAuthHeader(),
      },
    }

    if (body) {
      requestInit.headers = {
        ...requestInit.headers,
        'Content-Type': 'application/json',
      }
      requestInit.body = JSON.stringify(body)
    }
    const response = await fetch(`${backendUrl()}${url}`, {
      ...requestInit,
    })
    return handleError(response)
  },
}
