import React, { useEffect, useState } from 'react'
import { Alert, Select } from 'antd'
import { ProfileGroup } from '../../../../../types/profileGroups'
import { Profile } from '../../../../../types/profile'
import { getProfileDetails } from '../../../../../api/user'
import useDebounce from '../../../../../hooks/useDebounce'
import { FilterBody } from '../../../../../components/Filter/Filter'
import * as apiBarracks from '../../../../../api/barracks'
import { useIntl } from 'react-intl'
import { entity } from '../../../../../lang/definitions'
import * as Sentry from '@sentry/react'

interface GroupItemProps {
  entityId: string
  profileGroup: ProfileGroup
  profiles: Array<Profile>
  setProfiles: (profiles: Profile[]) => void
  removeProfileGroupMember: (profileGroupId: string, profileId: string) => void
  updateProfileGroupMembers: (profileGroupId: string, profileId: string) => void
}

interface SelectOptions {
  label: string
  value: string
}

interface MemberProps {
  profileGroupId: string
  profileId: string
  removeProfileGroupMember: (profileGroupId: string, profileId: string) => void
}

const Member = ({ profileGroupId, profileId, removeProfileGroupMember }: MemberProps): React.JSX.Element => {
  const [profile, setProfile] = useState<Profile>()

  const getProfile = async (id: string): Promise<void> => {
    try {
      const { profile: fetchedProfile } = await getProfileDetails(id)
      setProfile(fetchedProfile)
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  useEffect(() => {
    void getProfile(profileId)
  }, [profileId])

  return profile ? (
    <Alert
      className="p-group-member-box"
      type="warning"
      message={`${profile.user.name.first} ${profile.user.name.last}`}
      closable
      onClose={() => removeProfileGroupMember(profileGroupId, profileId)}
    />
  ) : (
    <></>
  )
}

const ProfileGroupItem = ({
  entityId,
  profileGroup,
  profiles,
  setProfiles,
  removeProfileGroupMember,
  updateProfileGroupMembers,
}: GroupItemProps): React.JSX.Element => {
  const intl = useIntl()
  const [isAddUserOpen, setIsAddUserOpen] = useState<boolean>(false)
  const [options, setOptions] = useState<SelectOptions[]>([])
  const [searchQuery, setSearchQuery] = useState<string>('')
  const debouncedSearchQuery = useDebounce(searchQuery, 300)

  useEffect(() => {
    const usersToOptions: SelectOptions[] = profiles.map((profile) => ({
      label: `${profile.user.name.first} ${profile.user.name.last}`,
      value: profile.id,
    }))
    setOptions(usersToOptions)
  }, [profiles])

  const fetchUsers = async (entityId: string): Promise<void> => {
    try {
      const searchBody: FilterBody = {
        operator: 'and',
        entityId: [entityId],
      }
      const { profiles } = await apiBarracks.searchProfile(searchBody)
      setProfiles(profiles)
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const searchUsers = async (searchQuery: string, entityId: string): Promise<void> => {
    try {
      const profiles = await apiBarracks.searchProfileTitleOrEmail(searchQuery, { entityId: [entityId] })
      setProfiles(profiles as Profile[])
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  useEffect(() => {
    if (debouncedSearchQuery) {
      void searchUsers(debouncedSearchQuery, entityId)
    } else {
      void fetchUsers(entityId)
    }
  }, [entityId, debouncedSearchQuery])

  useEffect(() => {
    const usersToOptions: SelectOptions[] = profiles.map((profile) => ({
      label: `${profile.user.name.first} ${profile.user.name.last}`,
      value: profile.id,
    }))
    setOptions(usersToOptions)
  }, [profiles])

  const handleSelect = (profileGroupId: string, profileId: string): void => {
    updateProfileGroupMembers(profileGroupId, profileId)
  }

  return (
    <React.Fragment>
      {profileGroup.profileIds &&
        profileGroup.profileIds.map((profileId: string) => (
          <Member
            key={`${profileGroup.id}-${profileId}`}
            profileGroupId={profileGroup.id}
            profileId={profileId}
            removeProfileGroupMember={removeProfileGroupMember}
          />
        ))}
      {isAddUserOpen && (
        <Select
          style={{ width: '100%' }}
          showSearch
          options={options}
          filterOption={false}
          onSelect={(profileId: unknown) => handleSelect(profileGroup.id, profileId as string)}
          onSearch={(value) => setSearchQuery(value)}
          searchValue={searchQuery}
          value={searchQuery}
        />
      )}
      {!isAddUserOpen && (
        <a onClick={() => setIsAddUserOpen(!isAddUserOpen)}>
          + {intl.formatMessage(entity['profile.groups.add.user'])}
        </a>
      )}
    </React.Fragment>
  )
}

export default ProfileGroupItem
