import { api } from './utils'
import { ProgressData } from '../types/paymentInstruction'
import { RedirectData } from '../types/charge'

export const backendLog = async (
  message: string,
  details: {
    chargeId?: string
    redirectData?: RedirectData
    progressData?: ProgressData
  }
): Promise<unknown> => {
  const data = {
    message,
    details,
  }
  const response = await api.post('/utils/log', data)
  return response
}
