import { api } from './utils'
import { Entity } from '../types/entity'
import { Document, DocumentDetails } from '../types/documents'
import { SearchUser } from '../pages/Reports/utils'
import { Approver, ApproverRound, User } from '../types/user'
import { QueryParams } from '../types/general'
import { Profile } from '../types/profile'
import { FilterBody } from '../components/Filter/Filter'
import { ProfileGroup } from '../types/profileGroups'
import * as Sentry from '@sentry/react'

const BASE_PATH = 'barracks'

export const searchProfile = async (
  filter: FilterBody,
  params?: QueryParams,
  operator?: 'and' | 'or'
): Promise<{
  profiles: Array<Profile>
  total?: number
}> => {
  const url = `/${BASE_PATH}/search`
  const body: FilterBody = {
    operator: operator || ('and' as const),
    kind: 'profile',
    ...filter,
  }

  const response = await api.post(url, body, params)

  const profiles = (await response.json()) as Array<Profile>
  const total = response.headers.get('x-total-count')

  return {
    profiles,
    total: Number.parseInt(total || '', 10),
  }
}

export const search = async <T extends Profile | User | Entity | ProfileGroup>(
  kind: 'profile' | 'user' | 'entity' | 'profileGroup',
  filter: FilterBody,
  params?: QueryParams,
  operator?: 'and' | 'or'
): Promise<{
  result: Array<T>
  total?: number
}> => {
  const url = `/${BASE_PATH}/search`
  const body: FilterBody = {
    operator: operator || ('and' as const),
    kind: kind,
    ...filter,
  }

  const response = await api.post(url, body, params)

  const result = (await response.json()) as Array<T>
  const total = response.headers.get('x-total-count')

  return {
    result,
    total: Number.parseInt(total || '0', 10),
  }
}

export const searchProfileTitleOrEmail = async (
  value: string,
  ringFence?: { entityId?: string[] }
): Promise<void | Profile[]> => {
  const url = `/${BASE_PATH}/barracks-search`
  // Search for users on email or title
  const userBody: FilterBody = {
    operator: 'or',
    kind: 'user',
    emailLike: value,
    title: value,
    ringFence,
  }

  return api.post(url, userBody).then(async (userResponse) => {
    return userResponse.json().then(async (users: { id: string }[]) => {
      // then, based on the users, find profiles matching these userIds
      const profileBody: FilterBody = {
        operator: 'and',
        kind: 'profile',
        userId: users.map((u) => u.id),
        ringFence,
      }

      return api.post(url, profileBody).then(async (profileResponse) => {
        return profileResponse.json().then((profiles: Profile[]) => {
          return profiles
        })
      })
    })
  })
}

export const getUsers = async (userId: string): Promise<Entity[] | undefined> => {
  const url = `/${BASE_PATH}/entity-hierarchy/user/${userId}`

  try {
    const response = await api.get(url)

    const users = (await response.json()) as Entity[]
    return users
  } catch (error) {
    Sentry.captureException(error)
  }
}

export const getEntity = async (entityId: string): Promise<Entity | undefined> => {
  const url = `/${BASE_PATH}/entity/${entityId}`
  try {
    const response = await api.get(url)

    const entity = (await response.json()) as Entity

    return entity
  } catch (error) {
    console.log('Error with fetching', error)
  }
}

export const getEntityProfile = async (profileId: string): Promise<Entity | undefined> => {
  const url = `/${BASE_PATH}/entity-hierarchy/profile/${profileId}`
  try {
    const response = await api.get(url)

    const profile = (await response.json()) as Entity

    return profile
  } catch (error) {
    Sentry.captureException(error)
  }
}

export const getUsersBySearch = async (listOfIds: string[], idType: string): Promise<SearchUser[] | undefined> => {
  const url = `/${BASE_PATH}/search`
  try {
    const response = await api.post(url, {
      kind: 'user',
      [idType]: listOfIds,
    })

    const users = (await response.json()) as SearchUser[]
    return users
  } catch (error) {
    console.log('Error with fetching', error)
  }
}

export const getEntityUsersByRole = async (entityId: string, role: string): Promise<Approver[]> => {
  const url = `/${BASE_PATH}/entity/${entityId}/role/${role}`

  const response = await api.get(url)

  return await response.json()
}

export const getApprovalRounds = async (
  entityId: string,
  paymentInstructionIds: Array<string>
): Promise<ApproverRound[]> => {
  const url = `/${BASE_PATH}/entity/${entityId}/role/approver`
  try {
    const response = await api.post(url, paymentInstructionIds)
    return await response.json()
  } catch (error) {
    const { message } = error as Error
    throw Error(message)
  }
}

export const createUploadedDocument = async (data: DocumentDetails, profileId: string): Promise<Document[]> => {
  const url = `/${BASE_PATH}/document/profile/${profileId}`

  const response = await api.post(url, data)

  return response.json()
}

export const getDocuments = async (
  profileId: string,
  params?: QueryParams
): Promise<{
  documents: Array<Document>
  total?: number
}> => {
  const url = `/${BASE_PATH}/document/profile/${profileId}`

  const response = await api.get(url, params)

  const documents = (await response.json()) as Array<Document>
  const total = response.headers.get('x-total-count')

  return {
    documents,
    total: Number.parseInt(total || '', 10),
  }
}

export const downloadUploadedDocument = async (documentId: string): Promise<Response> => {
  const url = `/${BASE_PATH}/document/${documentId}/file`

  const response = await api.get(url)
  return response
}

/******************PROFILE GROUPS******************/
export const searchProfileGroups = async (
  filter: FilterBody,
  params?: QueryParams,
  operator?: 'and' | 'or'
): Promise<{
  profileGroups: Array<ProfileGroup>
  total?: number
}> => {
  const url = `/${BASE_PATH}/search`
  const body: FilterBody = {
    operator: operator || ('and' as const),
    kind: 'profileGroup',
    ...filter,
  }

  const response = await api.post(url, body, params)

  const profileGroups = (await response.json()) as Array<ProfileGroup>
  const total = response.headers.get('x-total-count')

  return {
    profileGroups,
    total: Number.parseInt(total || '', 10),
  }
}

export const getProfileGroups = async (entityId: string, params?: QueryParams): Promise<ProfileGroup[]> => {
  const url = `/${BASE_PATH}/entity/${entityId}/profile-group`

  try {
    const response = await api.get(url, params)
    return await response.json()
  } catch (error) {
    const { message } = error as Error
    throw Error(message)
  }
}

export const createProfileGroup = async (
  entityId: string,
  profileGroup: Partial<ProfileGroup> | Array<Partial<ProfileGroup>>
): Promise<ProfileGroup[]> => {
  const url = `/${BASE_PATH}/entity/${entityId}/profile-group`

  try {
    const response = await api.post(url, profileGroup)
    return await response.json()
  } catch (error) {
    const { message } = error as Error
    throw Error(message)
  }
}

export const getProfileGroup = async (entityId: string, profileGroupId: string): Promise<ProfileGroup[]> => {
  const url = `/${BASE_PATH}/entity/${entityId}/profile-group/${profileGroupId}`

  try {
    const response = await api.get(url)
    return await response.json()
  } catch (error) {
    const { message } = error as Error
    throw Error(message)
  }
}

export const updateProfileGroup = async (
  entityId: string,
  profileGroupId: string,
  profileGroup: ProfileGroup
): Promise<ProfileGroup> => {
  const url = `/${BASE_PATH}/entity/${entityId}/profile-group/${profileGroupId}`

  try {
    const response = await api.put(url, profileGroup)
    return await response.json()
  } catch (error) {
    const { message } = error as Error
    throw Error(message)
  }
}

export const deleteProfileGroup = async (entityId: string, profileGroupId: string): Promise<void> => {
  const url = `/${BASE_PATH}/entity/${entityId}/profile-group/${profileGroupId}`

  try {
    await api.delete(url)
  } catch (error) {
    const { message } = error as Error
    throw Error(message)
  }
}

export const updateProfileGroupMember = async (
  entityId: string,
  profileGroupId: string,
  profileId: string
): Promise<string[]> => {
  const url = `/${BASE_PATH}/entity/${entityId}/profile-group/${profileGroupId}/profile/${profileId}`

  try {
    const response = await api.put(url, {})
    return await response.json()
  } catch (error) {
    const { message } = error as Error
    throw Error(message)
  }
}

export const deleteProfileGroupMember = async (
  entityId: string,
  profileGroupId: string,
  profileId: string
): Promise<void> => {
  const url = `/${BASE_PATH}/entity/${entityId}/profile-group/${profileGroupId}/profile/${profileId}`

  try {
    await api.delete(url)
  } catch (error) {
    const { message } = error as Error
    throw Error(message)
  }
}
