import { PAYMENT_KIND, Payment, PaymentInstruction } from 'types/paymentInstruction'

export default function mapPaymentInstructionToPayment(pi: PaymentInstruction): Payment {
  return {
    kind: PAYMENT_KIND.PAYMENT_INSTRUCTION,
    state: pi.state!,
    id: pi.id!,
    profileId: pi.profileId,
    beneficiaryTitle: pi.beneficiary!.title,
    beneficiaryAccountNumber: pi.beneficiary!.accountNumber,
    beneficiaryRoutingNumber: pi.beneficiary!.routingNumber,
    beneficiaryClearingNetwork: pi.beneficiary!.clearingNetwork,
    newBeneficiary: pi.beneficiary?.newBeneficiary,
    cardNetwork: pi.source?.typeProperties.cardNetwork,
    cardNumber: pi.source ? `${pi.source.typeProperties.bin} •••• •••• ${pi.source.typeProperties.last4}` : undefined,
    currency: pi.currency,
    amount: pi.amount,
    dateDue: pi.dateDue,
    sourceId: pi.source?.id,
    signatures: pi.signatures,
    source: pi.source,
    duplicateCheckId: `${pi.amount}${pi.currency}${pi.reference.join('')}${pi.beneficiary!.accountNumber}`,
    isSupplierFunded: !!pi.supplierFunded,
    creationChannel: pi.creationChannel,
  }
}
