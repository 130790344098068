import { useSession } from 'stores/session'

interface ReferencePrefixAndSuffix {
  prefix: string
  suffix: string
  prefixLength: number
  suffixLength: number
}

const usePaymentInstructionReferencePrefixAndSuffix = (): ReferencePrefixAndSuffix => {
  const {
    state: { rules },
  } = useSession()

  if (!rules?.logic?.paymentInstructionReferencePrefixAndSuffix?.rule) {
    return { prefix: '', suffix: '', prefixLength: 0, suffixLength: 0 }
  }

  const { prefix, suffix } = rules.logic.paymentInstructionReferencePrefixAndSuffix.rule

  return { prefix, suffix, prefixLength: prefix.length, suffixLength: suffix.length }
}

export default usePaymentInstructionReferencePrefixAndSuffix
