import { useReducer, Reducer } from 'react'
import { SET_SSO_STATUS, SET_PROVIDER_ID } from './SsoActionTypes'
import { createContainer } from 'react-tracked'

const initialState = {
  ssoStatus: '',
  providerId: '',
}

type State = typeof initialState

type Action = { type: string; value: string }

const reducer: Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case SET_SSO_STATUS:
      return {
        ...state,
        ssoStatus: action.value,
      }
    case SET_PROVIDER_ID:
      return {
        ...state,
        providerId: action.value,
      }
    default:
      throw new Error('unknown action type')
  }
}

const useValue = () => useReducer(reducer, initialState)

export const { Provider: SsoStateProvider, useTracked: useSsoState } = createContainer(useValue)
