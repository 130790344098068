import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css, token }) => ({
  container: css`
    margin-top: 20px;
    padding: 15px;
    background-color: white;
    border-radius: 10px;
  `,
  approversLabel: css`
    margin-left: 5px;

    span {
      font-size: ${token.fontSize}px;
      font-style: normal;
      font-weight: 500;
    }
  `,
  numberOfSignatures: css`
    margin: 0 3px;
    font-weight: 500;
  `,
  approversList: css`
    margin-top: 15px;
  `,
  approversListHeader: css`
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
  `,
  label: css`
    span {
      font-size: ${token.fontSize}px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.65);
      margin-right: 5px;
    }
  `,
  primary: css`
    background-color: ${token.colorPrimary} !important;
    color: white !important;
  `,
  approversText: css`
    font-size: ${token.fontSizeSM}px;
  `,
}))

export default useStyles
