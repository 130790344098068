import { PAYMENT_KIND, Payment } from 'types/paymentInstruction'

export default function sortPaymentsByKind(payments: Payment[]): {
  paymentInstructionIds: string[]
  templateIds: string[]
} {
  const mapToId = (payment: Payment) => payment.id

  return {
    paymentInstructionIds: payments
      .filter((payment: Payment) => payment.kind === PAYMENT_KIND.PAYMENT_INSTRUCTION)
      .map(mapToId),
    templateIds: payments.filter((payment: Payment) => payment.kind !== PAYMENT_KIND.PAYMENT_INSTRUCTION).map(mapToId),
  }
}
