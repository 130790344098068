import React from 'react'
import useReportCardStyles from './ReportCard.style'
import useReportsStyles from './Reports.style'

interface CardProps {
  title: string
  icon: string
  children: React.JSX.Element
}

const ReportCard = ({ title, icon, children }: CardProps): React.JSX.Element => {
  const { styles } = useReportCardStyles()
  const { styles: parentStyle } = useReportsStyles()
  return (
    <div className={styles.container}>
      <div className={parentStyle.header}>
        <img className={parentStyle.icon} src={icon} alt="icon" />
        <div className={styles.title}>{title}</div>
      </div>
      <div className={parentStyle.content}>{children}</div>
    </div>
  )
}

export default ReportCard
