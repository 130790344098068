import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css }) => ({
  container: css`
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 15px 0;
  `,
}))

export default useStyles
