import {
  PaymentFormData,
  PaymentInstructionTemplate,
  PaymentInstructionReferencePrefixAndSuffixLength,
} from 'types/paymentInstruction'
import { convertAmountCentsToForm } from '.'
import { extractPrefixAndSuffixFromReference } from './extractPrefixAndSuffixFromReference'

export default function mapPaymentInstructionTemplateToForm(
  template: PaymentInstructionTemplate,
  prefixAndSuffix: PaymentInstructionReferencePrefixAndSuffixLength
): PaymentFormData {
  const { occurrencesRemaining, paymentInstructionProperties } = template
  const { sourceId, beneficiaryId, reference, amount, currency, remittanceAdvice } = paymentInstructionProperties

  const referenceString = reference.join(', ')

  const referenceWithoutPrefixAndSuffix = extractPrefixAndSuffixFromReference(referenceString, prefixAndSuffix)

  return {
    sourceId,
    beneficiaryId,
    reference: referenceWithoutPrefixAndSuffix,
    amount: convertAmountCentsToForm(amount),
    currency,
    sendRemittance: !!remittanceAdvice,
    remittanceAdvice: remittanceAdvice ? remittanceAdvice.emails[0] : undefined,
    recurring: occurrencesRemaining && occurrencesRemaining === 1 ? false : true,
  }
}
