import React from 'react'
import { Pagination } from 'antd'
import classNames from 'classnames'
import Payment from '../Payment/Payment'
import { DEFAULT_PAYMENTS_PAGE_SIZE } from '../../Add/utils'
import { Payment as PaymentType, PAYMENT_KIND } from 'types/paymentInstruction'
import { useCurrentPayment } from 'stores/Payment'
import usePaymentsStyle from './Payments.style'

interface PaymentsProps {
  paymentList: PaymentType[]
  totalNumberOfPayments: number
  disableSelect?: boolean
  selectPayments: (payments: PaymentType[]) => void
  deselectPayments: (payments: PaymentType[]) => void
  editPayment?: (kind: PAYMENT_KIND, id: string) => void
  deletePayment?: (payment: PaymentType) => void
  showPaymentDetails?: (kind: PAYMENT_KIND, id: string) => void
  removePaymentFromSet?: (kind: PAYMENT_KIND, id: string) => void
}

const Payments = (props: PaymentsProps): React.JSX.Element => {
  const {
    paymentList,
    totalNumberOfPayments,
    disableSelect,
    selectPayments,
    deselectPayments,
    editPayment,
    deletePayment,
    showPaymentDetails,
    removePaymentFromSet,
  } = props

  const { styles } = usePaymentsStyle()
  const {
    state: { currentPaymentsPage, selectedPayments },
    actions: { setCurrentPaymentsPage },
  } = useCurrentPayment()

  const handlePaymentSelectToggle = (selectedPayment: PaymentType): void => {
    const paymentIdIndex = selectedPayments.findIndex((payment: PaymentType) => payment.id === selectedPayment.id)

    if (paymentIdIndex === -1) {
      selectPayments([selectedPayment])
    } else {
      deselectPayments([selectedPayment])
    }
  }

  return (
    <div
      className={classNames('payments-container', {
        'select-disabled': disableSelect,
      })}
    >
      <div className={styles.payments} data-testid="payments">
        {paymentList.map((payment: PaymentType) => {
          const selectedPaymentIndex = selectedPayments.findIndex(
            (selectedPayment: PaymentType) => selectedPayment.id === payment.id
          )

          return (
            <Payment
              key={`payment-${payment.id}`}
              disableSelect={disableSelect}
              data={payment}
              isSelected={selectedPaymentIndex !== -1}
              selectToggle={handlePaymentSelectToggle}
              editPayment={editPayment}
              deletePayment={deletePayment}
              showPaymentDetails={showPaymentDetails}
              removePaymentFromSet={removePaymentFromSet}
            />
          )
        })}
      </div>
      <div className={styles.paymentsPagination}>
        <Pagination
          current={currentPaymentsPage}
          defaultPageSize={DEFAULT_PAYMENTS_PAGE_SIZE}
          hideOnSinglePage
          showSizeChanger={false}
          total={totalNumberOfPayments}
          size="small"
          onChange={setCurrentPaymentsPage}
        />
      </div>
    </div>
  )
}

export default Payments
