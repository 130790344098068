import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css, token, responsive }) => ({
  container: css`
    height: calc(100vh - 200px);
    overflow: auto;
    padding-right: ${token.paddingMD}px;

    ${responsive.sm} {
      padding-right: ${token.paddingSM}px;
    }
  `,
  selectedOptionContainer: css`
    background-color: #e7edf6;
    border-radius: 10px;
    padding: 15px;

    .ant-form-item {
      margin-bottom: 0;
    }
  `,
  green: css`
    background-color: #70b28b;
  `,
  changeSelected: css`
    display: flex;
    button {
      margin-left: auto;
      padding: 0;
      color: #c15a5a;
    }
  `,
  itemWithAction: css`
    display: flex;
    gap: 10px;

    .ant-form-item {
      width: 100%;
    }

    .ant-select-selection-placeholder {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 150px;
      display: inline-block;
    }

    ${responsive.sm} {
      .ant-select-selection-placeholder {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 150px;
        display: inline-block;
      }
    }
  `,
  attachmentFile: css`
    display: flex;
    align-items: center;
  `,
  attachmentName: css`
    margin: 0 5px;
  `,
  deleteAttachment: css`
    margin-left: auto;
  `,
  attachmentComment: css`
    span {
      color: rgba(0, 0, 0, 0.45);
    }
  `,
  attachmentLabelButtonContainer: css`
    display: flex;
    justify-content: space-between;
  `,
  attachmentLabel: css`
    display: flex;
    align-items: center;
    span {
      font-size: ${token.fontSizeSM}px;
    }
  `,
  itemWithTags: css`
    display: flex;
    justify-content: space-between;
  `,
  tags: css`
    display: flex;
    align-items: center;

    button {
      margin-left: 20px;
    }
  `,
  oddOption: css`
    background-color: #f5f5f5;
  `,
  paymentFormSelectOption: css``,
  paymentAmountInput: css`
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    height: 40px !important;
    width: 100% !important;
    border-left: 0px !important;
  `,
  dueDateLabel: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  `,
  inlineItems: css`
    width: 100%;
  `,
}))

export default useStyles
