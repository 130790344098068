import React, { useEffect } from 'react'
import { Form, FormInstance } from 'antd'
import { RecipientTitle, RoutingNumber, AccountNumber, TypeSelector } from './FormItems'
import { CountryWarning, SpaceForm } from '../components'
import { useTranslation } from '../../../utils/helpers'
import {
  useBicCodeValidator,
  useSwiftAccountNumberValidator,
  AccountNumberMaxLength,
  SwiftRoutingNumberMaxLength,
} from 'utils/validators'

interface SWIFTProps {
  getFormInstance?: (form: FormInstance) => void
  onFinish?: (values: SWIFTForm) => void
}

export type SWIFTForm = {
  title: string
  accountNumber: string
  type: string
  routingNumber: string
  countryMatch: boolean
}

const SWIFT = ({ getFormInstance, onFinish }: SWIFTProps): React.JSX.Element => {
  const [swiftForm] = Form.useForm<SWIFTForm>()

  const t = useTranslation()

  const accountNumberValidator = useSwiftAccountNumberValidator()

  const bicCodeValidator = useBicCodeValidator()

  useEffect(() => {
    getFormInstance?.(swiftForm)
  }, [swiftForm])

  return (
    <Form form={swiftForm} requiredMark={false} onFinish={onFinish} layout="vertical" name="swift">
      <SpaceForm>
        <RecipientTitle />

        <RoutingNumber
          label={t('beneficiary.bicCode')}
          validator={bicCodeValidator}
          maxLength={SwiftRoutingNumberMaxLength}
        />

        <AccountNumber
          label={t('beneficiary.accountNumber')}
          validator={accountNumberValidator}
          maxLength={AccountNumberMaxLength}
        />

        <CountryWarning />

        <TypeSelector type="beneficiary" />
      </SpaceForm>

      <button type="submit" style={{ display: 'none' }} />
    </Form>
  )
}

export default SWIFT
