export const removeScript = (id: number) => {
  const existingScript = document.querySelector(`script[id="${id}"]`)
  if (existingScript) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    existingScript.parentNode.removeChild(existingScript)
  }
}

export const appendScript = (id: string, callback: () => void) => {
  const existingScript = document.getElementById(id)
  if (!existingScript) {
    const script = document.createElement('script')
    script.id = id
    script.src =
      id === '100128998'
        ? `https://test-api.paymentiq.io/paymentiq/api/viq/jscardencrypter/${id}`
        : id === '100128999'
          ? `https://test-api.paymentiq.io/paymentiq/api/viq/jscardencrypter/${id}`
          : `https://api.paymentiq.io/paymentiq/api/viq/jscardencrypter/${id}`
    script.async = true
    document.body.appendChild(script)

    script.onload = () => {
      if (callback) callback()
    }
  }
  if (existingScript && callback) callback()
}
