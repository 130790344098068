import React from 'react'
import AccountNumber from '../../components/Format/AccountNumber/AccountNumber'
import { Beneficiary as BeneficiaryType } from '../../types/beneficiary'

interface BeneficiaryProps {
  beneficiary: BeneficiaryType
}

const Beneficiary = ({ beneficiary }: BeneficiaryProps): React.JSX.Element => {
  return (
    <div className="beneficiary-container">
      <div className="beneficiary-title" data-testid="beneficiary-title">
        <span>
          {beneficiary.title}
          {beneficiary?.currency ? ` - ${beneficiary.currency.toUpperCase()}` : ''}
        </span>
      </div>
      <div className="beneficiary-account-number">
        <AccountNumber
          accountNumber={beneficiary.accountNumber}
          routingNumber={beneficiary.routingNumber}
          clearingNetwork={beneficiary.clearingNetwork}
        ></AccountNumber>
      </div>
    </div>
  )
}

export default Beneficiary
