import { getApprovalRounds } from 'api/barracks'
import { useActiveUser } from 'hooks'
import { sortedUniq } from 'lodash'
import { getCurrentPage, applyPaymentsFilter } from 'pages/PaymentInstructions/Add/utils'
import { useState, useEffect } from 'react'
import { Payment } from 'types/paymentInstruction'
import { Profile } from 'types/profile'
import useCurrentPayment from './useCurrentPayment'
import * as Sentry from '@sentry/react'
import * as apiRules from 'api/rules'
import { SignChainRule } from 'types/rules'
import { getSets } from 'utils/init'
import { useSession } from 'stores/session'

export default function useCurrentPaymentEffects() {
  const { state: sessionState } = useSession()
  const user = sessionState.user!
  const rules = sessionState.rules!
  const [activeProfile, setActiveProfile] = useState<Profile>()
  const activeUser = useActiveUser()

  const {
    state: {
      filteredPayments,
      currentPaymentsPage,
      currentPaymentsInSetPage,
      paymentsInSet,
      entityRules,
      payments,
      paymentsFilter,
      selectedPayments,
      approveSet,
      requireSignatureSet,
    },
    actions: {
      setPaymentsOnCurrentPage,
      setPaymentsInSetOnCurrentPage,
      setSignatureOrder,
      setFilteredPayments,
      setCurrentPaymentsPage,
      setCurrencies,
      setIsAllPaymentsSelectedButtonActive,
      setEntityRules,
      dispatchApprovalRounds,
      dispatchReadySet,
      dispatchRequireSignatureSet,
    },
  } = useCurrentPayment()

  useEffect(() => {
    const init = async () => {
      const signChainRules = rules?.logic?.requiredSignChain?.rule
      const isSignRequired = signChainRules?.every((rule: SignChainRule) => rule.requiredSignatures !== 0)
      try {
        const sets = await getSets(activeUser.profileId)

        dispatchReadySet(sets.readySet)
        if (isSignRequired) {
          dispatchRequireSignatureSet(sets.requireSignatureSet)
        }
      } catch (err) {
        Sentry.captureException(err)
      }
    }

    void init()
  }, [])

  useEffect(() => {
    const activeProfile = user.profiles.find((profile) => profile.id === user.activeProfileId)

    setActiveProfile(activeProfile)
  }, [])

  useEffect(() => {
    if (!activeProfile) {
      return
    }

    const getEntityRules = async (entityId: string): Promise<void> => {
      try {
        const entityRules = await apiRules.getEntityRules(entityId)
        setEntityRules(entityRules)
      } catch (error) {
        Sentry.captureException(error)
      }
    }

    void getEntityRules(activeProfile.entityId)
  }, [activeProfile])

  useEffect(() => {
    const paymentsOnCurrentPage = getCurrentPage(filteredPayments, currentPaymentsPage) as Payment[]

    setPaymentsOnCurrentPage(paymentsOnCurrentPage)
  }, [filteredPayments, currentPaymentsPage])

  useEffect(() => {
    const paymentsOnCurrentPage = getCurrentPage(paymentsInSet, currentPaymentsInSetPage) as Payment[]

    setPaymentsInSetOnCurrentPage(paymentsOnCurrentPage)
  }, [paymentsInSet, currentPaymentsInSetPage])

  useEffect(() => {
    const signChainRuleArray = entityRules?.logic.requiredSignChain.rule

    if (!signChainRuleArray) {
      return
    }

    // use first as for now should be the same for every currency
    const [signChain] = signChainRuleArray

    setSignatureOrder(signChain.signatureOrder)
  }, [entityRules])

  useEffect(() => {
    const filteredPayments = applyPaymentsFilter(payments, paymentsFilter)

    setFilteredPayments(filteredPayments)
  }, [payments, paymentsFilter])

  useEffect(() => {
    setCurrentPaymentsPage(1)
  }, [paymentsFilter, payments])

  useEffect(() => {
    const paymentsOnCurrentPage = getCurrentPage(filteredPayments, currentPaymentsPage) as Payment[]

    setPaymentsOnCurrentPage(paymentsOnCurrentPage)
  }, [filteredPayments, currentPaymentsPage])

  useEffect(() => {
    if (!payments.length) {
      return
    }

    const currencies = sortedUniq(payments.map((payment) => payment.currency).sort())

    setCurrencies(currencies)
  }, [payments])

  useEffect(() => {
    const isAllPaymentsSelectedButtonActive =
      filteredPayments.length > 0 &&
      filteredPayments.every((payment) => {
        return selectedPayments.find((selectedPayment) => selectedPayment.id === payment.id)
      })

    setIsAllPaymentsSelectedButtonActive(isAllPaymentsSelectedButtonActive)
  }, [filteredPayments, selectedPayments])

  useEffect(() => {
    const handleApprovers = async () => {
      if (user && requireSignatureSet) {
        const { entityId } = activeUser
        const { paymentInstructionIds, paymentInstructionTemplateIds } = requireSignatureSet
        const fetchedApprovalRounds = await getApprovalRounds(entityId, [
          ...(paymentInstructionIds || []),
          ...(paymentInstructionTemplateIds || []),
        ])
        // setApprovers([])
        dispatchApprovalRounds(fetchedApprovalRounds)
      }
    }

    void handleApprovers()
  }, [requireSignatureSet, user])

  useEffect(() => {
    const handleApprovers = async () => {
      if (user && approveSet) {
        const { entityId } = activeUser
        const { paymentInstructionIds, paymentInstructionTemplateIds } = approveSet
        const approvalRounds = await getApprovalRounds(entityId, [
          ...(paymentInstructionIds || []),
          ...(paymentInstructionTemplateIds || []),
        ])
        dispatchApprovalRounds(approvalRounds)
      }
    }

    void handleApprovers()
  }, [approveSet])
}
