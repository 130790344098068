import React, { useState } from 'react'
import { Upload } from 'antd'
import { UploadChangeParam } from 'antd/lib/upload/interface'
import { File } from 'types/file'

export interface UploadProps {
  children?: React.ReactNode // the button etc
  dragger?: boolean // if the element should be a drag drop
  multiple?: boolean // if allowing multiple uploads
  accept?: string // filetypes to allow (images/png, images/jpeg and pdf)
  showUploadList?: boolean // if showing default upload list
  onChange: (fileListWithContent: File[]) => void // Callback that will receive an array of files and contents
  limitUploads?: number // Limits number of files to upload
  checkInvoiceFileTypeAndSize?: boolean // Function to run before uploading
  dontPreviewFileImage?: boolean // If showing default upload list for invoice
  setHasFileError?: (showFileLimitExceedError: boolean) => void
  setUpdateFailAlert?: (setUpdateFailAlert: boolean) => void
}

// Have to use Any here, cannot combine the types of Uint8Array and readAsArrayBuffer
export const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
  const bytes = new Uint8Array(buffer)
  const chars = []
  const len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    chars.push(String.fromCharCode(bytes[i]))
  }
  const binary = chars.join('')
  return window.btoa(binary)
}

export const BhUpload = ({
  multiple,
  accept,
  onChange,
  children,
  dragger,
  showUploadList = true,
  limitUploads,
  checkInvoiceFileTypeAndSize,
  dontPreviewFileImage,
  setHasFileError,
  setUpdateFailAlert,
}: UploadProps): React.JSX.Element => {
  const [fileList, setFileList] = React.useState<File[]>()
  const [files, setFiles] = useState<File[]>([])
  const [sizeExceededFileId, setSizeExceededFileId] = useState('')
  const maxFileSize = 10 * 1024 * 1024
  const supportedFileTypes = ['.jpg', '.jpeg', '.png', '.pdf']

  const hasFileExceedMaxSizeLimit = (file: File) => {
    return file?.size && file.size > maxFileSize
  }

  const hasUnsupportedFileTypes = (file: File) => {
    const fileExtension = file.name.slice(file.name.lastIndexOf('.')).toLowerCase()
    return !supportedFileTypes.includes(fileExtension)
  }

  const props = {
    multiple: !!multiple,
    defaultFileList: [],
    showUploadList,
    onChange: (state: UploadChangeParam) => {
      const fileListWithContent: File[] = []
      const { fileList } = state
      fileList.forEach((file) => {
        const { uid, name, size, type, originFileObj } = file
        const fileName = name.replace(/(?:\.(?![^.]+$)|[^\w.]|[_])+/g, '')
        const reader = new FileReader()
        reader.addEventListener('load', () => {
          const base64Content = arrayBufferToBase64(reader.result as ArrayBuffer)
          fileListWithContent.push({
            originFileObj,
            base64Content,
            uid,
            name: fileName,
            size,
            type,
          })
          // execute callback when all files are loaded
          if (fileListWithContent.length === fileList.length) {
            if (limitUploads) {
              setFiles([fileListWithContent.slice(-limitUploads)[0]])
              onChange([fileListWithContent.slice(-limitUploads)[0]])
              setFileList(fileList.slice(-limitUploads))
            } else {
              setFiles(fileListWithContent)
              onChange(fileListWithContent)
              setFileList(fileList)
            }
            if (sizeExceededFileId) {
              const foundFile = fileList.find((eachFile) => eachFile.uid === sizeExceededFileId)
              if (foundFile) {
                foundFile.status = 'error'
              }
              setFileList([...fileList])
            }
          }
        })

        if (originFileObj) {
          reader.readAsArrayBuffer(originFileObj)
        }
      })
      if (files.length && !fileList.length) {
        setFiles([])
        onChange([])
      }
    },
    beforeUpload: (file: File) => {
      setFileList([])
      onChange([])
      setUpdateFailAlert?.(false)
      if (checkInvoiceFileTypeAndSize && (hasFileExceedMaxSizeLimit(file) || hasUnsupportedFileTypes(file))) {
        setHasFileError?.(true)
        setSizeExceededFileId(file.uid)
      } else {
        setHasFileError?.(false)
      }
      return false
    },
    onRemove: () => {
      setFileList([])
      onChange([])
      return true
    },
  }
  let component
  if (dragger) {
    component = (
      <Upload.Dragger
        {...props}
        listType={dontPreviewFileImage ? undefined : 'picture'}
        fileList={fileList}
        accept={accept}
      >
        {children}
      </Upload.Dragger>
    )
  } else {
    component = (
      <Upload {...props} listType={dontPreviewFileImage ? undefined : 'picture'} accept={accept}>
        {children}
      </Upload>
    )
  }

  return component
}
