import React from 'react'
import { Event } from '../../types/activity'
import Date from '../Format/Date/Date'
import { useLanguageState } from '../../stores/language/LanguageStore'

interface DeleteEventDetailsProps {
  event: Event
}
const DeleteEventDetails = ({ event }: DeleteEventDetailsProps): React.JSX.Element => {
  const [languageState] = useLanguageState()
  const language = languageState.language

  const prepareContent = (event: Event): React.JSX.Element => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>{event.labelText}</span>
        <Date value={event.dateCreated} locale={language} time />
      </div>
    )
  }

  return event && <div>{prepareContent(event)}</div>
}

export default DeleteEventDetails
