import { find } from 'lodash'
import { Profile } from '../types/profile'
import { PasswordPolicyRule } from '../types/rules'
import { User } from '../types/user'
import { IntlShape, MessageDescriptor, useIntl } from 'react-intl'
import { PrimitiveType, FormatXMLElementFn, Options as IntlMessageFormatOptions } from 'intl-messageformat'
import * as translations from '../lang/definitions'
import * as Sentry from '@sentry/react'
import { DetailsTotal } from 'types/paymentInstruction'

interface TranslationsObject {
  [key: string]: MessageDescriptor
}
export interface ActiveUser {
  profileId: string
  entityId: string
  sessionId: string
  profile: Profile
}

export interface PhoneNumberMask {
  fi: string
  gb: string
  ie: string
  it: string
  nl: string
  se: string
}

export const getActiveUser = (user: User): ActiveUser => {
  const profileId = user?.activeProfileId || ''
  const sessionId = user?.sessionId
  const profile = find(user?.profiles, { id: user?.activeProfileId }) || ({} as Profile)
  const entityId =
    find(user?.profiles, {
      id: user?.activeProfileId,
    })?.entityId || ''
  return {
    profileId,
    entityId,
    sessionId,
    profile,
  }
}

export const getLanguageCodeFromCountryCode = (countryCode: string): string => {
  let languageCode = ''

  switch (countryCode) {
    case 'it': {
      languageCode = 'it-IT'
      break
    }
    case 'fi': {
      languageCode = 'fi-FI'
      break
    }
    case 'ie': {
      languageCode = 'en-IE'
      break
    }
    case 'se': {
      languageCode = 'sv-SE'
      break
    }
    case 'nl': {
      languageCode = 'nl-NL'
      break
    }
    case 'gb': {
      languageCode = 'en-GB'
      break
    }
    default: {
      languageCode = 'en-GB'
    }
  }

  return languageCode
}

export const getCountryCodeFromLanguageCode = (languageCode: string): string => {
  return languageCode.slice(languageCode.length - 2).toLowerCase()
}

export const getLanguageFromLanguageCode = (languageCode: string): string => {
  return languageCode.slice(0, 2).toLowerCase()
}

export const phoneInputMasks = (): PhoneNumberMask => ({
  fi: '.. ... .. ...',
  gb: '.... .......',
  ie: '.. ........',
  it: '... ........',
  nl: '.. ........',
  se: '(...) ...-....' || '(...) ...-...',
})

export const NumberOfNumerics = (inputString: string): number => {
  return (inputString.match(/\d/g) || []).length
}

export const NumberOfAlphabets = (inputString: string): number => {
  return (inputString.match(/[a-z]/gi) || []).length
}

export const NumberOfSpecialChars = (inputString: string): number => {
  // eslint-disable-next-line no-useless-escape
  return (inputString.match(/[@#$€!%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g) || []).length
}

export const checkPasswordStrength = (password: string, passwordPolicy: PasswordPolicyRule): string[] => {
  const errorArray = []

  if (NumberOfAlphabets(password) < passwordPolicy.minAlpha) {
    errorArray.push('minAlpha')
  }
  if (password.length < passwordPolicy.minLength) {
    errorArray.push('minLength')
  }

  if (NumberOfNumerics(password) < passwordPolicy.minNumeric) {
    errorArray.push('minNumeric')
  }
  if (NumberOfSpecialChars(password) < passwordPolicy.minSpecial) {
    errorArray.push('minSpecial')
  }
  return errorArray
}

export const validateEmail = (email: string): boolean => {
  // eslint-disable-next-line no-useless-escape
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

export const getEntityName = (el: Profile): string => {
  return el.entity.class?.corp
    ? el.entity.class?.corp.title
    : el.entity.class?.sme
      ? el.entity.class?.sme.title
      : el.entity.class?.person
        ? `${el?.entity?.class?.person?.name?.first ?? ''} ${el?.entity?.class?.person?.name?.last ?? ''}`
        : ''
}

export const compareEntityNames = (firstElement: Profile, secondElement: Profile): number => {
  const language: string = navigator.language
  const first = getEntityName(firstElement)
  const second = getEntityName(secondElement)
  return first.localeCompare(second, language, { numeric: true })
}

export const getTranslation = (key: string, translationsObject: TranslationsObject, intl: IntlShape): string => {
  const messageObject: MessageDescriptor = translationsObject[key as keyof typeof translationsObject]
  return intl.formatMessage(messageObject)
}

const flatTranslations = Object.values(translations).reduce((acc, keysAndTranslations) => {
  return Object.assign(acc, keysAndTranslations)
}, {})

export const useTranslation = () => {
  const intl = useIntl()

  return (
    key: string,
    values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>,
    opts?: IntlMessageFormatOptions
  ): string => {
    const descriptor = flatTranslations[key as keyof typeof flatTranslations]

    if (!descriptor) {
      Sentry.captureEvent({ message: 'Missing translation', extra: { key } })
      return `[${key}]`
    }

    return intl.formatMessage(descriptor, values, opts)
  }
}

export const calculateTotalFeeBase = (detailsTotal?: DetailsTotal): number => {
  if (!detailsTotal?.details || !Array.isArray(detailsTotal.details)) {
    return 0
  }

  return detailsTotal.details.reduce((total, d) => {
    return total + (d.sumBase?.feeBase || 0)
  }, 0)
}

export const removeSpaces = (value: string) => {
  return value.replace(/\s/g, '')
}
export function cleanValue(value: string | undefined): string {
  if (typeof value !== 'string') return ''
  return value.replace(/[\s-]/g, '')
}
