import React from 'react'
import { Tabs, TabsProps } from 'antd'
import EntityInfoTab from './EntityInfoTab'
import EntityUsersTab from './EntityUsersTab'
import SettingsTab from './SettingsTab'
import { useOrganisationHierarchy } from 'stores/OrganisationHierarchy/hooks'

const EntityDrawerTabs = (): React.JSX.Element => {
  const {
    state: { selectedEntity },
  } = useOrganisationHierarchy()

  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: 'Info',
      children: <EntityInfoTab selectedEntity={selectedEntity} />,
    },
    {
      key: '2',
      label: 'User',
      children: <EntityUsersTab />,
    },
    {
      key: '3',
      label: 'Settings',
      children: <SettingsTab entityId={selectedEntity?.id || ''} />,
    },
  ]

  return <Tabs defaultActiveKey="1" className="middle" size="middle" items={tabItems} />
}

export default EntityDrawerTabs
