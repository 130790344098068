import { createStyles } from 'antd-style'

const useStyles = createStyles(({ token, css }) => {
  return {
    dropdownFilterWrapper: css`
      display: flex;
      flex-direction: column;
    `,
    dropdownFilter: css`
      border: none;
      background: transparent;

      .ant-collapse-content {
        border-top: none;
        background-color: white;
        border-bottom-left-radius: ${token.borderRadius}px !important;
        border-bottom-right-radius: ${token.borderRadius}px !important;
      }
    `,
    filterPanel: css`
      background: white;
      border-radius: ${token.borderRadius}px !important;
      border: none !important;
      margin-bottom: 16px;
    `,
    radioWrapper: css`
      margin-bottom: 10px;
    `,
    filterRadio: css`
      display: flex;
      align-items: center;

      &.ant-radio-wrapper-checked {
        font-weight: 500;
      }

      .ant-radio-checked .ant-radio-inner {
        border-color: black;
      }

      .ant-radio-checked .ant-radio-inner:after {
        background-color: black;
      }

      .ant-radio:hover .ant-radio-inner {
        border-color: black;
      }

      span.ant-radio + * {
        padding-top: 4px;
      }
    `,
    buttonsWrapper: css`
      display: flex;
      flex-direction: column;
    `,
    clearBtn: css`
      margin-top: 10px;
      border: none;
      color: black !important;
      margin-left: auto;
    `,
    rangePickerMobile: css`
      max-width: 100%;
    `,
    rangeDropdownMobile: css`
      max-width: 100%;

      .ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
        visibility: visible !important;
      }

      .ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
        visibility: visible !important;
      }

      .ant-picker-panels > *:last-child {
        display: none;
      }

      .ant-picker-panel-container,
      .ant-picker-footer {
        width: 280px !important;
      }

      .ant-picker-footer-extra > div {
        flex-wrap: wrap !important;
      }
    `,
  }
})

export default useStyles
