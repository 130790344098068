import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css, responsive, token }) => ({
  headerContentWrapper: css`
    display: flex;
    width: 100%;
    justify-content: space-between;

    ${responsive.xs} {
      padding-left: ${token.paddingXL}px;
      flex-direction: row-reverse;
    }

    .ant-dropdown {
      min-width: 352px !important;
      max-width: 352px !important;
      margin-right: 16px;

      ${responsive.xs} {
        min-width: 252px !important;
        max-width: 252px !important;
      }
    }
  `,
  companyTitle: css`
    &:empty {
      display: none;
    }
  `,
  logo: css`
    display: block;
    height: 43px;
    width: 113px;
    border-radius: 0px;

    ${responsive.xs} {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      img {
        height: 30px;
      }
    }
  `,
  menuContainer: css`
    display: flex;
    cursor: pointer;

    ${responsive.xs} {
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      > * {
        line-height: 16px;
      }
      .ant-dropdown-link {
        display: inline-flex;
        align-items: center;
      }
    }
  `,
  userInfo: css`
    color: white;
    font-size: ${token.fontSizeSM}px;
  `,
  userInfoSme: css`
    margin-left: ${token.marginMD}px;

    ${responsive.xs} {
      margin-left: 0;
    }
  `,
  dropdownLink: css`
    color: white;
    font-size: ${token.fontSizeSM}px;

    &:hover {
      color: white;
    }
  `,
  dropdownContent: css`
    font-size: ${token.fontSizeSM}px;
    border-radius: ${token.borderRadius}px;
    background: white;
    box-shadow:
      0 3px 6px -4px rgb(0 0 0 / 12%),
      0 6px 16px 0 rgb(0 0 0 / 8%),
      0 9px 28px 8px rgb(0 0 0 / 5%);
    .ant-dropdown-menu {
      box-shadow: none;
      max-height: 200px;
      overflow-y: scroll;
      padding: 0;
      &:empty {
        padding: 0;
      }
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-track {
        background: #e8e8e8;
        border-radius: 12px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #7a7a7a;
        border-radius: 12px;
      }

      .ant-dropdown-menu-item {
        border-radius: 0;
      }
    }
  `,
  submenuItem: css`
    font-size: ${token.fontSizeSM}px !important;
    padding: ${token.paddingSM}px ${token.paddingMD}px !important;
  `,
  logoutItem: css`
    padding: 12px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    cursor: pointer;
    border-radius: 0 0 ${token.borderRadius}px ${token.borderRadius}px;

    &:hover {
      background: #f5f5f5;
    }
  `,
  active: css`
    background: #f4effb !important;
    font-weight: 600;
  `,
}))

export default useStyles
