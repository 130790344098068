import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css }) => {
  return {
    ssoTabs: css`
      .ant-tabs-tab-active {
        display: block;
      }

      .ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #9360d2 !important;
      }

      .ant-tabs-nav .ant-tabs-tab {
        border: none !important;
        width: 200px;
      }

      .ant-tabs-tab-btn {
        padding: auto;
        text-align: center;
        vertical-align: middle;
      }

      .ant-form-item-explain-error {
        max-width: 400px;
      }
      @media only screen and (max-width: 576px) {
        .ant-tabs-tab-btn {
          white-space: normal;
          padding: auto;

          text-align: center;
          vertical-align: middle;
          padding-left: 10px;
          padding-right: 10px;
        }

        .ant-tabs-nav .ant-tabs-tab {
          border: none !important;
          width: 150px;
          height: 60px !important;
        }
        .ant-form-item-explain-error {
          max-width: 300px;
        }
      }
      .ant-tabs-ink-bar {
        background: #9360d2 !important ;
      }

      .ant-tabs-tab {
        width: 50%;
      }
    `,
    ssoFormItem: css`
      margin: 0;
    `,
    loginSubmit: css`
      border-radius: 10px !important;
    `,
  }
})

export default useStyles
