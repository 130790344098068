/* eslint-disable @typescript-eslint/no-explicit-any */
import debounce from 'debounce-promise'
import { useMemo } from 'react'

export default function useDebouncePromise<T extends (...args: any[]) => any>(
  callback: T,
  wait: number
): (...args: Parameters<T>) => ReturnType<T> extends Promise<any> ? ReturnType<T> : Promise<ReturnType<T>> {
  const memoisedCallback = useMemo(() => {
    return callback
  }, [callback])

  return debounce(memoisedCallback, wait)
}
