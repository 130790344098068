import { api } from './utils'
import { Beneficiary, BlacklistEntry, DeleteBeneficiariesResponse } from '../types/beneficiary'
import { QueryParams } from '../types/general'
import { FilterBody } from '../components/Filter/Filter'
import * as Sentry from '@sentry/react'

const BASE_PATH = 'beneficiary'

export const getBeneficiaries = async (
  params?: QueryParams
): Promise<{
  beneficiaries: Array<Beneficiary>
  total?: number
}> => {
  const url = `/${BASE_PATH}`

  const response = await api.get(url, params)

  const beneficiaries = (await response.json()) as Array<Beneficiary>
  const total = response.headers.get('x-total-count')

  return {
    beneficiaries,
    total: Number.parseInt(total || '', 10),
  }
}

export const getBeneficiary = async (beneficiaryId: string): Promise<Beneficiary> => {
  const url = `/${BASE_PATH}/${beneficiaryId}`

  const response = await api.get(url)

  return await response.json()
}

export const createBeneficiary = async (data: Beneficiary): Promise<Beneficiary> => {
  const url = `/${BASE_PATH}`

  const response = await api.post(url, data)

  return response.json()
}

export const deleteBeneficiary = async (id: string): Promise<void> => {
  const url = `/${BASE_PATH}/${id}`

  await api.delete(url)
}

export const searchBeneficiaries = async (
  filter: FilterBody,
  params?: QueryParams
): Promise<{
  beneficiaries: Array<Beneficiary>
  total?: number
}> => {
  const url = `/${BASE_PATH}/search`
  const body: FilterBody = {
    kind: 'beneficiary',
    operator: 'or',
    ...filter,
  }

  const response = await api.post(url, body, params)

  const beneficiaries = (await response.json()) as Array<Beneficiary>
  const total = response.headers.get('x-total-count')

  return {
    beneficiaries,
    total: Number.parseInt(total || '', 10),
  }
}

export const getBeneficiariesEntity = async (
  entityId: string,
  params?: QueryParams
): Promise<Beneficiary[] | undefined> => {
  try {
    const url = `/${BASE_PATH}/entity/${entityId}`

    const response = await api.get(url, params)

    const entities = (await response.json()) as Array<Beneficiary>

    return entities
  } catch (error) {
    Sentry.captureException(error)
  }
}

export const searchBlacklist = async (
  filter: FilterBody
): Promise<{
  blacklist: Array<BlacklistEntry>
}> => {
  const url = `/${BASE_PATH}/search`
  const body: FilterBody = {
    kind: 'blacklist',
    ...filter,
  }

  const response = await api.post(url, body)

  const blacklist = (await response.json()) as Array<BlacklistEntry>

  return {
    blacklist,
  }
}

export const deleteMultipleBeneficiaries = async (body: Beneficiary['id'][]): Promise<DeleteBeneficiariesResponse> => {
  const url = `/${BASE_PATH}`

  const response = await api.delete(url, body)
  return response.json()
}
