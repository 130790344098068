import { MessageFormatElement } from 'react-intl'
import * as Sentry from '@sentry/react'
import { format } from 'date-fns'
import * as piApi from '../api/paymentInstruction'
import { searchExternalMessages, searchMessages } from '../api/timesSquare'
import { getStrings } from '../api/strings'
import { getActiveUser } from './helpers'
import { SetActionsTypes } from '../types/paymentInstruction'
import { AlertMessage } from '../types/messages'
import { User } from '../types/user'
import stringsGB from '../lang/en-GB.json'

export const COUNTRY_CODES = ['it-IT', 'fi-FI', 'en-IE', 'sv-SE', 'nl-NL', 'en-GB']

export const DEFAULT_LOCALE = 'en-GB'

export const getSets = async (profileId: string) => {
  const readyState = 'ready'
  const requireSignatureState = 'require_signature'
  const additionalIdentifiers = {
    key: 'action',
    value: SetActionsTypes.ADD,
  }

  try {
    const [readySet, requireSignatureSet] = await Promise.all([
      piApi.getSetByAdditionalIdentifiers(profileId, readyState, additionalIdentifiers),
      piApi.getSetByAdditionalIdentifiers(profileId, requireSignatureState, additionalIdentifiers),
    ])

    return {
      readySet,
      requireSignatureSet,
    }
  } catch (error) {
    Sentry.captureException(error)
  }

  return {
    readySet: undefined,
    requireSignatureSet: undefined,
  }
}

export type Message = Record<string, string> | Record<string, MessageFormatElement[]> | undefined

export const getLanguageBasedOnCookie = () => {
  const cookieRows = document.cookie.split('; ')
  const languageCookie = (cookieRows || []).find((row) => row.startsWith('bhLang'))
  const regionCookie = (cookieRows || []).find((row) => row.startsWith('bhRegion'))

  let language: string = ''

  if (languageCookie && regionCookie) {
    const lang: string = languageCookie.split('=')[1]
    const region: string = regionCookie.split('=')[1]
    language = lang + '-' + region
  } else {
    language = navigator.language
  }

  if (!COUNTRY_CODES.includes(language)) {
    language = DEFAULT_LOCALE
  }

  return language
}

export const loadLocaleData = async (
  locale: string,
  setMessage: (message: Message) => void
): Promise<void | string[]> => {
  switch (locale) {
    case 'it-IT':
    case 'fi-FI':
    case 'en-IE':
    case 'sv-SE':
    case 'nl-NL':
    case 'en-GB':
      return await getStrings(locale)
        .then((lang: Message) => {
          /* Set cookie to keep language */
          setMessage(lang)
        })
        .catch((error) => {
          setMessage(stringsGB)
          Sentry.captureException(error)
        })
    default:
      return await getStrings(`en-GB`)
        .then((lang: Message) => {
          /* Set cookie to keep language */
          setMessage(lang)
        })
        .catch((error) => {
          setMessage(stringsGB)
          Sentry.captureException(error)
        })
  }
}

export const getAlertMessages = async (countryCode: string, user?: User): Promise<AlertMessage[]> => {
  const today = format(new Date(), 'yyyy-MM-dd')
  const filter = {
    selection: {
      countries: [countryCode],
    },
    ringFence: {
      validity: {
        from: today,
        to: today,
      },
    },
  }

  try {
    if (!user) {
      const result = await searchExternalMessages(filter)

      return result.messages
    }

    const { entityId, profile } = getActiveUser(user)
    const country = profile.entity.countryCode
    const entityClass = Object.keys(profile.entity.class!)[0]
    let entitySubClass

    if (entityClass === 'corp') {
      entitySubClass = profile.entity.class?.corp?.subClass
    } else if (entityClass === 'sme') {
      entitySubClass = profile.entity.class?.sme?.subClass
    } else if (entityClass === 'person') {
      entitySubClass = profile.entity.class?.person?.subClass
    }
    const domiciles = profile.entity.domicile ? [profile.entity.domicile] : null
    const selection: {
      entityIds: string[]
      countries: string[]
      entityClasses: string[]
      entitySubClasses?: string[]
      domiciles?: string[]
    } = {
      entityIds: [entityId],
      countries: country ? [country] : [],
      entityClasses: [entityClass],
    }

    if (entitySubClass) {
      selection.entitySubClasses = [entitySubClass]
    }
    if (domiciles) {
      selection.domiciles = domiciles
    }

    const notIn = {
      closedBy: [user?.user.id],
    }

    const result = await searchMessages({ ...filter, selection, notIn })

    return result.messages
  } catch (error) {
    Sentry.captureException(error)
  }

  return []
}

/**
 * Based on the entity class and subclass, returns true if the profile is B2B
 * @param user
 * @returns boolean
 */
export const getIsB2BUser = (user: User): boolean => {
  const activeProfile = user.profiles.find((eachProfile) => eachProfile.id === user.activeProfileId)
  let isB2B = false
  if (activeProfile) {
    const entityClass = Object.keys(activeProfile.entity.class!)[0]

    if (entityClass === 'corp') {
      isB2B = true
    } else if (entityClass === 'sme') {
      const entitySubClass = activeProfile.entity.class?.sme?.subClass
      if (entitySubClass === 'corp') {
        isB2B = true
      }
    }
  }

  return isB2B
}
