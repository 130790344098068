import getActions from '../actions'
import { useTracked } from '../container'

export const useSession = () => {
  const [state, dispatch] = useTracked()

  return {
    state,
    actions: getActions(dispatch),
  }
}
