import React from 'react'
import { Form, Radio, Space } from 'antd'
import { PaymentTypeRules } from '../../../types/beneficiary'
import { useTranslation } from '../../../utils/helpers'
import { RecipientsForm } from '../RecipientsDrawer'

interface BeneficiaryDetailsProps {
  availablePaymentTypes: PaymentTypeRules
}

const PaymentMethodSelect = ({
  availablePaymentTypes: availablePaymentTypes,
}: BeneficiaryDetailsProps): React.JSX.Element => {
  const form = Form.useFormInstance<RecipientsForm>()
  const currentCurrency = Form.useWatch('currency', form)
  const t = useTranslation()

  const paymentTypes = availablePaymentTypes[currentCurrency]?.paymentTypes || []

  return (
    <>
      <Form.Item label={t('beneficiary.paymentMethod.label')} name="paymentType">
        <Radio.Group style={{ display: 'grid' }}>
          <Space direction="vertical" size="small">
            {availablePaymentTypes &&
              currentCurrency &&
              paymentTypes.map((paymentType) => (
                <Radio value={paymentType} key={paymentType}>
                  {t(`beneficiary.paymentmethodLabel.${paymentType}`)}
                </Radio>
              ))}
          </Space>
        </Radio.Group>
      </Form.Item>
    </>
  )
}

export default PaymentMethodSelect
