import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css, responsive, token }) => ({
  contactText: css`
    display: flex;
    justify-content: center;
    margin: 40px 0px;

    p {
      width: 60vw;

      ${responsive.md} {
        width: auto;
      }
    }
  `,
  supportBtns: css`
    .ant-space-item {
      margin-bottom: 20px;
    }

    ${responsive.md} {
      width: 60%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .ant-space-item {
        width: 100%;
      }
    }
  `,
  downloadBtn: css`
    color: ${token.colorPrimary};
    font-weight: bold;
    &:disabled {
      color: ${token.colorPrimary};
    }
  `,
}))

export default useStyles
