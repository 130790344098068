import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css, token }) => {
  return {
    container: css`
      display: flex;
      flex-direction: column;
      align-items: left;
      min-height: 500px;
      height: fit-content;
      width: 100%;
    `,
    cardContainer: css`
      width: 100%;
      display: flex;
      flex-direction: row;
      height: 100%;
      justify-content: space-between;
      background-color: transparent;
    `,
    affix: css`
      position: sticky;
      top: 70px;

      //IE11 media query
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        position: fixed;
        width: 40%;
      }
    `,
    header: css`
      display: flex;
      align-items: center;
      margin-bottom: ${token.marginMD}px;
      column-gap: ${token.marginSM}px;
    `,
    deliveryTitle: css`
      margin-left: ${token.marginMD}px;
    `,
    content: css`
      width: 100%;
    `,
    icon: css`
      width: 16px;
      height: 16px;
    `,
    reportsCardWrapper: css`
      background: white;
      padding: 16px;
      border-radius: 10px;
      margin-bottom: 30px;
    `,
  }
})

export default useStyles
