import React, { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { useIntl } from 'react-intl'
import { action, entity, pi } from '../../../lang/definitions/index'
import * as api from '../../../api/user'
import { Tabs } from 'antd'
import type { TabsProps } from 'antd'
import ActionMenu, { Action } from '../../../components/ActionMenu/ActionMenu'
import ActionPage from '../../../components/ActionPage/ActionPage'
import Page, { MobileMenuOption } from '../../../components/Page/Page'
import UserForm from './User/UserForm'
import { UserData } from '../../../types/user'
import EntityDrawerTabs from './Entity/EntityDrawerTabs'
import { organization } from '../../../lang/definitions/organization'
import { useHistory } from 'react-router-dom'
import { replaceDrawerHash, setDrawerHash } from '../../../components/Drawers/utils'
import EntityInfoTab from './Entity/EntityInfoTab'
import SettingsTab from './Entity/SettingsTab'
import EntityCards from './Cards/EntityCards'
import CardForm from '../../Cards/CardForm'
import acl from '../../../utils/acl'
import { getActiveUser } from '../../../utils/helpers'
import { useOrganisationHierarchy, useOrganisationHierarchyUtils } from 'stores/OrganisationHierarchy/hooks'
import EntityHierarchiesTab from './Entity/EntityHierarchiesTab'
import { TABS, findEntityFromHierarchy } from './utils'
import Users from './User/Users'
import { useSession } from 'stores/session'
import useUsersStyle from './User/Users.style'
import { cx } from 'antd-style'

const OrganizationHierarchy = (): React.JSX.Element => {
  const history = useHistory()
  const intl = useIntl()
  const { styles } = useUsersStyle()

  const {
    state: { entityHierarchies, cardManagedByAdminRule, cardNetworks, paymentGateways, selectedEntity, selectedUser },
    actions: { setSelectedEntity, setSelectedUser },
  } = useOrganisationHierarchy()
  const { getEntityHierarchies, getCountryOptions, getEntities, getSources } = useOrganisationHierarchyUtils()

  const { state: sessionState } = useSession()
  const user = sessionState.user!
  const iam = sessionState.iam!

  const userId = user.user.id
  const activeProfile = getActiveUser(user).profile

  const [isActionMenuOpen, setIsActionMenuOpen] = useState(false)
  const [activeTab, setActiveTab] = useState<string>(TABS.USER)

  const isCorp = () => !!activeProfile?.entity.class?.corp

  const canCurrentEntityAddCard = acl({
    iam,
    me: user,
    kind: 'source',
    action: 'create',
    barracksId: activeProfile?.entityId,
  })

  const canCurrentEntityReadCard = acl({
    iam,
    me: user,
    kind: 'source',
    action: 'read',
    barracksId: activeProfile?.entityId,
  })

  const mobileMenuOptions: Array<MobileMenuOption> = [
    {
      label: 'Filter',
      callback: () => null,
    },
    {
      label: 'Actions',
      callback: () => setIsActionMenuOpen(true),
    },
  ]

  const actions: Array<Action> = [
    {
      id: 'add-user-btn',
      label: intl.formatMessage(organization['organization.action.newuser']),
      type: 'button',
      callback: () => {
        setSelectedEntity(undefined)
        setSelectedUser(undefined)
        setDrawerHash(history, '#drawer-new-user')
      },
    },
  ]

  useEffect(() => {
    void getEntityHierarchies(userId)
    void getEntities()
    void getCountryOptions()
  }, [userId])

  const renderActions = (): Action[] => {
    const actionMenu = [...actions]

    const addCardAction: Action = {
      id: 'add-card-btn',
      label: intl.formatMessage(organization['organization.action.newcard']),
      type: 'button',
      callback: () => {
        setDrawerHash(history, '#drawer-new-card')
      },
    }

    if (cardManagedByAdminRule && canCurrentEntityAddCard) {
      actionMenu.push({ ...addCardAction })
    }

    return actionMenu
  }

  const actionMenu = (
    <ActionMenu
      isOpen={isActionMenuOpen}
      actions={renderActions()}
      label={intl.formatMessage(organization['organization.page.title'])}
      closeMenu={() => setIsActionMenuOpen(false)}
    />
  )

  useEffect(() => {
    void showDrawersFromHash()
  }, [window.location.hash, entityHierarchies])

  const showDrawersFromHash = async (): Promise<void> => {
    const windowHash = window.location.hash

    if (windowHash && entityHierarchies) {
      const isEdit = windowHash.includes('edit-entity')
      const isEntityUser = windowHash.includes('entityUser')
      const hashArray = windowHash.split('?key=')
      const hashId = hashArray[1]

      if (isEntityUser) {
        try {
          const user = await api.getUser(hashId)
          setSelectedUser(user.user as unknown as UserData)
        } catch (error) {
          Sentry.captureException(error)
        }
      }

      isEdit && openEdit(hashId)
    }
  }

  const openEdit = (id: string): void => {
    const entity = findEntityFromHierarchy(id, entityHierarchies)
    setSelectedEntity(entity)
    setDrawerHash(history, `#drawer-edit-entity?key=${id}`)
  }

  const handleCardRefresh = (): void => {
    void getSources()
    replaceDrawerHash(history, '/app/admin/organization-hierarchy')
    setActiveTab(TABS.CARD)
  }

  const generateTabs = (): TabsProps['items'] => {
    const items: TabsProps['items'] = [
      {
        key: TABS.USER,
        label: intl.formatMessage(entity['organizationSettings.tabs.user']),
        children: <Users />,
      },
      {
        key: TABS.INFO,
        label: intl.formatMessage(entity['organizationSettings.tabs.info']),
        children: <EntityInfoTab selectedEntity={activeProfile.entity} />,
      },
      {
        key: TABS.SETTINGS,
        label: intl.formatMessage(entity['organizationSettings.tabs.settings']),
        children: <SettingsTab entityId={activeProfile?.entity.id || ''} />,
      },
    ]

    if (isCorp()) {
      items.splice(1, 0, {
        key: TABS.ENTITY,
        label: intl.formatMessage(entity['organizationSettings.entity.tab.title']),
        children: <EntityHierarchiesTab />,
      })
    }

    if (canCurrentEntityReadCard) {
      items.push({
        key: TABS.CARD,
        label: intl.formatMessage(entity['organizationSettings.tabs.cards']),
        children: <EntityCards />,
      })
    }

    return items
  }

  return (
    <Page
      title={intl.formatMessage(organization['organization.page.title'])}
      mobileMenuOptions={mobileMenuOptions}
      actionMenu={actionMenu}
    >
      <>
        <Tabs
          data-testid="tabs-container"
          className="settings-tabs"
          activeKey={activeTab}
          onChange={(activeKey: string) => setActiveTab(activeKey)}
          items={generateTabs()}
        />
        <ActionPage
          title={intl.formatMessage(action['action.admin.user.add'])}
          hash="#drawer-new-user"
          pathname={history.location.pathname}
          additionalClass={cx(styles.addUserDrawer, 'drawer-with-bg')}
        >
          <UserForm />
        </ActionPage>
        <ActionPage
          title={selectedEntity?.class?.corp?.title ?? ''}
          hash={`#drawer-edit-entity?key=${selectedEntity?.id ?? ''}`}
          pathname={history.location.pathname}
          additionalClass={cx(styles.entityDrawerTabs, 'drawer-with-bg')}
        >
          <EntityDrawerTabs />
        </ActionPage>
        <ActionPage
          title={intl.formatMessage(action['action.admin.user.edit'])}
          hash={`#drawer-entityUser?key=${selectedUser?.id || ''}`}
          additionalClass={cx(styles.editUserDrawer, 'drawer-with-bg')}
          pathname={history.location.pathname}
        >
          <UserForm />
        </ActionPage>
        <ActionPage
          title={intl.formatMessage(action['action.admin.user.add'])}
          hash={`#drawer-add-user-from-entity?key=${selectedEntity?.id || ''}`}
          additionalClass={cx(styles.addUserDrawer, 'drawer-with-bg')}
          pathname={history.location.pathname}
        >
          <UserForm />
        </ActionPage>
        <ActionPage
          title={intl.formatMessage(pi['pi.add.addPayments.action.addCard'])}
          hash="#drawer-new-card"
          pathname={history.location.pathname}
        >
          <CardForm
            cardNetworks={cardNetworks}
            gateways={paymentGateways}
            profileId={user.activeProfileId as string}
            refresh={handleCardRefresh}
          />
        </ActionPage>
      </>
    </Page>
  )
}

export default OrganizationHierarchy
