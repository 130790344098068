import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css, responsive, token }) => ({
  home: css`
    min-height: 100vh;
    display: flex;
    overflow: hidden;
  `,
  contentContainer: css`
    height: calc(100vh - 65px);
    overflow: unset;
  `,
  loaderContainer: css`
    display: flex;
    justify-content: center;
  `,
  siteLayout: css`
    margin-top: 64px;

    ${responsive.xs} {
      margin-left: 0px;
      margin-top: 0;
    }
  `,
  offlineWarning: css`
    border-radius: 8px;
    border: 1px solid #fdefd5;
    background: #fefaf1;
    margin: 25px 60px 0 60px;

    ${responsive.xs} {
      margin: 25px 20px 0 20px;
    }
  `,
  siteSidebar: css`
    top: 50px;
  `,
  siteHeader: css`
    position: fixed;
    top: 0;
    z-index: 2;
    width: 100%;
    padding: 0 ${token.paddingLG}px;

    ${responsive.xs} {
      position: sticky;
    }
  `,
  headerContent: css`
    ${responsive.xs} {
      display: flex;
      background-color: ${token.colorPrimary};
      height: 100%;
      align-items: center;
    }
  `,
}))

export default useStyles
