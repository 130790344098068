import React from 'react'
import { Form, Input } from 'antd'
import { useIntl } from 'react-intl'
import { card } from 'lang/definitions'
import { useExpiryDateValidator } from '../validators'
import { formatExpiryDate } from '../utils'

const ExpiryDateFormItem = () => {
  const intl = useIntl()

  const validator = useExpiryDateValidator()

  return (
    <Form.Item
      name="expiryDate"
      label={intl.formatMessage(card['card.form.expiryDate.label'])}
      normalize={(value: string) => formatExpiryDate(value)}
      hasFeedback
      rules={[
        {
          validator,
        },
      ]}
    >
      <Input size="large" placeholder="MM/YY" maxLength={7} />
    </Form.Item>
  )
}

export default ExpiryDateFormItem
