import { Beneficiary, Organization } from '../types/beneficiary'
import { api } from './utils'

const BASE_PATH = 'lookup'

export const searchGiro = async (
  accountNumber: string,
  clearingNetwork: string
): Promise<Partial<Beneficiary> & { countryCode?: string }> => {
  const url = `/${BASE_PATH}/giro`

  const body = {
    accountNumber,
    clearingNetwork,
  }

  const response = await api.post(url, body)

  return await response.json()
}

export const searchOrganization = async (companyTitle: string, countryCode: string): Promise<Organization[]> => {
  const url = `/${BASE_PATH}/organization`

  const body = {
    q: companyTitle,
    countryCode,
  }

  const response = await api.post(url, body)

  return await response.json()
}
