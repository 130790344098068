import { History } from 'history'

export const setDetailsHash = (history: History, hash: string): void => {
  if (window.location.hash === hash) return
  history.push(hash)
}

export const resetDetailsHash = (history: History): void => {
  const pathname = window.location.pathname
  history.replace(pathname)
}
