import React from 'react'
import { IntlShape } from 'react-intl'
import { admin } from '../../../lang/definitions/admin'
import { Entity } from '../../../types/entity'
import { Button } from 'antd'
import { RightOutlined, DeleteOutlined } from '@ant-design/icons'

export const actionColumn = (intl: IntlShape, openDelete: (id: string) => void) =>
  [
    {
      title: intl.formatMessage(admin['admin.user.columns.actions']),
      dataIndex: 'id',
      key: 'type',
      render: (id: string): React.JSX.Element => {
        return (
          <React.Fragment>
            <Button type="link" style={{ padding: 0 }}>
              <RightOutlined
                style={{
                  color: '#BDBDBD',
                  fontSize: '1rem',
                }}
              />
            </Button>
            <Button
              data-testid="delete-entity-hierarchy"
              type="link"
              style={{ padding: 0 }}
              onClick={(event) => {
                event.stopPropagation()
                openDelete(id)
              }}
            >
              <DeleteOutlined
                style={{
                  color: 'red',
                  fontSize: '1rem',
                  marginLeft: '0.5rem',
                }}
              />
            </Button>
          </React.Fragment>
        )
      },
    },
  ] as const

export const entityHierarchyColumns = (intl: IntlShape) =>
  [
    {
      title: intl.formatMessage(admin['admin.user.hierarchyColumns.entities']),
      dataIndex: '',
      key: 'class.corp.title',
      render: (data: {
        class: {
          sme: { title: string }
          person: { name: { first: string; last: string } }
          corp: { title: string }
        }
      }) => {
        const title = data.class.corp
          ? data.class.corp.title
          : data.class.sme
            ? data.class.sme.title
            : data.class.person && data.class.person.name
              ? `${data.class.person.name.first} ${data.class.person.name.last}`
              : null
        return <span>{title}</span>
      },
    },
    {
      title: intl.formatMessage(admin['admin.user.columns.numberOfPayments']),
      dataIndex: '',
      align: 'center',
      key: 'numberOfPayments',
      render: (data: Entity) => {
        return <span>{data.numberOfPayments || 0}</span>
      },
    },
    {
      title: intl.formatMessage(admin['admin.user.columns.numberOfUsers']),
      dataIndex: '',
      align: 'center',
      key: 'numberOfUsers',
      render: (data: Entity) => {
        return <span>{data.numberOfUsers || 0}</span>
      },
    },
  ] as const

export const profileColumns = (intl: IntlShape) =>
  [
    {
      title: intl.formatMessage(admin['admin.user.columns.user']),
      dataIndex: '',
      key: 'name',
      render: (data: { user: { name: { first: string; last: string } } }) => {
        return (
          <span>
            {data.user.name.first} {data.user.name.last}
          </span>
        )
      },
    },
  ] as const

export const getEntityTitle = (entity: Entity): string => {
  const corpTitle = entity.class?.corp?.title
  const smeTitle = entity.class?.sme?.title
  const personName = entity.class?.person?.name?.first + ' ' + entity.class?.person?.name?.last
  return corpTitle || smeTitle || personName
}

/**
 * This function is used to find entity from hierarchy. It will return the entity if it finds it. If not, it will recursively call itself to find the entity
 * @param id - entity to look for
 * @param entities - array of entity hierarchy
 * @returns Entity | undefined
 */
export const findEntityFromHierarchy = (id: string, entities: Entity[]): Entity | undefined => {
  const entity = entities.find((entity: Entity) => entity.id === id)
  if (entity) return entity

  for (const entity of entities) {
    if (entity.children) {
      const foundEntity = findEntityFromHierarchy(id, entity.children)
      if (foundEntity) return foundEntity
    }
  }
}

export enum EntityRole {
  OWNER = 'owner',
  ADMIN = 'admin',
  PAYER = 'payer',
  VIEWER = 'viewer',
  APPROVER = 'approver',
  ADDER = 'adder',
  MEMBER = 'member',
}

export enum TABS {
  USER = 'user',
  ENTITY = 'entity',
  CARD = 'card',
  INFO = 'info',
  SETTINGS = 'settings',
}
