import React from 'react'
import { Descriptions } from 'antd'
import { useIntl } from 'react-intl'
import { SignatureEventDetails } from '../../types/signature'
import { signatures } from '../../lang/definitions/signatures'

interface SignatureDetailsProps {
  signature: SignatureEventDetails
}

const SignatureDetails = ({ signature }: SignatureDetailsProps): React.JSX.Element => {
  const intl = useIntl()

  const paymentsCount: number = signature.count.paymentInstructions + signature.count.paymentInstructionTemplates

  const renderPayments = (signature: SignatureEventDetails): React.JSX.Element => {
    // eslint-disable-next-line react/prop-types
    const paymentInstructions = signature.amount.paymentInstructions.map((pi, index) => (
      <p key={index}>
        {pi.amount} {pi.currency}
      </p>
    ))

    // eslint-disable-next-line react/prop-types
    const paymentInstructionTemplates = signature.amount.paymentInstructionTemplates.map((pi, index) => (
      <p key={index}>
        {pi.amount} {pi.currency}
      </p>
    ))

    return (
      <div>
        {paymentInstructions && (
          <Descriptions.Item label="Payment instructions" span={3}>
            {paymentInstructions}
          </Descriptions.Item>
        )}
        {paymentInstructionTemplates && (
          <Descriptions.Item label="Payment instruction templates" span={3}>
            {paymentInstructionTemplates}
          </Descriptions.Item>
        )}
      </div>
    )
  }

  return (
    <Descriptions bordered layout="vertical" size="small" className="bh-description-vertical">
      {signature.profileInfo.name && (
        <Descriptions.Item label={intl.formatMessage(signatures['signatures.form.createdBy'])} span={3}>
          {signature.profileInfo.name.first} {signature.profileInfo.name.last}
        </Descriptions.Item>
      )}
      {signature.profileInfo.title && (
        <Descriptions.Item label={intl.formatMessage(signatures['signatures.form.entity'])} span={3}>
          {signature.profileInfo.title}
        </Descriptions.Item>
      )}
      {signature.profileInfo.entityClass && (
        <Descriptions.Item label={intl.formatMessage(signatures['signatures.form.type'])} span={3}>
          {signature.profileInfo.entityClass}
        </Descriptions.Item>
      )}
      {signature.amount && (
        <Descriptions.Item label={intl.formatMessage(signatures['signatures.form.payments'])} span={3}>
          {renderPayments(signature)}
        </Descriptions.Item>
      )}
      {signature.count && (
        <Descriptions.Item label={intl.formatMessage(signatures['signatures.form.count'])} span={3}>
          {paymentsCount}
        </Descriptions.Item>
      )}
    </Descriptions>
  )
}

export default SignatureDetails
