import React from 'react'
import Home from './pages/Home/Home'
import LoginPanel from './pages/Login/LoginPanel'
import SSOIntermediateLoginPage from './pages/Login/SSOIntermediateLoginPage'
import SSOIntermediateLinkPage from './pages/Login/SSOIntermediateLinkPage'
import LegacyIntermediatePage from './pages/Login/LegacyIntermediatePage'
import PaymentInstructions from './pages/PaymentInstructions/PaymentInstructions'
import Add from './pages/PaymentInstructions/Add/Add'
import Pay from './pages/PaymentInstructions/Pay/Pay'
import Review from './pages/PaymentInstructions/Review/Review'
import Beneficiaries from './pages/Beneficiaries/Beneficiaries'
import Settings from './pages/Settings/Settings'
import Admin from './pages/Admin/Admin'
import Activity from './pages/Admin/Activity/Activity'
import PaymentOverview from './pages/Admin/PaymentOverview/PaymentOverview'
import OrganizationHierarchy from './pages/Admin/OrganizationHierarchy/OrganizationHierarchy'
import Billing from './pages/Admin/Billing/Billing'
import AdminSettings from './pages/Admin/Settings/Settings'
import Notifications from './pages/External/Notifications/Notifications'
import Cards from './pages/Cards/Cards'
import ResetPassword from './pages/ResetPassword/ResetPassword'
import Support from './pages/Support/Support'
import RequestResetPassword from './pages/RequestResetPassword.tsx/RequestResetPassword'
import ChooseProfile from './components/ChooseProfile/ChooseProfile'
import PasswordUpdatePage from './pages/Login/PasswordUpdatePage'
import MotorolaIntermediateLoginPage from './pages/Login/MotorolaIntermediateLoginPage'
import ReportsTabs from './pages/Reports/ReportsTabs'
import FileDownloadPage from './pages/FileDownloadPage/FileDownloadPage'
import { CurrentPaymentStateProvider } from 'stores/Payment'
import { OrganisationHierarchyProvider } from 'stores/OrganisationHierarchy'

export default [
  {
    path: '/login/legacy-token/:token',
    name: 'Login',
    exact: true,
    component: LegacyIntermediatePage,
  },
  {
    path: '/login/provider/:provider',
    name: 'SSO Provider',
    exact: true,
    component: SSOIntermediateLinkPage,
    isMobileDisabled: false,
  },
  {
    path: '/login/sso/:bhSsoToken',
    name: 'SSO',
    exact: true,
    component: SSOIntermediateLoginPage,
    isMobileDisabled: false,
  },
  {
    path: '/login/motorola/sso',
    name: 'MotorolaSSO',
    exact: true,
    component: MotorolaIntermediateLoginPage,
    isMobileDisabled: false,
  },
  {
    path: '/login',
    name: 'Login',
    exact: true,
    component: LoginPanel,
    isMobileDisabled: false,
  },
  {
    path: '/action/download',
    name: 'FileDownloadPage',
    exact: true,
    private: true,
    component: FileDownloadPage,
    isMobileDisabled: false,
    routes: [],
  },
  {
    path: '/choose-profile',
    name: 'ChooseProfile',
    exact: true,
    private: true,
    component: ChooseProfile,
    isMobileDisabled: false,
  },
  {
    path: '/errare-humanum-est/:countryCode',
    name: 'RequestResetPassword',
    exact: true,
    component: RequestResetPassword,
    isMobileDisabled: false,
  },
  {
    path: '/errare-humanum-est/reset/:token',
    name: 'ResetPassword',
    exact: true,
    component: ResetPassword,
    isMobileDisabled: false,
  },
  {
    path: '/external/preferences/:preferenceToken/notifications',
    name: 'Notifications',
    component: Notifications,
    exact: true,
    isMobileDisabled: false,
  },
  {
    path: '/update-password/user/:userId',
    name: 'PasswordUpdate',
    exact: true,
    component: PasswordUpdatePage,
    isMobileDisabled: false,
  },
  {
    path: ['/', '/app/*'],
    name: 'Home',
    exact: true,
    private: true,
    component: Home,
    isMobileDisabled: false,
    routes: [
      {
        path: '/',
        name: 'Payment Instructions',
        exact: true,
        component: PaymentInstructions,
        private: true,
        routes: [],
        isMobileDisabled: false,
      },
      {
        path: '/app/payments/add/:tab',
        name: 'Add',
        exact: true,
        component: () => (
          <CurrentPaymentStateProvider>
            <Add />
          </CurrentPaymentStateProvider>
        ),
        private: true,
        routes: [],
        isMobileDisabled: false,
      },
      {
        path: '/app/payments/pay',
        name: 'Pay',
        exact: true,
        component: () => (
          <CurrentPaymentStateProvider>
            <Pay />
          </CurrentPaymentStateProvider>
        ),
        private: true,
        routes: [],
        isMobileDisabled: false,
      },
      {
        path: '/app/payments/review',
        name: 'Review',
        exact: true,
        component: () => (
          <CurrentPaymentStateProvider>
            <Review />
          </CurrentPaymentStateProvider>
        ),
        private: true,
        routes: [],
        isMobileDisabled: false,
      },
      {
        path: '/app/beneficiaries',
        name: 'Beneficiaries',
        exact: true,
        component: Beneficiaries,
        private: true,
        routes: [],
        isMobileDisabled: false,
      },
      {
        path: '/app/cards',
        name: 'Cards',
        exact: true,
        component: Cards,
        private: true,
        routes: [],
        isMobileDisabled: false,
      },
      {
        path: '/app/reports',
        name: 'Reports',
        exact: true,
        component: ReportsTabs,
        private: true,
        routes: [],
        isMobileDisabled: true,
      },
      {
        path: '/app/settings',
        name: 'Settings',
        exact: true,
        component: Settings,
        private: true,
        routes: [],
        isMobileDisabled: false,
      },
      {
        path: '/app/support-faq',
        name: 'Support',
        exact: true,
        component: Support,
        private: true,
        routes: [],
        isMobileDisabled: false,
      },
      {
        path: '/app/admin',
        name: 'Admin',
        component: Admin,
        private: true,
        isMobileDisabled: true,
        routes: [
          {
            path: '/app/admin/activity',
            name: 'Activity',
            exact: true,
            component: Activity,
            private: true,
            isMobileDisabled: true,
            routes: [],
          },
          {
            path: '/app/admin/reports',
            name: 'Reports',
            exact: true,
            component: ReportsTabs,
            private: true,
            isMobileDisabled: true,
            routes: [],
          },
          {
            path: '/app/admin/payment-overview',
            name: 'Payment overview',
            exact: true,
            component: PaymentOverview,
            private: true,
            isMobileDisabled: true,
            routes: [],
          },
          {
            path: '/app/admin/organization-hierarchy',
            name: 'Organization Hierarchy',
            exact: true,
            component: () => (
              <OrganisationHierarchyProvider>
                <OrganizationHierarchy />
              </OrganisationHierarchyProvider>
            ),
            private: true,
            isMobileDisabled: true,
            routes: [],
          },
          {
            path: '/app/admin/billing',
            name: 'Billing',
            exact: true,
            component: Billing,
            private: true,
            isMobileDisabled: true,
            routes: [],
          },
          {
            path: '/app/admin/settings',
            name: 'Settings',
            exact: true,
            component: AdminSettings,
            private: true,
            isMobileDisabled: true,
            routes: [],
          },
        ],
      },
    ],
  },
]
