import React from 'react'
import { Form } from 'antd'
import { CloseCircleFilled } from '@ant-design/icons'
import { FormInstance } from 'antd/lib/form'

type UseSuffixProps = {
  form?: FormInstance
  fieldName: string | string[]
  maxLength?: number
  pattern?: RegExp
}

const useSuffix = ({ form, fieldName, maxLength, pattern }: UseSuffixProps) => {
  const contextForm = Form.useFormInstance()
  const actualForm = form || contextForm

  const value = Form.useWatch(fieldName, actualForm) as string

  if (!value || value.length === 0) return null

  const count = (str: string) => {
    if (pattern) {
      return (str.match(pattern) || []).length
    } else {
      return str.length
    }
  }

  const cleanedLength = count(value)

  const clearFormItem = () => {
    actualForm.resetFields([fieldName]) // Clear only the specific field
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ color: 'gray', marginRight: '8px', marginLeft: '8px' }}>
        {`${cleanedLength}/${maxLength || ''}`}
      </span>
      <CloseCircleFilled
        data-testid="clear-icon"
        style={{
          color: 'gray',
          cursor: 'pointer',
          fontSize: '12px',
        }}
        onClick={clearFormItem}
      />
    </div>
  )
}

export default useSuffix
