import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css }) => ({
  alertNoCardWrapper: css`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
  alertNoCard: css`
    width: 100%;
    border-radius: 0% !important;
    background-color: #fff1f0 !important;
    border: 1px solid #ffccc7 !important;
    margin-bottom: 10px !important;
  `,
  alertNoCardNewCardBtn: css`
    margin-left: 0 !important;
  `,
  selectedOptionContainer: css`
    background-color: #e7edf6;
    border-radius: 10px;
    padding: 15px;

    .ant-form-item {
      margin-bottom: 0;
    }
  `,
  itemWithAction: css`
    display: flex;
    gap: 10px;

    .ant-form-item {
      width: 100%;
    }
  `,
  oddOption: css`
    background-color: #f5f5f5;
  `,
  paymentFormSelectOption: css``,
}))

export default useStyles
