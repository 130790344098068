import { FilterBody } from '../components/Filter/Filter'
import { QueryParams } from '../types/general'
import { api } from './utils'
import { Event, EventLabel } from '../types/activity'
import { Entity } from '../types/entity'

const BASE_PATH = 'bindefeld'

export const getAllEvents = async (
  params?: QueryParams
): Promise<{
  events: Array<Event>
  total?: number
}> => {
  const url = `/${BASE_PATH}/event/curated`

  const response = await api.get(url, params)

  const events = (await response.json()) as Array<Event>
  const total = response.headers.get('x-total-count')

  return {
    events,
    total: Number.parseInt(total || '', 10),
  }
}

export const searchEvents = async (
  filter: FilterBody,
  params?: QueryParams
): Promise<{
  events: Array<Event>
  total?: number
}> => {
  const url = `/${BASE_PATH}/search`
  const body: FilterBody = {
    operator: 'and',
    ...filter,
  }

  const response = await api.post(url, body, params)

  const events = (await response.json()) as Array<Event>
  const total = response.headers.get('x-total-count')

  return {
    events,
    total: Number.parseInt(total || '', 10),
  }
}

export const getAllEntities = async (
  params?: QueryParams
): Promise<{
  entities: Array<Entity>
  total?: number
}> => {
  const url = `/barracks/entity`

  const response = await api.get(url, params)

  const entities = (await response.json()) as Array<Entity>
  const total = response.headers.get('x-total-count')

  return {
    entities,
    total: Number.parseInt(total || '', 10),
  }
}

export const getEventLabels = async (): Promise<{
  labels: Array<EventLabel>
  total?: number
}> => {
  const url = `/${BASE_PATH}/label/curated`

  const response = await api.get(url)

  const labels = (await response.json()) as Array<EventLabel>

  return {
    labels,
  }
}
