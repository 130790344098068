import amexIcon from 'assets/cards/minicards/amex.svg'
import dinersIcon from 'assets/cards/minicards/diners.svg'
import mastercardIcon from 'assets/cards/minicards/mastercard.svg'
import visaIcon from 'assets/cards/minicards/visa.svg'
import unionPayIcon from 'assets/cards/minicards/unionpay.svg'
import airplusIcon from 'assets/cards/minicards/airplus.svg'

export const getCardIcon = (cardNetwork: string): string => {
  switch (cardNetwork) {
    case 'amex':
      return amexIcon
    case 'diners':
      return dinersIcon
    case 'mc':
      return mastercardIcon
    case 'visa':
      return visaIcon
    case 'up':
      return unionPayIcon
    case 'airplus':
      return airplusIcon
    default:
      return ''
  }
}
