import React, { CSSProperties, PropsWithChildren, useState } from 'react'
import { createPortal } from 'react-dom'

type Props = PropsWithChildren<{
  style?: CSSProperties
  title?: string
}>

export default function IFrame({ children, style, title }: Props) {
  const [ref, setRef] = useState<HTMLIFrameElement | null>(null)
  let container

  try {
    container = ref?.contentWindow?.document?.body
  } catch (err) {
    // CORS issue
    // Should only happen under development due to hot reloading
  }

  return (
    <iframe ref={setRef} style={style} title={title}>
      {container && createPortal(children, container)}
    </iframe>
  )
}
