import { sortBy } from 'lodash'
import { CardNetworkPaymentDays } from 'types/paymentInstruction'
import { Rules } from 'types/rules'

export default function getCardNetworksPaymentDays(
  rules: Rules,
  cardNetwork: string,
  clearingNetwork?: string,
  currency?: string,
  amount?: number
): CardNetworkPaymentDays {
  const { rule } = rules.logic.paymentDays

  const rulesToApply = rule.filter(
    (rule: {
      cardNetwork: string
      clearingNetwork: string
      currency: string
      amountFrom: number
      amountTo?: number
      days: number
    }) => {
      return (
        rule.cardNetwork === cardNetwork &&
        (!clearingNetwork || rule.clearingNetwork === clearingNetwork) &&
        (!currency || rule.currency === currency) &&
        (!amount || (rule.amountFrom < amount && (rule.amountTo ? rule.amountTo >= amount : true)))
      )
    }
  )

  const sortedRules = sortBy(rulesToApply, (rule: { days: number }) => rule.days)
  const ruleToApply = sortedRules[sortedRules.length - 1]

  return {
    cardNetwork,
    days: ruleToApply?.days,
  }
}
