import { Iam } from '../types/rules'
import { User } from '../types/user'
interface ACLProps {
  iam: Iam[]
  me: User
  kind?: string
  action?: string
  barracksId?: string
  requiredRoles?: string[]
}

const acl = ({ iam, me, kind, action, requiredRoles, barracksId }: ACLProps): boolean => {
  const { profiles = [], activeProfileId } = me || {}
  const activeProfile = profiles.find((p) => p.id === activeProfileId)
  if (!activeProfile) {
    return false
  }
  const { userId: activeUserId, entityId: activeEntityId } = activeProfile
  const barracksIdToCheck = barracksId || activeProfileId
  const activeIam = iam.find((i: Iam) => [i.entityId, ...(i.profileIds || [])].includes(barracksIdToCheck || ''))
  if (!activeIam) {
    return false
  }
  if (requiredRoles) {
    // Look for a role matching the requiresRoles
    const test = requiredRoles.map((r) => activeProfile.entityRoles.includes(r)).some((v) => v === true)
    return test
  } else if (kind && action) {
    // Check against profile or entity id barracksId is provided
    // Find the active profile's acls in the IAM

    // Make sure find the action
    const activeKind = activeIam.acl[kind]

    // Check for myself or the entity
    // meaning that if the inputed bararcksId is the one I am logged in as, or someone else
    const checkAgainsProfile = [activeProfileId, activeUserId, activeEntityId].includes(barracksIdToCheck)
    // Get the rule from the acl
    const ruleToApply = activeKind.entity[action] ? activeKind.entity[action].rule : false
    if (checkAgainsProfile && !ruleToApply) {
      return activeKind.profile[action] ? activeKind.profile[action].rule : false
    }
    return ruleToApply
  }
  return true
}

export default acl
