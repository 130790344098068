import { createGlobalStyle } from 'antd-style'

const GlobalStyle = createGlobalStyle`
#root {
  min-height: 100vh;
}

body {
  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,"sans-serif","Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji";
}

/* ------------ GLOBAL CLASSES ------------ */
.mobile-display {
  display: none !important;

  @media (max-width: ${(p) => p.theme.screenSM}px) {
    display: block !important;
  }
}

.desktop-display {
  @media (max-width: ${(p) => p.theme.screenSM}px) {
    display: none !important;
  }
}

.clickable {
  cursor: pointer;
}

.d-none {
  display: none !important;
}

.bh-track-classname {
  .ant-page-header-heading-title {
    font-size: ${(p) => p.theme.fontSizeSM}px !important;
  }
}

.bh-form .ant-form-item {
  margin: 0 !important;
}

.bh-action-page .ant-drawer-body {
  display: flex;
  flex-direction: column;
}

.bh-action-page .ant-descriptions-item-label {
    width: 12em;
}

.bh-action-page .ant-descriptions .ant-descriptions-item-label {
  color: #000000d9;
}

.bh-action-page .ant-descriptions .ant-descriptions-item-content {
  color: #000000d9;
  font-weight: 500;
}

.filterField .ant-input-group-addon button {
  border-color: ${(p) => p.theme.colorBorder} !important;
  border-radius: 0 ${(p) => p.theme.borderRadius}px ${(p) => p.theme.borderRadius}px 0 !important;
}

/* ------------ CUSTOM BUTTONS ------------ */
.ant-btn.btn-yellow {
    background-color: #f8b319 !important;
    border-color: #f8b319 !important;
    color: #333 !important;
}

/* ------------ DRAWER OVERRIDES ------------ */
.drawer-with-bg {
  .ant-drawer-content-wrapper>.ant-drawer-content {
    background: #e5e5e5;
  }
}

/* ------------ ANT TABLE OVERRIDES ------------ */
.bh-table .ant-table table {
    border-collapse: separate;
    border-spacing: 0 .4em;

    .ant-checkbox {
      border-spacing: 0;
    }
}

.bh-table table .ant-table-tbody tr {
  background-color: #fff;
  height: 48px;
} 

.bh-table .ant-table-tbody tr td:first-child {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px;
}

.bh-table .ant-table-tbody tr td:last-child {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px;
}

.ant-table-tbody>tr>td:not(:last-child):not(.ant-table-selection-column):not(.card-icons):before {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 2.4em;
  background-color: #ccc;
  transform: translateY(-50%);
  transition: background-color .3s;
  content: "";
}

.bh-table table .ant-table-thead tr th {
  color: #595959;
  font-weight: 500;
  margin-bottom: ${(p) => p.theme.marginSM}px;
  font-size: ${(p) => p.theme.fontSizeSM}px;
}

table .ant-table-tbody tr td.reference {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
`

export default GlobalStyle
