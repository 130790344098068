import { PaymentFormData, PaymentInstruction, PaymentInstructionTemplate, Recurrence } from 'types/paymentInstruction'
import { INTERVALS, mapFormToPaymentInstruction } from '.'
import dayjs from 'dayjs'

export default function mapFormToPaymentInstructionTemplate(
  formData: PaymentFormData,
  profileId: string,
  recurrenceData: Recurrence,
  dateDue: dayjs.Dayjs,
  paymentInstructionData?: PaymentInstruction
): PaymentInstructionTemplate {
  const pi = mapFormToPaymentInstruction(formData, profileId, dateDue)

  const intervalValue = INTERVALS[recurrenceData.interval!].value

  return {
    profileId: profileId,
    occurrencesRemaining: recurrenceData.occurrencesRemaining,
    interval: {
      ...intervalValue,
      infinity: !recurrenceData.limit,
    },
    dateDueNext: dateDue.format('YYYY-MM-DD'),
    creationChannel: {
      channel: 'web',
    },
    paymentInstructionProperties: {
      ...paymentInstructionData,
      ...pi,
      creationChannel: {
        channel: 'template',
      },
    },
  }
}
