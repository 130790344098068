import React, { useRef } from 'react'
import { Spin } from 'antd'
import { Loading3QuartersOutlined } from '@ant-design/icons'

interface LoaderProps {
  showLoader: boolean
  children?: React.ReactNode
  delay?: number
  bg?: string // a color
  message?: string
  fontSize?: number
}

export const BhLoadingIndicator = (fontSize = 36): React.JSX.Element => {
  return <Loading3QuartersOutlined style={{ fontSize }} spin />
}

const Loader = ({ showLoader, delay = 500, bg, message, children, fontSize }: LoaderProps): React.JSX.Element => {
  /**
   * Starts as true, but once the showLoader prop is false,
   * it will never be true again to avoid flickering
   */
  const spinning = useRef(true)

  if (!showLoader) {
    spinning.current = false
  }

  return (
    <Spin
      spinning={spinning.current}
      tip={message}
      indicator={BhLoadingIndicator(fontSize)}
      delay={delay}
      style={{
        backgroundColor: bg ? bg : 'inherit',
        maxHeight: '100%',
      }}
    >
      {children}
    </Spin>
  )
}

export default Loader
