import { useReducer, Reducer } from 'react'
import { getLanguageBasedOnCookie } from '../../utils/init'
import { SET_LANGUAGE } from './LanguageActionTypes'
import { createContainer } from 'react-tracked'
import { getPreferredLanguage } from '../../utils/storage'

const initialState = {
  language: getPreferredLanguage() || getLanguageBasedOnCookie(),
}

type State = typeof initialState

type Action = { type: string; language: string }

const reducer: Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.language,
      }

    default:
      throw new Error('unknown action type')
  }
}

const useValue = () => useReducer(reducer, initialState)

export const { Provider: LanguageStateProvider, useTracked: useLanguageState } = createContainer(useValue)
