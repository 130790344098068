import React, { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { Modal, Table } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { TablePaginationConfig } from 'antd/lib/table'
import { SorterResult, TableRowSelection } from 'antd/lib/table/interface'
import { useIntl } from 'react-intl'
import { FilterBody } from 'components/Filter/Filter'
import { BhLoadingIndicator } from 'components/Loader/Loader'
import { QueryParams } from 'types/general'
import { PaymentInstruction, PaymentInstructionAndTemplate, PaymentInstructionTemplate } from 'types/paymentInstruction'
import * as api from 'api/paymentInstruction'
import { useLanguageState } from 'stores/language/LanguageStore'
import { pi } from 'lang/definitions'
import { getActiveUser } from 'utils/helpers'
import { getDeviceType, DEVICE_TYPE, Size } from 'utils/getDeviceType'
import { useWindowSize } from 'hooks/useWindowSize'
import { columnsPaymentsCombo } from '../../utilsPiCombo'
import { useSession } from 'stores/session'

const DEFAULT_PAGE_SIZE = 10
const DEFAULT_PAGE = 1
const PAGE_SIZE_OPTIONS = ['10', '50', '100']

export interface PaymentAndTemplateRow {
  id: string
  state?: string
  dateDue?: string
  amount?: number
  currency?: string
  reference?: string[]
  beneficiary?: {
    title: string
    routingNumber: string | number
    accountNumber: string
    clearingNetwork: string
  }
  paymentInstructionProperties?: {
    amount: number
    currency: string
    reference: string[]
    beneficiary: {
      title: string
      routingNumber: string | number
      accountNumber: string
      clearingNetwork: string
    }
  }
}

interface PiProps {
  isActiveTable: boolean
  filter: FilterBody // The filter params for search
  rowsToRemove: string[]
  hideHeader?: boolean
  emptyMessage?: string
  piStates: string[]
  templateStates?: string[]
  typeOfPayment: string
  isPaymentOverview?: boolean
  onRowClick?: (
    event: React.MouseEvent<HTMLElement, MouseEvent> | undefined,
    record: PaymentAndTemplateRow,
    rowIndex: number | undefined
  ) => void
  setRowsToRemove: (rowsToRemove: string[]) => void
  setSelectedPayments: (selectedPayments: PaymentAndTemplateRow[]) => void
  setPaymentInstruction?: (record: PaymentInstruction | undefined) => void
  setPaymentInstructionTemplate?: (record: PaymentInstructionTemplate | undefined) => void
}

const CombinedPITable = React.memo(
  (props: PiProps): React.JSX.Element => {
    const {
      isActiveTable,
      filter,
      emptyMessage,
      typeOfPayment,
      rowsToRemove,
      isPaymentOverview,
      setRowsToRemove,
      onRowClick,
      setSelectedPayments,
      hideHeader,
      setPaymentInstruction,
      setPaymentInstructionTemplate,
    } = props

    const intl = useIntl()

    const size: Size = useWindowSize()

    const [languageState] = useLanguageState()
    const language = languageState.language
    const { state: sessionState } = useSession()
    const user = sessionState.user!
    const rules = sessionState.rules!

    const { profileId } = getActiveUser(user)

    const areAllPaymentsAllowed = !!rules.toggles?.allEntityPaymentsVisible?.rule
    const [dataSource, setDataSource] = useState<PaymentAndTemplateRow[]>([])
    const [total, setTotal] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false)
    const [selectedRowKeys, setSelectedRowKeys] = useState<(string | number | bigint)[]>([])

    const [pageSize, setPageSize] = useState(5)
    const [currentTablePage, setCurrentTablePage] = useState(DEFAULT_PAGE)
    const [params, setParams] = useState<QueryParams>()

    const fetchPayments = async (params?: QueryParams): Promise<void> => {
      if (!profileId) {
        return
      }
      setLoading(true)
      let fetched
      let total
      // If there is a filter set, search, otherwise get
      if (Object.values(filter).filter((v) => !!v).length) {
        ;({ payments: fetched, total: total = 0 } = isPaymentOverview
          ? await api.searchUsersPayments(filter, params)
          : await api.searchAllPaymentInstructionsAndTemplates(filter, params))
      } else {
        ;({ payments: fetched, total: total = 0 } = isPaymentOverview
          ? await api.getUserPayments(params)
          : await api.getAllPaymentsPerState(typeOfPayment, params))
      }

      const newDataSource: PaymentAndTemplateRow[] = (fetched || []).map((template: PaymentInstructionAndTemplate) => {
        //Check if it's pi template or not
        if (template.paymentInstructionProperties) {
          const {
            id = '',
            entity = {},
            dateDueNext = '',
            state = '',
            creationChannel,
            contributors = [],
            paymentInstructionProperties: {
              amount = 0,
              currency = '',
              reference = [],
              beneficiary: { title = '', accountNumber = '', routingNumber = '', clearingNetwork = '' } = {},
            } = {},
          } = template

          return {
            id,
            entity,
            dateDueNext,
            state,
            creationChannel,
            amount,
            currency,
            reference,
            contributors,
            paymentInstructionProperties: {
              amount,
              currency,
              reference,
              beneficiary: {
                title,
                accountNumber,
                routingNumber,
                clearingNetwork,
              },
            },
            beneficiary: {
              title,
              accountNumber,
              routingNumber,
              clearingNetwork,
            },
          }
        } else {
          const {
            id = '',
            entity = {},
            dateDue = '',
            state = '',
            amount,
            currency,
            reference = [],
            contributors = [],
            creationChannel,
            beneficiary: { title = '', accountNumber = '', routingNumber = '', clearingNetwork = '' } = {},
          } = template

          return {
            id,
            entity,
            dateDue,
            state,
            amount,
            currency,
            reference,
            contributors,
            creationChannel,
            beneficiary: {
              title,
              routingNumber,
              accountNumber,
              clearingNetwork,
            },
          }
        }
      })
      setLoading(false)
      setDataSource(newDataSource)
      setTotal(total)
    }

    useEffect(() => {
      if (isActiveTable && rowsToRemove.length) {
        const paymentsFromIds: PaymentAndTemplateRow[] = []

        rowsToRemove.forEach((id: string) => {
          dataSource.map((payment) => {
            if (id === payment.id) {
              paymentsFromIds.push(payment)
            }
          })
        })
        showDeleteConfirmationModal(paymentsFromIds)
      }
    }, [isActiveTable, rowsToRemove])

    useEffect(() => {
      void showDrawersFromHash()
    }, [window.location.hash, dataSource])

    // destructure hash url, prepare & store data for drawer form
    // triggered on hash url change
    const showDrawersFromHash = (): void => {
      const windowHash = window.location.hash

      if (windowHash && dataSource) {
        const isDetails = windowHash.includes('details')
        const hashIdFrom = windowHash.indexOf('?key=') + '?key='.length
        const hashIdTo = windowHash.lastIndexOf('?activeTab')
        // take id from hash url
        const hashId = windowHash.substring(hashIdFrom, hashIdTo >= 0 ? hashIdTo : undefined)

        const record = dataSource.find((rec) => rec.id === hashId)
        if (record && isDetails && setPaymentInstructionTemplate && record.paymentInstructionProperties) {
          setPaymentInstructionTemplate(record as PaymentInstructionTemplate)
        } else if (record && isDetails && setPaymentInstructionTemplate && !record.paymentInstructionProperties) {
          setPaymentInstruction && setPaymentInstruction(record as PaymentInstruction)
        }

        // for payment overview page
        if (record && isPaymentOverview) {
          const isTemplate = record?.paymentInstructionProperties

          if (isTemplate) {
            setPaymentInstructionTemplate && setPaymentInstructionTemplate(record as PaymentInstructionTemplate)
          } else {
            setPaymentInstruction && setPaymentInstruction(record as PaymentInstruction)
          }
        }
      }
    }

    const deletePaymentInstructions = async (payments: PaymentAndTemplateRow[]): Promise<void> => {
      setLoading(true)
      try {
        await Promise.all(
          payments.map(async (payment) => {
            if (payment.paymentInstructionProperties) {
              await api.deletePaymentInstructionTemplate(payment.id)
            } else if (!payment.paymentInstructionProperties) {
              await api.deletePaymentInstruction(payment.id)
            }
          })
        )

        setSelectedRowKeys([])
        setSelectedPayments([])
        setRowsToRemove([])

        const newParams: QueryParams = {
          ...params,
        }

        // How many pages remain after deleting the selected payments
        const remainingPages = Math.ceil((total - payments.length) / pageSize) || 1

        const pageToShow = remainingPages < currentTablePage ? remainingPages : currentTablePage
        setCurrentTablePage(pageToShow)

        const skip = (pageToShow - 1) * pageSize
        if (skip) {
          newParams.skip = skip
        }
        void fetchPayments(newParams)
      } catch (error) {
        setLoading(false)
        Sentry.captureException(error)
      }
    }

    const showDeleteConfirmationModal = (payments: PaymentAndTemplateRow[]) => {
      const content = intl.formatMessage(pi['pi.modal.delete.text'], {
        numberOfPayments: payments.length,
      })

      const okText = intl.formatMessage(pi['pi.modal.button.confirmDelete'])

      Modal.confirm({
        title: intl.formatMessage(pi['pi.modal.delete.header']),
        icon: <ExclamationCircleOutlined />,
        content,
        okText,
        cancelText: intl.formatMessage(pi['pi.modal.button.cancel']),
        onOk: () => deletePaymentInstructions(payments),
        onCancel: () => setRowsToRemove([]),
        okButtonProps: {
          style: {
            backgroundColor: '#C15A5A',
            border: 'none',
            outline: 'none',
            fontWeight: 'bold',
          },
        },
      })
    }

    useEffect((): void => {
      if (isActiveTable) {
        void fetchPayments()
        setCurrentTablePage(DEFAULT_PAGE)
        setPageSize(DEFAULT_PAGE_SIZE)
      } else {
        setTotal(0)
        setDataSource([])
      }
    }, [isActiveTable, filter, profileId])

    const handleChange = (
      pagination: TablePaginationConfig,
      filters: unknown,
      sorter: SorterResult<PaymentAndTemplateRow> | SorterResult<PaymentAndTemplateRow>[]
    ): void => {
      const newParams: QueryParams = {}

      const { current, pageSize } = pagination

      const { columnKey, order } = sorter as {
        columnKey: string
        order: string
      }
      if (current && pageSize) {
        newParams.limit = pageSize
        const skip = (current - 1) * pageSize
        if (skip) {
          newParams.skip = skip
        }
      }
      if (columnKey && order) {
        newParams.sortOrder = order === 'descend' ? 'desc' : 'asc'
        newParams.sortKey = columnKey
      }
      void fetchPayments(newParams)
      setParams(newParams)
    }

    const paginationProps = {
      total,
      pageSize,
      showSizeChanger: isPaymentOverview,
      showQuickJumper: isPaymentOverview,
      defaultPageSize: DEFAULT_PAGE_SIZE,
      current: currentTablePage,
      pageSizeOptions: PAGE_SIZE_OPTIONS,
      position: getDeviceType(size) === DEVICE_TYPE.MOBILE ? ['bottomCenter' as const] : ['bottomRight' as const],
      onShowSizeChange: (current: number, size: number) => {
        setCurrentTablePage(current)
        setPageSize(size)
      },
      onChange: (page: number) => setCurrentTablePage(page),
    }

    const getRowSelection = (): TableRowSelection<PaymentAndTemplateRow> | undefined => {
      if (!isPaymentOverview)
        return {
          preserveSelectedRowKeys: true,
          onChange: (selectedRowKeys: (string | number | bigint)[], selectedRows: PaymentAndTemplateRow[]) => {
            setSelectedRowKeys(selectedRowKeys)
            setSelectedPayments(selectedRows)
          },
          selectedRowKeys,
        }
    }

    return (
      <Table
        onRow={(record, rowIndex) => {
          return {
            onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) =>
              onRowClick && onRowClick(event, record, rowIndex),
          }
        }}
        rowClassName={() => {
          return onRowClick ? 'clickable' : ''
        }}
        rowSelection={getRowSelection()}
        size="small"
        loading={{ spinning: loading, indicator: BhLoadingIndicator() }}
        scroll={{ x: true }}
        rowKey={(record: { id: string }) => `${record.id}`}
        columns={[
          ...columnsPaymentsCombo({
            language,
            intl,
            hasAvatar: areAllPaymentsAllowed || !!isPaymentOverview,
            hasReference: !isPaymentOverview,
            hasEntity: !!isPaymentOverview,
          }),
        ]}
        locale={{
          emptyText: emptyMessage || intl.formatMessage(pi['pi.not.added']),
        }}
        className="bh-table"
        dataSource={dataSource}
        pagination={paginationProps}
        onChange={handleChange}
        showHeader={hideHeader ? false : true}
      />
    )
  },
  (prevProps, nextProps) => {
    const filtersChanged = JSON.stringify(prevProps.filter) !== JSON.stringify(nextProps.filter)
    const becameActive = prevProps.isActiveTable !== nextProps.isActiveTable
    const rowsToRemoveChanged = prevProps.rowsToRemove !== nextProps.rowsToRemove
    if (becameActive) {
      return false
    } else if (filtersChanged) {
      // rerender
      return false
    } else if (rowsToRemoveChanged) {
      // rerender
      return false
    }
    // Don't rerender
    return true
  }
)

export default CombinedPITable
