import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Progress, Button, Pagination } from 'antd'
import { PaperClipOutlined } from '@ant-design/icons'
import Currency from 'components/Format/Currency/Currency'
import { useLanguageState } from 'stores/language/LanguageStore'
import { getCurrentPage } from '../../utils'
import { Import, FileProperties, ProcessingError, SFTPProperties, BucketProperties } from 'types/import'
import { pi } from 'lang/definitions'
import Loader from 'components/Loader/Loader'
import usePaymentImportStyle from './PaymentImport.style'
import { cx } from 'antd-style'

const DEFAULT_ERROR_PAGE_SIZE = 5

interface PaymentImportProps {
  paymentImport: Import
}
const PaymentImport = (props: PaymentImportProps): React.JSX.Element => {
  const { styles } = usePaymentImportStyle()
  const [languageState] = useLanguageState()
  const language = languageState.language
  const { paymentImport } = props
  const intl = useIntl()
  const [showErrors, setShowErrors] = useState(false)
  const [errors, setErrors] = useState<ProcessingError[]>([])
  const [errorPage, setErrorPage] = useState<ProcessingError[]>([])
  const [showBadge, setShowBadge] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [, setLoading] = useState<boolean>(true)
  useEffect(() => {
    if (paymentImport.status === 'processing') {
      setShowBadge(true)
    }
    if (paymentImport.processingErrors) {
      setErrors(paymentImport.processingErrors)
      setShowBadge(false)
    }
    if (progressPercent === 100) {
      setLoading(false)
    }
  }, [paymentImport])

  useEffect(() => {
    const page = getCurrentPage(errors, currentPage, DEFAULT_ERROR_PAGE_SIZE) as ProcessingError[]
    setErrorPage(page)
  }, [errors, currentPage])
  const isFile = (
    sourceProperties: SFTPProperties | FileProperties | BucketProperties
  ): sourceProperties is FileProperties => {
    if ((sourceProperties as FileProperties).fileName) {
      return true
    }
    return false
  }
  const isSFTP = (
    sourceProperties: SFTPProperties | FileProperties | BucketProperties
  ): sourceProperties is SFTPProperties => {
    if ((sourceProperties as SFTPProperties).file) {
      return true
    }
    return false
  }
  // get import name based on sourceProperties type
  const importName = isFile(paymentImport.sourceProperties)
    ? paymentImport.sourceProperties.fileName
    : isSFTP(paymentImport.sourceProperties)
      ? paymentImport.sourceProperties.file.fileName
      : paymentImport.sourceProperties.format
  const numberPaymentInstructionsCreated = paymentImport.paymentInstructionIds?.length || 0
  const numberPaymentInstructionTemplatesCreated = paymentImport.paymentInstructionTemplateIds?.length || 0
  const numberOfErrors = paymentImport.processingErrors?.length || 0
  const parsedValidCount = paymentImport.parsedValidCount || 0
  const numberOfProcessed = numberPaymentInstructionsCreated + numberPaymentInstructionTemplatesCreated + numberOfErrors
  const progressPercent = Math.round((numberOfProcessed / parsedValidCount) * 100)
  const numberOfSuccessfullImports = numberPaymentInstructionsCreated + numberPaymentInstructionTemplatesCreated
  return (
    <div className={styles.container}>
      <div
        className={cx(styles.icon, {
          [styles.errors]: !!numberOfErrors && paymentImport.status !== 'processing',
        })}
      >
        {showBadge ? <Loader showLoader={true} fontSize={12} /> : <PaperClipOutlined />}
      </div>
      <div className={styles.import}>
        <div
          className={cx(styles.fileName, {
            [styles.errors]: !!numberOfErrors && paymentImport.status !== 'processing',
          })}
        >
          <span>{importName}</span>
        </div>
        {showBadge ? (
          <Progress percent={progressPercent} showInfo={false} />
        ) : (
          <React.Fragment>
            {numberOfSuccessfullImports && (
              <div>
                <span>
                  {intl.formatMessage(pi['pi.import.numberOfSuccessfullImports'], {
                    numberOfSuccessfullImports,
                  })}
                </span>
              </div>
            )}
            {!!numberOfErrors && (
              <React.Fragment>
                <div className={styles.importErrors}>
                  <div className={styles.errorHeader}>
                    <span>
                      {intl.formatMessage(pi['pi.import.numberOfFailedImports'], {
                        numberOfFailedImports: numberOfErrors,
                      })}
                    </span>
                    <Button type="link" onClick={() => setShowErrors(!showErrors)}>
                      {showErrors ? `hide` : `show`}
                    </Button>
                  </div>
                  {showErrors && (
                    <div className={styles.errorsTable}>
                      <div className={cx(styles.row, styles.header)}>
                        <div className={styles.column}>{intl.formatMessage(pi['pi.import.errorTable.message'])}</div>
                        <div className={styles.column}>
                          {intl.formatMessage(pi['pi.import.errorTable.beneficiary'])}
                        </div>
                        <div className={styles.column}>{intl.formatMessage(pi['pi.import.errorTable.dueDate'])}</div>
                        <div className={styles.column}>{intl.formatMessage(pi['pi.import.errorTable.amount'])}</div>
                      </div>
                      {errorPage.map((error: ProcessingError, index: number) => {
                        const {
                          details: { errorMessage, message, field } = {},
                          relatedTo: {
                            parameters: {
                              dateDue,
                              amount,
                              currencyId,
                              beneficiary: { title: beneficiaryTitle } = {},
                            } = {},
                          } = {},
                        } = error
                        const hasRelated = beneficiaryTitle || dateDue || amount || currencyId
                        return (
                          <div key={`error-${index}`} className={styles.row}>
                            <div className={styles.column} style={!hasRelated ? { width: '100%' } : {}}>
                              <div>{errorMessage || message}</div>
                              <div>{field}</div>
                            </div>
                            <div
                              className={cx(styles.column, {
                                [styles.empty]: !hasRelated,
                              })}
                            >
                              {beneficiaryTitle}
                            </div>
                            <div
                              className={cx(styles.column, {
                                [styles.empty]: !hasRelated,
                              })}
                            >
                              {dateDue}
                            </div>
                            <div
                              className={cx(styles.column, {
                                [styles.empty]: !hasRelated,
                              })}
                            >
                              {amount ? (
                                <Currency
                                  value={amount.toString()}
                                  locale={language}
                                  currency={error.relatedTo.parameters.currencyId}
                                />
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        )
                      })}
                      {errors.length > DEFAULT_ERROR_PAGE_SIZE && (
                        <div className={styles.errorsPagination}>
                          <Pagination
                            current={currentPage}
                            defaultPageSize={DEFAULT_ERROR_PAGE_SIZE}
                            showSizeChanger={false}
                            total={errors.length}
                            size="small"
                            onChange={setCurrentPage}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  )
}
export default PaymentImport
