import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import * as Sentry from '@sentry/react'
import { api } from '../../api/utils'
import { notification } from 'antd'
import { validateProfile, getUser } from '../../api/auth'
import { ValidateProfile } from '../../types/rules'
import { useInterval } from '../../hooks/useInterval'
import {
  saveAuthenticationStatus,
  saveSessionId,
  saveIsProfileChosen,
  removeIsProfileChosen,
} from '../../utils/storage'
import { Redirect } from 'react-router-dom'
import { useSsoState } from '../../stores/Sso/SsoStore'
import { SET_PROVIDER_ID, SET_SSO_STATUS } from '../../stores/Sso/SsoActionTypes'
import { validateSessionId } from '../../utils/validate'
import { useSession } from 'stores/session'

interface ParamTypes {
  bhSsoToken: string
}
interface Session {
  status: string
  sessionId: string
  profileId?: string
  providerId?: string
  userId?: string
  entityId?: string
  connectionId?: string
  countryCode?: string
}
// the page the user comes back to when an SSO login has been made
// Will look for an active session and login the user
const SSOIntermediateLoginPage = (): React.JSX.Element => {
  const [startPolling, setStartPolling] = useState<boolean>(false)
  const {
    actions: { setUser, setSessionId },
  } = useSession()
  const history = useHistory()
  const { bhSsoToken } = useParams<ParamTypes>()
  const [, dispatchSso] = useSsoState()
  const setSsoStatus = (status: string) => dispatchSso({ type: SET_SSO_STATUS, value: status })
  const setSsoProviderId = (provider: string) => dispatchSso({ type: SET_PROVIDER_ID, value: provider })
  // poll session status every 300ms. use this to fetch status.
  useInterval(
    async () => {
      const response = await api.get(`/session/sso/${bhSsoToken}`)
      const session = (await response.json()) as Session
      console.log('im polling session-status every 1 second', { session })

      if (session.status === 'ALIVE' && session.profileId) {
        saveSessionId(session?.sessionId)
        saveAuthenticationStatus()
        await validateProfile(session.profileId)
          .then(async (data: ValidateProfile): Promise<void> => {
            saveSessionId(data?.sessionId)
            saveAuthenticationStatus()
            const user = await getUser()
            if (user) {
              setUser(user)
              void saveIsProfileChosen('true')
              setSessionId(data?.sessionId)
              history.replace('/')
            }
          })
          .catch((err) => {
            setStartPolling(false)
            Sentry.captureException(err)
          })
      }
      if (session.status === 'ALIVE' && !session.profileId) {
        saveSessionId(session?.sessionId)
        saveAuthenticationStatus()
        removeIsProfileChosen()
        const isValid = await validateSessionId()
        if (isValid) {
          setSessionId(session?.sessionId)
          saveAuthenticationStatus()
          return <Redirect to={'/choose-profile'} />
        }
      }
      if (session.status === 'SSO_ERROR_INVALID_USER' && session.connectionId) {
        const countryCode = session.countryCode || 'gb'
        const connectionId = session.connectionId
        history.replace(`/integration/${countryCode}/xero/${connectionId}`)
      }
      if (session.status === 'SSO_ERROR_USER_PROVISION_NOT_ALLOWED') {
        const provider = localStorage.getItem('ssoPendingProvider')
        const status = session.status
        setSsoStatus(status)
        setSsoProviderId(provider!)
        history.replace('/')
      }
      if (session.status === 'SSO_ERROR_INVALID_CONNECTION') {
        notification.error({
          message: 'Something went wrong. Please try again.',
          placement: 'topRight',
        })
        history.replace('/')
      }
    },
    startPolling ? 1000 : null
  )

  useEffect(() => {
    try {
      setStartPolling(true)
    } catch (err) {
      console.log('Error', err)
    }
  }, [])

  return (
    <div className="sso-intermediate">
      <div>Loading 🤺</div>
    </div>
  )
}

export default SSOIntermediateLoginPage
