import React, { useEffect, useState } from 'react'
import { Alert, Button, Card, Form, FormInstance, Input, Space, Typography } from 'antd'
import { searchGiro } from '../../../api/lookup'
import { Beneficiary, PaymentType } from '../../../types/beneficiary'
import { RecipientTitle, AccountNumber } from './FormItems'
import { SpaceForm } from '../components'
import { useTranslation } from '../../../utils/helpers'
import {
  useBgPgAccountNumberValidator,
  BG_MAX_LENGTH,
  PG_MAX_LENGTH,
  BG_MIN_LENGTH,
  PG_MIN_LENGTH,
} from 'utils/validators'
import useSuffix from './FormItems/useSuffix'

export enum SwedishPaymentType {
  BG = PaymentType.BankGiro,
  PG = PaymentType.PlusGiro,
}

type Props = {
  paymentType: PaymentType
  onBeneficiaryChange: (beneficiary: Partial<Beneficiary> | undefined) => void
  getFormInstance?: (form: FormInstance<BeneficiaryLookupForm>) => void
}

export type BeneficiaryLookupForm = {
  accountNumber: string
  title: string
}
const pattern = /[A-Za-z0-9]/g
const getCleanAccountNumber = (accountNumber: string) => {
  return accountNumber.replace(/\D/g, '')
}

export default function BGPG({ paymentType, onBeneficiaryChange, getFormInstance }: Props): React.JSX.Element {
  const t = useTranslations(paymentType)
  const [lookupForm] = Form.useForm<BeneficiaryLookupForm>()
  const [beneficiary, setBeneficiary] = useState<Partial<Beneficiary>>()
  const [isLoading, setIsLoading] = useState(false)
  const [isManualFlow, setIsManualFlow] = useState(false)
  const accountNumber = Form.useWatch('accountNumber', lookupForm)
  const accountNumberSuffix = useSuffix({
    form: lookupForm,
    fieldName: 'accountNumber',
    maxLength: paymentType === PaymentType.BankGiro ? BG_MAX_LENGTH : PG_MAX_LENGTH,
    pattern,
  })

  useEffect(() => {
    getFormInstance?.(lookupForm)
  }, [lookupForm])

  useEffect(() => {
    unselectRecipient()
    setIsManualFlow(false)
  }, [paymentType])

  const unselectRecipient = () => {
    setBeneficiary(undefined)
  }

  const onFindRecipientClick = () => {
    if (isManualFlow) {
      // If there has been a search and no beneficiary was found, do a retry
      lookupBeneficiary(lookupForm.getFieldsValue())
    } else {
      // Otherwise submit the form to trigger validation
      lookupForm.submit()
    }
  }

  const onSubmit = (formData: BeneficiaryLookupForm) => {
    const { title, accountNumber } = formData

    if (isManualFlow) {
      setBeneficiary({
        title,
        accountNumber,
      })

      return
    }

    // Look up details
    lookupBeneficiary(formData)
  }

  const lookupBeneficiary = (form: BeneficiaryLookupForm) => {
    const { accountNumber } = form

    const formattedAccountNumber = getCleanAccountNumber(accountNumber)

    setIsLoading(true)
    searchGiro(formattedAccountNumber, paymentType)
      .then((response) => {
        delete response.countryCode // Not accepted by api

        if (response.title) {
          setBeneficiary(response)
          setIsManualFlow(false)
        } else {
          setIsManualFlow(true)
        }
      })
      .catch(() => {
        setBeneficiary(undefined)
        setIsManualFlow(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    onBeneficiaryChange(
      beneficiary
        ? {
            ...beneficiary,
            type: 'business',
          }
        : undefined
    )
  }, [beneficiary])

  const accountNumberValidator = useBgPgAccountNumberValidator(paymentType)
  const handleAccountNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.value.replace(/[^A-Za-z0-9]/g, '')
    if (isManualFlow) {
      setIsManualFlow(false)
      lookupForm.setFieldValue('title', '')
    }
  }
  const getMinMaxValidation = () => {
    if (paymentType === PaymentType.BankGiro) {
      return {
        min: BG_MIN_LENGTH,
        max: BG_MAX_LENGTH,
      }
    }
    return {
      min: PG_MIN_LENGTH,
      max: PG_MAX_LENGTH,
    }
  }
  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedValue = e.clipboardData.getData('text')
    const cleanedValue = pastedValue.replace(/[^A-Za-z0-9]/g, '')
    e.preventDefault() // Prevent the default paste operation

    lookupForm.setFieldsValue({ accountNumber: cleanedValue })
  }

  const { min, max } = getMinMaxValidation()
  return (
    <div data-testid="bg-pg-finder">
      <Form
        form={lookupForm}
        onFinish={onSubmit}
        scrollToFirstError
        requiredMark={false}
        layout="vertical"
        data-testid="account-number-form"
      >
        <SpaceForm>
          {!beneficiary && (
            <AccountNumber label={t.label} validator={accountNumberValidator}>
              <Space.Compact style={{ display: 'flex' }} size="large">
                <Input
                  placeholder={t.placeholder}
                  disabled={isLoading}
                  value={accountNumber}
                  size="large"
                  suffix={accountNumberSuffix}
                  onInput={handleAccountNumberChange}
                  onPaste={handlePaste}
                  minLength={min}
                  maxLength={max}
                  style={{
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  }}
                />
                <Button
                  type="primary"
                  loading={isLoading}
                  onClick={onFindRecipientClick}
                  htmlType="button"
                  style={{
                    borderTopRightRadius: 10,
                    borderBottomRightRadius: 10,
                  }}
                >
                  {t.lookupBtnLabel}
                </Button>
              </Space.Compact>
            </AccountNumber>
          )}

          {isManualFlow && !beneficiary && (
            <Card>
              <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                <Typography>{t.lookupFailed}</Typography>

                <RecipientTitle disabled={isLoading} onPressEnter={() => lookupForm.submit()} />
              </Space>
            </Card>
          )}

          {beneficiary && (
            <Alert
              message={beneficiary.title}
              description={`
            ${beneficiary.clearingNetwork?.toUpperCase?.() || ''}
            ${beneficiary.accountNumber || ''}
          `}
              type="success"
              afterClose={unselectRecipient}
              data-testid="beneficiary-alert"
              closable
            />
          )}
        </SpaceForm>
      </Form>
    </div>
  )
}

function useTranslations(paymentType: string): Record<string, string> {
  const t = useTranslation()

  return {
    ...{
      [PaymentType.BankGiro]: {
        label: t('beneficiary.bank.giroNumber'),
      },
      [PaymentType.PlusGiro]: {
        label: t('beneficiary.plus.giroNumber'),
      },
    }[paymentType],
    placeholder: t('beneficiary.accountNumber.placeholder'),
    lookupBtnLabel: t('beneficiary.bgpg.button.lookup'),
    lookupFailed: t('beneficiary.bgpg.lookup.failed'),
  }
}
