import React from 'react'
import BHDrawer from '../Drawers/BHDrawer'
import useActionPageStyle from './ActionPage.style'
import { cx } from 'antd-style'

interface ActionPageProps {
  title: string
  children: React.ReactNode | React.ReactNode[]
  additionalClass?: string | undefined
  hash: string | undefined
  pathname: string
  maskClosable?: boolean
}
const ActionPage = ({
  additionalClass,
  title,
  hash,
  children,
  pathname,
  maskClosable = true,
}: ActionPageProps): React.JSX.Element => {
  const { styles } = useActionPageStyle()
  const addClass = additionalClass ? additionalClass : ''
  return (
    <BHDrawer
      className={cx(styles.actionPage, addClass)}
      title={title}
      destroyOnClose={true}
      hash={hash}
      pathname={pathname}
      maskClosable={maskClosable}
    >
      {children}
    </BHDrawer>
  )
}

export default ActionPage
