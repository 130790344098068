import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css, responsive, token }) => {
  return {
    sidebar: css`
      background-color: ${token.colorPrimary};
      top: 0;
      min-width: 200px;
      max-width: 200px;
      overflow-y: auto;
      height: 100vh;

      &.hidden {
        display: block;
      }

      .ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow {
        opacity: 1;
        transform: scale(1.4);
      }

      ${responsive.xs} {
        z-index: 1;
        z-index: 100;
        top: 63px;
        left: 0;
        position: absolute;
        width: 100%;
        min-width: 100%;
        display: flex;
        flex-direction: column;

        &.hidden {
          display: none;
        }
      }
    `,
    sidebarButtonContainer: css`
      display: none;

      ${responsive.xs} {
        position: absolute;
        width: 56px;
        height: 49px;
        background-color: transparent;
        z-index: 3;
        top: 10%;
        left: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        button {
          height: 100%;
          padding-top: 15px;
          outline: none;
          > div {
            display: block;
          }
        }
      }
    `,
    menuContainer: css`
      eight: 100%;
      overflow-y: auto;

      ${responsive.xs} {
        width: 100%;
        padding-top: ${token.paddingSM}px;
      }
    `,
    menuLabel: css`
      padding: ${token.paddingXL}px;
      color: white;
    `,
    menuItem: css`
      margin-bottom: 20px !important;
      font-weight: 600;
      &.active {
        background: #57288f;
      }
    `,
    childMenuItem: css`
      font-weight: 400;
      padding-left: 36px !important;
      &.active {
        background: #57288f;
      }
    `,
    backButtonContainer: css`
      width: 90%;
      margin-left: auto;
      margin-right: auto;
    `,
  }
})

export default useStyles
