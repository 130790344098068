import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css, token }) => {
  return {
    summaryTitle: css`
      font-weight: 500;
      font-size: ${token.fontSizeLG}px;
      display: block;
      margin-bottom: ${token.marginMD}px;
      color: black;
    `,
    summaryCardWrapper: css`
      background: white;
      padding: 16px;
      border-radius: 10px;
      margin-bottom: 30px;
      min-height: 20vh;
      max-height: 50vh;
      overflow-y: auto;
    `,
    content: css`
      margin-bottom: ${token.marginLG}px;
    `,
    item: css`
      display: flex;
      column-gap: ${token.marginSM}px;
      align-items: center;
    `,
    icon: css`
      width: 16px;
      height: 16px;
    `,
    title: css`
      font-size: ${token.fontSize}px;
    `,
    itemSelected: css`
      margin: 8px 0 8px 4px;
    `,
    box: css`
      background: #e0e0e0;
      border-radius: 2px;
      padding: 4px 8px;
      display: inline-block;
      align-items: center;
      margin-right: 10px;
      margin-bottom: 10px;
    `,
    remove: css`
      cursor: pointer;
      margin-left: 8px;
    `,
  }
})

export default useStyles
