import { PAYMENT_KIND, Payment } from 'types/paymentInstruction'

export default function sortPaymentsByKindAndSetActionType(payments: Payment[]): {
  payPiIds: string[]
  payTemplateIds: string[]
  approvePiIds: string[]
  approveTemplateIds: string[]
} {
  const mapToId = (payment: Payment) => payment.id

  return {
    payPiIds: payments
      .filter(({ kind }: Payment) => kind === PAYMENT_KIND.PAYMENT_INSTRUCTION)
      .filter(({ signatures }: Payment) => signatures!.requiredNumber - signatures!.signedBy.length === 1)
      .map(mapToId),
    payTemplateIds: payments
      .filter(({ kind }: Payment) => kind !== PAYMENT_KIND.PAYMENT_INSTRUCTION)
      .filter(({ signatures }: Payment) => signatures!.requiredNumber - signatures!.signedBy.length === 1)
      .map(mapToId),
    approvePiIds: payments
      .filter(({ kind }: Payment) => kind === PAYMENT_KIND.PAYMENT_INSTRUCTION)
      .filter(({ signatures }: Payment) => signatures!.requiredNumber - signatures!.signedBy.length !== 1)
      .map(mapToId),
    approveTemplateIds: payments
      .filter(({ kind }: Payment) => kind !== PAYMENT_KIND.PAYMENT_INSTRUCTION)
      .filter(({ signatures }: Payment) => signatures!.requiredNumber - signatures!.signedBy.length !== 1)
      .map(mapToId),
  }
}
