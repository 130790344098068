import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { Button, Result, Tabs, TabsProps } from 'antd'
import Loader from 'components/Loader/Loader'
import CreatePayments from './CreatePayments/CreatePayments'
import ImportPayments from './ImportPayments/ImportPayments'
import { Attachment, PaymentInstruction, PaymentInstructionTemplate, PAYMENT_KIND } from 'types/paymentInstruction'
import { PaymentGateway } from 'types/rules'
import { page, pi } from 'lang/definitions'
import ACL from 'components/ACL/ACL'
import acl from 'utils/acl'
import { SCREEN_LG, Size } from 'utils/getDeviceType'
import { useWindowSize } from 'hooks/useWindowSize'
import AlertMessagesContainer from 'components/AlertMessage/AlertMessagesContainer'
import { PaymentTab } from 'types/ui'
import { useCurrentPayment } from 'stores/Payment'
import { useSession } from 'stores/session'
import useAddPaymentsStyles from './AddPayments.style'
import { cx } from 'antd-style'

export interface AddPaymentsProps {
  createPaymentInstruction: (pi: PaymentInstruction) => Promise<PaymentInstruction>
  deletePaymentInstruction: (paymentInstructionId: string) => void
  deleteTemplate: (templateId: string) => void
  getDataAndSelectPayments: (paymentInstructionIds: string[], paymentInstructionTemplateIds: string[]) => void
  createPaymentInstructionTemplate: (template: PaymentInstructionTemplate) => Promise<PaymentInstructionTemplate>
  updatePaymentInstruction: (pi: PaymentInstruction) => Promise<PaymentInstruction>
  updatePaymentInstructionTemplate: (template: PaymentInstructionTemplate) => Promise<PaymentInstructionTemplate>
  replacePaymentInstructionWithTemplate: (template: PaymentInstructionTemplate) => Promise<PaymentInstructionTemplate>
  replaceTemplateWithPaymentInstruction: (paymentInstruction: PaymentInstruction) => Promise<PaymentInstruction>
  cancelEdit: () => void
  createAttachment: (paymentId: string, attachment: Attachment, kind: PAYMENT_KIND) => Promise<void>
  deleteAttachment: (paymentId: string, attachmentId: string, kind: PAYMENT_KIND) => Promise<void>
}

const AddPayments = ({
  getDataAndSelectPayments,
  createPaymentInstruction,
  deletePaymentInstruction,
  deleteTemplate,
  createPaymentInstructionTemplate,
  updatePaymentInstruction,
  updatePaymentInstructionTemplate,
  replacePaymentInstructionWithTemplate,
  replaceTemplateWithPaymentInstruction,
  cancelEdit,
  createAttachment,
  deleteAttachment,
}: AddPaymentsProps): React.JSX.Element => {
  const intl = useIntl()
  const history = useHistory()
  const { styles } = useAddPaymentsStyles()
  const {
    state: { user, rules, isB2BUser, iam },
  } = useSession()

  const {
    state: { currentTab, showInitLoader },
    actions: { setCurrentPaymentsTab: setActiveTab },
  } = useCurrentPayment()

  const [cardNetworks, setCardNetworks] = useState<string[]>([])
  const [gateways, setGateways] = useState<PaymentGateway[]>([])

  const size: Size = useWindowSize()

  const showTabFromParam = (): void => {
    const windowHash = window.location.pathname

    if (!windowHash) {
      return
    }

    const hashArray = windowHash.split('/')
    const hashId = hashArray[4]

    switch (hashId) {
      case 'input': {
        setActiveTab('input')
        break
      }
      case 'import': {
        setActiveTab('import')
        break
      }
      default: {
        setActiveTab('input')
        break
      }
    }
  }

  useEffect(() => {
    showTabFromParam()
  }, [window.location.pathname])

  useEffect(() => {
    let ruleList: Array<string> = []

    if (!rules) {
      return
    }

    for (const [key, value] of Object.entries(rules.logic.cardNetworks.rule)) {
      if (value) ruleList = [...ruleList, key]
    }

    setCardNetworks(ruleList)
    setGateways(rules.logic.paymentGateways.rule)
  }, [rules])

  const getTabItems = (): TabsProps['items'] => {
    const tabs = [
      {
        key: 'input',
        label: intl.formatMessage(pi['pi.add.addPayments.tabs.input']),
        children: (
          <Loader showLoader={showInitLoader}>
            <CreatePayments
              cardNetworks={cardNetworks}
              gateways={gateways}
              createPaymentInstruction={createPaymentInstruction}
              deletePaymentInstruction={deletePaymentInstruction}
              deleteTemplate={deleteTemplate}
              createPaymentInstructionTemplate={createPaymentInstructionTemplate}
              updatePaymentInstruction={updatePaymentInstruction}
              updatePaymentInstructionTemplate={updatePaymentInstructionTemplate}
              replacePaymentInstructionWithTemplate={replacePaymentInstructionWithTemplate}
              replaceTemplateWithPaymentInstruction={replaceTemplateWithPaymentInstruction}
              cancelEdit={cancelEdit}
              createAttachment={createAttachment}
              deleteAttachment={deleteAttachment}
            />
          </Loader>
        ),
      },
    ]

    if (!iam || !user) {
      return tabs
    }

    if (
      isB2BUser &&
      acl({
        iam,
        me: user,
        kind: 'import',
        barracksId: user.activeProfileId,
        action: 'create',
      })
    ) {
      tabs.push({
        key: 'import',
        label: intl.formatMessage(pi['pi.add.addPayments.tabs.import']),

        children: (
          <ACL kind="import" barracksId={user?.activeProfileId || ''} action="create">
            <div className={styles.tab}>
              {(size.width || 0) < SCREEN_LG ? (
                <Result
                  status="warning"
                  title={intl.formatMessage(page['page.mobile.limitation.warning.text'])}
                  extra={
                    <Button
                      type="primary"
                      onClick={() => {
                        history.push('/')
                      }}
                    >
                      {intl.formatMessage(page['page.404.link.back'])}
                    </Button>
                  }
                />
              ) : (
                <ImportPayments
                  cardNetworks={cardNetworks}
                  gateways={gateways}
                  getDataAndSelectPayments={getDataAndSelectPayments}
                />
              )}
            </div>
          </ACL>
        ),
      })
    }

    return tabs
  }

  return (
    <div className={cx(styles.container, 'bh-track-add-payment-page-container')}>
      <div className={styles.tabs}>
        <Tabs
          destroyInactiveTabPane
          activeKey={currentTab}
          onChange={(key: string) => {
            history.push(key)
            setActiveTab(key as PaymentTab)
          }}
          items={getTabItems()}
        />
      </div>
      <AlertMessagesContainer placement="addPayment" />
    </div>
  )
}

export default AddPayments
