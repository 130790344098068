import React, { useState } from 'react'
import { Button, Form, Input } from 'antd'
import { useTranslation } from '../../../../utils/helpers'
import { useTitleValidator } from 'utils/validators'
import useSuffix from './useSuffix'
import { GenericMaxLength } from 'utils/validators'

export default function CareOf(): React.JSX.Element {
  const t = useTranslation()
  const titleValidator = useTitleValidator()

  const [showCOInput, setShowCOInput] = useState<boolean>(false)
  const careOfSuffix = useSuffix({ fieldName: ['address', 'careOf'], maxLength: GenericMaxLength })

  const changeShowCO = () => {
    setShowCOInput(!showCOInput)
  }

  return showCOInput ? (
    <Form.Item
      name={['address', 'careOf']}
      label={t('beneficiary.form.ach.careof.label')}
      hasFeedback
      validateTrigger="onChange"
      rules={[
        {
          validator: titleValidator,
        },
      ]}
    >
      <Input
        size="large"
        data-testid="care-of-input"
        type="text"
        placeholder={t('beneficiary.form.ach.careof.placeholder')}
        suffix={careOfSuffix}
      />
    </Form.Item>
  ) : (
    <Button type="link" onClick={changeShowCO}>
      {t('beneficiary.form.ach.careof.button')}
    </Button>
  )
}
