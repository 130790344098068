import React, { Dispatch, SetStateAction } from 'react'
import ActionPage from '../../../components/ActionPage/ActionPage'

import { Button, Checkbox, Collapse, Form, Input } from 'antd'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { action, reports } from '../../../lang/definitions'
import { QueryParams } from '@sentry/types'
import TextArea from 'antd/lib/input/TextArea'
import { ReportTemplate } from '../../../types/report'
import { useSession } from 'stores/session'

interface TemplateFormProps {
  template?: ReportTemplate
  setTemplate?: Dispatch<SetStateAction<ReportTemplate | undefined>>
  getReportTemplates?: (profileId: string, params?: QueryParams) => void
  handleEditTemplate?: () => void
  handleSubmitTemplate?: (values: { title: string; description: string }) => void
  setIsAvailableToAllEntityIds?: (checked: boolean) => void
  summary: React.JSX.Element
}

const TemplateForm = ({
  template,
  setTemplate,
  handleEditTemplate,
  handleSubmitTemplate,
  setIsAvailableToAllEntityIds,
  summary,
}: TemplateFormProps): React.JSX.Element => {
  const { Panel } = Collapse
  const [form] = Form.useForm()
  const intl = useIntl()
  const history = useHistory()
  const { state: sessionState } = useSession()
  const user = sessionState.user!

  return (
    <>
      <ActionPage
        title={
          !template
            ? intl.formatMessage(reports['reports.template.form.title'])
            : intl.formatMessage(reports['reports.template.form.edit.title'])
        }
        hash={
          !template ? '#drawer-new-report-template' : `#templates#drawer-edit?key=${(template && template.id) || ''}`
        }
        pathname={history.location.pathname}
        maskClosable={false}
      >
        <Collapse bordered={false} expandIconPosition="end" accordion={true}>
          <Panel
            header={intl.formatMessage(reports['reports.template.form.panel.header'])}
            key="1"
            data-testid="summary-panel"
          >
            {summary}
          </Panel>
        </Collapse>
        <Form
          className={!template ? 'report-payment-form' : 'report-edit-template-form'}
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={!template ? handleSubmitTemplate : handleEditTemplate}
        >
          <Form.Item name="title" label={intl.formatMessage(reports['reports.template.form.title.label'])}>
            <Input
              placeholder={intl.formatMessage(reports['reports.template.form.title.placeholder'])}
              defaultValue={(template && template.title) || ''}
              data-testid="template-form-title"
              onChange={(e) => {
                const { value } = e.target
                setTemplate &&
                  setTemplate(
                    (prevState) =>
                      ({
                        ...prevState,
                        title: value,
                      }) as ReportTemplate
                  )
              }}
            />
          </Form.Item>
          <Form.Item name="description" label={intl.formatMessage(reports['reports.template.form.description.label'])}>
            <TextArea
              rows={4}
              placeholder={intl.formatMessage(reports['reports.template.form.description.placeholder'])}
              defaultValue={(template && template.description) || ''}
              data-testid="template-form-description"
              onChange={(e) => {
                const { value } = e.target
                setTemplate &&
                  setTemplate(
                    (prevState) =>
                      ({
                        ...prevState,
                        description: value,
                      }) as ReportTemplate
                  )
              }}
            />
          </Form.Item>
          <Form.Item
            name="availabillity"
            label={intl.formatMessage(reports['reports.template.form.availabillity.label'])}
          >
            <Checkbox
              onChange={(e) => {
                !template
                  ? setIsAvailableToAllEntityIds && setIsAvailableToAllEntityIds(e.target.checked)
                  : setTemplate &&
                    setTemplate(
                      (prevState) =>
                        ({
                          ...prevState,
                          availableToEntityIds:
                            e.target.checked === true ? [(user && user.profiles[0].entityId) || ''] : [],
                        }) as ReportTemplate
                    )
              }}
              data-testid="template-form-checkbox-available-to-all"
            >
              {intl.formatMessage(reports['reports.template.form.checkbox.availabillity.description'])}
            </Checkbox>
          </Form.Item>
          <Form.Item className="bh-form-item">
            <Button block type="primary" htmlType="submit" data-testid="create-update-template">
              {!template
                ? intl.formatMessage(action['action.reports.page.template.form.createTemplate'])
                : intl.formatMessage(action['action.reports.page.template.form.updateTemplate'])}
            </Button>
          </Form.Item>
        </Form>
      </ActionPage>
    </>
  )
}

export default TemplateForm
