import { createStyles } from 'antd-style'
import ssoIcon from 'assets/logo/sso.svg'

const useStyles = createStyles(({ css, token }) => {
  return {
    loginContainer: css`
      .ant-tabs-nav::before {
        border: none !important;
      }
      .ant-tabs-nav-wrap {
        display: flex;
        justify-content: center;
      }
      .ant-tabs-tab-active {
        display: none;
      }
      .ant-tabs-nav .ant-tabs-tab {
        border-radius: 10px !important;
        border: 1px solid lightgray;
        margin-right: 10px;
        height: ${token.controlHeightLG}px;
        background-color: white;
        width: 100%;
        margin-right: 0;

        .ant-tabs-tab-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
        }
      }
      .ant-tabs-nav {
        margin-top: 0 !important;
        border: none;
        display: block !important;
      }
      .ant-tabs-nav-list {
        width: 100%;
        .ant-tabs-tab:nth-child(2) {
          margin-left: 0 !important;
        }
      }
      .ant-tabs-nav-operations {
        display: none !important;
      }
    `,
    loginMethod: css`
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    `,
    sso: css`
      background-image: url(${ssoIcon});
    `,
    loginIcons: css`
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      height: 24px;
      width: 24px;
      margin-right: 10px;
    `,
    buttonContainer: css`
      display: flex;
      justify-content: center;
    `,
  }
})

export default useStyles
