import { PaymentInstructionTemplate, Recurrence } from 'types/paymentInstruction'
import { INTERVALS } from './constants'

export default function getRecurrenceDataFromTemplate(template: PaymentInstructionTemplate): Recurrence {
  const { occurrencesRemaining, interval } = template

  return {
    limit: !interval.infinity,
    occurrencesRemaining,
    interval: Object.keys(INTERVALS).find((key: string) => {
      const { unit, value } = INTERVALS[key].value
      return unit === interval.unit && value === interval.value
    }),
  }
}
