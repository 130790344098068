import { api } from './utils'
import { PdfRequest } from '../types/angelia'

const BASE_PATH = 'angelia'
export const createPDFReport = async (requestBody: PdfRequest): Promise<Blob> => {
  const url = `/${BASE_PATH}/pdf`
  const response = await api.post(url, requestBody)

  return await response.blob()
}

export const emailPDFReport = async (requestBody: PdfRequest): Promise<void> => {
  const url = `/${BASE_PATH}/pdf`
  await api.post(url, requestBody)
}
