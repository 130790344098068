import { Rules } from './rules'

export interface Entity {
  domicile?: Domicile
  id: string
  parentEntityId?: string
  countryCode?: string
  dateCreated?: string
  dateUpdated?: string
  dateLastLogin?: string
  slug?: string
  state?: string
  class?: Class
  children?: Entity[]
  entityRoles?: string[]
  disabled?: boolean
  name?: string
  companyRegistrationNumber?: string
  streetAddress?: string
  zipCode?: string
  city?: string
  mocked?: string
  dateMocked?: string
  _id?: string
  numberOfPayments?: number | string
  numberOfUsers?: number | string
  availableRoles?: string[]
  rules?: Rules
}

export interface Corp {
  cin: string
  title: string
  dateCreated?: string
  address?: {
    streetAddress?: string
    zip?: string
    countryCode?: string
    city?: string
    street?: string[]
  }
  subClass?: string
  additionalIdentifiers?: AdditionalIdentifiers
}
export interface Person {
  name?: {
    first?: string
    last?: string
  }
  address?: {
    streetAddress?: string
    zipCode?: string
    countryCode?: string
    city?: string
  }
  subClass?: string
}

export interface Class {
  corp?: Corp
  sme?: Corp
  person?: Person
}

interface AdditionalIdentifiers {
  bankingPartner: string
}

export enum Domicile {
  UK = 'bh_uk',
  AB = 'bh_ab',
}
