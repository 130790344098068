import React, { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { MenuProps, message, Tabs, TabsProps } from 'antd'
import { DownloadOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { History } from 'history'
import { useIntl } from 'react-intl'
import { PaymentInstruction, PaymentInstructionTemplate } from 'types/paymentInstruction'
import Page, { MobileMenuOption } from 'components/Page/Page'
import ActionPage from 'components/ActionPage/ActionPage'
import Filter, { FilterBody, FilterGroup } from 'components/Filter/Filter'
import ActionMenu, { Action, Group } from 'components/ActionMenu/ActionMenu'
import { PaymentRow } from './components/Tables/PiTable'
import { TemplateRow } from './components/Tables/TemplateTable'
import * as api from 'api/paymentInstruction'
import * as rulesApi from 'api/rules'
import PaymentInstructionDetails from './PaymentInstructionDetails'
import PaymentInstructionTemplateDetails from './PaymentInstructionTemplateDetails'
import { pi, action, filters, page } from 'lang/definitions/index'
import { getActiveUser } from 'utils/helpers'
import SecondaryMenu from 'components/SecondaryMenu/SecondaryMenu'
import FilterDropdown, { SelectedFilter } from 'components/FilterDropdown/FilterDropdown'
import { FilterState, QueryParams } from 'types/general'
import { uniqBy } from 'lodash'
import { DEVICE_TYPE, getDeviceType, SCREEN_SM, Size } from 'utils/getDeviceType'
import { useWindowSize } from 'hooks/useWindowSize'
import { downloadMultiplePaymentInstructionPDF } from 'api/pdf'
import { useHistory } from 'react-router-dom'
import { resetDrawerHash, setDrawerHash } from 'components/Drawers/utils'
import AlertMessagesContainer from 'components/AlertMessage/AlertMessagesContainer'
import CombinedPITable, { PaymentAndTemplateRow } from './components/Tables/CombinedPITable'
import { requiredSignaturesForAllCurrencies } from 'utils/menu'
import { useSession } from 'stores/session'
import usePageStyle from 'components/Page/Page.style'

interface Props {
  name: string
  history: History
}

interface FilterDropdownGroupProps {
  value: string
  label: string
  type: string
  options?: FilterDropdownOption[]
}

interface FilterDropdownOption {
  label: string
  value: string
  fixedFilters?: unknown[]
  drawerKey?: string
}

const PaymentInstructions = (props: Props): React.JSX.Element => {
  const history = useHistory()
  const intl = useIntl()
  const { styles: pageStyles } = usePageStyle()

  const size: Size = useWindowSize()

  const FILTER_BREAKPOINT = SCREEN_SM
  const { state: sessionState } = useSession()

  const user = sessionState.user!
  const rules = sessionState.rules!

  const { profileId, entityId, profile } = getActiveUser(user)

  const [activeTable, setActiveTable] = useState<string>('ongoing')
  const [mobileTablePaymentTypes, setMobileTablePaymentTypes] = useState<string>('pending')
  const [filterBody, setFilterBody] = useState<FilterBody>({})
  const [filterState, setFilterState] = useState<FilterState>()
  const [, setFixedFilters] = useState<FilterBody[]>([])
  const [showClearFilter, setShowClearFilter] = useState(false)
  const [loading, setLoading] = useState(false)

  const [mobileActionButtons, setMobileButtons] = useState<MobileMenuOption[]>([
    {
      id: 'filter-btn',
      label: intl.formatMessage(filters['filter.label']),
      callback: () => setDrawerHash(history, '#drawer-filters'),
      badge: {
        count: 1, // initially the payments are filtered by state: Processing
        color: '#8F223A',
        filter: true,
      },
      className: pageStyles.filterButtonWrapper,
    },
    {
      id: 'add-payments-btn',
      label: intl.formatMessage(action['action.pi.new.payment']),
      callback: () => props.history.push('/app/payments/add/input'),
      icon: <PlusOutlined />,
      type: 'primary',
      applicableEntityClasses: ['person', 'sme', 'corp'],
    },
  ])

  const [paymentInstruction, setPaymentInstruction] = useState<PaymentInstruction>()
  const [paymentInstructionTemplate, setPaymentInstructionTemplate] = useState<PaymentInstructionTemplate>()
  const [emptyMessage, setEmptyMessage] = useState<string>(intl.formatMessage(pi['pi.not.added']))

  const [currencyOptions, setCurrencyOptions] = useState<Array<string>>([])

  const [rowsToRemove, setRowsToRemove] = useState<string[]>([])
  const [filterGroups, setFilterGroups] = useState<Array<FilterGroup>>([])
  const [drawerFilterGroup, setDrawerFilterGroup] = useState<Array<FilterGroup>>([])
  const [actionGroups, setActionGroups] = useState<Array<Group>>([])

  const [isActionMenuOpen, setIsActionMenuOpen] = useState(false)
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [selectedPayments, setSelectedPayments] = useState<PaymentRow[] | TemplateRow[] | PaymentAndTemplateRow[]>([])

  const [downloadLoader, setDownloadLoader] = useState(false)
  // let drawerFilters = {}

  // ---------------- STATE END --------------------------------------

  // ---------------- Effects --------------------------------------
  // On 1st render
  useEffect(() => {
    // Set the filter gourps on render
    if (size.width! < FILTER_BREAKPOINT) {
      setFilterGroups([
        {
          align: 'left',
          id: 'static-pi-filter',
          elements: [
            {
              label: intl.formatMessage(filters['filter.search']),
              type: 'search',
              callback: (value) => handleFilterChange('search', value as string),
            },
          ],
        },
      ])

      setDrawerFilterGroup([
        {
          align: 'left',
          id: 'drawer-filter',
          elements: [
            {
              label: intl.formatMessage(filters['filter.currency']),
              type: 'select' as const,
              callback: (value) => handleFilterChange('currency', value as string),
              dataSource: currencyOptions.map((type: string, index: number) => {
                return { id: index, name: type.toUpperCase() }
              }),
              placeholder: intl.formatMessage(filters['filter.currency.placeholder']),
            },
            {
              label: intl.formatMessage(filters['filter.date']),
              type: 'datePicker',
              callback: (value) => handleFilterChange('date', value as string),
            },
          ],
        },
      ])
    } else {
      setFilterGroups([
        {
          align: 'left',
          id: 'static-pi-filter',
          elements: [
            {
              label: intl.formatMessage(filters['filter.date']),
              type: 'datePicker',
              callback: (value) => handleFilterChange('date', value as string),
            },
            {
              label: intl.formatMessage(filters['filter.search']),
              type: 'search',
              callback: (value) => handleFilterChange('search', value as string),
            },
            {
              label: intl.formatMessage(filters['filter.currency']),
              type: 'select' as const,
              callback: (value) => handleFilterChange('currency', value as string),
              dataSource: currencyOptions.map((type: string, index: number) => {
                return { id: index, name: type.toUpperCase() }
              }),
              placeholder: intl.formatMessage(filters['filter.currency.placeholder']),
            },
          ],
        },
      ])

      setDrawerFilterGroup([
        {
          align: 'left',
          id: 'drawer-filter',
          elements: [
            {
              label: intl.formatMessage(filters['filter.currency']),
              type: 'select' as const,
              callback: (value) => handleFilterChange('currency', value as string),
              dataSource: currencyOptions.map((type: string, index: number) => {
                return { id: index, name: type.toUpperCase() }
              }),
              placeholder: intl.formatMessage(filters['filter.currency.placeholder']),
            },
          ],
        },
      ])
    }
  }, [currencyOptions])

  useEffect(() => {
    if (filterBody && Object.keys(filterBody).length > 0) {
      setShowClearFilter(true)

      // Handle the count of Filters in Badge icon
      mobileActionButtons.map((button) => {
        if (button.id === 'filter-btn')
          button.badge = {
            color: '#8F223A',
            count: Object.keys(filterBody).filter((k) => k !== 'kind').length,
            filter: true,
          }
        return button
      })
    } else {
      setShowClearFilter(false)
    }
  }, [filterBody])

  //Fetch info about payments that should be reviewed or made
  const fetchShouldMakeReviewInfo = async (): Promise<void> => {
    setLoading(true)
    try {
      const readyState = 'ready'
      const params: QueryParams = {
        limit: 0,
      }
      const [paymentInstructionsReady, templatesReady, paymentInstructionsReview, templatesReview] = await Promise.all([
        api.countPaymentInstructionsByState(profileId, readyState),
        api.countPaymentInstructionTemplatesByState(profileId, readyState),
        api.getPaymentInstructionsRequiresSignature(entityId, params),
        api.getPaymentInstructionTemplatesRequiresSignature(entityId, params),
      ])
      const actions: Array<Action> = [
        {
          id: 'pay-btn',
          label: intl.formatMessage(action['action.pi.newpayment']),
          type: 'primary',
          callback: () => props.history.push('/app/payments/add/input'),
          requiredACL: [{ kind: 'paymentInstruction', action: 'create' }],
          applicableEntityClasses: ['person', 'sme', 'corp'],
        },
      ]

      if (paymentInstructionsReady > 0 || templatesReady > 0) {
        const makePaymentsBtn = {
          id: 'make-payments-btn',
          className: pageStyles.makePaymentsBtn,
          label: intl.formatMessage(action['action.pi.makepayments']),
          badge: {
            count: paymentInstructionsReady + templatesReady,
            color: '#8F223A',
          },
          callback: () => props.history.push('/app/payments/pay'),
          applicableEntityClasses: ['corp'],
          requiredRuleSettings: {
            kind: 'requiredSignChain',
            action: requiredSignaturesForAllCurrencies,
          },
        }

        const buttonsStateCopy = [...mobileActionButtons]
        const buttonIndex = buttonsStateCopy.findIndex((button) => button.id === makePaymentsBtn.id)

        if (buttonIndex !== -1) {
          buttonsStateCopy[buttonIndex] = makePaymentsBtn
          setMobileButtons(uniqBy(buttonsStateCopy, (button) => button.id))
        } else {
          const newButtonState = [...mobileActionButtons, makePaymentsBtn]
          setMobileButtons(uniqBy(newButtonState, (button) => button.id))
        }

        actions.push({
          label: intl.formatMessage(action['action.pi.makepayments']),
          type: 'button',
          badge: {
            count: paymentInstructionsReady + templatesReady,
            color: '#8F223A',
          },
          callback: () => props.history.push('/app/payments/pay'),
          applicableEntityClasses: ['corp'],
          requiredRuleSettings: {
            kind: 'requiredSignChain',
            action: requiredSignaturesForAllCurrencies,
          },
        })
      }

      if (paymentInstructionsReview.length > 0 || templatesReview.length > 0) {
        setActiveTable('actionRequired')
        setMobileTablePaymentTypes('action')

        const reviewPaymentsBtn = {
          id: 'review-payments-btn',
          className: pageStyles.reviewPaymentsBtn,
          label: intl.formatMessage(action['action.pi.reviewpayments']),
          badge: {
            count: paymentInstructionsReview.length + templatesReview.length,
            color: '#8F223A',
          },
          callback: () => props.history.push('/app/payments/review'),
          requiredACL: [{ kind: 'signature', action: 'create' }],
          applicableEntityClasses: ['corp'],
        }

        const buttonsStateCopy = [...mobileActionButtons]
        const buttonIndex = buttonsStateCopy.findIndex((button) => button.id === reviewPaymentsBtn.id)

        if (buttonIndex > -1) {
          buttonsStateCopy[buttonIndex] = reviewPaymentsBtn
          setMobileButtons(uniqBy(buttonsStateCopy, (button) => button.id))
        } else {
          const newButtonState = [...mobileActionButtons, reviewPaymentsBtn]
          setMobileButtons(uniqBy(newButtonState, (button) => button.id))
        }

        actions.push({
          id: 'review-payments-btn',
          label: intl.formatMessage(action['action.pi.reviewpayments']),
          type: 'button',
          badge: {
            count: paymentInstructionsReview.length + templatesReview.length,
            color: '#8F223A',
          },
          callback: () => props.history.push('/app/payments/review'),
          requiredACL: [{ kind: 'signature', action: 'create' }],
          applicableEntityClasses: ['corp'],
        })
      }
      // Set the action buttons
      setActionGroups([
        {
          id: 'payment-default',
          actions: actions,
        },
      ])
      setLoading(false)
    } catch (error) {
      setLoading(false)
      Sentry.captureException(error)
    }
  }

  useEffect(() => {
    void getCurrencyOptions()
  }, [rules])

  useEffect(() => {
    void fetchShouldMakeReviewInfo()
  }, [rules, intl])

  useEffect(() => {
    if (activeTable) {
      setSelectedPayments([])
      setRowsToRemove([])
    }
  }, [activeTable])

  const isTemplate = (payment: PaymentRow | TemplateRow | PaymentAndTemplateRow): payment is TemplateRow => {
    if ((payment as TemplateRow).paymentInstructionProperties) {
      return true
    }
    return false
  }

  useEffect(() => {
    // If there is a selection, show additional buttons in the filter
    setActionGroups((prev) => {
      const filtered = (prev || []).filter((el) => el.id !== 'payment-selected')

      if (selectedPayments.length) {
        let paymentIds: string[] = []
        let state = ''
        let isDownloadReceiptAvailable = false

        if (isTemplate(selectedPayments[0])) {
          let payments = [...selectedPayments] as TemplateRow[]
          payments = payments.filter((payment: TemplateRow) =>
            ['ACTIVE', 'REQUIRE_SIGNATURE', 'READY'].includes(payment.state)
          )
          state = payments.length ? payments[0].state : ''
          paymentIds = payments.map((payment: TemplateRow) => payment.id)
        } else {
          let payments = [...selectedPayments] as PaymentRow[]
          isDownloadReceiptAvailable = !!payments.filter((payment: PaymentRow) =>
            ['PENDING', 'BANK', 'PAID'].includes(payment.state)
          ).length

          payments = payments.filter((payment: PaymentRow) =>
            ['PENDING', 'REQUIRE_SIGNATURE', 'READY'].includes(payment.state)
          )

          state = payments.length ? payments[0].state : ''
          paymentIds = payments.map((payment: PaymentRow) => payment.id)
        }

        const refundButtonLabel =
          selectedPayments.length && state === 'PENDING'
            ? intl.formatMessage(action['action.pi.refund'])
            : intl.formatMessage(action['action.pi.delete'])

        let actions: Action[] = []

        if (isDownloadReceiptAvailable) {
          actions = [
            {
              id: 'share',
              type: 'dropdown-button',
              label: intl.formatMessage(action['action.pi.download']),
              elementType: 'default',
              loading: downloadLoader,
              menu: downloadMenu,
              badge: { count: selectedPayments.length, color: 'green' },
              requiredACL: [{ kind: 'paymentInstruction', action: 'read' }],
            },
          ]
        }

        if (paymentIds.length) {
          actions = [
            {
              id: 'refund',
              type: 'button',
              label: refundButtonLabel,
              elementType: 'danger',
              badge: { count: paymentIds.length },
              disabled: !paymentIds.length,
              callback: () => {
                setRowsToRemove(paymentIds)
              },
              requiredACL: [{ kind: 'paymentInstruction', action: 'update' }],
            },
            ...actions,
          ]
        }
        filtered.push({
          id: 'payment-selected',
          actions,
        })
        return [...filtered]
      } else {
        return filtered
      }
    })
  }, [selectedPayments, downloadLoader])

  // dynamically change active tab if existing in hash url
  useEffect(() => {
    const hash = window.location.hash
    const hashArray = hash.split('?activeTab=')
    const activeTab = hashArray[1]
    activeTab && void onTabClick(activeTab)
  }, [window.location.hash])

  // ---------------- Effects END --------------------------------------

  const downloadMenu: MenuProps['items'] = [
    {
      key: '1',
      label: intl.formatMessage(pi['pi.details.receiptOption.withFees']),
      onClick: () => void handleDownloadReceipts('external-receipt'),
    },
    {
      key: '2',
      label: intl.formatMessage(pi['pi.details.receiptOption.withoutFees']),
      onClick: () => void handleDownloadReceipts('internal-receipt'),
    },
    {
      key: '3',
      label: intl.formatMessage(pi['pi.details.receiptOption.remittanceAdvice']),
      onClick: () => void handleDownloadReceipts('remittance-advice'),
    },
  ]

  const handleDownloadReceipts = async (reportType: string): Promise<void> => {
    const key = 'updatable'
    void message.loading({
      content: intl.formatMessage(action['action.pi.page.download.progress']),
      key,
    })

    const classType = Object.keys(profile?.entity?.class || [])
    setDownloadLoader(true)

    try {
      const selected = [...(selectedPayments as PaymentRow[])]
      const paymentInstructionIds = selected.map((payment) => payment.id)
      const response = await downloadMultiplePaymentInstructionPDF(paymentInstructionIds, reportType, classType[0])

      void message.success({
        content: intl.formatMessage(action['action.pi.page.download.finished']),
        key,
        duration: 2,
      })

      let fileName = 'billhop-receipt-multi'
      if (reportType === 'remittance-advice') {
        fileName = 'billhop-remittance-advice-multi'
      }

      const urls = window.URL.createObjectURL(new Blob([response]))
      const link = document.createElement('a')
      link.href = urls
      link.setAttribute('download', `${fileName}.pdf`)
      link.click()
    } catch (error) {
      Sentry.captureException(error)
    } finally {
      setDownloadLoader(false)
    }
  }

  const handleFilterChange = (valueType: string, value: string | string[]) => {
    switch (valueType) {
      case 'date':
        if (Array.isArray(value)) {
          setFilterState((state) => ({
            ...state,
            dateDue: {
              from: value[0],
              to: value[1],
            },
          }))
          if (value[0] && value[1]) {
            setFilterBody((prev) => {
              return {
                ...prev,
                dateDue: {
                  from: value[0],
                  to: value[1],
                },
              }
            })
          } else {
            setFilterBody((prev) => {
              delete prev.dateDue
              return { ...prev }
            })
          }
        } else {
          setFilterBody((prev) => {
            delete prev.dateDue
            return { ...prev }
          })
        }
        break
      case 'currency':
        setFilterState((state) => ({
          ...state,
          currency: value as string,
        }))
        setFilterBody((prev) => ({
          ...prev,
          currency: typeof value === 'string' ? value.toLowerCase() : '',
        }))
        if (!value) {
          setFilterBody((prev) => {
            delete prev.currency
            return prev
          })
        }
        break
      case 'search':
        setFilterState((state) => ({
          ...state,
          search: value as string,
        }))
        setFilterBody((prev) => ({
          ...prev,
          q: value as string,
        }))
        if (!value) {
          setFilterBody((prev) => {
            delete prev.q
            return prev
          })
          setEmptyMessage(intl.formatMessage(pi['pi.not.added']))
        } else {
          setEmptyMessage(intl.formatMessage(pi['pi.not.found']))
        }
        break
      default:
        // Nothing
        break
    }
  }

  const handleSetFilterState = (value: string, key: string): void => {
    setFilterState((state) => ({
      ...state,
      [key]: value,
    }))
  }

  const handleClearFilters = (): void => {
    handleFilterChange('search', '')
    handleFilterChange('currency', '')
    handleFilterChange('date', '')
    setFilterState((state) => ({
      ...state,
      search: '',
    }))
    setFilterState((state) => ({
      ...state,
      currency: undefined,
    }))
    setFilterState((state) => ({
      ...state,
      dateDue: {
        from: '',
        to: '',
      },
    }))
  }

  const secondMenuGroup = [
    {
      align: 'center',
      id: 'second-group-filter',
      elements: [
        {
          label: intl.formatMessage(filters['filter.clear']),
          type: 'button' as const,
          icon: <CloseOutlined />,
          visibility: showClearFilter,
          callback: () => handleClearFilters(),
        },
        {
          label: intl.formatMessage(action['action.pi.reports']),
          type: 'button' as const,
          icon: <DownloadOutlined />,
          visibility: true,
          callback: () => props.history.push('/app/reports'),
        },
      ],
    },
  ]

  const secondaryMenu = (
    <SecondaryMenu label={intl.formatMessage(action['action.pi.page.second.action.title'])} groups={secondMenuGroup} />
  )

  const actionMenu = (
    <ActionMenu
      isOpen={isActionMenuOpen}
      groups={actionGroups}
      label={intl.formatMessage(action['action.pi.page.title'])}
      closeMenu={() => setIsActionMenuOpen(false)}
    />
  )

  const filter = (
    <Filter
      isOpen={isFilterOpen}
      label={intl.formatMessage(filters['filter.pi.page.title'])}
      closeFilter={() => setIsFilterOpen(false)}
      groups={filterGroups}
      filterState={filterState}
      setFilterState={handleSetFilterState}
    />
  )

  const filterDropdownGroups: FilterDropdownGroupProps[] = [
    {
      value: 'status',
      label: intl.formatMessage(pi['pi.status']),
      type: 'radio',
      options: [
        {
          value: 'action',
          label: intl.formatMessage(pi['pi.state.actionRequired']),
          drawerKey: 'actionRequired',
        },
        {
          value: 'pending',
          label: intl.formatMessage(pi['pi.state.ongoing']),
          drawerKey: 'ongoing',
        },
        {
          value: 'complete',
          label: intl.formatMessage(pi['pi.state.complete']),
          drawerKey: 'complete',
        },
        {
          value: 'cancelled',
          label: intl.formatMessage(pi['pi.state.cancel']),
          drawerKey: 'canceled',
        },
        {
          value: 'error',
          label: intl.formatMessage(pi['pi.state.inReview']),
          drawerKey: 'In review',
        },
      ],
    },
    {
      value: 'currency',
      label: intl.formatMessage(pi['pi.currency']),
      type: 'radio',
      options: [
        {
          label: intl.formatMessage(pi['pi.state.all']),
          value: 'all',
        },
        ...currencyOptions.map((option: string) => {
          return {
            label: option.toUpperCase(),
            value: option,
          }
        }),
      ],
    },
    {
      value: 'datepicker',
      label: 'Date range',
      type: 'datepicker',
    },
  ]

  const handleApplyFilters = (filters: SelectedFilter): void => {
    const newFilterBody: FilterBody = { ...(filterBody || {}) }
    if (filters.currency) {
      newFilterBody.currency = filters.currency
    } else {
      delete newFilterBody.currency
    }
    if (filters.dateDue) {
      newFilterBody.dateDue = {
        from: filters['from'] || '',
        to: filters['to'] || '',
      }
    } else {
      delete newFilterBody.dateDue
    }

    if (filters.status) {
      setMobileTablePaymentTypes(filters.status)
    }

    setFilterBody((prev) => {
      return {
        ...prev,
        ...newFilterBody,
      }
    })
  }

  const drawerFilters = (
    <React.Fragment>
      {getDeviceType(size) === DEVICE_TYPE.MOBILE ? (
        <div className="mobile-display">
          <FilterDropdown
            closeFilter={() => resetDrawerHash(history)}
            groups={filterDropdownGroups}
            applyFilter={handleApplyFilters}
            setFixedFilters={setFixedFilters}
            preselectedFilters={filterBody}
            setActiveTab={setActiveTable}
            paymentTypes={mobileTablePaymentTypes}
          />
        </div>
      ) : (
        <div className="desktop-display">
          <Filter
            isOpen={isFilterOpen}
            label={intl.formatMessage(filters['filter.drawer.label'])}
            closeFilter={() => setIsFilterOpen(false)}
            groups={drawerFilterGroup}
            filterState={filterState}
            setFilterState={handleSetFilterState}
          />
        </div>
      )}
    </React.Fragment>
  )

  const getCurrencyOptions = async (): Promise<void> => {
    try {
      const currencies = await rulesApi.getCurrencies()
      if (JSON.stringify(currencies) !== JSON.stringify(currencyOptions)) {
        setCurrencyOptions(currencies)
      }
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const onTabClick = (type: string) => {
    // Push the tabel the url
    setActiveTable(type)
  }

  const onRowClick = (
    _event: React.MouseEvent<HTMLElement, MouseEvent> | undefined,
    record: PaymentRow | TemplateRow | PaymentAndTemplateRow
  ) => {
    const paymentRecord = record as PaymentRow
    const templateRecord = record as TemplateRow
    if (templateRecord?.paymentInstructionProperties) {
      // It's a template
      setPaymentInstructionTemplate(templateRecord as PaymentInstructionTemplate)
      setPaymentInstruction(undefined)
    } else {
      // It's a payment instruction
      setPaymentInstruction(paymentRecord as PaymentInstruction)
      setPaymentInstructionTemplate(undefined)
    }
    // activeTab used for dynamically loading tabs & data when opening drawers from hash url
    record?.id && setDrawerHash(history, `#drawer-details?key=${record.id}?activeTab=${activeTable}`)
  }

  const handleSelectPayments = (selectedPayments: PaymentRow[] | TemplateRow[] | PaymentAndTemplateRow[]): void => {
    setSelectedPayments(selectedPayments)
  }

  const handleRemovePayment = (id: string): void => {
    setRowsToRemove([id])
    setPaymentInstruction(undefined)
    setPaymentInstructionTemplate(undefined)
    resetDrawerHash(history)
  }

  const mobileSearchFilter: MobileMenuOption[] = [
    {
      label: intl.formatMessage(filters['filter.search']),
      searchCallback: (value) => handleFilterChange('search', value),
    },
  ]
  const createPITableProps = (
    activeKey: string,
    piStates: string[],
    templateStates: string[],
    typeOfPayment: string
  ) => {
    return {
      emptyMessage,
      isActiveTable: activeTable === activeKey,
      onRowClick,
      filter: filterBody,
      piStates,
      templateStates,
      typeOfPayment,
      rowsToRemove,
      setRowsToRemove,
      setSelectedPayments: handleSelectPayments,
      setPaymentInstruction,
      setPaymentInstructionTemplate,
    }
  }

  const items: TabsProps['items'] = [
    {
      key: 'actionRequired',
      label: (
        <div className="tab-pane-wrapper">
          <span>{intl.formatMessage(pi['pi.state.actionRequired'])}</span>
        </div>
      ),
      children: (
        <CombinedPITable
          {...createPITableProps(
            'actionRequired',
            ['REQUIRE_SIGNATURE', 'READY'],
            ['READY', 'REQUIRE_SIGNATURE'],
            'action'
          )}
        />
      ),
    },
    {
      key: 'ongoing',
      label: (
        <div className="tab-pane-wrapper">
          <span>{intl.formatMessage(pi['pi.state.ongoing'])}</span>
        </div>
      ),
      children: (
        <CombinedPITable
          {...createPITableProps(
            'ongoing',
            ['PENDING', 'BANK', 'PROCESSING', 'PENDING_AUTHORIZATION'],
            ['ACTIVE'],
            'pending'
          )}
        />
      ),
    },
    {
      key: 'complete',
      label: (
        <div className="tab-pane-wrapper">
          <span>{intl.formatMessage(pi['pi.state.complete'])}</span>
        </div>
      ),
      children: <CombinedPITable {...createPITableProps('complete', ['PAID'], [], 'complete')} />,
    },
    {
      key: 'cancelled',
      label: (
        <div className="tab-pane-wrapper">
          <span>{intl.formatMessage(pi['pi.state.cancel'])}</span>
        </div>
      ),
      children: (
        <CombinedPITable
          {...createPITableProps('cancelled', ['CANCELED', 'DELETED'], ['CANCELED', 'DELETED'], 'cancelled')}
        />
      ),
    },
    {
      key: 'error',
      label: (
        <div className="tab-pane-wrapper">
          <span>{intl.formatMessage(pi['pi.state.inReview'])}</span>
        </div>
      ),
      children: <CombinedPITable {...createPITableProps('error', ['ERROR'], ['ERROR'], 'error')} />,
    },
  ]

  const tableTabs = (
    <Tabs
      animated={false}
      onTabClick={onTabClick}
      className="table-tabs-wrapper"
      activeKey={activeTable}
      items={items}
    />
  )

  return (
    <React.Fragment>
      <AlertMessagesContainer placement="overview" />
      <Page
        title={intl.formatMessage(page['page.pi.title'])}
        mobileMenuOptions={mobileActionButtons}
        mobileSearch={mobileSearchFilter}
        actionMenu={actionMenu}
        filter={filter}
        secondaryMenu={secondaryMenu}
      >
        {!loading ? (
          <React.Fragment>
            {getDeviceType(size) === DEVICE_TYPE.MOBILE ? (
              <div className="mobile-display">
                {/* use template table for future payments ie. templates */}
                <CombinedPITable
                  isActiveTable={true}
                  emptyMessage={emptyMessage}
                  onRowClick={onRowClick}
                  filter={filterBody}
                  piStates={[]}
                  templateStates={[]}
                  typeOfPayment={mobileTablePaymentTypes}
                  rowsToRemove={rowsToRemove}
                  setRowsToRemove={setRowsToRemove}
                  setSelectedPayments={handleSelectPayments}
                  setPaymentInstruction={setPaymentInstruction}
                  setPaymentInstructionTemplate={setPaymentInstructionTemplate}
                />
              </div>
            ) : (
              <div className="desktop-display">{tableTabs}</div>
            )}
            <ActionPage
              title={intl.formatMessage(page['page.pi.slider.label.detail'])}
              hash={`#drawer-details?key=${
                paymentInstruction?.id || paymentInstructionTemplate?.id || ''
              }?activeTab=${activeTable}`}
              pathname={history.location.pathname}
              additionalClass="drawer-with-bg"
            >
              {paymentInstruction && (
                <PaymentInstructionDetails
                  paymentInstructionId={paymentInstruction.id!}
                  handleRemovePayment={handleRemovePayment}
                />
              )}
              {paymentInstructionTemplate && (
                <PaymentInstructionTemplateDetails
                  templateId={paymentInstructionTemplate.id!}
                  handleRemovePayment={handleRemovePayment}
                />
              )}
            </ActionPage>
            <ActionPage
              title={intl.formatMessage(page['page.pi.filter.drawer.title'])}
              hash="#drawer-filters"
              pathname={history.location.pathname}
              additionalClass="drawer-with-bg"
            >
              {drawerFilters}
            </ActionPage>
          </React.Fragment>
        ) : (
          <></>
        )}
      </Page>
    </React.Fragment>
  )
}

export default PaymentInstructions
