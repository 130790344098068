import { createStyles } from 'antd-style'
import loginImage from 'assets/login-image.svg'

const useStyles = createStyles(({ css }) => {
  return {
    splitContainer: css`
      display: flex;
    `,
    leftSideSplit: css`
      flex: 1;
      background-image: url(${loginImage});
      background-position: center;
      background-color: #f0f0f0;
    `,
    rightSideSplit: css`
      flex: 1;
    `,
  }
})

export default useStyles
