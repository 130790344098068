export interface Dbi {
  name: string
  label: string
  translationKey: string
  type: string
  pattern: string
  min: number
  max: number
  tooltipMessageKey?: string
}

export enum VcnProvider {
  AIRPLUS = 'airplus',
}

export interface DbiFormItem extends Dbi {
  required: boolean
}
