import { Rules } from 'types/rules'

export default function getPaymentLimit(rules: Rules, currency: string): number {
  const { limitPaymentInstruction } = rules.logic

  const rule = limitPaymentInstruction.rule.find(
    (rule: { currency: string; limit: number }) => rule.currency === currency
  )

  return rule?.limit || Infinity
}
