import React, { ErrorInfo } from 'react'
import { FallbackProps, ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'
import * as Sentry from '@sentry/react'
import { v4 as uuidv4 } from 'uuid'
import ErrorFallbackComponent from './ErrorFallbackComponent'

interface Props {
  children: React.ReactNode
}

enum SentryKeys {
  ERROR_BOUNDARY_DISPLAYED = 'errorBoundaryDisplayed',
  ERROR_ID = 'errorId',
  COMPONENT_STACK = 'componentStack',
}

const ErrorBoundary: React.FC<Props> = ({ children }) => {
  const errorId = uuidv4()

  const onErrorCapture = (error: Error, errorInfo: ErrorInfo): void => {
    Sentry.withScope((scope) => {
      scope.setTag(SentryKeys.ERROR_BOUNDARY_DISPLAYED, true)
      scope.setTag(SentryKeys.ERROR_ID, errorId)
      scope.setExtra(SentryKeys.COMPONENT_STACK, errorInfo.componentStack)
      Sentry.captureException(error)
    })
  }

  const onPageReload = (): void => {
    window.location.reload()
  }

  return (
    <ReactErrorBoundary
      FallbackComponent={({ resetErrorBoundary }: FallbackProps) => {
        return <ErrorFallbackComponent errorId={errorId} resetErrorBoundary={resetErrorBoundary} />
      }}
      onError={onErrorCapture}
      onReset={onPageReload}
    >
      {children}
    </ReactErrorBoundary>
  )
}

export default ErrorBoundary
