import { defineMessages } from 'react-intl'

export const activity = defineMessages({
  'activity.event.user.create': {
    id: 'activity.event.user.create',
    defaultMessage: 'Created user',
    description: 'Label for user.create',
  },
  'activity.event.entity.create': {
    id: 'activity.event.entity.create',
    defaultMessage: 'Created entity',
    description: 'Label for entity.create',
  },
  'activity.event.beneficiary.create': {
    id: 'activity.event.beneficiary.create',
    defaultMessage: 'Created beneficiary',
    description: 'Label for beneficiary.create',
  },
  'activity.event.paymentInstruction.create': {
    id: 'activity.event.paymentInstruction.create',
    defaultMessage: 'Created payment instruction',
    description: 'Label for paymentInstruction.create',
  },
  'activity.event.paymentInstructionTemplate.create': {
    id: 'activity.event.paymentInstructionTemplate.create',
    defaultMessage: 'Created payment instruction template',
    description: 'Label for paymentInstructionTemplate.create',
  },
  'activity.event.report.create': {
    id: 'activity.event.report.create',
    defaultMessage: 'Created report',
    description: 'Label for report.create',
  },
  'activity.event.import.create': {
    id: 'activity.event.import.create',
    defaultMessage: 'Created import',
    description: 'Label for import.create',
  },
  'activity.event.source.create': {
    id: 'activity.event.source.create',
    defaultMessage: 'Created source',
    description: 'Label for source.create',
  },
  'activity.event.signatureRequest.create': {
    id: 'activity.event.signatureRequest.create',
    defaultMessage: 'Created signature request',
    description: 'Label for signatureRequest.create',
  },
  'activity.event.signature.create': {
    id: 'activity.event.signature.create',
    defaultMessage: 'Created signature',
    description: 'Label for signature.create',
  },
  'activity.event.passwordResetRequest.create': {
    id: 'activity.event.passwordResetRequest.create',
    defaultMessage: 'Created password reset request',
    description: 'Label for passwordResetRequest.create',
  },
  'activity.event.charge.success': {
    id: 'activity.event.charge.success',
    defaultMessage: 'Charge successfull',
    description: 'Label for charge.success',
  },
  'activity.event.charge.fail': {
    id: 'activity.event.charge.fail',
    defaultMessage: 'Charge failed',
    description: 'Label for charge.fail',
  },
  'activity.event.entityRole.update': {
    id: 'activity.event.entityRole.update',
    defaultMessage: 'Updated entity role',
    description: 'Label for entityRole.update',
  },
  'activity.event.user.login': {
    id: 'activity.event.user.login',
    defaultMessage: 'Logged in',
    description: 'Label for user.login',
  },
  'activity.not.added': {
    id: 'activity.not.added',
    defaultMessage: 'No activities added',
    description: 'No activities added message',
  },
  'activity.event.drawer.details.title': {
    id: 'activity.event.drawer.details.title',
    defaultMessage: 'Event details',
    description: 'Label for setting event details drawer title',
  },
  'activity.event.login.text': {
    id: 'activity.event.login.text',
    defaultMessage: 'User {userValue} logged in to {eventValue}',
    description: 'Label for setting login event details drawer text',
  },
  'activity.event.create.user.text': {
    id: 'activity.event.create.user.text',
    defaultMessage: 'User {userValue} created a new user',
    description: 'Label for setting create user event details drawer text',
  },
  'activity.event.create.entity.text': {
    id: 'activity.event.create.entity.text',
    defaultMessage: 'User {userValue} created a new entity',
    description: 'Label for setting create entity event details drawer text',
  },
  'activity.event.update.entityRoles.text': {
    id: 'activity.event.update.entityRoles.text',
    defaultMessage: 'User {createdByValue} updated {userValue} roles to: {entityRolesValue} for {entityValue} entity',
    description: 'Label for setting update entity roles event details drawer text',
  },
  'activity.report.create.fileName': {
    id: 'activity.report.create.fileName',
    defaultMessage: 'File name',
    description: 'Label for setting report event details filename text',
  },
  'activity.report.create.delivery.email': {
    id: 'activity.report.create.delivery.email',
    defaultMessage: 'Delivered via email to:',
    description: 'Label for setting report event details email delivery text',
  },
  'activity.report.create.delivery.download': {
    id: 'activity.report.create.delivery.download',
    defaultMessage: 'Delivered -> download',
    description: 'Label for setting report event details download delivery text',
  },
  'activity.details.createdBy': {
    id: 'activity.details.createdBy',
    defaultMessage: 'Created by',
    description: 'This string sets created by label on event details forms',
  },
  'activity.details.entity': {
    id: 'activity.details.entity',
    defaultMessage: 'Entity',
    description: 'This string sets entity label on event details forms',
  },
  'activity.details.entity.type': {
    id: 'activity.details.entity.type',
    defaultMessage: 'Entity type',
    description: 'This string sets entity type label on event details forms',
  },
  'activity.import.details.total': {
    id: 'activity.import.details.total',
    defaultMessage: 'Total imports',
    description: 'This string sets total label on import event details forms',
  },
  'activity.import.details.successfull': {
    id: 'activity.import.details.successfull',
    defaultMessage: 'Successfull imports',
    description: 'This string sets successfull label on import event details forms',
  },
  'activity.import.details.unsuccessfull': {
    id: 'activity.import.details.unsuccessfull',
    defaultMessage: 'Unsuccessfull imports',
    description: 'This string sets unsuccessfull label on import event details forms',
  },
  'activity.import.details.payments': {
    id: 'activity.import.details.payments',
    defaultMessage: 'Payments imports',
    description: 'This string sets payments label on import event details forms',
  },
})
