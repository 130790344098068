import { getSessionId } from 'utils/storage'
import { SessionState } from '../types'

const initialState: SessionState = {
  sessionId: getSessionId(),
  user: undefined,
  rules: undefined,
  iam: undefined,
  isB2BUser: undefined,
}

export default initialState
