import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css, token }) => ({
  container: css`
    overflow: auto;
    height: 100vh;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
  `,
  addPaymentsButton: css`
    margin-top: 15px;
    display: flex;
    justify-content: center;
  `,
  noDataMessage: css`
    text-align: center;
    margin-top: 20px;
    color: rgba(0, 0, 0, 0.65);
    font-size: ${token.fontSizeSM}px;
  `,
}))

export default useStyles
