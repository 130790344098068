import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css, token }) => {
  return {
    accountTitle: css`
      font-weight: 500;
      color: '#949393';
      text-align: center;
      font-size: ${token.fontSizeSM}px;
    `,
    accountUser: css`
      border: 1px solid #f0f0f0;
      border-radius: 10px;
      margin-bottom: 12px;
      margin-left: 10px;
      margin-right: 10px;
      cursor: pointer;
      padding: 12px;
    `,
    accountList: css`
      max-width: 400px;
      text-align: center;
      max-height: 50vh;
      overflow-y: auto;
      border-radius: ${token.borderRadius}px;
      margin-bottom: ${token.marginSM}px;
      margin-left: 10px;
      margin-right: 10px;
    `,
    chooseAccount: css`
      max-width: 300px;
      margin-top: ${token.margin}px;
      margin-bottom: ${token.marginSM}px;
    `,
    accountName: css`
      font-weight: 500;
      font-size: 0.9rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 12px;
    `,
    accountId: css`
      font-weight: 400;
      color: #949393;
      font-size: 0.8rem;
    `,
    leftSide: css`
      flex: 0.9;

      background-image: url('../../assets/login-image.svg');
      background-position: center;
      background-color: #f0f0f0;
    `,
    rightSide: css`
      flex: 1;
    `,
    changeDefaultTitle: css`
      font-size: 0.8rem;
      color: #949393;
      font-weight: 400;
    `,
    splitFlex: css`
      display: flex;
      justify-content: center;
    `,
    bhTrackClassName: css`
      .ant-page-header-heading-title {
        font-size: ${token.fontSizeSM}px !important;
      }
    `,
  }
})

export default useStyles
