import {
  PaymentFormData,
  PaymentInstruction,
  PaymentInstructionReferencePrefixAndSuffixLength,
} from 'types/paymentInstruction'
import { convertAmountCentsToForm } from '.'
import { extractPrefixAndSuffixFromReference } from './extractPrefixAndSuffixFromReference'

export default function mapPaymentInstructionToForm(
  pi: PaymentInstruction,
  prefixAndSuffix: PaymentInstructionReferencePrefixAndSuffixLength
): PaymentFormData {
  const { beneficiaryId, reference, amount, currency, remittanceAdvice } = pi

  const referenceString = reference.join(', ')

  const referenceWithoutPrefixAndSuffix = extractPrefixAndSuffixFromReference(referenceString, prefixAndSuffix)

  return {
    beneficiaryId,
    reference: referenceWithoutPrefixAndSuffix,
    amount: convertAmountCentsToForm(amount),
    currency,
    sendRemittance: (remittanceAdvice?.emails?.length || 0) > 0,
    remittanceAdvice: remittanceAdvice?.emails?.[0],
    recurring: false,
  }
}
