import React from 'react'
import { useIntl } from 'react-intl'
import { page } from 'lang/definitions/index'

const Settings = (): React.JSX.Element => {
  const intl = useIntl()

  return <div>{intl.formatMessage(page['page.admin.settings.title'])}</div>
}

export default Settings
