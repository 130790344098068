import { defineMessages } from 'react-intl'

export const filters = defineMessages({
  'filter.label': {
    id: 'filter.label',
    defaultMessage: 'Filter',
    description: 'Label for Filter button',
  },
  'filter.apply': {
    id: 'filter.apply',
    defaultMessage: 'Apply Filters',
    description: 'Label for Apply Filters button',
  },
  'filter.clear': {
    id: 'filter.clear',
    defaultMessage: 'Clear Filters',
    description: 'Label for Clear Filters button',
  },
  'filter.pi.page.title': {
    id: 'filter.pi.page.title',
    defaultMessage: 'Filter your payments',
    description: 'Title for PI filter page (mobile)',
  },
  'filter.date': {
    id: 'filter.date',
    defaultMessage: 'Date',
    description: 'Label for date filter (mobile)',
  },
  'filter.search': {
    id: 'filter.search',
    defaultMessage: 'Search',
    description: 'Label for text search filter (mobile)',
  },
  'filter.search.payments.placeholder': {
    id: 'filter.search.payments.placeholder',
    defaultMessage: 'Search your payments',
    description: 'Label for text search filter (mobile)',
  },
  'filter.createdBy': {
    id: 'filter.createdBy',
    defaultMessage: 'Created by',
    description: 'Label for createdBy filter (mobile)',
  },
  'filter.createdBy.placeholder': {
    id: 'filter.createdBy.placeholder',
    defaultMessage: 'Created by',
    description: 'Placeholder text for createdBy filter',
  },
  'filter.status': {
    id: 'filter.status',
    defaultMessage: 'Status',
    description: 'Label for status filter (mobile)',
  },
  'filter.status.placeholder': {
    id: 'filter.status.placeholder',
    defaultMessage: 'Status',
    description: 'Placeholder text for status filter',
  },
  'filter.recipient.placeholder': {
    id: 'filter.recipient.placeholder',
    defaultMessage: 'Recipient',
    description: 'Placeholder text for recipient filter',
  },
  'filter.currency': {
    id: 'filter.currency',
    defaultMessage: 'Currency',
    description: 'Label for currency filter (mobile)',
  },
  'filter.currency.placeholder': {
    id: 'filter.currency.placeholder',
    defaultMessage: 'Currency',
    description: 'Placeholder text for currency filter',
  },
  'filter.search.placeholder': {
    id: 'filter.search.placeholder',
    defaultMessage: 'Search',
    description: 'Placeholder text for search filter',
  },
  'filter.beneficiary.page.title': {
    id: 'filter.beneficiary.page.title',
    defaultMessage: 'Filter your beneficiaries',
    description: 'Title for beneficiary filter page (mobile)',
  },
  'filter.cards.page.title': {
    id: 'filter.cards.page.title',
    defaultMessage: 'Filter your cards',
    description: 'Title for cards filter page (mobile)',
  },
  'filter.accessRights': {
    id: 'filter.accessRights',
    defaultMessage: 'Access rights',
    description: 'Label for access rights filter',
  },
  'filter.entity': {
    id: 'filter.entity',
    defaultMessage: 'Entity',
    description: 'Label for entity filter (mobile)',
  },
  'filter.entity.placeholder': {
    id: 'filter.entity.placeholder',
    defaultMessage: 'Entities',
    description: 'Placeholder for entity filter (mobile)',
  },
  'filter.country': {
    id: 'filter.country',
    defaultMessage: 'Country',
    description: 'Placeholder for country filter (mobile)',
  },
  'filter.users.label': {
    id: 'filter.users.label',
    defaultMessage: 'Users filter',
    description: 'Label for users filter (mobile)',
  },
  'filter.user': {
    id: 'filter.user',
    defaultMessage: 'User',
    description: 'Label for user filter',
  },
  'filter.user.placeholder': {
    id: 'filter.user.placeholder',
    defaultMessage: 'Users',
    description: 'Placeholder for user filter',
  },
  'filter.activity': {
    id: 'filter.activity',
    defaultMessage: 'Activity',
    description: 'Label for activity filter',
  },
  'filter.activity.placeholder': {
    id: 'filter.activity.placeholder',
    defaultMessage: 'Activities',
    description: 'Placeholder for activity filter',
  },
  'filter.drawer.label': {
    id: 'filter.drawer.label',
    defaultMessage: 'Filters',
    description: 'Label for drawer filter',
  },
  'filter.date.range.start.date.placeholder': {
    id: 'filter.date.range.start.date.placeholder',
    defaultMessage: 'Start date',
    description: 'Placeholder text for start date range filter',
  },
  'filter.date.range.end.date.placeholder': {
    id: 'filter.date.range.end.date.placeholder',
    defaultMessage: 'End date',
    description: 'Placeholder text for end date range filter',
  },
  'filter.search.noResults': {
    id: 'filter.search.noResults',
    defaultMessage: 'No results found. Please try again.',
    description: 'Label for when there are no search results',
  },
})
