import { Form, Input } from 'antd'
import useSuffix from 'pages/Beneficiaries/RecipientsDrawer/FormItems/useSuffix'
import React from 'react'
import { DbiFormItem } from 'types/cardio'
import { useTranslation } from 'utils/helpers'

interface DbiFormItemProps {
  dbi: DbiFormItem
}

function AirplusDbiFormItem({ dbi }: DbiFormItemProps): React.JSX.Element {
  const t = useTranslation()
  const form = Form.useFormInstance()
  const inputSuffix = useSuffix({ fieldName: dbi.name, maxLength: dbi.max, form })

  const buildLabel = (dbi: DbiFormItem) => {
    return `(${dbi.name}) ${t(dbi.translationKey)} ${dbi.required ? '' : `(${t('vcn.airplus.optional.label')})`}`
  }

  return (
    <Form.Item
      data-testid={`dbi-${dbi.name}`}
      key={dbi.name}
      name={dbi.name}
      validateDebounce={300}
      hasFeedback
      label={buildLabel(dbi)}
      required={dbi.required}
      rules={[
        {
          required: dbi.required,
          message: t('vcn.airplus.dbi.error.required.message'),
        },
        {
          pattern: new RegExp(dbi.pattern),
          message: t('vcn.airplus.dbi.error.format.message'),
        },
      ]}
      tooltip={
        dbi.tooltipMessageKey && {
          title: t(dbi.tooltipMessageKey),
          getPopupContainer: () => document.body,
        }
      }
    >
      <Input
        placeholder={t('vcn.airplus.dbi.placeholder', {
          label: t(dbi.translationKey),
        })}
        suffix={inputSuffix}
      />
    </Form.Item>
  )
}

export default AirplusDbiFormItem
