import React, { ReactElement } from 'react'
import { Button } from 'antd'
import useSecondaryMenuStyle from './SecondaryMenu.style'
import useFilterStyle from '../Filter/Filter.style'

export interface MenuOption {
  id?: string
  label?: string
  elementType?: string
  type: 'button'
  icon?: ReactElement
  visibility: boolean
  callback: () => void
}
export interface MenuGroup {
  id?: string
  align?: string
  elements: MenuOption[]
}

interface MenuProps {
  label: string
  groups: Array<MenuGroup>
}

const SecondaryMenu = (props: MenuProps): React.JSX.Element => {
  const { styles } = useSecondaryMenuStyle()
  const { styles: filterStyles } = useFilterStyle()

  const renderButton = (options: MenuOption) => {
    const { elementType, label } = options
    const filterButton = (
      <Button type="default" danger={elementType === 'danger'} onClick={options.callback}>
        {label}
        {options.icon}
      </Button>
    )
    return filterButton
  }

  const renderOption = (option: MenuOption): React.ReactNode => {
    switch (option.type) {
      case 'button':
        return renderButton(option)
    }
  }

  const filterElements = (group: MenuGroup) => {
    const filteredElementsArray = group.elements.filter((element) => {
      return element.visibility === true
    })
    return filteredElementsArray
  }

  return (
    <div className="desktop-display">
      {props.groups.map((group: MenuGroup, index: number) => {
        return (
          <div key={`filter-group-${index}`} className={styles.filters}>
            {filterElements(group).map((option: MenuOption, index: number) => {
              return (
                <div key={`filter-${index}`} className={filterStyles.filter}>
                  {renderOption(option)}
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

export default SecondaryMenu
