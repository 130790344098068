import { defineMessages } from 'react-intl'

export const register = defineMessages({
  'register.submit.btn': {
    id: 'register.submit.btn',
    defaultMessage: 'Register account',
    description: 'Register submit button',
  },
  'register.header.business.title': {
    id: 'register.header.business.title',
    defaultMessage: 'Register your business',
    description: 'Register business title',
  },
  'register.header.individual.page.title': {
    id: 'register.header.individual.page.title',
    defaultMessage: 'Register as an individual',
    description: 'Register individual page title',
  },
  'register.header.welcome.title': {
    id: 'register.header.welcome.title',
    defaultMessage: 'Create a Billhop account',
    description: 'Register welcome title',
  },
  'register.header.welcome.choose.title': {
    id: 'register.header.welcome.choose.title',
    defaultMessage: `Both businesses and private individuals can register at Billhop. 
    You can connect multiple accounts to the same email-address. Regardless whether you register a business or a private account, 
    you can use both your corporate or private credit card. The service fee is the same for both types of accounts.`,
    description: 'Register welcome choose register type title',
  },
  'register.header.company.subtitle': {
    id: 'register.header.company.subtitle',
    defaultMessage: `In order to use Billhop you need to register your business with us
    using the form below`,
    description: 'Register company subtitle',
  },
  'register.header.company.subtitle.se': {
    id: 'register.header.company.subtitle.se',
    defaultMessage: `To get started with Billhop, you need to register.Enter your
    e-mail address and telephone number and then identify yourself via
    Mobile BankID`,
    description: 'Register company subtitle for Sweden',
  },
  'register.header.individual.subtitle': {
    id: 'register.header.individual.subtitle',
    defaultMessage: `Creating a Billhop account only takes a few minutes. Once the registration is done, you can start paying your invoices immediately!`,
    description: 'Register company subtitle for individuals',
  },
  'register.accept.terms.service': {
    id: 'register.accept.terms.service',
    defaultMessage: 'Terms of Service',
    description: 'Register terms of service',
  },
  'register.accept.terms.individual': {
    id: 'register.accept.terms.individual',
    defaultMessage: `I have read, understood and accepted Billhop's
     {link}. I further acknowledge that
    I will use Billhop service for private purposes, and that I do
    not represent anyone but myself.`,
    description: 'Register individual accept terms',
  },
  'register.optional.question': {
    id: 'register.optional.question',
    defaultMessage: 'How did you find Billhop? (Optional question)',
    description: 'Register optional question',
  },
  'register.optional.question.option.one': {
    id: 'register.optional.question.option.one',
    defaultMessage: 'Colleague or friend',
    description: 'Register optional question one',
  },
  'register.optional.question.option.two': {
    id: 'register.optional.question.option.two',
    defaultMessage: 'Google search or similar',
    description: 'Register optional question two',
  },
  'register.optional.question.option.three': {
    id: 'register.optional.question.option.three',
    defaultMessage: 'Article or similar news media',
    description: 'Register optional question three',
  },
  'register.optional.question.option.five': {
    id: 'register.optional.question.option.five',
    defaultMessage: 'Other',
    description: 'Register optional question five',
  },
  'register.owner.structure.option.one': {
    id: 'register.owner.structure.option.one',
    defaultMessage: '100% of the business is owned by myself',
    description: 'Register owner structure option one',
  },
  'register.owner.structure.option.two': {
    id: 'register.owner.structure.option.two',
    defaultMessage:
      'The business has several owners, but no individual owns more than 25% directly or indirectly or has a significant influence ',
    description: 'Register owner structure option two',
  },
  'register.owner.structure.option.three': {
    id: 'register.owner.structure.option.three',
    defaultMessage:
      'The business has one or several owners that each, directly or indirectly, own more than 25% of the company or have a significant influence',
    description: 'Register owner structure option three',
  },
  'register.pep.radio.one': {
    id: 'register.pep.radio.one',
    defaultMessage: 'No, I’m not a PEP',
    description: 'Register pep radio option one',
  },
  'register.pep.radio.two': {
    id: 'register.pep.radio.two',
    defaultMessage: 'Yes, I’m a PEP',
    description: 'Register pep radio option two',
  },
  'register.pep.radio.error': {
    id: 'register.pep.radio.error',
    defaultMessage: 'Please select an option.',
    description: 'Error on non slected value on PEP',
  },
  'register.submit.btn.se': {
    id: 'register.submit.btn.se',
    defaultMessage: 'Register with Mobile BankID',
    description: 'Register submit button Sweden',
  },
  'register.accept.terms.conditions': {
    id: 'register.accept.terms.conditions',
    defaultMessage: 'Terms and conditions',
    description: 'Register individual accept terms and conditions',
  },
  'register.choose.company.title': {
    id: 'register.choose.company.title',
    defaultMessage: 'Register as a Small business',
    description: 'Register as company title',
  },
  'register.choose.company.text': {
    id: 'register.choose.company.text',
    defaultMessage: `The company needs to be registered in the United Kingdom. We will not
    carry out a credit scoring, but we will verify the details through the
    Companies House register`,
    description: 'Register as company text',
  },
  'register.choose.individual.title': {
    id: 'register.choose.individual.title',
    defaultMessage: 'Register as a Private individual',
    description: 'Register as individual title',
  },
  'register.choose.individual.text': {
    id: 'register.choose.individual.text',
    defaultMessage: `You need to be 18 years of age and registered in the UK. We will not
    do any credit scoring.`,
    description: 'Register as individual text',
  },
  'register.choose.company.btn.value': {
    id: 'register.choose.company.btn.value',
    defaultMessage: 'Register a small business',
    description: 'Register as company button',
  },
  'register.choose.company.btn.paragraph': {
    id: 'register.choose.company.btn.paragraph',
    defaultMessage: 'With company number',
    description: 'Register as company paragraph',
  },
  'register.choose.individual.btn.value': {
    id: 'register.choose.individual.btn.value',
    defaultMessage: 'Register as an individual',
    description: 'Register as individual button',
  },
  'register.sidebar.three.steps': {
    id: 'register.sidebar.three.steps',
    defaultMessage: 'Tree simple steps',
    description: 'Register three steps',
  },
  'register.sidebar.details.title': {
    id: 'register.sidebar.details.title',
    defaultMessage: 'details',
    description: 'Register details title',
  },
  'register.sidebar.account.details': {
    id: 'register.sidebar.account.details',
    defaultMessage: 'Account details',
    description: 'Register details title',
  },
  'register.sidebar.details.accept.terms': {
    id: 'register.sidebar.details.accept.terms',
    defaultMessage: 'Accept terms',
    description: 'Register details title',
  },
  'register.sidebar.details.title.company': {
    id: 'register.sidebar.details.title.company',
    defaultMessage: 'Company',
    description: 'Register details title company',
  },
  'register.sidebar.details.title.individual': {
    id: 'register.sidebar.details.title.individual',
    defaultMessage: 'Individual',
    description: 'Register details title individual',
  },
  'register.sidebar.additional.links': {
    id: 'register.sidebar.additional.links',
    defaultMessage: 'Additional links',
    description: 'Register additional links',
  },
  'register.sidebar.back.to.login': {
    id: 'register.sidebar.back.to.login',
    defaultMessage: 'Back to Login',
    description: 'Register back to login',
  },
  'register.sidebar.register.individual': {
    id: 'register.sidebar.register.individual',
    defaultMessage: 'Register as a Individual',
    description: 'Register individual',
  },
  'register.sidebar.register.company': {
    id: 'register.sidebar.register.company',
    defaultMessage: 'Register as a Company',
    description: 'Register company',
  },
  'register.sidebar.support.faq': {
    id: 'register.sidebar.support.faq',
    defaultMessage: 'Support and FAQ',
    description: 'Register support and faq',
  },
  'register.sidebar.support.url': {
    id: 'register.sidebar.support.url',
    defaultMessage: 'https://billhop.com/gb/support/',
    description: 'link to support and faq',
  },
  'register.sidebar.need.assistance': {
    id: 'register.sidebar.need.assistance',
    defaultMessage: 'Do you need assistance?',
    description: 'Register need assistance',
  },
  'register.sidebar.phone.number': {
    id: 'register.sidebar.phone.number',
    defaultMessage: '08-410 585 40',
    description: 'Register phone number',
  },
  'register.sidebar.contact.mail': {
    id: 'register.sidebar.contact.mail',
    defaultMessage: 'help@billhop.se',
    description: 'Register Weekdays',
  },
  'register.sidebar.customer.service.opening.times': {
    id: 'register.sidebar.customer.service.opening.times',
    defaultMessage: '8 - 11:30 am 12:30 - 4 pm',
    description: 'Display customer service opening times',
  },
  'register.sidebar.weekdays': {
    id: 'register.sidebar.weekdays',
    defaultMessage: 'Weekdays',
    description: 'Register Weekdays',
  },
  'register.bankid.label.email': {
    id: 'register.bankid.label.email',
    defaultMessage: 'Email address',
    description: 'Register label values',
  },
  'register.bankid.msg.email': {
    id: 'register.bankid.msg.email',
    defaultMessage: 'Please enter valid email address!',
    description: 'Register message values',
  },
  'register.bankid.placeholder.email': {
    id: 'register.bankid.placeholder.email',
    defaultMessage: 'my@email.com',
    description: 'Register placeholder values',
  },
  'register.validation.image.size.too.large': {
    id: 'register.validation.image.size.too.large',
    defaultMessage: 'Image size too large.Please use a file smaller than 50mb',
    description: 'Image size too large',
  },
  'register.validation.field.required': {
    id: 'register.validation.field.required',
    defaultMessage: 'This filed is required',
    description: 'Register validation filed required',
  },
  'register.validation.field.invalid.phone': {
    id: 'register.validation.field.invalid.phone',
    defaultMessage: 'Invalid phone number',
    description: 'Register validation invalid phone number',
  },
  'register.validation.field.minlength8': {
    id: 'register.validation.field.minlength8',
    defaultMessage: 'Min length 8 character',
    description: 'Minimum length 8 characters',
  },
  'register.validation.field.invalid.age': {
    id: 'register.validation.field.invalid.age',
    defaultMessage: 'Invalid Age',
    description: 'The age value is invalid',
  },
  'register.bankid.label.mobile.phone': {
    id: 'register.bankid.label.mobile.phone',
    defaultMessage: 'Mobile number',
    description: 'Register label values',
  },
  'register.bankid.placeholder.mobile.phone': {
    id: 'register.bankid.placeholder.mobile.phone',
    defaultMessage: 'Enter mobile number',
    description: 'Register placeholder values',
  },
  'register.bankid.label.person.number': {
    id: 'register.bankid.label.person.number',
    defaultMessage: 'Person number',
    description: 'Register label values',
  },
  'register.sidebar.additional.links.select.placeholder': {
    id: 'register.sidebar.additional.links.select.placeholder',
    defaultMessage: 'Choose your country',
    description: 'Register placeholder values',
  },
  'register.i.accept.terms': {
    id: 'register.i.accept.terms',
    defaultMessage: 'I accept the terms',
    description: 'Register I accept terms',
  },
  'register.accept.terms.title': {
    id: 'register.accept.terms.title',
    defaultMessage: 'Terms of Service',
    description: 'Register accept terms title',
  },
  'register.pep.title': {
    id: 'register.pep.title',
    defaultMessage: 'PEP',
    description: 'Register pep title',
  },
  'register.pep.description': {
    id: 'register.pep.description',
    defaultMessage: 'I hold or have held a political or governmental position (PEP).',
    description: 'Register pep description',
  },
  'register.pep.readMore': {
    id: 'register.pep.readMore',
    defaultMessage: 'Read more about PEP',
    description: 'Register pep read more',
  },
  'register.accept.terms.of.service': {
    id: 'register.accept.terms.of.service',
    defaultMessage: 'Terms of service',
    description: 'Register accept terms of service title',
  },
  'register.accept.terms.company': {
    id: 'register.accept.terms.company',
    defaultMessage: `I have read, understood and accepted Billhop's 
     {link}. In case I Register as a sole
    trader, I confirm that I am not representing anyone but myself.`,
    description: 'Register company accept terms',
  },
  'register.accept.terms.company.protect.rights': {
    id: 'register.accept.terms.company.protect.rights',
    defaultMessage: `We are keen to protect the privacy of our registered users and of
    the visitors of our website! Please read our {link} carefully.`,
    description: 'Register company accept terms rights',
  },
  'register.accept.terms.company.protect.accept': {
    id: 'register.accept.terms.company.protect.accept',
    defaultMessage: `By clicking on the "Register my business" button
    I confirm that I am an authorized signatory of the company and as
    such have legal authority to create an account on behalf of the
    company, and that the information I have given is valid an
    truthful.`,
    description: 'Register company accept terms accept',
  },
  'register.individual.details.title': {
    id: 'register.individual.details.title',
    defaultMessage: '2. Individual details',
    description: 'Register individual details title',
  },
  'register.individual.details.description': {
    id: 'register.individual.details.description',
    defaultMessage: `Enter your address as stated in the official registers. Do not use
    abbreviations and make sure to check for spelling errors.`,
    description: 'Register individual details description',
  },
  'register.howDidYouFind.title': {
    id: 'register.howDidYouFind.title',
    defaultMessage: '3. How did you find out about Billhop? (Optional question)',
    description: 'Register individual how did you find title',
  },
  'register.howDidYouFind.description': {
    id: 'register.howDidYouFind.description',
    defaultMessage: `If you want you can tell us how you found out about Billhop. It helps us improve our service and make it more efficient.`,
    description: 'Register individual how did you find description',
  },
  'register.owner.structure.title': {
    id: 'register.owner.structure.title',
    defaultMessage: '3. Owner structure',
    description: 'Register company how owner structure title',
  },
  'register.owner.structure.description': {
    id: 'register.owner.structure.description',
    defaultMessage: `In order to register your account we need to know if there are any individuals that have a significant influence over the business. By this we mean if there are any individuals that individually can make significant decisions concerning the company.`,
    description: 'Register company how owner structure description',
  },
  'register.individual.details.birth.date': {
    id: 'register.individual.details.birth.date',
    defaultMessage: 'Date of birth',
    description: 'Register individual details birth date',
  },
  'register.personal.details.birth.date': {
    id: 'register.personal.details.birth.date',
    defaultMessage: 'Firm signatory date of birth',
    description: 'Register personal details birth date',
  },
  'register.individual.details.birth.day.validation': {
    id: 'register.individual.details.birth.day.validation',
    defaultMessage: 'Value are between 1 and 31',
    description: 'Register individual details birth date validation',
  },
  'register.individual.details.birth.date.day': {
    id: 'register.individual.details.birth.date.day',
    defaultMessage: 'Day',
    description: 'Register individual details birth date day',
  },
  'register.individual.details.birth.month.validation': {
    id: 'register.individual.details.birth.month.validation',
    defaultMessage: 'Value are between 1 and 12',
    description: 'Register individual details birth month validation',
  },
  'register.individual.details.birth.month': {
    id: 'register.individual.details.birth.month',
    defaultMessage: 'Month',
    description: 'Register individual details birth month title',
  },
  'register.individual.details.birth.year': {
    id: 'register.individual.details.birth.year',
    defaultMessage: 'Year',
    description: 'Register individual details birth year title',
  },
  'register.individual.details.birth.year.validation': {
    id: 'register.individual.details.birth.year.validation',
    defaultMessage: 'Have to be age 18+',
    description: 'Register individual details birth year validation',
  },
  'register.individual.details.mobile.phone': {
    id: 'register.individual.details.mobile.phone',
    defaultMessage: 'Mobile Phone',
    description: 'Register individual details phone title',
  },
  'register.individual.details.mobile.phone.validation': {
    id: 'register.individual.details.mobile.phone.validation',
    defaultMessage: 'Value should contain just numbers',
    description: 'Register individual details phone validation',
  },
  'register.individual.details.address': {
    id: 'register.individual.details.address',
    defaultMessage: 'Address',
    description: 'Register individual details address title',
  },
  'register.individual.details.address.validation': {
    id: 'register.individual.details.address.validation',
    defaultMessage: 'Please enter street and number',
    description: 'Register individual details address validation',
  },
  'register.individual.details.address.placeholder': {
    id: 'register.individual.details.address.placeholder',
    defaultMessage: 'Street',
    description: 'Register individual details address placeholder',
  },
  'register.individual.details.postcode': {
    id: 'register.individual.details.postcode',
    defaultMessage: 'Postal code',
    description: 'Register individual details postcode title',
  },
  'register.individual.details.postcode.placeholder': {
    id: 'register.individual.details.postcode.placeholder',
    defaultMessage: 'Postal code',
    description: 'Register individual details postcode placeholder',
  },
  'register.individual.details.county': {
    id: 'register.individual.details.county',
    defaultMessage: 'County',
    description: 'Register individual details county title',
  },
  'register.individual.details.county.placeholder': {
    id: 'register.individual.details.county.placeholder',
    defaultMessage: 'County',
    description: 'Register individual details county placeholder',
  },
  'register.individual.details.city': {
    id: 'register.individual.details.city',
    defaultMessage: 'City',
    description: 'Register individual details city title',
  },
  'register.individual.details.city.placeholder': {
    id: 'register.individual.details.city.placeholder',
    defaultMessage: 'City',
    description: 'Register individual details city placeholder',
  },
  'register.individual.details.houseNumber': {
    id: 'register.individual.details.houseNumber',
    defaultMessage: 'House number',
    description: 'Register individual details house number title',
  },
  'register.individual.details.houseNumber.placeholder': {
    id: 'register.individual.details.houseNumber.placeholder',
    defaultMessage: 'House number',
    description: 'Register individual details house number placeholder',
  },
  'register.individual.details.proofOf.title': {
    id: 'register.individual.details.proofOf.title',
    defaultMessage: 'Proof of {item}:',
    description: 'Register individual details proof of title',
  },
  'register.individual.details.what.is.this.title': {
    id: 'register.individual.details.what.is.this.title',
    defaultMessage: 'What is this?',
    description: 'Register individual details what is this title',
  },
  'register.individual.title.identity': {
    id: 'register.individual.title.identity',
    defaultMessage: 'Proof of identity',
    description: 'Register individual details proof of title',
  },
  'register.individual.title.selfie': {
    id: 'register.individual.title.selfie',
    defaultMessage: 'Proof of selfie',
    description: 'Register individual details proof of title',
  },
  'register.individual.title.address': {
    id: 'register.individual.title.address',
    defaultMessage: 'Proof of address',
    description: 'Register individual details proof of title',
  },
  'register.company.title.organization': {
    id: 'register.company.title.organization',
    defaultMessage: 'Proof of organization',
    description: 'Register company details proof of title',
  },
  'register.individual.details.identity': {
    id: 'register.individual.details.identity',
    defaultMessage: `Identity document info`,
    description: 'Register individual details what is this identity',
  },
  'register.individual.details.selfie': {
    id: 'register.individual.details.selfie',
    defaultMessage: `Sefie document info`,
    description: 'Register individual details what is this selfie',
  },
  'register.individual.details.addressVerification': {
    id: 'register.individual.details.addressVerification',
    defaultMessage: `Address document info`,
    description: 'Register individual details what is this address',
  },
  'register.company.details.organizationCertificate': {
    id: 'register.company.details.organizationCertificate',
    defaultMessage: `Organization certificate document info`,
    description: 'Register individual details what is this org certificate',
  },
  'register.individual.upload.text': {
    id: 'register.individual.upload.text',
    defaultMessage: 'Click or drag file to this area to upload',
    description: 'Register individual details what is this address',
  },

  'register.account.details.title': {
    id: 'register.account.details.title',
    defaultMessage: '1. Account details',
    description: 'Register account details description',
  },
  'register.personal.details.title': {
    id: 'register.personal.details.title',
    defaultMessage: '1. Personal details',
    description: 'Register personal details description',
  },
  'register.personal.details.description.one.company': {
    id: 'register.personal.details.description.one.company',
    defaultMessage: `This account will be the first account for your business and as
    such the account that you will use to login to Billhop. `,
    description: 'Register personal details description first paragraph',
  },
  'register.personal.details.description.two.company': {
    id: 'register.personal.details.description.two.company',
    defaultMessage: `If you
    already have a Billhop account you can, login and register this
    business through "Settings".`,
    description: 'Register personal details description second paragraph',
  },
  'register.account.details.description.individual': {
    id: 'register.account.details.description.individual',
    defaultMessage: `This information is used for your Billhop account. You will use
    this email and the password to login to Billhop`,
    description: 'Register account details description',
  },
  'register.account.details.first.name': {
    id: 'register.account.details.first.name',
    defaultMessage: 'First name',
    description: 'Register account details description',
  },
  'register.account.details.first.name.placeholder': {
    id: 'register.account.details.first.name.placeholder',
    defaultMessage: 'Name',
    description: 'Register account details description',
  },
  'register.account.details.last.name': {
    id: 'register.account.details.last.name',
    defaultMessage: 'Surname',
    description: 'Register account details description',
  },
  'register.account.details.last.name.placeholder': {
    id: 'register.account.details.last.name.placeholder',
    defaultMessage: 'Surname',
    description: 'Register account details description',
  },
  'register.account.details.email': {
    id: 'register.account.details.email',
    defaultMessage: 'Last name',
    description: 'Register account details description',
  },
  'register.account.details.email.placeholder': {
    id: 'register.account.details.email.placeholder',
    defaultMessage: 'Enter last name',
    description: 'Register account details description',
  },
  'register.account.details.password.placeholder': {
    id: 'register.account.details.password.placeholder',
    defaultMessage: 'My difficult password',
    description: 'Register account details description',
  },
  'register.account.details.password.confirm': {
    id: 'register.account.details.password.confirm',
    defaultMessage: 'Confirm Password',
    description: 'Register account details description',
  },
  'register.account.details.password.confirm.validation': {
    id: 'register.account.details.password.confirm.validation',
    defaultMessage: 'Please confirm your password!',
    description: 'Register account details description',
  },
  'register.account.details.password.confirm.validation.match': {
    id: 'register.account.details.password.confirm.validation.match',
    defaultMessage: 'The two passwords that you entered do not match!',
    description: 'Register account details description',
  },
  'register.account.details.password.confirm.placeholder': {
    id: 'register.account.details.password.confirm.placeholder',
    defaultMessage: 'Repeat the password',
    description: 'Register account details description',
  },
  'register.company.details.title': {
    id: 'register.company.details.title',
    defaultMessage: '2. Company details',
    description: 'Register company details description',
  },
  'register.company.details.description.one': {
    id: 'register.company.details.description.one',
    defaultMessage: `In order for us to register your business we need some information.
    All information is kept strictly confidential.`,
    description: 'Register company details description paragraph one',
  },
  'register.company.details.description.two': {
    id: 'register.company.details.description.two',
    defaultMessage: `If you are a sole
    trader, please register as a private individual or contact us at
    help@billhop.com.`,
    description: 'Register company details description paragraph two',
  },
  'register.company.details.description.three': {
    id: 'register.company.details.description.three',
    defaultMessage: `You will only need to submit this information
    once.`,
    description: 'Register company details description paragraph three',
  },
  'register.company.details.name': {
    id: 'register.company.details.name',
    defaultMessage: 'Company name',
    description: 'Register company details description',
  },
  'register.company.details.name.placeholder': {
    id: 'register.company.details.name.placeholder',
    defaultMessage: 'Company name',
    description: 'Register company details description',
  },
  'register.company.details.registration.number': {
    id: 'register.company.details.registration.number',
    defaultMessage: 'Company registration number',
    description: 'Register company details description',
  },
  'register.company.details.registration.number.placeholder': {
    id: 'register.company.details.registration.number.placeholder',
    defaultMessage: 'Company registration number',
    description: 'Register company details description',
  },
  'register.company.main.industry': {
    id: 'register.company.main.industry',
    defaultMessage: 'Main industry',
    description: 'Register company details description',
  },
  'register.company.main.industry.placeholder': {
    id: 'register.company.main.industry.placeholder',
    defaultMessage: 'Choose industry',
    description: 'Register company details description',
  },
  'register.company.company.website': {
    id: 'register.company.company.website',
    defaultMessage: 'Company website',
    description: 'Register company details description',
  },
  'register.company.company.website.optional': {
    id: 'register.company.company.website.optional',
    defaultMessage: '(optional)',
    description: 'Register company details description',
  },
  'register.company.company.website.placeholder': {
    id: 'register.company.company.website.placeholder',
    defaultMessage: 'www.the-website.com',
    description: 'Register company details description',
  },
  'register.notification.error.server': {
    id: 'register.notification.error.server',
    defaultMessage: 'There is a problem with server',
    description: 'Register notification for server error',
  },
  'register.notification.created.profile': {
    id: 'register.notification.created.profile',
    defaultMessage: 'Successfully created profile',
    description: 'Register notification for creating profile',
  },
  'register.notification.bakid.error': {
    id: 'register.notification.bakid.error',
    defaultMessage: 'Error with BankID',
    description: 'Register notification for bankid error',
  },
  'register.notification.term.service': {
    id: 'register.notification.term.service',
    defaultMessage: 'Please read and accept our Terms of service before proceeding further',
    description: 'Register notification for terms of service',
  },
  'register.validation.email.already.registered': {
    id: 'register.validation.email.already.registered',
    defaultMessage: 'Email is already registered',
    description: 'Register validation message',
  },
  'register.validation.strong.password': {
    id: 'register.validation.strong.password',
    defaultMessage: 'Enter strong password',
    description: 'Register validation message',
  },
  'register.validation.phone.number': {
    id: 'register.validation.phone.number',
    defaultMessage: 'Enter valid phone number',
    description: 'Register validation message',
  },
  'register.validation.phone.number.used': {
    id: 'register.validation.phone.number.used',
    defaultMessage: 'Phone number is already in use',
    description: 'Register validation message',
  },
  'register.validation.valid.ssn': {
    id: 'register.validation.valid.ssn',
    defaultMessage: 'Enter valid ssn number',
    description: 'Register validation message',
  },
  'register.validation.ssn.used': {
    id: 'register.validation.ssn.used',
    defaultMessage: 'SSN is already in use',
    description: 'Register validation message',
  },
  'register.validation.ssn.not.equal': {
    id: 'register.validation.ssn.not.equal',
    defaultMessage: 'SSN are not equal',
    description: 'Register validation message',
  },
  'register.validation.valid.birth.date': {
    id: 'register.validation.valid.birth.date',
    defaultMessage: 'Birth date is not valid',
    description: 'Register validation message',
  },
  'register.validation.valid.cin': {
    id: 'register.validation.valid.cin',
    defaultMessage: 'CIN is not valid',
    description: 'Register validation message',
  },
  'register.validation.cin.not.exist': {
    id: 'register.validation.cin.not.exist',
    defaultMessage: "CIN number doesn't exist",
    description: 'Register validation message',
  },
  'register.validation.cin.exist': {
    id: 'register.validation.cin.exist',
    defaultMessage: 'CIN number already exists',
    description: 'Register validation message',
  },
  'register.validation.bankid.not.working': {
    id: 'register.validation.bankid.not.working',
    defaultMessage: 'BankId not working',
    description: 'Register validation message',
  },
  'register.validation.server.error': {
    id: 'register.validation.server.error',
    defaultMessage: 'Server error, please try again',
    description: 'Register validation message',
  },
  'register.validation.form.error': {
    id: 'register.validation.form.error',
    defaultMessage: 'Validation error',
    description: 'Form validation message',
  },
  'register.validation.identity.not.verified': {
    id: 'register.validation.identity.not.verified',
    defaultMessage: 'Identity is not verified',
    description: 'Register validation message',
  },
  'register.duplicate.file.upload.title': {
    id: 'register.duplicate.file.upload.title',
    defaultMessage: 'Duplicate file names',
    description: 'Duplicate file upload error message',
  },
  'register.wrong.file.type': {
    id: 'register.wrong.file.type',
    defaultMessage: 'Only pdf and images are allowed',
    description: 'Invalid file type upload, only pdf and images are allowed',
  },
  'register.national.id.label': {
    id: 'register.national.id',
    defaultMessage: 'National ID',
    description: 'Radio button national id label',
  },
  'register.drivers.license.label': {
    id: 'register.drivers.license.label',
    defaultMessage: 'Drivers License',
    description: 'Radio button drivers license label',
  },
  'register.passport.label': {
    id: 'register.passport.label',
    defaultMessage: 'Passport',
    description: 'Radio button passport label',
  },
  'register.identity': {
    id: 'register.identity',
    defaultMessage: 'identity',
    description: 'Document upload type for identity document in register page',
  },
  'register.selfie': {
    id: 'register.selfie',
    defaultMessage: 'selfie',
    description: 'Document upload type for selfie document in register page',
  },
  'register.address': {
    id: 'register.address',
    defaultMessage: 'address',
    description: 'Document upload type for address document in register page',
  },
  'register.pepInfo.title': {
    id: 'register.pepInfo.title',
    defaultMessage: 'Pep',
    description: 'Title of the pep info modal',
  },
  'register.pepInfo.body': {
    id: 'register.pepInfo.body',
    defaultMessage: 'Pep info',
    description: 'Body of the pep info modal',
  },
  'register.select.industry.accomodation.food': {
    id: 'register.select.industry.accomodation.food',
    defaultMessage: 'Accommodation and food service',
    description: 'Value for Industry select on registration',
  },
  'register.select.industry.agriculture.forestry.fishing': {
    id: 'register.select.industry.agriculture.forestry.fishing',
    defaultMessage: 'Agriculture, forestry and fishing',
    description: 'Value for Industry select on registration',
  },
  'register.select.industry.construction.craftsman': {
    id: 'register.select.industry.construction.craftsman',
    defaultMessage: 'Construction/craftsman',
    description: 'Value for Industry select on registration',
  },
  'register.select.industry.electricity': {
    id: 'register.select.industry.electricity',
    defaultMessage: 'Electricity',
    description: 'Value for Industry select on registration',
  },
  'register.select.industry.financial': {
    id: 'register.select.industry.financial',
    defaultMessage: 'Financial services',
    description: 'Value for Industry select on registration',
  },
  'register.select.industry.health.beauty': {
    id: 'register.select.industry.health.beauty',
    defaultMessage: 'Health and beauty',
    description: 'Value for Industry select on registration',
  },
  'register.select.industry.manufacturing': {
    id: 'register.select.industry.manufacturing',
    defaultMessage: 'Manufacturing',
    description: 'Value for Industry select on registration',
  },
  'register.select.industry.oil.gas.chemicals': {
    id: 'register.select.industry.oil.gas.chemicals',
    defaultMessage: 'Oil, gas and chemicals',
    description: 'Value for Industry select on registration',
  },
  'register.select.industry.other': {
    id: 'register.select.industry.other',
    defaultMessage: 'Other',
    description: 'Value for Industry select on registration',
  },
  'register.select.industry.professional.services': {
    id: 'register.select.industry.professional.services',
    defaultMessage: 'Professional services',
    description: 'Value for Industry select on registration',
  },
  'register.select.industry.real.estate': {
    id: 'register.select.industry.real.estates',
    defaultMessage: 'Real estates',
    description: 'Value for Industry select on registration',
  },
  'register.select.industry.retail': {
    id: 'register.select.industry.retail',
    defaultMessage: 'Retail',
    description: 'Value for Industry select on registration',
  },
  'register.select.industry.travel.leisure': {
    id: 'register.select.industry.travel.leisure',
    defaultMessage: 'Travel and leisure',
    description: 'Value for Industry select on registration',
  },
  'register.select.industry.wholesale.trade': {
    id: 'register.select.industry.wholesale.trade',
    defaultMessage: 'Wholesale trade',
    description: 'Value for Industry select on registration',
  },
})
