import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import * as apiBarracks from 'api/barracks'
import * as rulesApi from 'api/rules'
import Filter, { Data, FilterBody } from 'components/Filter/Filter'
import Page from 'components/Page/Page'
import { page, pi, filters } from 'lang/definitions'
import { FilterState } from 'types/general'
import CombinedPITable, { PaymentAndTemplateRow } from '../../PaymentInstructions/components/Tables/CombinedPITable'
import { getEntityName } from 'utils/helpers'
import { Profile } from 'types/profile'
import { setDrawerHash } from 'components/Drawers/utils'
import ActionPage from 'components/ActionPage/ActionPage'
import { PaymentInstruction } from 'types/paymentInstruction'
import { PaymentInstructionTemplate } from 'types/paymentInstruction'
import PaymentInstructionDetails from '../../PaymentInstructions/PaymentInstructionDetails'
import PaymentInstructionTemplateDetails from '../../PaymentInstructions/PaymentInstructionTemplateDetails'
import { getStateTranslation } from '../../PaymentInstructions/utils'
import * as Sentry from '@sentry/react'
import { useSession } from 'stores/session'

const PaymentOverview = (): React.JSX.Element => {
  const history = useHistory()
  const intl = useIntl()
  const {
    state: { iam },
  } = useSession()
  const userEntities = iam?.map((i) => i.entity) || []

  const [paymentInstruction, setPaymentInstruction] = useState<PaymentInstruction>()
  const [paymentInstructionTemplate, setPaymentInstructionTemplate] = useState<PaymentInstructionTemplate>()

  const [filterBody, setFilterBody] = useState<FilterBody>({})
  const [filterState, setFilterState] = useState<FilterState>()
  const [fetching, setFetching] = useState(false)
  const [createdByProfiles, setCreatedByProfiles] = useState<Profile[]>([])

  const [currencyOptions, setCurrencyOptions] = useState<Array<string>>([])

  const allPiStates = [
    'REQUIRE_SIGNATURE',
    'READY',
    'PENDING',
    'BANK',
    'PROCESSING',
    'PENDING_AUTHORIZATION',
    'PAID',
    'CANCELED',
    'DELETED',
    'ERROR',
  ]
  const allTemplateStates = ['READY', 'REQUIRE_SIGNATURE', 'ACTIVE', 'CANCELED', 'DELETED', 'ERROR']

  const onRowClick = (_event: React.MouseEvent<HTMLElement, MouseEvent> | undefined, record: PaymentAndTemplateRow) => {
    if (record?.paymentInstructionProperties) {
      // It's a template
      setPaymentInstructionTemplate(record as PaymentInstructionTemplate)
      setPaymentInstruction(undefined)
    } else {
      // It's a payment instruction
      setPaymentInstruction(record as PaymentInstruction)
      setPaymentInstructionTemplate(undefined)
    }
    setDrawerHash(history, `#drawer-details?key=${record.id}`)
  }

  const getCurrencyOptions = async (): Promise<void> => {
    try {
      const currencies = await rulesApi.getCurrencies()
      if (JSON.stringify(currencies) !== JSON.stringify(currencyOptions)) {
        setCurrencyOptions(currencies)
      }
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const getUserProfileIds = (userId: string): string[] => {
    return createdByProfiles.filter((profile) => profile.userId === userId).map((profile) => profile.id)
  }

  const getCreatedByOptions = async (): Promise<void> => {
    const userFiler: FilterBody = {
      entityId: [...userEntities.map((entity) => entity.id)],
    }

    try {
      setFetching(true)
      const { profiles } = await apiBarracks.searchProfile(userFiler)
      setCreatedByProfiles(profiles)
    } catch (error) {
      Sentry.captureException(error)
    } finally {
      setFetching(false)
    }
  }

  useEffect(() => {
    void getCurrencyOptions()
    void getCreatedByOptions()
  }, [])

  const handleFilterChange = (valueType: string, value: string | string[]) => {
    switch (valueType) {
      case 'createdBy': {
        const profileIds = getUserProfileIds(value as string)
        setFilterBody((prev) => ({
          ...prev,
          profileId: [...profileIds],
        }))
        if (!value) {
          setFilterBody((prev) => {
            delete prev.profileId
            return prev
          })
        }
        break
      }
      case 'status': {
        const isPiState = allPiStates.includes(value as string)
        const isTemplateState = allTemplateStates.includes(value as string)

        const filter: FilterBody = {}

        filter.piStates = isPiState ? [value as string] : []
        filter.templateStates = isTemplateState ? [value as string] : []

        setFilterBody((prev) => ({
          ...prev,
          ...filter,
        }))
        if (!value) {
          setFilterBody((prev) => {
            delete prev.piStates
            delete prev.templateStates
            return prev
          })
        }
        break
      }
      case 'date':
        if (Array.isArray(value)) {
          setFilterState((state) => ({
            ...state,
            dateDue: {
              from: value[0],
              to: value[1],
            },
          }))
          if (value[0] && value[1]) {
            setFilterBody((prev) => {
              return {
                ...prev,
                dateDue: {
                  from: value[0],
                  to: value[1],
                },
              }
            })
          } else {
            setFilterBody((prev) => {
              delete prev.dateDue
              return { ...prev }
            })
          }
        } else {
          setFilterBody((prev) => {
            delete prev.dateDue
            return { ...prev }
          })
        }
        break
      case 'currency':
        setFilterState((state) => ({
          ...state,
          currency: value as string,
        }))
        setFilterBody((prev) => ({
          ...prev,
          currency: typeof value === 'string' ? value.toLowerCase() : '',
        }))
        if (!value) {
          setFilterBody((prev) => {
            delete prev.currency
            return prev
          })
        }
        break
      case 'search':
        setFilterState((state) => ({
          ...state,
          search: value as string,
        }))
        setFilterBody((prev) => ({
          ...prev,
          q: value as string,
        }))
        if (!value) {
          setFilterBody((prev) => {
            delete prev.q
            return prev
          })
        }
        break
      case 'entity': {
        setFilterBody((prev) => ({
          ...prev,
          entityId: [value] as string[],
        }))
        if (!value) {
          setFilterBody((prev) => {
            delete prev.entityId
            return prev
          })
        }
        break
      }
      default:
        // Nothing
        break
    }
  }

  const handleSetFilterState = (value: string, key: string): void => {
    setFilterState((state) => ({
      ...state,
      [key]: value,
    }))
  }

  const filter = (
    <Filter
      isOpen={false}
      label={intl.formatMessage(filters['filter.pi.page.title'])}
      closeFilter={() => undefined}
      groups={[
        {
          align: 'left',
          id: 'static-pi-filter',
          elements: [
            {
              label: intl.formatMessage(filters['filter.createdBy']),
              type: 'selectWithSearch',
              callback: (value) => handleFilterChange('createdBy', value as string | string[]),
              dataSource: (createdByProfiles || [])
                .reduce((old, profile, index): Data[] => {
                  const {
                    user: { id: userId, name },
                  } = profile

                  const user = old.find(({ value }) => value === userId)

                  if (user) {
                    return [...old]
                  } else {
                    return [
                      ...old,
                      {
                        id: index,
                        name: `${name.first} ${name.last}`,
                        value: userId,
                      },
                    ]
                  }
                }, [] as Data[])
                .sort((a, b) => {
                  if (a.name > b.name) {
                    return 1
                  } else if (a.name < b.name) {
                    return -1
                  } else return 0
                }),
              fetching,
              placeholder: intl.formatMessage(filters['filter.createdBy.placeholder']),
            },
            {
              label: intl.formatMessage(filters['filter.status']),
              type: 'select' as const,
              callback: (value) => handleFilterChange('status', value as string | string[]),
              dataSource: [...new Set([...allPiStates, ...allTemplateStates])]
                .map((value, index) => ({
                  id: index,
                  name: getStateTranslation(value, intl),
                  value,
                }))
                .sort((a, b) => {
                  if (a.name > b.name) {
                    return 1
                  } else if (a.name < b.name) {
                    return -1
                  } else return 0
                }),
              placeholder: intl.formatMessage(filters['filter.status.placeholder']),
            },
            {
              label: intl.formatMessage(filters['filter.search']),
              type: 'search',
              callback: (value) => handleFilterChange('search', value as string | string[]),
              placeholder: intl.formatMessage(filters['filter.recipient.placeholder']),
            },
            {
              label: intl.formatMessage(filters['filter.entity']),
              type: 'selectWithSearch' as const,
              callback: (value) => handleFilterChange('entity', value as string | string[]),
              dataSource: userEntities
                .map((entity, index: number) => {
                  const title = getEntityName({ entity } as Profile)
                  return { id: index, name: title, value: entity.id }
                })
                .sort((a, b) => {
                  if (a.name > b.name) {
                    return 1
                  } else if (a.name < b.name) {
                    return -1
                  } else return 0
                }),
              placeholder: intl.formatMessage(filters['filter.entity.placeholder']),
            },
            {
              label: intl.formatMessage(filters['filter.date']),
              type: 'datePicker',
              callback: (value) => handleFilterChange('date', value as string | string[]),
            },
            {
              label: intl.formatMessage(filters['filter.currency']),
              type: 'select' as const,
              callback: (value) => handleFilterChange('currency', value as string | string[]),
              dataSource: currencyOptions.map((type: string, index: number) => {
                return { id: index, name: type.toUpperCase() }
              }),
              placeholder: intl.formatMessage(filters['filter.currency.placeholder']),
            },
          ],
        },
      ]}
      filterState={filterState}
      setFilterState={handleSetFilterState}
    />
  )

  return (
    <Page title={intl.formatMessage(page['page.admin.paymentOverview.title'])} mobileMenuOptions={[]} filter={filter}>
      <React.Fragment>
        <CombinedPITable
          isPaymentOverview={true}
          isActiveTable={true}
          emptyMessage={intl.formatMessage(pi['pi.not.found'])}
          onRowClick={onRowClick}
          filter={filterBody}
          piStates={allPiStates}
          templateStates={allTemplateStates}
          typeOfPayment={''}
          rowsToRemove={[]}
          setRowsToRemove={() => undefined}
          setSelectedPayments={() => undefined}
          setPaymentInstruction={setPaymentInstruction}
          setPaymentInstructionTemplate={setPaymentInstructionTemplate}
        />
        <ActionPage
          title={intl.formatMessage(page['page.pi.slider.label.detail'])}
          hash={`#drawer-details?key=${paymentInstruction?.id || paymentInstructionTemplate?.id || ''}`}
          pathname={history.location.pathname}
          additionalClass="drawer-with-bg"
        >
          {paymentInstruction && <PaymentInstructionDetails paymentInstructionId={paymentInstruction.id!} />}
          {paymentInstructionTemplate && (
            <PaymentInstructionTemplateDetails templateId={paymentInstructionTemplate.id!} isOverview={true} />
          )}
        </ActionPage>
      </React.Fragment>
    </Page>
  )
}

export default PaymentOverview
