import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Select, Collapse, Radio, Button, RadioChangeEvent, Input } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import CreditCard from '../../../../components/CreditCard/CreditCard'
import { Source } from '../../../../types/source'
import { PaymentsFilter as PaymentsFilterType, FilterFields } from '../../../../types/paymentInstruction'
import { pi, filters } from '../../../../lang/definitions'
import { useCurrentPayment } from 'stores/Payment'
import usePaymentsFilterStyle from './PaymentsFilter.style'

const { Panel } = Collapse

const DEFAULT_PAYMENT_FILTER = {
  pendingApprovals: 'all',
  state: 'all',
  currency: 'all',
  card: undefined,
  beneficiary: '',
}

interface PaymentsFilterProps {
  filterFields: FilterFields[]
  filter?: PaymentsFilterType
  setDropdownVisible: (visible: boolean) => void
}

const PaymentsFilter = (props: PaymentsFilterProps): React.JSX.Element => {
  const { filterFields, filter, setDropdownVisible } = props

  const {
    state: { cards, currencies },
    actions: { setPaymentsFilter },
  } = useCurrentPayment()

  const intl = useIntl()
  const { styles } = usePaymentsFilterStyle()

  const [filterValues, setFilterValues] = useState<PaymentsFilterType>(DEFAULT_PAYMENT_FILTER)
  const [activePanel, setActivePanel] = useState<string | string[]>()

  const handleFilterUpdate = (value: string, field: FilterFields): void => {
    const updatedFilter = { ...filterValues } as PaymentsFilterType

    switch (field) {
      case FilterFields.PENDING_APPROVALS:
        updatedFilter.pendingApprovals = value
        break
      case FilterFields.STATE:
        updatedFilter.state = value
        break
      case FilterFields.CURRENCY:
        updatedFilter.currency = value
        break
      case FilterFields.CARD:
        updatedFilter.card = value
        break
      case FilterFields.BENEFICIARY:
        updatedFilter.beneficiary = value
        break
    }

    setFilterValues(updatedFilter)
  }

  const handleFilterClear = (): void => {
    setFilterValues(DEFAULT_PAYMENT_FILTER)
    setPaymentsFilter({})
  }

  const handleApplyFilter = (): void => {
    const values: PaymentsFilterType = {}

    Object.keys(filterValues).forEach((key: string) => {
      if (filterValues[key] !== 'all') {
        values[key] = filterValues[key]
      }
    })
    setPaymentsFilter(values)
    setDropdownVisible(false)
  }

  return (
    <div className={styles.dropdownFilterWrapper}>
      <Collapse
        expandIconPosition="end"
        className={styles.dropdownFilter}
        activeKey={activePanel}
        onChange={(keys: string | string[]) => {
          setActivePanel(keys[keys.length - 1])
        }}
      >
        {filterFields.includes(FilterFields.PENDING_APPROVALS) && (
          <Panel
            className={styles.filterPanel}
            key={0}
            header={intl.formatMessage(pi['pi.add.reviewPayments.filter.pendingApprovals.placeholder'])}
          >
            <div className={styles.paymentsFilterContainer}>
              <Radio.Group
                value={filterValues.pendingApprovals}
                onChange={(event: RadioChangeEvent) =>
                  handleFilterUpdate(event.target.value as string, FilterFields.PENDING_APPROVALS)
                }
              >
                <div className={styles.radioWrapper}>
                  <Radio value="all" className={styles.filterRadio}>
                    {intl.formatMessage(pi['pi.add.reviewPayments.filter.pendingApprovals.option.all'])}
                  </Radio>
                </div>
                <div className={styles.radioWrapper}>
                  <Radio value="one" className={styles.filterRadio}>
                    {intl.formatMessage(pi['pi.add.reviewPayments.filter.pendingApprovals.option.one'])}
                  </Radio>
                </div>
                <div className={styles.radioWrapper}>
                  <Radio value="multiple" className={styles.filterRadio}>
                    {intl.formatMessage(pi['pi.add.reviewPayments.filter.pendingApprovals.option.multiple'])}
                  </Radio>
                </div>
              </Radio.Group>
            </div>
          </Panel>
        )}
        {filterFields.includes(FilterFields.STATE) && (
          <Panel
            className={styles.filterPanel}
            key={1}
            header={intl.formatMessage(pi['pi.add.payments.filter.dropdown.state'])}
          >
            <div className={styles.paymentsFilterContainer}>
              <Radio.Group
                value={filterValues.state}
                onChange={(event: RadioChangeEvent) =>
                  handleFilterUpdate(event.target.value as string, FilterFields.STATE)
                }
              >
                <div className={styles.radioWrapper}>
                  <Radio value="all" className={styles.filterRadio}>
                    {intl.formatMessage(pi['pi.add.reviewPayments.filter.state.option.all'])}
                  </Radio>
                </div>
                <div className={styles.radioWrapper}>
                  <Radio value="assigned" className={styles.filterRadio}>
                    {intl.formatMessage(pi['pi.add.reviewPayments.filter.state.option.assigned'])}
                  </Radio>
                </div>
                <div className={styles.radioWrapper}>
                  <Radio value="unassigned" className={styles.filterRadio}>
                    {intl.formatMessage(pi['pi.add.reviewPayments.filter.state.option.unassigned'])}
                  </Radio>
                </div>
                <div className={styles.radioWrapper}>
                  <Radio value="payable" className={styles.filterRadio}>
                    {intl.formatMessage(pi['pi.add.reviewPayments.filter.state.option.payable'])}
                  </Radio>
                </div>
              </Radio.Group>
            </div>
          </Panel>
        )}
        {filterFields.includes(FilterFields.CURRENCY) && (
          <Panel
            className={styles.filterPanel}
            key={3}
            header={intl.formatMessage(pi['pi.add.payments.filter.dropdown.currency'])}
          >
            <div className={styles.paymentsFilterContainer}>
              <Radio.Group
                value={filterValues.currency}
                onChange={(event: RadioChangeEvent) =>
                  handleFilterUpdate(event.target.value as string, FilterFields.CURRENCY)
                }
              >
                <div className={styles.radioWrapper}>
                  <Radio value="all" className={styles.filterRadio}>
                    {intl.formatMessage(pi['pi.add.reviewPayments.filter.state.option.all'])}
                  </Radio>
                </div>
                {currencies?.map(
                  (currency: string): React.ReactNode => (
                    <div key={`currency-${currency}`} className={styles.radioWrapper}>
                      <Radio value={currency} className={styles.filterRadio}>
                        {currency.toUpperCase()}
                      </Radio>
                    </div>
                  )
                )}
              </Radio.Group>
            </div>
          </Panel>
        )}
        {filterFields.includes(FilterFields.CARD) && (
          <Panel
            className={styles.filterPanel}
            key={4}
            header={intl.formatMessage(pi['pi.add.payments.filter.dropdown.card'])}
          >
            <div className={styles.paymentsFilterContainer}>
              <Select
                placeholder={intl.formatMessage(pi['pi.add.reviewPayments.filter.card.placeholder'])}
                allowClear={true}
                popupMatchSelectWidth={false}
                value={filter?.card}
                onChange={(value) => handleFilterUpdate(value, FilterFields.CARD)}
                labelRender={({ value }) => {
                  const cardTitle = cards?.find((card: Source) => card.id === value)?.title
                  return <div>{cardTitle}</div>
                }}
              >
                {cards?.map(
                  (card: Source): React.ReactNode => (
                    <Select.Option key={`card-${card.id}`} value={card.id}>
                      <CreditCard card={card} />
                    </Select.Option>
                  )
                )}
              </Select>
            </div>
          </Panel>
        )}
        {filterFields.includes(FilterFields.BENEFICIARY) && (
          <Panel
            className={styles.filterPanel}
            key={5}
            header={intl.formatMessage(pi['pi.add.payments.filter.beneficiary'])}
          >
            <div className={styles.paymentsFilterContainer}>
              <Input
                type={'text'}
                value={filterValues.beneficiary}
                onChange={(event) => handleFilterUpdate(event.target.value, FilterFields.BENEFICIARY)}
              />
            </div>
          </Panel>
        )}
      </Collapse>
      <div className={styles.buttonsWrapper}>
        <Button type="primary" ghost size="large" onClick={handleApplyFilter}>
          {intl.formatMessage(filters['filter.apply'])}
        </Button>
      </div>
      <Button type="link" className={styles.clearBtn} icon={<CloseOutlined />} onClick={() => handleFilterClear()}>
        {intl.formatMessage(filters['filter.clear'])}
      </Button>
    </div>
  )
}

export default PaymentsFilter
