import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import * as Sentry from '@sentry/react'
import { useHistory } from 'react-router-dom'
import { useLanguageState } from 'stores/language/LanguageStore'
import { Source } from 'types/source'
import { Button, Modal, notification, Table } from 'antd'
import { sourceColumns, useGetCardIdColumn } from 'pages/Cards/utils'
import { resetDrawerHash, setDrawerHash } from 'components/Drawers/utils'
import ActionPage from 'components/ActionPage/ActionPage'
import ACL from 'components/ACL/ACL'
import CardDetails from 'pages/Cards/CardDetails'
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { messages } from 'lang/definitions/message'
import { deleteSource } from 'api/card'
import { card } from 'lang/definitions/card'
import { useOrganisationHierarchy, useOrganisationHierarchyUtils } from 'stores/OrganisationHierarchy/hooks'
import { useSession } from 'stores/session'

const EntityCards = (): React.JSX.Element => {
  const intl = useIntl()
  const history = useHistory()

  const {
    state: { sources },
  } = useOrganisationHierarchy()

  const { getSources } = useOrganisationHierarchyUtils()

  const [languageState] = useLanguageState()
  const language = languageState.language

  const [source, setSource] = useState<Source>({} as Source)
  const [profileId] = useState<string>()
  const [isDeleteSourceWindowOpen, setIsDeleteSourceWindowOpen] = useState(false)

  const cardColumnsKeys = ['icon', 'title', 'number', 'cardExpiryDate', 'entities', 'preferredCurrency']
  const { state: sessionState } = useSession()
  const rules = sessionState.rules!
  const isCardIdRuleEnabled = rules.toggles.customCardIdByCustomer?.rule
  const getCardIdColumn = useGetCardIdColumn()

  const columns = () => {
    const baseColumns = sourceColumns(language, intl).filter((c) => cardColumnsKeys.includes(c.key || ''))
    return isCardIdRuleEnabled ? [...baseColumns, getCardIdColumn()] : baseColumns
  }

  const selectSource = (id: string): void => {
    const source = sources && sources.find((source: Source) => source && source.id === id)

    if (source) setSource(source)
  }

  const openDetails = (id: string): void => {
    selectSource(id)
    setDrawerHash(history, `#drawer-details?key=${id}`)
  }
  const openDelete = (id: string): void => {
    const source = sources && sources.find((source: Source) => source && source.id === id)

    if (source) {
      !isDeleteSourceWindowOpen ? setIsDeleteSourceWindowOpen(true) : setIsDeleteSourceWindowOpen(false)
      showDeleteConfirmationModal(source)
    }
  }
  const handleDeleteCard = async (id: string): Promise<void> => {
    try {
      await deleteSource(id)
      void getSources()
      notification.success({
        message: intl.formatMessage(messages['messages.success.cards.delete']),
        placement: 'topRight',
      })
    } catch (error) {
      notification.warning({
        message: intl.formatMessage(messages['messages.error.cards.delete']),
        description: intl.formatMessage(messages['messages.errorDescription.cards.delete']),
        placement: 'topRight',
      })
      Sentry.captureException(error)
    }
  }
  const showDeleteConfirmationModal = (source: Source) => {
    const { id } = source
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      content: intl.formatMessage(messages['messages.prompt.deleteSource']),
      okText: intl.formatMessage(messages['messages.prompt.ok']),
      cancelText: intl.formatMessage(messages['messages.prompt.cancel']),
      onOk: () => id && handleDeleteCard(id),
      okButtonProps: {
        style: {
          backgroundColor: '#C15A5A',
          border: 'none',
          outline: 'none',
          fontWeight: 'bold',
        },
      },
    })
  }

  useEffect(() => {
    void getSources()
  }, [])
  const showDrawersFromHash = (): void => {
    const windowHash = window.location.hash
    if (windowHash && sources) {
      const isDetails = windowHash.includes('details')
      const hashArray = windowHash.split('?key=')
      const hashId = hashArray[1]

      isDetails && openDetails(hashId)
    }
  }

  useEffect(() => {
    void showDrawersFromHash()
  }, [window.location.hash, sources])

  return (
    <React.Fragment>
      <Table
        data-testid="console-cards-table"
        size="small"
        className="bh-table"
        rowClassName="clickable"
        dataSource={sources}
        columns={columns()}
        showSorterTooltip={false}
        rowKey={(record: { id: string }) => `${record.id}`}
        onRow={(record: { id: string }) => {
          return {
            onClick: () => {
              openDetails(record.id)
            },
          }
        }}
      />
      <ActionPage
        title={intl.formatMessage(card['card.slider.label.details'])}
        hash={`#drawer-details?key=${source.id}`}
        pathname={history.location.pathname}
        additionalClass="bh-action-page"
      >
        <CardDetails
          source={source}
          refresh={() => {
            void getSources()
            resetDrawerHash(history)
          }}
        />
        <div className="actions-container">
          <ACL kind="source" barracksId={profileId} action="delete">
            <Button icon={<DeleteOutlined />} size="large" type="primary" danger onClick={() => openDelete(source.id)}>
              {intl.formatMessage(card['card.form.delete'])}
            </Button>
          </ACL>
        </div>
      </ActionPage>
    </React.Fragment>
  )
}

export default EntityCards
