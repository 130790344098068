import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css, responsive }) => ({
  payContainer: css`
    background-color: transparent;
    height: 100%;

    ${responsive.sm} {
      width: 100%;
      height: 100vh;
    }
  `,
  addPaymentsPage: css`
    overflow: hidden;
  `,
  backBtn: css`
    position: absolute;
    top: 30px;
    right: 20px;
    cursor: pointer;
  `,
  mobilePaymentCounts: css`
    display: none;

    ${responsive.md} {
      display: inline-block;
      position: absolute;
      right: 35px;
      top: 30px;
    }
  `,
  receiptIcon: css`
    display: block;
  `,
  add: css`
    .slick-slide {
      padding: 53px 3px 0px 0px;
      ${responsive.sm} {
        padding: 0px 3px 0px 0px;
      }
      &:first-child {
        padding-top: 0;
        padding-right: 4px;
      }
    }
  `,
  review: css`
    .slick-slide {
      padding: 0px 3px 3px 0px;
      &:first-child {
        padding-top: 0;
        padding-right: 12px;
      }
    }
  `,
  payCarousel: css`
    .slick-slide {
      padding: 25px;
      height: 100%;
    }

    ${responsive.sm} {
      height: fit-content;
      padding: 10px;
      display: flex !important;

      &:first-child {
        padding-left: 0px !important;
      }
      &:last-child {
        padding-right: 0px !important;
      }

      & > div {
        display: flex;
        flex-grow: 1;
        max-width: 100%;
      }
    }
  `,
  payContainerStep: css`
    height: 100%;
    display: flex !important;
    flex-direction: column;
  `,
  transparent: css`
    background-color: transparent;
  `,
  disabled: css`
    cursor: not-allowed;
    opacity: 0.2;
    pointer-events: none;
  `,
  withBackground: css`
    background-color: white;
  `,
  scrollable: css`
    overflow-y: auto;
  `,
  disableScrollbars: css`
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */

    &::-webkit-scrollbar {
      width: 0px;
      background: transparent; /* Chrome/Safari/Webkit */
    }
  `,
}))

export default useStyles
