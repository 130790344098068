import { notification } from 'antd'

export function getError(error: unknown): Error {
  if (error instanceof Error) return error
  return new Error(JSON.stringify(error))
}

export function notifyError(message: string) {
  notification.error({
    message,
    placement: 'topRight',
  })
}
