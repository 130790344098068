import React from 'react'
import { useWindowSize } from 'hooks/useWindowSize'
import { DEVICE_TYPE, getDeviceType, Size } from 'utils/getDeviceType'
import useSplitContainerStyles from './SplitContainer.style'

interface Props {
  children: React.ReactNode
}

export const SplitContainer: React.FC<Props> = ({ children }: Props) => {
  const size: Size = useWindowSize()
  const { styles } = useSplitContainerStyles()

  return (
    <div className={styles.splitContainer}>
      {getDeviceType(size) === DEVICE_TYPE.DESKTOP ? <div className={styles.leftSideSplit}></div> : null}
      <div className={styles.rightSideSplit}>{children}</div>
    </div>
  )
}
