import React, { useEffect } from 'react'
import { Form, FormInstance } from 'antd'
import { RecipientTitle, AccountNumber, TypeSelector } from './FormItems'
import { SpaceForm } from '../components'
import { useTranslation } from '../../../utils/helpers'
import { useIbanAccountNumberValidator, IbanMaxLength } from 'utils/validators'

interface IBANProps {
  getFormInstance?: (form: FormInstance) => void
  onFinish?: (values: IBANForm) => void
}

export type IBANForm = {
  title: string
  accountNumber: string
  type: string
}

const IBAN = ({ getFormInstance, onFinish }: IBANProps): React.JSX.Element => {
  const [ibanForm] = Form.useForm<IBANForm>()
  const t = useTranslation()

  useEffect(() => {
    getFormInstance?.(ibanForm)
  }, [ibanForm])

  const accountNumberValidator = useIbanAccountNumberValidator()

  return (
    <Form form={ibanForm} layout="vertical" requiredMark={false} name="iban" onFinish={onFinish}>
      <SpaceForm>
        <RecipientTitle />

        <AccountNumber label={t('beneficiary.iban')} validator={accountNumberValidator} maxLength={IbanMaxLength} />

        <TypeSelector type="beneficiary" />
      </SpaceForm>

      <button type="submit" style={{ display: 'none' }} />
    </Form>
  )
}
export default IBAN
