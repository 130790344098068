import React from 'react'
import { PageHeader } from '@ant-design/pro-layout'
import classNames from 'classnames'
import Logo from 'assets/logo/billhop-dark-gray.svg'
import Loader from '../Loader/Loader'
import AlertMessagesContainer from '../AlertMessage/AlertMessagesContainer'
import useExternalContainerStyles from './ExternalContainer.style'
interface Props {
  children: React.ReactNode
  title?: string
  subTitle?: string
  loading?: boolean
  contentBelow?: React.ReactNode[] // displays Components below the visual container (e.g countryPicker at login)
  showAlertMessages?: boolean
  bhTrackClassName?: string
  ['data-testid']?: string
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ExternalContainer = (props: Props) => {
  const { styles } = useExternalContainerStyles()
  const { children, title, subTitle, loading, contentBelow, showAlertMessages, bhTrackClassName } = props

  return (
    <div
      data-testid={props['data-testid']}
      className={classNames(
        styles.externalContainer,
        {
          ['justify-center']: !showAlertMessages,
        },
        bhTrackClassName
      )}
    >
      {showAlertMessages && (
        <div className={styles.externalAlertMessageContainer}>
          <AlertMessagesContainer placement={['login', 'everywhere']} />
        </div>
      )}
      <div className={styles.externalContainerWrapper}>
        <div className={styles.externalContainerInner}>
          <div className={styles.logoContainer}>
            <img src={Logo} className={styles.logoImg} />
          </div>
          {title && <PageHeader className={styles.externalContainerHeading} title={title} subTitle={subTitle} />}
          <Loader showLoader={loading || false} delay={0} bg="white">
            {children}
          </Loader>
        </div>
      </div>
      {contentBelow?.length && contentBelow.map((content) => content)}
    </div>
  )
}

export default ExternalContainer
