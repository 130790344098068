import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import dayjs from 'dayjs'
import { Calendar, Button } from 'antd'
import { Holiday } from 'types/greg'
import { CardNetworkBankDay } from 'types/paymentInstruction'
import { formatCardNetworksBankDays } from '../../utils'
import { pi } from 'lang/definitions'
import useDueDateSelectorStyle from './DueDateSelector.style'
import { cx } from 'antd-style'

const DATE_FORMAT = 'YYYY-MM-DD'

interface DueDateSelectorInterface {
  bankDays: CardNetworkBankDay[]
  bankHolidays: Holiday[]
  dateDue?: dayjs.Dayjs
  onSelectDueDate: (date: dayjs.Dayjs) => void
}
const DueDateSelector = (props: DueDateSelectorInterface): React.JSX.Element => {
  const { bankDays, bankHolidays, dateDue, onSelectDueDate } = props

  const intl = useIntl()
  const { styles } = useDueDateSelectorStyle()

  const [hoveredDay, setHoveredDay] = useState<dayjs.Dayjs>()
  const [selectedDay, setSelectedDay] = useState<dayjs.Dayjs>()

  useEffect(() => {
    if (dateDue) {
      setSelectedDay(dateDue)
    }
  }, [])

  const findBankDateIndex = (date: dayjs.Dayjs): number => {
    const formatedBankDays = formatCardNetworksBankDays(
      bankDays.map((day: CardNetworkBankDay) => ({
        cardNetwork: day.cardNetwork,
        bankDay: getDay(day.bankDay),
      }))
    )
    const bankDateIndex = formatedBankDays.findIndex(
      (day: CardNetworkBankDay) => day.bankDay.format(DATE_FORMAT) === date.format(DATE_FORMAT)
    )

    return bankDateIndex
  }

  const isBankHoliday = (date: dayjs.Dayjs): boolean => {
    const bankHoliday = bankHolidays.find((holiday: Holiday) => {
      return holiday.date === date.format(DATE_FORMAT)
    })
    return bankHoliday !== undefined
  }

  const isDateBeforeFirstBankDay = (date: dayjs.Dayjs): boolean => {
    if (!date || !bankDays.length) return false
    return date.isBefore(bankDays[0].bankDay)
  }

  const isWeekendDate = (date: dayjs.Dayjs): boolean => {
    const day = date.day()

    return day === 6 || day === 0 // 6 = Saturday; 0 = Sunday
  }

  const isDisabledDate = (date: dayjs.Dayjs): boolean => {
    return isWeekendDate(date) || isDateBeforeFirstBankDay(date) || isBankHoliday(date)
  }

  const onSelect = (date: dayjs.Dayjs): void => {
    const formatedDate = date.format(DATE_FORMAT)
    setSelectedDay(dayjs(formatedDate))
  }

  const handleOnMouseOver = (date: dayjs.Dayjs): void => {
    setHoveredDay(date)
  }

  const handleOnMouseLeave = (): void => {
    setHoveredDay(undefined)
  }

  const cellRenderDate = (date: dayjs.Dayjs) => {
    const disabled = isDisabledDate(date)
    const bankDateIndex = findBankDateIndex(date)
    const dateClassName = cx(styles.date, {
      [styles.disabled]: disabled,
      [styles.bankDate]: bankDateIndex !== -1,
      [styles.red]: bankDateIndex === 1,
      [styles.selected]: selectedDay && date.format(DATE_FORMAT) === selectedDay.format(DATE_FORMAT),
    })
    return (
      <div
        className={styles.calendarCell}
        data-testid="calendar-cell"
        onMouseOver={() => !disabled && handleOnMouseOver(date)}
        onMouseLeave={handleOnMouseLeave}
        onClick={() => !disabled && onSelect(date)}
        id={date.toISOString().split('T')[0]}
      >
        <div className={dateClassName}>{date.date()}</div>
      </div>
    )
  }

  const cellRenderMonth = (date: dayjs.Dayjs) => {
    const dateClassName = cx(styles.monthDate, {
      [styles.selectedMonth]: selectedDay && date.format(DATE_FORMAT) === selectedDay.format(DATE_FORMAT),
    })
    return (
      <div
        className={styles.calendarCellMonth}
        onMouseOver={() => handleOnMouseOver(date)}
        onMouseLeave={handleOnMouseLeave}
        onClick={() => onSelect(date)}
        id={date.toISOString().split('T')[0]}
      >
        <div className={dateClassName}>{date.format('MMM')}</div>
      </div>
    )
  }

  const getDay = (bankDay: dayjs.Dayjs): dayjs.Dayjs => {
    if (hoveredDay) {
      if (hoveredDay.isAfter(bankDay)) {
        return hoveredDay
      } else {
        return bankDay
      }
    } else if (selectedDay && selectedDay.isAfter(bankDay)) {
      return selectedDay
    } else {
      return bankDay
    }
  }

  const isUpdateButtonDisabled = (): boolean => {
    return !selectedDay || selectedDay.format(DATE_FORMAT) === dateDue?.format(DATE_FORMAT)
  }
  return (
    <div className={styles.dateDueSelectorContainer} data-testid="date-due-drawer">
      <Calendar
        fullscreen={false}
        fullCellRender={(date, info) => (info.type === 'date' ? cellRenderDate(date) : cellRenderMonth(date))}
      />
      <div className={styles.bankDaysContainer}>
        {formatCardNetworksBankDays(
          bankDays.map((day: CardNetworkBankDay) => ({
            cardNetwork: day.cardNetwork,
            bankDay: getDay(day.bankDay),
          }))
        ).map((day: CardNetworkBankDay, index: number): React.ReactNode => {
          const dateClassName = cx(styles.bankDateDay, {
            [styles.red]: index > 0,
          })

          return (
            <div key={`bank-day-${day.cardNetwork}`} className={styles.bankDaysDate}>
              <div className={dateClassName}>{day.bankDay.date()}</div>
              <span className={styles.cardNetwork}>{day.cardNetwork}</span>
            </div>
          )
        })}
      </div>

      <div className={styles.updateButtonContainer}>
        <Button
          type="primary"
          size="large"
          block
          disabled={isUpdateButtonDisabled()}
          onClick={() => onSelectDueDate(selectedDay!)}
          data-testid="submit-date-due-btn"
        >
          {intl.formatMessage(pi['pi.add.addPayments.dateDueForm.update'])}
        </Button>
      </div>
    </div>
  )
}

export default DueDateSelector
