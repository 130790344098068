import { uniq } from 'lodash'
import { FileDownloadQueryParams } from '../types/general'

const SESSION_ID_STORAGE_KEY = 'sessionId'
const AUTHENTICATED_STATUS = 'authenticated'
const DEVICE_TOKEN_KEY = 'deviceToken'
const IS_PROFILE_CHOSEN = 'profileChosen'
const PREFERRED_LANGUAGE = 'preferredLanguage'
const PREFERRED_LOGIN_METHOD = 'preferredLoginMethod'
const WELCOME_USER_IDS = 'welcomeUserIds'
const REMEMBER_SSN = 'rememberSSN'
const REMEMBER_SSO = 'rememberSSO'
const PASSWORD_TOKEN = 'passwordToken'
const TOKEN_DATE_EXPIRE = 'tokenDateExpire'
const FILE_DOWNLOAD_TRIGGER = 'fileDownloadTrigger'
const SSO_PENDING_PROVIDER = 'ssoPendingProvider'

export const savePasswordToken = (value: string): void => {
  localStorage.setItem(PASSWORD_TOKEN, value)
}

export const removePasswordToken = (): void => {
  localStorage.removeItem(PASSWORD_TOKEN)
}

export const getPasswordToken = (): string | undefined => {
  return localStorage.getItem(PASSWORD_TOKEN) || undefined
}

export const setTokenDateExpire = (value: string): void => {
  localStorage.setItem(TOKEN_DATE_EXPIRE, value)
}

export const removeTokenDateExpire = (): void => {
  localStorage.removeItem(TOKEN_DATE_EXPIRE)
}

export const getTokenDateExpire = (): string | undefined => {
  return localStorage.getItem(TOKEN_DATE_EXPIRE) || undefined
}

export const saveSessionId = (value: string): void => {
  localStorage.setItem(SESSION_ID_STORAGE_KEY, value)
}

export const getSessionId = (): string | undefined => {
  return localStorage.getItem(SESSION_ID_STORAGE_KEY) || undefined
}

export const removeSessionId = (): void => {
  localStorage.removeItem(SESSION_ID_STORAGE_KEY)
}

/**
 * this function is used when we need to know if we are authenticated and logged in.
 * e.g of usage: only display modal on error if you are logged in. so we dont show modal on error when not logged in (e.g login form etc).
 */
export const saveAuthenticationStatus = (): void => {
  localStorage.setItem(AUTHENTICATED_STATUS, 'true')
}

export const getAuthenticationStatus = (): string | null => {
  return localStorage.getItem(AUTHENTICATED_STATUS)
}

export const removeAuthenticationStatus = (): void => {
  localStorage.removeItem(AUTHENTICATED_STATUS)
}

export const saveFileDownloadTriggerInfo = (data: FileDownloadQueryParams): void => {
  localStorage.setItem(FILE_DOWNLOAD_TRIGGER, JSON.stringify(data))
}

export const getFileDownloadTriggerInfo = (): FileDownloadQueryParams | null => {
  const dataStringified = localStorage.getItem(FILE_DOWNLOAD_TRIGGER)

  if (!dataStringified) {
    return null
  }

  return JSON.parse(dataStringified)
}

export const removeFileDownloadTriggerInfo = (): void => {
  localStorage.removeItem(FILE_DOWNLOAD_TRIGGER)
}

export const saveDeviceToken = (value: string): void => {
  localStorage.setItem(DEVICE_TOKEN_KEY, value)
}

export const getDeviceToken = (): string | null => {
  return localStorage.getItem(DEVICE_TOKEN_KEY)
}

export const saveIsProfileChosen = (value: string): void => {
  return localStorage.setItem(IS_PROFILE_CHOSEN, value)
}

export const getIsProfileChosen = (): string | null => {
  return localStorage.getItem(IS_PROFILE_CHOSEN)
}

export const removeIsProfileChosen = (): void => {
  localStorage.removeItem(IS_PROFILE_CHOSEN)
}

export const savePreferredLanguage = (value: string): void => {
  localStorage.setItem(PREFERRED_LANGUAGE, value)
}

export const getPreferredLanguage = (): string | undefined => {
  return localStorage.getItem(PREFERRED_LANGUAGE) || undefined
}

export const removePreferredLanguage = (): void => {
  localStorage.removeItem(PREFERRED_LANGUAGE)
}

export const getWelcomeUserIds = (): string | null => {
  return localStorage.getItem(WELCOME_USER_IDS)
}

export const setWelcomeUserIds = (id: string): void => {
  const welcomeIDs = getWelcomeUserIds()
  const userIDs = welcomeIDs?.split(',') || []
  userIDs.push(id)
  const uniqUserIDs = uniq(userIDs)
  localStorage.setItem(WELCOME_USER_IDS, uniqUserIDs.toString())
}

export const setRememberSSN = (value: string): void => {
  localStorage.setItem(REMEMBER_SSN, value)
}

export const removeRememberSSN = (): void => {
  localStorage.removeItem(REMEMBER_SSN)
}

export const setRememberSSO = (value: boolean): void => {
  localStorage.setItem(REMEMBER_SSO, value.toString())
}

export const getRememberSSO = (): boolean => {
  return localStorage.getItem(REMEMBER_SSO) === 'true'
}

export const removeRememberSSO = (): void => {
  localStorage.removeItem(REMEMBER_SSO)
}

export const setPreferredLoginMethod = (value: string): void => {
  localStorage.setItem(PREFERRED_LOGIN_METHOD, value)
}

export const getPreferredLoginMethod = (): string | null => {
  return localStorage.getItem(PREFERRED_LOGIN_METHOD)
}

export const getSsoPendingProvider = (): string | null => {
  return localStorage.getItem(SSO_PENDING_PROVIDER)
}

export const setSsoPendingProvider = (value: string): void => {
  return localStorage.setItem(SSO_PENDING_PROVIDER, value)
}

export const removeSsoPendingProvider = (): void => {
  return localStorage.removeItem(SSO_PENDING_PROVIDER)
}
