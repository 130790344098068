import { defineMessages } from 'react-intl'

export const document = defineMessages({
  'document.tableTitle': {
    id: 'document.tableTitle',
    defaultMessage: 'Uploaded files',
    description: 'Table title of uploaded documents',
  },
  'document.typeOfDocument.select.placeholder': {
    id: 'document.typeOfDocument.select.placeholder',
    defaultMessage: 'Type of Document',
    description: 'Select option placeholder for type of document',
  },
  'document.typeOfDocument.proofOfIdentity': {
    id: 'document.typeOfDocument.proofOfIdentity',
    defaultMessage: 'Proof of Identity',
    description: 'Select option value for Proof of identity document',
  },
  'document.typeOfDocument.proofOfAddress': {
    id: 'document.typeOfDocument.proofOfAddress',
    defaultMessage: 'Proof of Address',
    description: 'Select option value for Proof of address document',
  },
  'document.typeOfDocument.organizationCertificate': {
    id: 'document.typeOfDocument.organizationCertificate',
    defaultMessage: 'Organization Certificate',
    description: 'Select option value for Organization certificate document',
  },
  'document.typeOfDocument.invoiceCopy': {
    id: 'document.typeOfDocument.invoiceCopy',
    defaultMessage: 'Invoice Copy',
    description: 'Select option value for Invoice copy document',
  },
  'document.table.column.type': {
    id: 'document.table.column.type',
    defaultMessage: 'Type',
    description: 'Title of column type in document table to show the type of documents',
  },
  'document.table.column.fileName': {
    id: 'document.table.column.fileName',
    defaultMessage: 'File Name',
    description: 'Title of column file name in document table to show the names of files uploaded',
  },
  'document.table.column.dateUploaded': {
    id: 'document.table.column.dateUploaded',
    defaultMessage: 'Date Uploaded',
    description: 'Title of column Date uploaded in document table to show the document uploaded date',
  },
  'document.table.column.comment': {
    id: 'document.table.column.comment',
    defaultMessage: 'Comment',
    description: 'Title of column Comment in document table to show the comment',
  },
  'document.table.column.download': {
    id: 'document.table.column.download',
    defaultMessage: 'Download',
    description: 'Title of column Download in document table to download a document',
  },
  'document.table.column.delete': {
    id: 'document.table.column.delete',
    defaultMessage: 'Delete',
    description: 'Title of column Delete in document table to delete a document',
  },
  'document.uploadDocument.button.description': {
    id: 'document.uploadDocument.button.description',
    defaultMessage: 'Upload Documents',
    description: 'Description on upload document button',
  },
  'document.attachment.uploader.text': {
    id: 'document.attachment.uploader.text',
    defaultMessage: 'Drag and drop here or browse',
    description: 'Attachment uploader text',
  },
  'document.noRecord.description': {
    id: 'document.noRecord.description',
    defaultMessage: 'No documents uploaded',
    description: 'Description on empty documents table',
  },
})
