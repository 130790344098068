import { Rules } from 'types/rules'

export default function getCurrencies(rules: Rules, clearingNetwork: string): string[] {
  const { clearingNetworks } = rules.logic

  const rule = clearingNetworks.rule.find(
    (rule: { clearingNetwork: string; currencies: string[] }) => rule.clearingNetwork === clearingNetwork
  )

  return rule?.currencies || []
}
