import React from 'react'
import { Radio, RadioChangeEvent, Space } from 'antd'
import { DELIVERY_OPTIONS } from './utils'
import { useIntl } from 'react-intl'
import { reports } from '../../lang/definitions/reports'

interface DeliveryProps {
  selectedOption: string
  handleChange: (value: RadioChangeEvent) => void
}

const DeliveryTypeSelect = ({ selectedOption, handleChange }: DeliveryProps): React.JSX.Element => {
  const intl = useIntl()
  return (
    <Radio.Group onChange={handleChange} value={selectedOption} data-testid="radio-group-delivery-type">
      <Space direction="horizontal">
        <Radio value={DELIVERY_OPTIONS.EMAIL}>{intl.formatMessage(reports['reports.delivery.option.email.me'])}</Radio>
        <Radio value={DELIVERY_OPTIONS.DOWNLOAD}>
          {intl.formatMessage(reports['reports.delivery.option.download'])}
        </Radio>
      </Space>
    </Radio.Group>
  )
}

export default DeliveryTypeSelect
