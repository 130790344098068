import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css, token }) => ({
  addUserDrawer: css`
    .ant-drawer-content-wrapper {
      width: 60% !important;
    }
  `,
  editUserDrawer: css`
    .ant-drawer-content-wrapper {
      width: 60% !important;
    }
  `,
  entityDrawerTabs: css`
    .ant-drawer-content-wrapper {
      width: 60% !important;
    }
  `,
  entityRolesLabels: css`
    span:nth-of-type(1) {
      font-weight: bold;
    }
  `,
  entityRolesWrapper: css`
    width: 100%;
    margin-bottom: 25px;
  `,
  roleCheckboxWrapper: css`
    display: flex !important;
    align-items: center;
  `,
  entityRoleRow: css`
    margin-bottom: 5px;
  `,
  rolesPermissionWrapper: css`
    height: 80px;
    display: 'flex';
    align-items: 'center';
    justify-content: 'center';
  `,
  rolesErrorMessage: css`
    color: ${token.colorError};
    font-weight: 400;
    font-size: ${token.fontSizeSM}px;
  `,
  childEntitiesTitle: css`
    font-weight: bold;
    font-size: 13px;
    margin-bottom: 5px;
  `,
  formNotValidated: css`
    margin-bottom: ${token.marginLG}px;
  `,
  truncatedContent: css`
    white-space: nowrap;
    text-overflow: ellipsis;
  `,
  entityColumnContainer: css`
    height: 30px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 15px;
  `,
  tagColumnContainer: css`
    height: 30px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  `,
  editUserHeading: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  `,
  userDetailsWrapper: css`
    background: white;
    border-radius: 10px;
    padding: 16px;
    margin-bottom: 32px;
  `,
  detailsTitle: css`
    display: block;
    font-size: ${token.fontSize}px;
  `,
  formActionContainer: css`
    margin-top: ${token.marginLG}px;
  `,
}))

export default useStyles
