import { defineMessages } from 'react-intl'

export const integration = defineMessages({
  'integration.active': {
    id: 'integration.active',
    defaultMessage: 'Active integrations',
    description: 'This string sets active integrations label',
  },
  'integration.available': {
    id: 'integration.available',
    defaultMessage: 'Available integrations',
    description: 'This string sets available integrations label',
  },
  'integrations.connect': {
    id: 'integration.connect',
    defaultMessage: 'Integrate',
    description: 'This string sets add integration button label',
  },
  'integrations.disconnect': {
    id: 'integration.disconnect',
    defaultMessage: 'Disconnect',
    description: 'This string sets remove integration button label',
  },
  'integrations.cancel': {
    id: 'integration.cancel',
    defaultMessage: 'Cancel',
    description: 'This string sets cancel integration button label',
  },
  'integrations.retry': {
    id: 'integration.retry',
    defaultMessage: 'Retry',
    description: 'This string sets retry integration button label',
  },
  'integrations.error': {
    id: 'integration.error',
    defaultMessage: 'There was a connection error, please try again and if the error persists, contact Billhop Support',
    description: 'This string sets integration error label',
  },
  'integrations.by': {
    id: 'integrations.by',
    defaultMessage: 'By',
    description: 'This string sets integration by label',
  },
  'integrations.fee.account.missing': {
    id: 'integrations.fee.account.missing',
    defaultMessage: 'An expense account in Billhop',
    description: 'Expense account missing',
  },
  'integrations.credit.card.missing.message': {
    id: 'integrations.credit.card.missing.message',
    defaultMessage: 'No saved cards',
    description: 'No credit card',
  },
  'integrations.modal.incomplete.title': {
    id: 'integrations.modal.incomplete.title',
    defaultMessage: 'Connection not complete',
    description: 'Incomplete connection modal title',
  },
  'integrations.modal.incomplete.text': {
    id: 'integrations.modal.incomplete.text',
    defaultMessage: 'To complete the connection please add the following:',
    description: 'Incomplete connection modal text',
  },
  'integrations.modal.finish.button': {
    id: 'integrations.modal.finish.button',
    defaultMessage: 'Finish Integration',
    description: 'Finish incomplete integration button',
  },
  'integrations.modal.complete.button': {
    id: 'integrations.modal.complete.button',
    defaultMessage: 'Complete connection',
    description: 'Complete incomplete connection button',
  },
  'integrations.unexpected.error': {
    id: 'integrations.unexpected.error',
    defaultMessage: 'Unfortunately, we found an unexpected error. Please, try again later.',
    description: 'unexpected error',
  },
  'integrations.select.expense.account.error': {
    id: 'integrations.select.account.error',
    defaultMessage: 'Select an account to save',
    description: 'Error for expense account',
  },
})
