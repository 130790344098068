import React, { ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { Badge, Button, Input } from 'antd'
import { filters } from '../../lang/definitions'
import { RequiredACL, RulesLogic } from '../../types/rules'
import { useSession } from 'stores/session'
import usePageStyle from './Page.style'
import { cx } from 'antd-style'

const { Search } = Input

export interface MobileMenuOption {
  id?: string
  label: string
  callback?: () => void
  searchCallback?: (value: string) => void
  icon?: ReactElement
  className?: string
  badge?: {
    color?: string
    count: number
    filter?: boolean
  }
  requiredACL?: RequiredACL[]
  applicableEntityClasses?: string[]
  type?: 'button' | 'primary' | 'link' | 'dropdown-button' | 'other'
  requiredRuleSettings?: {
    kind: string
    action: (a: Partial<RulesLogic>) => boolean
  }
}

interface PageProps {
  title: string
  mobileMenuOptions: Array<MobileMenuOption>
  children?: ReactElement
  actionMenu?: ReactElement
  filter?: ReactElement
  secondaryMenu?: ReactElement
  mobileSearch?: Array<MobileMenuOption>
  goToSlide?: (slideNumber: number) => void
  numberOfPayments?: number
  slideNumber?: number
  setSlideNumber?: (slideNumber: number) => void
  bhTrackClassName?: string
}

const Page = (props: PageProps): React.JSX.Element => {
  const intl = useIntl()
  const { styles } = usePageStyle()
  const { state: sessionState } = useSession()
  const rules = sessionState.rules!

  const filterByRule = (item: {
    requiredRuleSettings?: {
      kind: string
      action: (a: Partial<RulesLogic>) => boolean
    }
  }) => {
    if (!item.requiredRuleSettings) {
      return true
    }

    const { kind, action } = item.requiredRuleSettings
    return action(rules.logic[kind] as Partial<RulesLogic>)
  }

  const renderMobileMenuOptions = (option: MobileMenuOption, index: number): React.ReactNode => {
    // TODO it is necessary to take into account other filters such as acl and entity type
    const isOptionFiltered = !filterByRule(option)

    if (option === undefined || isOptionFiltered) {
      return null
    }
    const button = (
      <Button
        key={`mobile-menu-button-${index}`}
        onClick={() => option.callback && option.callback()}
        icon={option?.icon ?? null}
        className={option?.className ?? ''}
        type={option.type === 'primary' ? 'primary' : 'default'}
      >
        {option?.badge?.filter ? (
          <div className={option?.className ?? ''}>
            <div className={styles.mobileFilterLabel}>{option?.label}</div>
            <div className={styles.mobileFilterBadge}>{option.badge?.count}</div>
          </div>
        ) : (
          (option?.label ?? '')
        )}
      </Button>
    )
    if (option?.badge && !option?.badge?.filter)
      return (
        <Badge
          key={`mobile-menu-badge-${index}`}
          count={option.badge.count}
          style={option.badge.color ? { backgroundColor: option.badge.color } : {}}
        >
          {button}
        </Badge>
      )

    return button
  }

  const renderSearchMobile = (option: MobileMenuOption, index: number): React.ReactNode => (
    <Search
      key={index}
      placeholder={intl.formatMessage(filters['filter.search.placeholder'])}
      onSearch={(value) => {
        option && option.searchCallback && option.searchCallback(value)
      }}
      allowClear={true}
      className={option?.className || styles.filterField}
    />
  )

  return (
    <div className={cx(styles.pageContainer, props.bhTrackClassName)}>
      <div className={styles.contentContainer}>
        <div className={styles.title}>
          <h2 data-testid="page-title">{props.title}</h2>
        </div>
        <div className={styles.topMenuContainer}>{props.actionMenu}</div>
        {props.mobileMenuOptions.length > 0 && (
          // TODO need to refactor - using indexes is bad
          <>
            <div className={styles.mobileMenu}>
              <div>{renderMobileMenuOptions(props.mobileMenuOptions[0], 0)}</div>
              {props.mobileMenuOptions.length > 0 && (
                <div>{renderMobileMenuOptions(props.mobileMenuOptions[1], 1)}</div>
              )}
            </div>
            {props.mobileMenuOptions.length > 1 && (
              <div className={styles.secondaryMobileMenu}>
                {props.mobileMenuOptions.map((option: MobileMenuOption, index: number) => {
                  if (index > 1) {
                    return renderMobileMenuOptions(option, index)
                  }
                  return null
                })}
              </div>
            )}
          </>
        )}
        <div className="mobile-display">{props.mobileSearch?.map(renderSearchMobile)}</div>
        {props.actionMenu}
        <div className={styles.filtersWrapper}>
          {props.filter}
          {props.secondaryMenu}
        </div>
        {props.children}
      </div>
    </div>
  )
}

export default Page
