import React from 'react'
import { Form, Input } from 'antd'
import { useTranslation } from '../../../../utils/helpers'
import useSuffix from './useSuffix'
import { GenericMaxLength, useLocalAddressValidator } from 'utils/validators'

export default function AddressCity(): React.JSX.Element {
  const t = useTranslation()

  const AddressCitySuffix = useSuffix({ fieldName: ['address', 'city'], maxLength: GenericMaxLength })
  const cityValidator = useLocalAddressValidator('city')

  return (
    <Form.Item
      label={t('beneficiary.form.address.city.label')}
      name={['address', 'city']}
      validateTrigger="onChange"
      hasFeedback
      rules={[
        {
          validator: cityValidator,
        },
      ]}
    >
      <Input
        type="text"
        size="large"
        data-testid="city-input-field"
        suffix={AddressCitySuffix}
        placeholder={t('beneficiary.form.address.city.placeholder')}
        maxLength={GenericMaxLength}
      />
    </Form.Item>
  )
}
