import { ShowCreatePiFields } from '../types/rules'
import { useSession } from 'stores/session'

export default function useShowCreatePiFields(): ShowCreatePiFields {
  const {
    state: { rules },
  } = useSession()

  const showCreatePiFields: ShowCreatePiFields = {
    dateDue: true,
    attachment: true,
    recurring: true,
    remittanceAdvice: true,
  }

  return rules?.logic?.showCreatePiFields?.rule || showCreatePiFields
}
