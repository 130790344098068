import React from 'react'
import { Form, Input } from 'antd'
import { CloseCircleFilled } from '@ant-design/icons'
import { useTranslation } from '../../../../utils/helpers'
import { useLocalAddressValidator } from 'utils/validators'

export default function AddressZip(): React.JSX.Element {
  const form = Form.useFormInstance()
  const t = useTranslation()
  const zipValidator = useLocalAddressValidator('zip')

  const clearZipField = () => {
    form.setFieldValue(['address', 'zip'], '')
  }

  return (
    <Form.Item
      label={t('beneficiary.form.address.zip.label')}
      name={['address', 'zip']}
      validateTrigger="onChange"
      hasFeedback
      rules={[
        {
          validator: zipValidator,
        },
      ]}
    >
      <Input
        type="text"
        size="large"
        data-testid="zip-input-field"
        allowClear={{
          clearIcon: <CloseCircleFilled onClick={clearZipField} data-testid="zip-clear-icon" />,
        }}
        placeholder={t('beneficiary.form.address.zip.placeholder')}
      />
    </Form.Item>
  )
}
