import { CarouselRef } from 'antd/lib/carousel'
import { useWindowSize } from 'hooks'
import { RefObject, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { SCREEN_LG } from 'utils/getDeviceType'
import useCurrentPayment from './useCurrentPayment'

export default function useProgressSlide(carouselRef: RefObject<CarouselRef>) {
  const size = useWindowSize()
  const history = useHistory()

  const {
    state: { slideNumber },
    actions: { setDisableReviewPage, setSlideNumber },
  } = useCurrentPayment()

  useEffect(() => {
    const hashPath: string = history.location.hash

    if ((size.width || 0) < SCREEN_LG) {
      if (!hashPath) {
        goToSlide(0)
      }
      if (hashPath === '#review-payments') {
        goToSlide(1)
      }
      if (hashPath === '#submit-payments') {
        goToSlide(2)
      }
    } else {
      if (hashPath === '#submit-payments' || hashPath === '#review-payments') {
        history.replace(window.location.pathname)
      }
    }
  }, [history.location.hash, size])

  const goToSlide = (slideNumber: number): void => {
    setSlideNumber(slideNumber)

    if (!carouselRef.current) {
      return
    }

    carouselRef.current?.goTo(slideNumber)
  }

  const goToProgressSlide = (): void => {
    if (!size.width) {
      return
    }

    if ((size.width || 0) < SCREEN_LG) {
      history.push('#submit-payments')
    } else {
      goToSlide(2)
    }
    setDisableReviewPage(true)
  }

  const goBackTwoSlides = () => {
    if ((size.width || 0) < SCREEN_LG) {
      // go 2 slides back
      history.go(-2)
    } else {
      goToSlide(0)
    }

    setDisableReviewPage(false) // TODO: check later
  }

  const handleSlide = () => {
    if (slideNumber === 0) {
      history.push('#review-payments')
    } else {
      history.goBack()
    }
  }

  return {
    goToProgressSlide,
    goBackTwoSlides,
    goToSlide,
    handleSlide,
  }
}
