import { useOrganisationHierarchy, useOrganisationHierarchyUtils } from 'stores/OrganisationHierarchy/hooks'
import { Col, Table, Modal, notification } from 'antd'
import { Entity } from 'types/entity'
import { useIntl } from 'react-intl'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { actionColumn, entityHierarchyColumns, findEntityFromHierarchy, getEntityTitle } from '../utils'
import Loader from 'components/Loader/Loader'
import * as api from 'api/user'
import * as Sentry from '@sentry/react'
import { setDrawerHash } from 'components/Drawers/utils'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'utils/helpers'
import { useSession } from 'stores/session'

const DEFAULT_PAGE_SIZE = 10

function EntityHierarchiesTab() {
  const intl = useIntl()
  const history = useHistory()
  const { state: sessionState } = useSession()
  const userId = sessionState.user!.user.id
  const t = useTranslation()
  const {
    state: {
      entityHierarchies,
      isFetchingEntityHierarchies,
      entityHierarchiesTotal,
      entityHierarchiesPage,
      selectedEntity,
    },
    actions: { setEntityHierarchiesPage, setSelectedEntity },
  } = useOrganisationHierarchy()
  const { getEntityHierarchies } = useOrganisationHierarchyUtils()

  const paginationProps = {
    total: entityHierarchiesTotal,
    pageSize: DEFAULT_PAGE_SIZE,
    current: entityHierarchiesPage,
    hideOnSinglePage: true,
    onChange: (page: number) => setEntityHierarchiesPage(page),
  }

  const openDelete = (id: string): void => {
    const entity = findEntityFromHierarchy(id, entityHierarchies)
    setSelectedEntity(entity)

    if (entity) {
      showDeleteConfirmationModal(entity)
    }
  }

  const showDeleteConfirmationModal = (data: Entity) => {
    const title = getEntityTitle(data)
    Modal.confirm({
      title: t('pi.modal.delete.header'),
      icon: <ExclamationCircleOutlined />,
      content: t('organizationSettings.entity.delete.description', { title }),
      okText: t('admin.modal.delete.text'),
      cancelText: t('admin.modal.cancel.text'),
      onOk: () => data.id && handleDeleteEntity(data.id),
      okButtonProps: {
        style: {
          backgroundColor: 'red',
          border: 'none',
          outline: 'none',
          fontWeight: 'bold',
        },
      },
    })
  }

  const handleDeleteEntity = async (id: string): Promise<void> => {
    try {
      if (id) {
        await api.deleteEntity(id)
        void getEntityHierarchies(userId)
        notification.success({
          message: t('entity.form.action.delete'),
          placement: 'topRight',
        })
      }
    } catch (error) {
      notification.warning({
        message: 'Action failed!',
        description: t('messages.error.user.delete'),
        placement: 'topRight',
      })
      Sentry.captureException(error)
    }
  }

  const onClickRow = (record: Entity) => {
    return {
      onClick: () => {
        findEntityFromHierarchy(record.id, entityHierarchies)
        setSelectedEntity(record)
        setDrawerHash(history, `#drawer-edit-entity?key=${record.id}`)
      },
    }
  }

  const setRowClassName = (record: Entity) => {
    return record.id === selectedEntity?.id ? 'click-row-styl' : ''
  }

  return (
    <Col>
      <Loader showLoader={isFetchingEntityHierarchies}>
        <Table
          size="small"
          className="bh-table"
          loading={isFetchingEntityHierarchies}
          rowKey={(record: Entity) => `entity-key-${record.id}`}
          dataSource={entityHierarchies}
          columns={[...entityHierarchyColumns(intl), ...actionColumn(intl, openDelete)]}
          pagination={paginationProps}
          onRow={(record) => onClickRow(record)}
          rowClassName={setRowClassName}
          locale={{
            emptyText: t('entity.not.added'),
          }}
        />
      </Loader>
    </Col>
  )
}

export default EntityHierarchiesTab
