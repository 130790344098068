import React from 'react'
import { Descriptions } from 'antd'
import iban from 'iban'
import { getClearingNetwork } from './utils'
import { Beneficiary } from '../../types/beneficiary'
import { useIntl } from 'react-intl'
import { beneficiaryStrings } from '../../lang/definitions/index'
import AccountNumber from '../../components/Format/AccountNumber/AccountNumber'
import { useSession } from 'stores/session'

interface BeneficiaryDetailsProps {
  beneficiary: Beneficiary
}

const BeneficiaryDetails = ({ beneficiary }: BeneficiaryDetailsProps): React.JSX.Element => {
  const intl = useIntl()
  const {
    state: { rules },
  } = useSession()
  const clearingNetwork = rules && getClearingNetwork(rules, beneficiary)

  return (
    <Descriptions bordered layout="vertical" size="small" className="bh-description-vertical">
      {beneficiary.title && (
        <Descriptions.Item label={intl.formatMessage(beneficiaryStrings['beneficiary.name'])} span={3}>
          {beneficiary.title}
        </Descriptions.Item>
      )}
      {beneficiary.clearingNetwork && (
        <Descriptions.Item label={intl.formatMessage(beneficiaryStrings['beneficiary.accountType'])} span={3}>
          {clearingNetwork?.label}
        </Descriptions.Item>
      )}
      {beneficiary.type && (
        <Descriptions.Item label={intl.formatMessage(beneficiaryStrings['beneficiary.recepientType'])} span={3}>
          {beneficiary.type}
        </Descriptions.Item>
      )}
      {beneficiary.accountNumber && (
        <Descriptions.Item
          label={
            iban.isValid(beneficiary.accountNumber)
              ? intl.formatMessage(beneficiaryStrings['beneficiary.IBAN'])
              : intl.formatMessage(beneficiaryStrings['beneficiary.accountNumber'])
          }
          span={3}
        >
          <AccountNumber
            accountNumber={beneficiary.accountNumber}
            routingNumber={beneficiary.routingNumber}
            clearingNetwork={beneficiary.clearingNetwork}
          ></AccountNumber>
        </Descriptions.Item>
      )}
      {beneficiary.routingNumber && (
        <Descriptions.Item
          label={
            clearingNetwork?.label === 'BACS'
              ? intl.formatMessage(beneficiaryStrings['beneficiary.sortCode'])
              : intl.formatMessage(beneficiaryStrings['beneficiary.routingNumber'])
          }
          span={3}
        >
          {beneficiary.routingNumber}
        </Descriptions.Item>
      )}
      {beneficiary.identifier?.value && (
        <Descriptions.Item label={intl.formatMessage(beneficiaryStrings['beneficiary.orgNumber'])} span={3}>
          {beneficiary.identifier.value}
        </Descriptions.Item>
      )}
      {beneficiary.email && (
        <Descriptions.Item label={intl.formatMessage(beneficiaryStrings['beneficiary.email'])} span={3}>
          {beneficiary.email}
        </Descriptions.Item>
      )}
      {beneficiary.phone && (
        <Descriptions.Item label={intl.formatMessage(beneficiaryStrings['beneficiary.phoneNumber'])} span={3}>
          {beneficiary.phone}
        </Descriptions.Item>
      )}
      {beneficiary.address.street && beneficiary.address.street[0] && (
        <Descriptions.Item label={intl.formatMessage(beneficiaryStrings['beneficiary.streetAddress'])} span={3}>
          {beneficiary.address.street && beneficiary.address.street[0]}
        </Descriptions.Item>
      )}
      {beneficiary.address.zip && (
        <Descriptions.Item label={intl.formatMessage(beneficiaryStrings['beneficiary.zipCode'])} span={3}>
          {beneficiary.address.zip}
        </Descriptions.Item>
      )}
      {beneficiary.address.city && (
        <Descriptions.Item label={intl.formatMessage(beneficiaryStrings['beneficiary.city'])} span={3}>
          {beneficiary.address.city}
        </Descriptions.Item>
      )}
    </Descriptions>
  )
}

export default BeneficiaryDetails
