import { PaymentInstructionReferencePrefixAndSuffixLength } from 'types/paymentInstruction'

export const extractPrefixAndSuffixFromReference = (
  referenceString: string,
  prefixAndSuffix: PaymentInstructionReferencePrefixAndSuffixLength
): string => {
  const { prefixLength, suffixLength } = prefixAndSuffix

  const prefixLengthWithWhitespace = prefixLength ? prefixLength + 1 : 0
  const suffixLengthWithWhitespace = suffixLength ? suffixLength + 1 : 0

  let end = referenceString.length - suffixLengthWithWhitespace

  if (!prefixLengthWithWhitespace && !suffixLengthWithWhitespace) {
    end = referenceString.length
  }

  const referenceWithoutPrefixAndSuffix = referenceString.slice(prefixLengthWithWhitespace, end)

  return referenceWithoutPrefixAndSuffix
}
