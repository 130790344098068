import * as Sentry from '@sentry/react'
import { ScopeContext } from '@sentry/types'

export const catchExceptionWithSentry = (
  error: Error | string,
  options: Partial<ScopeContext> = { level: 'error', tags: {} }
) => {
  const { level, tags } = options
  Sentry.captureException(error, {
    level,
    tags: {
      billhop_exception: true,
      repo: 'billhop-client',
      ...tags,
    },
  })
}
