import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { Result, Button } from 'antd'
import { useIntl } from 'react-intl'
import { page } from '../../lang/definitions/index'

/*eslint-disable */
interface RouteParameters {
  exact: boolean
  path: string
  name: string
  component: React.ComponentType<any>
  private?: boolean
  routes: Array<RouteParameters>
}
/*eslint-enable */

interface Props {
  routes: Array<RouteParameters>
}

const Admin = ({ routes }: Props): React.JSX.Element => {
  const intl = useIntl()

  return (
    <Switch>
      {routes &&
        routes.map((route: RouteParameters, i: number) => (
          <Route
            key={i}
            exact={route.exact}
            path={route.path}
            render={(routeProps) => {
              return <route.component {...routeProps} name={route.name} routes={route.routes} />
            }}
          />
        ))}
      <Result
        status="404"
        title={intl.formatMessage(page['page.404.title'])}
        subTitle={intl.formatMessage(page['page.404.subTitle'])}
        extra={
          <Button type="primary" onClick={() => (window.location.href = '/')}>
            {intl.formatMessage(page['page.404.link.back'])}
          </Button>
        }
      />
    </Switch>
  )
}

export default Admin
