import { QueryParams } from '../types/general'
import { ReportTemplate } from '../types/report'
import { api } from './utils'

const BASE_PATH = 'reporter/report-template'

export const getReportTemplate = async (
  profileId: string,
  params?: QueryParams
): Promise<{
  templates: Array<ReportTemplate>
  total?: number
}> => {
  const url = `/${BASE_PATH}/profile/${profileId}`

  const response = await api.get(url, params)

  const templates = (await response.json()) as Array<ReportTemplate>
  const total = response.headers.get('x-total-count')

  return {
    templates,
    total: Number.parseInt(total || '', 10),
  }
}

export const createReportTemplate = async (profileId: string, data: ReportTemplate): Promise<ReportTemplate> => {
  const url = `/${BASE_PATH}/profile/${profileId}`
  const response = await api.post(url, data)
  const value = (await response.json()) as ReportTemplate
  return value
}

export const updateReportTemplate = async (templateId: string, data: ReportTemplate): Promise<ReportTemplate> => {
  const url = `/${BASE_PATH}/${templateId}`
  const response = await api.put(url, data)
  const value = (await response.json()) as ReportTemplate
  return value
}

export const deleteReportTemplate = async (templateId: string): Promise<void> => {
  const url = `/${BASE_PATH}/${templateId}`

  await api.delete(url)
}
