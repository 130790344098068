import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import * as Sentry from '@sentry/react'

import { api } from 'api/utils'

interface ParamTypes {
  provider: string
}
interface SSOLink {
  redirectUrl: string
}
/**
 * A view used to trigger an automatic redicrect to the login page,
 * Ie. a link from a companies intranet will cerate a login request to Billhop automatically
 */
const SSOIntermediateLinkPage = (): React.JSX.Element => {
  const { provider } = useParams<ParamTypes>()

  useEffect(() => {
    const getUrl = async () => {
      const response = await api.get(`/external/zuul/sso/${provider}`)
      const result = (await response.json()) as SSOLink

      const { redirectUrl } = result
      window.location.href = redirectUrl
    }
    try {
      void getUrl()
    } catch (err) {
      Sentry.captureException(err)
    }
  }, [provider])

  return (
    <div className="sso-intermediate">
      <div>Loading 🤺</div>
    </div>
  )
}

export default SSOIntermediateLinkPage
