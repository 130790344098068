import React from 'react'
import usePaymentCardStyle from './PaymentCard.style'

interface PaymentCardProps {
  title: string
  number: number
  children?: React.JSX.Element | React.JSX.Element[]
  cancelAction?: () => void
  cancelText?: string
}

const PaymentCard = ({ title, number, children, cancelAction, cancelText }: PaymentCardProps): React.JSX.Element => {
  const { styles } = usePaymentCardStyle()

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.titleWrapper}>
          <div className={styles.number}>{number}</div>
          <div className={styles.title}>{title}</div>
        </div>
        {cancelText && (
          <span className={styles.cancelAction} onClick={cancelAction}>
            {cancelText}
          </span>
        )}
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  )
}

export default PaymentCard
