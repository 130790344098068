import { useEffect } from 'react'
import * as Sentry from '@sentry/react'
import useOrganisationHierarchy from './useOrganisationHierarchy'
import * as usersApi from '../../../api/user'
import * as barracksApi from '../../../api/barracks'
import * as rulesApi from '../../../api/rules'
import * as cardApi from '../../../api/card'
import { FilterBody } from 'components/Filter/Filter'
import { QueryParams } from 'types/general'
import { Profile } from 'types/profile'
import { notification } from 'antd'
import { useIntl } from 'react-intl'
import { messages } from 'lang/definitions'
import { getActiveUser } from 'utils/helpers'
import { Entity } from 'types/entity'
import { UserData } from 'types/user'
import { useSession } from 'stores/session'

export default function useOrganisationHierarchyUtils() {
  const intl = useIntl()
  const { state: sessionState } = useSession()
  const rules = sessionState.rules!
  const user = sessionState.user!

  const {
    actions: {
      setEntityHierarchies,
      setPaymentGateways,
      setCardManagedByAdminRule,
      setCardNetworks,
      setIsFetchingEntityHierarchies,
      setEntityHierarchiesTotal,
      setIsFetchingUsers,
      setUsers,
      setUsersTotal,
      setCountryOptions,
      setEntities,
      setSources,
      setProfileGroups,
      setIsFetchingProfileGroups,
      setProfiles,
    },
  } = useOrganisationHierarchy()

  useEffect(() => {
    let ruleList: Array<string> = []

    for (const [key, value] of Object.entries(rules.logic.cardNetworks.rule)) {
      if (value) ruleList = [...ruleList, key]
    }

    setCardNetworks(ruleList)
    setPaymentGateways(rules.logic.paymentGateways.rule)
    setCardManagedByAdminRule(!!rules.toggles?.cardManagedByAdmin?.rule)
  }, [rules])

  const getEntityHierarchies = async (userId: string): Promise<void> => {
    try {
      setIsFetchingEntityHierarchies(true)
      const { entities, total } = await usersApi.getEntityHierarchy(userId)
      setEntityHierarchiesTotal(total as number)
      setEntityHierarchies(entities)
    } catch (error) {
      Sentry.withScope(function (scope) {
        scope.setContext('getEntityHierarchy', {
          userId,
        })
        Sentry.captureException(error)
      })
    } finally {
      setIsFetchingEntityHierarchies(false)
    }
  }

  const getUsers = async (filterBody = {} as FilterBody, params?: QueryParams): Promise<void> => {
    setIsFetchingUsers(true)
    try {
      const profiles = user.profiles.filter(
        (profile: Profile) =>
          !profile.entity.class!.person &&
          (profile.entityRoles.includes('admin') || profile.entityRoles.includes('owner'))
      )

      const entityIds = profiles.map((profile: Profile) => profile.entityId)

      const filter: FilterBody = {
        ...filterBody,
        entityId: filterBody.entityId || [...entityIds],
      }

      const { users, total } = await usersApi.searchUsersByMultipleEntities(filter, params)
      setUsers(users)
      setUsersTotal(total)
    } catch (error) {
      Sentry.captureException(error)
    } finally {
      setIsFetchingUsers(false)
    }
  }

  const getCountryOptions = async (): Promise<void> => {
    try {
      const countries = await rulesApi.getCountries()
      setCountryOptions(countries)
    } catch (error) {
      notification.warning({
        message: 'Error!',
        description: intl.formatMessage(messages['messages.error.country.fetch']),
        placement: 'topRight',
      })
      Sentry.captureException(error)
    }
  }

  /**
   * Fetches entities for the current user, but only where the user's profile has the role of admin or owner,
   * because ACL requires that the user has the role of admin or owner for parent entity to create an child entity.
   * This is used to populate the parent entity dropdown in the create entity form.
   * @returns void
   */
  const getEntities = async (): Promise<void> => {
    const REQUIRED_ROLES = ['admin', 'owner']
    try {
      const onlyOwnerOrAdminProfiles = user.profiles.filter((profile: Profile) =>
        profile.entityRoles.some((role) => REQUIRED_ROLES.includes(role))
      )
      const profileIds = onlyOwnerOrAdminProfiles.map((profile: Profile) => profile.id)

      const { result } = await barracksApi.search<Entity>('entity', {
        entityClass: ['corp', 'sme'],
        profileId: profileIds,
      })
      setEntities(result)
    } catch (error) {
      notification.warning({
        message: 'Error!',
        description: intl.formatMessage(messages['messages.error.country.fetch']),
        placement: 'topRight',
      })
      Sentry.captureException(error)
    }
  }

  const getSources = async (): Promise<void> => {
    const { entityId } = getActiveUser(user)
    try {
      const filter: FilterBody = {
        kind: 'source',
        availableToEntityIds: [entityId],
        status: 'SUCCESS',
        onFile: true,
      }
      const { sources } = await cardApi.searchSources(filter)
      setSources(sources)
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const fetchProfileGroups = async (user: UserData): Promise<void> => {
    if (!user.id) {
      return
    }
    setIsFetchingProfileGroups(true)
    try {
      const { profiles } = await barracksApi.searchProfile(
        {
          userId: [user.id],
        },
        { limit: 10002 } // fetch all profiles
      )
      setProfiles(profiles)

      const { profileGroups } = await barracksApi.searchProfileGroups({
        profileId: [...profiles.map((profile) => profile.id)],
      })

      setProfileGroups(profileGroups)
    } catch (error) {
      Sentry.captureException(error)
    } finally {
      setIsFetchingProfileGroups(false)
    }
  }

  return {
    getEntityHierarchies,
    getUsers,
    getCountryOptions,
    getEntities,
    getSources,
    fetchProfileGroups,
  }
}
