import { History } from 'history'

export const setDrawerHash = (history: History, hash: string): void => {
  if (window.location.hash === hash) return
  history.push(hash)
}

export const resetDrawerHash = (history: History): void => {
  /*detect if drawer opened from outside and prevent exit from application on close drawer*/
  if (history.length === 2 || history.length === 3) {
    const pathname = window.location.pathname
    history.replace(pathname)
  } else if (['/app/cards'].includes(history.location.pathname)) {
    history.replace(history.location.pathname)
  } else {
    history.goBack()
  }
}

export const replaceDrawerHash = (history: History, path: string): void => {
  history.replace(path)
}
