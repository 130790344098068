import { useEffect, useState } from 'react'
import { Set } from 'types/paymentInstruction'

export default function useSetProgress(chargingSet?: Set, chargedSet?: Set) {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    if (chargedSet) {
      setProgress(100)
      return
    }

    if (!chargingSet) {
      setProgress(0)
      return
    }

    // Calculate progress
    const amountToCharge = chargingSet.chargeGroups?.length || 0
    const amountCharged = chargingSet.chargeGroups?.filter((cg) => cg.charge?.status).length || 0
    const step = 1 / amountToCharge

    if (amountToCharge === 0) {
      setProgress(0)
      return
    }

    setProgress(
      Math.max(
        Math.min(
          100,
          // Calculates 0 to 1, adds a half step to represent half way of current charge, multiplies by 100 for %
          (amountCharged / amountToCharge + step / 2) * 100
        ),
        1
      )
    )
  }, [chargingSet, chargedSet])

  return progress
}
