import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css, token }) => {
  return {
    container: css`
      background: white;
      padding: 16px;
      border-radius: 10px;
      margin-bottom: 30px;
    `,
    title: css`
      font-size: ${token.fontSize}px;
    `,
  }
})

export default useStyles
