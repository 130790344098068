const splitAt = (str: string, index: number): string[] => [str.slice(0, index), str.slice(index)]

export default function convertAmountCentsToForm(amount: number): string {
  const amountString = amount.toString().replace('.', '')

  // eslint-disable-next-line prefer-const
  let [remainingAmount, cents] = splitAt(amountString, -2)

  const amountParts = []
  let hundreds = ''
  do {
    const [remaining, lastThree] = splitAt(remainingAmount, -3)
    remainingAmount = remaining
    hundreds = lastThree

    amountParts.unshift(hundreds)
  } while (remainingAmount.length)

  return `${amountParts.join(',')}.${cents}`
}
