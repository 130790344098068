import React from 'react'
import { useIntl } from 'react-intl'
import { Checkbox, Button, Tag, Collapse, Badge } from 'antd'
import { useLanguageState } from 'stores/language/LanguageStore'
import { SCREEN_XL, Size } from 'utils/getDeviceType'
import { EditOutlined, DeleteOutlined, CalendarOutlined, InfoCircleOutlined, CloseOutlined } from '@ant-design/icons'
import Currency from 'components/Format/Currency/Currency'
import AccountNumber from 'components/Format/AccountNumber/AccountNumber'
import { Payment as PaymentType, PAYMENT_KIND, PAYMENT_STATE } from 'types/paymentInstruction'
import { pi } from 'lang/definitions'
import ACL from 'components/ACL/ACL'
import { useWindowSize } from 'hooks/useWindowSize'
import { getActiveUser } from 'utils/helpers'
import { useCurrentPayment } from 'stores/Payment'
import { useSession } from 'stores/session'
import usePaymentStyle from './Payment.style'
import { cx } from 'antd-style'
import { getCardIcon } from 'utils/card'

interface PaymentProps {
  disableSelect?: boolean
  data: PaymentType
  isSelected: boolean
  selectToggle: (payment: PaymentType) => void
  editPayment?: (kind: PAYMENT_KIND, id: string) => void
  deletePayment?: (payment: PaymentType) => void
  showPaymentDetails?: (kind: PAYMENT_KIND, id: string) => void
  removePaymentFromSet?: (kind: PAYMENT_KIND, id: string) => void
}

const { Panel } = Collapse

const Payment = (props: PaymentProps): React.JSX.Element => {
  const { styles } = usePaymentStyle()
  const [languageState] = useLanguageState()
  const language = languageState.language
  const { state: sessionState } = useSession()
  const me = sessionState.user!
  const { entityId } = getActiveUser(me)
  const size: Size = useWindowSize()
  const {
    state: { isSelectingPayments },
  } = useCurrentPayment()

  const {
    disableSelect,
    data,
    isSelected,
    selectToggle,
    editPayment,
    deletePayment,
    showPaymentDetails,
    removePaymentFromSet,
  } = props

  const intl = useIntl()

  const renderSignatureData = (): React.ReactNode => (
    <span className={cx(styles.signatureRequestLabel, styles.danger)}>
      {intl.formatMessage(pi['pi.add.reviewPayments.payment.state.requiredSignature'])}
    </span>
  )

  const paymentClassName = cx(styles.payment, {
    selected: isSelected,
    'select-disabled': disableSelect,
  })

  const cardReferenceText = data.source?.title ?? data.cardNumber
  const renderDesktopView = (data: PaymentType): React.JSX.Element => (
    <div className={paymentClassName}>
      <div className={styles.checkbox} onClick={() => selectToggle(data)}>
        <Checkbox checked={isSelected} disabled={isSelectingPayments} />
      </div>
      <div className={styles.paymentDetails} onClick={() => selectToggle(data)}>
        <div className={styles.beneficiarySignaturesContainer}>
          <div className={styles.beneficiaryGroup}>
            <div className={styles.beneficiaryTitle} data-testid="beneficiary-title">
              <span>{data.beneficiaryTitle}</span>
            </div>
            {data.newBeneficiary && <Badge>NEW</Badge>}
          </div>
          <div className={styles.beneficiaryAccount}>
            <AccountNumber
              accountNumber={data.beneficiaryAccountNumber}
              routingNumber={data.beneficiaryRoutingNumber}
              clearingNetwork={data.beneficiaryClearingNetwork}
            ></AccountNumber>
          </div>
        </div>
        <div className={styles.dateDueCardContainer}>
          <div className={styles.dateDue}>
            <span>{data.dateDue}</span>
          </div>
          <div className={styles.cardDetails}>
            {data.cardNetwork && (
              <div className={styles.cardLogo}>
                <img src={getCardIcon(data.cardNetwork)} alt="card" width={30} />
              </div>
            )}
            {cardReferenceText && (
              <div className={styles.cardReference}>
                <span>{cardReferenceText}</span>
              </div>
            )}
          </div>
          {data.datePlannedCharge && (
            <div>
              <CalendarOutlined className={styles.calendarIcon} />
              <span className={styles.text}>{`Card charged ${data.datePlannedCharge}`}</span>
            </div>
          )}
        </div>
        <div className={styles.supplierFundedContainer}>
          <div className={styles.paymentAmount}>
            <Currency value={data.amount.toString()} locale={language} currency={data.currency} />
            {data.isSupplierFunded && (
              <div className={styles.supplierFunded}>
                <Tag color="green">
                  <span>{intl.formatMessage(pi['pi.supplierFunded.label'])}</span>
                </Tag>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.paymentButtons}>
        {editPayment && (
          <ACL
            kind={data.kind === PAYMENT_KIND.TEMPLATE ? 'paymentInstructionTemplate' : 'paymentInstruction'}
            barracksId={me.activeProfileId}
            action="update"
          >
            <Button onClick={() => editPayment(data.kind, data.id)}>
              <EditOutlined />
            </Button>
          </ACL>
        )}
        {deletePayment && (
          <>
            {me.activeProfileId === data.profileId ? (
              <ACL
                kind={data.kind === PAYMENT_KIND.TEMPLATE ? 'paymentInstructionTemplate' : 'paymentInstruction'}
                barracksId={me.activeProfileId}
                action="delete"
              >
                <Button onClick={() => deletePayment(data)}>
                  <DeleteOutlined />
                </Button>
              </ACL>
            ) : (
              <ACL
                kind={data.kind === PAYMENT_KIND.TEMPLATE ? 'paymentInstructionTemplate' : 'paymentInstruction'}
                barracksId={entityId}
                action="delete"
              >
                <Button onClick={() => deletePayment(data)}>
                  <DeleteOutlined />
                </Button>
              </ACL>
            )}
          </>
        )}
        {showPaymentDetails && (
          <Button onClick={() => showPaymentDetails(data.kind, data.id)}>
            <InfoCircleOutlined />
          </Button>
        )}
        {removePaymentFromSet && (
          <Button onClick={() => removePaymentFromSet(data.kind, data.id)}>
            <CloseOutlined />
          </Button>
        )}
      </div>
    </div>
  )

  const paymentCollapseHeaderClass = cx(styles.paymentCollapseContainer, {
    [styles.selected]: isSelected,
    [styles.selectDisabled]: disableSelect,
  })

  const paymentCollapseClass = cx(styles.paymentCollapse, {
    [styles.selected]: isSelected,
    [styles.selectDisabled]: disableSelect,
  })

  const paymentCollapseHeader = (
    <div className={paymentCollapseHeaderClass}>
      <div
        className={styles.checkbox}
        onClick={(e) => {
          e.stopPropagation()
          selectToggle(data)
        }}
      >
        <Checkbox checked={isSelected} />
      </div>
      <div className={styles.detailsWrapper}>
        <div className="beneficiary-wrapper">
          <div className={styles.bGroup}>
            <div className={styles.bTitle}>
              <span>{data.beneficiaryTitle}</span>
            </div>
            {data.newBeneficiary && <Badge className={styles.newBadge}>NEW</Badge>}
          </div>
          <div className={styles.bTitle}>
            <AccountNumber
              accountNumber={data.beneficiaryAccountNumber}
              routingNumber={data.beneficiaryRoutingNumber}
              clearingNetwork={data.beneficiaryClearingNetwork}
            />
          </div>
          <div className={styles.bState}>
            {data.state === PAYMENT_STATE.READY ? (
              <span>{intl.formatMessage(pi['pi.add.reviewPayments.payment.state.ready'])}</span>
            ) : (
              <React.Fragment>{renderSignatureData()}</React.Fragment>
            )}
          </div>
        </div>
        <div className={styles.priceWrapper}>
          <Currency value={data.amount.toString()} locale={language} currency={data.currency} />
          {data.isSupplierFunded && (
            <div className={styles.supplierFunded}>
              <Tag color="green">
                <span>{intl.formatMessage(pi['pi.supplierFunded.label'])}</span>
              </Tag>
            </div>
          )}
        </div>
      </div>
    </div>
  )

  const renderMobileView = (data: PaymentType): React.JSX.Element => (
    <div className={paymentCollapseClass}>
      <Collapse expandIconPosition="end" ghost>
        <Panel header={paymentCollapseHeader} key={data.id} className={styles.panelWrapper}>
          <div className={styles.panelContent}>
            <div className={styles.dateDueWrapper}>
              <span>{data.dateDue}</span>
            </div>
            <div className={styles.cardWrapper}>
              {data.cardNetwork && (
                <div className={styles.cardLogo}>
                  <img src={getCardIcon(data.cardNetwork)} alt="card" width={30} />
                </div>
              )}
              {cardReferenceText && (
                <div className={styles.cardReference}>
                  <span>{cardReferenceText}</span>
                </div>
              )}
            </div>
            {data.datePlannedCharge && (
              <div>
                <CalendarOutlined className={styles.calendarIcon} />
                <span className={styles.text}>{`Card charged ${data.datePlannedCharge}`}</span>
              </div>
            )}
          </div>
          <div className={styles.panelActions}>
            {editPayment && !data.signatures?.signatureRequests?.length && (
              <ACL
                kind={data.kind === PAYMENT_KIND.TEMPLATE ? 'paymentInstructionTemplate' : 'paymentInstruction'}
                barracksId={me.activeProfileId}
                action="update"
              >
                <Button type="primary" onClick={() => editPayment(data.kind, data.id)}>
                  <EditOutlined />
                </Button>
              </ACL>
            )}
            {deletePayment && (
              <>
                {me.activeProfileId === data.profileId ? (
                  <ACL
                    kind={data.kind === PAYMENT_KIND.TEMPLATE ? 'paymentInstructionTemplate' : 'paymentInstruction'}
                    barracksId={me.activeProfileId}
                    action="delete"
                  >
                    <Button onClick={() => deletePayment(data)}>
                      <DeleteOutlined />
                    </Button>
                  </ACL>
                ) : (
                  <ACL
                    kind={data.kind === PAYMENT_KIND.TEMPLATE ? 'paymentInstructionTemplate' : 'paymentInstruction'}
                    barracksId={entityId}
                    action="delete"
                  >
                    <Button onClick={() => deletePayment(data)}>
                      <DeleteOutlined />
                    </Button>
                  </ACL>
                )}
              </>
            )}
            {showPaymentDetails && (
              <Button onClick={() => showPaymentDetails(data.kind, data.id)}>
                <InfoCircleOutlined />
              </Button>
            )}
            {removePaymentFromSet && (
              <Button onClick={() => removePaymentFromSet(data.kind, data.id)}>
                <CloseOutlined />
              </Button>
            )}
          </div>
        </Panel>
      </Collapse>
    </div>
  )

  return (size.width || 0) < SCREEN_XL ? renderMobileView(data) : renderDesktopView(data)
}

export default Payment
