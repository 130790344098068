import { SessionState, SessionAction } from './types'
import { Reducer } from 'react'
const sessionReducer: Reducer<SessionState, SessionAction> = (state, action) => {
  switch (action.type) {
    case 'SET_SESSION_ID':
      return { ...state, sessionId: action.payload }
    case 'SET_USER':
      return { ...state, user: action.payload }
    case 'SET_RULES':
      return { ...state, rules: action.payload }
    case 'SET_IAM':
      return { ...state, iam: action.payload }
    case 'SET_IS_B2B_USER':
      return { ...state, isB2BUser: action.payload }
    case 'RESET_CONTEXT':
      return {
        sessionId: undefined,
        user: undefined,
        rules: undefined,
        iam: [],
        isB2BUser: undefined,
      }
    default:
      throw new Error(`Unknown action type`, action)
  }
}

export default sessionReducer
