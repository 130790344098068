import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css, token }) => ({
  noContentMsg: css`
    font-size: ${token.fontSize}px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  `,
  actionsContainer: css`
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    button {
      margin: 10px 0;
    }
  `,
  beneficiaryDetails: css`
    margin-top: auto;
  `,
  beneficiaryTable: css`
    display: table-row !important;
  `,
}))

export default useStyles
