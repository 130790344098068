import { useSession } from 'stores/session'
import {
  ClearingNetworkRule,
  defaultClearingNetworkByCurrency,
  PaymentTypeRules,
  PaymentType,
  getPaymentTypesByClearingNetwork,
} from '../types/beneficiary'

export default function useAvailablePaymentTypes(): PaymentTypeRules {
  const { state: sessionState } = useSession()
  const rules = sessionState.rules!

  if (!rules?.logic.clearingNetworks.rule) {
    return {}
  }

  const availablePaymentTypes = rules?.logic.clearingNetworks.rule.reduce(
    (paymentTypeRules: PaymentTypeRules, rule: ClearingNetworkRule): PaymentTypeRules => {
      rule.currencies.forEach((currency) => {
        paymentTypeRules[currency] = paymentTypeRules[currency] || {
          paymentTypes: [],
          defaultPaymentType: undefined,
        }

        const paymentTypes = getPaymentTypesByClearingNetwork(currency)[rule.clearingNetwork]
        const uniqueTypes = new Set([...paymentTypeRules[currency].paymentTypes, ...paymentTypes])

        // sorting based on the PaymentType enumeration order
        const ordering = Object.values(PaymentType)
        paymentTypeRules[currency].paymentTypes = Array.from(uniqueTypes).sort((pt1, pt2) => {
          return ordering.indexOf(pt1) > ordering.indexOf(pt2) ? 1 : -1
        })

        const defaultPaymentType = defaultClearingNetworkByCurrency[currency]
        if (
          defaultPaymentType &&
          paymentTypeRules[currency].paymentTypes.find((paymentType) => defaultPaymentType === paymentType)
        ) {
          paymentTypeRules[currency].defaultPaymentType = defaultPaymentType
        }
      })

      return paymentTypeRules
    },
    {}
  )

  // Define default as first type of payment types in case default is undefined
  Object.keys(availablePaymentTypes).forEach((currency) => {
    if (!availablePaymentTypes[currency].defaultPaymentType && availablePaymentTypes[currency].paymentTypes[0]) {
      availablePaymentTypes[currency].defaultPaymentType = availablePaymentTypes[currency].paymentTypes[0]
    }
  })

  return availablePaymentTypes
}
