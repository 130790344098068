import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css, token, responsive }) => ({
  menuContentWrapper: css`
    .ant-select-item-option-content {
      display: flex;
      align-items: center;
      height: 30px;
    }

    .rc-virtual-list-holder {
      min-width: 344px;
    }

    .ant-select-item-empty {
      color: fade(black, 85%);
      min-width: 344px;
    }
  `,
  filterContainer: css`
    position: relative;
    height: fit-content;
    width: 100%;
    padding: 0;
    display: inline-block;

    ${responsive.sm} {
      display: none;
    }
  `,
  filterHeader: css`
    display: none;

    ${responsive.sm} {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }
  `,
  open: css`
    display: block;
    z-index: 1;
    position: absolute;
    height: 100%;
    width: 100%;
    padding: ${token.paddingMD}px;
    top: 0;
    left: 0;
    background-color: white;
  `,
  label: css`
    h2 {
      font-weight: 500;
    }
  `,
  closeBtn: css`
    margin-top: 7px;

    button {
      background: transparent;
      border: none;
      outline: none;
      width: 20px;
      height: 20px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  `,
  filters: css`
    display: flex;
    flex-direction: row;
    width: 100%;
    column-gap: 10px;

    ${responsive.sm} {
      display: flex;
      flex-direction: row;
      width: 100%;

      > div {
        display: flex;
        width: 100%;
      }
    }
  `,
  filter: css``,
  filtersLabel: css`
    display: none;
  `,
}))

export default useStyles
