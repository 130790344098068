import React from 'react'
import { Drawer } from 'antd'
import { resetDrawerHash } from './utils'
import { useHistory } from 'react-router-dom'

interface BHDrawerProps {
  className: string
  title: string
  destroyOnClose: boolean
  children: React.ReactNode | React.ReactNode[]
  hash: string | undefined
  pathname: string
  maskClosable: boolean
}
const BHDrawer = ({
  className,
  title,
  destroyOnClose,
  children,
  hash,
  pathname,
  maskClosable,
}: BHDrawerProps): React.JSX.Element => {
  const history = useHistory()

  const goBack = (): void => {
    resetDrawerHash(history)
  }

  const isVisible = (): boolean => {
    if (window.location.hash === hash && window.location.pathname === pathname) {
      return true
    } else {
      return false
    }
  }

  return (
    <Drawer
      rootClassName={`bh-drawer ${className}`}
      title={title}
      open={isVisible()}
      onClose={goBack}
      destroyOnClose={destroyOnClose}
      maskClosable={maskClosable}
      getContainer="body" // fix for: "validateDOMNesting(...): <div> cannot appear as a child of <html>"
    >
      {children}
    </Drawer>
  )
}

export default BHDrawer
