import getActions from '../actions'
import { useTracked } from '../container'
import { CurrentPaymentContextState } from '../types'

export default function useCurrentPayment(): CurrentPaymentContextState {
  const [state, dispatch] = useTracked()

  return {
    state,
    actions: getActions(dispatch),
  }
}
