import React from 'react'
import { IntlShape } from 'react-intl'
import { reports } from '../../../lang/definitions'
import Date from '../../../components/Format/Date/Date'
import { ReportTemplate } from '../../../types/report'

export const reportTemplateColumns = (intl: IntlShape, language: string) => [
  {
    title: intl.formatMessage(reports['reports.template.table.column.title']),
    dataIndex: '',
    width: 200,
    render: (data: { title: string; description: string }) => {
      return (
        <div>
          <div className="report-template-column-title" data-testid="report-template">
            {data.title}
          </div>
          <div className="report-template-column-description">{data.description}</div>
        </div>
      )
    },
  },
  {
    title: intl.formatMessage(reports['reports.template.table.column.dateCreated']),
    dataIndex: 'dateCreated',
    sortName: 'dateCreated',
    key: 'dateCreated',
    sorter: (a: ReportTemplate, b: ReportTemplate) => a.dateCreated!.localeCompare(b.dateCreated!),
    render: (dateCreated: string) => {
      return <Date value={dateCreated} locale={language} time />
    },
  },
  {
    title: intl.formatMessage(reports['reports.template.table.column.dateUpdated']),
    dataIndex: 'dateUpdated',
    sortName: 'dateUpdated',
    key: 'dateUpdated',
    sorter: (a: ReportTemplate, b: ReportTemplate) => {
      if (!a.dateUpdated && !b.dateUpdated) {
        return 0
      } else if (!a.dateUpdated) {
        return 1
      } else if (!b.dateUpdated) {
        return -1
      } else {
        return a.dateUpdated.localeCompare(b.dateUpdated)
      }
    },
    render: (dateUpdated: string | undefined) =>
      dateUpdated ? <Date value={dateUpdated} locale={language} time /> : '',
  },
]
