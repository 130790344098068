import { Action, Actions, State } from './types'

type Dispatch = (action: Action) => void

const getActions = (dispatch: Dispatch): Actions => {
  return {
    setEntityHierarchies: (entityHierarchies: State['entityHierarchies']) => {
      dispatch({
        type: 'SET_ENTITY_HIERARCHIES',
        payload: entityHierarchies,
      })
    },
    setEntityHierarchiesTotal: (total: State['entityHierarchiesTotal']) => {
      dispatch({
        type: 'SET_ENTITY_HIERARCHIES_TOTAL',
        payload: total,
      })
    },
    setIsFetchingEntityHierarchies: (isFetching: State['isFetchingEntityHierarchies']) => {
      dispatch({
        type: 'SET_IS_FETCHING_ENTITY_HIERARCHIES',
        payload: isFetching,
      })
    },
    setCardManagedByAdminRule: (cardManagedByAdminRule: State['cardManagedByAdminRule']) => {
      dispatch({
        type: 'SET_CARD_MANAGED_BY_ADMIN_RULE',
        payload: cardManagedByAdminRule,
      })
    },
    setCardNetworks: (cardNetworks: State['cardNetworks']) => {
      dispatch({
        type: 'SET_CARD_NETWORKS',
        payload: cardNetworks,
      })
    },
    setPaymentGateways: (paymentGateways: State['paymentGateways']) => {
      dispatch({
        type: 'SET_PAYMENT_GATEWAYS',
        payload: paymentGateways,
      })
    },
    setUsers: (users: State['users']) => {
      dispatch({
        type: 'SET_USERS',
        payload: users,
      })
    },
    setUsersTotal: (total: State['usersTotal']) => {
      dispatch({
        type: 'SET_USERS_TOTAL',
        payload: total,
      })
    },
    setIsFetchingUsers: (isFetching: State['isFetchingUsers']) => {
      dispatch({
        type: 'SET_IS_USERS_FETCHING',
        payload: isFetching,
      })
    },
    setCountryOptions: (countryOptions: State['countryOptions']) => {
      dispatch({
        type: 'SET_COUNTRY_OPTIONS',
        payload: countryOptions,
      })
    },
    setEntities: (entities: State['entities']) => {
      dispatch({
        type: 'SET_ENTITIES',
        payload: entities,
      })
    },
    setSources: (sources: State['sources']) => {
      dispatch({
        type: 'SET_SOURCES',
        payload: sources,
      })
    },
    setSelectedEntity: (entity: State['selectedEntity']) => {
      dispatch({
        type: 'SET_SELECTED_ENTITY',
        payload: entity,
      })
    },
    setSelectedUser: (user: State['selectedUser']) => {
      dispatch({
        type: 'SET_SELECTED_USER',
        payload: user,
      })
    },
    setEntityHierarchiesPage: (page: State['entityHierarchiesPage']) => {
      dispatch({
        type: 'SET_ENTITY_HIERARCHIES_PAGE',
        payload: page,
      })
    },
    setUsersFilterBody: (filterBody: State['usersFilterBody']) => {
      dispatch({
        type: 'SET_USERS_FILTER_BODY',
        payload: filterBody,
      })
    },
    setProfileGroups: (profileGroups: State['profileGroups']) => {
      dispatch({
        type: 'SET_PROFILE_GROUPS',
        payload: profileGroups,
      })
    },
    setIsFetchingProfileGroups: (isFetching: State['isFetchingProfileGroups']) => {
      dispatch({
        type: 'SET_IS_FETCHING_PROFILE_GROUPS',
        payload: isFetching,
      })
    },
    setProfiles: (profiles: State['profiles']) => {
      dispatch({
        type: 'SET_PROFILES',
        payload: profiles,
      })
    },
    resetState: () => {
      dispatch({
        type: 'RESET_STATE',
      })
    },
  }
}

export default getActions
