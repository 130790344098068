import { Reducer } from 'react'
import { State, Action } from './types'

const reducer: Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case 'SET_ENTITY_HIERARCHIES':
      return {
        ...state,
        entityHierarchies: action.payload,
      }
    case 'SET_ENTITY_HIERARCHIES_TOTAL':
      return {
        ...state,
        entityHierarchiesTotal: action.payload,
      }
    case 'SET_IS_FETCHING_ENTITY_HIERARCHIES':
      return {
        ...state,
        isFetchingEntityHierarchies: action.payload,
      }
    case 'SET_CARD_MANAGED_BY_ADMIN_RULE':
      return {
        ...state,
        cardManagedByAdminRule: action.payload,
      }
    case 'SET_CARD_NETWORKS':
      return {
        ...state,
        cardNetworks: action.payload,
      }
    case 'SET_PAYMENT_GATEWAYS':
      return {
        ...state,
        paymentGateways: action.payload,
      }
    case 'SET_USERS':
      return {
        ...state,
        users: action.payload,
      }
    case 'SET_USERS_TOTAL':
      return {
        ...state,
        usersTotal: action.payload,
      }
    case 'SET_IS_USERS_FETCHING':
      return {
        ...state,
        isFetchingUsers: action.payload,
      }
    case 'SET_COUNTRY_OPTIONS':
      return {
        ...state,
        countryOptions: action.payload,
      }
    case 'SET_ENTITIES':
      return {
        ...state,
        entities: action.payload,
      }
    case 'SET_SOURCES':
      return {
        ...state,
        sources: action.payload,
      }
    case 'SET_SELECTED_ENTITY':
      return {
        ...state,
        selectedEntity: action.payload,
      }
    case 'SET_SELECTED_USER':
      return {
        ...state,
        selectedUser: action.payload,
      }
    case 'SET_ENTITY_HIERARCHIES_PAGE':
      return {
        ...state,
        entityHierarchiesPage: action.payload,
      }
    case 'SET_USERS_FILTER_BODY':
      return {
        ...state,
        usersFilterBody: action.payload,
      }
    case 'SET_PROFILE_GROUPS':
      return {
        ...state,
        profileGroups: action.payload,
      }
    case 'SET_IS_FETCHING_PROFILE_GROUPS':
      return {
        ...state,
        isFetchingProfileGroups: action.payload,
      }
    case 'SET_PROFILES':
      return {
        ...state,
        profiles: action.payload,
      }
    case 'RESET_STATE':
      return {
        ...state,
        entityHierarchies: [],
        isFetchingEntityHierarchies: false,
        cardManagedByAdminRule: false,
        cardNetworks: [],
        paymentGateways: [],
        users: [],
        usersTotal: 0,
        isFetchingUsers: false,
        countryOptions: [],
        entities: [],
        sources: [],
        selectedEntity: undefined,
        selectedUser: undefined,
        entityHierarchiesPage: 1,
        usersFilterBody: {},
        profileGroups: [],
        isFetchingProfileGroups: false,
        profiles: [],
      }
    default:
      throw new Error(`Unknown action type`, action)
  }
}

export default reducer
