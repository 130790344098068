import { State } from '../types'

const initialState: State = {
  currentTab: 'input',
  selectedCard: undefined,
  selectedBeneficiary: undefined,
  selectedAmount: 0,
  selectedCurrency: undefined,
  selectedPayments: [],
  isAllPaymentsSelectedButtonActive: false,
  filteredPayments: [],
  paymentsOnCurrentPage: [],
  payments: [],
  paymentsFilter: undefined,
  paymentInstructions: [],
  paymentInstructionTemplates: [],
  paymentInstruction: undefined,
  paymentInstructionTemplate: undefined,
  selectedApprovers: [],
  currentPaymentsPage: 1,
  cards: [],
  paymentCards: [],
  beneficiaries: [],
  currencies: [],
  numberOfPaymentsSentToApprovers: 0,
  numberOfSignaturesRequired: 0,
  disableReviewPage: false,
  showInitLoader: false,
  showReviewPaymentLoader: false,
  signatureOrder: 'parallel',
  entityRules: undefined,
  checkedConfirmation: false,
  needConfirmation: false,
  submitError: false,
  numberOfApprovedPayments: 0,
  paymentsInSet: [],
  currentPaymentsInSetPage: 0,
  paymentsInSetOnCurrentPage: [],
  approveSet: undefined,
  approveAndPaySet: undefined,
  approversToShow: [],
  readySet: undefined,
  requireSignatureSet: undefined,
  approvers: undefined,
  approvalRounds: undefined,
  slideNumber: 0,
  isSelectingPayments: false,
}

export default initialState
