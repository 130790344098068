import React, { useState } from 'react'
import { Form, Button, Select } from 'antd'
import { useIntl } from 'react-intl'
import { page } from '../../../lang/definitions/index'
import { useLanguageState } from '../../../stores/language/LanguageStore'
import { getDomain } from '../../../components/Cookie/utils'

interface LanguageFormData {
  language: string
}

interface LanguageFormProps {
  onSubmit: (language: string) => void
}

const LanguageForm = ({ onSubmit }: LanguageFormProps): React.JSX.Element => {
  const intl = useIntl()
  const [languageState] = useLanguageState()
  const language = languageState.language
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)

  const [form] = Form.useForm()

  const handleSubmit = ({ language }: LanguageFormData): void => {
    onSubmit(language)
    updateLanguageCookie(language)
  }

  const updateLanguageCookie = (language: string): void => {
    const CookieDate = new Date()
    CookieDate.setFullYear(CookieDate.getFullYear() + 3)
    const cookieDomain = `.${getDomain()}`

    const lang: string = language.split('-')[0]

    const region: string = language.split('-')[1]

    document.cookie = `bhLang=${lang}; expires=${CookieDate.toUTCString()}; path=/;  ${
      getDomain() !== 'localhost' ? `domain=${cookieDomain};` : ''
    }`
    document.cookie = `bhRegion=${region}; expires=${CookieDate.toUTCString()}; path=/;  ${
      getDomain() !== 'localhost' ? `domain=${cookieDomain};` : ''
    }`
  }

  const handleValuesChange = (changedValues: Partial<LanguageFormData>): void => {
    if (changedValues) {
      setIsSubmitDisabled(false)
    }
  }

  return (
    <Form
      className="language-form"
      form={form}
      id="language-form"
      layout="vertical"
      name="basic"
      requiredMark={false}
      onValuesChange={handleValuesChange}
      onFinish={handleSubmit}
      initialValues={{
        language,
      }}
    >
      <Form.Item
        className="bh-form-item"
        label={intl.formatMessage(page['page.settings.slider.language.country.label'])}
        name="language"
      >
        <Select className="bh-select">
          <Select.Option data-testid="language-option-se" value="sv-SE">
            {intl.formatDisplayName('sv-SE', { type: 'language' })}
          </Select.Option>
          <Select.Option value="en-GB">{intl.formatDisplayName('en-GB', { type: 'language' })}</Select.Option>
          <Select.Option value="it-IT">{intl.formatDisplayName('it-IT', { type: 'language' })}</Select.Option>
          <Select.Option value="fi-FI">{intl.formatDisplayName('fi-FI', { type: 'language' })}</Select.Option>
          <Select.Option value="nl-NL">{intl.formatDisplayName('nl-NL', { type: 'language' })}</Select.Option>
          <Select.Option value="en-IE">{intl.formatDisplayName('en-IE', { type: 'language' })}</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          data-testid="language-submit-form"
          htmlType="submit"
          size="large"
          disabled={isSubmitDisabled}
          block
        >
          {intl.formatMessage(page['page.settings.slider.language.update.button.label'])}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default LanguageForm
