import { uniq, flatten } from 'lodash'
import iban from 'iban'
import { Beneficiary, Address, BeneficiaryFormData, Identifier, PaymentType } from '../../types/beneficiary'
import {
  Rules,
  ClearingNetwork,
  SupplierFunded,
  ClearingNetworkCountries,
  AllowedCountryAccountNumberTypes,
} from '../../types/rules'
import { FormInstance } from 'antd/lib/form'
import { useTranslation } from 'utils/helpers'

export const defaultBeneficiary: Beneficiary = {
  title: '',
  type: '',
  email: '',
  phone: '',
  identifier: {
    type: '',
    value: '',
  },
  address: {
    street: [''],
    zip: '',
    city: '',
    countryCode: '',
  },
  accountNumber: '',
  routingNumber: '',
  clearingNetwork: '',
}

export const getAllowedBeneficiaryCountries = (rules: Rules): Array<string> => {
  const clearingNetworks = rules.logic.allowedBeneficiaryCountries.rule
  const countries = clearingNetworks.map((clearingNetwork: ClearingNetwork): Array<string> => clearingNetwork.countries)

  return uniq(flatten(countries)).sort()
}

export const getAllowedBbanCountries = (rules: Rules): Array<string> => {
  const swiftClearingNetworkCountries = rules.logic.clearingNetworkCountries?.rule.find(
    (clearingNetworkCountries: ClearingNetworkCountries) => clearingNetworkCountries.clearingNetwork === 'swift'
  )

  if (!swiftClearingNetworkCountries) {
    return []
  }
  const bbanCountries = swiftClearingNetworkCountries.countries
    .filter((country: AllowedCountryAccountNumberTypes) => !!country.bban)
    .map((country: AllowedCountryAccountNumberTypes) => country.countryCode)
  return bbanCountries
}

interface SelectPair {
  label: string
  value: string
}

const isEU = (allowedClearingNetworks: Array<string>): boolean => allowedClearingNetworks.indexOf('sepa') !== -1

export const getAllowedClearingNetworks = (rules: Rules, selectedCountry: string): Array<SelectPair> => {
  const clearingNetworks = rules.logic.allowedBeneficiaryCountries.rule

  const allowedClearingNetworks = clearingNetworks
    .filter((clearingNetwork: ClearingNetwork): ClearingNetwork | undefined => {
      if (clearingNetwork.countries.indexOf(selectedCountry) !== -1) {
        return clearingNetwork
      }
      return undefined
    })
    .map((clearingNetwork: ClearingNetwork): string => clearingNetwork.clearingNetwork)

  switch (selectedCountry) {
    case 'se':
      return [
        {
          label: 'BG',
          value: 'bg',
        },
        {
          label: 'PG',
          value: 'pg',
        },
        {
          label: 'IBAN',
          value: 'sepa',
        },
      ]
    case 'gb':
      return [
        {
          label: 'BACS',
          value: 'bacs',
        },
        {
          label: 'IBAN',
          value: 'sepa',
        },
      ]
    case 'no':
      return [
        {
          label: 'NICS',
          value: 'nics',
        },
        {
          label: 'IBAN',
          value: 'sepa',
        },
      ]
    default: {
      if (isEU(allowedClearingNetworks)) {
        return [
          {
            label: 'IBAN',
            value: 'sepa',
          },
          {
            label: 'Local',
            value: 'swift',
          },
        ]
      } else {
        return [
          {
            label: 'IBAN',
            value: 'swift',
          },
          {
            label: 'Local',
            value: 'local',
          },
        ]
      }
    }
  }
}

export const getClearingNetwork = (rules: Rules, beneficiary: Beneficiary): SelectPair => {
  const allowedClearingNetworks = getAllowedClearingNetworks(rules, beneficiary.address.countryCode)

  let clearingNetwork = allowedClearingNetworks.find(
    (clearingNetwork: SelectPair) => clearingNetwork.value === beneficiary.clearingNetwork
  )

  if (clearingNetwork?.label === 'IBAN') {
    const isIBAN = iban.isValid(beneficiary.accountNumber)
    if (!isIBAN) {
      clearingNetwork = {
        label: 'Local',
        value: 'local',
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return clearingNetwork!
}

export enum PAYMENT_METHODS {
  BG_PG = 'bg-pg',
  BACS = 'bacs',
  SEPA = 'sepa',
  SWIFT = 'swift',
}

// Returns true if number is a valid according to Luhn checksum
export const checkLuhn = (luhn: string): boolean => {
  const prodArr = [
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    [0, 2, 4, 6, 8, 1, 3, 5, 7, 9],
  ]
  let len = luhn.length,
    mul = 0,
    sum = 0
  while (len--) {
    sum += prodArr[mul][parseInt(luhn.charAt(len), 10)]
    mul ^= 1
  }
  return sum % 10 === 0 && sum > 0
}

export const getBeneficiaryFromFormState = (form: FormInstance): Beneficiary => {
  const title = form.getFieldValue('title') as string
  const type = form.getFieldValue('type') as string
  const accountNumber = form.getFieldValue('accountNumber') as string
  const routingNumber = form.getFieldValue('routingNumber') as string
  let clearingNetwork = form.getFieldValue('clearingNetwork') as string
  const address: Address = {
    countryCode: form.getFieldValue(['address', 'countryCode']) as string,
    street: form.getFieldValue(['address', 'street']) as string,
    zip: form.getFieldValue(['address', 'zip']) as string,
    city: form.getFieldValue(['address', 'city']) as string,
  }

  const identifierValue = form.getFieldValue(['identifier', 'value']) as string
  const identifier: Identifier = {
    type: type === 'business' ? 'cin' : 'dob',
    value: identifierValue || '',
  }

  if (!identifier.value) {
    delete identifier.value
  }

  // clearing network "local" must be changed to "nics" for Norway or to "swift" for other countries
  if (clearingNetwork === 'local') {
    clearingNetwork = address.countryCode === 'no' ? 'nics' : 'swift'
  }

  if (address.street && typeof address.street === 'string') {
    address.street = [address.street]
  }

  const beneficiary: Beneficiary = {
    title,
    type,
    accountNumber,
    routingNumber,
    clearingNetwork,
    address,
    identifier,
  }

  return beneficiary
}

export const mapBeneficiaryToForm = (beneficiary: Beneficiary): BeneficiaryFormData => {
  const { title, type, identifier, address, accountNumber, routingNumber, clearingNetwork } = beneficiary
  return {
    title,
    type,
    identifier,
    address,
    accountNumber,
    routingNumber,
    clearingNetwork,
  }
}

export const useRenderRoutingNumberLabel = () => {
  const t = useTranslation()

  return (clearingNetwork: string): string => {
    switch (clearingNetwork) {
      case 'swift':
        return t('beneficiary.details.paymentMethod.swift')
      case 'bacs':
        return t('beneficiary.details.paymentMethod.bacs')
      default:
        return t('beneficiary.details.paymentMethod')
    }
  }
}

export const useRenderAccountNumberLabel = () => {
  const t = useTranslation()

  return (clearingNetwork: string, accountNumber: string): string => {
    const isIBAN = iban.isValid(accountNumber)

    if (isIBAN) {
      return t('beneficiary.iban')
    }

    switch (clearingNetwork) {
      case 'bg':
        return t('beneficiary.bank.giroNumber')
      case 'pg':
        return t('beneficiary.plus.giroNumber')
      case 'sepa':
        return t('beneficiary.iban')
      default:
        return t('beneficiary.accountNumber')
    }
  }
}

export interface BeneficiaryFormProps {
  data?: Beneficiary
  onSubmit: (data: Beneficiary & { paymentType?: PaymentType }, supplierFunded?: SupplierFunded) => Promise<void>
}
