import React, { ReactElement } from 'react'

type Props = {
  gap: number
  children: ReactElement[]
}

/**
 * Splits the width evenly between children
 */
export default function SpaceEven({ gap, children }: Props) {
  return (
    <div style={{ display: 'flex', gap, alignItems: 'center' }}>
      {children.map((child) => (
        <div style={{ flex: '1 1 0' }} key={`space-even-${child.key}`}>
          {child}
        </div>
      ))}
    </div>
  )
}
