import React, { CSSProperties } from 'react'
import { DatePicker } from 'antd'
import { getLocaleDateStringFormat } from '../../utils/dates'
import { useLanguageState } from '../../stores/language/LanguageStore'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'

dayjs.extend(customParseFormat)
dayjs.extend(utc)

interface BHDatePickerProps {
  className?: string
  value?: string
  disabled?: boolean
  placeholder?: string
  size?: SizeType
  popupStyle?: CSSProperties
  allowClear?: boolean
  dropdownClassName?: string
  onChange?: (date: string) => void
}

const BHDatePicker = ({
  className,
  value,
  disabled,
  placeholder,
  size,
  popupStyle,
  allowClear,
  dropdownClassName,
  onChange,
}: BHDatePickerProps): React.JSX.Element => {
  const [languageState] = useLanguageState()
  const language = languageState.language

  const onDateChange = (value: dayjs.Dayjs): void => {
    if (value) {
      const date = dayjs(value).utc().toISOString()
      onChange && onChange(date)
    } else {
      onChange && onChange('')
    }
  }

  return (
    <DatePicker
      className={`bh-datepicker ${className ? className : ''}`}
      value={dayjs(value)}
      format={{
        format: getLocaleDateStringFormat(language),
      }}
      disabled={disabled || false}
      onChange={(date) => onDateChange(date)}
      placeholder={placeholder}
      size={size}
      popupStyle={popupStyle}
      allowClear={allowClear || true}
      popupClassName={dropdownClassName || ''}
      data-testid="bh-date-picker"
    />
  )
}

export default BHDatePicker
