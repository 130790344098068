import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css, token }) => ({
  payment: css`
    display: flex;
    width: 100%;
    box-shadow: inset 0px -1px 0px #f0f0f0;
    border-radius: 10px;
    margin-bottom: 5px;
    background-color: white;
    flex-wrap: nowrap;
    justify-content: space-between;

    &:last-child {
      box-shadow: none;
    }

    &:hover {
      background-color: #f2f2f2;
      cursor: pointer;
    }
  `,
  paymentButtons: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 10px;
    margin-left: auto;

    button {
      width: 24px;
      height: 24px;
      padding: 0;
      margin: 2px 0;
      border: none;
      border-radius: 2px;
      background-color: #3e1d67;
      color: #fff;
    }
  `,
  checkbox: css`
    display: flex;
    align-items: center;
    padding: 10px;
  `,
  paymentDetails: css`
    display: flex;
    width: 80%;
    padding: 5px 10px 5px 0;
  `,
  beneficiarySignaturesContainer: css`
    width: 40%;
    padding: 5px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `,
  beneficiaryGroup: css`
    display: flex;

    .ant-badge {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: ${token.paddingXXS}px ${token.paddingXS}px;
      background: #e6f7ff;
      border-radius: ${token.borderRadius}px;
      color: #096dd9;
      font-size: ${token.fontSizeSM}px;
      font-weight: 500;
      margin-left: ${token.marginXXS}px;
    }
  `,
  beneficiaryTitle: css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    span {
      font-size: ${token.fontSizeSM}px;
      font-weight: 500;
    }
  `,
  beneficiaryAccount: css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    span {
      margin-right: 4px;
    }
  `,
  dateDueCardContainer: css`
    width: 40%;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `,
  dateDue: css`
    span {
      font-size: ${token.fontSizeSM}px;
    }
  `,
  cardDetails: css`
    display: flex;
    align-items: center;
  `,
  cardLogo: css`
    img {
      height: 20px;
    }
  `,
  networkUp: css`
    height: 30px;
    width: 30px;
    position: relative;
    svg {
      position: absolute;
      top: 8px;
      left: 8px;
    }
  `,
  cardReference: css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 5px;
    font-size: ${token.fontSizeSM}px;
  `,
  calendarIcon: css`
    color: #f2994a;
  `,
  supplierFundedContainer: css`
    width: 20%;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  `,
  paymentAmount: css`
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    span {
      font-weight: 500;
      font-size: 80%;
    }
  `,
  supplierFunded: css`
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    top: 55px;

    .ant-tag {
      margin: 0;
    }
    span {
      font-size: 10px;
    }
  `,
  paymentCollapseContainer: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  `,
  selected: css`
    background-color: #f2f2f2;
  `,
  selectDisabled: css``,
  paymentCollapse: css`
    border-radius: ${token.borderRadius}px !important;
    margin-bottom: 10px;
    background-color: white;

    .ant-collapse-expand-icon {
      margin: auto;
    }
  `,
  detailsWrapper: css`
    margin-left: 5px;
    display: flex;
    justify-content: space-between;
  `,
  bGroup: css`
    display: flex;
  `,
  bTitle: css`
    font-size: ${token.fontSizeSM}px;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    width: auto;
  `,
  newBadge: css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 43px;
    height: 16px;
    padding: ${token.paddingXXS}px ${token.paddingXS}px;
    background: #e6f7ff;
    border-radius: ${token.borderRadius}px;
    color: #096dd9;
    font-size: ${token.fontSizeSM}px;
    font-weight: 500;
    margin-left: ${token.marginXXS}px;
  `,
  bState: css`
    font-size: ${token.fontSizeSM}px;
    font-weight: 500;
  `,
  priceWrapper: css`
    display: flex;
    align-items: center;
    margin-left: ${token.marginMD}px;
  `,
  panelWrapper: css`
    .ant-collapse-content-box {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  `,
  panelContent: css`
    padding: 5px 5px 5px 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `,
  dateDueWrapper: css`
    > span {
      font-size: ${token.fontSizeSM}px;
    }
  `,
  cardWrapper: css`
    display: flex;
    align-items: center;
  `,
  text: css`
    margin-left: 5px;
    font-size: ${token.fontSizeSM}px;
  `,
  panelActions: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    > button {
      width: 24px;
      height: 24px;
      padding: 0;
      margin: 2px 0;
      border: none;
      border-radius: 2px;
      background-color: #3e1d67;
      color: #fff;
    }
  `,
  signatureRequestLabel: css`
    font-weight: 500;
  `,
  danger: css`
    color: ${token.colorError};
  `,
}))

export default useStyles
