import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css }) => ({
  errorBoundaryModal: css`
    .ant-modal-header {
      border-bottom: none;
      padding-top: 24px;
      padding-bottom: 0px;
    }

    .ant-modal-footer {
      border-top: none;
      padding-bottom: 24px;
      padding-top: 0px;
    }

    .ant-modal-content {
      font-family:
        Montserrat,
        -apple-system,
        BlinkMacSystemFont,
        Segoe UI,
        Roboto,
        Helvetica Neue,
        Arial,
        Noto Sans,
        'sans-serif',
        'Apple Color Emoji',
        'Segoe UI Emoji',
        Segoe UI Symbol,
        'Noto Color Emoji';
    }
  `,
  titleContainer: css`
    display: flex;
    align-items: center;
    column-gap: 4px;
  `,
  icon: css`
    font-size: 20px;
    color: #f44336;
  `,
  title: css`
    margin-bottom: 0px !important;
  `,
}))

export default useStyles
