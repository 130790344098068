import React from 'react'
import { useIntl } from 'react-intl'
import { page } from '../../../lang/definitions/index'

const Billing = (): React.JSX.Element => {
  const intl = useIntl()

  return <div>{intl.formatMessage(page['page.admin.billing.title'])}</div>
}

export default Billing
