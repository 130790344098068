import { Corp, Entity } from '../../../types/entity'
import { Profile } from '../../../types/profile'
import { TwoFactorAuthenticationRule } from '../../../types/rules'
import { UserResponseBody } from '../../../types/user'

export const defaultProfile: Profile = {
  id: '',
  userId: '',
  entityId: '',
  active: true,
  dateCreated: '',
  dateUpdated: '',
  entityRoles: [''],
  user: {
    id: '',
    name: {
      first: '',
      last: '',
    },
    email: '',
    phone: {
      countryCode: '',
      number: '',
    },
    timeZone: '',
    countryCode: '',
    tags: [],
    dateCreated: '',
    dateUpdated: '',
    birthDate: '',
  },
  entity: {
    id: '',
    parentEntityId: '',
    countryCode: '',
    dateCreated: '',
    dateUpdated: '',
    slug: '',
    state: '',
    class: {
      corp: {
        cin: '',
        title: '',
        dateCreated: '',
      },
    },
  },
  entities: [],
  entitiesRoles: [],
}

export const defaultUserDetails: UserResponseBody = defaultProfile.user

export const defaultEntityDetails: Entity = defaultProfile.entity

export const defaultTwoFactorAuthenticationRule: TwoFactorAuthenticationRule = {
  when: 'device',
  what: 'sms',
}

export const defaultCorp: Corp = {
  cin: '',
  title: '',
  dateCreated: '',
}
