import { Recurrence } from 'types/paymentInstruction'

export const DEFAULT_PAYMENTS_PAGE_SIZE = 5

export const DEFAULT_RECURRENCE_DATA: Recurrence = {
  limit: true,
  occurrencesRemaining: 1,
  interval: 'everyMonth',
}

export const cardNetworkNames: {
  [key: string]: string
} = {
  mc: 'Mastercard',
  visa: 'Visa',
  amex: 'Amex',
  diners: 'Diners',
}

interface Intervals {
  [key: string]: {
    label: string
    value: {
      unit: string
      value: number
    }
  }
}

export const INTERVALS: Intervals = Object.freeze({
  everyWeek: {
    label: 'Every week',
    value: {
      unit: 'days',
      value: 7,
    },
  },
  every2Weeks: {
    label: 'Every 2 weeks',
    value: {
      unit: 'days',
      value: 14,
    },
  },
  everyMonth: {
    label: 'Every month',
    value: {
      unit: 'months',
      value: 1,
    },
  },
  every2Months: {
    label: 'Every 2 months',
    value: {
      unit: 'months',
      value: 2,
    },
  },
  everyQuarter: {
    label: 'Every quarter',
    value: {
      unit: 'months',
      value: 3,
    },
  },
})
