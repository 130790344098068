import React, { PropsWithChildren } from 'react'
import { Space } from 'antd'

export default function SpaceForm({ children }: PropsWithChildren<Record<never, never>>) {
  return (
    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
      {children}
    </Space>
  )
}
