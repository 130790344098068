import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css }) => ({
  container: css`
    margin-top: 20px;
    padding: 15px;
    background-color: white;
    border-radius: 10px;
  `,
  summaryLabel: css`
    margin-left: 5px;

    span {
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
    }
  `,
  summaryRow: css`
    position: relative;
    display: flex;
    width: 100%;
    border-bottom: 1px solid #b3b3b3;
    padding: 2px 0;
    align-items: center;

    span {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.65);
    }
  `,
  rowData: css`
    margin-left: auto;
    display: flex;
    align-items: center;
  `,
  column: css`
    width: 100%;
  `,
  currency: css`
    span {
      font-size: 14px;
      font-weight: 700;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.45);
    }
  `,
  buttonContainer: css`
    margin-left: 5px;
    display: flex;
    align-items: center;

    button {
      width: 15px;
      height: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      cursor: pointer;
      background-color: transparent;
      border: none;

      span {
        padding: 0;

        svg {
          height: 10px;
          width: 10px;
        }
      }
    }
  `,
  rowLabel: css`
    display: flex;
    flex-direction: column;
    margin-left: 20px;

    span {
      line-height: 18px;
    }
  `,
  active: css`
    transition: max-height 0.3s ease-out;
    max-height: 100px;
    overflow-y: auto;
    display: inline;
  `,
  hidden: css`
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    padding: 0px;
  `,
  details: css`
    height: 45px;

    span {
      color: rgba(0, 0, 0, 0.45);
    }
  `,
  total: css`
    span {
      font-weight: 700;
    }
  `,
  cardNumber: css`
    font-size: 10px !important;
    color: rgba(0, 0, 0, 0.45) !important;
  `,
}))

export default useStyles
