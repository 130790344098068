import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css, responsive, token }) => ({
  actionPage: css`
    .ant-drawer-mask {
      background: rgba(black, 0.45);
    }
    .ant-drawer-body {
      padding-bottom: 20px;
    }

    .ant-drawer-content-wrapper {
      top: 0;
      min-width: 460px;
    }

    ${responsive.sm} {
      .ant-drawer-mask {
        background: transparent;
      }
      .ant-drawer-body {
        padding-bottom: ${token.paddingXL}px;
      }

      .ant-drawer-close {
        display: flex;
      }

      .ant-drawer-content-wrapper {
        top: 64px;
        min-width: 100%;
      }
    }
  `,
}))

export default useStyles
