import { api } from './utils'

const BASE_PATH = 'zuul'

export const getHubspotToken = async (): Promise<{
  token: string
}> => {
  const url = `/${BASE_PATH}/hubspot/token`
  const response = await api.post(url, {})
  return response.json()
}
