import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { browserTracingIntegration } from '@sentry/browser'
import 'antd/dist/reset.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import '@formatjs/intl-displaynames/locale-data/en' // locale-data for en
import '@formatjs/intl-displaynames/locale-data/sv' // locale-data for sv
import '@formatjs/intl-displaynames/locale-data/it' // locale-data for it
import '@formatjs/intl-displaynames/locale-data/nl' // locale-data for nl
import '@formatjs/intl-displaynames/locale-data/fi' // locale-data for fi
import { GlobalStateProvider } from './stores/GlobalStateProvider'
import env from 'utils/env'
import 'utils/dayjs'
import ErrorBoundary from 'components/ErrorBoundary/ReactErrorBoundary'

const sentryConfig =
  env('VITE_ENVIRONMENT') === 'development'
    ? {
        // do not run Sentry locally
      }
    : {
        dsn: env('VITE_SENTRY_DSN'),
        environment: env('VITE_ENVIRONMENT'),
        release: env('VITE_VERSION'),
        integrations: [browserTracingIntegration()],
        tracesSampleRate: 0.5,
      }

Sentry.init(sentryConfig)

function bootstrapApplication() {
  const rootElement = document.getElementById('root')
  const root = createRoot(rootElement!)

  root.render(
    <ErrorBoundary>
      <GlobalStateProvider>
        <App />
      </GlobalStateProvider>
    </ErrorBoundary>
  )
}

void bootstrapApplication()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
