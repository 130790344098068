import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { Collapse, Button, Row, Typography, Space } from 'antd'
import { getFaq } from 'api/faq'
import Page from 'components/Page/Page'
import { useIntl } from 'react-intl'
import rehypeRaw from 'rehype-raw'
import { support } from 'lang/definitions/support'
import { page } from 'lang/definitions'
import chatIcon from 'assets/chatIcon.svg'
import emailIcon from 'assets/emailIcon.svg'
import phoneIcon from 'assets/phoneIcon.svg'
import { catchExceptionWithSentry } from 'utils/Sentry'
import { notification } from 'antd'
import { getActiveUser, useTranslation } from 'utils/helpers'
import { FAQ } from 'types/faq'
import { useHubspotChat } from 'hooks/useHubspotChat'
import { Domicile } from 'types/entity'
import { useSession } from 'stores/session'
import useSupportStyle from './Support.style'
import { getBucketFile } from 'api/static'

const { Panel } = Collapse

const Support: React.FC = () => {
  const t = useTranslation()
  const { styles } = useSupportStyle()
  const {
    state: { rules, ...sessionState },
  } = useSession()
  const user = sessionState.user!
  const [faqData, setFaqData] = useState<FAQ | undefined>(undefined)
  const { openHubspotChat } = useHubspotChat()
  const [isDownloading, setIsDownloading] = useState(false)

  const { profile } = getActiveUser(user)
  const [supportEmail, setSupportEmail] = useState('')
  const [supportPhoneNumber, setSupportPhoneNumber] = useState('')

  const intl = useIntl()

  useEffect(() => {
    const fetchFaq = async () => {
      try {
        const response = await getFaq()
        setFaqData(response)
        return response
      } catch (error) {
        catchExceptionWithSentry(error as Error, {
          level: 'error',
          tags: { component: 'Support' },
        })
        notification.error({
          message: t('error.faq.fetch'),
          placement: 'topRight',
        })
      }
    }
    void fetchFaq()
  }, [rules])

  useEffect(() => {
    if (profile.entity.domicile === Domicile.UK) {
      setSupportEmail(intl.formatMessage(support['support.email.id.domicile.uk']))
      setSupportPhoneNumber(intl.formatMessage(support['support.phone.domicile.uk']))
    } else {
      setSupportEmail(intl.formatMessage(support['support.email.domicile.ab']))
      setSupportPhoneNumber(intl.formatMessage(support['support.phone.domicile.ab']))
    }
  }, [profile])

  const onDownloadUserGuide = async () => {
    setIsDownloading(true)
    const billhopUserManual = 'billhop-user-manual'
    try {
      const response = await getBucketFile(billhopUserManual)
      const urls = window.URL.createObjectURL(new Blob([response]))
      const link = document.createElement('a')
      link.href = urls
      link.setAttribute('download', `${billhopUserManual}.pdf`)
      link.click()
    } catch (err) {
      catchExceptionWithSentry(err as Error, {
        level: 'error',
        tags: { component: 'Support' },
      })
      notification.error({
        message: t('support.error.download.user.guide'),
        placement: 'topRight',
      })
    } finally {
      setIsDownloading(false)
    }
  }

  return (
    <Page title={intl.formatMessage(page['page.support.title'])} mobileMenuOptions={[]}>
      <>
        <div className={styles.contactText}>
          <p>{intl.formatMessage(support['support.intro'])}</p>
        </div>

        <Row justify="center" gutter={[16, 16]} style={{ textAlign: 'center' }}>
          <Space className={styles.supportBtns} size={24}>
            <div>
              <p>{intl.formatMessage(support['support.chatWithUs'])}</p>
              <Button size="large" icon={<img src={chatIcon} alt="chat" />} block onClick={() => openHubspotChat()}>
                {intl.formatMessage({
                  id: 'support',
                  defaultMessage: 'Contact support',
                  description: 'Contact support',
                })}
              </Button>
            </div>
            <div>
              <p>{intl.formatMessage(support['support.emailUs'])}</p>
              <Button
                size="large"
                icon={<img src={emailIcon} alt="email" />}
                block
                href={`mailto:${intl.formatMessage(support['support.email'])}`}
              >
                {supportEmail}
              </Button>
            </div>
            <div>
              <p>{intl.formatMessage(support['support.callUs'])}</p>
              <Button className="phone-button" size="large" icon={<img src={phoneIcon} alt="phone" />} block>
                {supportPhoneNumber}
              </Button>
            </div>
          </Space>
        </Row>
        <Row justify="center" gutter={[16, 16]} style={{ textAlign: 'center' }}>
          <Button
            loading={isDownloading}
            type="link"
            disabled={isDownloading}
            onClick={() => void onDownloadUserGuide()}
            size="large"
            className={styles.downloadBtn}
          >
            {intl.formatMessage(support['support.download.user.guide'])}
          </Button>
        </Row>
        {faqData &&
          Object.entries(faqData).map(([categoryName, items]) => (
            <div key={categoryName}>
              <Typography.Title level={4} style={{ marginTop: '1em' }}>
                {categoryName}
              </Typography.Title>
              <Collapse accordion>
                {items.map((item, index) => (
                  <Panel header={item.title} key={index}>
                    <div key={index}>
                      <ReactMarkdown rehypePlugins={[rehypeRaw]}>{item.content}</ReactMarkdown>
                    </div>
                  </Panel>
                ))}
              </Collapse>
            </div>
          ))}
      </>
    </Page>
  )
}

export default Support
