import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css, token }) => {
  return {
    selectCountry: css`
      .anticon-down {
        font-size: 10px;
        vertical-align: middle;
        padding-left: ${token.paddingXXS}px;
      }
    `,
    dropdownLink: css`
      font-weight: 400;
      cursor: pointer;
      font-size: ${token.fontSize}px;
      color: ${token.colorPrimary};

      &:hover {
        color: ${token.colorPrimary};
      }
    `,
  }
})

export default useStyles
