import React from 'react'
import { Descriptions } from 'antd'
import { useIntl } from 'react-intl'
import { activity } from '../../lang/definitions/activity'
import { Details } from '../../types/activity'

interface ImportEventDetailsProps {
  importDetails: Details
}

const ImportEventDetails = ({ importDetails }: ImportEventDetailsProps): React.JSX.Element => {
  const intl = useIntl()

  return (
    <Descriptions bordered layout="vertical" size="small" className="bh-description-vertical">
      {importDetails.name && (
        <Descriptions.Item label={intl.formatMessage(activity['activity.details.createdBy'])} span={3}>
          {importDetails.name.first} {importDetails.name.last}
        </Descriptions.Item>
      )}
      {importDetails.entityClass && (
        <Descriptions.Item label={intl.formatMessage(activity['activity.details.entity.type'])} span={3}>
          {importDetails.entityClass}
        </Descriptions.Item>
      )}
      {importDetails.title && (
        <Descriptions.Item label={intl.formatMessage(activity['activity.details.entity'])} span={3}>
          {importDetails.title}
        </Descriptions.Item>
      )}
    </Descriptions>
  )
}

export default ImportEventDetails
