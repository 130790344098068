import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css, token }) => ({
  cardWrapper: css`
    background: white;
    border-radius: 10px;
    padding: 16px;
    margin-bottom: 16px;
  `,
  header: css`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
  title: css`
    font-size: ${token.fontSize}px;
    display: flex;
    width: 100%;
    align-items: center;
    font-weight: 500;
    line-height: 24px;
  `,
  icon: css`
    > img {
      width: 16px;
      margin-bottom: 4px;
      margin-right: 16px;
      height: 16px;
    }
  `,
  subtitleWrapper: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: ${token.marginXL}px;
  `,
  subtitle: css`
    font-weight: 400;
    color: #828282;
    margin-left: 32px;
  `,
  subtitleSwitch: css`
    margin-bottom: 0px;
  `,
  formItem: css`
    margin-top: 10px;
  `,
  content: css`
    margin-left: 32px;
    margin-top: 16px;
  `,
  passwordItems: css`
    .ant-descriptions-item {
      padding-bottom: 6px;
    }
  `,
  requiredSignaturesError: css`
    display: block;
    margin-top: ${token.marginMD}px;
    color: ${token.colorError};
    font-size: ${token.fontSize}px;
  `,
  signeeFormItem: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-right: 30px;
  `,
  dualSigneeWrapper: css`
    .ant-col.ant-form-item-control {
      width: 75px;
      max-width: 75px;
    }
    .ant-col.ant-form-item-label {
      display: inline;
      padding-bottom: 0px;
    }
  `,
  signeeInput: css`
    width: 44px;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    margin-left: 16px;

    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  `,
  signeeSelect: css`
    width: 44px;
    margin-left: 16px;
    > .ant-select-selector {
      border: 1px solid #d9d9d9;
      border-radius: 10px;
    }
  `,
}))

export default useStyles
