import React from 'react'
import { Form, Radio, RadioChangeEvent } from 'antd'
import { useTranslation } from '../../../../utils/helpers'
import { AccountTypes, BeneficiaryTypes } from '../../../../types/beneficiary'
import RadioWithStyle from '../../components/RadioWithStyle'

type Prop = {
  type: 'beneficiary' | 'account'
}

const radioValuesByType = {
  beneficiary: Object.values(BeneficiaryTypes),
  account: Object.values(AccountTypes),
}

export default function TypeSelector({ type }: Prop): React.JSX.Element {
  const form = Form.useFormInstance()
  const t = useTranslation()

  const radioValues = radioValuesByType[type]

  const label = {
    beneficiary: t('beneficiary.type'),
    account: t('beneficiary.accountType'),
  }[type]

  const errorMessage = {
    beneficiary: t('messages.error.addBeneficiary.enter.type'),
    account: t('messages.error.addBeneficiary.select.accountType'),
  }[type]

  const name = {
    beneficiary: 'type',
    account: 'accountType',
  }[type]

  const onChange = (e: RadioChangeEvent) => {
    form.setFieldValue(name, e.target.value)
    void form.validateFields([name])
  }

  return (
    <Form.Item
      name={name}
      label={label}
      rules={[
        {
          required: true,
          message: errorMessage,
        },
      ]}
    >
      <Radio.Group onChange={onChange} size="large" style={{ display: 'flex', width: '100%' }}>
        {radioValues.map((value) => (
          <div key={`recipient-type-${value}`} style={{ flexGrow: 1 }}>
            <RadioWithStyle value={value}>{t(`page.addBeneficiary.recipientType.${value}.label`)}</RadioWithStyle>
          </div>
        ))}
      </Radio.Group>
    </Form.Item>
  )
}
