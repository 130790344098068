import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css, token }) => ({
  reviewHeader: css`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;
    background-color: #e0e0e0;
    border-radius: 10px;
    padding: 4px 0;
  `,
  selectAllLabelContainer: css`
    display: flex;
    align-items: center;
  `,
  allPaymentsSelectOptions: css`
    padding: 0 10px;
  `,
  headerLabel: css`
    padding: 0 9px;

    span {
      width: 100%;
      font-weight: 500;
      font-size: ${token.fontSize}px;
    }
  `,
  headerMenusContainer: css`
    display: flex;
    align-items: center;
    padding: 0 10px;
  `,
  headerBtnMore: css`
    .ant-dropdown-trigger {
      border-radius: 6px !important;
      height: 26px !important;
      background: rgba(255, 255, 255, 0.8);
      border: 1px solid rgba(0, 0, 0, 0.2);
      color: rgba(0, 0, 0, 0.8);
    }
    .ant-btn-compact-first-item {
      display: none;
    }
  `,
  filterPaymentDropdown: css`
    min-width: 300px;
    height: max-content;
    min-height: 300px;
    max-height: 500px;
    overflow-y: auto;
    &:hover {
      background-color: white !important;
    }
  `,
  toolbarBtn: css`
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 0.8);
    height: 28px !important;
    min-width: 90px;
    width: 90px;
    display: flex !important;
    align-items: center !important;
    border-radius: 8px !important;

    span {
      font-weight: 500;
      font-size: ${token.fontSize}px;
    }
  `,
}))

export default useStyles
