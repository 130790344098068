import React from 'react'
import { Button, Form, Select, Alert } from 'antd'
import CreditCard from 'components/CreditCard/CreditCard'
import { Source } from 'types/source'
import ACL from 'components/ACL/ACL'
import { setDrawerHash } from 'components/Drawers/utils'
import { useHistory } from 'react-router'
import { useCurrentPayment } from 'stores/Payment'
import { useTranslation } from 'utils/helpers'
import useSelectCardFormStyles from './SelectCardForm.style'
import { cx } from 'antd-style'
import AirplusDbisForm from './AirplusDbisForm'

interface SelectCardProps {
  cards: Array<Source>
  activeProfileId: string | undefined
  isFormSubmitting: boolean | undefined
  handleChangeCard: () => void
}

const SelectCardForm = ({
  cards,
  activeProfileId,
  isFormSubmitting,
  handleChangeCard,
}: SelectCardProps): React.JSX.Element => {
  const t = useTranslation()
  const history = useHistory()
  const { styles } = useSelectCardFormStyles()
  const {
    state: { selectedCard },
  } = useCurrentPayment()

  return (
    <>
      <div className={selectedCard ? styles.selectedOptionContainer : styles.itemWithAction}>
        <Form.Item
          name="sourceId"
          initialValue={selectedCard?.id}
          style={{ flexGrow: 1 }}
          rules={[
            {
              validator: (_, value) => {
                if (value) {
                  return Promise.resolve()
                }

                return Promise.reject(t('pi.add.addPayments.form.cardDetails.error.message'))
              },
            },
          ]}
        >
          {selectedCard ? (
            <CreditCard card={selectedCard} />
          ) : (
            <Select placeholder={t('pi.add.addPayments.form.card.select.placeholder')}>
              {cards?.map((card: Source, index: number): React.ReactNode => {
                const optionClassName = cx(styles.paymentFormSelectOption, {
                  [styles.oddOption]: index % 2 === 0,
                })
                return (
                  <Select.Option key={`card-${card.id}`} className={optionClassName} value={card.id}>
                    <CreditCard card={card} />
                  </Select.Option>
                )
              })}
            </Select>
          )}
        </Form.Item>

        {!selectedCard && (
          <div style={{ flexGrow: 0 }}>
            {cards.length > 0 ? (
              <ACL kind="source" barracksId={activeProfileId} action="create">
                <Button onClick={(): void => setDrawerHash(history, '#drawer-new-card')} className="btn-yellow">
                  {t('pi.add.addPayments.form.card.new')}
                </Button>
              </ACL>
            ) : (
              <div className={styles.alertNoCardWrapper}>
                {isFormSubmitting && cards.length === 0 && (
                  <Alert
                    className={styles.alertNoCard}
                    message={t('pi.add.addPayments.form.card.no.card.available.label')}
                    type="error"
                  />
                )}

                <ACL kind="source" barracksId={activeProfileId} action="create">
                  <Button
                    onClick={(): void => setDrawerHash(history, '#drawer-new-card')}
                    block={cards.length === 0}
                    className={cx('btn-yellow', styles.alertNoCardNewCardBtn)}
                  >
                    {t('pi.add.addPayments.form.card.new')}
                  </Button>
                </ACL>
              </div>
            )}
          </div>
        )}
      </div>

      {selectedCard && (
        <div className="change-selected">
          <Button type="link" onClick={handleChangeCard}>
            {t('pi.add.addPayments.form.card.changeCard')}
          </Button>
        </div>
      )}

      {selectedCard && selectedCard.typeProperties.cardNetwork === 'airplus' && (
        <AirplusDbisForm selectedPoolId={selectedCard.id} />
      )}
    </>
  )
}

export default SelectCardForm
