import { IntlShape } from 'react-intl'
import { pi } from '../../lang/definitions'
import { Actions } from '../../types/paymentInstruction'

const avatarColors = ['#70E2FF', '#F8C1BA', '#BCE9E0', '#F9D299', '#9DD0DA']

const hashString = (string: string) =>
  string
    .split('')
    .map((char) => char.charCodeAt(0))
    .reduce((a, b) => a + b + string.length, 0)

export const stringToColor = (string: string): string => {
  return avatarColors[hashString(string) % avatarColors.length]
}

export const getInitials = (fullName: string): string => {
  const allNames = fullName.trim().split(' ')
  const initials = allNames.reduce((acc, curr, index) => {
    if (index === 0 || index === allNames.length - 1) {
      acc = `${acc}${curr.charAt(0).toUpperCase()}`
    }
    return acc
  }, '')
  return initials
}

export const getRoleName = (action: string, intl: IntlShape): string => {
  // Convert `action` to the enum type `Actions`
  const actionEnum: Actions = Actions[action as keyof typeof Actions]

  switch (actionEnum) {
    case Actions.CREATED:
      return intl.formatMessage(pi['pi.details.creator'])
    case Actions.PAID:
      return intl.formatMessage(pi['pi.details.payer'])
    case Actions.SIGNED:
      return intl.formatMessage(pi['pi.details.signer'])
    default:
      return ''
  }
}
