import { defineMessages } from 'react-intl'

export const reports = defineMessages({
  'reports.header': {
    id: 'reports.header',
    defaultMessage: 'Reports',
    description: 'Header for reports page',
  },
  'reports.date.range': {
    id: 'reports.date.range',
    defaultMessage: 'Select date range',
    description: 'Title for select date range',
  },
  'reports.date.field': {
    id: 'reports.date.field',
    defaultMessage: 'Date field',
    description: 'Title for select date field',
  },
  'reports.date.due': {
    id: 'reports.date.due',
    defaultMessage: 'Date due',
    description: 'Title for date due label',
  },
  'reports.date.user.paid': {
    id: 'reports.date.user.paid',
    defaultMessage: 'Date user paid',
    description: 'Title for date user paid label',
  },
  'reports.payment.status': {
    id: 'reports.payment.status',
    defaultMessage: 'Payment status',
    description: 'Title for payment status filter',
  },
  'reports.payment.status.completed': {
    id: 'reports.payment.status.completed',
    defaultMessage: 'Completed',
    description: 'Title for payment status option completed',
  },
  'reports.select': {
    id: 'reports.select',
    defaultMessage: 'Select ...',
    description: 'Title for status option select',
  },
  'reports.report.fields': {
    id: 'reports.report.fields',
    defaultMessage: 'Report fields',
    description: 'Title for report fields filter',
  },
  'reports.report.fields.recommended': {
    id: 'reports.report.fields.recommended',
    defaultMessage: 'Recommended',
    description: 'Title for report fields option recommended',
  },
  'reports.entities': {
    id: 'reports.entities',
    defaultMessage: 'Entities',
    description: 'Title for entities filter',
  },
  'reports.selected': {
    id: 'reports.selected',
    defaultMessage: 'selected',
    description: 'Title for selected values',
  },
  'reports.delivery.option': {
    id: 'reports.delivery.option',
    defaultMessage: 'Delivery option',
    description: 'Title for delivery options filter',
  },
  'reports.delivery.option.download': {
    id: 'reports.delivery.option.download',
    defaultMessage: 'Download',
    description: 'Title for delivery options download',
  },
  'reports.delivery.option.email.me': {
    id: 'reports.delivery.option.email.me',
    defaultMessage: 'Email me',
    description: 'Title for delivery options email me',
  },
  'reports.advance.filters': {
    id: 'reports.advance.filters',
    defaultMessage: 'Advanced Filters',
    description: 'Title for advanced filter',
  },
  'reports.generate.report': {
    id: 'reports.generate.report',
    defaultMessage: 'Generate report',
    description: 'Title for generate report',
  },
  'reports.generate.reportTemplate': {
    id: 'reports.generate.reportTemplate',
    defaultMessage: 'Save as template',
    description: 'Title for generate report template',
  },
  'reports.history': {
    id: 'reports.history',
    defaultMessage: 'History',
    description: 'Title for history tab',
  },
  'reports.back': {
    id: 'reports.back',
    defaultMessage: 'Back',
    description: 'Title for value back',
  },
  'reports.users': {
    id: 'reports.users',
    defaultMessage: 'Users',
    description: 'Title for users filter',
  },
  'reports.beneficiaries': {
    id: 'reports.beneficiaries',
    defaultMessage: 'Beneficiaries',
    description: 'Title for value beneficiaries',
  },
  'reports.sources': {
    id: 'reports.sources',
    defaultMessage: 'Sources',
    description: 'Title for value sources',
  },
  'reports.clearing.networks': {
    id: 'reports.clearing.networks',
    defaultMessage: 'Clearing Networks',
    description: 'Title for value clearing networks',
  },
  'reports.card.networks': {
    id: 'reports.card.networks',
    defaultMessage: 'Card Networks',
    description: 'Title for value card networks',
  },
  'reports.currency': {
    id: 'reports.currency',
    defaultMessage: 'Currency',
    description: 'Title for value currency',
  },
  'reports.all': {
    id: 'reports.all',
    defaultMessage: 'All',
    description: 'Title for currency option all',
  },
  'reports.legacy': {
    id: 'reports.legacy',
    defaultMessage: 'Legacy report ',
    description: 'Title for report option legacy',
  },
  'reports.legacy.tooltip': {
    id: 'reports.legacy.tooltip',
    defaultMessage:
      'Generate a report based on the format from the previous Billhop platform. Recomended for users who depend on the previous format for internal reposrts etc.',
    description: 'Tooltip for report option legacy',
  },
  'reports.summary': {
    id: 'reports.summary',
    defaultMessage: 'Summary',
    description: 'Title summary',
  },
  'reports.payments.selected': {
    id: 'reports.payments.selected',
    defaultMessage: 'Number of payments selected',
    description: 'Title for payments selected',
  },
  'reports.label.from': {
    id: 'reports.label.from',
    defaultMessage: 'From',
    description: 'Title for label from',
  },
  'reports.label.to': {
    id: 'reports.label.to',
    defaultMessage: 'To',
    description: 'Title for label to',
  },
  'reports.select.entity.placeholder': {
    id: 'reports.select.entity.placeholder',
    defaultMessage: 'Select entities',
    description: 'Placeholder for select entites',
  },
  'reports.select.beneficiary.placeholder': {
    id: 'reports.select.beneficiary.placeholder',
    defaultMessage: 'Select beneficiaries',
    description: 'Placeholder for select entites',
  },
  'reports.radio.select': {
    id: 'reports.radio.select',
    defaultMessage: 'Select',
    description: 'Title for select option',
  },
  'reports.add.email': {
    id: 'reports.add.email',
    defaultMessage: 'Add email',
    description: 'Label for add email button',
  },
  'reports.report.format': {
    id: 'reports.report.format',
    defaultMessage: 'Report format',
    description: 'Report format title',
  },
  'reports.report.name.on.report': {
    id: 'reports.report.name.on.report',
    defaultMessage: 'Name on report',
    description: 'Name on report title',
  },
  'reports.include.extra.info': {
    id: 'reports.include.extra.info',
    defaultMessage: 'Include extra information',
    description: 'Include extra information title',
  },
  'reports.excel.report': {
    id: 'reports.excel.report',
    defaultMessage: 'Excel report',
    description: 'Excel report title',
  },
  'reports.pdf.report': {
    id: 'reports.pdf.report',
    defaultMessage: 'Pdf report',
    description: 'Pdf report title',
  },
  'reports.report.type': {
    id: 'reports.report.type',
    defaultMessage: 'Report type',
    description: 'Report type title',
  },
  'reports.receipts.single': {
    id: 'reports.receipts.single',
    defaultMessage: 'Receipts (Single PI per page)',
    description: 'Receipts title',
  },
  'reports.transactions.multiple': {
    id: 'reports.transactions.multiple',
    defaultMessage: 'Transaction report (Table of PIs)',
    description: 'Transaction report title',
  },
  'reports.report.company.name': {
    id: 'reports.report.company.name',
    defaultMessage: 'Company name',
    description: 'Company name title',
  },
  'reports.report.person.name': {
    id: 'reports.report.person.name',
    defaultMessage: "Person's name",
    description: "Person's name title",
  },
  'reports.report.include.bh.fee': {
    id: 'reports.report.include.bh.fee',
    defaultMessage: 'Include Billhop fee',
    description: 'Include Billhop fee title',
  },
  'reports.report.include.card.info': {
    id: 'reports.report.include.card.info',
    defaultMessage: 'Include payment card information',
    description: 'Include payment card information title',
  },
  'reports.template.form.title': {
    id: 'reports.template.form.title',
    defaultMessage: 'New Template',
    description: 'Title for the new report template form',
  },
  'reports.template.form.edit.title': {
    id: 'reports.template.form.edit.title',
    defaultMessage: 'Edit Template',
    description: 'Title for the edit report template form',
  },

  'reports.template.form.panel.header': {
    id: 'reports.template.form.panel.header',
    defaultMessage: 'Summary',
    description: 'Header for the report summary in template form',
  },
  'reports.template.form.title.label': {
    id: 'reports.template.form.title.label',
    defaultMessage: 'Title of the Template',
    description: 'Title label for the report field in template form',
  },
  'reports.template.form.title.placeholder': {
    id: 'reports.template.form.title.placeholder',
    defaultMessage: 'Template title',
    description: 'Title placeholder for the report field in template form',
  },
  'reports.template.form.description.label': {
    id: 'reports.template.form.description.label',
    defaultMessage: 'Optional description',
    description: 'Description label for the report field in template form',
  },
  'reports.template.form.description.placeholder': {
    id: 'reports.template.form.description.placeholder',
    defaultMessage: 'Template description',
    description: 'Description placeholder for the report textArea in template form',
  },
  'reports.template.form.availabillity.label': {
    id: 'reports.template.form.availabillity.label',
    defaultMessage: 'Availabillity',
    description: 'Availabillity label for the report checkbox in template form',
  },
  'reports.template.form.info.description': {
    id: 'reports.template.form.info.description',
    defaultMessage:
      "To update the values, apply the template by clicking the 'Use Template'-button, make the appropriate changes in the form and save it as a new template",
    description: 'Description text in template form',
  },
  'reports.template.form.checkbox.availabillity.description': {
    id: 'reports.template.form.checkbox.availabillity.description',
    defaultMessage: 'Available to all users in this Organization',
    description: 'Description for the new report template checkbox description',
  },
  'reports.template.noRecord.description': {
    id: 'reports.template.noRecord.description',
    defaultMessage: 'No templates created',
    description: 'Description on empty template table',
  },
  'reports.template.table.column.title': {
    id: 'reports.template.table.column.title',
    defaultMessage: 'Title',
    description: 'Title of column type in template table to show the title/name of template',
  },
  'reports.template.table.column.dateCreated': {
    id: 'reports.template.table.column.dateCreated',
    defaultMessage: 'Date Created',
    description: 'Title of column Date created in template table to show the template created date',
  },
  'reports.template.table.column.dateUpdated': {
    id: 'document.table.column.dateUpdated',
    defaultMessage: 'Date updated',
    description: 'Title of column Date updated in template table to show the template updated date',
  },
})
