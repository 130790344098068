import { Features } from '../types/rules'
import { useSession } from 'stores/session'

export default function useFeatureFlags(): Features {
  const {
    state: { rules },
  } = useSession()

  const emptyFeatureFlag: Features = {
    amountOfPaymentsForAsyncResponse: 0,
    useDynamicPaymentThreshold: false,
  }

  return rules?.logic?.featureFlags?.rule || emptyFeatureFlag
}
