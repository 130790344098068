import React from 'react'
import iban from 'iban'

interface FormatAccountNumberProps {
  accountNumber: string
  routingNumber?: string
  clearingNetwork: string
}

const AccountNumber = (props: FormatAccountNumberProps): React.JSX.Element => {
  const renderAccountNumber = (props: FormatAccountNumberProps) => {
    const { accountNumber, routingNumber, clearingNetwork } = props

    let finalAccountNumber

    const clearingNetworkLower = clearingNetwork.toLowerCase()

    switch (clearingNetworkLower) {
      case 'bg': {
        finalAccountNumber =
          clearingNetwork.toUpperCase() +
          ' ' +
          accountNumber.substring(0, accountNumber.length / 2) +
          '-' +
          accountNumber.substring(accountNumber.length / 2, accountNumber.length)
        break
      }
      case 'pg': {
        finalAccountNumber =
          clearingNetwork.toUpperCase() +
          ' ' +
          accountNumber.substring(0, accountNumber.length - 1) +
          '-' +
          accountNumber.substring(accountNumber.length - 1, accountNumber.length)
        break
      }
      case 'sepa': {
        finalAccountNumber = iban.printFormat(accountNumber)
        break
      }
      case 'bacs': {
        if (routingNumber == null) {
          finalAccountNumber = accountNumber
        } else {
          const parts = routingNumber.match(/.{1,2}/g)
          if (parts) {
            finalAccountNumber = parts.join('-') + ' ' + accountNumber
          }
        }
        break
      }
      case 'swift': {
        if (iban.isValid(accountNumber)) {
          finalAccountNumber = iban.printFormat(accountNumber)
        } else {
          if (routingNumber) {
            finalAccountNumber = `${routingNumber.toString()} ${accountNumber}`
          } else {
            finalAccountNumber = accountNumber
          }
        }
        break
      }
      default:
        finalAccountNumber = accountNumber
    }

    return <span>{finalAccountNumber}</span>
  }

  return renderAccountNumber(props)
}

export default AccountNumber
