import React from 'react'
import { IntlShape } from 'react-intl'
import Date from '../../../components/Format/Date/Date'
import { admin } from '../../../lang/definitions/admin'
import { Event } from '../../../types/activity'
import { Name } from '../../../types/user'

export const columnsEvents = (language: string, intl: IntlShape) => [
  {
    title: intl.formatMessage(admin['admin.user.columns.user']),
    dataIndex: '',
    key: 'user',
    render: (data: { createdBy: { name: Name } }) => {
      return (
        <span>
          {data.createdBy.name?.first} {data.createdBy.name?.last}
        </span>
      )
    },
  },
  {
    title: intl.formatMessage(admin['admin.user.columns.entity']),
    dataIndex: '',
    sortName: 'entity',
    key: 'entity',
    render: (data: { createdBy: { title: string } }) => {
      return <span>{data.createdBy.title}</span>
    },
  },
  {
    title: 'Created on',
    dataIndex: 'dateCreated',
    sortName: 'dateCreated',
    key: 'dateCreated',
    sorter: true,
    render: (date: string) => {
      return <Date value={date} locale={language} time />
    },
  },
  {
    title: 'Activity',
    dataIndex: '',
    sortName: 'activity',
    key: 'activity',
    render: (data: { label: string; labelText: string }) => {
      return <span>{data.labelText}</span>
    },
  },
]

export const defaultEvent: Event = {
  id: '',
  label: '',
  details: {
    name: { first: '', last: '' },
    title: '',
    entityClass: '',
    entityRoles: [],
  },
  dateCreated: '',
  source: '',
  createdBy: { name: { first: '', last: '' }, title: '' },
  relatedTo: {
    beneficiaryId: '',
    entityId: '',
    paymentInstructionId: '',
    paymentInstructionTemplateId: '',
    profileId: '',
    requestId: '',
    sessionId: '',
    userId: '',
  },
}
