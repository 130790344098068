import React from 'react'
import { Form, Select } from 'antd'
import { useTranslation } from '../../../../utils/helpers'
import states from 'states-us'

export default function AddressState(): React.JSX.Element {
  const t = useTranslation()

  return (
    <Form.Item
      label={t('beneficiary.form.address.state.label')}
      name={['address', 'region']}
      validateTrigger="onChange"
      hasFeedback
      rules={[
        {
          required: true,
          message: t('beneficiary.form.address.state.error'),
        },
      ]}
    >
      <Select
        showSearch
        size="large"
        placeholder={t('beneficiary.form.address.state.placeholder')}
        optionFilterProp="children"
        placement="topLeft"
        filterOption={(input: string, option: unknown): boolean => {
          const { key, value } = option as {
            key: string
            value: string
          }
          return (
            key.toLowerCase().startsWith(input.toLowerCase()) || value.toLowerCase().startsWith(input.toLowerCase())
          )
        }}
      >
        {states.map((state) => (
          <Select.Option key={state.abbreviation} value={state.name}>
            {state.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}
