import React from 'react'
import { Form, Input } from 'antd'
import { useTranslation } from '../../../../utils/helpers'
import { useTitleValidator } from 'utils/validators'
import useSuffix from './useSuffix'
import { GenericMaxLength } from 'utils/validators'

type Props = {
  children?: React.JSX.Element
  disabled?: boolean
  onPressEnter?: () => void
  transformValue?: (value: string) => string
}

export default function RecipientTitle({ children, disabled, onPressEnter, transformValue }: Props): React.JSX.Element {
  const t = useTranslation()

  const titleValidator = useTitleValidator()
  const titleSuffix = useSuffix({ fieldName: 'title', maxLength: GenericMaxLength })
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!transformValue) {
      return
    }

    e.target.value = transformValue(e.target.value)
  }

  return (
    <Form.Item
      label={t('beneficiary.title')}
      name="title"
      hasFeedback
      validateTrigger="onChange"
      rules={[
        {
          validator: titleValidator,
        },
      ]}
    >
      {children || (
        <Input
          size="large"
          type="text"
          onPressEnter={onPressEnter}
          onInput={handleInputChange}
          disabled={disabled}
          suffix={titleSuffix}
          data-testid="recipient-title-input"
          placeholder={t('beneficiary.name.placeholder')}
          maxLength={GenericMaxLength}
        />
      )}
    </Form.Item>
  )
}
