import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  'messages.success.otp.sms': {
    id: 'messages.success.otp.sms',
    defaultMessage: 'New code send successfully',
    description: 'Success message to send the new opt code',
  },
  'messages.success.otp.voice': {
    id: 'messages.success.otp.voice',
    defaultMessage: 'We are calling you now!',
    description: 'Success message to initiate the vocie call for opt code',
  },
  'messages.error.otp.send': {
    id: 'messages.error.otp.send',
    defaultMessage: 'We could not send the code',
    description: 'Error message to send the otp code',
  },
  'messages.error.otp.incorrect': {
    id: 'messages.error.otp.incorrect',
    defaultMessage: 'Incorrect code!',
    description: 'Error message when incorrect otp code is entered',
  },
  'messages.error.login.incorrect.emailFormat': {
    id: 'messages.error.login.incorrect.emailFormat',
    defaultMessage: 'The input is not valid E-mail!',
    description: 'Error message when incorrect email format',
  },
  'messages.error.login.incorrect.name': {
    id: 'messages.error.login.incorrect.name',
    defaultMessage: 'Please add a valid name',
    description: 'Error message when incorrect name ',
  },
  'messages.error.login.incorrect.credentials': {
    id: 'messages.error.login.incorrect.credentials',
    defaultMessage: 'The e-mail or password you entered is incorrect!',
    description: "Error message when login credentials don't match",
  },
  'messages.error.login.enter.email': {
    id: 'messages.error.login.enter.email',
    defaultMessage: 'Please enter your e-mail address',
    description: 'Message to ask user to enter email address',
  },
  'messages.error.login.enter.name': {
    id: 'messages.error.login.enter.name',
    defaultMessage: 'Please add your name.',
    description: 'Message to ask user to enter username',
  },
  'messages.error.login.enter.email.required': {
    id: 'messages.error.login.enter.email.required',
    defaultMessage: 'Please add your email address.',
    description: 'Message to ask user to enter email address',
  },
  'messages.login.incorrect.emailFormat.error.message': {
    id: 'messages.login.incorrect.emailFormat.error.message',
    defaultMessage: 'Please add a valid email address.',
    description: 'Message to ask the user to enter a valid email address',
  },
  'messages.error.login.enter.password': {
    id: 'messages.error.login.enter.password',
    defaultMessage: 'Please input your password!',
    description: 'Message to ask user to enter password',
  },

  'messages.error.login.legacy.heading': {
    id: 'messages.error.login.legacy.heading',
    defaultMessage: 'You are not migrated to our new platform',
    description: 'Heading for legacy login warning',
  },
  'messages.error.login.legacy.description': {
    id: 'messages.error.login.legacy.description',
    defaultMessage:
      'Your account has not yet been migrated to our new platform. While this is beeing done please use the Legacy Billhop platform following the link below',
    description: 'Info for legacy login warning',
  },
  'messages.error.login.legacy.button': {
    id: 'messages.error.login.legacy.button',
    defaultMessage: 'Login to Billhop Legacy',
    description: 'Button textfor legacy login warning',
  },
  'messages.error.login.otp.enter.phone': {
    id: 'messages.error.login.otp.enter.phone',
    defaultMessage: 'Please input your phone!',
    description: 'Message to ask user to enter phone',
  },
  'messages.login.otp': {
    id: 'messages.login.otp',
    defaultMessage: 'Add phone number to be able to use OTP (One Time Password) login.',
    description: 'Title for otp form',
  },
  'messages.login.otp.save.phone': {
    id: 'messages.login.otp.save.phone',
    defaultMessage: 'Submit',
    description: 'Button label for submit',
  },
  'messages.error.login.otp.send.code': {
    id: 'messages.error.login.otp.send.code',
    defaultMessage: 'Phone number is not valid. Please try again!',
    description: 'Error label for save number',
  },
  'messages.error.login.incorrect.personnummerFormat': {
    id: 'messages.error.login.incorrect.personnummerFormat',
    defaultMessage: 'Incorrect format of personnummer',
    description: 'Error message when personnummer format is not correct',
  },
  'messages.error.login.enter.personnummer': {
    id: 'messages.error.login.enter.personnummer',
    defaultMessage: 'Please enter your personnummer',
    description: 'Message to ask user to enter personnummer',
  },
  'messages.status.bankId.pending': {
    id: 'messages.status.bankId.pending',
    defaultMessage: 'Launch the BankID app on your device',
    description: 'Message for different bankId statuses',
  },
  'messages.status.bankId.ongoing': {
    id: 'messages.status.bankId.ongoing',
    defaultMessage: 'BankID login in progress',
    description: 'Message for different bankId statuses',
  },
  'messages.status.bankId.alreadyInProgress': {
    id: 'messages.status.bankId.alreadyInProgress',
    defaultMessage: 'BankID session already in progress!',
    description: 'Message for different bankId statuses',
  },
  'messages.status.bankId.canceled': {
    id: 'messages.status.bankId.canceled',
    defaultMessage: 'BankID login canceled',
    description: 'Message for different bankId statuses',
  },
  'messages.status.bankId.missingSsn': {
    id: 'messages.status.bankId.missingSsn',
    defaultMessage: 'The personnummer is missing. Perhaps you need to register an account first',
    description: 'Message when an ssn is not found for BankID registration',
  },
  'messages.status.bankId.error': {
    id: 'messages.status.bankId.error',
    defaultMessage: 'BankID error',
    description: 'Message for different bankId statuses',
  },
  'messages.status.bankId.timeout': {
    id: 'messages.status.bankId.timeout',
    defaultMessage: 'Session has expired',
    description: 'Message for different bankId statuses',
  },
  'messages.status.bankId.generalError': {
    id: 'messages.status.bankId.generalError',
    defaultMessage: 'General error',
    description: 'Message for different bankId statuses',
  },
  'messages.prompt.delete': {
    id: 'messages.prompt.delete',
    defaultMessage:
      'Your saved {beneficiary, plural, one {recipient} other {recipients}} will be permanently deleted. Any payment added with {beneficiary, plural, one {this recipient} other {these recipients}} will still be processed.',
    description: 'Prompt message when deleting a beneficiary',
  },
  'messages.prompt.deleteBeneficiaries': {
    id: 'messages.prompt.deleteBeneficiaries',
    defaultMessage: 'Delete {beneficiary, plural, one {recipient} other {recipients}}?',
    description: 'Prompt title when deleting sources',
  },
  'messages.prompt.deleteReportTemplate': {
    id: 'messages.prompt.deleteReportTemplate',
    defaultMessage: 'Are you sure you want to delete this template? This action cannot be undone?',
    description: 'Prompt message when deleting a Report template',
  },
  'messages.prompt.deleteAccount': {
    id: 'messages.prompt.deleteAccount',
    defaultMessage: 'Are you sure you want to delete this account?',
    description: 'Prompt message when deleting an account',
  },
  'messages.prompt.deleteSource.title': {
    id: 'messages.prompt.deleteSource.title',
    defaultMessage: 'Delete {source, plural, one {card} other {cards}}?',
    description: 'Prompt title when deleting sources',
  },
  'messages.prompt.deleteSource': {
    id: 'messages.prompt.deleteSource',
    defaultMessage:
      'Your saved {source, plural, one {card} other {cards}} will be permanently deleted. Any payment added with {source, plural, one {this card} other {these cards}} will still be processed.',
    description: 'Prompt message when deleting a source',
  },
  'messages.prompt.ok': {
    id: 'messages.prompt.ok',
    defaultMessage: 'Delete',
    description: 'Text for delete button on prompt message box',
  },
  'messages.prompt.cancel': {
    id: 'messages.prompt.cancel',
    defaultMessage: 'Cancel',
    description: 'Text for cancel button on prompt message box',
  },
  'messages.success.beneficiary.add': {
    id: 'messages.success.beneficiary.add',
    defaultMessage: 'Beneficiary added successfully',
    description: 'Success message on adding a beneficiary',
  },
  'messages.error.beneficiary.add': {
    id: 'messages.error.beneficiary.add',
    defaultMessage: 'We were unable to add the beneficiary, please try again',
    description: 'Error message on adding a beneficiary',
  },
  'messages.success.beneficiary.save': {
    id: 'messages.success.beneficiary.save',
    defaultMessage: 'Beneficiary saved successfully',
    description: 'Success message on saving a beneficiary',
  },
  'messages.error.beneficiary.save': {
    id: 'messages.error.beneficiary.save',
    defaultMessage: 'We were unable to save the beneficiary, please try again',
    description: 'Error message on saving a beneficiary',
  },
  'messages.success.beneficiary.delete': {
    id: 'messages.success.beneficiary.delete',
    defaultMessage: '{beneficiary, plural, one {Recipient} other {Recipients}} deleted successfully',
    description: 'Success message on deleting a beneficiary',
  },
  'messages.error.beneficiary.delete': {
    id: 'messages.error.beneficiary.delete',
    defaultMessage:
      'Unable to delete {beneficiary, plural, one {recipient} other {recipients}}. Please try again later',
    description: 'Error message on deleting a beneficiary',
  },
  'messages.partial.error.beneficiary.delete': {
    id: 'messages.partial.error.beneficiary.delete',
    defaultMessage: 'One or more recipients could not be deleted!',
    description: 'Error message when deleting multiple beneficairies at least one of them fails',
  },
  'messages.error.beneficiary.search': {
    id: 'messages.error.beneficiary.search',
    defaultMessage: 'We were unable to search the beneficiaries, please try again',
    description: 'Error message on searching for beneficiaries',
  },
  'messages.error.addBeneficiary.enter.name': {
    id: 'messages.error.addBeneficiary.enter.name',
    defaultMessage: 'Please add the name of the recipient.',
    description: 'Error message to enter mandatory beneficiary name',
  },
  'messages.error.addBeneficiary.enter.title': {
    id: 'messages.error.addBeneficiary.enter.title',
    defaultMessage: 'Please add the name of the recipient.',
    description: 'Error message to enter mandatory beneficiary title',
  },
  'messages.error.addBeneficiary.invalidTitle': {
    id: 'messages.error.addBeneficiary.invalidTitle',
    defaultMessage: 'The input can’t consist of only blank characters (space) or start with a colon (:) or hyphen (-).',
    description: 'Error message for invalid beneficiary title input',
  },
  'messages.error.addBeneficiary.select.country': {
    id: 'messages.error.addBeneficiary.select.country',
    defaultMessage: 'Please select recipient country',
    description: 'Error message to select beneficiary country',
  },
  'messages.error.addBeneficiary.select.accountType': {
    id: 'messages.error.addBeneficiary.select.accountType',
    defaultMessage: 'Please select account type',
    description: 'Error message to select beneficiary account type',
  },
  'messages.error.addBeneficiary.enter.IBAN': {
    id: 'messages.error.addBeneficiary.enter.IBAN',
    defaultMessage: 'Please add a valid number.',
    description: 'Error message to enter IBAN number',
  },
  'messages.error.addBeneficiary.enter.type': {
    id: 'messages.error.addBeneficiary.enter.type',
    defaultMessage: 'Please select a business type.',
    description: 'Error message to enter business type',
  },
  'messages.error.addBeneficiary.enter.accountNumber': {
    id: 'messages.error.addBeneficiary.enter.accountNumber',
    defaultMessage: 'Please add a valid number. ',
    description: 'Error message to enter account number',
  },
  'messages.error.addBeneficiary.enter.validAccountNumber': {
    id: 'messages.error.addBeneficiary.enter.validAccountNumber',
    defaultMessage: 'Please add a valid number. ',
    description: 'Error message to enter valid account number',
  },
  'messages.error.addBeneficiary.enter.giroNumber': {
    id: 'messages.error.addBeneficiary.enter.giroNumber',
    defaultMessage: 'Please enter giro number',
    description: 'Error message to enter giro number',
  },
  'messages.error.addBeneficiary.enter.giroNumberFormat': {
    id: 'messages.error.addBeneficiary.enter.giroNumberFormat',
    defaultMessage: 'Please enter valid giro number',
    description: 'Error message to enter a valid giro number',
  },
  'messages.error.addBeneficiary.enter.IBANFormat': {
    id: 'messages.error.addBeneficiary.enter.IBANFormat',
    defaultMessage: 'Please add a valid number.',
    description: 'Error message to enter a valid IBAN number',
  },
  'messages.error.addBeneficiary.enter.IBANBlockedCountry': {
    id: 'messages.error.addBeneficiary.enter.IBANBlockedCountry',
    defaultMessage: 'Payments to this country are not supported. ',
    description: 'Error message to enter a IBAN from country that is supported',
  },
  'messages.error.addBeneficiary.enter.sortCode': {
    id: 'messages.error.addBeneficiary.enter.sortCode',
    defaultMessage: 'Please add a valid code. ',
    description: 'Error message to enter sort code',
  },
  'messages.error.addBeneficiary.invalid.sortCode': {
    id: 'messages.error.addBeneficiary.invalid.sortCode',
    defaultMessage: 'Please add a valid code. ',
    description: 'Error message for invalid sort code',
  },
  'messages.error.addBeneficiary.enter.routingNumber': {
    id: 'messages.error.addBeneficiary.enter.routingNumber',
    defaultMessage: 'Please enter routing number',
    description: 'Error message to enter routing number',
  },
  'messages.error.addBeneficiary.select.recipientType': {
    id: 'messages.error.addBeneficiary.select.recipientType',
    defaultMessage: 'Please select recipient type',
    description: 'Error message to select recipient type',
  },
  'messages.error.addBeneficiary.incorrect.emailFormat': {
    id: 'messages.error.addBeneficiary.incorrect.emailFormat',
    defaultMessage: 'The input is not valid E-mail',
    description: 'Error message when incorrect email format',
  },
  'messages.error.addBeneficiary.enter.streetAddress': {
    id: 'messages.error.addBeneficiary.enter.streetAddress',
    defaultMessage: 'Please enter recipent street address',
    description: 'Error message to enter street address',
  },
  'messages.error.addBeneficiary.enter.zip': {
    id: 'messages.error.addBeneficiary.enter.zip',
    defaultMessage: 'Please enter recipent zip/postal code',
    description: 'Error message to enter street address',
  },
  'messages.error.addBeneficiary.enter.city': {
    id: 'messages.error.addBeneficiary.enter.city',
    defaultMessage: 'Please enter recipent city',
    description: 'Error message to enter street address',
  },
  'messages.success.settings.updateUser': {
    id: 'messages.success.settings.updateUser',
    defaultMessage: 'Updated successfully!',
    description: 'Success message when user settings are updated',
  },
  'messages.error.settings.updateUser': {
    id: 'messages.error.settings.updateUser',
    defaultMessage: 'We were unable to save the user, please try again!',
    description: 'Error message when user settings could not be updated',
  },
  'messages.error.settings.updatePhone': {
    id: 'messages.error.settings.updatePhone',
    defaultMessage: 'We were unable to save the phone, please try again!',
    description: 'Error message when phone settings could not be updated',
  },
  'messages.success.settings.deleteUser': {
    id: 'messages.success.settings.deleteUser',
    defaultMessage: 'Deleted successfully!',
    description: 'Success message when user deleted successfully',
  },
  'messages.error.settings.deleteUser': {
    id: 'messages.error.settings.deleteUser',
    defaultMessage: 'We were unable to delete the user, please try again!',
    description: 'Error message when user settings could not be deleted',
  },
  'messages.error.settings.fetchUser': {
    id: 'messages.error.settings.fetchUser',
    defaultMessage: 'We were unable to get the user details, please try again!',
    description: 'Error message when user settings could not be fetched',
  },
  'messages.success.settings.document.upload': {
    id: 'messages.success.settings.document.upload',
    defaultMessage: 'Document uploaded successfully!',
    description: 'Success message on uploading a document',
  },
  'messages.error.settings.document.upload': {
    id: 'messages.error.settings.document.upload',
    defaultMessage: 'We were unable to upload the document, please try again!',
    description: 'Error message on uploading a document',
  },
  'messages.success.settings.document.delete': {
    id: 'messages.success.settings.document.delete',
    defaultMessage: 'Document deleted successfully!',
    description: 'Success message on deleting a document',
  },
  'messages.error.settings.document.delete': {
    id: 'messages.error.settings.document.delete',
    defaultMessage: 'We were unable to delete the document, please try again!',
    description: 'Error message on deleting a document',
  },
  'messages.error.uploadDocument.typeOfDocument': {
    id: 'messages.error.uploadDocument.typeOfDocument',
    defaultMessage: 'Please select the type of document',
    description: 'Error message if document type is not selected',
  },
  'messages.error.country.fetch': {
    id: 'messages.error.country.fetch',
    defaultMessage: 'We were unable to fetch the countries, please try again!',
    description: 'Error message on fetching countries',
  },
  'messages.error.entity.fetch': {
    id: 'messages.error.entity.fetch',
    defaultMessage: 'We were unable to fetch the entities, please try again!',
    description: 'Error message on fetching entities',
  },
  'messages.error.entityRoles.fetch': {
    id: 'messages.error.entityRoles.fetch',
    defaultMessage: 'We were unable to fetch the entity roles, please try again!',
    description: 'Error message on fetching entity roles',
  },
  'messages.error.entityRoles.user': {
    id: 'messages.error.entityRoles.user',
    defaultMessage: 'Please select at least one role for the user.',
    description: 'Error message when entity roles are not selected',
  },
  'messages.success.user.add': {
    id: 'messages.success.user.add',
    defaultMessage: 'User added successfully!',
    description: 'Success message when user is added successfully',
  },
  'messages.success.user.edit': {
    id: 'messages.success.user.edit',
    defaultMessage: 'User updated successfully!',
    description: 'Success message when user is updated successfully',
  },
  'messages.success.user.delete': {
    id: 'messages.success.user.delete',
    defaultMessage: 'User deleted successfully!',
    description: 'Success message when user deleted successfully',
  },
  'messages.success.user.editRoles': {
    id: 'messages.success.user.editRoles',
    defaultMessage: 'User roles updated successfully!',
    description: 'Success message when user roles updated successfully',
  },
  'messages.error.user.add': {
    id: 'messages.error.user.add',
    defaultMessage: 'We were unable to create the user, please try again!',
    description: 'Error message when creating user',
  },
  'messages.error.user.phone.already.exists': {
    id: 'messages.error.user.phone.already.exists',
    defaultMessage: 'Phone number already exists',
    description: 'Error message when creating user',
  },
  'messages.error.user.email.already.exists': {
    id: 'messages.error.user.email.already.exists',
    defaultMessage: 'Email already exists',
    description: 'Error message when creating user',
  },
  'messages.error.user.add.entityRoles.required': {
    id: 'messages.error.user.add.entityRoles.required',
    defaultMessage: 'Please select users entity roles',
    description: 'Error message when creating user',
  },
  'messages.error.user.edit': {
    id: 'messages.error.user.edit',
    defaultMessage: 'We were unable to update the user, please try again!',
    description: 'Error message when editing user',
  },
  'messages.error.details.user.edit': {
    id: 'messages.error.details.user.edit',
    defaultMessage: 'Please check if phone number is in correct format',
    description: 'Error message when editing user',
  },
  'messages.error.user.delete': {
    id: 'messages.error.user.delete',
    defaultMessage: 'We were unable to delete the user, please try again!',
    description: 'Error message when deleting user',
  },
  'messages.error.user.editRoles': {
    id: 'messages.error.user.editRoles',
    defaultMessage: 'We were unable to update the user roles, please try again!',
    description: 'Error message when editing user roles',
  },
  'messages.success.cards.delete': {
    id: 'messages.success.cards.delete',
    defaultMessage: '{source, plural, one {Card} other {Cards}} deleted successfully',
    description: 'Success message when deleting card',
  },
  'messages.error.cards.delete': {
    id: 'messages.error.cards.delete',
    defaultMessage: 'Unable to delete {source, plural, one {card} other {cards}}. Please try again later',
    description: 'Error message when deleting card',
  },
  'messages.partial.error.cards.delete': {
    id: 'messages.partial.error.cards.delete',
    defaultMessage: 'One or more cards could not be deleted!',
    description: 'Error message when deleting multiple cards at least one of them fails',
  },
  'messages.errorDescription.cards.delete': {
    id: 'messages.errorDescription.cards.delete',
    defaultMessage: 'We were unable to delete the Source, please try again!',
    description: 'Error message description when deleting card',
  },
  'messages.error.cards.get': {
    id: 'messages.error.cards.get',
    defaultMessage: 'Error!',
    description: 'Error message when getting cards',
  },
  'messages.errorDescription.cards.get': {
    id: 'messages.errorDescription.cards.get',
    defaultMessage: 'We were unable to get Sources, please try again!',
    description: 'Error message description when getting cards',
  },
  'messages.error.cards.create': {
    id: 'messages.error.cards.create',
    defaultMessage: 'Error',
    description: 'Error message when creating card',
  },
  'messages.success.reports.create': {
    id: 'messages.success.reports.create',
    defaultMessage: 'Report successfuly sent to your email',
    description: 'Success message on creating report',
  },
  'messages.error.reports.create': {
    id: 'messages.error.reports.create',
    defaultMessage: 'There are no payments for selected filters',
    description: 'Error message on creating report',
  },
  'messages.error.password.update': {
    id: 'messages.error.password.update',
    defaultMessage: 'Unable to update password!',
    description: 'Error message on updating password',
  },
  'messages.success.password.update': {
    id: 'messages.success.password.update',
    defaultMessage: 'Password updated successfuly!',
    description: 'Success message on updating password',
  },
  'messages.success.source.edit': {
    id: 'messages.success.source.edit',
    defaultMessage: 'Card edited successfully',
    description: 'Success message on editing a source',
  },
  'messages.integration.account.select.success': {
    id: 'messages.integration.account.select.success',
    defaultMessage: 'Expense account saved',
    description: 'Success message on selecting integration account',
  },
  'messages.integration.account.select.fail': {
    id: 'messages.integration.account.select.fail',
    defaultMessage: 'Unable to change expense account',
    description: 'Warning message on selecting integration account',
  },
  'messages.integration.account.select.notChanged': {
    id: 'messages.integration.account.select.notChanged',
    defaultMessage: 'Expense account not changed',
    description: 'Info message on selecting same integration account',
  },
  'messages.integration.pending.title': {
    id: 'messages.integration.pending.title',
    defaultMessage: 'Almost there!',
    description: 'Warning message title when integration is in pending state',
  },
  'messages.integration.pending.description': {
    id: 'messages.integration.pending.description',
    defaultMessage:
      'You need to complete your {integration} integration before you can start import payments from {integration}',
    description: 'Warning message description when integration is in pending state',
  },
  'messages.integration.pending.url': {
    id: 'messages.integration.pending.url',
    defaultMessage: '{url}',
    description: 'Location where user can choose an account and active integration that is in pending state',
  },
  'messages.integration.pending.complete.link': {
    id: 'messages.integration.pending.complete.link',
    defaultMessage: 'Integrate',
    description: 'Complete integration button label',
  },
  'messages.integration.pending.complete.button': {
    id: 'messages.integration.pending.complete.button',
    defaultMessage: 'Integrate',
    description: 'Complete integration button label',
  },
  'messages.success.template.create': {
    id: 'messages.success.template.create',
    defaultMessage: 'Template successfully crested',
    description: 'Success message on creating a template',
  },
  'messages.success.template.selected': {
    id: 'messages.success.template.selected',
    defaultMessage: 'Template successfully selected',
    description: 'Success message on selecting a template',
  },
  'messages.success.template.delete': {
    id: 'messages.success.template.delete',
    defaultMessage: 'Template successfully deleted',
    description: 'Success message when deleting template',
  },
  'messages.error.template.create': {
    id: 'messages.error.template.create',
    defaultMessage: 'Error when trying to creating template',
    description: 'Error message when creating template',
  },
  'messages.error.template.delete': {
    id: 'messages.error.template.delete',
    defaultMessage: 'Action failed!',
    description: 'Error message when deleting template',
  },
  'messages.errorDescription.template.delete': {
    id: 'messages.errorDescription.template.delete',
    defaultMessage: 'We were unable to delete the template, please try again!',
    description: 'Error message description when deleting template',
  },
  'messages.success.template.update': {
    id: 'messages.success.template.update',
    defaultMessage: 'Template successfully updated',
    description: 'Success message on update template',
  },
  'messages.error.template.update': {
    id: 'messages.error.template.update',
    defaultMessage: 'Error when trying to update template',
    description: 'Error message on update template',
  },
  'messages.error.card_date.missing': {
    id: 'messages.error.card_date.missing',
    defaultMessage: 'Missing card network due date, please contact support',
    description: 'Error message when card network date is missing',
  },
})
