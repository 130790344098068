import React from 'react'
import useSettingsCardStyle from './SettingsCard.style'

interface CardProps {
  title: string
  subtitle?: string
  icon: string
  children: React.JSX.Element
}

const SettingsCard = ({ title, icon, subtitle, children }: CardProps): React.JSX.Element => {
  const { styles } = useSettingsCardStyle()
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <span className={styles.title}>
          <span className={styles.icon}>
            <img src={icon} alt="logo" />
          </span>
          {title}
        </span>
        {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  )
}

export default SettingsCard
