import React, { useState, useEffect } from 'react'

import { useIntl } from 'react-intl'
import Payments from '../../components/Payments/Payments'
import PaymentsSummary from '../../components/PaymentsSummary/PaymentsSummary'
import SubmitPayments from '../../components/SubmitPayments/SubmitPayments'
import ForeignCurrencyWarning from 'components/ForeignCurrencyWarning/ForeignCurrencyWarning'
import { Set, SetTotalDetails, PAYMENT_KIND, PAYMENT_STATE } from 'types/paymentInstruction'
import { pi } from 'lang/definitions'
import PaymentsHeader from '../../components/PaymentsHeader'
import Approvers from '../../Add/ReviewPayments/Approvers'
import { Approver } from 'types/user'
import { Checkbox } from 'antd'
import { useCurrentPayment } from 'stores/Payment'
import useReviewPaymentsStyle from '../../Add/ReviewPayments/ReviewPayments.style'

interface ReviewPaymentsProps {
  approvers: Approver[]
  totalNumberOfPayments: number
  numberOfSignaturesRequired: number
  removePaymentFromSet: (kind: PAYMENT_KIND, id: string) => void
  showPaymentDetails: (kind: PAYMENT_KIND, paymentId: string) => void
  getNumberOfSignaturesRequired: (paymentIds: string[]) => void
  goToProgressSlide: () => void
  submitButton?: React.JSX.Element
  startPolling: (set: Set) => void
  requireActionSet?: Set
}

const ReviewPayments = (props: ReviewPaymentsProps): React.JSX.Element => {
  const {
    totalNumberOfPayments,
    approvers,
    removePaymentFromSet,
    showPaymentDetails,
    getNumberOfSignaturesRequired,
    goToProgressSlide,
    submitButton,
    requireActionSet,
    startPolling,
  } = props

  const intl = useIntl()
  const { styles } = useReviewPaymentsStyle()
  const [paymentInstructionDetails, setPaymentInstructionDetails] = useState<SetTotalDetails>()
  const [templateDetails, setTemplateDetails] = useState<SetTotalDetails>()
  const [, setNumberOfPayments] = useState(0)
  const [currentRound, setCurrentRound] = useState<number>(0)
  const [multiple, setMultiple] = useState<number>(0)

  const {
    state: { signatureOrder, needConfirmation, checkedConfirmation, paymentsInSet, approveAndPaySet, approveSet },
    actions: { setCheckedConfirmation, setNeedConfirmation },
  } = useCurrentPayment()

  const flowIsSequential = signatureOrder === 'sequential'

  useEffect(() => {
    if (approveAndPaySet && approveAndPaySet.paymentInstructionState === PAYMENT_STATE.REQUIRE_SIGNATURE) {
      const paymentInstructionDetails = approveAndPaySet.detailsTotal?.details.find(
        (details: SetTotalDetails) => details.kind === 'paymentInstruction'
      )

      const templateDetails = approveAndPaySet.detailsTotal?.details.find(
        (details: SetTotalDetails) => details.kind === 'paymentInstructionTemplate'
      )

      setPaymentInstructionDetails(paymentInstructionDetails)
      setTemplateDetails(templateDetails)
    } else {
      setPaymentInstructionDetails(undefined)
      setTemplateDetails(undefined)
    }
  }, [approveAndPaySet])

  useEffect(() => {
    if (!approveSet) {
      return
    }

    const paymentInstructionIds = approveSet.paymentInstructionIds || []
    const paymentInstructionTemplateIds = approveSet.paymentInstructionTemplateIds || []

    const numberOfPaymentInstructions = paymentInstructionIds.length
    const numberOfTemplates = paymentInstructionTemplateIds.length

    setNumberOfPayments(numberOfPaymentInstructions + numberOfTemplates)

    getNumberOfSignaturesRequired([...paymentInstructionIds, ...paymentInstructionTemplateIds])
  }, [approveSet])

  useEffect(() => {
    const [currentPayment] = paymentsInSet

    if (!currentPayment) {
      return
    }

    const currentRound = (currentPayment.signatures?.signatureRequests.length || 0) + 1
    setCurrentRound(currentRound)
  }, [paymentsInSet])

  useEffect(() => {
    handleDisplayWarning()
  }, [paymentsInSet])

  const handleDisplayWarning = (): void => {
    if (!paymentsInSet) {
      return
    }

    const shouldShowWarning = paymentsInSet.some((payment) => payment.newBeneficiary === true)
    setNeedConfirmation(shouldShowWarning)

    const count = paymentsInSet.filter((pi) => pi.newBeneficiary).length
    setMultiple(count)
  }

  const onChangeCheckBox = (value: boolean) => {
    setCheckedConfirmation(value)
  }

  return (
    <div className={styles.reviewContainer} data-testid="review-payments-container">
      <PaymentsHeader label={intl.formatMessage(pi['pi.review.reviewPayments.header'])} enablePaymentsFilter={false} />
      {paymentsInSet.length ? (
        <React.Fragment>
          <Payments
            paymentList={paymentsInSet}
            totalNumberOfPayments={totalNumberOfPayments}
            disableSelect={true}
            selectPayments={() => {
              return
            }}
            deselectPayments={() => {
              return
            }}
            removePaymentFromSet={removePaymentFromSet}
            showPaymentDetails={showPaymentDetails}
          />
          <React.Fragment>
            {paymentInstructionDetails && paymentInstructionDetails.count > 0 && (
              <PaymentsSummary setTotalDetails={paymentInstructionDetails} />
            )}
            {templateDetails && templateDetails.count > 0 && <PaymentsSummary setTotalDetails={templateDetails} />}
            {needConfirmation && (
              <div className="confirm-checkbox">
                <Checkbox
                  checked={checkedConfirmation}
                  onChange={(e) => {
                    onChangeCheckBox(e.target.checked)
                  }}
                >
                  {multiple > 1
                    ? intl.formatMessage(pi['pi.multipleNewBeneficiaryConfirmation.message'])
                    : intl.formatMessage(pi['pi.newBeneficiaryConfirmation.message'])}
                </Checkbox>
              </div>
            )}
            <ForeignCurrencyWarning payments={paymentsInSet} />
            {flowIsSequential && (
              <Approvers
                set={approveSet}
                approvers={approvers}
                getNumberOfSignaturesRequired={getNumberOfSignaturesRequired}
                currentRound={currentRound}
              />
            )}
          </React.Fragment>
        </React.Fragment>
      ) : (
        <div className={styles.noDataMessage} data-testid="no-data-message">
          <span>{intl.formatMessage(pi['pi.review.reviewPayments.noDataMessage'])}</span>
        </div>
      )}
      {submitButton ? (
        submitButton
      ) : (
        <SubmitPayments
          goToProgressSlide={goToProgressSlide}
          requireActionSet={requireActionSet}
          startPolling={startPolling}
          skipApproverSelection={true}
        />
      )}
    </div>
  )
}

export default ReviewPayments
