import bankRoutingValidator from 'bank-routing-number-validator'
import { RuleObject } from 'antd/lib/form'
import { useDebouncePromise } from 'hooks'
import { useTranslation } from './helpers'
import UkModulusChecking from 'uk-modulus-checking'
import iban from 'iban'
import { checkLuhn } from 'pages/Beneficiaries/utils'
import { PaymentType } from 'types/beneficiary'

const blockedCountryCodes = ['CY', 'RO']
const FORM_DEBOUNCE_TIME = 300

export const useLocalUSAccountNumberValidator = () => {
  const t = useTranslation()

  return useDebouncePromise((_ruleObject: RuleObject, value: string) => {
    const regex = `^\\d{${UsAccountNumberMinLength},${UsAccountNumberMaxLength}}$`
    if (new RegExp(regex).test(value)) {
      return Promise.resolve()
    }

    return Promise.reject(t('beneficiary.form.ach.account.error'))
  }, FORM_DEBOUNCE_TIME)
}

export const useSwiftAccountNumberValidator = () => {
  const t = useTranslation()

  return useDebouncePromise((rule: RuleObject, value: string) => {
    const reject = () => Promise.reject(t('beneficiary.form.ach.account.error'))

    if (!value || value.length < SwiftAccountNumberMinLength) {
      return reject()
    }

    // Must be 1 to 34 digits or letters
    const regex = `^[a-zA-Z0-9]{1,${AccountNumberMaxLength}}$`
    if (new RegExp(regex).test(value)) {
      return Promise.resolve()
    }

    return reject()
  }, FORM_DEBOUNCE_TIME)
}

export const useLocalUsRoutingNumberValidator = () => {
  const t = useTranslation()

  return useDebouncePromise((_ruleObject: RuleObject, value: string) => {
    const regex = `^\\d{${UsRoutingNumberMinLength},${UsAccountNumberMaxLength}}$`

    if (new RegExp(regex).test(value) && bankRoutingValidator.ABARoutingNumberIsValid(value)) {
      return Promise.resolve()
    }

    return Promise.reject(t('beneficiary.form.ach.routing.error'))
  }, FORM_DEBOUNCE_TIME)
}

export const bicCodePattern = /^([A-Z]{6}[A-Z2-9][A-NP-Z1-9])(X{3}|[A-WY-Z0-9][A-Z0-9]{2})?$/

export const useBicCodeValidator = () => {
  const t = useTranslation()

  return useDebouncePromise((_ruleObject: RuleObject, value: string) => {
    if (!value) {
      return Promise.reject(t('beneficiary.bicCode.error.required'))
    }
    if (bicCodePattern.test(value)) {
      return Promise.resolve()
    }

    return Promise.reject(t('beneficiary.bicCode.error.invalid'))
  }, FORM_DEBOUNCE_TIME)
}

export const useLocalUKAccountNumberValidator = (routingNumber: string) => {
  const t = useTranslation()

  return useDebouncePromise((_rule: RuleObject, value: string) => {
    if (!value || value.length < UkRoutingNumberMinLength) {
      return Promise.reject(t('messages.error.addBeneficiary.enter.accountNumber'))
    }
    const modChecking = new UkModulusChecking({
      accountNumber: value,
      sortCode: routingNumber?.replace(/\D/g, ''),
    })

    return modChecking.isValid()
      ? Promise.resolve()
      : Promise.reject(t('messages.error.addBeneficiary.enter.accountNumber'))
  }, FORM_DEBOUNCE_TIME)
}

export const useIbanAccountNumberValidator = () => {
  const t = useTranslation()

  return useDebouncePromise((_rule, value: string) => {
    if (!value) {
      return Promise.reject(t('messages.error.addBeneficiary.enter.IBAN'))
    }

    const country = value.slice(0, 2)
    const isValid = iban.isValid(value)

    if (isValid) {
      if (blockedCountryCodes.includes(country.toUpperCase())) {
        return Promise.reject(t('messages.error.addBeneficiary.enter.IBANBlockedCountry'))
      }

      return Promise.resolve()
    }

    return Promise.reject(t('messages.error.addBeneficiary.enter.IBANFormat'))
  }, FORM_DEBOUNCE_TIME)
}

export const useLocalUkRoutingNumberValidator = () => {
  const t = useTranslation()
  return useDebouncePromise((_rule, value: string) => {
    const formattedSortCode = value?.replace(/\D/g, '')

    if (
      formattedSortCode &&
      formattedSortCode.length >= UkRoutingNumberMinLength &&
      formattedSortCode.length <= UkRoutingNumberMaxLength
    ) {
      return Promise.resolve()
    }

    return Promise.reject(t('messages.error.addBeneficiary.enter.sortCode'))
  }, FORM_DEBOUNCE_TIME)
}
export const useLocalAddressValidator = (fieldType: string | undefined) => {
  const t = useTranslation()

  return useDebouncePromise((_ruleObject: RuleObject, value: string) => {
    // Check for the minimum length only if the field is not 'apartment'
    if (fieldType !== 'apartment' && (!value || value.length < 2)) {
      switch (fieldType) {
        case 'city':
          return Promise.reject(t('beneficiary.form.address.city.error'))
        case 'street':
          return Promise.reject(t('beneficiary.form.address.street.error'))
        case 'zip':
          return Promise.reject(t('beneficiary.form.address.zip.error'))
        default:
          // Provide a default rejection message when fieldType is not provided or does not match
          return Promise.reject(t('beneficiary.form.address.error.generic'))
      }
    }
    const startsWithInvalidCharacter = /^[\s:-]/.test(value)
    if (startsWithInvalidCharacter) {
      switch (fieldType) {
        case 'city':
          return Promise.reject(t('beneficiary.form.address.error.invalidTitleCity'))
        case 'zip':
          return Promise.reject(t('beneficiary.form.address.error.invalidTitleZip'))
        // You can add a case for 'apartment' here if needed
        default:
          return Promise.reject(t('beneficiary.form.address.error.invalidStart'))
      }
    }

    return Promise.resolve()
  }, FORM_DEBOUNCE_TIME)
}

export const BENEFICIARY_TITLE_PATTERN = /^(?![\s:-])[\s\da-zA-Z0-9()?+/:,.'-]*$/

export const useTitleValidator = () => {
  const t = useTranslation()

  return useDebouncePromise((_ruleObject: RuleObject, value: string) => {
    if (!value || value.length < 2) {
      return Promise.reject(t('messages.error.addBeneficiary.enter.name'))
    }
    if (/^\s+$/.test(value) || value.startsWith(':') || value.startsWith('-')) {
      return Promise.reject(t('messages.error.addBeneficiary.invalidTitle'))
    }
    if (BENEFICIARY_TITLE_PATTERN.test(value)) {
      return Promise.resolve()
    }

    return Promise.reject(t('beneficiary.title.error.invalid.character'))
  }, FORM_DEBOUNCE_TIME)
}

export const useBgPgAccountNumberValidator = (paymentType: PaymentType) => {
  const t = useTranslation()

  const invalidAccountNumberMessage =
    paymentType === PaymentType.BankGiro
      ? t('beneficiary.bank.accountNumber.invalid')
      : t('beneficiary.plus.accountNumber.invalid')

  const getCleanAccountNumber = (accountNumber: string | null | undefined) => {
    if (!accountNumber) return ''
    return accountNumber.replace(/\D/g, '')
  }

  return useDebouncePromise((_ruleObject: RuleObject, value: string) => {
    const minLength = paymentType === PaymentType.BankGiro ? BgAccountNumberMinLength : PgAccountNumberMinLength

    if (value?.length <= minLength || !checkLuhn(getCleanAccountNumber(value))) {
      return Promise.reject(invalidAccountNumberMessage)
    } else {
      return Promise.resolve()
    }
  }, FORM_DEBOUNCE_TIME)
}

export const AccountNumberMaxLength = 34
export const AccountNumberMinLength = 1
export const BG_MAX_LENGTH = 8
export const BG_MIN_LENGTH = 7
export const BgAccountNumberMinLength = 6
export const BgPgAccountNumberMaxLength = 8
export const GenericMaxLength = 35
export const IbanMaxLength = 34
export const PG_MAX_LENGTH = 10
export const PG_MIN_LENGTH = 2
export const PgAccountNumberMinLength = 2
export const SwiftAccountNumberMinLength = 6
export const SwiftRoutingNumberMaxLength = 11
export const SwiftRoutingNumberMinLength = 8
export const UkAccountNumberMaxLength = 8
export const UkAccountNumberMinLength = 7
export const UkRoutingNumberMaxLength = 6
export const UkRoutingNumberMinLength = 6
export const UsAccountNumberMaxLength = 12
export const UsAccountNumberMinLength = 10
export const UsRoutingNumberMaxLength = 9
export const UsRoutingNumberMinLength = 9
