import { defineMessages } from 'react-intl'

export const errors = defineMessages({
  'error.default': {
    id: 'error.default',
    defaultMessage: 'An error has occurred',
    description: 'This string sets default error modal text',
  },
  'error.404.default': {
    id: 'error.404.default',
    defaultMessage: 'Resource not found',
    description: 'This string sets default 404 error modal text',
  },
  'error.401.default': {
    id: 'error.401.default',
    defaultMessage:
      'You could not access the requested asset. If you think this is wrong, please contact your Billhop administrator or Billhop Customer service',
    description: 'This string sets default 401 error modal text',
  },
  'error.invalid.token': {
    id: 'error.invalid.token',
    defaultMessage: 'Invalid or expired token',
    description: 'When an invalid or expired token is used to reset password',
  },
  'error.session.title': {
    id: 'error.session.title',
    defaultMessage: 'Session expired',
    description: 'This string sets session expired error modal title',
  },
  'error.session.message': {
    id: 'error.session.message',
    defaultMessage: 'You are no longer logged in on your Billhop account. Restart your session by logging in again',
    description: 'This string sets session expired error modal text',
  },
  'error.session.button': {
    id: 'error.session.button',
    defaultMessage: 'Login again',
    description: 'This string sets session expired error modal button text',
  },
  'error.month.limit.exceeded': {
    id: 'error.month.limit.exceeded',
    defaultMessage: 'Month limit exceeded',
    description: 'This string sets month limit error modal text',
  },
  'error.faq.fetch': {
    id: 'error.faq.fetch',
    defaultMessage: 'Error fetching FAQ',
    description: 'This string sets the error message when there is an issue fetching FAQs',
  },
  'error.updateRules.default': {
    id: 'error.updateRules.default',
    defaultMessage: 'Oops! Something went wrong while updating notification preferences',
    description: 'This string sets default error message for updating notification rules',
  },
  'networkError.message': {
    id: 'networkError.message',
    defaultMessage: 'Unable to complete the action. Please try again later.',
    description: 'This string sets error message for network error',
  },
  'error.login.generic': {
    id: 'error.login.generic',
    defaultMessage: 'An unexpected error occurred during login',
    description: 'This string sets the error message for generic login errors',
  },
})
