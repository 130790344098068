import { PAYMENT_KIND, Payment, PaymentInstructionTemplate } from 'types/paymentInstruction'

export default function mapTemplateToPayment(template: PaymentInstructionTemplate): Payment {
  return {
    kind: PAYMENT_KIND.TEMPLATE,
    state: template.state!,
    id: template.id!,
    profileId: template.profileId,
    beneficiaryTitle: template.paymentInstructionProperties.beneficiary!.title,
    beneficiaryAccountNumber: template.paymentInstructionProperties.beneficiary!.accountNumber,
    beneficiaryRoutingNumber: template.paymentInstructionProperties.beneficiary!.routingNumber,
    beneficiaryClearingNetwork: template.paymentInstructionProperties.beneficiary!.clearingNetwork,
    newBeneficiary: template.paymentInstructionProperties.beneficiary?.newBeneficiary,
    cardNetwork: template.paymentInstructionProperties.source?.typeProperties.cardNetwork,
    cardNumber: template.paymentInstructionProperties.source
      ? `${template.paymentInstructionProperties.source.typeProperties.bin} •••• •••• ${template.paymentInstructionProperties.source.typeProperties.last4}`
      : undefined,
    currency: template.paymentInstructionProperties.currency,
    amount: template.paymentInstructionProperties.amount,
    dateDue: template.dateDueNext,
    datePlannedCharge: template.datePlannedCharge,
    sourceId: template.paymentInstructionProperties.source?.id,
    signatures: template.signatures,
    source: template.paymentInstructionProperties.source,
    duplicateCheckId: `${template.paymentInstructionProperties.amount}${
      template.paymentInstructionProperties.currency
    }${template.paymentInstructionProperties.reference.join('')}${
      template.paymentInstructionProperties.beneficiary!.accountNumber
    }`,
    isSupplierFunded: !!template.paymentInstructionProperties.supplierFunded,
    creationChannel: template.creationChannel,
  }
}
