import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Button, Result } from 'antd'
import * as Sentry from '@sentry/react'
import { useUtils } from 'hooks/useUtils'
import { getAttachment } from 'api/paymentInstruction'
import { downloadReport } from 'api/reports'
import { getStaticFile } from 'api/static'
import ExternalContainer from 'components/ExternalContainer/ExternalContainer'
import { SplitContainer } from 'components/SplitContainer/SplitContainer'
import { fileDownload } from 'lang/definitions'
import { getFileDownloadTriggerInfo, removeFileDownloadTriggerInfo } from '../../utils/storage'

const FileDownloadPage = (): React.JSX.Element => {
  const intl = useIntl()
  const { logoutUser } = useUtils()
  const history = useHistory()
  const fileDownloadTriggerInfo = getFileDownloadTriggerInfo()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isSuccessfullyDownloaded, setIsSuccessfullyDownloaded] = useState<boolean>(false)

  const handleDownloadAttachment = async (piId: string, attachmentId: string, filename: string): Promise<void> => {
    const response = await getAttachment(piId, attachmentId)
    const urls = window.URL.createObjectURL(new Blob([response]))
    const link = document.createElement('a')
    link.href = urls
    link.setAttribute('download', `${filename}`)
    link.click()
  }

  const handleDownloadReport = async (reportId: string, filename: string): Promise<void> => {
    const response = await downloadReport(reportId)
    const urls = window.URL.createObjectURL(new Blob([response]))
    const link = document.createElement('a')
    link.href = urls
    link.setAttribute('download', `${filename}`)
    link.click()
  }

  const fetchStaticFile = async (fileId: string): Promise<void> => {
    try {
      setIsLoading(true)
      const staticFileObject = await getStaticFile(fileId)
      const {
        type,
        filename,
        relatedTo: { paymentInstructionId, paymentInstructionTemplateId, reportId, attachmentId } = {},
      } = staticFileObject

      if (type === 'piAttachment' && paymentInstructionId && attachmentId) {
        await handleDownloadAttachment(paymentInstructionId, attachmentId, filename)
        setIsSuccessfullyDownloaded(true)
      } else if (type === 'piAttachment' && paymentInstructionTemplateId && attachmentId) {
        await handleDownloadAttachment(paymentInstructionTemplateId, attachmentId, filename)
        setIsSuccessfullyDownloaded(true)
      } else if (type === 'report' && reportId) {
        await handleDownloadReport(reportId, filename)
        setIsSuccessfullyDownloaded(true)
      } else {
        setIsSuccessfullyDownloaded(false)
      }
    } catch (err) {
      setIsSuccessfullyDownloaded(false)
      Sentry.captureException(err)
    } finally {
      setIsLoading(false)
    }
  }

  const handleLogout = () => {
    void logoutUser()
    removeFileDownloadTriggerInfo()
  }

  const goToPayments = () => {
    history.push('/choose-profile')
    removeFileDownloadTriggerInfo()
  }

  useEffect(() => {
    if (fileDownloadTriggerInfo?.fileId && fileDownloadTriggerInfo.nextAction === 'download') {
      void fetchStaticFile(fileDownloadTriggerInfo.fileId)
    }
  }, [])

  return (
    <SplitContainer>
      <ExternalContainer loading={isLoading} showAlertMessages bhTrackClassName="bh-track-login-page-container">
        {isSuccessfullyDownloaded ? (
          <Result
            status="success"
            title={intl.formatMessage(fileDownload['file.download.page.success.title'])}
            subTitle={intl.formatMessage(fileDownload['file.download.page.success.subtitle'])}
            extra={[
              <Button type="primary" key="logout" onClick={handleLogout}>
                {intl.formatMessage(fileDownload['file.download.page.button.close'])}
              </Button>,
              <Button onClick={goToPayments} key="login">
                {intl.formatMessage(fileDownload['file.download.page.button.proceed'])}
              </Button>,
            ]}
          />
        ) : (
          <Result
            status="error"
            title={intl.formatMessage(fileDownload['file.download.page.error.title'])}
            subTitle={intl.formatMessage(fileDownload['file.download.page.error.subtitle'])}
            extra={[
              <Button type="primary" key="logout" onClick={handleLogout}>
                {intl.formatMessage(fileDownload['file.download.page.button.close'])}
              </Button>,
              <Button onClick={goToPayments} key="login">
                {intl.formatMessage(fileDownload['file.download.page.button.proceed'])}
              </Button>,
            ]}
          />
        )}
      </ExternalContainer>
    </SplitContainer>
  )
}

export default FileDownloadPage
