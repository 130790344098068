import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css, token }) => ({
  bhPhone: css``,
  bhPhoneInput: css`
    border-radius: ${token.borderRadius}px !important;
    width: 100% !important;

    &:disabled {
      background-color: #f5f5f5;
      color: rgba(0, 0, 0, 0.25);
    }
  `,
  bhPhoneBtn: css`
    border-bottom-left-radius: ${token.borderRadius}px !important;
    border-top-left-radius: ${token.borderRadius}px !important;
  `,
}))

export default useStyles
