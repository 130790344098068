import React from 'react'
import { Form, Input } from 'antd'
import { RuleObject } from 'antd/lib/form'
import { useTranslation } from 'utils/helpers'
import useSuffix from './useSuffix'

type Props = {
  label: string
  maxLength?: number
  validator: (_ruleObject: RuleObject, value: string) => Promise<void>
}

export default function RoutingNumber({ label, validator, maxLength }: Props): React.JSX.Element {
  const t = useTranslation()
  const form = Form.useFormInstance()

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.replace(/[^A-Za-z0-9]/g, '')
    form.setFieldsValue({ routingNumber: newValue })
  }

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault()
    const pastedValue = e.clipboardData.getData('Text')
    const cleanedValue = pastedValue.replace(/[^A-Za-z0-9]/g, '')
    const finalValue = maxLength ? cleanedValue.slice(0, maxLength) : cleanedValue

    form.setFieldsValue({ routingNumber: finalValue })
    void form.validateFields(['routingNumber'])
  }

  const pattern = /[A-Za-z0-9]/g
  const routingNumberSuffix = useSuffix({
    fieldName: 'routingNumber',
    maxLength,
    pattern,
  })

  return (
    <Form.Item
      label={label}
      name="routingNumber"
      validateTrigger="onChange"
      hasFeedback
      rules={[
        {
          validator,
        },
      ]}
    >
      <Input
        type="text"
        size="large"
        data-testid="routing-number-input"
        suffix={routingNumberSuffix}
        placeholder={t('beneficiary.bicCode.placeholder')}
        onInput={handleInputChange}
        onPaste={handlePaste}
        maxLength={maxLength}
      />
    </Form.Item>
  )
}
