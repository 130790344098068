import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css, responsive, token }) => {
  return {
    logoutWrapper: css`
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 0;
      padding: ${token.paddingXXS}px ${token.paddingSM}px;
      cursor: pointer;

      label {
        cursor: pointer;
        margin-left: ${token.marginXS}px;
      }

      &:hover {
        background-color: #f5f5f5;
      }

      ${responsive.sm} {
        color: #828282;
        cursor: pointer;

        label {
          cursor: pointer;
        }

        &:hover {
          background-color: fade(white, 15%);
        }
      }
    `,
  }
})

export default useStyles
