import React from 'react'
import { getLocaleDateStringFormat } from '../../utils/dates'
import { useLanguageState } from '../../stores/language/LanguageStore'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import dayjs, { Dayjs } from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'
import { DatePicker } from 'antd'
import { CSSProperties } from 'react'

dayjs.extend(customParseFormat)
dayjs.extend(utc)

interface BHRangePickerProps {
  className?: string
  values?: Array<string>
  disabled?: boolean
  placeholder?: Array<string>
  size?: SizeType
  popupStyle?: CSSProperties
  allowClear?: boolean
  dropdownClassName?: string
  onChange: (dates: [string, string] | []) => void
}

const { RangePicker } = DatePicker

const BHRangePicker = ({
  className,
  values,
  disabled,
  placeholder,
  size,
  popupStyle,
  allowClear,
  dropdownClassName,
  onChange,
}: BHRangePickerProps): React.JSX.Element => {
  const [languageState] = useLanguageState()
  const language = languageState.language

  const onRangeChange = (dates: null | (Dayjs | null)[]) => {
    if (dates) {
      const from = dates[0] ? dayjs(dates[0]).utc().toISOString() : ''
      const to = dates[1] ? dayjs(dates[1]).utc().toISOString() : ''

      onChange([from, to])
    } else {
      console.log('Clear')
      onChange([])
    }
  }

  return (
    <RangePicker
      className={`bh-rangepicker ${className ? className : ''}`}
      value={values ? [dayjs(values[0]), dayjs(values[1])] : undefined}
      format={{
        format: getLocaleDateStringFormat(language),
      }}
      disabled={disabled || false}
      placeholder={placeholder ? [placeholder[0], placeholder[1]] : undefined}
      onChange={onRangeChange}
      size={size}
      popupStyle={popupStyle}
      allowClear={allowClear || true}
      popupClassName={dropdownClassName || ''}
    />
  )
}

export default BHRangePicker
