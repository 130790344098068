import React from 'react'
import { beneficiaryStrings } from '../../lang/definitions/index'
import { IntlShape } from 'react-intl'
import AccountNumber from '../../components/Format/AccountNumber/AccountNumber'
import { Beneficiary } from '../../types/beneficiary'

export interface beneficiaryType {
  value: string
  label: string
}

export const beneficiaryColumns = (_language: string, intl: IntlShape) => [
  {
    title: intl.formatMessage(beneficiaryStrings['beneficiary.table.title']),
    dataIndex: 'title',
    sortName: 'title',
    key: 'title',
    sorter: true,
    className: 'beneficiary-name',
    render: (data: string) => {
      return <span className="beneficiary-column-title">{data}</span>
    },
  },
  {
    title: intl.formatMessage(beneficiaryStrings['beneficiary.table.currency']),
    dataIndex: 'currency',
    sortName: 'currency',
    key: 'currency',
    sorter: true,
    className: 'desktop-display',
    render: (data: string) => {
      return data ? data.toUpperCase() : ''
    },
  },

  {
    title: intl.formatMessage(beneficiaryStrings['beneficiary.accountNumber']),
    dataIndex: 'accountNumber',
    sortName: 'accountNumber',
    key: 'accountNumber',
    sorter: true,
    render: (_: string, record: Beneficiary) => {
      return (
        <AccountNumber
          accountNumber={record.accountNumber}
          routingNumber={record.routingNumber}
          clearingNetwork={record.clearingNetwork}
        ></AccountNumber>
      )
    },
  },
]
