import React, { PropsWithChildren } from 'react'
import { Form, Radio } from 'antd'

export default function RadioWithStyle({ value, children }: PropsWithChildren<{ value: string }>) {
  const { status } = Form.Item.useStatus()

  return (
    <Radio value={value} style={{ color: `${status === 'error' ? '#D66666' : ''}` }}>
      {children}
    </Radio>
  )
}
