import React from 'react'
import { Input, Select, Button, Badge, Spin } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'
import { filters } from '../../lang/definitions/index'
import { FilterState } from '../../types/general'
import BHRangePicker from '../RangePicker/BHRangePicker'
import useFilterStyle from './Filter.style'
import { cx } from 'antd-style'

export interface Data {
  id: number
  name: string
  value?: string
}

export interface DateRange {
  from: string
  to: string
}

export interface FilterOption {
  id?: string
  label?: string
  elementType?: string
  type:
    | 'button'
    | 'search'
    | 'date'
    | 'datePicker'
    | 'select'
    | 'selectWithSearch'
    | 'multipleSelect'
    | 'close'
    | 'countrySelect'
  dataSource?: Array<Data>
  placeholder?: string
  dropdownMatchSelectWidth?: boolean
  badge?: {
    color?: string
    count: number
  }
  fetching?: boolean
  callback: (value?: string | string[] | Date | React.MouseEvent<HTMLElement, MouseEvent>) => void
}

export interface FilterGroup {
  id?: string
  align?: string
  elements: FilterOption[]
}

const { Search } = Input
const { Option } = Select

interface FilterProps {
  isOpen: boolean
  label: string
  groups: Array<FilterGroup>
  closeFilter: () => void
  applyFilter?: () => void
  filterBody?: FilterBody
  filterState?: FilterState
  setFilterState?: (value: string, key: string) => void
  beneficiaryFilterField?: boolean
}

export interface RelatedTo {
  requestId?: string[]
  userId?: string[]
  entityId?: string[]
  profileId?: string[]
  beneficiaryId?: string[]
  paymentInstructionId?: string[]
  sourceId?: string[]
}

export interface FilterBody {
  kind?:
    | 'paymentInstruction'
    | 'paymentInstructionTemplate'
    | 'import'
    | 'beneficiary'
    | 'blacklist'
    | 'whitelist'
    | 'user'
    | 'event'
    | 'profile'
    | 'entity'
    | 'source'
    | 'charge'
    | 'curatedEvent'
    | 'profileGroup'
  operator?: 'and' | 'or'
  status?: 'SUCCESS' | 'REQUIRES_ACTION' // why not a string?
  ringFence?: {
    userId?: string[]
    entityId?: string[]
    profileId?: string[]
  }
  id?: string[]
  title?: string
  profileId?: string[]
  sourceId?: string[]
  paymentInstructionId?: string[]
  paymentInstructionTemplateId?: string[]
  state?: string[]
  piStates?: string[]
  templateStates?: string[]
  dateCreated?: DateRange
  dateDue?: DateRange
  userId?: string[]
  beneficiaryTitle?: string
  q?: string
  beneficiaryId?: string[]
  currency?: string
  signatures?: {
    signatureRequests?: { userId: string[] }
    signedBy?: { userId: string[] }
  }
  user?: string
  entityId?: string[]
  entityIds?: string[]
  entityRoles?: string[]
  countryCode?: string
  name?: string
  relatedTo?: RelatedTo
  label?: string[]
  accountNumber?: string
  cardNetwork?: string
  cardNetworks?: string[]
  routingNumber?: string
  availableToEntityIds?: string[]
  onFile?: boolean
  emailLike?: string
  accountNumberLike?: string
  countryLike?: string
  currencyLike?: string
  titleLike?: string
  entityClass?: string[]
  entitySubClass?: string[]
  setId?: string[]
}

const Filter = (props: FilterProps): React.JSX.Element => {
  const intl = useIntl()
  const { styles } = useFilterStyle()
  const close = () => props.closeFilter()
  const state: FilterState = props.filterState || {}
  const setState = props.setFilterState || null
  const beneficiaryFilterField = props?.beneficiaryFilterField

  const renderSearchBox = (option: FilterOption) => {
    return (
      <Search
        key={'search-users'}
        placeholder={option.placeholder || intl.formatMessage(filters['filter.search.placeholder'])}
        value={state?.search || ''}
        onChange={(event) => (setState ? setState(event.target.value, 'search') : null)}
        onSearch={(value) => {
          option.callback(value)
        }}
        style={{
          width: beneficiaryFilterField ? 320 : 200,
        }}
        allowClear={true}
        className="filterField"
      />
    )
  }

  const renderRangeDatePicker = (option: FilterOption) => {
    const from = state?.dateDue?.from || null
    const to = state?.dateDue?.to || null

    return (
      <BHRangePicker
        values={from && to ? [from, to] : undefined}
        onChange={(dateString) => option.callback(dateString)}
        placeholder={[
          intl.formatMessage(filters['filter.date.range.start.date.placeholder']),
          intl.formatMessage(filters['filter.date.range.end.date.placeholder']),
        ]}
      />
    )
  }

  const renderSelect = (option: FilterOption) => {
    return (
      <Select
        onChange={(value: string) => option.callback(value)}
        placeholder={option.placeholder}
        allowClear={true}
        className="bh-select full-width"
        popupClassName={styles.menuContentWrapper}
        listHeight={200}
        popupMatchSelectWidth={option.dropdownMatchSelectWidth ? option.dropdownMatchSelectWidth : false}
      >
        {option.dataSource &&
          option.dataSource.map((item: Data) => (
            <Option key={item.id} value={item.value ? item.value : item.name}>
              {item.name}
            </Option>
          ))}
      </Select>
    )
  }

  const renderCountrySelect = (option: FilterOption) => {
    return (
      <Select
        onChange={(value: string) => option.callback(value)}
        placeholder={option.placeholder}
        allowClear={true}
        className="bh-select full-width"
        popupMatchSelectWidth={option.dropdownMatchSelectWidth ? option.dropdownMatchSelectWidth : false}
      >
        {option.dataSource &&
          option.dataSource.map((item: Data) => (
            <Option key={item.id} value={item.value ? item.value : item.name}>
              {intl.formatDisplayName(item.name.toUpperCase(), {
                type: 'region',
              })}
            </Option>
          ))}
      </Select>
    )
  }

  const renderSelectWithSearch = (option: FilterOption) => {
    return (
      <Select
        showSearch
        filterOption={(input: string, opt: unknown): boolean => {
          const { children } = opt as { children: string }
          return children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }}
        onChange={(value: string) => option.callback(value)}
        notFoundContent={
          option.fetching ? <Spin size="small" /> : <div>{intl.formatMessage(filters['filter.search.noResults'])}</div>
        }
        placeholder={option.placeholder}
        allowClear={true}
        className="bh-select full-width"
        popupClassName={styles.menuContentWrapper}
        listHeight={200}
        popupMatchSelectWidth={option.dropdownMatchSelectWidth ? option.dropdownMatchSelectWidth : false}
      >
        {option.dataSource &&
          option.dataSource.map((item: Data) => (
            <Option key={item.id} value={item.value ? item.value : item.name}>
              {item.name}
            </Option>
          ))}
      </Select>
    )
  }

  const renderMultipleSelect = (option: FilterOption) => {
    return (
      <Select
        mode="multiple"
        onChange={(value: string[]) => option.callback(value)}
        style={{ width: 106, height: 32 }}
        allowClear={true}
        className="bh-select filterField"
      >
        {option.dataSource &&
          option.dataSource.map((item: Data) => (
            <Option key={item.id} value={item.name}>
              {item.name}
            </Option>
          ))}
      </Select>
    )
  }

  const renderButton = (options: FilterOption) => {
    const { elementType, label, badge } = options
    const filterButton = (
      <Button
        type="default"
        danger={elementType === 'danger'}
        onClick={(event) => options.callback(event as React.MouseEvent<HTMLElement, MouseEvent>)}
      >
        {label}
      </Button>
    )
    if (badge) {
      return (
        <Badge count={badge.count} style={badge.color ? { backgroundColor: badge.color } : {}}>
          {filterButton}
        </Badge>
      )
    } else {
      return filterButton
    }
  }

  const renderCloseButton = () => {
    return (
      <Button type="default" onClick={() => close()}>
        <CloseOutlined />
      </Button>
    )
  }

  const filterClassName = cx(styles.filterContainer, {
    [styles.open]: props.isOpen,
  })

  const renderOption = (option: FilterOption): React.ReactNode => {
    switch (option.type) {
      case 'button':
        return renderButton(option)
      case 'search':
        return renderSearchBox(option)
      case 'datePicker':
        return renderRangeDatePicker(option)
      case 'select':
        return renderSelect(option)
      case 'selectWithSearch':
        return renderSelectWithSearch(option)
      case 'multipleSelect':
        return renderMultipleSelect(option)
      case 'close':
        return renderCloseButton()
      case 'countrySelect':
        return renderCountrySelect(option)
    }
  }

  return (
    <div className={filterClassName} data-testid="filters-group">
      <div className={styles.filterHeader}>
        <div className={styles.label}>
          <h2>{props.label}</h2>
        </div>
        <div className={styles.closeBtn}>
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault()
              close()
            }}
          >
            <CloseOutlined />
          </button>
        </div>
      </div>
      {props.groups.map((group: FilterGroup, index: number) => {
        return (
          <div key={`fileter-group-${index}`} className={styles.filters}>
            {group.elements.map((option: FilterOption, index: number) => {
              return (
                <div key={`filter-${index}`} className={styles.filter}>
                  <span className={styles.filtersLabel}>{option.label}</span>
                  {renderOption(option)}
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

export default Filter
