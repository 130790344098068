import { State } from '../types'

const initialState: State = {
  entityHierarchies: [],
  entityHierarchiesTotal: 0,
  isFetchingEntityHierarchies: false,
  cardNetworks: [],
  paymentGateways: [],
  cardManagedByAdminRule: false,
  users: [],
  usersTotal: 0,
  isFetchingUsers: false,
  countryOptions: [],
  entities: [],
  sources: [],
  selectedEntity: undefined,
  selectedUser: undefined,
  entityHierarchiesPage: 1,
  usersFilterBody: {},
  profileGroups: [],
  isFetchingProfileGroups: false,
  profiles: [],
}

export default initialState
