import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import Modal from 'antd/lib/modal/Modal'
import Loader from '../../../../components/Loader/Loader'
import { ProgressData } from '../../../../types/paymentInstruction'
import { RedirectData } from '../../../../types/charge'
import { pi } from '../../../../lang/definitions'
import { backendLog } from '../../../../api/util'
import * as Sentry from '@sentry/react'
import { useTranslation } from 'utils/helpers'
import IFrame from './IFrame'
import Trigger from './Trigger'
import { useFeatureFlags } from 'hooks'

interface ThreeDSecureModalProps {
  isVisible: boolean
  redirectData?: RedirectData
  progressData?: ProgressData
  chargeId?: string
}

const message = 'User making a payment, 3dSecure modal triggered'

const ThreeDSecureModal = ({
  isVisible,
  redirectData,
  progressData,
  chargeId,
}: ThreeDSecureModalProps): React.JSX.Element => {
  const intl = useIntl()
  const [title, setTitle] = useState<string>('')
  const [iframeUrl, setIframeUrl] = useState<string>('')
  const [isMethodInvalid, setIsMethodInvalid] = useState<boolean>()
  const t = useTranslation()

  const { useDynamicPaymentThreshold } = useFeatureFlags()

  const sendLogToBackend = () => {
    backendLog(message, { chargeId, redirectData, progressData }).catch((err) => {
      Sentry.captureException(err)
    })
  }

  useEffect(() => {
    if (progressData) {
      setTitle(
        intl.formatMessage(pi['pi.add.reviewPayments.threeDSecure.header'], {
          ...progressData,
        })
      )
    }
  }, [progressData])

  useEffect(() => {
    sendLogToBackend()
    Sentry.addBreadcrumb({
      category: 'payments',
      message: message,
      level: 'info',
    })
    window.addEventListener('load', sendLogToBackend)

    return () => window.removeEventListener('load', sendLogToBackend)
  }, [])

  useEffect(() => {
    sendLogToBackend()
  }, [chargeId, redirectData, progressData])

  const handleGet = () => {
    if (!redirectData) {
      return
    }

    setIframeUrl(redirectData.url)
  }

  const handlePost = () => {
    if (!redirectData) {
      return
    }

    const params = redirectData.params.reduce((acc, { key, value }) => {
      return {
        ...acc,
        [key]: value,
      }
    }, {})

    const searchParams = new URLSearchParams(params)

    setIframeUrl(`${redirectData.url}?${searchParams.toString()}`)
  }

  useEffect(() => {
    if (!redirectData) {
      return
    }

    switch (redirectData.method.toLowerCase()) {
      case 'get':
        setIsMethodInvalid(false)
        handleGet()
        break
      case 'post':
        setIsMethodInvalid(false)
        handlePost()
        break
      default:
        setIsMethodInvalid(true)
    }
  }, [redirectData])

  const method = redirectData?.method.toLowerCase()

  return (
    <Modal
      data-testid="3d-secure-modal"
      open={isVisible && progressData !== undefined}
      keyboard={false}
      maskClosable={false}
      footer={null}
      closable={false}
      title={useDynamicPaymentThreshold ? '' : title}
      styles={{
        mask: { backgroundColor: 'rgba(0, 0, 0, 0.85)' },
      }}
      afterClose={sendLogToBackend}
    >
      <div
        style={{
          minHeight: 550,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Loader showLoader={!redirectData} />
        {method === 'post' &&
          (useDynamicPaymentThreshold ? (
            <iframe
              style={{
                border: 'none',
                height: '550px',
                width: '100%',
                background: 'transparent',
              }}
              title="validationFrame"
              src={iframeUrl}
            />
          ) : (
            <IFrame
              style={{
                border: 'none',
                height: '550px',
                width: '100%',
                background: 'transparent',
              }}
              title="validationFrame"
            >
              <Trigger redirectData={redirectData!} />
            </IFrame>
          ))}
        {method === 'get' && (
          <iframe
            style={{
              border: 'none',
              height: '550px',
              width: '100%',
              background: 'transparent',
            }}
            title="validationFrame"
            src={iframeUrl}
          />
        )}
        {isMethodInvalid && <span>{t('card.form.invalid.type.of.api.call')}</span>}
      </div>
    </Modal>
  )
}

export default ThreeDSecureModal
