import { defineMessages } from 'react-intl'

export const fileDownload = defineMessages({
  'file.download.banner.title': {
    id: 'file.download.banner.title',
    defaultMessage: 'Invoice download',
    description: 'File download banner title',
  },
  'file.download.banner.description': {
    id: 'file.download.banner.description',
    defaultMessage: 'Please log in to download or view the invoice.',
    description: 'File download banner description',
  },
  'file.download.page.success.title': {
    id: 'file.download.page.success.title',
    defaultMessage: 'User verified',
    description: 'File download page success title',
  },
  'file.download.page.success.subtitle': {
    id: 'file.download.page.success.subtitle',
    defaultMessage: 'Your download will begin shortly.',
    description: 'File download page success sub title',
  },
  'file.download.page.error.title': {
    id: 'file.download.page.error.title',
    defaultMessage: 'Something went wrong',
    description: 'File download page error title',
  },
  'file.download.page.error.subtitle': {
    id: 'file.download.page.error.subtitle',
    defaultMessage:
      'You cannot download the invoice at this time due to a technical error. Please try again later or contact your administrator.',
    description: 'File download page error sub title',
  },
  'file.download.page.button.close': {
    id: 'file.download.page.button.close',
    defaultMessage: 'Close',
    description: 'File download button title',
  },
  'file.download.page.button.proceed': {
    id: 'file.download.page.button.proceed',
    defaultMessage: 'Go to payments',
    description: 'File download button title',
  },
})
