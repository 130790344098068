import { defineMessages } from 'react-intl'

export const page = defineMessages({
  'page.reports.title': {
    id: 'page.reports.title',
    defaultMessage: 'Reports',
    description: 'Report page title',
  },
  'page.reports.tab.reports.label': {
    id: 'page.reports.tab.reports.label',
    defaultMessage: 'Reports',
    description: 'Report page reports tab label',
  },
  'page.reports.tab.templates.label': {
    id: 'page.reports.tab.templates.label',
    defaultMessage: 'Templates',
    description: 'Report page templates tab label',
  },
  'page.reports.tab.history.label': {
    id: 'page.reports.tab.history.label',
    defaultMessage: 'History',
    description: 'Report page history tab label',
  },
  'page.support.title': {
    id: 'page.support.title',
    defaultMessage: 'Support and FAQ',
    description: 'Support and FAQ page title',
  },
  'page.admin.billing.title': {
    id: 'page.admin.billing.title',
    defaultMessage: 'Billing',
    description: 'Billing page title',
  },
  'page.admin.insights.title': {
    id: 'page.admin.insights.title',
    defaultMessage: 'Insights',
    description: 'Insights page title',
  },
  'page.admin.paymentOverview.title': {
    id: 'page.admin.paymentOverview.title',
    defaultMessage: 'Payment overview',
    description: 'Payment overview page title',
  },
  'page.admin.org.title': {
    id: 'page.admin.org.title',
    defaultMessage: 'Organization hierarchy',
    description: 'Organization hierarchy page title',
  },
  'page.admin.settings.title': {
    id: 'page.admin.settings.title',
    defaultMessage: 'Settings',
    description: 'Admin settings page title',
  },
  'page.admin.users.title': {
    id: 'page.admin.users.title',
    defaultMessage: 'Users',
    description: 'Users page title',
  },
  'page.404.title': {
    id: 'page.404.title',
    defaultMessage: '404',
    description: '404 page title',
  },
  'page.404.subTitle': {
    id: 'page.404.subTitle',
    defaultMessage: 'Sorry, the page you visited does not exist.',
    description: '404 page sub title',
  },
  'page.404.link.back': {
    id: 'page.404.link.back',
    defaultMessage: 'Back home',
    description: '404 page back home link label',
  },
  'page.otp.link.back': {
    id: 'page.otp.link.back',
    defaultMessage: 'Take me back',
    description: 'Label for Otp page link to go back',
  },
  'page.otp.title': {
    id: 'page.otp.title',
    defaultMessage: 'Input your security code',
    description: 'Otp page title',
  },
  'page.otp.recipient.message': {
    id: 'page.otp.reciptient.message',
    defaultMessage: 'The code was sent by {method} to {endPoint}',
    description: 'Otp page message displaying how and where Otp was sent',
  },
  'page.otp.link.sendAnother': {
    id: 'page.otp.link.sendAnother',
    defaultMessage: 'Send me another one ',
    description: 'Otp link label to send another code',
  },
  'page.otp.link.call': {
    id: 'page.otp.link.call',
    defaultMessage: ' Call me instead',
    description: 'Otp link label to send code over call',
  },
  'page.otp.text.noCode': {
    id: 'page.otp.text.noCode',
    defaultMessage: ' Didnt get a code? ',
    description: 'Otp label to prompt a question in case no otp was recieved',
  },
  'page.login.form.email': {
    id: 'page.login.form.email',
    defaultMessage: 'E-mail address',
    description: 'Email address field label on login page',
  },
  'page.login.sso.form.email.invalid': {
    id: 'page.login.sso.form.email.invalid',
    defaultMessage: 'Please enter valid email address!',
    description: 'Invalid Email address on sso login page message',
  },
  'page.login.sso.form.email.user.not.found': {
    id: 'page.login.sso.form.email.user.not.found',
    defaultMessage: 'User not found',
    description: 'User not found on our database',
  },
  'page.login.form.password': {
    id: 'page.login.form.password',
    defaultMessage: 'Password',
    description: 'Password field label on login page',
  },
  'page.login.form.loginButton': {
    id: 'page.login.form.loginButton',
    defaultMessage: 'Login',
    description: 'Login button label on login page',
  },
  'page.login.link.forgot': {
    id: 'page.login.link.forgot',
    defaultMessage: 'Forgot your password or username?',
    description: 'Forgot password/username link label on login page',
  },
  'page.login.link.register': {
    id: 'page.login.link.register',
    defaultMessage: 'Need an account? Register now!',
    description: 'Register account link label on login page',
  },
  'page.login.link.login': {
    id: 'page.login.link.login',
    defaultMessage: 'Back to login',
    description: 'Text for link to get back to login form',
  },
  'page.login.tab.credentials': {
    id: 'page.login.tab.credentials',
    defaultMessage: 'Credentials',
    description: 'Tab name for login with credentials',
  },
  'page.login.tab.sso': {
    id: 'page.login.tab.sso',
    defaultMessage: 'Single Sign On',
    description: 'Tab name for login with SSO',
  },
  'page.login.tab.bankId': {
    id: 'page.login.tab.bankId',
    defaultMessage: 'BankID',
    description: 'Tab name for login with BankID',
  },
  'page.login.tab.bankid': {
    id: 'page.login.tab.bankid',
    defaultMessage: 'BankID',
    description: 'Tab name for login with BankID',
  },
  'page.login.form.bankId.remember': {
    id: 'page.login.form.bankId.remember',
    defaultMessage: 'Remember my personnummmer',
    description: 'Text label for checkbox to remember personnummer',
  },
  'page.login.form.personnummer': {
    id: 'page.login.form.personnummer',
    defaultMessage: 'Enter personnummer to log in with BankID',
    description: 'Text label for textbox to enter personnummer',
  },
  'page.login.form.personnummer.placeholder': {
    id: 'page.login.form.personnummer.placeholder',
    defaultMessage: 'YYMMDD-XXXX',
    description: 'Placeholder text for textbox to enter personnummer',
  },
  'page.login.form.backButton': {
    id: 'page.login.form.backButton',
    defaultMessage: 'Back to login',
    description: 'Back to login button label on login page',
  },
  'page.login.sso.unauthorisedError.message': {
    id: 'page.login.sso.unauthorisedError.message',
    defaultMessage: 'Unauthorised email',
    description: 'Title for the message for unprovisioned users',
  },
  'page.login.sso.tab.companyId': {
    id: 'page.login.sso.tab.companyId',
    defaultMessage: 'Log in with company ID',
    description: 'Tab for sso company id',
  },
  'page.login.sso.tab.companyEmail': {
    id: 'page.login.sso.tab.companyEmail',
    defaultMessage: 'Log in with company email',
    description: 'Tab for sso company email',
  },
  'page.login.sso.placeholder.companyId': {
    id: 'page.login.sso.placeholder.companyId',
    defaultMessage: 'Enter company ID',
    description: 'Placeholder for sso input field company id',
  },
  'page.login.sso.placeholder.companyEmail': {
    id: 'page.login.sso.placeholder.companyEmail',
    defaultMessage: 'Enter company Email',
    description: 'Placeholder for sso input field company email',
  },
  'page.login.sso.organizationLabel': {
    id: 'page.login.sso.organizationLabel',
    defaultMessage: 'Enter your company title or work email',
    description: 'Label for sso input field',
  },
  'page.login.sso.invalidCompanyIdError': {
    id: 'page.login.sso.invalidCompanyIdError',
    defaultMessage: 'The company ID you have entered does not match any account. Please double check and try again.',
    description: 'The error message displayed for an invalid company id',
  },
  'page.login.sso.invalidCompanyIdFormat': {
    id: 'page.login.sso.invalidCompanyIdFormat',
    defaultMessage: 'The company ID you have entered is invalid.',
    description: 'When Company ID is invalid',
  },
  'page.login.sso.invalidCompanyIdRequired': {
    id: 'page.login.sso.invalidCompanyIdRequired',
    defaultMessage: 'Please, insert your Company ID.',
    description: 'When Company ID is missing',
  },
  'page.login.sso.companyEmail.required': {
    id: 'page.login.sso.companyEmail.required',
    defaultMessage: 'Please, insert your company e-mail.',
    description: 'When company e-mail is missing',
  },
  'page.login.sso.continue': {
    id: 'page.login.sso.continue',
    defaultMessage: 'Continue',
    description: 'Text on button for SSO',
  },
  'page.login.sso.remember': {
    id: 'page.login.sso.remember',
    defaultMessage: 'Remember my input',
    description: 'Text for checkbox in sso input',
  },
  'page.login.sessionExpired': {
    id: 'page.login.sessionExpired',
    defaultMessage: 'You have been logged out due to inactivity',
    description: 'Text to show when session has expired and user have been logged out',
  },
  'page.dashboard.title': {
    id: 'page.dashboard.title',
    defaultMessage: 'Dashboard',
    description: 'Dashboard page title',
  },
  'page.beneficiaries.title': {
    id: 'page.beneficiaries.title',
    defaultMessage: 'Beneficiaries',
    description: 'Beneficiaries page title',
  },
  'page.pi.title': {
    id: 'page.pi.title',
    defaultMessage: 'Payments',
    description: 'Page name for PI list',
  },
  'page.pi.slider.label.add': {
    id: 'page.pi.slider.label.add',
    defaultMessage: 'Add payments',
    description: 'Right slider label for adding new payments',
  },
  'page.pi.slider.label.detail': {
    id: 'page.pi.slider.label.detail',
    defaultMessage: 'Payment details',
    description: 'Right slider label for details of a payment',
  },
  'page.pi.slider.label.selectCard': {
    id: 'page.pi.slider.label.selectCard',
    defaultMessage: 'Select Card',
    description: 'Right slider label for select card',
  },
  'page.pi.filter.drawer.title': {
    id: 'page.pi.filter.drawer.title',
    defaultMessage: 'Select filters',
    description: 'Title for filter drawer',
  },
  'page.addBeneficiary.recepientName.label': {
    id: 'page.addBeneficiary.recepientName.label',
    defaultMessage: 'Recepient name',
    description: 'Add Beneficiaries form label',
  },
  'page.addBeneficiary.recepientName.placeholder': {
    id: 'page.addBeneficiary.recepientName.placeholder',
    defaultMessage: 'Enter name',
    description: 'Add Beneficiaries form label',
  },
  'page.addBeneficiary.country.label': {
    id: 'page.addBeneficiary.country.label',
    defaultMessage: 'Select country',
    description: 'Add Beneficiaries form label',
  },
  'page.addBeneficiary.accountType.label': {
    id: 'page.addBeneficiary.accountType.label',
    defaultMessage: 'Account type',
    description: 'Add Beneficiaries form label',
  },
  'page.addBeneficiary.recipientType.label': {
    id: 'page.addBeneficiary.recipientType.label',
    defaultMessage: 'Select recipient type',
    description: 'Add Beneficiaries form label',
  },
  'page.addBeneficiary.recipientType.business.label': {
    id: 'page.addBeneficiary.recipientType.business.label',
    defaultMessage: 'Business',
    description: "Recipient label for 'business' account",
  },
  'page.addBeneficiary.recipientType.personal.label': {
    id: 'page.addBeneficiary.recipientType.personal.label',
    defaultMessage: 'Private',
    description: "Recipient label for 'private' account",
  },
  'page.addBeneficiary.recipientType.checking.label': {
    id: 'page.addBeneficiary.recipientType.checking.label',
    defaultMessage: 'Checking',
    description: "Recipient label for 'checking' account",
  },
  'page.addBeneficiary.recipientType.savings.label': {
    id: 'page.addBeneficiary.recipientType.savings.label',
    defaultMessage: 'Savings',
    description: "Recipient label for 'savings' account",
  },
  'page.addBeneficiary.orgNumber.placeholder': {
    id: 'page.addBeneficiary.orgNumber.placeholder',
    defaultMessage: 'Enter organization number',
    description: 'Add Beneficiaries form label',
  },
  'page.addBeneficiary.email.placeholder': {
    id: 'page.addBeneficiary.email.placeholder',
    defaultMessage: 'Enter email',
    description: 'Add Beneficiaries form label',
  },
  'page.addBeneficiary.phone.placeholder': {
    id: 'page.addBeneficiary.phone.placeholder',
    defaultMessage: 'Enter phone number',
    description: 'Add Beneficiaries form label',
  },
  'page.addBeneficiary.address.placeholder': {
    id: 'page.addBeneficiary.address.placeholder',
    defaultMessage: 'Enter street address',
    description: 'Add Beneficiaries form label',
  },
  'page.addBeneficiary.zipCode.placeholder': {
    id: 'page.addBeneficiary.zipCode.placeholder',
    defaultMessage: 'Enter zip code',
    description: 'Add Beneficiaries form label',
  },
  'page.addBeneficiary.city.placeholder': {
    id: 'page.addBeneficiary.city.placeholder',
    defaultMessage: 'Enter city',
    description: 'Add Beneficiaries form label',
  },
  'page.addBeneficiary.button.add': {
    id: 'page.addBeneficiary.button.add',
    defaultMessage: 'Add',
    description: 'Add Beneficiaries form label',
  },
  'page.addBeneficiary.button.edit': {
    id: 'page.addBeneficiary.button.edit',
    defaultMessage: 'Update',
    description: 'Add Beneficiaries form label',
  },
  'page.notifications.title': {
    id: 'page.notifications.title',
    defaultMessage: 'Notifications',
    description: 'Notifications page title',
  },
  'page.settings.acccount.section.personal.title': {
    id: 'page.settings.acccount.section.personal.title',
    defaultMessage: 'Personal details',
    description: 'Account settings page section title',
  },
  'page.settings.acccount.section.org.title': {
    id: 'page.settings.acccount.section.org.title',
    defaultMessage: 'Organization details',
    description: 'Account settings page section title',
  },
  'page.settings.acccount.section.account.title': {
    id: 'page.settings.acccount.section.account.title',
    defaultMessage: 'Account details',
    description: 'Account settings page section title',
  },
  'page.settings.acccount.section.edit.link.label': {
    id: 'page.settings.acccount.section.edit.link.label',
    defaultMessage: 'Change',
    description: 'Account settings edit link label',
  },
  'page.settings.password.new.placeholder': {
    id: 'page.settings.password.new.placeholder',
    defaultMessage: 'Enter new password',
    description: 'Change password slider form placeholder',
  },
  'page.settings.password.confirm.placeholder': {
    id: 'page.settings.password.confirm.placeholder',
    defaultMessage: 'Confirm new password',
    description: 'Change password slider form placeholder',
  },
  'page.settings.slider.password.current.label': {
    id: 'page.settings.slider.password.current.label',
    defaultMessage: 'Current password',
    description: 'Change password slider form label',
  },
  'page.settings.slider.password.new.label': {
    id: 'page.settings.slider.password.new.label',
    defaultMessage: 'New password',
    description: 'Change password slider form label',
  },
  'page.settings.slider.password.confirm.label': {
    id: 'page.settings.slider.password.confirm.label',
    defaultMessage: 'Confirm new password',
    description: 'Change password slider form label',
  },
  'page.settings.slider.password.current.placeholder': {
    id: 'page.settings.slider.password.current.placeholder',
    defaultMessage: 'Enter current password',
    description: 'Change password slider form placeholder',
  },
  'page.settings.slider.password.new.placeholder': {
    id: 'page.settings.slider.password.new.placeholder',
    defaultMessage: 'Enter new password',
    description: 'Change password slider form placeholder',
  },
  'page.settings.slider.password.confirm.placeholder': {
    id: 'page.settings.slider.password.confirm.placeholder',
    defaultMessage: 'Confirm new password',
    description: 'Change password slider form placeholder',
  },
  'page.settings.slider.password.update.button.label': {
    id: 'page.settings.slider.password.update.button.label',
    defaultMessage: 'Update',
    description: 'Change password slider update button label',
  },
  'page.settings.slider.email.new.label': {
    id: 'page.settings.slider.email.new.label',
    defaultMessage: 'New e-mail address',
    description: 'Change email slider form label',
  },
  'page.settings.slider.email.confirm.label': {
    id: 'page.settings.slider.email.confirm.label',
    defaultMessage: 'Confirm new e-mail adddress',
    description: 'Change email slider form label',
  },
  'page.settings.slider.email.new.placeholder': {
    id: 'page.settings.slider.email.new.placeholder',
    defaultMessage: 'Enter new e-mail address',
    description: 'Change email slider form placeholder',
  },
  'page.settings.slider.email.confirm.placeholder': {
    id: 'page.settings.slider.email.confirm.placeholder',
    defaultMessage: 'Confirm new e-mail address',
    description: 'Change email slider form placeholder',
  },
  'page.settings.slider.email.update.button.label': {
    id: 'page.settings.slider.email.update.button.label',
    defaultMessage: 'Update',
    description: 'Change email slider update button label',
  },
  'page.settings.slider.language.country.label': {
    id: 'page.settings.slider.language.country.label',
    defaultMessage: 'Select language',
    description: 'Change language slider form label',
  },
  'page.settings.slider.language.update.button.label': {
    id: 'page.settings.slider.language.update.button.label',
    defaultMessage: 'Update',
    description: 'Change language slider update button label',
  },
  'page.settings.slider.mobile.country.label': {
    id: 'page.settings.slider.mobile.country.label',
    defaultMessage: 'Select country',
    description: 'Change mobile slider form label',
  },
  'page.settings.slider.mobile.phone.label': {
    id: 'page.settings.slider.mobile.phone.label',
    defaultMessage: 'Phone number',
    description: 'Change mobile slider form label',
  },
  'page.settings.slider.mobile.phone.placeholder': {
    id: 'page.settings.slider.mobile.phone.placeholder',
    defaultMessage: 'Enter phone number',
    description: 'Change mobile slider form placeholder',
  },
  'page.settings.slider.mobile.update.button.label': {
    id: 'page.settings.slider.mobile.update.button.label',
    defaultMessage: 'Update',
    description: 'Change mobile slider update button label',
  },
  'page.settings.slider.2FA.enable.label': {
    id: 'page.settings.slider.2FA.enable.label',
    defaultMessage: 'Enable two factor authentication',
    description: 'Change 2FA slider form label',
  },
  'page.settings.slider.2FA.frequency.label': {
    id: 'page.settings.slider.2FA.frequency.label',
    defaultMessage: 'Frequency of trigger',
    description: 'Change 2FA slider form label',
  },
  'page.settings.slider.2FA.mode.label': {
    id: 'page.settings.slider.2FA.mode.label',
    defaultMessage: 'Choose mode of communication',
    description: 'Change 2FA slider form label',
  },
  'page.settings.slider.2FA.enable.on.label': {
    id: 'page.settings.slider.2FA.enable.on.label',
    defaultMessage: 'Yes',
    description: 'Change 2FA slider form label',
  },
  'page.settings.slider.2FA.enable.off.label': {
    id: 'page.settings.slider.2FA.enable.off.label',
    defaultMessage: 'No',
    description: 'Change 2FA slider form label',
  },
  'page.settings.slider.2FA.frequency.once.label': {
    id: 'page.settings.slider.2FA.frequency.once.label',
    defaultMessage: 'Once',
    description: 'Change 2FA slider form label',
  },
  'page.settings.slider.2FA.frequency.always.label': {
    id: 'page.settings.slider.2FA.frequency.always.label',
    defaultMessage: 'Always',
    description: 'Change 2FA slider form label',
  },
  'page.settings.slider.2FA.mode.sms.label': {
    id: 'page.settings.slider.2FA.mode.sms.label',
    defaultMessage: 'SMS',
    description: 'Change 2FA slider form label',
  },
  'page.settings.slider.2FA.mode.email.label': {
    id: 'page.settings.slider.2FA.mode.email.label',
    defaultMessage: 'E-Mail',
    description: 'Change 2FA slider form label',
  },
  'page.settings.slider.2FA.update.button.label': {
    id: 'page.settings.slider.2FA.update.button.label',
    defaultMessage: 'Update',
    description: 'Change 2FA slider update button label',
  },
  'page.settings.tab.account.label': {
    id: 'page.settings.tab.account.label',
    defaultMessage: 'Account',
    description: 'Settings page account tab label',
  },
  'page.settings.tab.messages.label': {
    id: 'page.settings.tab.messages.label',
    defaultMessage: 'Notification preferences',
    description: 'Settings page for notification preferences tab label',
  },
  'page.settings.tab.documents.label': {
    id: 'page.settings.tab.documents.label',
    defaultMessage: 'Documents',
    description: 'Settings page documents tab label',
  },
  'page.settings.tab.integrations.label': {
    id: 'page.settings.tab.integrations.label',
    defaultMessage: 'Integrations',
    description: 'Settings page integrations tab label',
  },
  'page.settings.slider.2FA.title': {
    id: 'page.settings.slider.2FA.title',
    defaultMessage: 'Update 2FA',
    description: 'Change 2FA slider title',
  },
  'page.settings.slider.email.title': {
    id: 'page.settings.slider.email.title',
    defaultMessage: 'Change e-mail address',
    description: 'Change email slider title',
  },
  'page.settings.slider.password.title': {
    id: 'page.settings.slider.password.title',
    defaultMessage: 'Change password',
    description: 'Change 2FA slider title',
  },
  'page.settings.slider.language.title': {
    id: 'page.settings.slider.langauge.title',
    defaultMessage: 'Change language',
    description: 'Change 2FA slider title',
  },
  'page.settings.slider.phone.title': {
    id: 'page.settings.slider.phone.title',
    defaultMessage: 'Update phone number',
    description: 'Change 2FA slider title',
  },
  'page.choose.account.load.more.text': {
    id: 'page.choose.account.load.more.text',
    defaultMessage: 'Load more',
    description: 'Text for load more',
  },
  'page.choose.account.text': {
    id: 'page.choose.account.text',
    defaultMessage: 'Choose Account',
    description: 'Text for choose account',
  },
  'page.cards.title': {
    id: 'page.cards.title',
    defaultMessage: 'Cards',
    description: 'Cards page title',
  },
  'page.mobile.limitation.warning.text': {
    id: 'page.mobile.limitation.warning.text',
    defaultMessage:
      'This functionality is only available on desktop version of the website, please login via a desktop browser to access it',
    description: 'Text for mobile limitation warning',
  },
  'page.login.divider.continue.with': {
    id: 'page.login.divider.continue.with',
    defaultMessage: 'or continue with',
    description: 'Text for divider',
  },
  'page.login.divider.change.default': {
    id: 'page.login.divider.change.default',
    defaultMessage: 'You can change default account in settings',
    description: 'Text for paragraph',
  },
  'page.add.payments.page.title': {
    id: 'page.add.payments.page.title',
    defaultMessage: 'Add payments',
    description: 'This is the title of Add payments page',
  },
  'page.allPages.user.offline.warning.message': {
    id: 'page.allPages.user.offline.warning.message',
    defaultMessage: 'A network error has occurred',
    description: 'This is the title of offline alert banner',
  },
  'page.allPages.user.offline.warning.description': {
    id: 'page.allPages.user.offline.warning.description',
    defaultMessage: 'Please make sure you are connected to the internet or try again later.',
    description: 'This is the description of offline alert banner',
  },
  'page.review.payments.page.title': {
    id: 'page.review.payments.page.title',
    defaultMessage: 'Review payments',
    description: 'This is the title of Review payments page',
  },
  'page.pay.payments.page.title': {
    id: 'page.pay.payments.page.title',
    defaultMessage: 'Make payments',
    description: 'This is the title of Make payments page',
  },
  'page.settings.tab.account.tfa.status.enabled': {
    id: 'page.settings.tab.account.tfa.status.enabled',
    defaultMessage: 'Enabled',
    description: 'Settings page account tab tfa enabled status',
  },
  'page.settings.tab.account.tfa.status.disabled': {
    id: 'page.settings.tab.account.tfa.status.disabled',
    defaultMessage: 'Disabled',
    description: 'Settings page account tab tfa disabled status',
  },
  'page.settings.tab.account.tfa.status.not.enabled': {
    id: 'page.settings.tab.account.tfa.status.not.enabled',
    defaultMessage: 'Not enabled',
    description: 'Settings page account tab tfa not enabled status',
  },
  'page.settings.tab.account.tfa.status.per.device': {
    id: 'page.settings.tab.account.tfa.status.per.device',
    defaultMessage: 'per device',
    description: 'Settings page account tab tfa per device label',
  },
  'page.settings.tab.account.tfa.what.sms': {
    id: 'page.settings.tab.account.tfa.what.sms',
    defaultMessage: 'sms',
    description: 'Settings page account tab tfa what sms label',
  },
  'page.settings.tab.account.tfa.what.email': {
    id: 'page.settings.tab.account.tfa.what.email',
    defaultMessage: 'e-mail',
    description: 'Settings page account tab tfa what email label',
  },
  'page.settings.tab.account.tfa.what.call': {
    id: 'page.settings.tab.account.tfa.what.call',
    defaultMessage: 'call',
    description: 'Settings page account tab tfa what call label',
  },
  'page.settings.tab.account.tfa.when.never': {
    id: 'page.settings.tab.account.tfa.when.never',
    defaultMessage: 'never',
    description: 'Settings page account tab tfa when never label',
  },
  'page.settings.tab.account.tfa.when.always': {
    id: 'page.settings.tab.account.tfa.when.always',
    defaultMessage: 'always',
    description: 'Settings page account tab tfa when always label',
  },
  'page.settings.tab.account.tfa.when.device': {
    id: 'page.settings.tab.account.tfa.when.device',
    defaultMessage: 'device',
    description: 'Settings page account tab tfa when device label',
  },
  'page.login.welcome.form.heading.one': {
    id: 'page.login.welcome.form.heading.one',
    defaultMessage: 'Welcome to Billhop',
    description: 'Login welcome form heading one',
  },
  'page.login.welcome.form.heading.two': {
    id: 'page.login.welcome.form.heading.two',
    defaultMessage: 'We need to know a little more about you on how you plan to use Billhop',
    description: 'Login welcome form heading two',
  },
  'page.login.welcome.form.paragraph.one': {
    id: 'page.login.welcome.form.paragraph.one',
    defaultMessage:
      'Since we are a regulated financial service, we need information from you on how you will use Billhop.',
    description: 'Login welcome form paragraph one',
  },
  'page.login.welcome.form.paragraph.two': {
    id: 'page.login.welcome.form.paragraph.two',
    defaultMessage:
      'Answer the questions below to your best knowledge, after you’ve responded you can continue to use Billhop.',
    description: 'Login welcome form paragraph two',
  },
  'page.login.welcome.form.question.howUse': {
    id: 'page.login.welcome.form.question.howUse',
    defaultMessage: 'In what role will you be using Billhop?',
    description: 'Login welcome form howUse question',
  },
  'page.login.welcome.form.howUse.option.one': {
    id: 'page.login.welcome.form.howUse.option.one',
    defaultMessage: 'Private individual',
    description: 'Login welcome form howUse question option one',
  },
  'page.login.welcome.form.howUse.option.two': {
    id: 'page.login.welcome.form.howUse.option.two',
    defaultMessage: 'Company',
    description: 'Login welcome form howUse question option two',
  },
  'page.login.welcome.form.howUse.option.three': {
    id: 'page.login.welcome.form.howUse.option.three',
    defaultMessage: 'Both',
    description: 'Login welcome form howUse question option three',
  },
  'page.login.welcome.form.question.typesOfPayments': {
    id: 'page.login.welcome.form.question.typesOfPayments',
    defaultMessage: 'What kind of invoices and bills will you be paying on Billhop?',
    description: 'Login welcome form typesOfPayments question',
  },
  'page.login.welcome.form.typesOfPayments.option.one': {
    id: 'page.login.welcome.form.typesOfPayments.option.one',
    defaultMessage: 'Unexpected expenditures',
    description: 'Login welcome form typesOfPayments question option one',
  },
  'page.login.welcome.form.typesOfPayments.option.two': {
    id: 'page.login.welcome.form.typesOfPayments.option.two',
    defaultMessage: 'Liquidity management',
    description: 'Login welcome form typesOfPayments question option two',
  },
  'page.login.welcome.form.typesOfPayments.option.three': {
    id: 'page.login.welcome.form.typesOfPayments.option.three',
    defaultMessage: 'Both',
    description: 'Login welcome form typesOfPayments question option three',
  },
  'page.login.welcome.form.question.amountPrediction': {
    id: 'page.login.welcome.form.question.amountPrediction',
    defaultMessage: 'How much do you intend to spend using Billhop',
    description: 'Login welcome form amountPrediction question',
  },
  'page.login.welcome.form.amountPrediction.option.one': {
    id: 'page.login.welcome.form.amountPrediction.option.one',
    defaultMessage: '0 - $1000 per year',
    description: 'Login welcome form amountPrediction question option one',
  },
  'page.login.welcome.form.amountPrediction.option.two': {
    id: 'page.login.welcome.form.amountPrediction.option.two',
    defaultMessage: '$1000 - $10 000 per year',
    description: 'Login welcome form amountPrediction question option two',
  },
  'page.login.welcome.form.amountPrediction.option.three': {
    id: 'page.login.welcome.form.amountPrediction.option.three',
    defaultMessage: 'More than $10 000 per year',
    description: 'Login welcome form amountPrediction question option three',
  },
  'page.login.welcome.form.question.pep': {
    id: 'page.login.welcome.form.question.pep',
    defaultMessage: 'I hold or have held a political or governmental position (PEP).',
    description: 'Login welcome form pep question',
  },
  'page.login.welcome.form.question.pep.readMore': {
    id: 'page.login.welcome.form.question.pep.readMore',
    defaultMessage: 'Read more about PEP',
    description: 'Login welcome form pep question read more link',
  },
  'page.login.welcome.form.pep.option.one': {
    id: 'page.login.welcome.form.pep.option.one',
    defaultMessage: 'No, I’m not a PEP',
    description: 'Login welcome form pep question option one',
  },
  'page.login.welcome.form.pep.option.two': {
    id: 'page.login.welcome.form.pep.option.two',
    defaultMessage: 'Yes, I’m a PEP',
    description: 'Login welcome form pep question option two',
  },
  'page.login.welcome.form.question.comment': {
    id: 'page.login.welcome.form.question.comment',
    defaultMessage: 'Other comments on why you will use Billhop',
    description: 'Login welcome form comment question',
  },
  'page.login.welcome.form.question.comment.text.placeHolder': {
    id: 'page.login.welcome.form.question.comment.text.placeHolder',
    defaultMessage: 'Optional field',
    description: 'Login welcome form comment question text area placeholder',
  },
  'page.login.welcome.form.button.label': {
    id: 'page.login.welcome.form.button.label',
    defaultMessage: 'Send and start using Billhop',
    description: 'Login welcome form button label',
  },
  'page.login.welcome.form.footer.text': {
    id: 'page.login.welcome.form.footer.text',
    defaultMessage: 'Your answers will be saved but never shared with a third party',
    description: 'Login welcome form footer text',
  },
  'page.login.welcome.form.validation.message': {
    id: 'page.login.welcome.form.validation.message',
    defaultMessage: 'Please enter your input',
    description: 'Error message when not entering mandatory informations in kyc form',
  },
  'page.welcome.title': {
    id: 'page.welcome.title',
    defaultMessage: 'Welcome to',
    description: 'Title for welcome modal',
  },
  'page.welcome.continue': {
    id: 'page.welcome.continue',
    defaultMessage: 'Continue',
    description: 'Title for welcome button',
  },
  'page.welcome.text': {
    id: 'page.welcome.text',
    defaultMessage: 'We have updated the look and feel of your Billhop account to simplify your user experience.',
    description: 'Content of welcome modal',
  },
  'page.welcome.new.platform': {
    id: 'page.welcome.new.platform',
    defaultMessage: 'Try out our new platform',
    description: 'Try out our new platform text',
  },
  'page.welcome.paragraph.1': {
    id: 'page.welcome.paragraph.1',
    defaultMessage:
      'Billhop is in the process of upgrading. As part of the launch, you have been selected to try out our new platform!',
    description: 'Paragraph 1 text',
  },
  'page.welcome.paragraph.2': {
    id: 'page.welcome.paragraph.2',
    defaultMessage:
      'We have performed extensive functionality tests, but there might be certain functions that work in another way than you would expect.',
    description: 'Paragraph 2 text',
  },
  'page.welcome.paragraph.3': {
    id: 'page.welcome.paragraph.3',
    defaultMessage:
      'For this reason, we have added a feedback page in Support and FAQs page, for you to share your experience.',
    description: 'Paragraph 3 text',
  },
  'page.welcome.paragraph.4': {
    id: 'page.welcome.paragraph.4',
    defaultMessage: 'We welcome all feedback, so we can create an end product that best suits your needs.',
    description: 'Paragraph 4 text',
  },
  'page.welcome.paragraph.6': {
    id: 'page.welcome.paragraph.6',
    defaultMessage: 'Thank you for helping us.',
    description: 'Paragraph 6 text',
  },
  'page.pooling.abort.btn': {
    id: 'page.page.pooling.abort.btn',
    defaultMessage: 'Abort',
    description: 'Button text',
  },
})
