import { api } from './utils'
const BASE_PATH = 'pdf'

export const downloadSinglePaymentInstructionPDF = async (
  paymentInstructionId: string,
  reportType: string,
  classType: string
): Promise<Blob> => {
  const url = `/${BASE_PATH}/single`
  const body = {
    report: reportType,
    paymentInstructionIds: [paymentInstructionId],
    classType,
  }

  const response = await api.post(url, body)
  return await response.blob()
}

export const downloadMultiplePaymentInstructionPDF = async (
  paymentInstructionIds: string[],
  reportType: string,
  classType: string
): Promise<Blob> => {
  const url = `/${BASE_PATH}/multiple`
  const body = {
    report: reportType,
    paymentInstructionIds: [...paymentInstructionIds],
    classType,
  }

  const response = await api.post(url, body)
  return await response.blob()
}
