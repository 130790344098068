const referenceFieldMaxLength = (
  maxLengthForClearingNetwork: number | undefined,
  prefixLength: number,
  suffixLength: number
) => {
  const referenceMaxLength = maxLengthForClearingNetwork ?? 0
  const referenceMaxLengthWithPrefixAndSuffix = referenceMaxLength
    ? referenceMaxLength - (prefixLength + suffixLength)
    : 0

  return referenceMaxLengthWithPrefixAndSuffix
}

export default referenceFieldMaxLength
