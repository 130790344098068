import { defineMessages } from 'react-intl'

export const organization = defineMessages({
  'organization.page.title': {
    id: 'organization.page.title',
    defaultMessage: 'Organization',
    description: 'Organization title on page and in sidebar',
  },
  'organization.action.newuser': {
    id: 'organization.action.newuser',
    defaultMessage: '+ New user',
    description: 'Label for the New user button in top action list',
  },
  'organization.action.newentity': {
    id: 'organization.action.newentity',
    defaultMessage: '+ New entity',
    description: 'Label for the New entity button in top action list',
  },
  'organization.action.newcard': {
    id: 'organization.action.newcard',
    defaultMessage: '+ New card',
    description: 'Label for the New card button in top action list',
  },
})
