/* eslint-disable no-empty */
import React, { useState, useEffect } from 'react'
import { Result, Button } from 'antd'
import { useParams } from 'react-router-dom'
import { validateToken } from 'api/auth'
import { getExternalRules, updateRulesExternal } from 'api/rules'
import { NotificationRules } from 'types/rules'
import { AuthPreferenceToken } from 'types/token'
import PreferenceList from '../../Settings/Messages/PreferenceList'
import ExternalContainer from 'components/ExternalContainer/ExternalContainer'
import { useIntl } from 'react-intl'
import { page } from 'lang/definitions/index'
import { getError } from 'utils/error'
import { catchExceptionWithSentry } from 'utils/Sentry'
import { notification } from 'antd'
import { useTranslation } from 'utils/helpers'

interface ParamTypes {
  preferenceToken: string
}

const Notifications: React.FC = () => {
  const t = useTranslation()
  const intl = useIntl()
  const [tokenStatus, setTokenStatus] = useState<AuthPreferenceToken | undefined>(undefined)
  const [externalRules, setExternalRules] = useState<NotificationRules | undefined>(undefined)
  const [error, setError] = useState<Error | undefined>(undefined)
  const { preferenceToken } = useParams<ParamTypes>()
  // fetch token

  const getTokenStatus = async (): Promise<void> => {
    try {
      const response = await validateToken(preferenceToken)
      setTokenStatus(response)
    } catch (error) {
      catchExceptionWithSentry(error as Error, {
        level: 'error',
        tags: { component: 'Notifications' },
      })
      notification.error({
        message: t('error.invalid.token'),
        placement: 'topRight',
      })
      setError(getError(error))
    }
  }

  const handleGetExternalRules = async (profileId: string, token: string) => {
    try {
      const response = await getExternalRules(profileId, token)

      const externalRules = {
        notificationPreferences: {
          ...response,
        },
      }
      setExternalRules(externalRules)
    } catch (error) {
      catchExceptionWithSentry(error as Error, {
        level: 'error',
        tags: { component: 'Notifications' },
      })
      setError(getError(error))
    }
  }

  useEffect(() => {
    void getTokenStatus()
  }, [])

  useEffect(() => {
    if (tokenStatus) {
      void handleGetExternalRules(tokenStatus.profileId, preferenceToken)
    }
  }, [tokenStatus])

  const updateRulesState = (section: string, title: string, key: string, checked: boolean) => {
    const updatedRules = externalRules
    if (updatedRules) {
      updatedRules.notificationPreferences.rule.profile[section][title][key] = checked
    }
    return setExternalRules(updatedRules)
  }

  const handleUpdateRules = async (rules: NotificationRules | undefined): Promise<void> => {
    try {
      const body = {
        ...rules,
      }
      if (tokenStatus?.profileId) await updateRulesExternal(body, preferenceToken, tokenStatus.profileId)
    } catch (error) {
      catchExceptionWithSentry(error as Error, {
        level: 'error',
        tags: { component: 'Notifications' },
      })
      notification.error({
        message: t('error.updateRules.default'),
        placement: 'topRight',
      })
    }
  }

  return (
    <ExternalContainer
      title={intl.formatMessage(page['page.notifications.title'])}
      subTitle={tokenStatus?.emailMask as string}
    >
      <div>
        {error ? (
          <Result
            status="404"
            title={intl.formatMessage(page['page.404.title'])}
            subTitle={intl.formatMessage(page['page.404.subTitle'])}
            extra={
              <Button type="primary" onClick={() => (window.location.href = '/')}>
                {intl.formatMessage(page['page.404.link.back'])}
              </Button>
            }
          />
        ) : (
          <PreferenceList rules={externalRules} updateState={updateRulesState} updateRules={handleUpdateRules} />
        )}
      </div>
    </ExternalContainer>
  )
}

export default Notifications
