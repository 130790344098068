import React, { useEffect, useState } from 'react'
import { Button, Divider, Form, Input, notification, Select } from 'antd'
import { CreditCardOutlined } from '@ant-design/icons'
import { Source } from 'types/source'
import * as rulesApi from 'api/rules'
import { editSource } from 'api/card'
import { Profile } from 'types/profile'
import { Entity } from 'types/entity'
import acl from 'utils/acl'
import { Rules } from 'types/rules'
import { ProfileGroup } from 'types/profileGroups'
import { getProfileGroups } from 'api/barracks'
import {
  CardFormData,
  buildAvailableEntityOptions,
  buildCurrencyOptions,
  buildProfileGroupOptions,
  formatCardId,
} from './utils'
import * as Sentry from '@sentry/react'
import { useTranslation } from 'utils/helpers'
import { SpaceForm } from 'pages/Beneficiaries/components'
import { useActiveUser } from 'hooks'
import { useSession } from 'stores/session'

interface CardDetailsProps {
  source: Source
  refresh: (sourceId: string) => void
}

const CardDetails = ({ source, refresh }: CardDetailsProps): React.JSX.Element => {
  const t = useTranslation()
  const activeProfile = useActiveUser().profile
  const {
    state: { rules, iam, ...sessionState },
  } = useSession()
  const user = sessionState.user!

  const [form] = Form.useForm()
  const [currencies, setCurrencies] = useState<string[]>([])
  const [sourceId, setSourceId] = useState<string>()
  const [isCorp, setIsCorp] = useState<boolean>()
  const [cardAdminEntities, setCardAdminEntities] = useState<Entity[]>([])
  const [profileGroups, setProfileGroups] = useState<ProfileGroup[]>([])

  const isEntityCardManagedByAdmin = !!rules?.toggles?.cardManagedByAdmin?.rule

  const canCreateCard = acl({
    iam: iam!,
    me: user,
    kind: 'source',
    barracksId: user?.activeProfileId,
    action: 'create',
  })

  const isCardAdmin = canCreateCard && isEntityCardManagedByAdmin

  const fetchProfilesFromEntityIds = (entityIds: string[]): Profile[] => {
    return entityIds.map((id: string) => {
      return user.profiles.find((profile) => profile.entityId === id)!
    })
  }

  const fetchProfileRules = async (profiles: Profile[]) => {
    return await Promise.all(profiles.map((profile: Profile) => rulesApi.getProfileRules(profile.id)))
  }

  //Fetch rules for every entityId available for current user
  const getEntitiesWithCardAdmin = async (): Promise<void> => {
    try {
      //Fetch all entityIds available for current user
      const profileEntityIds = user.profiles.map((profile: Profile) => {
        return profile.entityId
      })

      const profileEntityRules = await Promise.all(profileEntityIds.map((id: string) => rulesApi.getEntityRules(id)))

      const entitiesCardManagedByAdminIds: string[] = []
      //Check if entity has specific rule (cardManagedByAdmin and push them into a new array)
      profileEntityIds.forEach((entityId, index) => {
        const entityRule = profileEntityRules[index]
        if (entityRule.toggles?.cardManagedByAdmin?.rule) {
          entitiesCardManagedByAdminIds.push(entityId)
        }
      })
      //We're mapping through an array of entityIds so we can find their entity data to display (ex: name)
      const entityProfilesCardManagedByAdmin = fetchProfilesFromEntityIds(entitiesCardManagedByAdminIds)

      const profilesCardManagedByAdmin = await fetchProfileRules(entityProfilesCardManagedByAdmin)

      const profileRulesList: { rule: Rules; profile: Profile }[] = []

      entityProfilesCardManagedByAdmin.forEach((profile, index) => {
        const profileRule = profilesCardManagedByAdmin[index]
        if (profileRule.acl.source.profile.create.rule) {
          const data = {
            profile: profile,
            rule: profileRule,
          }
          profileRulesList.push(data)
        }
      })
      const profileEntities = profileRulesList.map((profile) => profile.profile.entity)

      setCardAdminEntities(profileEntities)
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const fetchProfileGroups = async (entityId: string): Promise<void> => {
    try {
      const profileGroups = await getProfileGroups(entityId)
      setProfileGroups(profileGroups)
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  useEffect(() => {
    void getAllCurrencies()
    if (source) {
      setSourceId(source.id)
      setIsCorp(!!activeProfile?.entity.class?.corp)
    }

    if (isCardAdmin) {
      void getEntitiesWithCardAdmin()
      void fetchProfileGroups(activeProfile.entityId)
    }
  }, [])

  const getAllCurrencies = async (): Promise<void> => {
    try {
      const currencies = await rulesApi.getCurrencies()
      setCurrencies(currencies)
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const onSubmit = async (data: CardFormData): Promise<void> => {
    const { title, preferredCurrency, availableToEntityIds, availableToProfileGroupIds } = data
    try {
      const body: Partial<Source> = {
        preferredCurrency,
        title,
      }
      if (availableToEntityIds) {
        body.availableToEntityIds = [availableToEntityIds]
      }
      if (availableToProfileGroupIds && availableToProfileGroupIds.length) {
        body.availableToProfileGroupIds = availableToProfileGroupIds
      }
      if (sourceId) {
        await editSource(body, sourceId)
      }
      if (sourceId) refresh(sourceId)
      notification.success({
        message: t('messages.success.source.edit'),
        placement: 'topRight',
      })
    } catch (error) {
      Sentry.captureException(error)
    }
  }
  const initialValues: Partial<CardFormData> = {
    title: source.title,
    preferredCurrency: source.preferredCurrency,
  }
  if (source.availableToEntityIds && source.availableToEntityIds.length > 0) {
    initialValues.availableToEntityIds = source.availableToEntityIds[0]
  }
  if (source.availableToProfileGroupIds && source.availableToProfileGroupIds.length > 0) {
    initialValues.availableToProfileGroupIds = source.availableToProfileGroupIds
  }
  return (
    <div className="bh-form">
      <Form
        form={form}
        layout="vertical"
        name="source-details"
        requiredMark={false}
        autoComplete="off"
        onFinish={(data: CardFormData) => void onSubmit(data)}
        initialValues={initialValues}
      >
        <SpaceForm>
          <Form.Item
            name="title"
            label={t('card.form.editCardName.label')}
            hasFeedback
            rules={[
              {
                required: true,
                message: t('card.form.cardName.error.required'),
              },
            ]}
          >
            <Input
              size="large"
              name="cardName"
              placeholder={t('card.form.name.placeholder')}
              disabled={isEntityCardManagedByAdmin && !isCardAdmin}
            />
          </Form.Item>

          {source.typeProperties.bin && source.typeProperties.last4 && (
            <Form.Item label={t('card.number')} hasFeedback>
              <Input
                prefix={<CreditCardOutlined />}
                size="large"
                value={`${source.typeProperties.bin} •••• ${source.typeProperties.last4}`}
                disabled
              />
            </Form.Item>
          )}

          {isCorp && (
            <Form.Item
              name="preferredCurrency"
              label={t('card.preferredCurrency')}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: t('card.form.preferredCurrency.error.required'),
                },
              ]}
            >
              <Select
                style={{ width: '100%' }}
                size="large"
                placeholder={t('card.preferredCurrency.placeholder')}
                disabled={isEntityCardManagedByAdmin && !isCardAdmin}
                options={buildCurrencyOptions(currencies)}
              />
            </Form.Item>
          )}

          {source.id && (
            <Form.Item label={t('card.form.cardId.label')} hasFeedback>
              <Input size="large" disabled value={formatCardId(source.id)} />
            </Form.Item>
          )}

          {isCardAdmin && (
            <Form.Item
              name="availableToEntityIds"
              label={t('card.availableEntities')}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: t('card.form.available.entities.error.required'),
                },
              ]}
            >
              <Select
                allowClear
                size="large"
                style={{ width: '100%' }}
                placeholder={t('card.availableEntities.placeholder')}
                options={buildAvailableEntityOptions(cardAdminEntities)}
              />
            </Form.Item>
          )}
          {isCardAdmin && profileGroups.length > 0 && (
            <Form.Item label={t('card.availableProfileGroups')} name="availableToProfileGroupIds" hasFeedback>
              <Select
                allowClear
                size="large"
                mode="multiple"
                style={{ width: '100%' }}
                placeholder={t('card.availableProfileGroups.placeholder')}
                options={buildProfileGroupOptions(profileGroups)}
              />
            </Form.Item>
          )}
          <Divider />
        </SpaceForm>

        <Button
          size="large"
          type="primary"
          htmlType="submit"
          disabled={isEntityCardManagedByAdmin && !isCardAdmin}
          block
        >
          {t('card.form.updateButton.label')}
        </Button>
      </Form>
    </div>
  )
}

export default CardDetails
