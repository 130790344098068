import { Form, Input, FormInstance } from 'antd'
import React, { useEffect } from 'react'
import { CloseCircleFilled } from '@ant-design/icons'
import { Address } from '../../../types/beneficiary'
import { RecipientTitle, RoutingNumber, AccountNumber, TypeSelector } from './FormItems'
import { SpaceForm } from '../components'
import { useTranslation } from '../../../utils/helpers'
import {
  useLocalUKAccountNumberValidator,
  useLocalUkRoutingNumberValidator,
  UkRoutingNumberMaxLength,
  UkAccountNumberMaxLength,
} from 'utils/validators'

type Props = {
  getFormInstance?: (form: FormInstance) => void
  onFinish?: (values: LocalGBForm) => void
}

export type LocalGBForm = {
  title: string
  routingNumber: string
  accountNumber: string
  type: string
  address: Partial<Address>
  identifier: {
    type: string
    value: string
  }
}

const initialValues: LocalGBForm = {
  title: '',
  routingNumber: '',
  accountNumber: '',
  type: '',
  address: {},
  identifier: {
    type: 'cin',
    value: '',
  },
}

const LocalGB = ({ getFormInstance, onFinish }: Props): React.JSX.Element => {
  const t = useTranslation()
  const [form] = Form.useForm<LocalGBForm>()
  const type = Form.useWatch('type', form)
  const routingNumber = Form.useWatch('routingNumber', form)

  useEffect(() => {
    getFormInstance?.(form)
  }, [form])

  const accountNumberValidator = useLocalUKAccountNumberValidator(routingNumber)

  const routingNumberValidator = useLocalUkRoutingNumberValidator()

  return (
    <Form
      form={form}
      scrollToFirstError
      layout="vertical"
      requiredMark={false}
      initialValues={initialValues}
      onFinish={onFinish}
      data-testid="LocalGB-form"
      autoComplete="off"
    >
      <SpaceForm>
        <RecipientTitle />
        <RoutingNumber
          label={t('beneficiary.ukSortCode')}
          validator={routingNumberValidator}
          maxLength={UkRoutingNumberMaxLength}
        />

        <AccountNumber
          label={t('beneficiary.accountNumber')}
          validator={accountNumberValidator}
          maxLength={UkAccountNumberMaxLength}
        />

        <TypeSelector type="beneficiary" />

        {type === 'business' && (
          <Form.Item hasFeedback label={t('beneficiary.orgNumber')} name={['identifier', 'value']}>
            <Input
              size="large"
              type="text"
              allowClear={{
                clearIcon: (
                  <CloseCircleFilled
                    onClick={() => {
                      form.resetFields(['identifier'])
                      void form.validateFields(['identifier', 'value'])
                    }}
                  />
                ),
              }}
              placeholder={t('beneficiary.orgNumber.placeholder')}
            />
          </Form.Item>
        )}
      </SpaceForm>
      <button type="submit" style={{ display: 'none' }} />
    </Form>
  )
}

export default LocalGB
