import { Source } from 'types/source'
import { Action, Actions, State } from './types'

type Dispatch = (action: Action) => void

const getActions = (dispatch: Dispatch): Actions => {
  return {
    dispatchCurrentAmountToStore: (amount: State['selectedAmount']) => {
      dispatch({
        type: 'SET_CURRENT_AMOUNT',
        payload: amount,
      })
    },
    dispatchCurrentBeneficiaryToStore: (beneficiary: State['selectedBeneficiary']) => {
      dispatch({
        type: 'SET_CURRENT_BENEFICIARY',
        payload: beneficiary,
      })
    },
    dispatchCurrentCardToStore: (card: Source | undefined) => {
      dispatch({ type: 'SET_CURRENT_CARD', payload: card })
    },
    dispatchCurrentCurrency: (currency: State['selectedCurrency']) => {
      dispatch({
        type: 'SET_CURRENT_CURRENCY',
        payload: currency,
      })
    },
    dispatchResetCurrentPaymentStore: () => {
      dispatch({
        type: 'RESET_CURRENT_PAYMENT_STATE',
      })
    },
    setIsAllPaymentsSelectedButtonActive: (isActive: State['isAllPaymentsSelectedButtonActive']) => {
      dispatch({
        type: 'SET_IS_ALL_SELECTED_BUTTON_ACTIVE',
        payload: isActive,
      })
    },
    setCurrentPaymentsTab: (paymentTab: State['currentTab']) => {
      dispatch({ type: 'SET_CURRENT_PAYMENTS_TAB', payload: paymentTab })
    },
    setSelectedPayments: (payments: State['selectedPayments']) => {
      dispatch({ type: 'SET_SELECTED_PAYMENTS', payload: payments })
    },
    setPayments: (payments: State['payments']) => {
      dispatch({ type: 'SET_PAYMENTS', payload: payments })
    },
    setFilteredPayments: (payments: State['filteredPayments']) => {
      dispatch({ type: 'SET_FILTERED_PAYMENTS', payload: payments })
    },
    setPaymentsOnCurrentPage: (payments: State['paymentsOnCurrentPage']) => {
      dispatch({ type: 'SET_PAYMENTS_ON_CURRENT_PAGE', payload: payments })
    },
    setPaymentsFilter: (paymentsFilter: State['paymentsFilter']) => {
      dispatch({ type: 'SET_PAYMENTS_FILTER', payload: paymentsFilter })
    },
    setPaymentInstructions: (paymentInstructions: State['paymentInstructions']) => {
      dispatch({ type: 'SET_PAYMENT_INSTRUCTIONS', payload: paymentInstructions })
    },
    setPaymentInstructionTemplates: (paymentInstructionTemplates: State['paymentInstructionTemplates']) => {
      dispatch({ type: 'SET_PAYMENT_INSTRUCTION_TEMPLATES', payload: paymentInstructionTemplates })
    },
    setPaymentInstruction: (paymentInstruction: State['paymentInstruction']) => {
      dispatch({ type: 'SET_PAYMENT_INSTRUCTION', payload: paymentInstruction })
    },
    setPaymentInstructionTemplate: (paymentInstructionTemplate: State['paymentInstructionTemplate']) => {
      dispatch({ type: 'SET_PAYMENT_INSTRUCTION_TEMPLATE', payload: paymentInstructionTemplate })
    },
    setSelectedApprovers: (approvers: State['selectedApprovers']) => {
      dispatch({ type: 'SET_SELECTED_APPROVERS', payload: approvers })
    },
    setCurrentPaymentsPage: (page: State['currentPaymentsPage']) => {
      dispatch({ type: 'SET_CURRENT_PAYMENTS_PAGE', payload: page })
    },
    setCards: (cards: State['cards']) => {
      dispatch({ type: 'SET_CARDS', payload: cards })
    },
    setPaymentCards: (paymentCards: State['paymentCards']) => {
      dispatch({ type: 'SET_PAYMENT_CARDS', payload: paymentCards })
    },
    setBeneficiaries: (beneficiaries: State['beneficiaries']) => {
      dispatch({ type: 'SET_BENEFICIARIES', payload: beneficiaries })
    },
    setCurrencies: (currencies: State['currencies']) => {
      dispatch({ type: 'SET_CURRENCIES', payload: currencies })
    },
    setNumberOfPaymentsSentToApprovers: (numberOfPaymentsSentToApprovers: State['numberOfPaymentsSentToApprovers']) => {
      dispatch({ type: 'SET_NUMBER_OF_PAYMENTS_SENT_TO_APPROVERS', payload: numberOfPaymentsSentToApprovers })
    },
    setNumberOfSignaturesRequired: (numberOfSignaturesRequired: State['numberOfSignaturesRequired']) => {
      dispatch({ type: 'SET_NUMBER_OF_SIGNATURES_REQUIRED', payload: numberOfSignaturesRequired })
    },
    setDisableReviewPage: (disableReviewPage: State['disableReviewPage']) => {
      dispatch({ type: 'SET_DISABLE_REVIEW_PAGE', payload: disableReviewPage })
    },
    setShowInitLoader: (showInitLoader: State['showInitLoader']) => {
      dispatch({ type: 'SET_SHOW_INIT_LOADER', payload: showInitLoader })
    },
    setShowReviewPaymentLoader: (showReviewPaymentLoader: State['showReviewPaymentLoader']) => {
      dispatch({ type: 'SET_SHOW_REVIEW_PAYMENT_LOADER', payload: showReviewPaymentLoader })
    },
    setSignatureOrder: (signatureOrder: State['signatureOrder']) => {
      dispatch({ type: 'SET_SIGNATURE_ORDER', payload: signatureOrder })
    },
    setEntityRules: (entityRules: State['entityRules']) => {
      dispatch({ type: 'SET_ENTITY_RULES', payload: entityRules })
    },
    setCheckedConfirmation: (isConfirmation: boolean) => {
      dispatch({ type: 'SET_CHECKED_CONFIRMATION', payload: isConfirmation })
    },
    setNeedConfirmation: (needConfirmation: boolean) => {
      dispatch({ type: 'SET_NEED_CONFIRMATION', payload: needConfirmation })
    },
    setSubmitError: (submitError: boolean) => {
      dispatch({ type: 'SET_SUBMIT_ERROR', payload: submitError })
    },
    setNumberOfApprovedPayments: (numberOfApprovedPayments: number) => {
      dispatch({ type: 'SET_NUMBER_OF_APPROVED_PAYMENTS', payload: numberOfApprovedPayments })
    },
    setPaymentsInSet: (payments: State['paymentsInSet']) => {
      dispatch({ type: 'SET_PAYMENTS_IN_SET', payload: payments })
    },
    setCurrentPaymentsInSetPage: (payments: State['currentPaymentsInSetPage']) => {
      dispatch({ type: 'SET_CURRENT_PAYMENTS_IN_SET_PAGE', payload: payments })
    },
    setPaymentsInSetOnCurrentPage: (payments: State['paymentsInSetOnCurrentPage']) => {
      dispatch({ type: 'SET_PAYMENTS_IN_SET_ON_CURRENT_PAGE', payload: payments })
    },
    resetState: () => {
      dispatch({ type: 'RESET_STATE' })
    },
    setApproveSet: (approveSet: State['approveSet']) => {
      dispatch({ type: 'SET_APPROVE_SET', payload: approveSet })
    },
    setApproveAndPaySet: (approveAndPaySet: State['approveAndPaySet']) => {
      dispatch({ type: 'SET_APPROVE_AND_PAY_SET', payload: approveAndPaySet })
    },
    setApproversToShow: (approversToShow: State['approversToShow']) => {
      dispatch({ type: 'SET_APPROVERS_TO_SHOW', payload: approversToShow })
    },
    dispatchReadySet: (readySet: State['readySet']) => {
      dispatch({ type: 'SET_READY_SET', payload: readySet })
    },
    dispatchRequireSignatureSet: (requireSignatureSet: State['requireSignatureSet']) => {
      dispatch({ type: 'SET_REQUIRED_SIGNATURE_SET', payload: requireSignatureSet })
    },
    dispatchApprovers: (approvers: State['approvers']) => {
      dispatch({ type: 'SET_APPROVERS', payload: approvers })
    },
    dispatchApprovalRounds: (approvalRounds: State['approvalRounds']) => {
      dispatch({ type: 'SET_APPROVAL_ROUNDS', payload: approvalRounds })
    },
    setSlideNumber: (slideNumber: State['slideNumber']) => {
      dispatch({ type: 'SET_SLIDE_NUMBER', payload: slideNumber })
    },
    setIsSelectingPayments: (isSelectingPayments: State['isSelectingPayments']) => {
      dispatch({ type: 'SET_IS_SELECTING_PAYMENTS', payload: isSelectingPayments })
    },
  }
}

export default getActions
