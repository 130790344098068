import React, { useEffect, useRef } from 'react'
import { RedirectData, RedirectDataParam } from 'types/charge'

type Props = {
  redirectData: RedirectData
}

export default function Trigger({ redirectData }: Props) {
  const formRef = useRef<HTMLFormElement>(null)

  useEffect(() => {
    if (!redirectData) {
      return
    }

    formRef.current?.submit()
  }, [redirectData])

  if (!redirectData) {
    return <></>
  }

  return (
    <form ref={formRef} action={redirectData.url} method={redirectData.method}>
      {redirectData.params.map((p: RedirectDataParam) => (
        <input key={p.key} name={p.key} id={p.key} type="hidden" value={p.value} />
      ))}
    </form>
  )
}
