import React from 'react'

import { RequiredACL } from '../../types/rules'
import acl from '../../utils/acl'
import { useSession } from 'stores/session'

interface ACLProps {
  requiredACL?: RequiredACL[]
  requiredRoles?: string[]
  kind?: string
  action?: string
  barracksId?: string
  children?: React.JSX.Element
}
/**
 * This component is used to hide/show content based on ACLs, ie. that the current user has access to or not.
 * Example, wrap a button in this component and it will be shown only if the correct ACL's are met.
 * <ACL kind="paymentInstruction" barracksId="entity or profileId" action="create/read/update/delete"> <Button> </ACL>
 * Props that goes in are either
 * kind, action, barracksId
 * requiredRoles, barracksId
 * requiredACL, barracksId
 *
 * barracksId - this is the id (profile or entity) of the object we want to check against.
 *              Example 1 - to check if a user has the right to delete a PI use the PI's profileId as barracksId
 *              Example 2  - to check if a user has the right to cerate PIs, use the current entityId as bararcksId
 * kind - the kind object to test for , eg. paymentInstruction, paymentInstructionTemplate, source, charge, signatureRequest etc
 * action - the action to test against (CRUD verbs)
 * requiredRoles - an array of roles if we want ot check specificlt for an admin or owner
 * requiredACL - array of objects with {kind, action} in order to test for multiple acls at the same time
 *
 * In the background this component (<ACL>) uses the ocntext for "me" and "iam" to get which user is logged in and whan acls that user has (the IAM-data).
 * This data along with the props are sent to a function called "acl()" wich does the actial check against the acls etc.
 * Since the acl() is a separate function, this can be used in code that is not in a render function, ie, in if-staements etc.
 * If the acl function is used, than the me and iam context ahs to be provided as arguments.
 *
 * For examples in the wild - see for componetn use in paymentinstructionDetails.tsx and for the acl() function see Sidebar.tsx or ActionMenu.tsx
 */
const ACL = ({ kind, action, requiredRoles, barracksId, children, requiredACL }: ACLProps): React.JSX.Element => {
  const { state: sessionState } = useSession()
  const iam = sessionState.iam!
  const me = sessionState.user!

  let tests
  if (requiredACL) {
    tests = requiredACL.map((a) => acl({ iam, me, kind: a.kind, action: a.action, barracksId }))
  } else if (kind && action) {
    tests = [acl({ iam, me, kind, action, barracksId })]
  } else if (requiredRoles) {
    tests = [acl({ iam, me, requiredRoles })]
  }
  if (!tests) {
    return <></>
  }
  const pass = tests.some((v) => v === true)
  if (pass) {
    return <>{children}</>
  } else {
    return <></>
  }
}
export default ACL
