import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css }) => ({
  uppercase: css`
    text-transform: uppercase;
    text-align: center;
    background: #ffffff;
  `,
  currency: css`
    max-width: 71px; // or any other width you prefer

    .ant-input {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  `,
}))

export default useStyles
