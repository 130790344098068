import React from 'react'
import { CreateUser, Name } from '../../../../types/user'
import Date from '../../../../components/Format/Date/Date'
import { Entity } from '../../../../types/entity'
import { Checkbox } from 'antd'
import { IntlShape } from 'react-intl'
import { admin } from '../../../../lang/definitions/admin'
import { ACL, Rules, SignChainRule } from '../../../../types/rules'

export interface EntityRolesRow {
  id: string
  title: string
  entityRoles: string[]
  children?: EntityRolesRow[]
  disabled: boolean
  rules?: Rules
  profileAcl?: ACL
}

export const renderRoleLabel = (role: string, intl: IntlShape): React.JSX.Element => {
  let text
  switch (role) {
    case 'owner':
      text = (
        <div>
          <span>{intl.formatMessage(admin['admin.user.hierarchyColumns.label.owner'])}</span>
          <br />
          <span>{intl.formatMessage(admin['admin.user.hierarchyColumns.owner'])}</span>
        </div>
      )
      break
    case 'admin':
      text = (
        <div>
          <span>{intl.formatMessage(admin['admin.user.hierarchyColumns.label.admin'])}</span>
          <br />
          <span>{intl.formatMessage(admin['admin.user.hierarchyColumns.admin'])}</span>
        </div>
      )
      break
    case 'approver':
      text = (
        <div>
          <span>{intl.formatMessage(admin['admin.user.hierarchyColumns.approver.label'])}</span>
          <br />
          <span>{intl.formatMessage(admin['admin.user.hierarchyColumns.approver'])}</span>
        </div>
      )
      break
    case 'payer':
      text = (
        <div>
          <span>{intl.formatMessage(admin['admin.user.hierarchyColumns.label.payer'])}</span>
          <br />
          <span>{intl.formatMessage(admin['admin.user.hierarchyColumns.payer'])}</span>
        </div>
      )
      break
    case 'adder':
      text = (
        <div>
          <span>{intl.formatMessage(admin['admin.user.hierarchyColumns.label.adder'])}</span>
          <br />
          <span>{intl.formatMessage(admin['admin.user.hierarchyColumns.adder'])}</span>
        </div>
      )
      break
    case 'viewer':
      text = (
        <div>
          <span>{intl.formatMessage(admin['admin.user.hierarchyColumns.label.viewer'])}</span>
          <br />
          <span>{intl.formatMessage(admin['admin.user.hierarchyColumns.viewer'])}</span>
        </div>
      )
      break
    case 'member':
      text = (
        <div>
          <span>{intl.formatMessage(admin['admin.user.hierarchyColumns.label.member'])}</span>
          <br />
          <span>{intl.formatMessage(admin['admin.user.hierarchyColumns.member'])}</span>
        </div>
      )
      break

    default:
      text = <span></span>
      break
  }

  return text
}

export const userColumns = (language: string, intl: IntlShape) => [
  {
    title: intl.formatMessage(admin['admin.user.columns.name']),
    dataIndex: '',
    key: 'user',
    render: (data: { name: Name }) => {
      return (
        <span>
          {data.name.first} {data.name.last}
        </span>
      )
    },
  },
  {
    title: intl.formatMessage(admin['admin.user.columns.email']),
    dataIndex: '',
    key: 'email',
    render: (data: { email: string }) => {
      return <span>{data.email}</span>
    },
  },
  {
    title: intl.formatMessage(admin['admin.user.columns.lastLoggedIn']),
    dataIndex: '',
    key: 'dateLastLogin',
    render: (data: { dateLastLogin: string }) => {
      return <div>{data.dateLastLogin && <Date value={data.dateLastLogin} locale={language} time={true} />}</div>
    },
  },
]

export const addEntityRolesIncludingChildren = (
  entityRoles: string[],
  children: EntityRolesRow[],
  role: string,
  setEntities: React.Dispatch<React.SetStateAction<EntityRolesRow[]>>,
  entities: EntityRolesRow[]
): void => {
  // prevent duplicates
  if (!entityRoles.includes(role)) {
    entityRoles.push(role)
  }

  if (children && children.length) {
    children.map((entity) => {
      addEntityRolesIncludingChildren(
        entity.entityRoles ? entity.entityRoles : [],
        entity.children ? entity.children : [],
        role,
        setEntities,
        entities
      )
    })
  }

  setEntities([...entities])
}
export const removeEntityRolesIncludingChildren = (
  entityRoles: string[],
  children: EntityRolesRow[],
  role: string,
  setEntities: React.Dispatch<React.SetStateAction<EntityRolesRow[]>>,
  entities: EntityRolesRow[]
): void => {
  if (entityRoles.includes(role)) {
    entityRoles.splice(entityRoles.indexOf(role), 1)
  }

  if (children && children.length) {
    children.map((entity) => {
      removeEntityRolesIncludingChildren(
        entity.entityRoles ? entity.entityRoles : [],
        entity.children ? entity.children : [],
        role,
        setEntities,
        entities
      )
    })
  }

  setEntities([...entities])
}

export const defaultEntity: Entity = {
  id: '',
  parentEntityId: '',
  countryCode: '',
  dateCreated: '',
  dateUpdated: '',
  slug: '',
  state: '',
  class: {},
}

export const defaultNewUser: CreateUser = {
  countryCode: '',
  entityRoles: [],
  email: '',
  name: {
    first: '',
    last: '',
  },
}

const isApproveEnabled = (rules: Rules, disabled: boolean): boolean => {
  if (!disabled) {
    const { logic: { requiredSignChain: { rule: requiredSignChainRules = [] } = {} } = {} } = rules
    const shouldApprove = requiredSignChainRules.some((rule: SignChainRule) => rule.requiredSignatures > 0)
    return shouldApprove
  }
  return false
}

export const editUserColumns = (
  entities: EntityRolesRow[],
  setEntities: React.Dispatch<React.SetStateAction<EntityRolesRow[]>>,
  intl: IntlShape
) =>
  [
    {
      title: intl.formatMessage(admin['admin.user.hierarchyColumns.entities']),
      dataIndex: 'title',
      key: 'title',
      width: 300,
    },
    {
      title: intl.formatMessage(admin['admin.user.hierarchyColumns.admin']),
      dataIndex: '',
      align: 'center',
      key: 'admin',
      render: (data: { entityRoles: string[]; children: EntityRolesRow[]; disabled: boolean; id: string }) => {
        return (
          <Checkbox
            onChange={() => {
              if (data.entityRoles.includes('admin')) {
                removeEntityRolesIncludingChildren(data.entityRoles, data.children, 'admin', setEntities, entities)
              } else {
                addEntityRolesIncludingChildren(data.entityRoles, data.children, 'admin', setEntities, entities)
              }
            }}
            value={data.entityRoles?.includes('admin')}
            checked={data.entityRoles?.includes('admin')}
            disabled={data.disabled}
          ></Checkbox>
        )
      },
    },
    {
      title: intl.formatMessage(admin['admin.user.hierarchyColumns.approver']),
      dataIndex: '',
      align: 'center',
      key: 'approver',
      render: (data: {
        entityRoles: string[]
        children: EntityRolesRow[]
        disabled: boolean
        id: string
        rules: Rules
      }) => {
        return (
          <Checkbox
            onChange={() => {
              if (data.entityRoles.includes('approver')) {
                removeEntityRolesIncludingChildren(data.entityRoles, data.children, 'approver', setEntities, entities)
              } else {
                addEntityRolesIncludingChildren(data.entityRoles, data.children, 'approver', setEntities, entities)
              }
            }}
            value={data.entityRoles.includes('approver')}
            checked={data.entityRoles.includes('approver')}
            disabled={!isApproveEnabled(data.rules, data.disabled)}
          ></Checkbox>
        )
      },
    },
    {
      title: intl.formatMessage(admin['admin.user.hierarchyColumns.payer']),
      dataIndex: '',
      align: 'center',
      key: 'payer',
      render: (data: { entityRoles: string[]; children: EntityRolesRow[]; disabled: boolean; id: string }) => {
        return (
          <Checkbox
            onChange={() => {
              if (data.entityRoles.includes('payer')) {
                removeEntityRolesIncludingChildren(data.entityRoles, data.children, 'payer', setEntities, entities)
              } else {
                addEntityRolesIncludingChildren(data.entityRoles, data.children, 'payer', setEntities, entities)
              }
            }}
            value={data.entityRoles.includes('payer')}
            checked={data.entityRoles.includes('payer')}
            disabled={data.disabled}
          ></Checkbox>
        )
      },
    },
    {
      title: intl.formatMessage(admin['admin.user.hierarchyColumns.adder']),
      dataIndex: '',
      align: 'center',
      key: 'adder',
      render: (data: { entityRoles: string[]; children: EntityRolesRow[]; disabled: boolean; id: string }) => {
        return (
          <Checkbox
            onChange={() => {
              if (data.entityRoles.includes('adder')) {
                removeEntityRolesIncludingChildren(data.entityRoles, data.children, 'adder', setEntities, entities)
              } else {
                addEntityRolesIncludingChildren(data.entityRoles, data.children, 'adder', setEntities, entities)
              }
            }}
            value={data.entityRoles.includes('adder')}
            checked={data.entityRoles.includes('adder')}
            disabled={data.disabled}
          ></Checkbox>
        )
      },
    },
    {
      title: intl.formatMessage(admin['admin.user.hierarchyColumns.viewer']),
      dataIndex: '',
      align: 'center',
      key: 'viewer',
      render: (data: { entityRoles: string[]; children: EntityRolesRow[]; disabled: boolean; id: string }) => {
        return (
          <Checkbox
            onChange={() => {
              if (data.entityRoles.includes('viewer')) {
                removeEntityRolesIncludingChildren(data.entityRoles, data.children, 'viewer', setEntities, entities)
              } else {
                addEntityRolesIncludingChildren(data.entityRoles, data.children, 'viewer', setEntities, entities)
              }
            }}
            value={data.entityRoles.includes('viewer')}
            checked={data.entityRoles.includes('viewer')}
            disabled={data.disabled}
          ></Checkbox>
        )
      },
    },
    {
      title: 'Can administer cards?',
      dataIndex: '',
      align: 'center',
      key: 'cardAdmin',
      render: (data: { entityRoles: string[]; children: EntityRolesRow[]; disabled: boolean; id: string }) => {
        return (
          <Checkbox
            onChange={() => {
              if (data.entityRoles.includes('cardAdmin')) {
                removeEntityRolesIncludingChildren(data.entityRoles, data.children, 'cardAdmin', setEntities, entities)
              } else {
                addEntityRolesIncludingChildren(data.entityRoles, data.children, 'cardAdmin', setEntities, entities)
              }
            }}
            value={data.entityRoles.includes('cardAdmin')}
            checked={data.entityRoles.includes('cardAdmin')}
            disabled={data.disabled}
          ></Checkbox>
        )
      },
    },
  ] as const
