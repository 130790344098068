import { defineMessages } from 'react-intl'

export const signatures = defineMessages({
  'signatures.form.createdBy': {
    id: 'signatures.form.createdBy',
    defaultMessage: 'Created by',
    description: 'This string sets created by label on signatures forms',
  },
  'signatures.form.requireSignatureBy': {
    id: 'signatures.form.requireSignatureBy',
    defaultMessage: 'Require signature by',
    description: 'This string sets requireSignatureBy label on signatures forms',
  },
  'signatures.form.amount': {
    id: 'signatures.form.amount',
    defaultMessage: 'Amount',
    description: 'This string sets amount label on signatures forms',
  },
  'signatures.form.currency': {
    id: 'signatures.form.currency',
    defaultMessage: 'Currency',
    description: 'This string sets currency label on signatures forms',
  },
  'signatures.form.type': {
    id: 'signatures.form.type',
    defaultMessage: 'Type',
    description: 'This string sets type label on signatures forms',
  },
  'signatures.form.entity': {
    id: 'signatures.form.entity',
    defaultMessage: 'Entity',
    description: 'This string sets entity label on signatures forms',
  },
  'signatures.form.count': {
    id: 'signatures.form.count',
    defaultMessage: 'Total payments count',
    description: 'This string sets count label on signatures forms',
  },
  'signatures.form.payments': {
    id: 'signatures.form.payments',
    defaultMessage: 'Payments requesting signature',
    description: 'This string sets entity label on signatures forms',
  },
})
