import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css }) => ({
  inputStyle: css`
    width: 32px !important;
    height: 32px;
    margin: 0 10px;
    font-size: 18px;
    border-radius: 2px;
    border: 1px solid #d9d9d9;
  `,
  otpErrorContainer: css`
    .ant-alert {
      margin-bottom: 20px;
    }
  `,
  otpFooter: css`
    margin-top: 70px;
    text-align: center;
    color: #949393;
  `,
  otpHeading: css`
    text-align: center;
    color: #949393;
    font-size: 14px;
    margin: 15px 0px;
  `,
  buttonNewCode: css`
    background: none;
  `,
}))

export default useStyles
