import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css, token }) => ({
  filtersWrapper: css`
    display: flex;
    align-items: center;
    column-gap: ${token.marginMD}px;
    width: fit-content;
  `,
  tableWrapper: css`
    margin-top: ${token.marginXL}px;
    margin-bottom: ${token.marginXL}px;
  `,
}))

export default useStyles
