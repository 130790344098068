import { defineMessages } from 'react-intl'

export const notification = defineMessages({
  'notification.switch.yes': {
    id: 'notification.switch.yes',
    defaultMessage: 'Yes',
    description: 'Yes',
  },
  'notification.switch.no': {
    id: 'notification.switch.no',
    defaultMessage: 'No',
    description: 'No',
  },
  'notification.general': {
    id: 'notification.general',
    defaultMessage: 'General',
    description: 'The title for the general section',
  },
  'notification.general.description': {
    id: 'notification.general.description',
    defaultMessage: 'General notifications',
    description: 'The description for the general section',
  },
  'notification.news': {
    id: 'notification.news',
    defaultMessage: 'News',
    description: 'The title for news',
  },
  'notification.news.description': {
    id: 'notification.news.description',
    defaultMessage: 'News about Billhop',
    description: 'The description for news',
  },
  'notification.dateBreak': {
    id: 'notification.dateBreak',
    defaultMessage: 'Date breaks',
    description: 'The title for Date breaks',
  },
  'notification.dateBreak.description': {
    id: 'notification.dateBreak.description',
    defaultMessage: 'Information on monthly date breaks',
    description: 'The description for date breaks',
  },
  'notification.payments': {
    id: 'notification.payments',
    defaultMessage: 'Payments',
    description: 'The title for the payments section',
  },
  'notification.payments.description': {
    id: 'notification.payments.description',
    defaultMessage: 'Notifications about payments',
    description: 'The description for the payments section',
  },
  'notification.added': {
    id: 'notification.added',
    defaultMessage: 'Added payment',
    description: 'The title decribing when a payment is added',
  },
  'notification.added.description': {
    id: 'notification.added.description',
    defaultMessage: 'When a payment has been added',
    description: 'The description for when a payment is added',
  },
  'notification.pending': {
    id: 'notification.pending',
    defaultMessage: 'Paid by me',
    description: 'The title decribing when a payment is for Pending',
  },
  'notification.pending.description': {
    id: 'notification.pending.description',
    defaultMessage: 'When a payment has been paid by myself (card payment)',
    description: 'The description for when a payment is pending',
  },
  'notification.paid': {
    id: 'notification.paid',
    defaultMessage: 'Paid',
    description: 'The title decribing when a payment is paid',
  },
  'notification.paid.description': {
    id: 'notification.paid.description',
    defaultMessage: 'When a payment is completed',
    description: 'The description for when a payment is completed',
  },
  'notification.paymentSignatures': {
    id: 'notification.paymentSignatures',
    defaultMessage: 'Signatures',
    description: 'The title for the signatures section',
  },
  'notification.paymentSignatures.description': {
    id: 'notification.paymentSignatures.description',
    defaultMessage: 'Notifications regarding signatures',
    description: 'The description for the signatures section',
  },
  'notification.signatureRequest': {
    id: 'notification.signatureRequest',
    defaultMessage: 'Signature request',
    description: 'The title for signature request',
  },
  'notification.signatureRequest.description': {
    id: 'notification.signatureRequest.description',
    defaultMessage: 'When you receive a signature request',
    description: 'The description for when a signature request is received',
  },
  'notification.signature': {
    id: 'notification.signature',
    defaultMessage: 'Signature',
    description: 'The title for signature',
  },
  'notification.signature.description': {
    id: 'notification.signature.description',
    defaultMessage: 'When someone signs your signature request',
    description: 'The description for when a signature request is signed',
  },
  'notification.organization': {
    id: 'notification.organization',
    defaultMessage: 'Organization',
    description: 'The title for organization',
  },
  'notification.organization.description': {
    id: 'notification.organization.description',
    defaultMessage: 'Notifications regarding your organization',
    description: 'The description for the organization section',
  },
  'notification.user.added': {
    id: 'notification.user.added',
    defaultMessage: 'User added',
    description: 'The title for user added to organization',
  },
  'notification.user.added.description': {
    id: 'notification.user.added.description',
    defaultMessage: 'When a user in your organization is added',
    description: 'The description for user added to organization',
  },
  'notification.roleUpdated': {
    id: 'notification.roleUpdated',
    defaultMessage: 'User updated',
    description: 'The title for user updated to organization',
  },
  'notification.roleUpdated.description': {
    id: 'notification.roleUpdated.description',
    defaultMessage: 'When a user in your organization has been updated',
    description: 'The description for user updated in organization',
  },
  'notification.deleted': {
    id: 'notification.deleted',
    defaultMessage: 'User deleted',
    description: 'The title for user deleted to organization',
  },
  'notification.deleted.description': {
    id: 'notification.deleted.description',
    defaultMessage: 'When a user in your organization is deleted',
    description: 'The description for user deleted in organization',
  },
})
