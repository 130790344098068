import { defineMessages } from 'react-intl'

export const admin = defineMessages({
  'admin.user.title': {
    id: 'admin.user.title',
    defaultMessage: 'User',
    description: 'This string sets manage users page title',
  },
  'admin.user.add': {
    id: 'admin.user.add',
    defaultMessage: 'Add User',
    description: 'This string sets add user action label',
  },
  'admin.user.userDetails': {
    id: 'admin.user.userDetails',
    defaultMessage: 'User details',
    description: 'This string sets user details title on edit user drawer',
  },
  'admin.user.noItems': {
    id: 'admin.user.noItems',
    defaultMessage: 'No items',
    description: 'This string sets label in the table when there is no records',
  },
  'admin.user.columns.name': {
    id: 'admin.user.columns.name',
    defaultMessage: 'Name',
    description: 'This string sets name label in the user table',
  },
  'admin.user.columns.email': {
    id: 'admin.user.columns.email',
    defaultMessage: 'Email',
    description: 'This string sets email label in the user table',
  },
  'admin.user.columns.entity': {
    id: 'admin.user.columns.entity',
    defaultMessage: 'Entity',
    description: 'This string sets entity label in the user table',
  },
  'admin.user.columns.roles': {
    id: 'admin.user.columns.roles',
    defaultMessage: 'Roles',
    description: 'This string sets roles label in the user table',
  },
  'admin.user.columns.lastLoggedIn': {
    id: 'admin.user.columns.lastLoggedIn',
    defaultMessage: 'Last logged in',
    description: 'This string sets lastLoggedIn label in the user table',
  },
  'admin.user.columns.dateCreated': {
    id: 'admin.user.columns.dateCreated',
    defaultMessage: 'Date created',
    description: 'This string sets dateCreated label in the user table',
  },
  'admin.user.columns.actions': {
    id: 'admin.user.columns.actions',
    defaultMessage: 'Actions',
    description: 'This string sets actions label in the user table',
  },
  'admin.user.hierarchyColumns.entities': {
    id: 'admin.user.hierarchyColumns.entities',
    defaultMessage: 'Entities',
    description: 'This string set entities column label in the entity hierarchy table',
  },
  'admin.user.hierarchyColumns.owner': {
    id: 'admin.user.hierarchyColumns.owner',
    defaultMessage: 'Is owner?',
    description: 'This string set owner column label in the entity hierarchy table',
  },
  'admin.user.hierarchyColumns.label.owner': {
    id: 'admin.user.hierarchyColumns.label.owner',
    defaultMessage: 'Owner',
    description: 'This string set owner column label in the entity hierarchy table',
  },
  'admin.user.hierarchyColumns.admin': {
    id: 'admin.user.hierarchyColumns.admin',
    defaultMessage: 'Can administrate business settings?',
    description: 'This string set admin column label in the entity hierarchy table',
  },
  'admin.user.hierarchyColumns.label.admin': {
    id: 'admin.user.hierarchyColumns.label.admin',
    defaultMessage: 'Admin',
    description: 'This string set admin column label in the entity hierarchy table',
  },
  'admin.user.hierarchyColumns.approver': {
    id: 'admin.user.hierarchyColumns.approver',
    defaultMessage: 'Can approve payments?',
    description: 'This string set approver column label in the entity hierarchy table',
  },
  'admin.user.hierarchyColumns.approver.label': {
    id: 'admin.user.hierarchyColumns.approver.label',
    defaultMessage: 'Approver',
    description: 'This string set approver column label in the entity hierarchy table',
  },
  'admin.user.hierarchyColumns.payer': {
    id: 'admin.user.hierarchyColumns.payer',
    defaultMessage: 'Can make payments?',
    description: 'This string set payer column label in the entity hierarchy table',
  },
  'admin.user.hierarchyColumns.label.payer': {
    id: 'admin.user.hierarchyColumns.label.payer',
    defaultMessage: 'Payer',
    description: 'This string set payer column label in the entity hierarchy table',
  },
  'admin.user.hierarchyColumns.adder': {
    id: 'admin.user.hierarchyColumns.adder',
    defaultMessage: 'Can add payments?',
    description: 'This string set adder column label in the entity hierarchy table',
  },
  'admin.user.hierarchyColumns.label.adder': {
    id: 'admin.user.hierarchyColumns.label.adder',
    defaultMessage: 'Adder',
    description: 'This string set adder column label in the entity hierarchy table',
  },
  'admin.user.hierarchyColumns.viewer': {
    id: 'admin.user.hierarchyColumns.viewer',
    defaultMessage: 'Can view payments?',
    description: 'This string set viewer column label in the entity hierarchy table',
  },
  'admin.user.hierarchyColumns.label.viewer': {
    id: 'admin.user.hierarchyColumns.label.viewer',
    defaultMessage: 'Viewer',
    description: 'This string set viewer column label in the entity hierarchy table',
  },
  'admin.user.hierarchyColumns.member': {
    id: 'admin.user.hierarchyColumns.member',
    defaultMessage: 'Is member?',
    description: 'This string set member column label in the entity hierarchy table',
  },
  'admin.user.hierarchyColumns.label.member': {
    id: 'admin.user.hierarchyColumns.label.member',
    defaultMessage: 'Member',
    description: 'This string set member column label in the entity hierarchy table',
  },
  'admin.user.columns.user': {
    id: 'admin.user.columns.user',
    defaultMessage: 'User',
    description: 'This string sets user label in the user table',
  },
  'admin.modal.delete.text': {
    id: 'admin.modal.delete.text',
    defaultMessage: 'Delete',
    description: 'The delete button label in popup Modal',
  },
  'admin.modal.cancel.text': {
    id: 'admin.modal.cancel.text',
    defaultMessage: 'Cancel',
    description: 'The cancel button label in popup Modal',
  },
  'admin.user.columns.numberOfPayments': {
    id: 'admin.user.columns.numberOfPayments',
    defaultMessage: 'Number of payments',
    description: 'This string sets number of payments label in the entity table',
  },
  'admin.user.columns.numberOfUsers': {
    id: 'admin.user.columns.numberOfUsers',
    defaultMessage: 'Number of users',
    description: 'This string sets number of users label in the entity table',
  },
  'admin.user.entityRoles.update.noPermission': {
    id: 'admin.user.entityRoles.update.noPermission',
    defaultMessage: 'User do not have permission to update entity roles',
    description: 'This string sets no permission label for updating entity roles',
  },
  'admin.user.entityRoles.childEntities': {
    id: 'admin.user.entityRoles.childEntities',
    defaultMessage: 'Child entities',
    description: 'This string sets child entities label on entity roles',
  },
})
