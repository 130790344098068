import { defineMessages } from 'react-intl'

export const menu = defineMessages({
  'menu.left.reports': {
    id: 'menu.left.reports',
    defaultMessage: 'Reports',
    description: 'This string sets left menu label',
  },
  'menu.left.addreports': {
    id: 'menu.left.addreports',
    defaultMessage: 'Generate report',
    description: 'This string sets left menu label',
  },
  'menu.left.reports.dashboard': {
    id: 'menu.left.reports.dashboard',
    defaultMessage: 'Dashboard',
    description: 'This string sets left menu label',
  },
  'menu.left.payments': {
    id: 'menu.left.payments',
    defaultMessage: 'Payments',
    description: 'This string sets left menu label',
  },
  'menu.left.addpayments': {
    id: 'menu.left.addpayments',
    defaultMessage: 'Add payment',
    description: 'This string sets left menu label',
  },
  'menu.left.reviewpayments': {
    id: 'menu.left.reviewpayments',
    defaultMessage: 'Review payments',
    description: 'This string sets left menu label',
  },
  'menu.left.makepayments': {
    id: 'menu.left.makepayments',
    defaultMessage: 'Make payments',
    description: 'This string sets left menu label',
  },
  'menu.left.beneficiaries': {
    id: 'menu.left.beneficiaries',
    defaultMessage: 'Beneficiaries',
    description: 'This string sets left menu label',
  },
  'menu.left.sources': {
    id: 'menu.left.sources',
    defaultMessage: 'Cards',
    description: 'This string sets left menu label for sources (cards)',
  },
  'menu.left.settings': {
    id: 'menu.left.settings',
    defaultMessage: 'Settings',
    description: 'This string sets left menu label',
  },
  'menu.left.adminConsole': {
    id: 'menu.left.adminConsole',
    defaultMessage: 'Admin console',
    description: 'This string sets left menu label',
  },
  'menu.left.support': {
    id: 'menu.left.support',
    defaultMessage: 'Support & FAQ',
    description: 'This string sets left menu label',
  },
  'menu.right.profile': {
    id: 'menu.right.profile',
    defaultMessage: 'Profile',
    description: 'This string sets right menu label',
  },
  'menu.right.settings': {
    id: 'menu.right.settings',
    defaultMessage: 'Settings',
    description: 'This string sets right menu label',
  },
  'menu.right.notifications': {
    id: 'menu.right.notifications',
    defaultMessage: 'Notifications',
    description: 'This string sets right menu label',
  },
  'menu.right.logout': {
    id: 'menu.right.logout',
    defaultMessage: 'Sign out',
    description: 'This string sets right menu label',
  },
  'menu.admin.insights': {
    id: 'menu.admin.insights',
    defaultMessage: 'Insights',
    description: 'This string sets left admin menu label',
  },
  'menu.admin.users': {
    id: 'menu.admin.users',
    defaultMessage: 'Users',
    description: 'This string sets left admin menu label',
  },
  'menu.admin.paymentOverview': {
    id: 'menu.admin.paymentOverview',
    defaultMessage: 'Payment overview',
    description: 'This string sets left admin menu label',
  },
  'menu.admin.activity': {
    id: 'menu.admin.activity',
    defaultMessage: 'Activity',
    description: 'This string sets left admin menu label',
  },
  'menu.admin.orgHierarchy': {
    id: 'menu.admin.orgHierarchy',
    defaultMessage: 'Organization Hierarchy',
    description: 'This string sets left admin menu label',
  },
  'menu.admin.billing': {
    id: 'menu.admin.billing',
    defaultMessage: 'Billing',
    description: 'This string sets left admin menu label',
  },
  'menu.admin.settings': {
    id: 'menu.admin.settings',
    defaultMessage: 'Settings',
    description: 'This string sets left admin menu label',
  },
  'menu.admin.backHome': {
    id: 'menu.admin.backHome',
    defaultMessage: 'Back to homepage',
    description: 'This string sets left admin menu label',
  },
  'menu.admin.back': {
    id: 'menu.admin.back',
    defaultMessage: 'Back',
    description: 'This string sets left admin menu label',
  },
})
