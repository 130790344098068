import React, { useEffect } from 'react'
import { motorolaSSOLogin } from '../../api/external'
import * as Sentry from '@sentry/react'

const MotorolaIntermediateLoginPage = (): React.JSX.Element => {
  useEffect(() => {
    const getUrl = async () => {
      try {
        const { redirectUrl } = await motorolaSSOLogin()
        window.location.href = redirectUrl
      } catch (error) {
        Sentry.captureException(error)
      }
    }
    try {
      void getUrl()
    } catch (err) {
      Sentry.captureException(err)
    }
  }, [])

  return (
    <div className="sso-intermediate">
      <div>Loading 🤺</div>
    </div>
  )
}

export default MotorolaIntermediateLoginPage
