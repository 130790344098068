import React, { useEffect, useState } from 'react'
import { Form, Input, Button, Checkbox, Divider, Tabs, Alert, Spin } from 'antd'
import * as Sentry from '@sentry/react'
import { ExclamationCircleFilled, LoadingOutlined } from '@ant-design/icons'
import { loginSSO, getNonProvisionedUserMessage } from 'api/external'
import Loader from 'components/Loader/Loader'
import {
  removeAuthenticationStatus,
  removeIsProfileChosen,
  removeRememberSSO,
  removeSessionId,
  setRememberSSO,
  setPreferredLoginMethod,
  setSsoPendingProvider,
  removeSsoPendingProvider,
  getRememberSSO,
  getSsoPendingProvider,
} from 'utils/storage'
import { useSsoState } from 'stores/Sso/SsoStore'
import { SET_PROVIDER_ID, SET_SSO_STATUS } from 'stores/Sso/SsoActionTypes'
import { useTranslation } from 'utils/helpers'
import { useDebouncePromise } from 'hooks'
import { notifyError } from 'utils/error'
import { emailPattern } from 'utils/patterns'
import useLoginSSOStyles from './LoginSSO.style'

type LoginFormValues = {
  ssoCompanyId: string
  ssoCompanyEmail: string
  rememberSSO: boolean
}
interface MessageError {
  details: {
    errorMessage?: string
  }
  message: string
  status: number
}

type ActiveKeyTab = 'tab-company-id' | 'tab-company-email'

const LoginSSO = (): React.JSX.Element => {
  const { styles } = useLoginSSOStyles()
  const [SsoState, dispatchSso] = useSsoState()
  const ssoStatus = SsoState.ssoStatus
  const ssoProvider = SsoState.providerId
  const setSsoStatus = (status: string) => dispatchSso({ type: SET_SSO_STATUS, value: status })
  const setSsoProviderId = (provider: string) => dispatchSso({ type: SET_PROVIDER_ID, value: provider })
  const [form] = Form.useForm<LoginFormValues>()
  const [loading, setLoading] = useState<boolean>(false)
  const [nonProvMessage, setNonProvMessage] = useState<string>('Loading 🤺')
  const [activeKey, setActiveKey] = useState<ActiveKeyTab>('tab-company-id')
  const t = useTranslation()

  useEffect(() => {
    const rememberSSO = getRememberSSO()
    const providerOrEmail = getSsoPendingProvider()

    form.setFieldsValue({
      rememberSSO,
    })

    if (providerOrEmail) {
      const isEmail = emailPattern.test(providerOrEmail)

      isEmail ? setActiveKey('tab-company-email') : setActiveKey('tab-company-id')

      if (rememberSSO) {
        const fieldKey = isEmail ? 'ssoCompanyEmail' : 'ssoCompanyId'

        form.setFieldsValue({
          [fieldKey]: providerOrEmail,
        })
      }
    }
  }, [])

  useEffect(() => {
    if (!ssoProvider) {
      return
    }

    const getMessage = async (provider: string) => {
      const result = await getNonProvisionedUserMessage(provider)

      const { nonProvisionUserMessage } = result
      setNonProvMessage(nonProvisionUserMessage)
    }
    try {
      void getMessage(ssoProvider)
    } catch (err) {
      Sentry.captureException(err)
    } finally {
      removeSsoPendingProvider()
    }
  }, [ssoProvider])

  const rememberSSO = () => {
    const checked = form.getFieldValue('rememberSSO') ? true : false

    if (checked) {
      setRememberSSO(checked)
    } else {
      removeRememberSSO()
      removeAuthenticationStatus()
      removeSessionId()
      removeIsProfileChosen()
    }
  }

  const handleFormSubmit = async ({ ssoCompanyEmail, ssoCompanyId }: LoginFormValues) => {
    const ssoValue = activeKey === 'tab-company-id' ? ssoCompanyId : ssoCompanyEmail

    try {
      setLoading(true)
      rememberSSO()

      const { redirectUrl } = await loginSSO(ssoValue)
      setSsoPendingProvider(ssoValue)
      setPreferredLoginMethod('sso')

      window.location.href = redirectUrl // redirect to SSO partner page
    } catch (err) {
      const error = err as MessageError

      if (error.message === 'User not found') {
        notifyError(t('page.login.sso.form.email.user.not.found'))
        return
      }

      if (error.details?.errorMessage === 'Invalid provider') {
        notifyError(t('page.login.sso.invalidCompanyIdError'))
        return
      }

      notifyError(t('error.default'))

      Sentry.captureException(err)
    } finally {
      setLoading(false)
      setSsoStatus('')
      setSsoProviderId('')
    }
  }
  const onCloseAlert = () => {
    setSsoStatus('')
    setSsoProviderId('')
  }

  const ssoCompanyIdValidator = useDebouncePromise((_, value: string) => {
    if (!value) {
      return Promise.resolve()
    }

    if (/^[\w-]+$/.test(value)) {
      return Promise.resolve()
    }

    return Promise.reject(t('page.login.sso.invalidCompanyIdFormat'))
  }, 300)

  const ssoEmailValidator = useDebouncePromise((_, value: string) => {
    if (!value) {
      return Promise.resolve()
    }

    if (emailPattern.test(value)) {
      return Promise.resolve()
    }

    return Promise.reject(t('page.login.sso.form.email.invalid'))
  }, 300)

  const loadingIcon = <LoadingOutlined style={{ color: '#fff' }} spin />

  return (
    <Loader showLoader={loading} delay={0} bg="white">
      <Form
        form={form}
        id="sso-form"
        layout="vertical"
        name="ssoForm"
        requiredMark={false}
        validateTrigger="onChange"
        onFinish={(values: LoginFormValues) => {
          void handleFormSubmit(values)
        }}
        scrollToFirstError
      >
        {ssoStatus === 'SSO_ERROR_USER_PROVISION_NOT_ALLOWED' && (
          <Alert
            message={t('page.login.sso.unauthorisedError.message')}
            description={<div dangerouslySetInnerHTML={{ __html: nonProvMessage }} />}
            type="error"
            showIcon
            icon={<ExclamationCircleFilled />}
            closable
            onClose={onCloseAlert}
            style={{ backgroundColor: '#F8E6E6', borderColor: '#EBB3B3' }}
          />
        )}

        <Tabs
          activeKey={activeKey}
          className={styles.ssoTabs}
          onChange={(key) => {
            setActiveKey(key as ActiveKeyTab)
          }}
          items={[
            {
              key: 'tab-company-id',
              label: t('page.login.sso.tab.companyId'),
              children: (
                <Form.Item
                  className={styles.ssoFormItem}
                  name="ssoCompanyId"
                  rules={[
                    { required: activeKey === 'tab-company-id', message: t('page.login.sso.invalidCompanyIdRequired') },
                    {
                      validator: ssoCompanyIdValidator,
                    },
                  ]}
                >
                  <Input placeholder={t('page.login.sso.placeholder.companyId')} size="large" autoFocus />
                </Form.Item>
              ),
            },
            {
              key: 'tab-company-email',
              label: t('page.login.sso.tab.companyEmail'),
              children: (
                <Form.Item
                  className={styles.ssoFormItem}
                  name="ssoCompanyEmail"
                  required
                  rules={[
                    { required: activeKey === 'tab-company-email', message: t('page.login.sso.companyEmail.required') },
                    {
                      validator: ssoEmailValidator,
                    },
                  ]}
                >
                  <Input placeholder={t('page.login.sso.placeholder.companyEmail')} size="large" autoFocus />
                </Form.Item>
              ),
            },
          ]}
        />

        <Form.Item name="rememberSSO" valuePropName="checked" noStyle>
          <Checkbox>{t('page.login.sso.remember')}</Checkbox>
        </Form.Item>

        <Button htmlType="submit" block type="primary" className={styles.loginSubmit} disabled={loading}>
          {loading ? <Spin indicator={loadingIcon} /> : t('page.login.sso.continue')}
        </Button>
      </Form>

      <Divider
        style={{
          fontSize: '12px',
          color: '#bdbdbd',
        }}
      >
        {t('page.login.divider.continue.with')}
      </Divider>
    </Loader>
  )
}

export default LoginSSO
