import React from 'react'
import { Form, Button } from 'antd'
import { UserResponseBody } from 'types/user'
import { useIntl } from 'react-intl'
import { page } from 'lang/definitions/index'
import PhoneInput, { PhoneValue } from 'components/PhoneInput/PhoneInput'
import { useSession } from 'stores/session'

interface PhoneFormProps {
  onSubmit: (phone: string, phoneCountry: string) => void
  userDetails: UserResponseBody
}

interface FormData {
  phone: PhoneValue
}

const PhoneForm = ({ userDetails, onSubmit }: PhoneFormProps): React.JSX.Element => {
  const intl = useIntl()
  const [form] = Form.useForm()
  const { state: sessionState } = useSession()
  const userCountryCode = sessionState.user!.user.countryCode

  const handleSubmit = (data: FormData) => {
    onSubmit(data.phone.number, data.phone.countryCode)
  }

  return (
    <Form
      className="phone-form"
      form={form}
      id="phone-form"
      layout="vertical"
      name="basic"
      requiredMark={false}
      size="middle"
      initialValues={{
        phone: {
          number: userDetails?.phone?.number,
          countryCode: userDetails?.phone?.countryCode,
        },
      }}
      onFinish={handleSubmit}
    >
      <Form.Item label={intl.formatMessage(page['page.settings.slider.mobile.phone.label'])} name="phone">
        <PhoneInput enableSearch defaultCountry={userCountryCode || 'gb'} />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" size="large" block>
          {intl.formatMessage(page['page.settings.slider.mobile.update.button.label'])}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default PhoneForm
