import React, { useEffect, useState } from 'react'
import { Col, Collapse, Form, Row } from 'antd'
import * as cardioAPI from 'api/cardio'
import * as Sentry from '@sentry/react'
import { VcnProvider, Dbi, DbiFormItem } from 'types/cardio'
import { useSession } from 'stores/session'
import { AirplusIntegration } from 'types/rules'
import AirplusDbiFormItem from './AirplusDbiFormItem'

interface AirplusDbisFormProps {
  selectedPoolId: string
}

function AirplusDbisForm({ selectedPoolId }: AirplusDbisFormProps): React.JSX.Element {
  const [form] = Form.useForm()
  const [dbis, setDbis] = useState<Dbi[]>([])
  const [selectedVcnPool, setSelectedVcnPool] = useState<AirplusIntegration>()
  const [dbiFormItems, setDbiFormItems] = useState<DbiFormItem[]>([])

  const {
    state: { rules },
  } = useSession()

  useEffect(() => {
    void fetchAirplusDbis()
  }, [])

  useEffect(() => {
    if (!rules) {
      return
    }
    const vcnAirplus = (rules.logic.activeIntegrations.rule.vcnAirplus as AirplusIntegration[]) || []
    const selectedVcnPool = vcnAirplus.find((vcnPool) => vcnPool.id === selectedPoolId)

    setSelectedVcnPool(selectedVcnPool)
  }, [rules])

  useEffect(() => {
    if (!selectedVcnPool) {
      return
    }
    generateFormItemsProperties(selectedVcnPool, dbis)
  }, [selectedVcnPool, dbis])

  const generateFormItemsProperties = (selectedVcnPool: AirplusIntegration, dbis: Dbi[]) => {
    const dbisArray: DbiFormItem[] = []
    for (const [key, value] of Object.entries(selectedVcnPool.integrationProperties.dbis)) {
      if (dbis.find((dbi) => dbi.name === key)) {
        const dbi = dbis.find((dbi) => dbi.name === key)
        if (dbi) dbisArray.push({ ...dbi, required: value.required })
      }
    }

    setDbiFormItems(dbisArray)
  }

  const fetchAirplusDbis = async () => {
    try {
      const dbis = await cardioAPI.getDbis(VcnProvider.AIRPLUS)
      setDbis(dbis)
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  return (
    <Form form={form} size="small" layout="vertical" name="airplusDbisForm">
      <Collapse expandIconPosition="end" defaultActiveKey={['1']}>
        <Collapse.Panel header="Airplus DBIs" key="1">
          <Row gutter={16}>
            {dbiFormItems.map((dbi) => (
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
                key={dbi.name}
              >
                <AirplusDbiFormItem dbi={dbi} />
              </Col>
            ))}
          </Row>
        </Collapse.Panel>
      </Collapse>
    </Form>
  )
}

export default AirplusDbisForm
