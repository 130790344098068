import { notification, Table } from 'antd'
import { TablePaginationConfig } from 'antd/lib/table'
import { SorterResult } from 'antd/lib/table/interface'
import React, { useEffect, useState } from 'react'
import Filter, { Data, FilterBody, FilterOption } from 'components/Filter/Filter'
import Page from 'components/Page/Page'
import { Event, EventLabel } from 'types/activity'
import { QueryParams } from 'types/general'
import { useLanguageState } from 'stores/language/LanguageStore'
import { columnsEvents } from './utils'
import * as api from 'api/bindefeld'
import * as apiUser from 'api/user'
import { useIntl } from 'react-intl'
import { Entity } from 'types/entity'
import { UserData } from 'types/user'
import { messages } from 'lang/definitions/message'
import { filters } from 'lang/definitions/filter'
import _ from 'lodash'
import { activity } from 'lang/definitions/activity'
import { getActiveUser } from 'utils/helpers'
import { FilterState } from 'types/general'
import ActionPage from 'components/ActionPage/ActionPage'
import { setDrawerHash } from 'components/Drawers/utils'
import { useHistory } from 'react-router-dom'
import LoginEventDetails from 'components/EventDrawerDetails/LoginEventDetails'
import CreateEventDetails from 'components/EventDrawerDetails/CreateEventDetails'
import UpdateEventDetails from 'components/EventDrawerDetails/UpdateEventDetails'
import DeleteEventDetails from 'components/EventDrawerDetails/DeleteEventDetails'
import SuccessEventDetails from 'components/EventDrawerDetails/SuccessEventDetails'
import * as Sentry from '@sentry/react'
import { useSession } from 'stores/session'

enum FILTER_KIND {
  DATE = 'dateFilter',
  USER = 'userFilter',
  ENTITY = 'entityFilter',
  ACTIVITY = 'activityFilter',
}

const Activity = (): React.JSX.Element => {
  const history = useHistory()
  const intl = useIntl()

  const [languageState] = useLanguageState()
  const language = languageState.language

  const { state: sessionState } = useSession()
  const user = sessionState.user!

  const { profileId } = getActiveUser(user)

  const [events, setEvents] = useState<Event[]>()

  const [eventLabels, setEventLabels] = useState<EventLabel[]>()
  const [entityOptions, setEntityOptions] = useState<Array<Entity>>([])
  const [userOptions, setUserOptions] = useState<Array<UserData>>([])

  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [filterBody] = useState<FilterBody>({
    kind: 'curatedEvent',
  })
  const [filterState, setFilterState] = useState<FilterState>()
  const [showLoader, setShowLoader] = useState(false)
  const [total, setTotal] = useState<number>()

  const [selectedEvent, setSelectedEvent] = useState<Event>()

  const filterOptions: Array<FilterOption> = [
    {
      id: FILTER_KIND.DATE,
      label: 'Date',
      type: 'datePicker',
      callback: (value) => updateDateFilter(value as string[]),
    },
    {
      id: FILTER_KIND.USER,
      label: intl.formatMessage(filters['filter.user']),
      type: 'select',
      callback: (value) => updateUserFilter(value as string),
      dataSource: userOptions.map((type: UserData, index: number): Data => {
        if (type.name) {
          const data: Data = {
            id: index,
            name: type.name.first + ' ' + type.name.last,
            value: type.id,
          }
          return data
        } else {
          const data: Data = { id: index, name: '', value: type.id }
          return data
        }
      }),
      placeholder: intl.formatMessage(filters['filter.user.placeholder']),
    },
    {
      id: FILTER_KIND.ENTITY,
      label: intl.formatMessage(filters['filter.entity']),
      type: 'select',
      callback: (value) => updateEntityFilter(value as string),
      dataSource: entityOptions.map((type: Entity, index: number): Data => {
        if (type.class?.corp?.title) {
          const data: Data = {
            id: index,
            name: type.class?.corp?.title,
            value: type.id,
          }
          return data
        } else {
          const data: Data = { id: index, name: '', value: type.id }
          return data
        }
      }),
      placeholder: intl.formatMessage(filters['filter.entity.placeholder']),
    },
    {
      id: FILTER_KIND.ACTIVITY,
      label: intl.formatMessage(filters['filter.activity']),
      type: 'select',
      callback: (value) => updateTypeFilter(value as string),
      dataSource: eventLabels?.map((type: EventLabel, index: number): Data => {
        if (type.labelText) {
          const data: Data = { id: index, name: type.labelText }
          return data
        } else if (type.label) {
          const data: Data = { id: index, name: type.label }
          return data
        } else {
          const data: Data = { id: index, name: '' }
          return data
        }
      }),
      placeholder: intl.formatMessage(filters['filter.activity.placeholder']),
    },
  ]

  const isFilterEmpty = (): boolean => {
    if (filterBody.relatedTo || filterBody.label || filterBody.dateCreated) {
      return false
    } else {
      return true
    }
  }

  const updateDateFilter = (dateRange: string[]) => {
    if (Array.isArray(dateRange)) {
      const from = dateRange[0] || ''
      const to = dateRange[1] || ''

      if (from && to) filterBody.dateCreated = { from, to }
      else delete filterBody.dateCreated
    } else if (!dateRange) {
      delete filterBody.dateCreated
    }

    if (isFilterEmpty()) {
      void getEvents()
    } else {
      void searchEvents(filterBody)
    }
    setFilterState((state) => ({
      ...state,
      dateDue: {
        from: dateRange[0],
        to: dateRange[1],
      },
    }))
  }

  const updateUserFilter = (userId: string) => {
    if (!filterBody.relatedTo) {
      filterBody.relatedTo = {}
    }

    const user = userOptions.find((u) => u.id === userId)

    user ? (filterBody.relatedTo.userId = [user.id!]) : delete filterBody.relatedTo['userId']

    if (_.isEmpty(filterBody.relatedTo)) {
      delete filterBody['relatedTo']
    }

    if (isFilterEmpty()) {
      void getEvents()
    } else {
      void searchEvents(filterBody)
    }
  }

  const updateEntityFilter = (entityId: string) => {
    if (!filterBody.relatedTo) {
      filterBody.relatedTo = {}
    }

    const entity = entityOptions.find((ent) => ent.id === entityId)

    entity ? (filterBody.relatedTo.entityId = [entity.id]) : delete filterBody.relatedTo['entityId']

    if (_.isEmpty(filterBody.relatedTo)) {
      delete filterBody['relatedTo']
    }

    if (isFilterEmpty()) {
      void getEvents()
    } else {
      void searchEvents(filterBody)
    }
  }

  const updateTypeFilter = (label: string) => {
    if (!filterBody.relatedTo) {
      filterBody.relatedTo = {}
    }

    const selectedLabel = eventLabels?.find((e) => e.label === label)

    selectedLabel ? (filterBody.label = [selectedLabel.label]) : delete filterBody['label']

    if (_.isEmpty(filterBody.relatedTo)) {
      delete filterBody['relatedTo']
    }

    if (isFilterEmpty()) {
      void getEvents()
    } else {
      void searchEvents(filterBody)
    }
  }

  const handleSetFilterState = (value: string, key: string): void => {
    setFilterState((state) => ({
      ...state,
      [key]: value,
    }))
  }

  const renderFilterOptions = (options: Array<FilterOption>): Array<FilterOption> => {
    // filter out entity filter when have only one record
    if (entityOptions.length === 1) {
      return options.filter((option) => option.id !== FILTER_KIND.ENTITY)
    }
    return options
  }

  const filter = (
    <Filter
      isOpen={isFilterOpen}
      label={'Activity filter'}
      closeFilter={() => setIsFilterOpen(false)}
      groups={[{ elements: renderFilterOptions(filterOptions) }]}
      filterState={filterState}
      setFilterState={handleSetFilterState}
    />
  )

  const getEventLabels = async (): Promise<void> => {
    try {
      setShowLoader(true)
      if (!profileId) {
        throw Error('Profile is required!')
      }
      const { labels } = await api.getEventLabels()
      formatEventLabels(labels)
    } catch (error) {
      Sentry.captureException(error)
    } finally {
      setShowLoader(false)
    }
  }

  const formatEvents = (events: Event[]): void => {
    events.forEach((el) => {
      if (el.label == 'user.login') {
        el.labelText = intl.formatMessage(activity['activity.event.user.login'])
      } else if (el.label == 'user.create') {
        el.labelText = intl.formatMessage(activity['activity.event.user.create'])
      } else if (el.label == 'entity.create') {
        el.labelText = intl.formatMessage(activity['activity.event.entity.create'])
      } else if (el.label == 'beneficiary.create') {
        el.labelText = intl.formatMessage(activity['activity.event.beneficiary.create'])
      } else if (el.label == 'paymentInstruction.create') {
        el.labelText = intl.formatMessage(activity['activity.event.paymentInstruction.create'])
      } else if (el.label == 'paymentInstructionTemplate.create') {
        el.labelText = intl.formatMessage(activity['activity.event.paymentInstructionTemplate.create'])
      } else if (el.label == 'report.create') {
        el.labelText = intl.formatMessage(activity['activity.event.report.create'])
      } else if (el.label == 'import.create') {
        el.labelText = intl.formatMessage(activity['activity.event.import.create'])
      } else if (el.label == 'source.create') {
        el.labelText = intl.formatMessage(activity['activity.event.source.create'])
      } else if (el.label == 'signatureRequest.create') {
        el.labelText = intl.formatMessage(activity['activity.event.signatureRequest.create'])
      } else if (el.label == 'signature.create') {
        el.labelText = intl.formatMessage(activity['activity.event.signature.create'])
      } else if (el.label == 'passwordResetRequest.create') {
        el.labelText = intl.formatMessage(activity['activity.event.passwordResetRequest.create'])
      } else if (el.label == 'charge.success') {
        el.labelText = intl.formatMessage(activity['activity.event.charge.success'])
      } else if (el.label == 'charge.fail') {
        el.labelText = intl.formatMessage(activity['activity.event.charge.fail'])
      } else if (el.label == 'entityRole.update') {
        el.labelText = intl.formatMessage(activity['activity.event.entityRole.update'])
      }
    })

    setEvents(events)
  }

  const formatEventLabels = (events: EventLabel[]): void => {
    events.forEach((el) => {
      if (el.label == 'user.login') {
        el.labelText = intl.formatMessage(activity['activity.event.user.login'])
      } else if (el.label == 'user.create') {
        el.labelText = intl.formatMessage(activity['activity.event.user.create'])
      } else if (el.label == 'entity.create') {
        el.labelText = intl.formatMessage(activity['activity.event.entity.create'])
      } else if (el.label == 'beneficiary.create') {
        el.labelText = intl.formatMessage(activity['activity.event.beneficiary.create'])
      } else if (el.label == 'paymentInstruction.create') {
        el.labelText = intl.formatMessage(activity['activity.event.paymentInstruction.create'])
      } else if (el.label == 'paymentInstructionTemplate.create') {
        el.labelText = intl.formatMessage(activity['activity.event.paymentInstructionTemplate.create'])
      } else if (el.label == 'report.create') {
        el.labelText = intl.formatMessage(activity['activity.event.report.create'])
      } else if (el.label == 'import.create') {
        el.labelText = intl.formatMessage(activity['activity.event.import.create'])
      } else if (el.label == 'source.create') {
        el.labelText = intl.formatMessage(activity['activity.event.source.create'])
      } else if (el.label == 'signatureRequest.create') {
        el.labelText = intl.formatMessage(activity['activity.event.signatureRequest.create'])
      } else if (el.label == 'signature.create') {
        el.labelText = intl.formatMessage(activity['activity.event.signature.create'])
      } else if (el.label == 'passwordResetRequest.create') {
        el.labelText = intl.formatMessage(activity['activity.event.passwordResetRequest.create'])
      } else if (el.label == 'charge.success') {
        el.labelText = intl.formatMessage(activity['activity.event.charge.success'])
      } else if (el.label == 'charge.fail') {
        el.labelText = intl.formatMessage(activity['activity.event.charge.fail'])
      } else if (el.label == 'entityRole.update') {
        el.labelText = intl.formatMessage(activity['activity.event.entityRole.update'])
      }
    })

    setEventLabels(events)
  }

  const getEntityOptions = async (): Promise<void> => {
    try {
      const params = { limit: 100 } as QueryParams
      const { entities } = await api.getAllEntities(params)
      setEntityOptions(entities)
    } catch (error) {
      notification.warning({
        message: 'Error!',
        description: intl.formatMessage(messages['messages.error.entity.fetch']),
        placement: 'topRight',
      })
      Sentry.captureException(error)
    }
  }

  const getUniqueUsers = async (): Promise<void> => {
    try {
      const params = { limit: 100 } as QueryParams
      const { users } = await apiUser.getUniqueUsers(params)
      setUserOptions(users)
    } catch (error) {
      notification.warning({
        message: 'Error!',
        description: intl.formatMessage(messages['messages.error.entity.fetch']),
        placement: 'topRight',
      })
      Sentry.captureException(error)
    }
  }

  const getEvents = async (params?: QueryParams): Promise<void> => {
    try {
      setShowLoader(true)
      if (!profileId) {
        throw Error('Profile is required!')
      }
      const { events, total } = await api.getAllEvents(params)
      setEvents(events)
      setTotal(total)

      formatEvents(events)
    } catch (error) {
      Sentry.captureException(error)
    } finally {
      setShowLoader(false)
    }
  }

  const searchEvents = async (filter: FilterBody, params?: QueryParams): Promise<void> => {
    try {
      setShowLoader(true)
      const { events, total } = await api.searchEvents(filter, params)
      formatEvents(events)
      setTotal(total)
    } catch (error) {
      notification.warning({
        message: 'Error!',
        description: 'We were unable to search payments, please try again!',
        placement: 'topRight',
      })
      Sentry.captureException(error)
    } finally {
      setShowLoader(false)
    }
  }

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: unknown,
    sorter: SorterResult<Event> | SorterResult<Event>[]
  ): void => {
    const params: QueryParams = {}
    const { current, pageSize } = pagination
    const { columnKey, order } = sorter as { columnKey: string; order: string }
    if (current && pageSize) {
      const skip = (current - 1) * pageSize
      if (skip) {
        params.skip = skip
      }
    }
    if (columnKey && order) {
      params.sortOrder = order === 'descend' ? 'desc' : 'asc'
      params.sortKey = Array.isArray(columnKey) ? columnKey.join('.') : columnKey
    }

    if (isFilterEmpty()) {
      void getEvents(params)
    } else {
      void searchEvents(filterBody, params)
    }
  }

  const selectEvent = (id: string): Event => {
    const event = events!.find((event: Event) => event.id === id)!
    setSelectedEvent(event)

    return event
  }

  const openEventDetails = (id: string): void => {
    selectEvent(id)
    setDrawerHash(history, `#drawer-event-details?key=${id}`)
  }

  const onRowClick = (record: Event) => {
    return {
      onClick: () => {
        openEventDetails(record.id)
      },
    }
  }

  const prepareDrawer = (event: Event): React.JSX.Element => {
    const eventType = event.label.split('.', 2)[1]

    let drawer: React.JSX.Element

    switch (eventType) {
      case 'login':
        drawer = <LoginEventDetails event={event}></LoginEventDetails>
        break
      case 'create':
        drawer = <CreateEventDetails event={event}></CreateEventDetails>
        break
      case 'update':
        drawer = <UpdateEventDetails event={event}></UpdateEventDetails>
        break
      case 'delete':
        drawer = <DeleteEventDetails event={event}></DeleteEventDetails>
        break
      case 'success':
        drawer = <SuccessEventDetails event={event}></SuccessEventDetails>
        break

      default:
        drawer = <div></div>
        break
    }

    return drawer
  }

  useEffect(() => {
    void getEventLabels()
    void getEntityOptions()
    void getUniqueUsers()
    void getEvents()
  }, [profileId])

  return (
    <Page title="Activity" filter={filter} mobileMenuOptions={[]}>
      <>
        <Table
          size="small"
          loading={showLoader}
          rowKey={(record: Event) => `event-key-${record.id}`}
          dataSource={events}
          columns={[...columnsEvents(language, intl)]}
          className="bh-table"
          showSorterTooltip={false}
          pagination={{ total }}
          onChange={handleTableChange}
          locale={{
            emptyText: intl.formatMessage(activity['activity.not.added']),
          }}
          onRow={(record) => onRowClick(record)}
        />
        <ActionPage
          title={intl.formatMessage(activity['activity.event.drawer.details.title'])}
          hash={`#drawer-event-details?key=${selectedEvent?.id || ''}`}
          pathname={history.location.pathname}
        >
          {selectedEvent && prepareDrawer(selectedEvent)}
        </ActionPage>
      </>
    </Page>
  )
}

export default Activity
