import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css, token }) => ({
  dropdownFilterWrapper: css`
    padding: 10px;
    width: 100%;

    .ant-collapse > .ant-collapse-item {
      border-bottom: none;
    }
  `,
  paymentsFilterContainer: css`
    width: 100%;

    .ant-select {
      width: 100%;
    }
  `,
  dropdownFilter: css`
    border: none;
    background: transparent;

    .ant-collapse-content {
      border-top: none;
      border-bottom-left-radius: ${token.borderRadius}px !important;
      border-bottom-right-radius: ${token.borderRadius}px !important;
    }
  `,
  filterPanel: css`
    font-size: ${token.fontSize}px;
  `,
  radioWrapper: css`
    :not(:last-child) {
      margin-bottom: 8px;
    }
    :last-child {
      margin-bottom: 0px;
    }
  `,
  filterRadio: css`
    display: flex;
    align-items: center;

    &.ant-radio-wrapper-checked {
      font-weight: 500;
    }

    .ant-radio-checked .ant-radio-inner {
      border-color: black;
    }

    .ant-radio-checked .ant-radio-inner:after {
      background-color: black;
    }

    .ant-radio:hover .ant-radio-inner {
      border-color: black;
    }

    span.ant-radio + * {
      padding-top: 4px;
    }
  `,
  buttonsWrapper: css`
    display: flex;
    flex-direction: column;
  `,
  clearBtn: css`
    margin-top: 20px;
    border: none;
    color: black !important;
    font-weight: 500;
    float: right;
  `,
}))

export default useStyles
