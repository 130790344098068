import { api } from './utils'
import { Import } from '../types/import'
import { QueryParams } from '../types/general'

const BASE_URL = 'importer'

export const createImport = async (data: Import): Promise<Import> => {
  const url = `/${BASE_URL}/import`

  const response = await api.post(url, data)

  return await response.json()
}

export const getImport = async (importId: string): Promise<Import> => {
  const url = `/${BASE_URL}/import/${importId}`

  const response = await api.get(url)

  return await response.json()
}

export const getProfileImports = async (profileId: string, params?: QueryParams): Promise<Import[]> => {
  const url = `/${BASE_URL}/import/profile/${profileId}`

  const response = await api.get(url, params)

  return await response.json()
}

export const searchImports = async (
  filter: {
    status: string
    profileId: string[]
  },
  params?: QueryParams
): Promise<Import[]> => {
  const url = `/${BASE_URL}/search`

  const body: unknown = {
    kind: 'import',
    ...filter,
  }

  const result = await api.post(url, body, params)

  return await result.json()
}

export const downloadFileSample = async (type?: string): Promise<Blob> => {
  const url = `/${BASE_URL}/sample/${type || 'excel'}`
  const response = await api.get(url)
  return await response.blob()
}
