import { api } from './utils'
import { Dbi, VcnProvider } from 'types/cardio'

const BASE_PATH = 'cardio'

export const getDbis = async (providerId: VcnProvider): Promise<Dbi[]> => {
  const url = `/${BASE_PATH}/provider/${providerId}/dbis`
  const response = await api.get(url)
  return await response.json()
}
