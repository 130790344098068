import { defineMessages } from 'react-intl'

export const hyperlink = defineMessages({
  'hyperlink.privacyPolicy': {
    id: 'hyperlink.privacyPolicy',
    defaultMessage: 'privacy-policy',
    description: 'privacy policy param',
  },
  'hyperlink.enterprisePrivacyPolicy': {
    id: 'hyperlink.enterprisePrivacyPolicy',
    defaultMessage: 'enterprise-privacy-policy',
    description: 'privacy policy param',
  },
  'hyperlink.cookiePolicy': {
    id: 'hyperlink.cookiePolicy',
    defaultMessage: 'cookie-policy',
    description: 'coockie policy param',
  },
  'hyperlink.terms': {
    id: 'hyperlink.terms',
    defaultMessage: 'terms-of-service',
    description: 'terms of service params',
  },
})
