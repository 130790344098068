import { PAYMENT_STATE, Payment, PaymentsFilter } from 'types/paymentInstruction'

export default function applyPaymentsFilter(payments: Payment[], paymentsFilter?: PaymentsFilter): Payment[] {
  return payments.filter((payment: Payment): boolean => {
    if (!paymentsFilter) {
      return true
    }

    const requirements: boolean[] = []
    const { pendingApprovals, state, currency, card } = paymentsFilter

    if (pendingApprovals?.length) {
      const { requiredNumber, signedBy } = payment.signatures!
      const numberOfRemainingRequiredSignatures = requiredNumber - signedBy.length

      switch (pendingApprovals) {
        case 'one':
          requirements.push(numberOfRemainingRequiredSignatures === 1)
          break
        case 'multiple':
          requirements.push(numberOfRemainingRequiredSignatures > 1)
          break
      }
    }

    if (state?.length) {
      switch (state) {
        case 'assigned':
          requirements.push(
            payment.state === PAYMENT_STATE.REQUIRE_SIGNATURE && !!payment.signatures!.signatureRequests.length
          )
          break
        case 'unassigned':
          requirements.push(
            payment.state === PAYMENT_STATE.REQUIRE_SIGNATURE && !payment.signatures!.signatureRequests.length
          )
          break
        case 'payable':
          requirements.push(payment.state === PAYMENT_STATE.READY)
          break
      }
    }

    if (currency?.length) {
      requirements.push(payment.currency === currency)
    }

    if (card?.length && payment.sourceId) {
      requirements.push(payment.sourceId === card)
    }

    return requirements.every(Boolean)
  })
}
