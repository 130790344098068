//antd breakpoints
export const SCREEN_XS = 480
export const SCREEN_SM = 576
export const SCREEN_MD = 768
export const SCREEN_LG = 992
export const SCREEN_XL = 1200
export const SCREEN_XXL = 1600
export interface Size {
  width: number | undefined
  height: number | undefined
}

export enum DEVICE_TYPE {
  MOBILE = 'mobile',
  TABLET = 'tablet',
  DESKTOP = 'desktop',
}

export const getDeviceType = (size: Size): DEVICE_TYPE => {
  if (size.width && size.width <= SCREEN_SM) {
    return DEVICE_TYPE.MOBILE
  } else if (size.width && size.width > SCREEN_SM && size.width <= SCREEN_MD) {
    return DEVICE_TYPE.TABLET
  } else {
    return DEVICE_TYPE.DESKTOP
  }
}
