import { createStyles } from 'antd-style'

const useStyles = createStyles(({ css, token }) => {
  return {
    divider: css`
      color: #bdbdbd !important;
      font-size: 0.8rem !important;
    `,
    link: css`
      color: ${token.colorPrimary};
      font-size: ${token.fontSizeSM}px;
      font-weight: 500;
    `,
    formFooter: css`
      text-align: center;
    `,
  }
})

export default useStyles
