import { Descriptions } from 'antd'
import React from 'react'
import { Event } from '../../types/activity'
import Date from '../Format/Date/Date'
import { useLanguageState } from '../../stores/language/LanguageStore'
import { useIntl } from 'react-intl'
import { activity } from '../../lang/definitions/activity'

interface LoginEventDetailsProps {
  event: Event
}
const LoginEventDetails = ({ event }: LoginEventDetailsProps): React.JSX.Element => {
  const [languageState] = useLanguageState()
  const language = languageState.language
  const intl = useIntl()

  const prepareContent = (event: Event): React.JSX.Element => {
    const relatedTo = event.label.split('.', 2)[0]

    let content: React.JSX.Element = <div></div>
    const label: React.JSX.Element = (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>{event.labelText}</span>
        <Date value={event.dateCreated} locale={language} time />
      </div>
    )
    switch (relatedTo) {
      case 'user': {
        const text = intl.formatMessage(activity['activity.event.login.text'], {
          userValue: `${event.createdBy.name.first} ${event.createdBy.name.last}`,
          eventValue: `${event.createdBy.title}`,
        })

        content = (
          <Descriptions layout="vertical" bordered>
            <Descriptions.Item label={label}>
              <p>{text}</p>
            </Descriptions.Item>
          </Descriptions>
        )
        break
      }
      default: {
        break
      }
    }

    return content
  }

  return event && <div>{prepareContent(event)}</div>
}

export default LoginEventDetails
