import React from 'react'
import { Button, Modal, Typography } from 'antd'
import { ExclamationCircleFilled } from '@ant-design/icons'
import useErrorFallbackStyle from './ErrorFallbackComponent.style'

interface Props {
  errorId: string
  resetErrorBoundary: () => void
}

const ErrorFallback: React.FC<Props> = ({ errorId, resetErrorBoundary }) => {
  const { styles } = useErrorFallbackStyle()
  return (
    <Modal
      data-testid="error-boundary-modal"
      mask={true}
      styles={{
        mask: { backgroundColor: 'rgba(0, 0, 0, 0.75)' },
      }}
      closable={false}
      className={styles.errorBoundaryModal}
      title={
        <div className={styles.titleContainer}>
          <ExclamationCircleFilled className={styles.icon} />
          <Typography.Title className={styles.title} level={3}>
            Something went wrong
          </Typography.Title>
        </div>
      }
      open={true}
      footer={[
        <Button
          key="button"
          style={{
            backgroundColor: '#3E1D67',
            borderRadius: '12px',
          }}
          type="primary"
          onClick={resetErrorBoundary}
        >
          Refresh
        </Button>,
      ]}
    >
      <Typography.Paragraph>
        Please refresh the page and contact Customer Support if the problem persists.
      </Typography.Paragraph>
      <Typography.Paragraph>
        Error ID: <strong>{errorId}</strong>
      </Typography.Paragraph>
    </Modal>
  )
}

export default ErrorFallback
